import React, { useEffect, useState, useCallback, useRef, useReducer } from 'react';
import Ripples from 'react-ripples';
import { useHistory, withRouter } from 'react-router-dom';
import { CircularProgress, makeStyles, createStyles, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Toast from 'qs-common/Alerts/Toast';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import SingleThemeContainer from 'qs-components/Theme/SingleThemeContainer';
import companyData from 'qs-data/companyData';
import PersonalizedThemeData from 'qs-data/personalizedTheme';
import CacheRequest from 'qs-data/cacheRequest';
import cacheKeys from 'qs-data/cacheKeys';
import Utility from 'qs-helpers/utility';
import AllThemes from './AllThemes';
import { getI18N } from '../../i18N';
import {
  setNavigationBarColor,
  setStatusBarColor,
  trackAnalytics,
  PLATFORMS,
  getPlatform,
} from '../../os';
import {
  themeStateInitializer,
  themesMetaReducer,
  setThemes,
  setSelectedTheme,
  FETCH_COMPANY_DETAILS,
  FETCH_COMPANY_DETAILS_SUCCESS,
  getThemesMetaCache,
  setThemesMetaCache,
  getThemeScrollPosition,
  setThemeScrollPosition,
} from './reducer';
import './style.scss';
import { getToken } from 'qs-data/util';

const NAVBAR_HEIGHT = 55;

const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    paper: {
      height: '100%',
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      overflow: 'hidden',
    },
    title: {
      fontWeight: 600,
      padding: `${theme.spacing(1.5)}px 0px`,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    tryNow: {
      backgroundColor: '#2a66e2',
      '&:focus': {
        backgroundColor: '#2a66e2',
      },
      '&:hover': {
        backgroundColor: '#2a66e2',
      },
    },
    useTheme: {
      backgroundColor: 'transparent',
      border: '1px solid #01a775',
      color: '#01a775 !important',
    },
    selectedTheme: {
      backgroundColor: '#01a775',
      '&:focus': {
        backgroundColor: '#01a775',
      },
      '&:hover': {
        backgroundColor: '#01a775',
      },
    },
  });
});

const ThemesManager = () => {
  const { t } = getI18N();
  const pageContainerRef = useRef(null);
  const themeContainerRef = useRef(null);
  const themeListItemRef = useRef(null);

  const classes = useStyles();
  const history = useHistory();

  const [themeMetaData, setThemeMetaData] = useReducer(
    themesMetaReducer,
    getThemesMetaCache(),
    themeStateInitializer
  );

  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [, setRender] = useState(false);
  const [page, setPage] = useState(1);
  const [scrolling, setScrolling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);

  const themesLengthRef = useRef(themeMetaData.themes.length);
  const dateUpdatedRef = useRef();

  dateUpdatedRef.current = themeMetaData.dateUpdated;

  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const navigateToAddNewTheme = useCallback(() => {
    history.push({
      pathname: '/theme-update',
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const renderAddNewThemeButton = useCallback(() => {
    if (getPlatform() === PLATFORMS.IOS) {
      return (
        <IconButton
          onClick={navigateToAddNewTheme}
          style={{ width: 30, height: 40, padding: 0 }}
          color="primary"
          aria-label="create-theme"
        >
          <AddIcon />
        </IconButton>
      );
    }
    return (
      <Ripples
        className={'addNewThemeButtonsContainer'}
        style={{ backgroundColor: '#FFFFFF', color: '#00A775' }}
        onClick={navigateToAddNewTheme}
      >
        {t('create_theme')}
      </Ripples>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToAddNewTheme]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#4DA47A',
        color: '#FFFFFF',
        title: t('themes_manager'),
        hideBack: desktop,
        height: NAVBAR_HEIGHT,
        actions: renderAddNewThemeButton,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#212934');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch, renderAddNewThemeButton]);

  const deletePreviousThemeCache = () => {
    CacheRequest.deleteCacheForKeys([
      cacheKeys.selectedThemeId,
      cacheKeys.selectedTheme,
      cacheKeys.name,
      cacheKeys.primaryColor,
      cacheKeys.contrastTextColor,
      cacheKeys.pageTextColor,
      cacheKeys.backgroundColor,
      cacheKeys.colorUpdatedToggle,
      cacheKeys.nullEntryAddedToHistory,
    ]);
  };
  const pageContainerRefMounted = useCallback(
    (ref) => {
      setRender(true);
      pageContainerRef.current = ref;
      const scrollPosition = getThemeScrollPosition();
      if (scrollPosition && pageContainerRef.current) {
        setTimeout(() => {
          pageContainerRef.current.scrollTop = scrollPosition;
        }, 0);
      }
      return () => {
        pageContainerRef.current = null;
      };
    },
    [setRender]
  );
  const themeContainerRefMounted = useCallback(
    (ref) => {
      if (themeContainerRef.current === null) {
        themeContainerRef.current = ref;
        setRender(true);
      }
    },
    [setRender]
  );

  const themeItemRefMounted = useCallback(
    (ref) => {
      if (themeListItemRef.current === null) {
        themeListItemRef.current = ref;
        setRender(true);
      }
    },
    [setRender]
  );

  themesLengthRef.current = themeMetaData.themes.length;

  const fetchCompanyDetails = useCallback(() => {
    const cachedCompayDetails = CacheRequest.getCacheForKey(cacheKeys.companyDetails);
    if (cachedCompayDetails) {
      setThemeMetaData({
        type: FETCH_COMPANY_DETAILS_SUCCESS,
        data: cachedCompayDetails,
      });
      return;
    }
    setThemeMetaData({ type: FETCH_COMPANY_DETAILS });
    companyData
      .getCompanyDetails()
      .then((companyDetailsResponse) => {
        CacheRequest.setCacheForKey(cacheKeys.companyDetails, companyDetailsResponse);
        setThemeMetaData({ type: FETCH_COMPANY_DETAILS_SUCCESS, data: companyDetailsResponse });
      })
      .catch((error) => {
        setThemeMetaData({ type: FETCH_COMPANY_DETAILS_SUCCESS, error });
        setToastState({ open: true, message: t('error_while_fetching_company_details') });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchThemeList = useCallback(() => {
    if (themesLengthRef.current === 0) {
      setProgressBar(true);
      showErrorComponent(false);
      setLoading(true);
    }
    PersonalizedThemeData.getAllThemesForCompany({ page, dateUpdated: dateUpdatedRef.current })
      .then(({ themes, totalCount: total, dateUpdated }) => {
        if (themes) {
          setThemes(setThemeMetaData, { themes, total, page, dateUpdated });
        }
      })
      .catch(() => {
        showErrorComponent(true);
      })
      .then(() => {
        setLoading(false);
        setProgressBar(false);
      });
  }, [page]);

  const getSelectedTheme = () => {
    const cachedTheme = CacheRequest.getCacheForKey(cacheKeys.selectedTheme);
    if (cachedTheme) {
      setSelectedTheme(setThemeMetaData, cachedTheme);
      return;
    }
    PersonalizedThemeData.getSelectedThemeForCompany()
      .then((theme) => setSelectedTheme(setThemeMetaData, theme))
      .catch(() =>
        setToastState({
          open: true,
          message: t('couldnt_fetch_selected_theme'),
        })
      );
  };

  useEffect(() => {
    deletePreviousThemeCache();
    getSelectedTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  useEffect(() => {
    setThemesMetaCache(themeMetaData);
  }, [themeMetaData]);

  useEffect(() => {
    const themesMetaCache = getThemesMetaCache() || {};
    if ((themesMetaCache.themes || []).length > 0 && scrolling === false) {
      setProgressBar(false);
      setLoading(false);
    } else {
      fetchThemeList();
      setScrolling(false);
    }
    return () => {
      if (pageContainerRef.current) {
        setThemeScrollPosition(pageContainerRef.current.scrollTop);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchThemeList]);

  const onSelectedThemeChanged = (theme) => () => {
    setSelectedTheme(setThemeMetaData, theme);
    PersonalizedThemeData.setSelectedThemeIdForCompany(theme.id)
      .then(() => {
        trackAnalytics({
          eventName: 'theme_set',
          props: {
            theme_id: theme.id,
            theme_name: theme.name,
          },
        });
        setToastState({
          open: true,
          message: t('theme_changed_successfully'),
        });
      })
      .catch(() => {
        setToastState({
          open: true,
          message: t('theme_change_failed'),
        });
      })
      .then(() => {
        setProgressBar(false);
      });
  };

  const editThemeHandler = (theme) => (e) => {
    e.preventDefault();

    if (!Utility.isThemeEditable(theme)) {
      setToastState({
        open: true,
        message: t('quicksell_basic_themes_cant_be_edited'),
      });
      return;
    }

    CacheRequest.setCacheForKey(cacheKeys.selectedThemeId, theme.id);
    navigateToAddNewTheme();
  };

  const loadMoreHandler = () => {
    setLoading(true);
    setPage((prevPage) => prevPage + 1);
  };

  const renderSelectedThemeContainer = () => {
    if (!themeMetaData.selectedTheme) {
      return null;
    }
    return (
      <div className="setThemeContainer">
        <p className={'heading'}>{t('site_theme')}</p>
        <p className={'subHeading'}>{t('applied_across_all_catalogues')}</p>
        <SingleThemeContainer
          theme={themeMetaData.selectedTheme}
          hideDivider
          hideSetThemeIcon
          fromThemeEditDrawer={false}
          source={source}
          languageCode={languageCode}
        />
      </div>
    );
  };

  const onScrollHandler = (scrollEvent) => {
    const { scrollTop } = scrollEvent.target;
    const { total, themes } = themeMetaData;
    const themeItemHeight = (themeListItemRef.current || {}).clientHeight || 450;
    const screenHeight = window.screen.height;
    if (
      scrollTop + screenHeight + themeItemHeight / 2 < themeContainerRef.current.clientHeight ||
      loading ||
      themes.length >= total
    ) {
      return;
    }
    setScrolling(true);
    loadMoreHandler();
  };

  const renderAllThemes = () => {
    const { total, themes, selectedTheme } = themeMetaData;

    if (!pageContainerRef.current) {
      return;
    }

    return (
      <div className="allThemes">
        <p className={'heading'}>{t('all_themes')}</p>
        <AllThemes
          themeItemRefMounted={themeItemRefMounted}
          loading={loading}
          themes={themes}
          classes={classes}
          selectedTheme={selectedTheme || {}}
          editThemeHandler={editThemeHandler}
          onSelectedThemeChanged={onSelectedThemeChanged}
          totalThemesCount={total}
          companyDetails={themeMetaData.companyDetails.data || {}}
          source={source}
          languageCode={languageCode}
        />
      </div>
    );
  };

  const commonTextStyle = { justifyContent: 'center', alignItems: 'center' };

  if (progressBar) {
    return (
      <div className="themesManagerContainer" style={commonTextStyle}>
        <CircularProgress size={24} color="primary" />
      </div>
    );
  }

  if (errorComponent || themeMetaData.themes.length === 0) {
    return (
      <div className="themesManagerContainer" style={commonTextStyle}>
        <CustomErrorComponent onRetryClick={fetchThemeList} />
      </div>
    );
  }

  return (
    <div
      className="themesManagerContainer"
      onScroll={onScrollHandler}
      ref={pageContainerRefMounted}
    >
      <div className="theme-container" ref={themeContainerRefMounted}>
        {renderSelectedThemeContainer()}
        {renderAllThemes()}
        <Toast
          open={toastState.open}
          message={toastState.message}
          onClose={() => {
            setToastState({
              open: false,
              message: '',
            });
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(ThemesManager);
