import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  customFields: {
    // minHeight: '100vh',
    background: '#242c36',
    flexDirection: 'column',
  },
  draggingItem: {
    backgroundColor: '#0d131b',
    listStyle: 'none',
    pointerEvents: 'auto !important',
    cursor: 'grabbing',

    '& div': {
      cursor: 'grabbing !important',
    },
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '1rem',
  },
  noFieldsTitle: {
    justifyContent: 'center',
  },
  title: {
    fontWeight: 700,
    color: '#FFFFFF',
    fontSize: 26,
    marginBottom: 0,
  },
  newFieldBtn: {
    fontSize: 20,
    fontWeight: 600,
    textTransform: 'none',
    backgroundColor: '#4da47a',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#387256',
    },
  },
  navBtn: {
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: '#4da47a',
    margin: '4px',
    marginLeft: '15px',
    color: '#FFFFFF',
    height: 'auto',
    '&:hover, &:focus': {
      backgroundColor: '#387256',
    },
    '&:active': {
      backgroundColor: 'none',
    },
  },
  info: {
    fontWeight: 500,
    color: '#777E8F',
  },
  titleInfo: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  newField: {
    fontSize: 20,
  },
  dlgButton: {
    fontSize: 16,
    borderRadius: '5rem',
    color: '#FFFFFF',
    fontWeight: 600,
    textTransform: 'none',
    marginTop: '0.6rem',
    marginRight: '0.5rem',
  },
  btnError: {
    color: '#f44336',
    fontSize: 15,
    fontWeight: 400,
    marginTop: '0.5rem',
  },
  formControl: {
    display: 'flex',
    marginTop: '1.5rem',
  },
  txtVisibility: {
    fontSize: 17,
    fontWeight: 600,
    marginTop: '1.2rem',
  },
  txtControl: {
    fontSize: 15,
    fontWeight: 600,
    color: '#8397AF',
  },
  listText: {
    fontWeight: 600,
    color: '#FFFFFF',
  },
  listSubText: {
    fontWeight: 600,
    color: '#8D9EB4',
  },
  listButton: {
    justifyContent: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
    minWidth: 'auto',

    '&.disabledListButton': {
      opacity: 0.95,
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
  },
  listActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    right: 24,
  },
  listItemContainer: {
    flex: 1,
  },
  listItem: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    paddingRight: 0,
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    outline: '1px solid transparent',
    borderLeft: 'none',
    borderRight: 'none',
    zIndex: 0,

    '&:hover': {
      backgroundColor: '#0d131b',
    },

    '&.loading': {
      cursor: 'progress',
      boxShadow: 'none',
      background: 'linear-gradient(-45deg,#2e3947,#1c2733,#1a222b,#0a121a)',
      backgroundSize: '400% 400%',
      borderColor: '#141a22',
      outlineColor: '#141a22',
      animation: 'gradient 1s linear infinite',
      backfaceVisibility: 'hidden',
      transformStyle: 'preserve-3d',
    },

    '&.disabled': {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
  },
  visibility: {
    fontSize: 17,
    fontWeight: 600,
    background: '#161D25',
    borderRadius: '4.5rem',
    height: 32,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    alignItems: 'center',
    display: 'flex',
    marginRight: '0.5rem',
  },
  PRIVATE: {
    color: '#FF4747',
  },
  PUBLIC: {
    color: '#2ED57E',
  },
  image: {
    marginRight: '1.25rem',
  },
  selected: {
    color: '#000000',
  },
  plusSvg: {
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: 20,
    backgroundColor: '#4da47a !important',
  },
  divider: {
    marginLeft: '2rem',
    marginRight: '2rem',
  },
});

export default useStyles;
