import { atom } from 'jotai';

export const intialCustomerData = {
  id: null,
  name: '',
  phone: '',
  email: '',
  businessName: '',
  symbol: '',
};
export const selectedCustomerAtom = atom(intialCustomerData);

export const orderAtom = atom();
