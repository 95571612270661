import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#252B36",
    margin: "0px",
    padding: "0px",
    color: "#ffffff",
    boxShadow: "none",
    overflow: "initial",
    borderRadius: "0px",
    cursor: "pointer",
    "&:last-child": {
      marginBottom: "0px",
    },
  },
  jewelleryPriceRowContainer: {
    borderRadius: "0px",
    margin: "0px",
    padding: "15px 20px 0px 20px",
    width: "calc(100% - 40px)",
  },
  nestedJewelleryPriceRowContainer: {
    padding: "15px 20px 0px 20px",
    width: "calc(100% - 40px)",
    margin: "0px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0d131b",
    },
  },
  parentJewelleryPriceRowContainer: {
    flexDirection: "column",
    padding: "0px",
    borderBottom: "none",
    width: "100%",
  },
  singleJewelleryPriceRowContainer: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0d131b",
    },
  },
  jewelleryPriceFlexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "15px",
  },
  parentJewelleryPriceFlexContainer: {
    padding: "15px 20px 0px 20px",
    width: "calc(100% - 40px)",
    borderBottom: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#0d131b",
    },
    "& $jewelleryPriceFlexContainer": {
      borderBottom: "1px solid #3a424e",
    },
  },
  childJewelleryPriceFlexContainer: {
    padding: "0px",
    width: "100%",
    borderBottom: "1px solid #3a424e",
  },
  jewelleryPriceRowTitleContainer: {
    flexGrow: 1,
    paddingRight: "20px",
  },
  jewelleryPriceTitle: {
    color: "#ffffff",
    fontSize: "1.1rem",
    fontWeight: "700",
  },
  jewelleryPriceDescription: {
    color: "#9caabd",
    fontSize: "0.9rem",
  },
  jewelleryPricePageContainer: {
    background: "#242c36",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  jewelleryPricePageLoader: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});