import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/checkmark.svg';
import CountDownTimer from './CountDownTimer';
import LiveButton from './LiveButton';
import network from '../../data/network';
import green from '../../../node_modules/@material-ui/core/colors/green';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../node_modules/@material-ui/core/Button/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import CustomTextField from '../../common/CustomTextField';
import Loader from '../../common/Loader';
import dateFormat from 'dateformat';
import { seatReservedNative, setNavigationBarColor, setStatusBarColor } from '../../os';
import { handleError } from '../../common/error';
import { useAppContext } from '../../common/Contexts/AppContext';
import CustomErrorComponent from '../../common/CustomErrorComponent';
import Toast from 'qs-common/Alerts/Toast';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { getI18N } from '../../i18N';
import Utility from 'qs-helpers/utility';

// webinarState : scheduled, live, ended
// seatState : seatReserved, seatReserving, seatNotReserved

const WebinarState = {
  DOES_NOT_EXIST: 'does_not_exist',
  SCHEDULED: 'scheduled',
  LIVE: 'live',
  ENDED: 'dead',
};

const SeatState = {
  SEAT_LOADING: 'seatLoading',
  SEAT_RESERVED: 'seatReserved',
  SEAT_NOT_RESERVED: 'seatNotReserved',
  SEAT_RESERVING: 'seatReserving',
};

const ScreenState = {
  WEBINAR_LOADING: 'webinar_loading',
  WEBINAR_LOADED: 'webinar_loaded',
};

function Webinar() {
  const { t } = getI18N();
  const { id } = useParams();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [errorComponent, showErrorComponent] = useState(false);
  const [screenState, setScreenState] = useState(ScreenState.WEBINAR_LOADING);
  const [webinarData, setWebinarData] = useState({
    webinarId: '',
    webinarTitle: '',
    webinarDescription: '',
    webinarTime: 0,
    webinarDurationMinutes: 0,
  });
  const [webinarHost, setWebinarHost] = useState({
    name: '',
    image_url: '',
    designation: '',
  });
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [seatState, setSeatState] = useState(SeatState.SEAT_LOADING);
  const [webinarState, setWebinarState] = useState(WebinarState.DOES_NOT_EXIST);
  const [joinUrl, setJoinUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [toastState, setToastState] = useState({ open: false, message: '' });

  const loadWebinarData = useCallback(() => {
    Promise.all([network.getWebinarDetails(id), network.getUserInfo()])
      .then((answers) => {
        setScreenState(ScreenState.WEBINAR_LOADED);
        const [data, res] = answers;
        if (!data.webinarId) {
          setWebinarState(WebinarState.DOES_NOT_EXIST);
          return;
        }
        const { webinarId, webinarTitle, webinarDescription, webinarTime, webinarDurationMinutes } =
          data;

        const { name, image_url, designation } = data.host;
        setWebinarData({
          webinarId,
          webinarTitle,
          webinarDescription,
          webinarTime,
          webinarDurationMinutes,
        });

        setWebinarHost({
          name,
          image_url,
          designation,
        });

        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            ...res,
          };
        });

        const webinarStart = webinarTime;
        const webinarEnd = webinarStart + webinarDurationMinutes * 60 * 1000;
        const now = new Date().getTime();

        if (now > webinarEnd) {
          onWebinarDied();
          showErrorComponent(false);
          return;
        }

        if (now < webinarEnd) {
          network
            .getWebinarAttendance({ webinarId })
            .then((attendance) => {
              const seatReserved = attendance;

              if (seatReserved.join_url) {
                setSeatState(SeatState.SEAT_RESERVED);
                setJoinUrl(seatReserved.join_url);
              } else {
                setSeatState(SeatState.SEAT_NOT_RESERVED);
              }

              showErrorComponent(false);
            })
            .catch(() => showErrorComponent(true));
        }
      })
      .catch(() => {
        showErrorComponent(true);
      });
  }, [id]);

  useEffect(() => {
    loadWebinarData();
  }, [loadWebinarData]);

  useEffect(() => {
    const webinarStart = webinarData.webinarTime;
    const webinarEnd = webinarData.webinarTime + webinarData.webinarDurationMinutes * 60 * 1000;
    const now = new Date().getTime();
    if (now < webinarStart) {
      setWebinarState(WebinarState.SCHEDULED);
    }

    if (now > webinarStart && now < webinarEnd) {
      setWebinarState(WebinarState.LIVE);
    }

    if (now > webinarEnd) {
      setWebinarState(WebinarState.ENDED);
    }
  }, [webinarData.webinarTime, webinarData.webinarDurationMinutes]);

  const onReserveSeat = () => {
    // if name || email is not present then ask for it.
    if (!userInfo.firstName || !userInfo.lastName || !userInfo.email) {
      setOpen(true);
      return;
    }

    setOpen(false);

    setSeatState(SeatState.SEAT_RESERVING);

    network
      .reserveASeat({
        webinarId: webinarData.webinarId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
      })
      .then((data) => {
        const seatReserved = data;

        if (seatReserved.join_url) {
          setSeatState(SeatState.SEAT_RESERVED);
          setJoinUrl(seatReserved.join_url);
          try {
            seatReservedNative(webinarData.webinarTitle, webinarData.webinarTime);
          } catch (e) {
            handleError(e);
          }
        } else {
          setSeatState(SeatState.SEAT_NOT_RESERVED);
        }
      })
      .catch(() => {
        setToastState({
          open: true,
          message: t('something_went_wrong_while_reserving_a_seat_for_this_webinar'),
        });
        setSeatState(SeatState.SEAT_NOT_RESERVED);
      });
  };

  const onInputChanged = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo((userInfo) => {
      return {
        ...userInfo,
        [name]: value,
      };
    });
  };

  const onWebinarDied = () => {
    setWebinarState(WebinarState.ENDED);
  };

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? 'linear-gradient(360deg, #0f101a 0%, #0F101A 100%)' : '#4DA47A',
        color: '#FFFFFF',
        title: '',
        boxShadow: 'none',
      },
    });
    setNavigationBarColor('#6799CB');
    setStatusBarColor('#4DA47A');
  }, [dispatch, desktop]);

  const retryLoadingWebinar = () => {
    setScreenState(ScreenState.WEBINAR_LOADING);
    setSeatState(SeatState.SEAT_LOADING);
    setWebinarState(WebinarState.DOES_NOT_EXIST);
    loadWebinarData();
  };

  if (errorComponent) {
    return (
      <div className="WebinarContainer">
        <div className="WebinarDoesNotExist">
          <CustomErrorComponent style={{ margin: 'auto' }} onRetryClick={retryLoadingWebinar} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="WebinarContainer">
        {screenState === ScreenState.WEBINAR_LOADING && (
          <div className="LoaderContainer">
            <Loader />
          </div>
        )}

        {screenState === ScreenState.WEBINAR_LOADED &&
          webinarState === WebinarState.DOES_NOT_EXIST && (
            <div className="WebinarDoesNotExist">{t('we_will_schedule_a_webinar_soon')}</div>
          )}

        {screenState === ScreenState.WEBINAR_LOADED &&
          webinarState !== WebinarState.DOES_NOT_EXIST && (
            <div className="WebinarContent">
              <div className="HeroTitle">{webinarData.webinarTitle}</div>
              <div className="HeroSubtitle">{webinarData.webinarDescription}</div>

              {webinarState === WebinarState.LIVE ? <LiveButton /> : null}

              {webinarData.webinarTime > 0 && (
                <div className="YourTime">
                  {dateFormat(new Date(webinarData.webinarTime), 'dddd, dS mmmm, HH:MMtt')}
                </div>
              )}
              {webinarState === WebinarState.LIVE && seatState === SeatState.SEAT_RESERVED ? (
                <button
                  className="JoinNowButton"
                  onClick={() => {
                    Utility.openPopup(joinUrl);
                  }}
                >
                  {t('join_now')}
                </button>
              ) : null}

              {webinarState === WebinarState.SCHEDULED && seatState === SeatState.SEAT_RESERVED && (
                <div className="SeatReservedContainer">
                  <CheckmarkIcon className="SeatReservedIcon" />
                  <span className="SeatReservedText">{t('your_seat_is_reserved')}</span>
                </div>
              )}

              {seatState === SeatState.SEAT_LOADING ? (
                <div className="SeatLoading">
                  <Loader small />
                </div>
              ) : null}

              {seatState === SeatState.SEAT_NOT_RESERVED && webinarData.webinarId && (
                <button className="ReserveASeatButton" onClick={onReserveSeat}>
                  {t('reserve_a_seat')}
                </button>
              )}

              {seatState === SeatState.SEAT_RESERVING && (
                <Loader small style={{ marginTop: 10, marginBottom: 10 }} />
              )}

              {webinarState === WebinarState.SCHEDULED && (
                <div className="TimeRemainingContainer">
                  <div className="TimeRemainingLabel">{t('time_remaining')}</div>
                  <CountDownTimer
                    goLiveTime={webinarData.webinarTime}
                    endTime={
                      webinarData.webinarTime + webinarData.webinarDurationMinutes * 10 * 1000
                    }
                    isDead={onWebinarDied}
                    isLiveCallback={() => {
                      setWebinarState(WebinarState.LIVE);
                    }}
                  />
                </div>
              )}

              {webinarState === WebinarState.ENDED && <div>{t('this_webinar_has_ended')}</div>}

              <div className="HostContainer">
                <img className="HostImage" alt="" src={webinarHost.image_url} />
                <span className="HostName">{webinarHost.name}</span>
                <span className="HostTitle">{webinarHost.designation}</span>
              </div>
            </div>
          )}
      </div>

      <MaterialDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('please_enter_your_details')}</DialogTitle>
        <DialogContent>
          <CustomTextField
            fullWidth
            variant="filled"
            label={t('first_name')}
            margin="normal"
            name="firstName"
            value={userInfo.firstName}
            onChange={onInputChanged}
          />
          <CustomTextField
            fullWidth
            variant="filled"
            label={t('last_name')}
            margin="normal"
            name="lastName"
            value={userInfo.lastName}
            onChange={onInputChanged}
          />
          <CustomTextField
            fullWidth
            variant="filled"
            label={t('email')}
            margin="normal"
            name="email"
            value={userInfo.email}
            onChange={onInputChanged}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReserveSeat} style={{ color: green.A100 }} autoFocus>
            {t('register')}
          </Button>
        </DialogActions>
      </MaterialDialog>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
}

export default Webinar;
