import Loader from 'qs-common/Loader';
import React from 'react';
import {
  BUTTON_TEXT,
  ENTITY_TYPE,
  renderIcon,
  renderItemIconStyle,
} from './privacySettingConstants';
import './styles.scss';
import { getI18N } from '../../i18N';

const RenderRowIcon = ({
  key,
  name = '',
  iconColor = 'black',
  iconName = 'ic_bird_1',
  renderCount,
  api = () => {},
  renderButtontext,
  loading = false,
  entityType = 'COMPANY',
  additionalStyles = false,
}) => {
  const { t } = getI18N();
  return (
    <div className="renderItem" key={key} style={additionalStyles ? { overflowX: 'hidden' } : {}}>
      {(renderButtontext() === BUTTON_TEXT.ALLOW || renderButtontext() === BUTTON_TEXT.BLOCK) &&
        entityType === ENTITY_TYPE.CATALOGUE && <div className="overLay"></div>}
      <div
        style={{
          width: renderItemIconStyle.size,
          height: renderItemIconStyle.size,
          borderRadius: renderItemIconStyle.borderRadius,
          marginLeft: renderItemIconStyle.marginLeft,
          backgroundColor: iconColor,
        }}
      ></div>

      <img
        src={renderIcon(iconName)}
        alt="row icon"
        style={{
          maxHeight: 48,
          width: renderItemIconStyle.imageWidth,
          marginLeft: renderItemIconStyle.imageMarginLeft,
        }}
      />
      <div className="renderItemDetails">
        <div className="nameAndCount">
          <div className="renderItemName">{name}</div>
          <div className="renderItemCount">{renderCount()}</div>
        </div>
        {loading ? (
          <Loader small />
        ) : (
          <div
            className={`allowAndDenyButton ${additionalStyles ? 'buttonStyle' : ''}`}
            onClick={api}
          >
            {t(renderButtontext().toLowerCase())}
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderRowIcon;
