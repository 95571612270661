import React from 'react';
import Ripples from 'react-ripples';
import { getI18N } from '../../i18N';
import "./style.scss";

export default function ({ text = 'change', onClick, style }) {
    const { t } = getI18N();

    return <Ripples onClick={onClick}>
        <span className='changeText' style={style}>{t(text)}</span>
    </Ripples>
} 