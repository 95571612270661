import React from 'react';

export default () => (
  <svg
    width="9px"
    height="15px"
    viewBox="0 0 9 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons8-lightning_bolt</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android-Copy-5"
        transform="translate(-103.000000, -342.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g id="Group-4" transform="translate(103.000000, 340.000000)">
          <g id="icons8-lightning_bolt" transform="translate(0.000000, 2.000000)">
            <path
              d="M4.43181811,0 C4.28926551,1.74033446e-05 4.16177514,0.0887294611 4.11221584,0.222389928 L0.10520243,7.81960215 C0.0367716818,7.92804588 0.000308814014,8.0535889 0,8.18181805 C0,8.55837583 0.305260393,8.86363622 0.681818171,8.86363622 L4.09090903,8.86363622 L4.09090903,14.6590907 C4.09090903,14.8473696 4.24353922,14.9999998 4.43181811,14.9999998 C4.55973806,15.0001499 4.67697646,14.9286756 4.73543993,14.814897 L8.77175032,7.16042239 L8.77041873,7.1590908 C8.83091506,7.05562296 8.86306777,6.93803611 8.86363622,6.81818171 C8.86363622,6.44162393 8.55837583,6.13636354 8.18181805,6.13636354 L4.7727272,6.13636354 L4.7727272,0.340909086 C4.7727272,0.152630197 4.620097,0 4.43181811,0 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
