import React, { useEffect, useState, useCallback } from 'react';
import './style.scss';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import Loader from 'qs-common/Loader';
import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../../os';
import CustomCheckbox from 'qs-common/CustomCheckbox';
import { makeStyles } from '@material-ui/core/styles';
import RightArrow from 'qs-assets/icons/RightArrowInviteNow';
import { fetchContactList, hasPermission, requestPermission } from '../../../os';
import Toast from 'qs-common/Alerts/Toast';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import network from 'qs-data/network';
import { navigationBarColor } from '../Constants';
import Cache from '../Cache';
import { getI18N } from '../../../i18N';

const useStyles = makeStyles({
  checkBoxStyle: {
    padding: 0,
    paddingRight: '10px',
  },
  formControlLabel: {
    marginLeft: '0px',
    marginRight: '0px',
  },
});
const SelectContacts = () => {
  const { t } = getI18N();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const classes = useStyles();
  const [, dispatch] = useAppContext();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [selectedContactList, setSelectedContactList] = useState([]);
  const [expandSearchBox, setExpandSearchBox] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [sendInvitesLoader, setSendInvitesLoader] = useState(false);

  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const onSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearchExpand = useCallback(() => {
    setExpandSearchBox(!expandSearchBox);
    setSearchText('');
  }, [expandSearchBox]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#21252D',
        color: '#FFFFFF',
        boxShadow: false,
        referral: true,
        enableBoxShadow: false,
        title: t('select_friends_to_refer'),
        placeholder: t('search_your_friends'),
        searchable: true,
        expandSearchBox: expandSearchBox,
        onClick: onSearchExpand,
        onChange: (e) => onSearch(e),
        value: searchText,
        overrideTitleClassStyle: {
          color: '#FFFFFF',
          lineHeight: '25px',
          fontSize: '18px',
          fontWeight: 600,
          flexGrow: 1,
        },
      },
    });
    setNavigationBarColor(navigationBarColor);
    setStatusBarColor('#21252D');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, expandSearchBox, onSearch, onSearchExpand]);

  useEffect(() => {
    if (!desktop) {
      checkPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop]);

  const permissionResult = async ({ detail } = {}) => {
    const { permission } = detail;
    setLoading(false);
    if (permission) {
      getContactsList();
      return;
    } else {
      setToastState({
        open: true,
        message: t('allow_access_to_contacts'),
      });
    }
  };

  useEffect(() => {
    document.addEventListener('permissionResult', permissionResult);
    return () => {
      document.removeEventListener('permissionResult', permissionResult);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeSpecialCharacters = (text) => {
    return text.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
  };

  useEffect(() => {
    if (contactsList.length) {
      if (!searchText && expandSearchBox) {
        setSearchResults([...contactsList]);
        return;
      }
      if (searchText) {
        trackAnalytics({
          eventName: 'contacts_searched',
          props: {
            ...Cache.getReferralAnalyticsObject(),
            search_query: searchText,
          },
        });
        const copyContactsList = [...contactsList];
        const removeSpecialCharactersInSearchText = removeSpecialCharacters(searchText);
        const filteredContacts = copyContactsList.filter((contact) => {
          const contactName = removeSpecialCharacters(contact.name);
          const contactPhoneNumber = removeSpecialCharacters(contact.phoneNumber);
          if (
            contactName.toLowerCase().includes(removeSpecialCharactersInSearchText.toLowerCase()) ||
            contactPhoneNumber.includes(removeSpecialCharactersInSearchText)
          ) {
            return contact;
          }
          return undefined;
        });
        setSearchResults(filteredContacts);
      }
    }
  }, [searchText, contactsList, expandSearchBox]);

  const checkPermission = async () => {
    setLoading(true);
    try {
      const hasContactsPermission = await hasPermission('contacts');
      if (hasContactsPermission) {
        await getContactsList();
      } else {
        askForContactsPermission();
      }
    } catch (error) {
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    } finally {
      setLoading(false);
    }
  };

  const askForContactsPermission = () => {
    requestPermission('contacts');
  };

  const getContactsList = async () => {
    setLoading(true);
    try {
      const data = await fetchContactList();
      const contacts = typeof data === 'string' ? JSON.parse(data) : data;
      const addCheckedPropertyToContactsList = contacts.map((contact) => {
        contact.checked = false;
        return contact;
      });
      setContactsList(addCheckedPropertyToContactsList || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastState({
        open: true,
        message: t('could_not_get_contacts'),
      });
    }
  };

  const setLabel = () => {
    if (!selectAll) {
      return t('select_all');
    }
    return t('deselect_all');
  };

  const getSelectedContacts = (list) => {
    const selectedContacts = list.filter((contact) => contact.checked);
    setSelectedContactList(selectedContacts);
  };

  const changeSelectAll = () => {
    if (selectAll) {
      trackAnalytics({
        eventName: 'contacts_unselect_all',
        props: {
          ...Cache.getReferralAnalyticsObject(),
          contacts_count: 0,
        },
      });
    } else {
      trackAnalytics({
        eventName: 'contacts_select_all',
        props: {
          ...Cache.getReferralAnalyticsObject(),
          contacts_count: contactsList.length,
        },
      });
    }
    const updatedContactsList = [...contactsList].map((contact) => {
      contact.checked = !selectAll;
      return contact;
    });
    setSelectAll(!selectAll);
    setContactsList(updatedContactsList);
    getSelectedContacts(updatedContactsList);
  };

  const selectContact = ({ id, checked, name, phoneNumber }) => {
    if (checked) {
      trackAnalytics({
        eventName: 'contact_unchecked',
        props: {
          ...Cache.getReferralAnalyticsObject(),
          name: name,
          phoneNumber: phoneNumber,
        },
      });
    } else {
      trackAnalytics({
        eventName: 'contact_checked',
        props: {
          ...Cache.getReferralAnalyticsObject(),
          name: name,
          phoneNumber: phoneNumber,
        },
      });
    }

    const updatedContactsList = [...contactsList].map((contact) => {
      if (contact.id === id) {
        contact.checked = !checked;
      }
      return contact;
    });

    const checkIfAllContactsAreSelected = updatedContactsList.every(
      (contact) => contact.checked === true
    );
    setSelectAll(checkIfAllContactsAreSelected);
    getSelectedContacts(updatedContactsList);
  };

  const renderLoader = useCallback((styles, key) => {
    return (
      <div className="loader" style={styles} key={key}>
        <Loader />
      </div>
    );
  }, []);

  const renderRow = useCallback(
    ({ data, index, style }) => {
      const contactData = data[index];
      if (!contactData) {
        return renderLoader(style, index);
      }
      const { id, name, phoneNumber, checked } = contactData;
      return (
        <div
          key={id}
          style={style}
          className="contactDetailsContainer"
          onClick={() => selectContact({ id, checked, name, phoneNumber })}
        >
          <div className="contactDetails">
            <div className="checkbox">
              <CustomCheckbox
                key={id}
                checked={checked}
                checkBoxStyle={classes.checkBoxStyle}
                formControlLabel={classes.formControlLabel}
              />
            </div>

            <div className="contactDetailsAndOneDayFreeText">
              <div className="customerNameAndNumber">
                <div className="name">{name}</div>
                <div className="phoneNumber">{phoneNumber}</div>
              </div>
              <div className={`oneDayFree ${checked ? 'oneDayFreeSelected' : ''}`}>
                <span>{t('one_day_free')}</span>
              </div>
            </div>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectContact]
  );

  const itemKey = useCallback((index, data) => {
    const contactsData = data[index];
    if (!contactsData) {
      return `noDataAvailable-${index}`;
    }
    return `${data[index].id}${index}`;
  }, []);

  const removeDuplicateSelectEdContactsList = () => {
    return selectedContactList.reduce((acc, contact) => {
      const phoneNumber = contact.phoneNumber;
      acc[phoneNumber] = contact.name;
      return acc;
    }, {});
  };

  const sendInvites = async () => {
    setSendInvitesLoader(true);
    trackAnalytics({
      eventName: 'contacts_referred',
      props: {
        ...Cache.getReferralAnalyticsObject(),
        contacts_count: selectedContactList.length,
      },
    });
    try {
      const sendSelectedContactsList = {};
      const removedDuplicatePhoneNumbers = removeDuplicateSelectEdContactsList();
      sendSelectedContactsList.invitees = removedDuplicatePhoneNumbers;
      await network.sendInvites(sendSelectedContactsList);
      if (selectAll) {
        setSelectAll(!selectAll);
      }
      contactsList.forEach((contacts) => {
        contacts.checked = false;
      });
      setSelectedContactList([]);
      setToastState({
        open: true,
        message: t('invited_successfully'),
      });
      setSendInvitesLoader(false);
    } catch (error) {
      setSendInvitesLoader(false);
      setToastState({
        open: true,
        message: t('something_went_wrong_could_not_send_the_invites_please_try_again_later'),
      });
    }
    if (selectAll) {
      setSelectAll(!selectAll);
    }
    contactsList.forEach((contacts) => {
      contacts.checked = false;
    });
    setSelectedContactList([]);
  };

  const renderContactsList = () => {
    return (
      <div
        className="contactListContainer"
        style={{ height: selectedContactList && selectedContactList.length > 0 ? 'calc(100% - 61px - 60px)' : undefined }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemData={!expandSearchBox ? contactsList : searchResults}
              itemCount={!expandSearchBox ? contactsList.length : searchResults.length}
              itemKey={itemKey}
              itemSize={80}
              width={width}
              overscanCount={5}
            >
              {renderRow}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  };

  const renderInviteNowBar = () => {
    return (
      <div className="inviteNowBarContainer" onClick={sendInvites}>
        <div className="inviteNowTextAndSubtitle">
          <div className="title">{t('invite_now').toUpperCase()}</div>
          <div className="subtitle">
            {t(selectedContactList.length === 1 ? 'get_free_day' : 'get_free_days', {
              count: selectedContactList.length
            })}
          </div>
        </div>
        <div className="rightArrow">
          <RightArrow />
        </div>
      </div>
    );
  };

  return (
    <div className="selectContactsContainer">
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : sendInvitesLoader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <>
          {!expandSearchBox && contactsList.length > 0 && (
            <div className="selectAllDeselectAllContainer" onClick={changeSelectAll}>
              <CustomCheckbox
                key={t('select_all')}
                checked={selectAll}
                label={setLabel()}
                labelStyleClassName="labelStyle"
                checkBoxStyle={classes.checkBoxStyle}
                formControlLabel={classes.formControlLabel}
              />
            </div>
          )}

          {renderContactsList()}
          {selectedContactList && selectedContactList.length > 0 && renderInviteNowBar()}
        </>
      )}
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
};

export default SelectContacts;
