import React from 'react';

export default ({ color = '#FFFFFF', width = 18, height = 28, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 28"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Android-Copy-4"
          transform="translate(-84.000000, -579.000000)"
          fill={color}
          fillrule="nonzero"
        >
          <g id="icons8-flash_on" transform="translate(84.000000, 579.000000)">
            <path
              d="M4.69562142,27.486968 C4.59685991,27.486968 4.49562952,27.4647462 4.4018064,27.4153654 C4.1326808,27.2721616 4.00429083,26.9610625 4.0931762,26.6721852 L7.26589027,16.2725979 L0.631585492,16.2725979 C0.38715059,16.2725979 0.167406382,16.1318629 0.0612375983,15.9145876 C-0.042461674,15.6948434 -0.0128332202,15.4355939 0.137777929,15.2454782 L12.1792752,0.236197135 C12.369391,0.00163901681 12.695304,-0.0674940421 12.96196,0.0707720757 C13.2310856,0.209038193 13.3619445,0.515198883 13.2804664,0.804076149 L10.3200899,11.2160085 L16.7074909,11.2160085 C16.9494563,11.2160085 17.17167,11.3542746 17.2753693,11.5740188 C17.381538,11.7937636 17.3519096,12.0530124 17.2012984,12.2431282 L5.18942899,27.2499404 C5.06597741,27.4054893 4.8832683,27.486968 4.69562142,27.486968 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
