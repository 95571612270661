import cacheRequest from 'qs-data/cacheRequest';
import checkoutFormFields from 'qs-data/checkoutFormFields';
import { getCheckoutFormFieldsDataCacheKey } from './helpers';
import {
  ADD_NEW_FORM_FIELDS_TO_LIST,
  FETCH_FORM_FIELD_DATA,
  FETCH_FORM_FIELD_DATA_FAILURE,
  FETCH_FORM_FIELD_DATA_SUCCESS,
  REMOVE_FORM_FIELD,
  SORT_FORM_FIELDS_DATA,
  TOGGLE_VISIBILITY_FORM_FIELD,
  UPDATE_FORM_FIELDS_DATA,
  UPDATE_FORM_FIELDS_DATA_FAILURE,
  UPDATE_FORM_FIELDS_DATA_SUCCESS,
} from './reducer';

const listItemCountMap = new Map();

export const fetchFormFieldComponents = (dispatchCheckoutFormsData) => {
  dispatchCheckoutFormsData({ type: FETCH_FORM_FIELD_DATA });
  const listener = (error, response) => {
    if (error) {
      dispatchCheckoutFormsData({ type: FETCH_FORM_FIELD_DATA_FAILURE, error });
      return;
    }
    const { data } = response;
    if (!data) {
      return;
    }
    dispatchCheckoutFormsData({ type: FETCH_FORM_FIELD_DATA_SUCCESS, data });
    listItemCountMap.clear();
  };
  cacheRequest.attachListener(getCheckoutFormFieldsDataCacheKey(), listener);
  cacheRequest.makeRequest(
    getCheckoutFormFieldsDataCacheKey(),
    checkoutFormFields.getCheckoutFields
  );
  return () => cacheRequest.removeListener(getCheckoutFormFieldsDataCacheKey(), listener);
};

export const addNewFormFieldComponent = (newFormField, formFields, dispatchCheckoutFormsData, callbackFn) => {
  const { id, fieldName, fieldType, fieldOptions = [], required, visibility } = newFormField;

  const formFieldApiParams = {
    id: undefined,
    fieldName,
    fieldType,
    fieldOptions,
    required,
    visibility,
  };

  let apiFunction = checkoutFormFields.addCheckoutField;
  let successMessage = 'checkout_field_added';

  const editingTheExistingField = !!formFields.find(
    ({ id: formFieldId }) => newFormField.id === formFieldId
  );
  // Only call the update api
  if (editingTheExistingField) {
    formFieldApiParams.id = id;
    apiFunction = checkoutFormFields.updateCheckoutField;
    successMessage = 'updated_the_checkout_field';
  }

  dispatchCheckoutFormsData({ type: UPDATE_FORM_FIELDS_DATA });
  return apiFunction(formFieldApiParams)
    .then((response) => {
      const { insertedIds = [] } = response;
      // Will handle the editing the existing field case inside the reducer;
      dispatchCheckoutFormsData({
        type: ADD_NEW_FORM_FIELDS_TO_LIST,
        insertedId: insertedIds[0],
        formField: newFormField,
      });
      callbackFn(successMessage);
    })
    .catch((error) =>
      dispatchCheckoutFormsData({
        type: UPDATE_FORM_FIELDS_DATA_FAILURE,
        keepCurrentFormStateSame: true,
        error,
      })
    );
};

export const updateFormFieldComponents = (formField, dispatchCheckoutFormsData, showSnackbarMessage) => {
  const { id, fieldName, fieldType, fieldOptions, required, visibility } = formField;

  dispatchCheckoutFormsData({ type: TOGGLE_VISIBILITY_FORM_FIELD, fieldId: id, formField, refreshing: true });
  return checkoutFormFields
    .updateCheckoutField({
      id,
      fieldName,
      fieldType,
      fieldOptions,
      required,
      visibility: !visibility,
    })
    .then(() => {
      dispatchCheckoutFormsData({ type: UPDATE_FORM_FIELDS_DATA_SUCCESS });
      showSnackbarMessage(`Checkout field marked as ${visibility ? 'hidden' : 'visible'}`);
    })
    .catch(() =>
      dispatchCheckoutFormsData({
        type: TOGGLE_VISIBILITY_FORM_FIELD,
        fieldId: id,
        error: true,
        refreshing: false,
      })
    );
};

export const deleteFormFieldComponent = ({ id: fieldId }, dispatchCheckoutFormsData, showSnackbarMessage) => {
  dispatchCheckoutFormsData({ type: UPDATE_FORM_FIELDS_DATA });
  return checkoutFormFields
    .deleteCheckoutField(fieldId)
    .then(() => {
      dispatchCheckoutFormsData({ type: REMOVE_FORM_FIELD, fieldId });
      showSnackbarMessage('Checkout field deleted');
    })
    .catch((error) => dispatchCheckoutFormsData({ type: UPDATE_FORM_FIELDS_DATA_FAILURE, error }));
};

const updateCheckoutFormFieldPositions = formFields => {
  const updatedFieldPositions = {};
  formFields.forEach((checkoutFormField, newPosition) => {
    if (checkoutFormField.canReorder) {
      updatedFieldPositions[checkoutFormField.id] = newPosition;
    }
  })
  return updatedFieldPositions;
};

export const changeOrderCheckoutFields = (
  { formFields, oldIndex, newIndex },
  dispatchCheckoutFormsData,
  showSnackbarMessage
) => {
  if (newIndex === oldIndex) {
    // Avoiding api call in case of moving to same the index
    return Promise.resolve();
  }
  dispatchCheckoutFormsData({ type: SORT_FORM_FIELDS_DATA, oldIndex, newIndex });
  return checkoutFormFields
    .reorderCheckoutFields(updateCheckoutFormFieldPositions(formFields))
    .then(() => {
      dispatchCheckoutFormsData({ type: UPDATE_FORM_FIELDS_DATA_SUCCESS });
      showSnackbarMessage('Updated the order of the field');
    })
    .catch((error) =>
      dispatchCheckoutFormsData({ type: SORT_FORM_FIELDS_DATA, oldIndex: newIndex, newIndex: oldIndex, error })
    );
};
