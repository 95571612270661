import ApiWrapper from './apiWrapper';

let apis = {
  trackEvent: '/v1/analytics/track'
};

/* Analytics track apis */

const trackEvent = ({eventName, source}) => {
  return ApiWrapper.postDataPromise(apis.trackEvent, {
    event: eventName,
    property : {
      source: source
    }
  }, null);
};

export default {
  trackEvent
}