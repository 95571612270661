import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PRIMARY_COLOR, SECONDARY_COLOR, BACKGROUND_COLOR } from './constants';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import Loader from 'qs-common/Loader';
import network from 'qs-data/network';
import Toast from 'qs-common/Alerts/Toast';
import CustomErrorComponent from '../../common/CustomErrorComponent';
import DialogBox from 'qs-common/DialogBox';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { ReactComponent as WebhookIcon } from 'qs-assets/images/ic_webhooks.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { getToken } from '../../data/util';
import { getI18N } from '../../i18N';

import './style.scss';

const NAVBAR_HEIGHT = 55;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: SECONDARY_COLOR
    },
    secondary: {
      main: PRIMARY_COLOR
    },
    background: {
      paper: BACKGROUND_COLOR,
      default: BACKGROUND_COLOR
    },
    text: {
      color: SECONDARY_COLOR
    },
    type: 'dark'
  }
});

function Webhooks() {
  const [webhooksList, setWebhooksList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSelectedWebhook, setCurrentSelectedWebhook] = useState({ webhookUrl: '', events: [] });
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [toastState, setToastState] = useState({ open: false, message: '' });
  const history = useHistory();
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [errorComponent, setErrorComponent] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        title: t('webhooks'),
        height: NAVBAR_HEIGHT,
        hideBack: desktop,
        actions: desktop ? renderDesktopCreateWebhooksButton() : null,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });

    setNavigationBarColor('#242c36');
    setStatusBarColor(PRIMARY_COLOR);

    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop, loading]);

  const showWebhookDeleteConfirmDialog = () => {
    setDeleteDialogVisible(true);
  };

  const closeWebhookDeleteConfirmDialog = () => {
    setDeleteDialogVisible(false);
  };

  const getWebhooksList = async () => {
    try {
      setLoading(true);
      const webhooksList = await network.getWebhooks();
      setWebhooksList(webhooksList);
      setErrorComponent(false);
    } catch (error) {
      setToastState({ open: true, message: t('error_while_fetching_webhooks') });
      setErrorComponent(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWebhooksList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDesktopCreateWebhooksButton = () => {
    if (!desktop) {
      return null;
    }
    return (
      <div className="create-webhooks-button-container">
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => history.push(`/webhooks/create?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
          className="create-webhooks-button"
        >
          {t('create_webhook')}
        </Button>
      </div>
    );
  };

  const renderMobileCreateWebhooksButton = () => {
    if (desktop) {
      return null;
    }
    return (
      <div className="create-webhooks-button-container">
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={loading}
          startIcon={loading ? (
            <Loader small={true} />
          ) : (
            <WebhookIcon className="create-new-webhook-button-icon" height={25} width={25} />
          )}
          onClick={() => history.push(`/webhooks/create?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
          className="create-webhooks-button create-webhooks-button-mobile"
        >
          {t('create_webhook')}
        </Button>
      </div>
    );
  };

  const renderWebhooksList = () => {
    if (!webhooksList || webhooksList.length === 0) {
      return null;
    }
    return (
      <List className="create-webhooks-list">
        {webhooksList.map(({ webhookUrl, events }, index) => {
          return (
            <ListItem key={index} divider={false} className="create-webhooks-list-item">
              <IconButton
                disabled={loading}
                classes={{ root: 'create-webhooks-list-item-icon-button', label: 'create-webhooks-list-item-icon-button-label' }}
                component="div"
                onClick={() => history.push(`/webhooks/edit/${encodeURIComponent(webhookUrl)}?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
              >
                <ListItemText
                  primary={webhookUrl}
                  secondary={
                    !events || events.length === 0
                      ? t('no_events') :
                      events.length === 1 ? t('number_of_event', { count : events.length }) : t('number_of_events', { count : events.length })
                  }
                  classes={{
                    root: 'create-webhooks-list-item-text',
                    primary: 'create-webhooks-list-item-primary',
                    secondary: 'create-webhooks-list-item-secondary',
                  }}
                />
                <div className="create-webhooks-list-item-actions">
                  <div className="create-webhooks-list-item-action">
                    <IconButton
                      edge="end"
                      disabled={loading}
                      onClick={(event) => {
                        event.stopPropagation();
                        history.push(`/webhooks/edit/${encodeURIComponent(webhookUrl)}?token=${getToken()}&source=${source}&languageCode=${languageCode}`);
                      }}
                      className="create-webhooks-list-item-action-icon"
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                  <div className="create-webhooks-list-item-action">
                    <IconButton
                      edge="end"
                      disabled={loading}
                      onClick={(event) => {
                        event.stopPropagation();
                        setCurrentSelectedWebhook({ webhookUrl, events });
                        showWebhookDeleteConfirmDialog();
                      }}
                      className="create-webhooks-list-item-action-icon"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </IconButton>
              {index < webhooksList.length - 1 && (
                <Divider className="create-webhooks-list-item-divider" />
              )}
            </ListItem>
          );
        })}
      </List>
    );
  };

  const deleteWebhook = async () => {
    try {
      setLoading(true);
      const url = currentSelectedWebhook.webhookUrl;
      const updatedList = webhooksList.filter((webhooks) => webhooks.webhookUrl !== url);
      setWebhooksList(updatedList);
      await network.deleteWebhook({ webhookUrls: [url] });
    } catch (error) {
      setToastState({ open: true, message: t('error_while_deleting_the_webhook') });
    } finally {
      closeWebhookDeleteConfirmDialog();
      setLoading(false);
    }
  };

  const renderDeleteWebhookDialog = () => {
    return (
      <DialogBox
        type="simple"
        show={deleteDialogVisible}
        width={400}
        primaryBtnText={t('delete').toUpperCase()}
        secondaryBtnText={t('cancel').toUpperCase()}
        onSuccess={deleteWebhook}
        onClose={closeWebhookDeleteConfirmDialog}
        showProgressBar={loading}
        dialogId="#delete-webhook-dialog"
      >
        <div className="delete-webhook-dialog-container">
          <p className="delete-webhook-dialog-text">
            {t('are_you_sure_you_want_to_delete_this_webhook')}
          </p>
        </div>
      </DialogBox>
    );
  };

  const renderToast = () => {
    return (
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({ open: false, message: '' });
        }}
      />
    );
  };

  if (loading) {
    return (
      <div className="create-webhooks-container-loader">
        <Loader />
      </div>
    );
  }

  if (errorComponent) {
    return (
      <div className="create-webhooks-container-error">
        <CustomErrorComponent onRetryClick={getWebhooksList} />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className={`create-webhooks-container ${desktop ? 'desktop' : 'mobile'}`}>
        {(!webhooksList || webhooksList.length === 0) ? (
          <div className="empty-webhooks-container">
            <Typography className="create-webhooks-heading" color="primary">
              {t('no_webhooks_added')}
            </Typography>
            {desktop && (
              <div className="create-webhooks-button-container">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  startIcon={
                    loading ? (
                      <Loader small={true} />
                    ) : (
                      <WebhookIcon className="create-new-webhook-button-icon" height={25} width={25} />
                    )
                  }
                  onClick={() => history.push(`/webhooks/create?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
                  className="create-webhooks-button"
                >
                  {t('create_webhook')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          renderWebhooksList()
        )}
        {renderMobileCreateWebhooksButton()}
        {renderDeleteWebhookDialog()}
        {renderToast()}
      </Box>
    </ThemeProvider>
  );
}

export default Webhooks;
