import React from 'react';

export default function ({ color, size  }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			version="1.1"
			fill={color || '#3833EA'}>
			<g id="surface1" fill={color || '#3833EA'}>
				<path d="M 21.1875 9.28125 L 19.78125 10.71875 L 24.0625 15 L 4 15 L 4 17 L 24.0625 17 L 19.78125 21.28125 L 21.1875 22.71875 L 27.90625 16 Z "/>
			</g>
		</svg>
	);
}