import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { useAppContext } from '../../common/Contexts/AppContext';
import {
  setNavigationBarColor,
  setStatusBarColor,
  isFeatureAllowed,
  showPremiumFeatureDialog,
  postProductType,
} from '../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { withRouter } from 'react-router-dom';
import Toast from '../../common/Alerts/Toast';
import EditIcon from '@material-ui/icons/Edit';
import DialogBox from 'qs-common/DialogBox';
import DiamondLogo from 'qs-assets/images/diamonds.png';
import basicLogo from 'qs-assets/images/basicProduct.png';
import {
  Box,
  List,
  ListItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import productTypeAPI from 'qs-data/productType';
import { PRODUCT_TYPE_FEATURE } from 'qs-helpers/utility';
import { getI18N } from '../../i18N';
import './style.scss';

const greenBackgroundColor = '#4DA47A';
const pageBackgroundColor = '#242C36';
let POP_STATE_COUNTER = 0;

const useStyles = makeStyles({
  radioRoot: {
    padding: 9,
  },
  radioView: {
    color: '#ffffff',
    '&$checked': {
      color: greenBackgroundColor,
    },
    '&$disabled': {
      color: greenBackgroundColor,
    },
  },
  checked: {},
  disabled: {},
});

function ProductType() {
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const NAVBAR_HEIGHT = 55;
  const [showDialog, setShowDialog] = useState(false);
  const [productType, setProductType] = useState();
  const [savedType, setSavedType] = useState();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const componentStyles = useStyles();

  const { t } = getI18N();

  const productOptions = [
    { type: 'BASIC', name: t('default'), logo: basicLogo, size: '25px' },
    { type: 'JEWELLERY', name: t('jewellery'), logo: DiamondLogo, size: '35px' },
  ];

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: greenBackgroundColor,
        color: '#FFFFFF',
        title: t('product_type'),
        hideBack: desktop,
      },
    });
    setNavigationBarColor(pageBackgroundColor);
    setStatusBarColor(greenBackgroundColor);
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
        backgroundColor: pageBackgroundColor,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetchProductType = useCallback(() => {
    setLoading(true);
    productTypeAPI
      .getProductType()
      .then((type) => {
        setProductType(type);
        setSavedType(type);
        setLoading(false);
      })
      .catch(() => {
        setToastState({ open: true, message: t('error_while_fetching_product_type_please_retry') });
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    fetchProductType();
  }, [fetchProductType]);

  const popStateListener = () => {
    if (POP_STATE_COUNTER === 0) {
      POP_STATE_COUNTER++;
    } else {
      onClose();
      POP_STATE_COUNTER = 0;
    }
  };

  useEffect(() => {
    window.addEventListener('hashchange', popStateListener);
    return () => {
      window.removeEventListener('hashchange', popStateListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProductType = async () => {
    let featureAllowed = await isFeatureAllowed(PRODUCT_TYPE_FEATURE);
    if (productType === 'BASIC') {
      featureAllowed = true;
    }
    if (featureAllowed) {
      setDisable(true);
      productTypeAPI
        .setProductType({ productType: productType })
        .then(() => {
          postProductType(productType);
          setShowDialog(false);
          setDisable(false);
          fetchProductType();
          setToastState({ open: true, message: t('product_type_updated_successfully') });
        })
        .catch(() => {
          setShowDialog(false);
          setDisable(false);
          setToastState({ open: true, message: t('error_while_updating_product_type') });
        });
    } else {
      setShowDialog(false);
      showPremiumFeatureDialog(PRODUCT_TYPE_FEATURE);
    }
  };

  return (
    <div>
      <Box className="product-type-container">
        <section
          className={`settings-data-container 
        ${loading ? 'settings-data--disable' : ''}`}
          onClick={() => setShowDialog(true)}
        >
          <div className="settings-data-row-container">
            <div className="settings-data-text-container">
              <h4 className="settings-data-text--title">{t('select_product_type')}</h4>
              <div className="settings-data-text--description">
                {savedType
                  ? `${t('current_product_type')} :
                    ${productOptions.find((item) => item.type === savedType)?.name}`
                  : t('click_to_change_product_type')}
              </div>
            </div>
            <EditIcon style={{ color: '#ffffff' }} />
          </div>
        </section>
      </Box>
      {showDialog && (
        <DialogBox
          type={'simple'}
          show={showDialog}
          onClose={onClose}
          width={300}
          primaryBtnText={t('save')}
          secondaryBtnText={t('cancel')}
          disabledPrimaryButton={disable}
          onSuccess={updateProductType}
        >
          <Fragment>
            <div style={{ borderBottom: 'solid 1px', borderBottomColor: '#3F454F' }}>
              <p className="dialogHeader">{t('select_product_type')}</p>
              <List
                style={{
                  margin: '0px 20px',
                  padding: '10px 0px 0px 0px',
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  {productOptions.map((options) => {
                    return (
                      <ListItem
                        key={options}
                        divider={!(options === productOptions[productOptions.length - 1])}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          padding: '5px 0px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setProductType(options.type)}
                      >
                        <FormControlLabel
                          value={options.type}
                          control={
                            <Radio
                              classes={{
                                colorSecondary: componentStyles.radioView,
                                checked: componentStyles.checked,
                                disabled: componentStyles.disabled,
                                root: componentStyles.radioRoot,
                              }}
                            />
                          }
                          label={options.name}
                        />
                        <div className="logoContainer">
                          <img src={options.logo} alt="options icon" style={{ width: options.size }} />
                        </div>
                      </ListItem>
                    );
                  })}
                </RadioGroup>
              </List>
            </div>
          </Fragment>
        </DialogBox>
      )}
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}

export default withRouter(ProductType);
