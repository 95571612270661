import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  BACKGROUND_COLOR,
  PAGE_TITLES,
  PAGE_DESCRIPTIONS,
  PAGE_TYPE,
  PRIVACY_POLICY_FIELDS,
} from '../constants';
import otherPagesData from 'qs-data/otherPages';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import { getTemplate } from './getTemplate';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import Toast from 'qs-common/Alerts/Toast';
import { getI18N } from '../../../i18N';

import './style.scss';

const NAVBAR_HEIGHT = 55;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: SECONDARY_COLOR,
    },
    secondary: {
      main: PRIMARY_COLOR,
    },
    background: {
      paper: BACKGROUND_COLOR,
      default: BACKGROUND_COLOR,
    },
    text: {
      color: SECONDARY_COLOR,
    },
    type: 'dark',
  },
});

const useStyles = makeStyles({
  navBtn: {
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: SECONDARY_COLOR,
    color: PRIMARY_COLOR,
    height: 38,
    '&:hover, &:focus, &:active': {
      backgroundColor: SECONDARY_COLOR,
      color: PRIMARY_COLOR,
    },
  },
  title: {
    fontSize: 17,
    color: SECONDARY_COLOR,
  },
});

function OtherPage() {
  const { t } = getI18N();
  const { pageType } = useParams();
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const [textContent, setTextContent] = useState('');
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [formFields, setFromFields] = useState({});
  const [erroredFormFields, setErroredFormFields] = useState({});

  const classes = useStyles();

  const getOtherPageCompanyDetails = async () => {
    setProgressBar(true);
    try {
      const value = await otherPagesData.getOtherPageCompanyDetails(pageType);
      setTextContent(value || '');
    } catch (error) {
      showErrorComponent(true);
    } finally {
      setProgressBar(false);
    }
  };

  const setOtherPageCompanyDetails = useCallback(async () => {
    setProgressBar(true);
    try {
      await otherPagesData.setOtherPageCompanyDetails(pageType, textContent || '');
      setToastState({
        open: true,
        message: t('page_title_saved_successfully', { pageTitle: t(PAGE_TITLES[pageType]) }),
      });
    } catch (error) {
      showErrorComponent(true);
    } finally {
      setProgressBar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType, textContent]);

  const saveButton = useCallback(() => {
    return (
      <Button
        variant="contained"
        color="primary"
        className={`${classes.navBtn} btn-top`}
        disabled={!pageType || progressBar}
        onClick={setOtherPageCompanyDetails}
      >
        {t('save')}
      </Button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType, progressBar, setOtherPageCompanyDetails]);

  const [, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        title: t(PAGE_TITLES[pageType]),
        height: NAVBAR_HEIGHT,
        hideBack: false,
        actions: saveButton(),
        maxWidth: 'calc(100% - 118px)',
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor(PRIMARY_COLOR);
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageType, classes.navBtn, saveButton]);

  useEffect(() => {
    if (Object.keys(PAGE_TYPE).includes(pageType)) {
      getOtherPageCompanyDetails();
    } else {
      setToastState({
        open: true,
        message: t('something_went_wrong_please_try_again'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  const useFocus = () => {
    const htmlElRef = useRef();
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();

  const onChangeFormField =
    (fieldKey) =>
    (...args) => {
      if (args) {
        const fieldValue = args[0].target.value.trim();
        setErroredFormFields({
          ...erroredFormFields,
          [fieldKey]: !fieldValue,
        });
        const updatedFormFields = {
          ...formFields,
          [fieldKey]: fieldValue,
        };
        setFromFields(updatedFormFields);
      }
    };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const onSubmitForm = () => {
    if (pageType === PAGE_TYPE.PRIVACY_POLICY) {
      //handle privacy policy generation
      const erroredFields = {};
      const fields = Object.keys(PRIVACY_POLICY_FIELDS);

      fields.forEach((fieldKey) => {
        if (formFields[PRIVACY_POLICY_FIELDS[fieldKey]]) {
          delete erroredFormFields[PRIVACY_POLICY_FIELDS[fieldKey]];
          return;
        }

        erroredFields[PRIVACY_POLICY_FIELDS[fieldKey]] = true;
      });

      const firstErroredField = fields.find(
        (fieldKey) => erroredFields[PRIVACY_POLICY_FIELDS[fieldKey]]
      );
      setErroredFormFields(erroredFields);
      if (firstErroredField) {
        setInputFocus();
        return;
      }

      const generatedPolicy = getTemplate(
        formFields[PRIVACY_POLICY_FIELDS.COMPANY],
        formFields[PRIVACY_POLICY_FIELDS.BRAND],
        formFields[PRIVACY_POLICY_FIELDS.URL]
      );
      setTextContent(generatedPolicy);
    }
  };

  const renderForm = () => {
    if (pageType !== PAGE_TYPE.PRIVACY_POLICY) {
      return;
    }

    const commonProps = {
      className: 'page-form-field',
      InputLabelProps: {
        shrink: true,
      },
      inputProps: {
        className: 'page-form-field-input',
      },
    };
    return (
      <>
        <form
          className="page-form"
          name="page-form"
          action="/"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TextField
            required
            autoFocus
            placeholder={t('please_enter_form_fields', { field: t(PRIVACY_POLICY_FIELDS.COMPANY) })}
            title={t('please_enter_form_fields', { field: t(PRIVACY_POLICY_FIELDS.COMPANY) })}
            label={t(PRIVACY_POLICY_FIELDS.COMPANY)}
            ref={inputRef[PRIVACY_POLICY_FIELDS.COMPANY]}
            error={erroredFormFields[PRIVACY_POLICY_FIELDS.COMPANY]}
            helperText={erroredFormFields[PRIVACY_POLICY_FIELDS.COMPANY] ? t('required_field') : ''}
            onChange={onChangeFormField(PRIVACY_POLICY_FIELDS.COMPANY)}
            onKeyDown={handleEnter}
            {...commonProps}
          />
          <TextField
            required
            placeholder={t('please_enter_form_fields', { field: t(PRIVACY_POLICY_FIELDS.BRAND) })}
            title={t('please_enter_form_fields', { field: t(PRIVACY_POLICY_FIELDS.BRAND) })}
            label={t(PRIVACY_POLICY_FIELDS.BRAND)}
            ref={inputRef[PRIVACY_POLICY_FIELDS.BRAND]}
            error={erroredFormFields[PRIVACY_POLICY_FIELDS.BRAND]}
            helperText={erroredFormFields[PRIVACY_POLICY_FIELDS.BRAND] ? t('required_field') : ''}
            onChange={onChangeFormField(PRIVACY_POLICY_FIELDS.BRAND)}
            onKeyDown={handleEnter}
            {...commonProps}
          />
          <TextField
            required
            placeholder={t('please_enter_form_fields', { field: t(PRIVACY_POLICY_FIELDS.URL) })}
            title={t('please_enter_form_fields', { field: t(PRIVACY_POLICY_FIELDS.URL) })}
            label={t(PRIVACY_POLICY_FIELDS.URL)}
            ref={inputRef[PRIVACY_POLICY_FIELDS.URL]}
            error={erroredFormFields[PRIVACY_POLICY_FIELDS.URL]}
            helperText={erroredFormFields[PRIVACY_POLICY_FIELDS.URL] ? t('required_field') : ''}
            onChange={onChangeFormField(PRIVACY_POLICY_FIELDS.URL)}
            {...commonProps}
          />

          <Button
            type="submit"
            className="page-form-submit"
            variant="contained"
            color="secondary"
            onClick={onSubmitForm}
          >
            {t('generate')}
          </Button>
        </form>
        <Typography color="textSecondary" className={classes.title} style={{ paddingBottom: 1 }}>
          {t(PAGE_TITLES[PAGE_TYPE.PRIVACY_POLICY])}
        </Typography>
      </>
    );
  };

  const renderContent = () => {
    if (progressBar) {
      return <CircularProgress size={36} color="primary" style={{ marginTop: '6px' }} />;
    }

    if (errorComponent) {
      return <CustomErrorComponent onRetryClick={getOtherPageCompanyDetails} />;
    }

    return (
      <Fragment>
        <Typography
          color="textSecondary"
          className={classes.title}
          style={{ marginBottom: '15px' }}
        >
          {t(PAGE_DESCRIPTIONS[pageType])}
        </Typography>
        {renderForm()}
        <TextareaAutosize
          placeholder={t('start_typing_text_or_html')}
          readOnly={progressBar}
          disabled={progressBar}
          value={textContent}
          onChange={(event) => setTextContent(event.target.value || '')}
        />
      </Fragment>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={`other-page-container ${progressBar ? 'loading' : ''}`}>
        {renderContent()}
      </Box>
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </ThemeProvider>
  );
}

export default OtherPage;
