import React, { useState, useCallback, useEffect } from 'react';
import './styles.scss';
import {
  Button,
  Checkbox,
  Select,
  MenuItem,
  InputBase,
  makeStyles,
  FormControlLabel,
  FormControl,
  InputLabel,
  Typography,
  createMuiTheme,
  ThemeProvider,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { updateCoupon, createCoupon, getCouponData, getCouponMetaData } from 'qs-data/coupons';
import { withStyles } from '@material-ui/core/styles';
import { codeGenerator, getChangesForUpdate } from 'qs-helpers/coupons';
import CompanyData from 'qs-data/companyData';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Loader from 'qs-common/Loader';
import { useParams, useHistory } from 'react-router-dom';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import Toast from 'qs-common/Alerts/Toast';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { getI18N } from '../../../i18N';

const materialTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#49a579',
    },
    secondary: {
      main: '#fff',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#000',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#252B36',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: '#252B36',
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: '5px',
        marginTop: '5px',
      },
    },
  },
});
const useStyles = makeStyles({
  inputLabel: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20,
    marginLeft: 0,
    transform: 'translate(0, -1.5px) scale(0.75)',
  },
  checkBox: {
    color: '#2f9644 !important',
  },

  label: {
    fontSize: 15,
    color: '#fff',
    fontWeight: 500,
  },
  typography: {
    color: '#fff',
    flex: '0 1 150px',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 5,
    lineHeight: 'normal',
  },

  icon: {
    fill: '#fff',
  },
  menu: {
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2c2c2c',
    },
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&$focused': {
      color: 'transparent',
    },
  },
  input: {
    position: 'relative',
    borderBottom: '1px solid #414a5a',
    fontSize: 16,
    fontWeight: 400,
    padding: '5px 26px 5px 2px',
    color: '#fff',
    outline: 0,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);

export default () => {
  const { action, code } = useParams();
  const history = useHistory();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const couponData = getCouponMetaData(code) || null;

  const { t } = getI18N();

  const [couponName, setCouponName] = useState(couponData ? couponData.name : '');
  const [errorName, setErrorName] = useState(false);
  const [couponCode, setCouponCode] = useState(action === 'create' ? codeGenerator(4) : code);
  const [errorCode, setErrorCode] = useState(false);
  const [discount, setDiscount] = useState(
    couponData && couponData.discountPercent ? couponData.discountPercent.toString() : ''
  );
  const [errorDiscount, setErrorDiscount] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState(
    couponData ? couponData.applyOnDiscountedPrice : true
  );
  const [couponType, setCouponType] = useState(
    couponData && couponData.discountAmount ? 'flat' : 'percent'
  );
  const [couponAmount, setCouponAmount] = useState(
    `${couponData && couponData.discountAmount ? couponData.discountAmount : ''}`
  );
  const [validTill, setValidTill] = useState(
    couponData && couponData.validTill ? new Date(couponData.validTill) : null
  );
  const [errorValidTill, setErrorValidTill] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [couponUsage, setCouponUsage] = useState(
    couponData && couponData.totalUsageLimit ? couponData.totalUsageLimit.toString() : ''
  );
  const [errorCouponUsage, setErrorCouponUsage] = useState(false);
  const [perUser, setPerUser] = useState(
    couponData && couponData.perUserLimit ? couponData.perUserLimit.toString() : ''
  );
  const [perUserError, setPerUserError] = useState(false);
  const [usageError, setUsageError] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [operationLoader, setOperationLoader] = useState(false);
  const [updateData, setUpdateData] = useState(couponData ? couponData : {});

  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });

  const [minimumOrderAmount, setMinimumOrderAmount] = useState(
    couponData && couponData.minimumOrderAmount ? couponData.minimumOrderAmount.toString() : ''
  );

  const classes = useStyles();

  const [, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#289D75' : '#4DA47A',
        color: '#ffffff',
        title: action === 'create' ? t('create_new_coupon') : t('update_coupon'),
        boxShadow: 'none',
        borderBottom: '1px solid #242C36',
      },
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action]);

  useEffect(() => {
    const getCouponsDataFromCode = async () => {
      if (!code || couponData) {
        return;
      }

      try {
        getCouponData(code).then(({ coupon }) => {
          setUpdateData(coupon);
          setCouponName(coupon ? coupon.name : '');
          setDiscount(coupon && coupon.discountPercent ? coupon.discountPercent.toString() : '');
          setApplyDiscount(coupon ? coupon.applyOnDiscountedPrice : true);
          setCouponType(coupon && coupon.discountAmount ? 'flat' : 'percent');
          setCouponAmount(`${coupon && coupon.discountAmount ? coupon.discountAmount : ''}`);
          setValidTill(coupon && coupon.validTill ? new Date(coupon.validTill) : null);
          setCouponUsage(coupon && coupon.totalUsageLimit ? coupon.totalUsageLimit.toString() : '');
          setPerUser(coupon && coupon.perUserLimit ? coupon.perUserLimit.toString() : '');
          setMinimumOrderAmount(
            coupon && coupon.minimumOrderAmount ? coupon.minimumOrderAmount.toString() : ''
          );
        });
      } catch (err) {
        setToastState({
          message: t('something_went_wrong_please_try_again_later'),
          open: true,
        });
      }
    };

    getCouponsDataFromCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, couponData]);

  const currency = CompanyData.getCompanyCurrencyProperties().symbol;

  // Get tomorrow's date and only keep the day part, remove the time part. This will be used for validation
  // with the input
  const minDate = new Date(new Date().valueOf() + 1000 * 3600 * 24);
  minDate.setUTCHours(0, 0, 0, 0);

  const handleUpdateApi = useCallback(
    async (data) => {
      setOperationLoader(true);
      const { code } = updateData;
      const updates = getChangesForUpdate({ originalData: updateData, inputData: data });
      try {
        await updateCoupon(code, updates);
        setToastState({
          message: t('coupon_updated'),
          open: true,
        });
      } catch (err) {
        setToastState({
          message: t('something_went_wrong_while_updating_this_coupon'),
          open: true,
        });
      } finally {
        setOperationLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateData]
  );

  const handleCreateApi = async (data) => {
    let didErrorOccured = false;
    setOperationLoader(true);
    try {
      await createCoupon(data);
    } catch (err) {
      setToastState({
        message: t('something_went_wrong_while_creating_this_coupon'),
        open: true,
      });
      didErrorOccured = true;
    } finally {
      setOperationLoader(false);
      if (!didErrorOccured) {
        history.goBack();
      }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let errorFlag = false;
    const formData = {
      code: couponCode,
      name: couponName,
      discountPercent: null,
      discountAmount: null,
      applyOnDiscountedPrice: applyDiscount,
      totalUsageLimit: null,
      perUserLimit: null,
      validTill: null,
      minimumOrderAmount: null,
    };

    if (couponName.trim().length < 1) {
      setErrorName(true);
      errorFlag = true;
    }

    if (couponType === 'flat') {
      const trimmedInput = couponAmount.trim();
      const numCouponAmount = Number(trimmedInput);
      if (!trimmedInput || Number.isNaN(numCouponAmount)) {
        setAmountError(true);
        errorFlag = true;
      } else {
        formData.discountAmount = numCouponAmount;
      }
    }

    if (couponType === 'percent') {
      const trimmedInput = discount.trim();
      const numDiscountPercent = Number(trimmedInput);
      if (
        !trimmedInput ||
        Number.isNaN(numDiscountPercent) ||
        numDiscountPercent <= 0 ||
        numDiscountPercent > 100
      ) {
        setErrorDiscount(true);
        errorFlag = true;
      } else {
        formData.discountPercent = numDiscountPercent;
      }
    }

    // Necessary while create
    if (couponCode.trim().length < 1) {
      setErrorCode(true);
      errorFlag = true;
    }

    if (validTill) {
      if (validTill.toString() === 'Invalid Date') {
        errorFlag = true;
        setErrorValidTill(true);
      } else {
        formData.validTill = validTill.toISOString();
      }
    }

    if (couponUsage) {
      const trimmedInput = couponUsage.trim();
      const numCouponUsage = Number(trimmedInput);
      if (!trimmedInput || Number.isNaN(numCouponUsage)) {
        setErrorCouponUsage(true);
        errorFlag = true;
      } else {
        formData.totalUsageLimit = numCouponUsage;
      }
    }

    if (perUser) {
      const trimmedInput = perUser.trim();
      const numPerUser = Number(trimmedInput);
      if (!trimmedInput || Number.isNaN(numPerUser)) {
        setPerUserError(true);
        errorFlag = true;
      } else {
        formData.perUserLimit = numPerUser;
      }
    }

    if (
      typeof formData.perUserLimit === 'number' &&
      typeof formData.totalUsageLimit === 'number' &&
      formData.perUserLimit > formData.totalUsageLimit
    ) {
      errorFlag = true;
      setUsageError(true);
    }

    if (minimumOrderAmount) {
      const trimmedInput = minimumOrderAmount.trim();
      const numMinimumAmount = +trimmedInput;
      if (!trimmedInput || Number.isNaN(numMinimumAmount)) {
        return;
      }
      formData.minimumOrderAmount = +numMinimumAmount.toFixed(2);
    }

    if (!errorFlag) {
      if (action === 'create') {
        return handleCreateApi(formData);
      }
      if (action === 'update') {
        return handleUpdateApi(formData);
      }
    }
  };

  const onChangeType = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value },
    } = e;
    setCouponType(value);
  }, []);

  const onChangeCode = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setErrorCode(false);
    setCouponCode(value);
  }, []);

  const onDateChange = (validTillChange) => {
    if (validTillChange && validTillChange.toString() !== 'Invalid Date') {
      validTillChange.setUTCHours(0, 0, 0, 0);
    }
    setValidTill(validTillChange);
    setErrorValidTill(false);
  };

  const validateNumberOnChange = (value) => {
    return !Number.isNaN(Number(value));
  };

  const onPerUserChange = (event) => {
    const { value } = event.target;
    if (!validateNumberOnChange(value)) {
      return;
    }

    setUsageError(false);
    setPerUserError(false);
    setErrorCouponUsage(false);
    setPerUser(value);
  };

  const onTotalLimitChange = (event) => {
    const { value } = event.target;
    if (!validateNumberOnChange(value)) {
      return;
    }

    setUsageError(false);
    setErrorCouponUsage(false);
    setPerUserError(false);
    setCouponUsage(value);
  };

  const onDiscountAmountChange = (event) => {
    const { value } = event.target;
    if (!validateNumberOnChange(value)) {
      return;
    }
    setAmountError(false);
    setCouponAmount(value);
  };

  const onDiscountPercentChange = (event) => {
    const { value } = event.target;
    if (!validateNumberOnChange(value)) {
      return;
    }
    setErrorDiscount(false);
    setDiscount(value);
  };

  const onMinimumCouponAmountChange = (event) => {
    const { value } = event.target;
    if (!validateNumberOnChange(value) || +value < 0) {
      return;
    }
    setMinimumOrderAmount(value);
  };

  const renderHelpTextForTimesPerCustomer = () => {
    if (perUserError || usageError) {
      return (
        <span className={'createCouponError helpText'}>
          {usageError ? t('times_per_customer_is_greater_than_total_times') : t('invalid_times_chosen')}
        </span>
      );
    }
  };

  const renderHelpTextForTotalTimes = () => {
    if (errorCouponUsage) {
      return <span className={'createCouponError helpText'}>{t('invalid_coupon_usage')}</span>;
    }
  };

  const renderDialogChildren = () => {
    return (
      <form onSubmit={submitForm} className="couponForm" noValidate autoComplete="off">
        <div className={'couponContent'}>
          <div className={'couponInputContainer couponName'}>
            <TextField
              color="secondary"
              label={t('coupon_name')}
              value={couponName}
              placeholder={t('example_wholesale_christmas_reseller')}
              onChange={(event) => {
                setErrorName(false);
                setCouponName(event.target.value);
              }}
              classes={{ root: classes.root }}
              InputProps={{
                disableUnderline: true,
                input: classes.input,
              }}
              InputLabelProps={{
                classes: { formControl: classes.inputLabel },
                shrink: true,
              }}
            />

            {errorName && <span className={'createCouponError helpText'}>{t('invalid_title')}</span>}
            <span className="helpText">
              {t('your_customers_will_be_able_to_see_this_coupon_name_on_the_invoice')}
            </span>
          </div>
          <div className={'couponInputContainer'}>
            <FormControl className={classes.formControl}>
              <InputLabel classes={{ root: classes.inputLabel }} color="secondary">
                {t('coupon_type')}
              </InputLabel>
              <Select
                value={couponType}
                onChange={onChangeType}
                input={<BootstrapInput />}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem classes={{ root: classes.menu }} value="flat">
                  {t('flat_amount')}
                </MenuItem>
                <MenuItem classes={{ root: classes.menu }} value="percent">
                  {t('percentage')}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={'couponCodeDiscount'}>
            <div className={'couponCodeInput couponCodeInputFlex'}>
              <TextField
                color="secondary"
                label={t('code')}
                value={couponCode}
                placeholder={t('enter_coupon_code')}
                onChange={onChangeCode}
                disabled={action !== 'create'}
                className={'inputCouponCode'}
                InputProps={{
                  disableUnderline: true,
                }}
                inputProps={{ maxLength: 4 }}
                InputLabelProps={{
                  classes: { formControl: classes.inputLabel },
                  shrink: true,
                }}
              />
              {errorCode && <span className={'createCouponError helpText'}>{t('invalid_code')}</span>}
            </div>
            {couponType === 'percent' ? (
              <div className={'couponDiscountInput'}>
                <TextField
                  color="secondary"
                  label={t('discount_percent')}
                  value={discount}
                  type="tel"
                  inputMode={'numeric'}
                  placeholder={t('enter_discount_percent')}
                  onChange={onDiscountPercentChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    classes: { formControl: classes.inputLabel },
                    shrink: true,
                  }}
                />
                {errorDiscount && (
                  <span className={'createCouponError helpText'}>{t('invalid_discount_percent')}</span>
                )}
              </div>
            ) : (
              <div className={'couponDiscountInput'}>
                <TextField
                  color="secondary"
                  label={t('discount_amount')}
                  value={couponAmount || ''}
                  type="tel"
                  placeholder={t('enter_discount_amount',{currency})}
                  onChange={onDiscountAmountChange}
                  classes={{ root: classes.root }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                  }}
                  InputLabelProps={{
                    classes: { formControl: classes.inputLabel },
                    shrink: true,
                  }}
                />
                {amountError && (
                  <span className={'createCouponError helpText'}>{t('invalid_discount_amount')}</span>
                )}
              </div>
            )}
          </div>
          <div className={'applyDiscount'}>
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  checked={applyDiscount}
                  classes={{ root: classes.checkBox }}
                  onChange={() => setApplyDiscount(!applyDiscount)}
                />
              }
              label={t('apply_discount_on_discounted_price')}
            />
          </div>
          <div className={'couponCodeDiscount'}>
            <div className={'couponCodeInput'}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  minDate={minDate}
                  disablePast
                  openTo="date"
                  format="dd-MM-yyyy"
                  views={['year', 'month', 'date']}
                  className={classes.dateField}
                  clearable
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    classes: { formControl: classes.inputLabel },
                    shrink: true,
                  }}
                  onClick={() => setIsDatePickerOpen(true)}
                  open={isDatePickerOpen}
                  onClose={() => setIsDatePickerOpen(false)}
                  onChange={onDateChange}
                  invalidDateMessage=""
                  disableToolbar
                  value={validTill}
                  label={t('coupon_valid_till')}
                  color="secondary"
                  placeholder={'dd-mm-yyyy'}
                />
              </MuiPickersUtilsProvider>
              {errorValidTill && <span className={'createCouponError helpText'}>{t('invalid_date')}</span>}
            </div>
          </div>
          <div className="minimumOrderAmount">
            <div className="minimumAmountInputContainer">
              <TextField
                color="secondary"
                value={minimumOrderAmount || ''}
                type="number"
                min={0}
                placeholder={t('minimum_order_amount')}
                className="minimumAmountInput"
                onChange={onMinimumCouponAmountChange}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  classes: { formControl: classes.inputLabel },
                  shrink: true,
                }}
                label={t('minimum_order_amount')}
              />
            </div>
          </div>
          <div className={'couponInputContainer couponUsage'}>
            <div className="couponUsageInput">
              <TextField
                color="secondary"
                label={t('coupon_usage')}
                value={perUser || ''}
                type="tel"
                min={0}
                max={couponUsage}
                placeholder={t('times_per_customer')}
                className={'usageInput'}
                onChange={onPerUserChange}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  classes: { formControl: classes.inputLabel },
                  shrink: true,
                }}
              />
              <Typography classes={{ root: classes.typography }}>{t('times_per_customer')}</Typography>
            </div>
            {renderHelpTextForTimesPerCustomer()}
            <div className="couponUsageInput">
              <TextField
                color="secondary"
                value={couponUsage || ''}
                type="tel"
                min={0}
                placeholder={t('times')}
                className={'usageInput'}
                onChange={onTotalLimitChange}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  classes: { formControl: classes.inputLabel },
                  shrink: true,
                }}
              />
              <Typography classes={{ root: classes.typography }}>{t('times_in_total')}</Typography>
            </div>
            {renderHelpTextForTotalTimes()}
          </div>
        </div>
        <Button
          color={'primary'}
          type="submit"
          className="actionButton"
          variant="outlined"
          onClick={submitForm}
        >
          {operationLoader ? (
            <Loader small style={{ stroke: '#fff' }} />
          ) : (
            <span className="confirmButtonText">{action === 'create' ? t('create') : t('update')}</span>
          )}
        </Button>
      </form>
    );
  };

  return (
    <ThemeProvider theme={materialTheme}>
      <div className="couponFormPage">{renderDialogChildren()}</div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </ThemeProvider>
  );
};
