import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
// import { ReactComponent as EditIcon } from 'qs-assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from 'qs-assets/images/trash.svg';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import Toast from 'qs-common/Alerts/Toast';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useTrapBackButtonClick from 'qs-common/Hooks/useTrapBackButtonClick';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import Loader from 'qs-common/Loader';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import VariantsManagerData from 'qs-data/variantsManager';
import { updateCustomVariantCache } from './helpers';
import { getI18N } from '../../i18N';
import './style.scss';

const NAVBAR_HEIGHT = 55;

const materialTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#49a579',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
  },
});

const useStyles = makeStyles({
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
  dialogButton: {
    fontWeight: 'bold',
    color: 'red',
  },
});

const VariantsManager = () => {
  const [customVariantName, setCustomVariantName] = useState('');
  const [customVariants, setCustomVariants] = useState([]);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [deleteVaraintField, setDeleteVaraintField] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [dialogLoader, setDialogLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const updateVariantId = useRef('');
  const classes = useStyles();
  const { language } = getI18N();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });
  const { t } = getI18N();

  const fetchCustomVariants = useCallback(() => {
    setError(false);
    VariantsManagerData.getCustomVariant()
      .then((optionTypes) => {
        if (optionTypes) {
          setCustomVariants(optionTypes);
        }
      })
      .catch(() => {
        setError(true);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchCustomVariants();
  }, [fetchCustomVariants]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#289D75' : '#4DA47A',
        color: '#ffffff',
        title: t('product_options'),
        boxShadow: 'none',
        borderBottom: '1px solid #242C36',
        hideBack: desktop,
        height: NAVBAR_HEIGHT,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  const cleanState = useCallback(() => {
    updateVariantId.current = '';
    setDialogLoader(false);
    setCustomVariantName('');
    setErrorMessage('');
    setOpenSaveDialog(false);
    setDeleteVaraintField('');
    setOpenDeleteDialog(false);
  }, []);

  useTrapBackButtonClick({
    onBackClick: () => cleanState(),
    shouldTrap: openSaveDialog || openDeleteDialog,
  });

  const openSaveVariantDialog = useCallback((customVariant = '') => {
    if (customVariant.optionType) {
      const { optionType, optionTypeId, translation } = customVariant;
      setCustomVariantName(translation[language] || optionType);
      updateVariantId.current = optionTypeId;
    }
    setOpenSaveDialog(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveCustomVariant = useCallback(
    (e) => {
      e.preventDefault();
      const trimmedVariantName = customVariantName.trim();
      if (!trimmedVariantName || trimmedVariantName.length === 0) {
        setErrorMessage(t('option_name_cant_be_empty'));
        return;
      }
      if (/[[\]{}()<>?]/.test(trimmedVariantName)) {
        setErrorMessage(t('option_name_cant_have_special_characters'));
        return;
      }
      setDialogLoader(true);
      let data;
      if (updateVariantId.current) {
        data = {
          optionTypeId: updateVariantId.current,
          optionType: trimmedVariantName,
        };
      } else {
        data = {
          optionType: trimmedVariantName,
        };
      }

      VariantsManagerData.saveCustomVariant(data)
        .then((data) => {
          let updatedCustomVariants = [...customVariants];
          if (updateVariantId.current) {
            const editedVariantIndex = updatedCustomVariants.findIndex(
              (variant) => variant.optionTypeId === data.optionTypeId
            );
            if (editedVariantIndex !== -1) {
              updatedCustomVariants[editedVariantIndex] = data;
            }
          } else {
            updatedCustomVariants.push(data);
          }

          updateCustomVariantCache(updatedCustomVariants);
          setCustomVariants(updatedCustomVariants);
          setToastState({
            message: t('option_saved_successfully'),
            open: true,
          });
          cleanState();
        })
        .catch((error) => {
          setDialogLoader(false);
          setErrorMessage(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customVariantName, cleanState]
  );

  const renderSaveCustomVariantDialog = useCallback(() => {
    return (
      <Dialog open={openSaveDialog} onClose={cleanState} fullWidth maxWidth="xs">
        <DialogTitle>
          {updateVariantId.current === '' ? t('create_new_option') : t('update_option')}
        </DialogTitle>

        <form onSubmit={saveCustomVariant} noValidate autoComplete="off">
          <DialogContent>
            <>
              <TextField
                autoFocus
                color="secondary"
                label={t('option_name')}
                value={customVariantName}
                placeholder={t('type_option_name')}
                variant="outlined"
                fullWidth
                error={errorMessage}
                helperText={errorMessage && <span>{errorMessage}</span>}
                onChange={(event) => {
                  setErrorMessage('');
                  setCustomVariantName(event.target.value);
                }}
              />
            </>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={cleanState} type="button" color="secondary">
              {t('cancel')}
            </Button>
            <Button
              onClick={(e) => saveCustomVariant(e)}
              disabled={!!errorMessage}
              type="submit"
              color="secondary"
            >
              {dialogLoader ? <Loader small /> : t('save_option')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openSaveDialog,
    customVariantName,
    cleanState,
    errorMessage,
    dialogLoader,
    saveCustomVariant,
    classes.dialogActions,
  ]);

  const openDeleteVariantDialog = useCallback((customVariant = '') => {
    if (customVariant.optionType) {
      const { optionTypeId, translation } = customVariant;
      VariantsManagerData.getCustomVariantProducts(optionTypeId).then(({ count }) => {
        if (count > 0) {
          setToastState({
            message: t('cannot_delete_option', { count }),
            open: true,
          });
          return;
        }
        setCustomVariantName(translation[language]);
        setOpenDeleteDialog(true);
        updateVariantId.current = optionTypeId;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCustomVariant = useCallback(
    (e) => {
      e.preventDefault();
      if (deleteVaraintField !== 'DELETE VARIANT') {
        setErrorMessage(t('mismatch_entry'));
        return;
      }
      setDialogLoader(true);
      let data;
      if (updateVariantId.current) {
        data = {
          optionTypeId: updateVariantId.current,
        };
      }

      VariantsManagerData.deleteCustomVariant(data)
        .then(() => {
          const filterCustomVariants = customVariants.filter(
            (variantName) => variantName.optionTypeId !== data.optionTypeId
          );
          updateCustomVariantCache(filterCustomVariants);
          setCustomVariants(filterCustomVariants);
          setToastState({
            message: t('option_deleted_successfully'),
            open: true,
          });
          cleanState();
        })
        .catch(() => {
          setDialogLoader(false);
          setErrorMessage(t('failed_to_delete_option'));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cleanState, deleteVaraintField]
  );

  const renderDeleteCustomVariantDialog = useCallback(() => {
    return (
      <Dialog open={openDeleteDialog} onClose={cleanState} fullWidth maxWidth="xs">
        <form onSubmit={deleteCustomVariant} noValidate autoComplete="off">
          <DialogContent>
            <p className="deleteDialogTitle">{t('are_you_sure_you_want_to_delete')}</p>

            <>
              <p className="warningText">
                {t(
                  'if_you_delete_this_variant_it_will_delete_across_all_products_and_all_catalogues'
                )}
              </p>
              <p className="warningTextBold">{t('type_delete_varient_below_to_delete')}</p>
              <TextField
                autoFocus
                color="secondary"
                value={deleteVaraintField}
                error={errorMessage}
                helperText={errorMessage && <span>{errorMessage}</span>}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  setErrorMessage('');
                  setDeleteVaraintField(event.target.value);
                }}
              />
            </>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={cleanState} type="button" color="secondary">
              {t('cancel')}
            </Button>
            <Button
              onClick={deleteCustomVariant}
              color="secondary"
              type="submit"
              disabled={!!errorMessage}
              className={classes.dialogButton}
            >
              {dialogLoader ? <Loader small /> : t('delete_variant')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openDeleteDialog,
    cleanState,
    errorMessage,
    dialogLoader,
    deleteCustomVariant,
    deleteVaraintField,
    classes.dialogActions,
    classes.dialogButton,
  ]);

  if (loading) {
    return (
      <div className="alignCenter mg20">
        <Loader style={{ marginTop: 10, marginBottom: 10 }} />
      </div>
    );
  }
  if (error) {
    return (
      <div className="alignCenter errorMessage">
        <CustomErrorComponent onRetryClick={fetchCustomVariants} />
      </div>
    );
  }
  return (
    <div className="customVariantPage">
      <div className="customVariantList">
        {customVariants && customVariants.length > 0 ? (
          customVariants.map((customVariant) => {
            return (
              <div className="customVariantItem" key={customVariant.optionTypeId}>
                <div className="customVariantTitle">{customVariant.translation[language] || customVariant.optionType}</div>
                <div className="customVariantActions">
                  {/* <Button
                    onClick={() => openSaveVariantDialog(customVariant)}
                    className="customVariantActionButton"
                  >
                    <EditIcon fill="#FFF" width="18" height="18" />
                  </Button> */}
                  <Button
                    onClick={() => openDeleteVariantDialog(customVariant)}
                    className="customVariantActionButton"
                  >
                    <DeleteIcon fill="#FFF" width="16" height="16" />
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="noVariantDataMessage">{t('no_variants_added')}</div>
        )}
      </div>
      <ThemeProvider theme={materialTheme}>
        {renderSaveCustomVariantDialog()}
        {renderDeleteCustomVariantDialog()}
      </ThemeProvider>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
        duration={5000}
      />
      <div className="variantDialogActionButtonContainer">
        <Button
          onClick={() => openSaveVariantDialog()}
          variant="contained"
          color="secondary"
          className="variantDialogActionButton"
        >
          <Add /> {t('add_new_variant')}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(VariantsManager);
