import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  PAGE_TYPE,
  PAGE_TITLES,
  PAGE_DESCRIPTIONS,
  LINK_URL_REGEX_PATTERN,
  LINK_SLUG_REGEX_PATTERN,
} from './constants';

import otherPagesData from 'qs-data/otherPages';
import network from 'qs-data/network';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';
import Loader from 'qs-common/Loader';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import BrushIcon from '@material-ui/icons/Brush';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../os';
import { useAppContext } from '../../common/Contexts/AppContext';
import Toast from 'qs-common/Alerts/Toast';
import Utility from 'qs-helpers/utility';
import { getToken } from 'qs-data/util';
import { getI18N } from '../../i18N';

import './style.scss';

const NAVBAR_HEIGHT = 55;

let POP_STATE_COUNTER = 0;

function OtherPages() {
  const [progressBar, setProgressBar] = useState(false);
  const [domain, setDomain] = useState(null);
  const [customPages, setCustomPages] = useState([]);
  const [customPagesListLoading, setCustomPagesListLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [pageUrl, setPageUrl] = useState('');
  const [toastState, setToastState] = useState({ open: false, message: '' });
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [currentCustomPageId, setCurrentCustomPageId] = useState(null);
  const [customPageDeleteDialogVisible, setCustomPageDeleteDialogVisible] = useState(false);
  const [customPageCreateDialogVisible, setCustomPageCreateDialogVisible] = useState(false);
  const [customPageEditDialogVisible, setCustomPageEditDialogVisible] = useState(false);
  const [customPageSlugAutoSuggestEnabled, setCustomPageSlugAutoSuggestEnabled] = useState(false);

  const history = useHistory();
  const [, dispatch] = useAppContext();
  const { t } = getI18N();

  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';

  const otherPages = [
    {
      primary: t(PAGE_TITLES[PAGE_TYPE.ABOUT_US]),
      secondary: t(PAGE_DESCRIPTIONS[PAGE_TYPE.ABOUT_US]),
      id: PAGE_TYPE.ABOUT_US,
      url: `/other-page/${PAGE_TYPE.ABOUT_US}?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    },
    {
      primary: t(PAGE_TITLES[PAGE_TYPE.PRIVACY_POLICY]),
      secondary: t(PAGE_DESCRIPTIONS[PAGE_TYPE.PRIVACY_POLICY]),
      id: PAGE_TYPE.PRIVACY_POLICY,
      url: `/other-page/${PAGE_TYPE.PRIVACY_POLICY}?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    },
    {
      primary: t(PAGE_TITLES[PAGE_TYPE.TERMS_AND_CONDITIONS]),
      secondary: t(PAGE_DESCRIPTIONS[PAGE_TYPE.TERMS_AND_CONDITIONS]),
      id: PAGE_TYPE.TERMS_AND_CONDITIONS,
      url: `/other-page/${PAGE_TYPE.TERMS_AND_CONDITIONS}?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    },
    {
      primary: t(PAGE_TITLES[PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY]),
      secondary: t(PAGE_DESCRIPTIONS[PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY]),
      id: PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY,
      url: `/other-page/${PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY}?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    },
  ];

  const handleCustomPageMenuOpen = (event, customPageId) => {
    setMenuAnchorElement(event.currentTarget);
    setCurrentCustomPageId(customPageId);
  };

  const handleCustomPageMenuClose = () => {
    setMenuAnchorElement(null);
  };

  const handleCustomPageEditTitle = () => {
    handleCustomPageEditDialogOpen();
    handleCustomPageMenuClose();
  };

  const handleCustomPageChangeDesign = () => {
    const customPage = customPages.find((customPage) => customPage.id === currentCustomPageId);
    if (customPage) {
      trackAnalytics({ eventName: 'custom_page_change_design', props: { ...customPage } });
      window.location.href =
        window.location.origin.replace(':3001', ':3002') +
        `/custompages/create-page?customPageId=${customPage.id}&token=${getToken()}&source=desktop`;
    }
    setCurrentCustomPageId(null);
    handleCustomPageMenuClose();
  };

  const handleCustomPagePreviewPage = async () => {
    const customPage = customPages.find((customPage) => customPage.id === currentCustomPageId);
    if (customPage && customPage.isPublished && domain && domain.domainName) {
      try {
        const { customPageLink } = await otherPagesData.getCustomPagePreviewLink({
          customPageId: customPage.id,
        });
        trackAnalytics({ eventName: 'custom_page_preview_page', props: { ...customPage } });
        Utility.openPopup(customPageLink);
      } catch (error) {
        setToastState({
          open: true,
          message: typeof error === 'string' ? error : t('could_not_fetch_page_preview_link'),
        });
      }
    }
    setCurrentCustomPageId(null);
    handleCustomPageMenuClose();
  };

  const handleCustomPageDeleteItem = () => {
    handleCustomPageDeleteDialogOpen();
    handleCustomPageMenuClose();
  };

  const handleCustomPageDeleteDialogOpen = () => {
    history.push({
      ...history.location,
      state: { customPageDeleteDialogVisible },
      search: `${history.location.search}&customPageDeleteDialogVisible=true`,
    });
    setCustomPageDeleteDialogVisible(true);
  };

  const handleCustomPageDeleteDialogClose = (shouldHistoryGoBack = true) => {
    if (shouldHistoryGoBack) {
      history.goBack();
    }
    setCurrentCustomPageId(null);
    setCustomPageDeleteDialogVisible(false);
  };

  const handleCustomPageCreateDialogOpen = () => {
    history.push({
      ...history.location,
      state: { customPageCreateDialogVisible },
      search: `${history.location.search}&customPageCreateDialogVisible=true`,
    });
    setPageTitle('');
    setPageUrl('');
    setCustomPageSlugAutoSuggestEnabled(true);
    setCustomPageCreateDialogVisible(true);
  };

  const handleCustomPageCreateDialogClose = (shouldHistoryGoBack = true) => {
    if (shouldHistoryGoBack) {
      history.goBack();
    }
    setPageTitle('');
    setPageUrl('');
    setCustomPageSlugAutoSuggestEnabled(false);
    setCustomPageCreateDialogVisible(false);
  };

  const handleCustomPageEditDialogOpen = () => {
    history.push({
      ...history.location,
      state: { customPageEditDialogVisible },
      search: `${history.location.search}&customPageEditDialogVisible=true`,
    });
    const customPage = customPages.find((customPage) => customPage.id === currentCustomPageId);
    setPageTitle(customPage ? customPage.title || '' : '');
    setPageUrl(customPage ? customPage.defaultSlug || '' : '');
    setCustomPageEditDialogVisible(true);
  };

  const handleCustomPageEditDialogClose = (shouldHistoryGoBack = true) => {
    if (shouldHistoryGoBack) {
      history.goBack();
    }
    setPageTitle('');
    setPageUrl('');
    setCurrentCustomPageId(null);
    setCustomPageEditDialogVisible(false);
  };

  const handleCustomPagesListItemClick = (customPageId) => {
    const customPage = customPages.find((customPage) => customPage.id === customPageId);
    if (customPage) {
      try {
        trackAnalytics({ eventName: 'custom_page_change_design', props: { ...customPage } });
        window.location.href =
          window.location.origin.replace(':3001', ':3002') +
          `/custompages/create-page?customPageId=${
            customPage.id
          }&token=${getToken()}&source=desktop`;
      } catch (error) {
        setToastState({
          open: true,
          message: typeof error === 'string' ? error : t('could_not_fetch_page_preview_link'),
        });
      }
    }
  };

  const renderDesktopCreatePageButton = () => {
    return desktop ? (
      <div className="create-page-button-container">
        <Button
          variant="contained"
          startIcon={progressBar ? <Loader small={true} /> : null}
          disabled={progressBar || customPagesListLoading}
          onClick={handleCustomPageCreateDialogOpen}
          className="create-page-button"
        >
          {t('create_page')}
        </Button>
      </div>
    ) : (
      <Fragment />
    );
  };

  const renderMobileCreatePageButton = () => {
    return !desktop ? (
      <div className="create-page-button-container create-page-floating-button-container">
        <Button
          variant="contained"
          size="small"
          startIcon={progressBar ? <Loader small={true} /> : null}
          disabled={progressBar || customPagesListLoading}
          onClick={handleCustomPageCreateDialogOpen}
          className="create-page-button"
        >
          <AddIcon />
          {t('create_page')}
        </Button>
      </div>
    ) : (
      <Fragment />
    );
  };

  const popStateListener = () => {
    if (POP_STATE_COUNTER === 0) {
      POP_STATE_COUNTER++;
    } else {
      handleCustomPageDeleteDialogClose(false);
      handleCustomPageCreateDialogClose(false);
      handleCustomPageEditDialogClose(false);
      POP_STATE_COUNTER = 0;
    }
  };

  const validateLinkUrl = (slug) => {
    try {
      slug = encodeSlug(slug);
      const url = new URL(`https://${domain ? domain.domainName : 'quicksell.co'}/${slug}`);
      const hrefPattern = new RegExp(LINK_URL_REGEX_PATTERN, 'i');
      const pathnamePattern = new RegExp(LINK_SLUG_REGEX_PATTERN, 'i');
      const isValidLinkUrl = hrefPattern.test(url.href) && pathnamePattern.test(url.pathname);
      return isValidLinkUrl;
    } catch (_error) {
      return false;
    }
  };

  const encodeSlug = (slug) =>
    slug
      .replace(/ /g, '-')
      .normalize('NFD')
      .replace(/[^\w-]/g, '')
      .toLowerCase();

  useEffect(() => {
    getCustomPages();
    getDomain();
    window.addEventListener('popstate', popStateListener);
    return () => {
      window.removeEventListener('popstate', popStateListener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentCustomPageId && customPageSlugAutoSuggestEnabled) {
      setPageUrl(pageTitle && pageTitle.length > 0 ? encodeSlug(pageTitle) : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle, currentCustomPageId]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        title: t('pages'),
        height: NAVBAR_HEIGHT,
        hideBack: desktop,
        actions: desktop ? renderDesktopCreatePageButton() : null,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor(PRIMARY_COLOR);
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop, progressBar, customPagesListLoading]);

  const slugDashCounter = (slug) => {
    return slug.split('').reduce((total, letter) => {
      total[letter] ? total[letter]++ : (total[letter] = 1);
      return total;
    }, {});
  };

  const canSavePage = () => {
    const customPage = customPages.find((customPage) => customPage.id === currentCustomPageId);
    if (
      pageTitle &&
      pageTitle.length > 0 &&
      ((customPage && customPage.isFrontPage) || (pageUrl && pageUrl.length > 0)) &&
      validateLinkUrl(pageUrl)
    ) {
      const dashLength = slugDashCounter(pageUrl)['-'] || 0;
      return dashLength !== pageUrl.length;
    }
    return false;
  };

  const getDomain = async () => {
    setProgressBar(true);
    try {
      let domains = await network.getDomain();
      if (domains && domains.length > 0) {
        setDomain(domains.find((domain) => domain.domainStatus === 'connected'));
      }
    } catch (error) {
      setToastState({
        open: true,
        message: typeof error === 'string' ? error : t('could_not_fetch_company_domain'),
      });
    } finally {
      setProgressBar(false);
    }
  };

  const getCustomPages = async () => {
    setCustomPagesListLoading(true);
    try {
      const response = await otherPagesData.getCustomPages();
      if (response.customPages && response.customPages.length > 0) {
        setCustomPages(response.customPages.filter((customPage) => !customPage.isFrontPage));
      }
    } catch (error) {
      setToastState({
        open: true,
        message: typeof error === 'string' ? error : t('could_not_fetch_custom_pages'),
      });
    } finally {
      setCustomPagesListLoading(false);
    }
  };

  const createCustomPage = async () => {
    if (canSavePage()) {
      setProgressBar(true);
      try {
        const customPageSlug = pageUrl.trim();
        const { isAvailable } = await otherPagesData.getCustomPageSlugAvailability({
          customPageSlug: encodeSlug(customPageSlug),
        });
        if (isAvailable) {
          const response = await otherPagesData.createCustomPage({
            title: pageTitle,
            defaultSlug: encodeSlug(customPageSlug),
            isFrontPage: false,
            isPublished: false,
          });
          setCustomPages(customPages.concat(response.customPage));
          trackAnalytics({ eventName: 'custom_page_created', props: { ...response.customPage } });
          if (customPageCreateDialogVisible) {
            handleCustomPageCreateDialogClose();
          }
          setToastState({
            open: true,
            message: t('page_title_created_successfully', { pageTitle }),
          });
        } else {
          setToastState({
            open: true,
            message: t('duplicate_page_already_exists_with_the_same_page_title'),
          });
        }
      } catch (error) {
        setToastState({
          open: true,
          message: typeof error === 'string' ? error : t('could_not_create_custom_page'),
        });
      } finally {
        setProgressBar(false);
      }
    }
  };

  const editCustomPage = async () => {
    if (currentCustomPageId && canSavePage()) {
      setProgressBar(true);
      try {
        const currentCustomPage = customPages.find(
          (customPage) => customPage.id === currentCustomPageId
        );
        const customPageSlug = pageUrl.trim();
        const { isAvailable } = await otherPagesData.getCustomPageSlugAvailability({
          customPageId: currentCustomPage.id,
          customPageSlug: encodeSlug(customPageSlug),
        });
        if (isAvailable) {
          const response = await otherPagesData.editCustomPage({
            id: currentCustomPage.id,
            title: pageTitle,
            defaultSlug: encodeSlug(customPageSlug),
            isFrontPage: currentCustomPage.isFrontPage,
            isPublished: currentCustomPage.isPublished,
          });
          const updatedCustomPages = customPages.filter(
            (customPage) => customPage.id !== response.customPage.id
          );
          const updatedCustomPageIndex = customPages.findIndex(
            (customPage) => customPage.id === response.customPage.id
          );
          if (updatedCustomPageIndex > -1) {
            updatedCustomPages.splice(updatedCustomPageIndex, 0, response.customPage);
          }
          setCustomPages(updatedCustomPages);
          trackAnalytics({ eventName: 'custom_page_edited', props: { ...response.customPage } });
          if (customPageEditDialogVisible) {
            handleCustomPageEditDialogClose();
          }
          setToastState({
            open: true,
            message: t('page_title_saved_successfully', { pageTitle }),
          });
        } else {
          setToastState({
            open: true,
            message: t('page_url_is_not_available', { pageUrl }),
          });
        }
      } catch (error) {
        setToastState({
          open: true,
          message: typeof error === 'string' ? error : t('could_not_save_custom_pages'),
        });
      } finally {
        setProgressBar(false);
      }
    }
  };

  const deleteCustomPage = async () => {
    if (currentCustomPageId) {
      setProgressBar(true);
      try {
        const currentCustomPage = customPages.find(
          (customPage) => customPage.id === currentCustomPageId
        );
        await otherPagesData.deleteCustomPage({ id: currentCustomPage.id });
        setCustomPages(customPages.filter((customPage) => customPage.id !== currentCustomPageId));
        trackAnalytics({ eventName: 'custom_page_deleted', props: { ...currentCustomPage } });
        if (customPageDeleteDialogVisible) {
          handleCustomPageDeleteDialogClose();
        }
        setToastState({
          open: true,
          message: t('current_page_deleted_successfully', { title: currentCustomPage.title }),
        });
      } catch (error) {
        setToastState({
          open: true,
          message: typeof error === 'string' ? error : t('could_not_delete_custom_page'),
        });
      } finally {
        setProgressBar(false);
      }
    }
  };

  const renderCustomPagesList = () => {
    return (
      <List className="custom-pages-list">
        {customPages.map(({ title, id }, index) => {
          return (
            <ListItem
              key={index}
              divider={true}
              button={true}
              className="custom-pages-list-item"
              onClick={() => handleCustomPagesListItemClick(id)}
            >
              <ListItemText
                primary={title}
                classes={{
                  primary: 'custom-pages-list-item-primary',
                  secondary: 'custom-pages-list-item-secondary',
                }}
              />
              <ListItemSecondaryAction className="custom-pages-list-item-action">
                <IconButton
                  edge="end"
                  onClick={(event) => handleCustomPageMenuOpen(event, id)}
                  className="custom-pages-list-item-action-icon"
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderCustomPagesMenu = () => {
    const customPage = customPages.find((customPage) => customPage.id === currentCustomPageId);
    return (
      <Menu
        anchorEl={menuAnchorElement}
        keepMounted={true}
        open={!!menuAnchorElement}
        onClose={handleCustomPageMenuClose}
        className="custom-pages-menu"
      >
        <MenuItem key={0} onClick={handleCustomPageEditTitle} className="custom-pages-menu-item">
          <EditIcon className="custom-pages-menu-item-icon" />
          <span className="custom-pages-menu-item-text">{t('edit_title')}</span>
        </MenuItem>
        <MenuItem key={1} onClick={handleCustomPageChangeDesign} className="custom-pages-menu-item">
          <BrushIcon className="custom-pages-menu-item-icon" />
          <span className="custom-pages-menu-item-text">{t('change_design')}</span>
        </MenuItem>
        <MenuItem
          key={2}
          onClick={handleCustomPagePreviewPage}
          className="custom-pages-menu-item"
          disabled={!customPage || !customPage.isPublished || !domain}
        >
          <PlayArrowIcon className="custom-pages-menu-item-icon" />
          <span className="custom-pages-menu-item-text">{t('preview_page')}</span>
        </MenuItem>
        <MenuItem key={3} onClick={handleCustomPageDeleteItem} className="custom-pages-menu-item">
          <DeleteIcon className="custom-pages-menu-item-icon" />
          <span className="custom-pages-menu-item-text">{t('delete_page')}</span>
        </MenuItem>
      </Menu>
    );
  };

  const renderOtherPagesList = () => {
    return (
      <List className="other-pages-list">
        {otherPages.map(({ primary, secondary, url }, index) => {
          return (
            <ListItem key={index} divider={true} className="other-pages-list-item">
              <ListItemText
                primary={primary}
                secondary={secondary}
                classes={{
                  primary: 'other-pages-list-item-primary',
                  secondary: 'other-pages-list-item-secondary',
                }}
              />
              <ListItemSecondaryAction className="other-pages-list-item-action">
                <IconButton
                  edge="end"
                  onClick={() => history.push(url)}
                  className="other-pages-list-item-action-icon"
                >
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderCustomPageDeleteDialog = () => {
    return (
      <Dialog
        open={customPageDeleteDialogVisible}
        onClose={(_event, reason) => {
          if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && progressBar) {
            // Do Nothing
          } else {
            handleCustomPageDeleteDialogClose();
          }
        }}
        className="custom-page-dialog"
      >
        <DialogTitle disableTypography={true} className="custom-page-dialog-title-container">
          <div className="custom-page-dialog-title">{t('delete_page')}</div>
          <div className="custom-page-dialog-subtitle">
            {t('are_you_sure_you_want_to_delete_this_page_and_all_its_sections')}
          </div>
        </DialogTitle>
        <DialogActions className="custom-page-dialog-actions">
          <Button
            type="submit"
            variant="text"
            color="default"
            disabled={progressBar || customPagesListLoading}
            onClick={handleCustomPageDeleteDialogClose}
            classes={{ disabled: 'custom-page-dialog-action-disabled' }}
            className="custom-page-dialog-action"
          >
            {t('cancel')}
          </Button>
          <Button
            type="reset"
            variant="text"
            color="primary"
            disabled={progressBar || customPagesListLoading}
            startIcon={progressBar ? <Loader small={true} /> : null}
            onClick={deleteCustomPage}
            classes={{ disabled: 'custom-page-dialog-action-disabled' }}
            className="custom-page-dialog-action"
          >
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderCustomPageCreateDialog = () => {
    return (
      <Dialog
        open={customPageCreateDialogVisible}
        onClose={(_event, reason) => {
          if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && progressBar) {
            // Do Nothing
          } else {
            handleCustomPageCreateDialogClose();
          }
        }}
        className="custom-page-dialog"
      >
        <DialogTitle disableTypography={true} className="custom-page-dialog-title-container">
          <div className="custom-page-dialog-title">{t('create_new_page')}</div>
          <div className="custom-page-dialog-subtitle">
            {t('create_a_page_with_a_custom_layout')}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="custom-page-dialog-content">
            <TextField
              fullWidth={true}
              autoFocus={true}
              placeholder={t('please_enter_page_title')}
              label={t('page_title')}
              value={pageTitle || ''}
              onChange={(event) => setPageTitle(event.target.value || '')}
              InputProps={{
                classes: {
                  input: 'custom-page-dialog-textfield-input',
                  formControl: 'custom-page-dialog-textfield-form-control',
                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: 'custom-page-dialog-textfield-label',
                  shrink: 'custom-page-dialog-textfield-label-shrink',
                },
              }}
              className="custom-page-dialog-textfield"
            />
            <TextField
              fullWidth={true}
              label={t('page_url')}
              value={pageUrl || ''}
              onChange={(event) => {
                setPageUrl(encodeSlug(event.target.value || ''));
                setCustomPageSlugAutoSuggestEnabled(
                  event.target.value && event.target.value.length > 0 ? false : true
                );
              }}
              InputProps={{
                classes: {
                  input: 'custom-page-dialog-textfield-input',
                  formControl: 'custom-page-dialog-textfield-form-control',
                },
                startAdornment: (
                  <InputAdornment position="end" className="custom-page-dialog-textfield-adornment">
                    {domain ? domain.domainName : 'quicksell.co'}/
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: 'custom-page-dialog-textfield-label',
                  shrink: 'custom-page-dialog-textfield-label-shrink',
                },
              }}
              className="custom-page-dialog-textfield"
            />
          </div>
        </DialogContent>
        <DialogActions className="custom-page-dialog-actions">
          <Button
            type="submit"
            variant="text"
            disabled={progressBar || customPagesListLoading}
            onClick={handleCustomPageCreateDialogClose}
            classes={{ disabled: 'custom-page-dialog-action-disabled' }}
            className="custom-page-dialog-action"
          >
            {t('cancel')}
          </Button>
          <Button
            type="reset"
            variant="text"
            disabled={progressBar || customPagesListLoading || !canSavePage()}
            startIcon={progressBar ? <Loader small={true} /> : null}
            onClick={createCustomPage}
            classes={{ disabled: 'custom-page-dialog-action-disabled' }}
            className="custom-page-dialog-action"
          >
            {t('create_page')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderCustomPageEditDialog = () => {
    return (
      <Dialog
        open={customPageEditDialogVisible}
        onClose={(_event, reason) => {
          if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && progressBar) {
            // Do Nothing
          } else {
            handleCustomPageEditDialogClose();
          }
        }}
        className="custom-page-dialog"
      >
        <DialogTitle disableTypography={true} className="custom-page-dialog-title-container">
          <div className="custom-page-dialog-title">{t('edit_page_title')}</div>
        </DialogTitle>
        <DialogContent>
          <div className="custom-page-dialog-content">
            <TextField
              fullWidth={true}
              autoFocus={true}
              placeholder={t('please_enter_page_title')}
              label={t('page_title')}
              value={pageTitle || ''}
              onChange={(event) => setPageTitle(event.target.value || '')}
              InputProps={{
                classes: {
                  input: 'custom-page-dialog-textfield-input',
                  formControl: 'custom-page-dialog-textfield-form-control',
                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: 'custom-page-dialog-textfield-label',
                  shrink: 'custom-page-dialog-textfield-label-shrink',
                },
              }}
              className="custom-page-dialog-textfield"
            />
            <TextField
              fullWidth={true}
              label={t('page_url')}
              value={pageUrl || ''}
              onChange={(event) => setPageUrl(encodeSlug(event.target.value || ''))}
              InputProps={{
                classes: {
                  input: 'custom-page-dialog-textfield-input',
                  formControl: 'custom-page-dialog-textfield-form-control',
                },
                startAdornment: (
                  <InputAdornment position="end" className="custom-page-dialog-textfield-adornment">
                    {domain ? domain.domainName : 'quicksell.co'}/
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: 'custom-page-dialog-textfield-label',
                  shrink: 'custom-page-dialog-textfield-label-shrink',
                },
              }}
              className="custom-page-dialog-textfield"
            />
          </div>
        </DialogContent>
        <DialogActions className="custom-page-dialog-actions">
          <Button
            type="submit"
            variant="text"
            disabled={progressBar || customPagesListLoading}
            onClick={handleCustomPageEditDialogClose}
            classes={{ disabled: 'custom-page-dialog-action-disabled' }}
            className="custom-page-dialog-action"
          >
            {t('cancel')}
          </Button>
          <Button
            type="reset"
            variant="text"
            disabled={progressBar || customPagesListLoading || !canSavePage()}
            startIcon={progressBar ? <Loader small={true} /> : null}
            onClick={editCustomPage}
            classes={{ disabled: 'custom-page-dialog-action-disabled' }}
            className="custom-page-dialog-action"
          >
            {t('save_page')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderToast = () => {
    return (
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    );
  };

  return (
    <Fragment>
      {customPagesListLoading ? (
        <div className="custom-pages-list-loader">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {customPages && customPages.length > 0 && (
            <Box className={`custom-pages-container ${desktop ? 'desktop' : 'mobile'}`}>
              <Typography className="custom-pages-heading">{t('custom_pages')}</Typography>
              {renderCustomPagesList()}
            </Box>
          )}
          <Box className={`other-pages-container ${desktop ? 'desktop' : 'mobile'}`}>
            <Typography className="other-pages-heading">{t('other_pages')}</Typography>
            {renderOtherPagesList()}
          </Box>
        </Fragment>
      )}
      {renderCustomPagesMenu()}
      {renderCustomPageDeleteDialog()}
      {renderCustomPageCreateDialog()}
      {renderCustomPageEditDialog()}
      {!desktop && renderMobileCreatePageButton()}
      {renderToast()}
    </Fragment>
  );
}

export default OtherPages;
