import Loader from 'qs-common/Loader';
import React from 'react';
import { ReactComponent as InfoError } from '../../../assets/images/info-error.svg';
import './styles.scss';

function BottomSheetContent({ variant, message, children }) {
  const messageComponnet = message && <div className="bottom-sheet-loader-message">{message}</div>;

  switch (variant) {
    case 'loading':
      return (
        <div className="bottom-sheet-loader">
          <Loader small />
          {messageComponnet}
        </div>
      );
    case 'loading-error':
      return (
        <div className="bottom-sheet-loader">
          <InfoError />
          {messageComponnet}
        </div>
      );
    default:
      return <div className="product-list">{children}</div>;
  }
}

export default BottomSheetContent;
