import React from 'react';
import CustomTextField from '../../../../common/CustomTextField';
import { makeStyles } from '@material-ui/core/styles';
import { getI18N } from '../../../../i18N';

const useStyles = makeStyles({
  title: {
    margin: '24px 24px 0px',
    color: '#ffffff',
    fontSize: '17px',
    fontWeight: '700',
  },
});

export default function StepTwo({
  state,
  isActive,
  credentialsExist,
  errors,
  onChange,
  showOldForm,
}) {
  const classes = useStyles();
  const { t } = getI18N();
  return (
    <>
      {!showOldForm && (
        <div className={classes.title}>
          {t('bank_details')}
        </div>
      )}
      <CustomTextField
        fullWidth
        label={t('ifsc_code')}
        name="ifscCode"
        value={state.ifscCode}
        onChange={onChange}
        error={!!errors['ifscCode']}
        helperText={errors['ifscCode']}
        InputLabelProps={{ shrink: true }}
        disabled={isActive || credentialsExist}
      />
      <CustomTextField
        fullWidth
        label={t('beneficiary_name')}
        name="beneficiaryName"
        value={state.beneficiaryName}
        onChange={onChange}
        error={!!errors['beneficiaryName']}
        helperText={errors['beneficiaryName']}
        InputLabelProps={{ shrink: true }}
        disabled={isActive || credentialsExist}
      />
      <CustomTextField
        fullWidth
        label={t('account_number')}
        name="accountNumber"
        value={state.accountNumber}
        onChange={onChange}
        error={!!errors['accountNumber']}
        helperText={errors['accountNumber']}
        InputLabelProps={{ shrink: true }}
        disabled={isActive || credentialsExist}
      />
    </>
  );
}
