import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Loader from "../Loader";
import { getI18N } from '../../i18N';

const useStyles = makeStyles({
  dlgTitle: {
    fontSize: 22,
  },
  dlgContent: {
    padding: '0 !important',
  }
});

const NewDialogTitle = withStyles({
  root: {
    color: "white",
    fontSize: 26,
    fontWeight: 700,
  },
})(DialogTitle);

export const CommonButton = withStyles({
  root: {
    fontSize: 15,
    fontWeight: 600,
    color: "#9D9D9D",
  },
})(Button);

export default function DialogBox(props) {
  const {
    show = false,
    title = "Title",
    onClose,
    onSuccess,
    primaryBtnText,
    secondaryBtnText,
    content,
    dark = false,
    danger = false,
    width,
    height,
    children,
    type,
    updatingContent = false,
    ...rest
  } = props;
  const { t } = getI18N();
  const [open, setOpen] = useState(show);
  const classes = useStyles();
  let paperStyle = {
    width,
    height,
  };
  paperStyle = dark
    ? {
        backgroundColor: "#161C23",
        ...paperStyle,
      }
    : paperStyle;

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onCancelClick = () => {
    onClose && onClose();
    // setOpen(!open);
  };

  const onSucessClick = () => {
    onSuccess && onSuccess();
    // setOpen(!open);
  };

  const DiagTitle = dark ? NewDialogTitle : DialogTitle;

  const MainButton = withStyles({
    root: {
      fontSize: 14,
      fontWeight: 700,
      color: danger ? "#E76F6F" : "#92CFB3",
    },
  })(Button);

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: paperStyle,
      }}
      onClose={onCancelClick}
      aria-labelledby="form-dialog-title"
      {...rest}
    >
      {type !== 'simple' && (
        <DiagTitle disableTypography={true} id="form-dialog-title">
          <div className={classes.dlgTitle}>{title}</div>
        </DiagTitle>
      )}
      <DialogContent className={`${type === 'simple' ? classes.dlgContent : ''}`}>
        {content || children}
      </DialogContent>
      {type !== 'simple' && (
        <DialogActions className={updatingContent && classes.dlgActions}>
          <CommonButton onClick={onCancelClick} color="primary" disabled={updatingContent}>
            {secondaryBtnText || t('cancel')}
          </CommonButton>
          <MainButton onClick={onSucessClick} color="primary" disabled={updatingContent}>
            {primaryBtnText || t('ok')}
            {updatingContent && <Loader style={{ marginLeft: '8px' }} small />}
          </MainButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
