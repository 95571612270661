import html2canvas from 'html2canvas';
import { getI18N } from '../i18N';
import Utility from 'qs-helpers/utility';
import scissor from 'qs-assets/images/scissor.png';

// Generating 4 digit random coupon code [0-9a-z]
// Math.random() * 36 to create a random number from 0-35
// Math.floor to have the value as an integer
// .toString(36) to convert a number to the base of 36 (0-9a-z)
export const codeGenerator = (length) => {
  return [...Array(length)]
    .map(() => Math.floor(Math.random() * 36).toString(36))
    .join('')
    .toUpperCase();
};

export const getChangesForUpdate = ({ originalData, inputData }) => {
  const updates = {};
  if (originalData.name !== inputData.name) {
    updates.name = inputData.name;
  }
  if (originalData.applyDiscount !== inputData.applyOnDiscountedPrice) {
    updates.applyOnDiscountedPrice = inputData.applyOnDiscountedPrice;
  }
  if (originalData.couponAmount !== inputData.discountAmount) {
    updates.discountAmount = inputData.discountAmount;
  }
  if (originalData.discount !== inputData.discountPercent) {
    updates.discountPercent = inputData.discountPercent;
  }
  if (originalData.perUser !== inputData.perUserLimit) {
    updates.perUserLimit = inputData.perUserLimit;
  }
  if (originalData.totalUsageLimit !== inputData.totalUsageLimit) {
    updates.totalUsageLimit = inputData.totalUsageLimit;
  }

  if (originalData.minimumOrderAmount !== inputData.minimumOrderAmount) {
    updates.minimumOrderAmount = inputData.minimumOrderAmount;
  }

  const { validTill } = originalData;
  const { validTill: inputValidTill } = inputData;
  const originalValid = validTill ? new Date(validTill).valueOf() : null;
  const updateValid = inputValidTill ? new Date(inputValidTill).valueOf() : null;
  if (updateValid !== originalValid) {
    updates.validTill = inputValidTill;
  }

  return updates;
};

const getCouponText = (
  discount,
  couponAmount,
  currencySymbol,
  currencyAlignment,
  { discountKey = 'get_percent_off' } = {}
) => {
  const { t } = getI18N();
  if (typeof discount === 'number') {
    return t(discountKey, { percent: discount });
  }
  if (typeof couponAmount === 'number') {
    return `${Utility.getFormattedCurrency(couponAmount, currencySymbol, currencyAlignment)} ${t(
      'off'
    )}`;
  }
  return null;
};

const getCouponCode = (code) => {
  const { t } = getI18N();
  return t('use_code_coupon_code', { code });
};

const getValidTillText = (validTill) => {
  if (!validTill) {
    return null;
  }
  const { t, language } = getI18N();
  return t('valid_till', {
    date: new Date(validTill).toLocaleDateString(language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }),
  });
};

const getCompanyLogo = async (companyId, logoExists, logoVersion) => {
  if (!logoExists || !companyId) {
    return null;
  }

  const imageURL = `https://s3.ap-south-1.amazonaws.com/quicksell-logos/${companyId}.jpg?v=${logoVersion}`;
  const imageDataUrl = await Utility.getDataUrlFromFileUrl(imageURL);

  return `
      <img
        src=${imageDataUrl}
        width="20px"
        height="20px"
        style="padding-right: 5px"
        alt="Your company profile image"
        crossorigin="anonymous"
      />
    `;
};

export const getCouponDetailsAsTextForCopy = (couponData, currencyProps) => {
  if (!couponData) {
    return '';
  }

  const { name, code, discount, couponAmount, validTill } = couponData;

  let copyString = '=============';

  copyString += `\n*${name}*`;

  const couponText = getCouponText(
    discount,
    couponAmount,
    currencyProps.symbol,
    currencyProps.symbolOnRight ? 'right' : 'left'
  );

  if (!couponText) {
    return '';
  }

  copyString += `\n${couponText}`;
  copyString += `\n${getCouponCode(code)}`;

  const validTillText = getValidTillText(validTill);

  if (validTillText) {
    copyString += `\n${validTillText}`;
  }

  copyString += '\n=============';
  return copyString;
};

const GENERATED_ID_LENGTH_FOR_CANVAS = 12;

const createElementInDOM = (htmlInString) => {
  const dummyElement = document.createElement('div');
  dummyElement.innerHTML = htmlInString.trim();
  document.body.appendChild(dummyElement.firstElementChild);
};

export const generateSharableCouponDataURL = async (couponData, currencyProps, companyDetails) => {
  if (!couponData) {
    return;
  }

  const { name, code, discount, couponAmount, validTill, color } = couponData;

  const { name: companyName, logoExists, logoVersion, companyId } = companyDetails;
  const companyLogo = await getCompanyLogo(companyId, logoExists, logoVersion);

  const couponText = getCouponText(
    discount,
    couponAmount,
    currencyProps.symbol,
    currencyProps.symbolOnRight ? 'right' : 'left',
    { discountKey: 'percent_off' }
  );

  if (!couponText) {
    return;
  }

  const validTillText = getValidTillText(validTill);

  const generatedId = codeGenerator(GENERATED_ID_LENGTH_FOR_CANVAS);

  const formattedText = couponText.split(' ');
  const offText = formattedText.pop();
  const offerText = formattedText.join('');

  const canvasHtmlString = `
    <div style="width: 250px;display: flex;flex-direction: column;align-items: stretch;" id="${generatedId}">
      <div style="display: flex;flex:0 0 auto;padding-top: 15px;margin-left: 20px;align-items: center">
        ${companyLogo || ''}
        <div style="font-weight: bold">
          ${companyName}
        </div>
      </div>
      <div style="display:flex;flex-direction:column;flex:1 1 0;margin: 15px 20px;padding:10px;border: 2px dashed ${color};border-radius:2px;position: relative;z-index: 1">
        <img src=${scissor} style="top: 0;right: 30px;position: absolute;transform: translateY(-50%);z-index: 2" width="35px" height="35px" alt=""/>
        <div style="display: flex;flex-direction: column;align-items: flex-start">
          <div style="font-size: 12px;font-weight: bold;margin-bottom: 2px">
              Use code
          </div>
          <div style="background-color: ${color};color: white;border-radius: 2px;padding:0 10px 2px;font-size: 24px;">
              ${code}
          </div>
        </div>
        <div style="display: flex;flex-direction: column;align-items: stretch">
          <div style="font-weight: bold;font-size: 50px;">
              ${offerText} <span style="font-weight: bold;font-size: 50px;">${offText}</span>
          </div>
          ${
            validTillText
              ? `<div style="font-weight: bold;font-size: 12px;margin-bottom: 5px;">${validTillText}</div>`
              : ''
          }
          <div style="font-size: 12px;font-weight: bold;">
              ${name}
          </div>
        </div>
      </div> 
    </div>`;

  createElementInDOM(canvasHtmlString);
  const createdElement = document.getElementById(generatedId);
  const canvas = await html2canvas(createdElement);
  const generatedDataURL = canvas.toDataURL('image/jpeg');
  createdElement.remove();
  return generatedDataURL;
};
