export const PRIMARY_COLOR = '#4DA47A';
export const SECONDARY_COLOR = '#FFFFFF';
export const BACKGROUND_COLOR = '#303B4B';

export const LINK_URL_REGEX_PATTERN = '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$';

export const LINK_SLUG_REGEX_PATTERN = /^\/[A-Za-z0-9_-]*$/;

export const PAGE_TYPE = {
  FRONT_PAGE: 'FRONT_PAGE',
  ABOUT_US: 'ABOUT_US',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  REFUND_AND_EXCHANGE_POLICY: 'REFUND_AND_EXCHANGE_POLICY'
};

export const PAGE_TITLES = {
  [PAGE_TYPE.FRONT_PAGE]: 'front_page',
  [PAGE_TYPE.ABOUT_US]: 'about_us',
  [PAGE_TYPE.PRIVACY_POLICY]: 'privacy_policy',
  [PAGE_TYPE.TERMS_AND_CONDITIONS]: 'terms_and_conditions',
  [PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY]: 'return_and_exchange_policy'
};

export const PAGE_DESCRIPTIONS = {
  [PAGE_TYPE.FRONT_PAGE]: 'design_your_front_page',
  [PAGE_TYPE.ABOUT_US]: 'add_information_about_your_business',
  [PAGE_TYPE.PRIVACY_POLICY]: 'generate_your_privacy_policy',
  [PAGE_TYPE.TERMS_AND_CONDITIONS]: 'add_information_about_your_terms_and_conditions',
  [PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY]: 'add_your_returns_and_exchange_policy'
};

export const PRIVACY_POLICY_FIELDS = {
  COMPANY: 'company_name',
  BRAND: 'brand_name',
  URL: 'website'
};
