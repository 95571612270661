import React, { useState, useReducer, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import payments from '../../../data/payments';
import Loader from 'qs-common/Loader/index';
import useStyles from 'qs-common/CustomStyles';
import Dialog from 'qs-common/Alerts/Dialog';
import Toast from 'qs-common/Alerts/Toast';
import './style.css';
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import StepThree from './StepThree/StepThree';
import { makeStyles } from '@material-ui/core/styles';
import { getI18N } from '../../../i18N';
import { RAZORPAY_REQUIRED_DOCUMENTS } from './constants';
import Utility from 'qs-helpers/utility';

const useStyle = makeStyles({
  loader: {
    margin: '24px',
  },
  button: {
    margin: '24px 0px 24px 24px',
  },
});

function formReducer(state, { type, ...data }) {
  if (type === 'input-changed') {
    return {
      ...state,
      [data.name]: data.value,
    };
  } else if (type === 'initialize') {
    const credentials = data;
    return {
      ...state,
      ...credentials,
    };
  }
}

export default function Rzp({ id, gatewayData, setPaymentData }) {
  const timerRef = useRef();
  const [erroDialog, setErrorDialog] = useState(false);
  const [isActive, setIsActive] = useState(gatewayData['isActive'] || false);
  const [credentialsExist, setCredentialsExist] = useState(
    !!(gatewayData.credentials && gatewayData.credentials.ifscCode)
  );
  const [sameCustomerBusinessName, setSameCustomerBusinessName] = useState(true);
  const [showOldForm] = useState(
    !!(gatewayData.credentials && gatewayData.credentials.oldAccountExists)
  );
  const [requiredDocumentsState, setRequiredDocumentsState] = useState(
    RAZORPAY_REQUIRED_DOCUMENTS[(gatewayData.credentials || {}).businessType] || {
      businessPanRequired: false,
      cinRequired: false,
      llpinRequired: false,
    }
  );
  const [showCheckCurrentStatusButton, setShowCheckCurrentStatusButton] = useState(
    gatewayData.extraGatewayData &&
      !gatewayData.extraGatewayData.formLink &&
      gatewayData.extraGatewayData.activationStatus
  );
  const { t } = getI18N();

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  const getInitialState = () => {
    const companyMeta = gatewayData.companyMeta || {};
    const credentials = gatewayData.credentials || {};
    return {
      ifscCode: credentials.ifscCode || '',
      accountType: 'current',
      accountNumber: credentials.accountNumber || '',
      beneficiaryName: credentials.beneficiaryName || '',
      name: credentials.name || companyMeta.name || '',
      email: credentials.email || companyMeta.email || '',
      businessName: credentials.businessName || companyMeta.companyName || '',
      businessType: credentials.businessType || '',
      pan: credentials.pan || companyMeta.userPan || '',
      businessPan: credentials.businessPan || '',
      phone: credentials.phone || companyMeta.phone || '',
      cin: credentials.cin || companyMeta.companyCin || '',
      city:
        (credentials.profile && credentials.profile.address && credentials.profile.address.city) ||
        '',
      state:
        (credentials.profile && credentials.profile.address && credentials.profile.address.state) ||
        '',
      country:
        (credentials.profile &&
          credentials.profile.address &&
          credentials.profile.address.country) ||
        'India',
      pincode:
        (credentials.profile &&
          credentials.profile.address &&
          credentials.profile.address.pincode) ||
        '',
      street1:
        (credentials.profile &&
          credentials.profile.address &&
          credentials.profile.address.street1) ||
        '',
      street2:
        (credentials.profile &&
          credentials.profile.address &&
          credentials.profile.address.street2) ||
        '',
      category: (credentials.profile && credentials.profile.category) || '',
      subCategory: (credentials.profile && credentials.profile.subCategory) || '',
      tncAccepted: true,
      customerBusinessName: credentials.customerBusinessName,
    };
  };

  const classes = useStyles();
  const rzpClasses = useStyle();
  const [errors, setErrors] = useState({});
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(formReducer, getInitialState());

  // const paymentChargesData = payments.getRazorpayGatewayCharges();

  const updateErrors = (field) => {
    setErrors((prevErrors) => {
      delete prevErrors[field];
      return prevErrors;
    });
  };

  const clearErrorsAndStopLoder = () => {
    setErrors({});
    setLoading(false);
  };

  const renderMismatchDomainDialog = () => {
    if (!erroDialog) {
      return;
    }
    return (
      <Dialog
        open={erroDialog}
        title={t('mismatch_domain')}
        description={
          <span>
            {t('domain_is_manadatory_to_collect_payments_using_this_method')}
          </span>
        }
        confirmText={'OK'}
        onClose={() => setErrorDialog(false)}
        handleConfirm={() => setErrorDialog(false)}
      />
    );
  };

  const updateCustomerBusinessName = () => {
    dispatch({
      type: 'input-changed',
      name: 'customerBusinessName',
      value: state.businessName,
    });
    updateErrors('customerBusinessName');
    setLoading(false);
    setSameCustomerBusinessName(!sameCustomerBusinessName);
  };

  const validateForm = () => {
    if (showOldForm) {
      return false;
    }
    const _errors = {};
    const result = { ...state };
    const { businessPanRequired, cinRequired, llpinRequired } = requiredDocumentsState;
    if (!businessPanRequired) {
      delete result.businessPan;
    }
    if (!cinRequired && !llpinRequired) {
      delete result.cin;
    }
    Object.keys(result).forEach((element) => {
      if (result[element] === '') {
        _errors[element] = t('this_field_cannot_be_left_blank');
      }
    });
    setErrors(_errors);
    return Object.keys(_errors).length;
  };

  const onSubmit = () => {
    let data = {};
    if (credentialsExist) {
      data = { ...gatewayData.credentials };
    } else {
      let numberErrors = validateForm();
      if (numberErrors) {
        return;
      }
      data = {
        ifscCode: state.ifscCode,
        accountType: 'current',
        accountNumber: state.accountNumber,
        beneficiaryName: state.beneficiaryName,
        name: state.name,
        email: state.email,
        businessName: state.businessName,
        businessType: state.businessType,
        pan: state.pan,
        businessPan: state.businessPan,
        cin: state.cin,
        phone: state.phone,
        profile: {
          address: {
            city: state.city,
            state: state.state,
            country: state.country,
            pincode: state.pincode,
            street1: state.street1,
            street2: state.street2,
          },
          category: state.category,
          subCategory: state.subCategory,
        },
        customerBusinessName: sameCustomerBusinessName
          ? state.businessName
          : state.customerBusinessName,
      };
    }
    data.partnerAccountData = !showOldForm;
    setLoading(true);
    payments
      .enableRZP(data)
      .then((res) => {
        if (!res) {
          clearErrorsAndStopLoder();
          setToastState({
            message: t('an_error_occured'),
            open: true,
          });
          return;
        }
        if (!credentialsExist && res.formLink) {
          setPaymentData({
            ...gatewayData,
            extraGatewayData: {
              formLink: res.formLink,
            },
          });
        }
        clearErrorsAndStopLoder();
        setToastState({
          message: t('payment_gateway_enabled'),
          open: true,
        });
        setCredentialsExist(true);
        setIsActive(true);
      })
      .catch((err) => {
        let message = t('an_error_occured');
        if (err.response.data.reason === 'INTERNAL_SERVER_ERROR') {
          message = err.response.data.extraData.description;
        }
        if (
          err.response.data.reason === 'RAZORPAY_DOMAIN_MISMATCH' ||
          err.response.data.reason === 'RAZORPAY_DOMAIN_NOT_FOUND'
        ) {
          setErrorDialog(true);
          clearErrorsAndStopLoder();
          return;
        }

        clearErrorsAndStopLoder();
        setToastState({
          message: message,
          open: true,
        });
      });
  };

  const onDisableClick = () => {
    setLoading(true);
    payments
      .disableRZP()
      .then((disableResponse) => {
        if (!disableResponse) {
          throw new Error('');
        }
        setIsActive(false);
        setLoading(false);
        setToastState({
          message: t('payment_gateway_disabled'),
          open: true,
        });
      })
      .catch(() => {
        setToastState({
          message: t('failed_to_disable'),
          open: true,
        });
        setLoading(false);
      });
  };

  const updateState = ({ name, value }) => {
    dispatch({
      type: 'input-changed',
      name,
      value,
    });
    updateErrors(name);
    setLoading(false);
  };

  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value || '';
    updateState({ name, value });
  };

  const getShouldShowCompleteKycButton = () =>
    gatewayData.extraGatewayData && gatewayData.extraGatewayData.formLink;

  const onOpenCompleteKycFormClick = () => {
    setShowCheckCurrentStatusButton(true);
    Utility.openPopup(gatewayData.extraGatewayData.formLink, 'noopener=true');
  };

  const getShouldShowStatusMessage = () =>
    !showOldForm &&
    !getShouldShowCompleteKycButton() &&
    gatewayData.extraGatewayData &&
    gatewayData.extraGatewayData.activationStatus;

  const refreshGatewayData = () => {
    setLoading(true);
    payments
      .getPaymentMethodForId(id)
      .then((data) => {
        setPaymentData(data);
        setShowCheckCurrentStatusButton(
          data && data.extraGatewayData && !data.extraGatewayData.formLink
        );
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const onGetKycData = () => {
    clearTimeout(timerRef.current);
    // Wait for 5 seconds before making request, this is done to provide some time for the webhook to be
    // received and status to be updated
    timerRef.current = setTimeout(refreshGatewayData, 5000);
  };

  const enableButton = (
    <Button
      variant="contained"
      size="large"
      className={`${classes.button} ${rzpClasses.button}`}
      onClick={onSubmit}
    >
      {t('enable')}
    </Button>
  );

  const disableButton = (
    <Button
      variant="contained"
      size="large"
      className={`${classes.buttonDisable} ${rzpClasses.button}`}
      onClick={onDisableClick}
    >
      {t('disable')}
    </Button>
  );

  const completeKycButton = (
    <Button
      variant="contained"
      size="large"
      className={`${classes.button} ${rzpClasses.button}`}
      onClick={onOpenCompleteKycFormClick}
    >
      {t('complete_kyc')}
    </Button>
  );

  const getCurrentStatusButton = (
    <Button
      variant="contained"
      size="large"
      className={`${classes.button} ${rzpClasses.button}`}
      onClick={onGetKycData}
    >
      {t('get_kyc_status')}
    </Button>
  );

  const getFormButtons = () => {
    if (loading) {
      return (
        <div className={rzpClasses.loader}>
          <Loader small />
        </div>
      );
    }

    if (showOldForm) {
      if (isActive) {
        return disableButton;
      }
      return enableButton;
    }

    if (isActive) {
      if (getShouldShowCompleteKycButton()) {
        return showCheckCurrentStatusButton ? getCurrentStatusButton : completeKycButton;
      }

      return disableButton;
    }
    return enableButton;
  };

  return (
    <>
      <div className="RzpContainer">
        <div className="RzpPaymentDetails">
          <div className="RzpTitle">{t('settlement_schedule')}</div>
          <div className="RzpSubtitle">{t('working_days_excluding_saturday_and_sunday', { days : 2 })}</div>
          {/* <div className="RzpTitle">{t('payment_gateway_charges')}</div>
          <div className="RzpSubtitleGreen">{t('best_in_industry')}</div>
          <table className="RzpChargesTabel">
            <tbody>
              {paymentChargesData.map((tableElement, index) => (
                <tr className="RzpChargesTabelRow" key={index}>
                  <td className="RzpChargesTabelElement">{t(tableElement.info)}</td>
                  <td className="RzpChargesTabelElement RzpChargesTabelChargesElement">
                    {t(tableElement.charges)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="RzpAdditionalInfo">
            {t('additional_percent_gst_applicable_on_payment_gateway_charges'), { percent: 18 }}
          </div> */}
        </div>
        <form className={classes.root} noValidate autoComplete="off" disabled>
          <StepOne
            state={state}
            isActive={isActive}
            credentialsExist={credentialsExist}
            errors={errors}
            onChange={onChange}
            showOldForm={showOldForm}
          />
          <StepTwo
            classes={classes}
            state={state}
            isActive={isActive}
            credentialsExist={credentialsExist}
            errors={errors}
            onChange={onChange}
            updateState={updateState}
            showOldForm={showOldForm}
            sameCustomerBusinessName={sameCustomerBusinessName}
            updateCustomerBusinessName={updateCustomerBusinessName}
            requiredDocumentsState={requiredDocumentsState}
            setRequiredDocumentsState={setRequiredDocumentsState}
          />
          <StepThree
            state={state}
            isActive={isActive}
            credentialsExist={credentialsExist}
            errors={errors}
            onChange={onChange}
            showOldForm={showOldForm}
          />
          {getShouldShowStatusMessage() && (
            <div className="RzpKycStatus">
              {payments.statusMessages()[gatewayData.extraGatewayData.activationStatus] || ''}
            </div>
          )}
          {getFormButtons()}
        </form>
        <Toast
          message={toastState.message}
          open={toastState.open}
          onClose={() => {
            setToastState({
              open: false,
              message: '',
            });
          }}
        />
        {renderMismatchDomainDialog()}
      </div>
    </>
  );
}
