import React from 'react';

export default ({ color = "#00A674", size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.408 2.95197H19.296V1.91997C19.296 1.24797 18.768 0.719971 18.096 0.719971C17.424 0.719971 16.896 1.24797 16.896 1.91997V2.95197H15.24V1.91997C15.24 1.24797 14.712 0.719971 14.04 0.719971C13.368 0.719971 12.84 1.24797 12.84 1.91997V2.95197H11.184V1.91997C11.184 1.24797 10.656 0.719971 9.98402 0.719971C9.31202 0.719971 8.78402 1.24797 8.78402 1.91997V2.95197H7.12802V1.91997C7.12802 1.24797 6.60002 0.719971 5.92802 0.719971C5.25602 0.719971 4.72802 1.24797 4.72802 1.91997V2.95197H2.61602C1.51202 2.95197 0.624023 3.83997 0.624023 4.94397V21.288C0.624023 22.392 1.51202 23.28 2.61602 23.28H21.456C22.56 23.28 23.448 22.392 23.448 21.288V4.94397C23.4 3.83997 22.512 2.95197 21.408 2.95197ZM21.408 20.784C21.408 21.048 21.192 21.288 20.904 21.288H3.07202C2.80802 21.288 2.56802 21.072 2.56802 20.784V7.84797C2.56802 7.58397 2.78402 7.34397 3.07202 7.34397H20.904C21.168 7.34397 21.408 7.55997 21.408 7.84797V20.784Z" fill={color}/>
      <path d="M8.08808 15.4319H5.95208C5.59208 15.4319 5.30408 15.7199 5.30408 16.0799V17.9279C5.30408 18.2879 5.59208 18.5759 5.95208 18.5759H8.08808C8.44808 18.5759 8.73608 18.2879 8.73608 17.9279V16.0799C8.73608 15.7199 8.44808 15.4319 8.08808 15.4319Z" fill={color}/>
      <path d="M13.0561 15.4319H10.9201C10.5601 15.4319 10.2721 15.7199 10.2721 16.0799V17.9279C10.2721 18.2879 10.5601 18.5759 10.9201 18.5759H13.0561C13.4161 18.5759 13.7041 18.2879 13.7041 17.9279V16.0799C13.7041 15.7199 13.4161 15.4319 13.0561 15.4319Z" fill={color}/>
      <path d="M18.048 15.4319H15.912C15.552 15.4319 15.264 15.7199 15.264 16.0799V17.9279C15.264 18.2879 15.552 18.5759 15.912 18.5759H18.048C18.408 18.5759 18.696 18.2879 18.696 17.9279V16.0799C18.696 15.7199 18.408 15.4319 18.048 15.4319Z" fill={color}/>
      <path d="M8.08808 10.3199H5.95208C5.59208 10.3199 5.30408 10.6079 5.30408 10.9679V12.8159C5.30408 13.1759 5.59208 13.4639 5.95208 13.4639H8.08808C8.44808 13.4639 8.73608 13.1759 8.73608 12.8159V10.9679C8.73608 10.6079 8.44808 10.3199 8.08808 10.3199Z" fill={color}/>
      <path d="M13.0561 10.3199H10.9201C10.5601 10.3199 10.2721 10.6079 10.2721 10.9679V12.8159C10.2721 13.1759 10.5601 13.4639 10.9201 13.4639H13.0561C13.4161 13.4639 13.7041 13.1759 13.7041 12.8159V10.9679C13.7041 10.6079 13.4161 10.3199 13.0561 10.3199Z" fill={color}/>
      <path d="M18.048 10.3199H15.912C15.552 10.3199 15.264 10.6079 15.264 10.9679V12.8159C15.264 13.1759 15.552 13.4639 15.912 13.4639H18.048C18.408 13.4639 18.696 13.1759 18.696 12.8159V10.9679C18.696 10.6079 18.408 10.3199 18.048 10.3199Z" fill={color}/>
    </svg>

  );
}