import { trackAnalytics } from '../../os';
import {
  getCachedShippingCredentials,
  fetchShippingCredentials,
  setCachedShippingCredentials,
  setCachedSelectedProvider,
  putShippingCredentials,
} from 'qs-data/shippingProviders';

export const trackUpdateShippingCredentialsEvent = (credentials) => {
  trackAnalytics({
    eventName: 'changed_shiprokcet_credentials',
    props: credentials || {},
  });
};


export const getShippingCredentials = () => {
  const cachedCredentials = getCachedShippingCredentials();
  if (cachedCredentials) {
    return Promise.resolve(cachedCredentials);
  }
  return fetchShippingCredentials().then((credentials) => {
    setCachedShippingCredentials(credentials);
    return Promise.resolve(credentials);
  });
}

export const updateShippingCredentials = ({ credentials, provider }) =>
  putShippingCredentials({ ...credentials, provider }).then(() => {
    trackUpdateShippingCredentialsEvent(credentials);
    setCachedShippingCredentials(credentials);
    setCachedSelectedProvider(provider);
    return Promise.resolve();
  });