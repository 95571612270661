import { Button, Dialog, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as CopyIcon } from 'qs-assets/images/copy.svg';
import { ReactComponent as DeleteIcon } from 'qs-assets/images/delete-icon.svg';
import CouponIcon from 'qs-assets/images/ic_coupon_white.png';
import { ReactComponent as EditIcon } from 'qs-assets/images/pencil.svg';
import { ReactComponent as ShareIcon } from 'qs-assets/images/share.svg';
import Toast from 'qs-common/Alerts/Toast';
import Loader from 'qs-common/Loader';
import CompanyData from 'qs-data/companyData';
import { generateSharableCouponDataURL, getCouponDetailsAsTextForCopy } from 'qs-helpers/coupons';
import React, { useState } from 'react';
import Utility from 'qs-helpers/utility';
import { shareImage } from '../../../os';
import './styles.scss';
import { getI18N } from '../../../i18N';

const useStyles = makeStyles({
  actionButton: {
    fontSize: '14px',
    textTransform: 'none',
    padding: '4px 12px',
    marginTop: 10,
    flex: '0 0 auto',
    marginLeft: 10,
  },
});

export default (props) => {
  const { showActionButtons, onEdit, onDelete, info = {} } = props;
  const { name, code, discount, couponAmount, color } = info;
  const componentStyles = useStyles();
  const [couponLoading, setCouponLoading] = useState(false);
  const { t } = getI18N();

  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const onShareClick = async () => {
    setCouponLoading(true);
    const companyDetails = await CompanyData.getCompanyDetails();
    const generatedDataURL = await generateSharableCouponDataURL(
      info,
      CompanyData.getCompanyCurrencyProperties(),
      companyDetails
    );
    setCouponLoading(false);
    shareImage(generatedDataURL);
  };

  const onCopyClick = () => {
    Utility.copyToClipboard(
      getCouponDetailsAsTextForCopy(info, CompanyData.getCompanyCurrencyProperties())
    );
    setToastState({
      open: true,
      message: t('coupon_details_copied_to_clipboard'),
    });
  };

  let couponOff = null;
  if (typeof couponAmount === 'number') {
    couponOff = `${CompanyData.getCompanyCurrencyProperties().symbol} ${couponAmount} ${t('off')}`;
  } else {
    couponOff = t('percent_off', { percent: discount });
  }

  return (
    <>
      <div className="couponCardContainer">
        <div className={'couponCard'}>
          <div className="codeAndBasicMetaContainer">
            <div className="couponCode" style={{ backgroundColor: color || '#2F9644' }}>
              <img src={CouponIcon} alt={''} height={22} width={16} />
              <span>{code}</span>
            </div>
            <div className="couponInfo">{`${name} - ${couponOff}`}</div>
          </div>
          <div className={'actionSection'}>
            <div
              className={'editAction'}
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <EditIcon className="editIcon" />
            </div>
            <div
              className={'deleteAction'}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(code);
              }}
            >
              <DeleteIcon className="deleteIcon" />
            </div>
          </div>
        </div>
        {showActionButtons && (
          <div className="buttonsContainer">
            <Button
              startIcon={<CopyIcon className="actionButtonIcon" />}
              classes={{ root: componentStyles.actionButton }}
              onClick={onCopyClick}
              variant="outlined"
            >
              {t('copy')}
            </Button>
            <Button
              startIcon={<ShareIcon className="actionButtonIcon" />}
              classes={{ root: componentStyles.actionButton }}
              onClick={onShareClick}
              variant="outlined"
            >
              {t('share')}
            </Button>
          </div>
        )}
      </div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
      <Dialog open={couponLoading}>
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          <Typography color="primary">{t('preparing_coupon')}</Typography>
          <Loader style={{ margin: '5px', padding: '5px' }} />
        </div>
      </Dialog>
    </>
  );
};
