export const percentOpened = (totalDelivered, totalOpened) => {
  if (!totalOpened || (totalOpened === 0 && totalDelivered === 0)) {
    return 0;
  }
  return ((100 * totalOpened) / totalDelivered).toFixed(2);
};

export const getCompanyLogo = (companyId, logoVersion, logoExists) => {
  if (!logoExists || !companyId) {
    return null;
  }
  return `https://s3.ap-south-1.amazonaws.com/quicksell-logos/${companyId}.jpg?v=${logoVersion}`;
};
