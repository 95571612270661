export const PRIMARY_COLOR = '#4DA47A';
export const SECONDARY_COLOR = '#FFFFFF';
export const BACKGROUND_COLOR = '#303B4B';
export const LINK_REGEX =
  '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$';

export const getWebhookEvents = (t) => {
  return {
    ORDER: {
      formattedName: 'Order',
      events: [
        {
          id: 'eee65054-8b3d-11ed-8396-73ad64604371',
          event: 'ORDER_STATUS_ALL',
          formattedName: t('order_status_all'),
        },
        {
          id: 'eee657ac-8b3d-11ed-83a3-c767919e81b1',
          event: 'SEND_ORDER_ACCEPTED',
          formattedName: t('send_order_accepted'),
        },
        {
          id: 'eee657de-8b3d-11ed-83a4-3be368714ecb',
          event: 'SEND_ORDER_CONFIRMED',
          formattedName: t('send_order_confirmed'),
        },
        {
          id: 'eee65806-8b3d-11ed-83a5-bff3599ad99d',
          event: 'SEND_ORDER_REJECTED',
          formattedName: t('send_order_rejected'),
        },
        {
          id: 'eee65838-8b3d-11ed-83a6-97556b0ac76a',
          event: 'SEND_ORDER_COMPLETED',
          formattedName: t('send_order_completed'),
        },
        {
          id: 'eee6586a-8b3d-11ed-83a7-e3febbfe5f96',
          event: 'SEND_ORDER_UNCONFIRMED',
          formattedName: t('send_order_unconfirmed'),
        },
        {
          id: '38f27112-2794-11ee-8219-cb95f24070bf',
          event: 'SEND_ORDER_DELETED',
          formattedName: t('send_order_deleted'),
        },
      ],
    },
    INQUIRY: {
      formattedName: 'Inquiry',
      events: [
        {
          id: 'eee6563a-8b3d-11ed-839b-07ac674dfca7',
          event: 'INQUIRY_CREATED',
          formattedName: t('inquiry_created'),
        },
        {
          id: 'eee6566c-8b3d-11ed-839c-0307626273c5',
          event: 'INQUIRY_UPDATED',
          formattedName: t('inquiry_updated'),
        },
        {
          id: 'eee6569e-8b3d-11ed-839d-3b862d79a270',
          event: 'INQUIRY_DELETED',
          formattedName: t('inquiry_deleted'),
        },
      ],
    },
    VISITOR: {
      formattedName: 'Visitor',
      events: [
        {
          id: 'eee65608-8b3d-11ed-839a-6b74f2552949',
          event: 'VISITOR_CREATED',
          formattedName: t('visitor_created'),
        },
      ],
    },
    CATALOGUE_ACCESS: {
      formattedName: 'Private catalogue access',
      events: [
        {
          id: '583ff857-04b7-475f-8343-58acc90d8ac1',
          event: 'CATALOUGE_ACCESS_ACCEPTED',
          formattedName: t('access_provided'),
        },
        {
          id: '6fe5b667-80ec-4a5e-af39-0c4edc415769',
          event: 'CATALOUGE_ACCESS_REJECTED',
          formattedName: t('access_revoked'),
        },
        {
          id: '5156e5c3-1ff1-460d-8d97-d69d5d256529',
          event: 'CATALOUGE_ACCESS_EXPIRED',
          formattedName: t('access_expired'),
        },
      ],
    },
  };
};
