import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

let apis = {
  referralCodeAndOfferEndPoint: '/referral/code',
  referralFriendsListEndPoint: '/referral/friends',
  referralRewardsEndPoint: '/referral/reward',
  redeemReferralCouponEndPoint: '/referral/redeem',
  referralAnalytics: '/v1/analytics/track',
  checkUserType: '/company/show-booster',
};

/* Referral code and offer apis */

const getReferralCodeAndOffer = () => {
  return ApiWrapper.getDataPromise(
    apis.referralCodeAndOfferEndPoint,
    null,
    CacheKeys.referralCodeAndOffer
  );
};

const checkIfUserIsJewellery = () => {
  return ApiWrapper.getDataPromise(apis.checkUserType, null, null);
};

const getReferralFriendList = () => {
  return ApiWrapper.getDataPromise(apis.referralFriendsListEndPoint, 'friends', null);
};

const getReferralReward = (userId) => {
  return ApiWrapper.getDataPromise(
    `${apis.referralRewardsEndPoint}?friendUserId=${userId}`,
    null,
    null
  );
};

const redeemReferralCoupon = (coupon) => {
  return ApiWrapper.postDataPromise(apis.redeemReferralCouponEndPoint, coupon, null);
};

export default {
  getReferralCodeAndOffer,
  getReferralFriendList,
  getReferralReward,
  redeemReferralCoupon,
  checkIfUserIsJewellery,
};
