let response = {};

const setCacheForKey = (key, value) => {
  response[key] = value;
};

const getCacheForKey = (key) => response[key] || null;

export default {
  setCacheForKey,
  getCacheForKey,
};
