import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import FacebookPixelData from 'qs-data/facebookPixel';
import './style.scss';
import FacebookPixelId from 'qs-components/FacebookPixelId';
import { getI18N } from '../../i18N';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';

const FacebookPixel = () => {
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [facebookPixelId, setFacebookPixelId] = useState(null);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const { t } = getI18N();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#242C36' : '#4DA47A',
        color: '#FFFFFF',
        title: t('facebook_pixel'),
        height: NAVBAR_HEIGHT,
        boxShadow: desktop ? 'none' : undefined,
        hideBack: desktop,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  useEffect(() => {
    fetchFacebookPixelCode();
  }, []);

  const fetchFacebookPixelCode = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    FacebookPixelData.getFacebookPixelId()
      .then((data) => {
        if (data) {
          setFacebookPixelId(data);
        }
      })
      .catch(() => {
        showErrorComponent(true);
      })
      .then(() => {
        setProgressBar(false);
      });
  };

  return (
    <div
      className="facebookPixelPage"
      style={{ alignItems: progressBar || errorComponent ? 'center' : 'flex-start' }}
    >
      {progressBar ? (
        <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} />
      ) : errorComponent ? (
        <CustomErrorComponent onRetryClick={fetchFacebookPixelCode} />
      ) : (
        <FacebookPixelId pixelId={facebookPixelId} />
      )}
    </div>
  );
};

export default withRouter(FacebookPixel);
