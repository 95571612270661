/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import network from 'qs-data/network';
import { getCountries, getCountryFromCountryCode } from './countries-mapper';
import './style.scss';
import { Autocomplete } from '@material-ui/lab';
import { Box, TextField } from '@material-ui/core';
import { getCatalogueCDNAssetPath } from 'qs-helpers';

const CountryCodeSelector = ({ value, onChange }) => {
  const [selectedCountry, setSelectedCountry] = useState(value ?? getCountryFromCountryCode('IN'));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      network.getCountryCode().then((countryCodeData) => {
        const {
          data: {
            geo: { countryCode },
          },
        } = countryCodeData;
        const countryFromCountryCode = getCountryFromCountryCode(countryCode);
        setSelectedCountry(
          countryFromCountryCode ? countryFromCountryCode : getCountryFromCountryCode('IN')
        );
      });
    })();
  }, []);

  const getCountryList = () => {
    const countries = getCountries();
    return countries;
  };

  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
    setOpen(false);
  };

  useEffect(() => {
    onChange(selectedCountry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  return (
    <>
      <Autocomplete
        options={getCountryList()}
        getOptionLabel={(option) => option.symbol}
        value={selectedCountry}
        onChange={handleCountryChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderOption={(country) => {
          if (!country) return null;
          return (
            <Box
              className={`countryItem countryItem${country?.label.replace(/ /g, '')} ${
                selectedCountry?.label === country?.label ? 'selected' : ''
              }`}
            >
              <img
                className="icon"
                alt=""
                src={getCatalogueCDNAssetPath(
                  `assets/flags/${country.countryCode && country.countryCode.toLowerCase()}.png`
                )}
                style={{ marginRight: 8, width: 20, height: 20 }}
              />
              <Box className="name" flexGrow={1}>
                <span>{country.label}</span>
              </Box>
              {country.symbol && (
                <Box className="symbol">
                  <span>{country.symbol}</span>
                  {country.currencyCode && (
                    <span className="currencyCode">{country.currencyCode}</span>
                  )}
                </Box>
              )}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" className="country-selector-input" />
        )}
        className="country-selector-container"
        variant="outlined"
      />
    </>
  );
};

export default CountryCodeSelector;
