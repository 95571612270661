import React, { useState, forwardRef } from 'react';
import Loader from 'qs-common/Loader';
import './style.scss';
import { getI18N } from '../../../i18N';

function VideoListItem({ id, embedId, title, currentlyPlaying, onVideoSelect }, ref) {
  const { t } = getI18N();
  const { id: playingId } = currentlyPlaying || {};
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <div
      key={id}
      className={`videoListContainer ${id === playingId ? 'active' : ''}`}
      onClick={onVideoSelect}
      ref={ref}
    >
      <div className="videoThubmnail">
        <span className="playIcon"></span>
        {imageLoaded ? null : (
          <div className="loader">
            <Loader style={{ marginTop: 10, marginBottom: 10 }} />
          </div>
        )}
        <img
          src={`https://img.youtube.com/vi/${embedId}/sddefault.jpg`}
          style={imageLoaded ? {} : { display: 'none' }}
          onLoad={() => setImageLoaded(true)}
          alt={title}
        />
      </div>
      <div className="videoInfo">
        <div className="playing">{t('currently_playing')}</div>
        <div className="videoTitle">{title}</div>
      </div>
    </div>
  );
}

export default forwardRef(VideoListItem);
