import React, { useEffect } from 'react';
import './styles.scss';
import { ReactComponent as Close } from '../../../assets/images/close-white.svg';
import { ReactComponent as Scanner } from '../../../assets/images/barcode.svg';
import Loader from 'qs-common/Loader';
// import { ReactComponent as Help } from '../../../assets/images/help-white.svg';
import { ReactComponent as InfoError } from '../../../assets/images/info-error.svg';
import AnimatedEllipsis from '../AnimatedEllipsis';
import { Check } from '@material-ui/icons';
import { getI18N } from '../../../i18N';
import Ripple from '../Ripple';

const BottomSheet = ({
  active,
  children,
  title,
  subtitle,
  onClose,
  footerMessage,
  status,
  hideStatus,
  statusMessage,
  items = [],
}) => {
  const [products, setProducts] = React.useState(items);
  const [added, setAdded] = React.useState(false);
  const { t } = getI18N();
  useEffect(() => {
    if (items?.length > products.length) {
      setAdded(true);
      setTimeout(() => {
        setAdded(false);
      }, 2000);
    }
    setProducts(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items?.length]);
  const isBig =
    products?.length === 0 &&
    status &&
    !['fetching', 'fetching-error', 'already-exist'].includes(status);
  return (
    <>
      {active && (
        <div
          className={`bottom-sheet-overlay ${active ? 'overlay-active' : ''} ${
            isBig ? 'overlay-padding' : ''
          }`}
          onClick={active ? onClose : undefined}
        />
      )}
      <div
        className={`bottom-sheet-container ${active ? 'active' : ''} ${
          isBig ? 'overlay-padding' : ''
        }`}
      >
        <div className="bottom-sheet-header">
          <div className="bottom-sheet-header-top-row">
            <div className="bottom-sheet-title">
              {title} {/* <Help className="help-icon" />{' '} */}
            </div>
            <Close onClick={onClose} />
          </div>
          <div className="bottom-sheet-subtitle">{subtitle}</div>
        </div>
        {children}
        {footerMessage}
        {!hideStatus && (
          <div
            className={`bottom-sheet-status type-${status} ${
              isBig ? 'bottom-sheet-status-initial' : ''
            }`}
          >
            {added && <div className="animated-success" />}
            {['fetching-error', 'already-exist'].includes(status) && (
              <div className="animated-error" />
            )}
            {added ? (
              <>
                <Check className="check-icon" />
                {t('item_added_successful')}
              </>
            ) : status === 'fetching' ? (
              <>
                <Loader small />
                {t('fetching_product_details')}
              </>
            ) : status === 'fetching-error' ? (
              <>
                <InfoError className="error-icon" />
                {t('could_not_find_product_in_database')}
              </>
            ) : status === 'already-exist' ? (
              <>
                <InfoError className="error-icon" />
                {statusMessage}
              </>
            ) : products?.length === 0 ? (
              <>
                <Ripple />
                <span>{t('start_scanning')}</span>
              </>
            ) : (
              <>
                <Scanner small />
                <AnimatedEllipsis message={t('continue_scanning')} />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BottomSheet;
