import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { getToken } from 'qs-data/util';
import PrivacyGroupLogo from 'qs-assets/images/groups.png';
import ic_white_plus from 'qs-assets/images/ic_white_plus.png';
import {
  ACCESS_COLOR,
  ACCESS_TYPE,
  ATOM,
  COUNT_TYPE,
  ENTITY_TYPE,
  getCacheKeyForGlobalGroup,
  GlOBAL_GROUPS,
  renderCount,
  renderIcon,
  renderItemIconStyle,
  settingOptions,
} from '../privacySettingConstants';
import Toast from 'qs-common/Alerts/Toast';
import network from 'qs-data/network';
import CustomCheckbox from 'qs-common/CustomCheckbox';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import PrivacySettingsCache from '../PrivacySettingsCache';
import Loader from 'qs-common/Loader';
import ConfirmationDialogBox from '../ConfirmationDialogBox';
import CacheKeys from '../CacheKeys';
import useOnClickOutside from 'qs-common/Hooks/useOnClickOutside';
import { getI18N } from '../../../i18N';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';

const useStyles = makeStyles({
  checkBoxStyle: {
    padding: 0,
  },
  formControlLabel: {
    marginLeft: '0px',
    marginRight: '0px',
  },
});

const GlobalGroups = () => {
  const classes = useStyles();
  const [, dispatch] = useAppContext();
  const history = useHistory();
  const { globalGroupId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [expandSearchBox, setExpandSearchBox] = useState(false);
  const [globalGroups, setGlobalGroups] = useState(
    PrivacySettingsCache.getCacheForKey(getCacheKeyForGlobalGroup(globalGroupId)) || []
  );
  const headerSectionRef = useRef(null);
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');

  const [searchResults, setSearchResults] = useState([]);
  const [isAnyGroupSelected, setGroupSelected] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [showConfirmationtDialog, setConfirmationtDialog] = useState(false);
  const [confirmationBoxDetails, setConfirmationBoxDetails] = useState({});
  const [onSuccessLoader, setOnSuccessLoader] = useState(false);
  const [headerHeight, setHeaderSectionHeight] = useState(null);
  const { t } = getI18N();
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const onSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearchExpand = useCallback(() => {
    setExpandSearchBox(!expandSearchBox);
    setSearchText('');
  }, [expandSearchBox]);

  const unSelectAllGroups = () => {
    const updatedGroups = (globalGroups || []).map((group) => ({
      ...group,
      checked: false,
    }));
    setGlobalGroups(updatedGroups);
  };

  const closeMenuOptions = () => {
    setShowMenuOptions(false);
  };

  const showMenuOptionsRef = useOnClickOutside(closeMenuOptions, showMenuOptions);

  const clearState = () => {
    setGroupSelected(!isAnyGroupSelected);
    setSearchText('');
    setExpandSearchBox(false);
    setShowMenuOptions(false);
    setConfirmationtDialog(false);
  };

  const onClose = () => {
    setConfirmationtDialog(false);
  };

  const closeSelectedGroupNavbar = useCallback(() => {
    unSelectAllGroups();
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyGroupSelected]);

  const group = settingOptions.find((group) => group.id === globalGroupId) || {};
  const { styles = {} } = group || {};

  const showSelectedGroupTitle = useCallback(() => {
    let updatedSelectedGroup = [];
    if (isAnyGroupSelected) {
      updatedSelectedGroup = [...globalGroups].filter((group) => {
        if (group && group.checked) {
          return { ...group };
        }
        return undefined;
      });
    }
    return t(updatedSelectedGroup.length === 1 ? 'group_selected' : 'groups_selected', { count: updatedSelectedGroup.length });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalGroups]);

  const showOptionsOnMenuIconClick = () => {
    setShowMenuOptions(true);
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background:
          isAnyGroupSelected || expandSearchBox || scrolling
            ? group.styles.navBarColor
            : 'transparent',
        title: group.title ? t(group.title) : '',
        subTitle: showGroupCountTitle() || '',
        showTitleSubTitle: scrolling,
        color: '#FFFFFF',
        placeholder: `${t('search')}...`,
        boxShadow: false,
        enableBoxShadow: false,
        searchable: true,
        expandSearchBox: expandSearchBox,
        onClick: onSearchExpand,
        onChange: (e) => onSearch(e),
        value: searchText,
        changedNavbarTitle: showSelectedGroupTitle() || '',
        hideChangedNavbar: closeSelectedGroupNavbar,
        isSelected: isAnyGroupSelected,
        onMoreIconClick: showOptionsOnMenuIconClick || null,
        onDeleteIconClick:
          group.groupName && group.groupName === GlOBAL_GROUPS.CUSTOMER
            ? showDeleteConfirmationDialog
            : null,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: '120vh',
      },
    });

    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    searchText,
    expandSearchBox,
    onSearch,
    onSearchExpand,
    isAnyGroupSelected,
    scrolling,
    showSelectedGroupTitle,
  ]);
  useEffect(() => {
    if (isAnyGroupSelected) {
      window.scrollTo({
        top: window.scrollY - headerHeight + 55,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: scrolling ? window.scrollY + headerHeight - 55 : 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyGroupSelected]);

  useEffect(() => {
    if (headerSectionRef && headerSectionRef.current) {
      const headerSectionHeight = headerSectionRef.current.clientHeight;
      setHeaderSectionHeight(headerSectionHeight);
    }
  }, [headerSectionRef]);

  useEffect(() => {
    getGlobalGroups();
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener('scroll', onScrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (globalGroups.length) {
      if (!searchText && expandSearchBox) {
        setSearchResults([...globalGroups]);
        return;
      }
      if (searchText) {
        window.scrollTo(0, 0);
        const copyGroupsList = [...globalGroups];
        const filteredGroups = copyGroupsList.filter((group) => {
          const groupName = group.name;
          if (groupName.toLowerCase().includes(searchText)) {
            return group;
          }
          return undefined;
        });
        setSearchResults(filteredGroups);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, globalGroups, expandSearchBox]);

  const getGlobalGroups = async () => {
    if (!PrivacySettingsCache.getCacheForKey(getCacheKeyForGlobalGroup(globalGroupId))) {
      setLoading(true);
    }
    try {
      const { groups = [] } = (await network.getGlobalGroups(group.id)) || {};
      const addCheckedPropertyToGroups = groups.map((group) => {
        group.checked = false;
        return { ...group };
      });
      PrivacySettingsCache.setCacheForKey(
        getCacheKeyForGlobalGroup(globalGroupId),
        addCheckedPropertyToGroups
      );

      setGlobalGroups(addCheckedPropertyToGroups);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      window.scrollTo(0, 0);
      setLoading(false);
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const renderGroupMembers = (groupId, group) => {
    if (showMenuOptions) {
      return;
    }
    const keyName = `${CacheKeys.SELECTED_COMPANY_GROUP}_${globalGroupId}_${groupId}`;
    PrivacySettingsCache.setCacheForKey(keyName, group);
    window.scrollTo(0, 0);
    history.push({
      pathname: `/group-members/${ENTITY_TYPE.COMPANY}/${globalGroupId}/${groupId}`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };

  const renderAddExistingGroup = () => {
    if (showMenuOptions) {
      return;
    }
    window.scrollTo(0, 0);
    const accessLevel = group.groupName;
    history.push({
      pathname: `/add-existing-groups/${ENTITY_TYPE.COMPANY}/${accessLevel}`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };

  const showGroupCountTitle = () => {
    return renderCount(globalGroups.length, COUNT_TYPE.GROUP);
  };

  const renderMemberCount = (memberCount) => {
    return renderCount(memberCount, COUNT_TYPE.MEMBER);
  };

  const onScrollHandler = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const groupSelected = ({ checked, id }) => {
    if (showMenuOptions) {
      return;
    }
    const groupSelectedList = [...globalGroups].map((group) => {
      const updatedGroup = { ...group };
      if (updatedGroup.id === id) {
        updatedGroup.checked = !checked;
      }
      return updatedGroup;
    });
    const checkIfAnyGroupIsSelected = groupSelectedList.some((group) => group.checked === true);
    if (searchText && !checkIfAnyGroupIsSelected) {
      setSearchText(false);
    }
    setGlobalGroups(groupSelectedList);
    setGroupSelected(checkIfAnyGroupIsSelected);
    if (expandSearchBox) {
      setExpandSearchBox(false);
    }
  };

  const getSelectedGroups = () => {
    let updatedSelectedGroup = [];
    if (isAnyGroupSelected) {
      updatedSelectedGroup = [...globalGroups].filter((group) => {
        if (group && group.checked) {
          return { ...group };
        }
        return undefined;
      });
      return updatedSelectedGroup;
    }
  };
  const deleteSelectedGroups = async (groups) => {
    const groupIds = groups.map((group) => group.id);
    setOnSuccessLoader(true);
    try {
      await network.deleteGroups({
        groupIds,
      });
      const updatedGroups = [...globalGroups].filter((group) => groupIds.indexOf(group.id) === -1);
      PrivacySettingsCache.setCacheForKey(getCacheKeyForGlobalGroup(globalGroupId), updatedGroups);
      setGlobalGroups(updatedGroups);
      setToastState({
        open: true,
        message: t('successfully_deleted'),
      });
      setOnSuccessLoader(false);
      clearState();
    } catch (error) {
      setOnSuccessLoader(false);
      clearState();
      setToastState({
        open: true,
        message: t('could_not_delete'),
      });
    }
  };

  const showDeleteConfirmationDialog = () => {
    const count = (getSelectedGroups() || []).length;
    const details = {
      title: t('delete_group'),
      message: t(count === 1 ? 'delete_group_confirmation' : 'delete_groups_confirmation', {
        count
      }),
      api: () => deleteSelectedGroups(getSelectedGroups()),
    };
    setConfirmationtDialog(true);
    setConfirmationBoxDetails(details);
  };
  const addToPrivacyList = async (listType, groups, toastMessage) => {
    const ids = groups.map((group) => group.id);
    setOnSuccessLoader(true);

    try {
      await network.addToPrivacyList({
        entityType: ENTITY_TYPE.COMPANY,
        forceListType:
          listType === GlOBAL_GROUPS.ALLOWED ? ACCESS_TYPE.WHITELIST : ACCESS_TYPE.BLACKLIST,
        ids: ids,
        type: ATOM.GROUP,
      });
      let updatedGroups;
      if (group.groupName !== GlOBAL_GROUPS.CUSTOMER) {
        updatedGroups = [...globalGroups].filter((group) => ids.indexOf(group.id) === -1);
      } else if (group.groupName === GlOBAL_GROUPS.CUSTOMER) {
        updatedGroups = [...globalGroups].map((group) => {
          group.checked = false;
          if (ids.indexOf(group.id) !== -1)
            group.accessType =
              listType === GlOBAL_GROUPS.ALLOWED ? ACCESS_TYPE.WHITELIST : ACCESS_TYPE.BLACKLIST;
          return group;
        });
      }
      PrivacySettingsCache.setCacheForKey(getCacheKeyForGlobalGroup(globalGroupId), updatedGroups);
      setGlobalGroups(updatedGroups);
      setOnSuccessLoader(false);
      window.scrollTo(0, 0);
      setToastState({
        open: true,
        message: toastMessage,
      });
      clearState();
    } catch (error) {
      setOnSuccessLoader(false);
      window.scrollTo(0, 0);
      clearState();
      setToastState({
        open: true,
        message: t('could_not_add_to_the_privacy_list'),
      });
    }
  };

  const removeFromPrivacyList = async (listType, groups, toastMessage) => {
    const ids = groups.map((group) => group.id);
    setOnSuccessLoader(true);
    try {
      await network.removeFromPrivacyList({
        entityType: ENTITY_TYPE.COMPANY,
        forceListType:
          listType === GlOBAL_GROUPS.ALLOWED ? ACCESS_TYPE.WHITELIST : ACCESS_TYPE.BLACKLIST,
        ids: ids,
        type: ATOM.GROUP,
      });
      let updatedGroups;
      if (group.groupName !== GlOBAL_GROUPS.CUSTOMER) {
        updatedGroups = [...globalGroups].filter((group) => ids.indexOf(group.id) === -1);
      } else if (group.groupName === GlOBAL_GROUPS.CUSTOMER) {
        updatedGroups = [...globalGroups].map((group) => {
          group.checked = false;
          if (ids.indexOf(group.id) !== -1)
            group.accessType =
              listType === GlOBAL_GROUPS.ALLOWED ? ACCESS_TYPE.WHITELIST : ACCESS_TYPE.BLACKLIST;
          return group;
        });
      }
      setGlobalGroups(updatedGroups);
      PrivacySettingsCache.setCacheForKey(getCacheKeyForGlobalGroup(globalGroupId), updatedGroups);
      window.scrollTo(0, 0);
      setOnSuccessLoader(false);
      setToastState({
        open: true,
        message: toastMessage,
      });
      clearState();
    } catch (error) {
      window.scrollTo(0, 0);
      setOnSuccessLoader(false);
      clearState();
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const renderConfirmationBox = () => {
    return (
      <ConfirmationDialogBox
        open={showConfirmationtDialog}
        title={confirmationBoxDetails.title}
        message={confirmationBoxDetails.message}
        onClose={onClose}
        onClickHandler={() => confirmationBoxDetails.api()}
        onSuccessLoader={onSuccessLoader}
      />
    );
  };

  const addBlocked = async () => {
    const count = (getSelectedGroups() || []).length;
    const toastMessage = t('added_to_blocked_group');

    const details = {
      title: t('add_to_blocked_group'),
      message: t(count === 1 ? 'add_blocked_group_confirmation' : 'add_blocked_groups_confirmation', {
        count
      }),
      id: 'add-blocked',
      api: () => addToPrivacyList(GlOBAL_GROUPS.BLOCKED, getSelectedGroups(), toastMessage),
    };
    setShowMenuOptions(false);
    setConfirmationtDialog(true);
    setConfirmationBoxDetails(details);
  };

  const removeAllowed = () => {
    const count = (getSelectedGroups() || []).length;
    const toastMessage = t('removed_from_allowed_group');
    const details = {
      title: t('remove_from_allowed_group'),
      message: t(count === 1 ? 'remove_allowed_group_confirmation' : 'remove_allowed_groups_confirmation', {
        count
      }),
      id: 'remove-allowed',
      api: () => removeFromPrivacyList(GlOBAL_GROUPS.ALLOWED, getSelectedGroups(), toastMessage),
    };
    setShowMenuOptions(false);
    setConfirmationtDialog(true);
    setConfirmationBoxDetails(details);
  };

  const addAllowed = () => {
    const count = (getSelectedGroups() || []).length;
    const toastMessage = t('add_to_allowed_group');
    const details = {
      title: t('added_to_allowed_group'),
      message: t(count === 1 ? 'add_allowed_group_confirmation' : 'add_allowed_groups_confirmation', {
        count
      }),
      id: 'add-allowed',
      api: () => addToPrivacyList(GlOBAL_GROUPS.ALLOWED, getSelectedGroups(), toastMessage),
    };
    setShowMenuOptions(false);
    setConfirmationtDialog(true);
    setConfirmationBoxDetails(details);
  };

  const removeBlocked = () => {
    const count = (getSelectedGroups() || []).length;
    const toastMessage = t('removed_from_blocked_group');
    const details = {
      title: t('remove_from_blocked_group'),
      message: t(count === 1 ? 'remove_blocked_group_confirmation' : 'remove_blocked_groups_confirmation', {
        count
      }),
      id: 'remove-blocked',
      api: () => removeFromPrivacyList(GlOBAL_GROUPS.BLOCKED, getSelectedGroups(), toastMessage),
    };
    setShowMenuOptions(false);
    setConfirmationtDialog(true);
    setConfirmationBoxDetails(details);
  };

  const markAllowed = () => {
    const count = (getSelectedGroups() || []).length;
    const toastMessage = t('marked_as_allowed_group');
    const details = {
      title: t('mark_as_allowed_group'),
      message: t(count === 1 ? 'set_default_allowed_group_confirmation' : 'set_default_allowed_groups_confirmation', {
        count
      }),
      id: 'mark-allowed',
      api: () => addToPrivacyList(GlOBAL_GROUPS.ALLOWED, getSelectedGroups(), toastMessage),
    };
    setShowMenuOptions(false);
    setConfirmationtDialog(true);
    setConfirmationBoxDetails(details);
  };

  const markBlocked = () => {
    const count = (getSelectedGroups() || []).length;
    const toastMessage = t('marked_as_blocked_group');
    const details = {
      title: t('mark_as_blocked_group'),
      message: t(count === 1 ? 'set_default_blocked_group_confirmation' : 'set_default_blocked_groups_confirmation', {
        count
      }),
      id: 'mark-blocked',
      api: () => addToPrivacyList(GlOBAL_GROUPS.BLOCKED, getSelectedGroups(), toastMessage),
    };
    setShowMenuOptions(false);
    setConfirmationtDialog(true);
    setConfirmationBoxDetails(details);
  };

  const getMoreOptions = (listType) => {
    switch (listType) {
      case GlOBAL_GROUPS.ALLOWED:
        return [
          {
            title: t('add_to_blocked_groups'),
            action: addBlocked,
          },
          {
            title: t('remove_from_allowed_group'),
            action: removeAllowed,
          },
        ];
      case GlOBAL_GROUPS.BLOCKED:
        return [
          {
            title: t('add_to_allowed_groups'),
            action: addAllowed,
          },
          {
            title: t('remove_from_blocked_group'),
            action: removeBlocked,
          },
        ];
      default:
        return [
          {
            title: t('mark_as_default_allowed_group'),
            action: markAllowed,
          },
          {
            title: t('mark_as_default_blocked_group'),
            action: markBlocked,
          },
        ];
    }
  };

  const renderMenuOptions = () => {
    const listType = group.groupName || GlOBAL_GROUPS.CUSTOMER.id;
    const moreSettingsOptions = getMoreOptions(listType);

    return (
      <div className="menuOptionsContainer" ref={showMenuOptionsRef}>
        {moreSettingsOptions.map((option, index) => {
          return (
            <div className="optionTitle" onClick={option.action} key={index}>
              {option.title}
            </div>
          );
        })}
      </div>
    );
  };
  const renderGroupsList = searchText ? searchResults : globalGroups;

  return (
    <>
      <div className="globalGroupContainer">
        {!(expandSearchBox || isAnyGroupSelected) && (
          <div
            className="headerSection"
            style={{ background: styles.background }}
            ref={headerSectionRef}
          >
            <img src={PrivacyGroupLogo} alt="privacy group" style={{ width: 65, height: 55 }} />
            <div className="groupTitle">{t(group.title)}</div>
            <div className="groupMembersCount">{showGroupCountTitle()}</div>
          </div>
        )}
        {loading ? (
          <div className="loader">
            <Loader />;
          </div>
        ) : (
          <div
            className="groupsContainer"
            style={expandSearchBox || isAnyGroupSelected ? { paddingTop: '3.5rem' } : {}}
          >
            {renderGroupsList.map((singleGroup) => {
              const { id, name, iconColor, iconName, memberCount, accessType, checked } =
                singleGroup;
              let accessBackgroundColor = 'transparent';
              if (accessType === ACCESS_TYPE.BLACKLIST) {
                accessBackgroundColor = ACCESS_COLOR.BLOCKED_COLOR;
              } else if (accessType === ACCESS_TYPE.WHITELIST) {
                accessBackgroundColor = ACCESS_COLOR.ALLOWED_COLOR;
              }
              return (
                <div className="singleGroup" key={id}>
                  <div
                    style={{
                      width: renderItemIconStyle.size,
                      height: renderItemIconStyle.size,
                      borderRadius: renderItemIconStyle.borderRadius,
                      marginLeft: renderItemIconStyle.marginLeft,
                      backgroundColor: iconColor,
                    }}
                  ></div>

                  <img
                    src={renderIcon(iconName)}
                     alt="single group"
                    style={{
                      maxHeight: 48,
                      width: renderItemIconStyle.imageWidth,
                      marginLeft: renderItemIconStyle.imageMarginLeft,
                    }}
                  />

                  <div className="checkbox">
                    <CustomCheckbox
                      key={id}
                      checked={checked}
                      checkBoxStyle={classes.checkBoxStyle}
                      formControlLabel={classes.formControlLabel}
                      handleChange={() => groupSelected({ checked, id, name })}
                    />
                  </div>

                  <div className="accessBarAndDetails">
                    {group.groupName && group.groupName === GlOBAL_GROUPS.CUSTOMER && (
                      <div
                        style={{
                          borderLeft: `2px solid  ${accessBackgroundColor}`,
                          alignSelf: 'stretch',
                          marginBottom: 15,
                        }}
                      ></div>
                    )}
                    <div
                      className="singleGroupDetails"
                      onClick={() => {
                        renderGroupMembers(id, singleGroup);
                      }}
                    >
                      <div className="groupName">{name}</div>
                      <div className="groupMemberCount">{renderMemberCount(memberCount)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
            {renderGroupsList.length === 0 && <div className="noGroupsText">{t('no_groups')}</div>}
          </div>
        )}
      </div>
      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={3000}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
      {group.groupName !== GlOBAL_GROUPS.CUSTOMER && (
        <img
          onClick={renderAddExistingGroup}
          src={ic_white_plus}
          alt="plus icon"
          style={{
            borderRadius: '50%',
            height: 18,
            width: 18,
            position: 'fixed',
            backgroundColor: '#439875',
            bottom: 30,
            right: 30,
            padding: 15,
            cursor: 'pointer',
          }}
        />
      )}
      {showMenuOptions && renderMenuOptions()}
      {renderConfirmationBox({ confirmationBoxDetails })}
    </>
  );
};

export default GlobalGroups;
