import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import NativeAppData from 'qs-data/nativeApp';
import { getCompanyLogo } from 'qs-helpers/nativeApp';
import CompanyData from 'qs-data/companyData';
import TextField from '@material-ui/core/TextField';
import Loader from 'qs-common/Loader';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import Button from '@material-ui/core/Button';
import BoltIcon from 'qs-assets/icons/BoltIcon';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Toast from 'qs-common/Alerts/Toast';
import { trackAnalytics, setNavigationBarColor, setStatusBarColor } from '../../os';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import './style.scss';
import { getI18N } from '../../i18N';

const useStyles = makeStyles({
  inputLabel: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20,
    marginLeft: 0,
    marginBottom: 16,
    transform: 'translate(0, -1.5px) scale(0.75)',
  },

  label: {
    fontSize: 15,
    color: '#fff',
    fontWeight: 500,
  },

  notificationIcon: {
    width: 17,
    height: 17,
  },
});

const PushNotificationButton = withStyles({
  root: {
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#2e40e4',
    },
  },
})(Button);

const PushNotification = () => {
  const { t } = getI18N();
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [loading, setLoading] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [notificationDescription, setNotificationDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [notificationImage, setNotificationImage] = useState(null);
  const [notificationImageFile, setNotificationImageFile] = useState(null);
  const [notificationImageLoader, setNotificationImageLoader] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [expandNotification, setExpandNotification] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const TITLE_LIMIT = 50;
  const DESCRIPTION_LIMIT = 200;

  const classes = useStyles();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('new_push_notification'),
      },
    });
    setNavigationBarColor('#28303A');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  const trackPushNotificationPageOpenedEvent = () => {
    trackAnalytics({
      eventName: 'Start - PushNotificationPageActivity',
      props: {},
    });
  };

  const trackSendClickedEvent = () => {
    trackAnalytics({
      eventName: 'app_notification_sent',
      props: {},
    });
  };

  const setCompanyDetailsToNotification = () => {
    CompanyData.getCompanyDetails()
      .then((data) => {
        if (data) {
          const { name, companyId, logoVersion, logoExists } = data;
          setCompanyName(name);
          setCompanyLogo(getCompanyLogo(companyId, logoVersion, logoExists));
          trackPushNotificationPageOpenedEvent();
        }
      })
      .catch(() => showErrorComponent(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setCompanyDetailsToNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const imageFile = e.target.files;
    if (imageFile && imageFile[0]) {
      const selectedImage = imageFile[0];
      setNotificationImageFile(selectedImage);
      const reader = new FileReader();
      reader.readAsDataURL(imageFile[0]);
      reader.onload = (e) => {
        setNotificationImage(e.target.result);
      };
    }
  };

  const onTitleChange = (e) => {
    const title = e.target.value;
    if (title.length > TITLE_LIMIT) {
      setNotificationTitle(title);
      setTitleError(true);
      return;
    }
    setNotificationTitle(title);
    setTitleError(false);
  };

  const onDescriptionChange = (e) => {
    const description = e.target.value;
    if (description.length > DESCRIPTION_LIMIT) {
      setNotificationDescription(description);
      setDescriptionError(true);
      return;
    }
    setNotificationDescription(description);
    setDescriptionError(false);
  };

  const cleanState = () => {
    setNotificationTitle('');
    setNotificationDescription('');
    setNotificationImage(null);
  };

  const handlePushNotificationForm = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!notificationTitle || notificationTitle.length > TITLE_LIMIT) {
      setTitleError(true);
    }

    if (notificationDescription.length > DESCRIPTION_LIMIT) {
      setDescriptionError(true);
    }

    if (
      !notificationTitle ||
      notificationTitle.length > TITLE_LIMIT ||
      notificationDescription.length > DESCRIPTION_LIMIT
    ) {
      return;
    }

    setNotificationImageLoader(true);
    NativeAppData.setNativeAppPushNotification({
      title: notificationTitle,
      body: notificationDescription,
      icon: notificationImageFile,
    })
      .then((res) => {
        trackSendClickedEvent();
        setToastState({
          open: true,
          message: res.message,
        });
        cleanState();
        setNotificationImageLoader(false);
      })
      .catch((err) => {
        setToastState({
          open: true,
          message: err ? err : t('something_went_wrong'),
        });
        setNotificationImageLoader(false);
      });
  };

  const renderCharacterCounts = (textLength, textLimit) => {
    return (
      <span className="characterCounts">
        {textLength}/{textLimit}
      </span>
    );
  };

  if (loading) {
    return (
      <div className="centerAlignContent">
        <Loader />
      </div>
    );
  }
  if (errorComponent) {
    return (
      <div className="centerAlignContent">
        <CustomErrorComponent onRetryClick={setCompanyDetailsToNotification} />
      </div>
    );
  }
  return (
    <div className="pushNotificationContainer">
      <div
        onClick={() => setExpandNotification(!expandNotification)}
        className={`notificationPreviewCollapsed ${
          expandNotification ? 'notificationPreviewExpanded' : ''
        }`}
      >
        <div className="notificationHeader">
          <span className="companyLogo">
            {companyLogo ? (
              <img src={companyLogo} alt="company logo" />
            ) : (
              <NotificationsActiveIcon className={classes.notificationIcon} />
            )}
          </span>
          <div className="headerTitle">
            {expandNotification ? (
              companyName && companyName
            ) : (
              <>
                <p className="title">
                  {notificationTitle}{' '}
                  <span className="notificationTime"> - {t('now')}</span>
                </p>
                <p>{notificationDescription}</p>
              </>
            )}
            {expandNotification && <span className="notificationTime"> - {t('now')}</span>}
          </div>
          {companyLogo && (
            <span className="companyLogo big">
              <img src={companyLogo} alt="company logo" />
            </span>
          )}
          <div className={`toggleCollapse ${companyLogo ? 'alignToEnd' : ''}`}>
            {expandNotification ? (
              <ExpandLessIcon className="toggleIcon" />
            ) : (
              <ExpandMoreIcon className="toggleIcon" />
            )}
          </div>
        </div>
        {expandNotification && (
          <div className="notificationContent">
            <p className="title lineClampEllipis">
              {notificationTitle.length > 200
                ? `${notificationTitle.slice(0, 200)}...`
                : notificationTitle}
            </p>
            <p className="lineClampEllipis">
              {notificationDescription.length > 250
                ? `${notificationDescription.slice(0, 250)}...`
                : notificationDescription}
            </p>
            {notificationImage && (
              <div className="notificationImageContainer">
                <img className="notificationImage" src={notificationImage} alt="push notification icon" />
              </div>
            )}
          </div>
        )}
      </div>

      <form className="pushNotificationForm" onSubmit={handlePushNotificationForm}>
        <TextField
          fullWidth
          value={notificationTitle}
          label="Title"
          error={titleError}
          helperText={
            titleError
              ? !notificationTitle.length
                ? t('title_is_required')
                : t('title_can_not_exceed_character_limit', { limit: `${notificationTitle.length}/${TITLE_LIMIT}` })
              : renderCharacterCounts(notificationTitle.length, TITLE_LIMIT)
          }
          className="notificationInputField"
          InputLabelProps={{
            classes: { formControl: classes.inputLabel },
            shrink: true,
          }}
          onChange={onTitleChange}
        />

        <TextField
          fullWidth
          value={notificationDescription}
          multiline
          label={t('description')}
          error={descriptionError}
          helperText={
            descriptionError
              ? t('description_can_not_exceed_character_limit', { limit: `${notificationDescription.length}/${DESCRIPTION_LIMIT}` })
              : renderCharacterCounts(notificationDescription.length, DESCRIPTION_LIMIT)
          }
          className="notificationInputField"
          InputLabelProps={{
            classes: { formControl: classes.inputLabel },
            shrink: true,
          }}
          onChange={onDescriptionChange}
        />

        <div className="imageUpload">
          <p>{t('image')}</p>
          <div className="imageUploadButton">
            {notificationImage ? (
              <div className="selectedImageContainer">
                <AddIcon className="removeImageIcon" onClick={() => setNotificationImage(null)} />
                <img className="selectedImage" src={notificationImage} alt="push notification icon" />
              </div>
            ) : (
              <>
                <AddIcon className="addImageIcon" />
                <input
                  type="file"
                  name="PushNotificationPicture"
                  accept="image/*"
                  onChange={onUpload}
                  className="imagePicker"
                />
              </>
            )}
          </div>
        </div>

        <PushNotificationButton
          type="submit"
          disabled={notificationImageLoader}
          style={notificationImageLoader ? { background: '#777b7a' } : {}}
          className="sendPushNotificationButton"
        >
          <BoltIcon className="boltIcon" />
          {t('send_now')}
        </PushNotificationButton>
      </form>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
};

export default withRouter(PushNotification);
