import React, { Fragment, useState, useRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import ShippingData from "qs-data/shipping";
import DialogBox from "qs-common/DialogBox";
import CurrencyMapper from 'qs-helpers/currency';
import Utility from 'qs-helpers/utility';
import { getI18N } from '../../../../i18N';
import "./style.scss";

export default function ({ shippingData, minimumOrderAmount, showDialog, onClose, onSuccess, currencyUnit, deleteMode }) {

  const amountRef = useRef();
  const [isValidAmount, toggleValidAmount] = useState(deleteMode || false);

  const [progressBar, setProgressBar] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = getI18N();

  const updateMinimumShippingCost = async () => {
    setProgressBar(true);
    setErrorMessage(null);
    let val = null;
    if (!deleteMode) {
      val = +parseFloat(amountRef.current.value).toFixed(2);
    } 
    ShippingData.setMinOrderAmountForFreeShipping(shippingData.mode.id, shippingData.zone.id, {
      'amount': deleteMode ? null : val
    }).then(() => {
      setCloseDialog(true);
      onSuccess(val);
    }).catch((err) => {
      setErrorMessage(err);
    }).then(() => {
      setProgressBar(false)
    });
  }

  const onChange = () => {
    let errorMsg = null;
    const isNumberInValid = Utility.isNumberInValid(amountRef);
    if (isNumberInValid) {
      errorMsg = t('amount_cannot_be_less_than', {limit: '0'});
    }
    setErrorMessage(errorMsg);
    toggleValidAmount(!isNumberInValid);
  }

  return <DialogBox
    type={"simple"}
    dialogId={'#add-minimum-price'}
    show={showDialog}
    width={300}
    onClose={onClose}
    onSuccess={updateMinimumShippingCost}
    primaryBtnText={deleteMode ? t('yes') : t('save')}
    disabledPrimaryButton={!isValidAmount}
    showProgressBar={progressBar}
    closeDialog={closeDialog}
    danger={deleteMode}
  >
    <Fragment>
      <div className="changeAmountDialogContent">
        <p className="dialogTitle">
          {deleteMode ? t('are_you_sure_you_want_to_remove_minimum_order_amount') : t('set_minimum_amount')}
          </p>
        {!deleteMode && <TextField
          autoFocus
          className='inputPrice'
          inputRef={amountRef}
          type='number'
          defaultValue={minimumOrderAmount}
          onChange={onChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">{CurrencyMapper[currencyUnit] || currencyUnit}</InputAdornment>,
          }}
          error={errorMessage !== null}
          helperText={errorMessage}
        />}
      </div>
    </Fragment>
  </DialogBox>
}
