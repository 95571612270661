import React, { Fragment, useCallback, useEffect, useReducer, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Loader from 'qs-common/Loader';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import Toast from 'qs-common/Alerts/Toast';
import CheckoutFormFields from './CheckoutFormFields';
import EditCheckoutFormFields from './EditCheckoutFormFields';
import { goBack, setNavigationBarColor, setStatusBarColor } from '../../os';
import { deleteFormFieldComponent, fetchFormFieldComponents } from './formFieldsData';
import { CHECKOUT_FORM_LABELS, CHECKOUT_FORM_STATES } from './constants';
import {
  ADD_NEW_FORM_FIELD,
  checkoutFormFieldsReducer,
  getInitialStateOfCheckoutForm,
  SET_EDIT_STATE,
  SET_NEXT_STATE,
  UPDATE_NEW_FIELD,
} from './reducer';
import { getI18N } from '../../i18N';
import './styles.scss';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';

const primaryColor = '#4DA47A';
const secondaryColor = '#EEEEEE';
const backgroundColor = '#303B4B';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: secondaryColor,
    },
    secondary: {
      main: primaryColor,
    },
    background: {
      paper: backgroundColor,
      default: backgroundColor,
    },
    type: 'dark',
  },
});

const CheckoutForm = () => {
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [formFieldToDelete, setFormFieldToDelete] = useState(null);
  const [errorToast, showErrorToast] = useState(null);
  const [, dispatch] = useAppContext();
  const [checkoutFormsData, dispatchCheckoutFormsData] = useReducer(
    checkoutFormFieldsReducer,
    undefined,
    getInitialStateOfCheckoutForm
  );

  const { t } = getI18N();

  const {
    loading,
    refreshing,
    error,
    enableEdit,
    currentFormState,
    formFieldsData,
    newFormFieldData,
    errorWhileUpdate,
  } = checkoutFormsData;

  const setCurrentFormState = useCallback(
    (nextState) => {
      dispatchCheckoutFormsData({
        type: SET_NEXT_STATE,
        nextState,
      });
    },
    [dispatchCheckoutFormsData]
  );

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t(CHECKOUT_FORM_LABELS.title),
        height: NAVBAR_HEIGHT,
        hideBack: false,
        onBackClick: () => {
          if (enableEdit) {
            dispatchCheckoutFormsData({ type: SET_EDIT_STATE, enableEdit: false });
          }
          if (currentFormState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
            goBack();
            return;
          } else if (currentFormState === CHECKOUT_FORM_STATES.ENTER_NAME) {
            setCurrentFormState(CHECKOUT_FORM_STATES.SELECT_FORM_TYPE);
            return;
          } else if (currentFormState === CHECKOUT_FORM_STATES.SUBMIT) {
            setCurrentFormState(CHECKOUT_FORM_STATES.ENTER_NAME);
            return;
          } else {
            goBack();
            return;
          }
        }
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch, currentFormState, enableEdit]);

  // dependency is not needed as it will only calls api once on component mount
  useEffect(() => fetchFormFieldComponents(dispatchCheckoutFormsData), []);

  useEffect(() => {
    if (errorWhileUpdate) {
      showErrorToast({
        open: true,
        message: `Error: ${
          typeof errorWhileUpdate === 'string' ? t(errorWhileUpdate) : t('update_failed')
        }`,
        onClose: () => showErrorToast(null),
      });
    }
    return () => showErrorToast(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorWhileUpdate]);

  const showSnackbarMessage = useCallback(
    (message) => {
      showErrorToast({ open: true, message, onClose: () => showErrorToast(null) });
    },
    [showErrorToast]
  );

  const fetchData = () => fetchFormFieldComponents(dispatchCheckoutFormsData);

  const addNewFormField = () => {
    if (error) {
      return;
    }
    dispatchCheckoutFormsData({ type: ADD_NEW_FORM_FIELD });
  };

  const onCloseAddNewFormField = useCallback(
    () =>
      dispatchCheckoutFormsData({
        type: UPDATE_NEW_FIELD,
        formField: null,
      }),
    []
  );

  const onDeleteFormField = (formField) => setFormFieldToDelete(formField);

  const onCloseDeleteFormFieldConfirmation = () => setFormFieldToDelete(null);

  const onClickConfirmDelete = () =>
    deleteFormFieldComponent(formFieldToDelete, dispatchCheckoutFormsData, showSnackbarMessage)
      .then(onCloseDeleteFormFieldConfirmation)
      .catch(onCloseDeleteFormFieldConfirmation);

  const renderConfirmationDialog = () => {
    if (!formFieldToDelete) {
      return;
    }
    return (
      <Dialog open={!!formFieldToDelete}>
        <DialogContent id="confirmation-box-message">
          <Typography>{t(CHECKOUT_FORM_LABELS.CONFIRMATION_BOX_MESSAGE)}</Typography>
        </DialogContent>
        <DialogActions>
          {formFieldToDelete && refreshing ? <Loader small /> : ''}
          <Button
            variant="text"
            color="primary"
            type="button"
            disabled={refreshing}
            onClick={onCloseDeleteFormFieldConfirmation}
          >
            {t('cancel')}
          </Button>
          <Button
            id="confirm-delete-btn"
            variant="text"
            color="secondary"
            type="submit"
            disabled={refreshing}
            onClick={onClickConfirmDelete}
          >
            {t(CHECKOUT_FORM_LABELS.CONFIRM_DELETE_BUTTON_TEXT)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <div className="checkout-form-loader-container">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="checkout-form-error-container">
        <CustomErrorComponent
          errorMessage={t(CHECKOUT_FORM_LABELS.COMMON_ERROR_MESSAGE)}
          onRetryClick={fetchData}
          style={{ textAlign: 'center' }}
        />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className={enableEdit ? 'editable-checkout-form-container' : 'checkout-form-container'}>
        {enableEdit ? (
          <EditCheckoutFormFields
            theme={theme}
            refreshing={refreshing}
            newFormField={newFormFieldData}
            formFieldsData={formFieldsData}
            currentFormState={currentFormState}
            onCloseCallback={onCloseAddNewFormField}
            dispatchCheckoutFormsData={dispatchCheckoutFormsData}
            showSnackbarMessage={showSnackbarMessage}
          />
        ) : (
          <Fragment>
            <CheckoutFormFields
              refreshing={refreshing}
              formFieldsData={formFieldsData}
              onDeleteCallback={onDeleteFormField}
              dispatchCheckoutFormsData={dispatchCheckoutFormsData}
              showSnackbarMessage={showSnackbarMessage}
              useDragHandle
            />
            <div className="form-field-action-btns">
              <Button
                id="add-btn-action-checkout-form"
                className="add-button"
                classes={{ label: 'add-button-label' }}
                aria-label="add"
                variant="contained"
                color="secondary"
                disabled={loading || refreshing || error}
                onClick={addNewFormField}
              >
                <Add /> {t('add_field')}
              </Button>
            </div>
          </Fragment>
        )}
      </Box>
      {renderConfirmationDialog()}
      <Toast {...(errorToast || {})} />
    </ThemeProvider>
  );
};

export default CheckoutForm;
