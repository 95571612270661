import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export default function Toast(props) {
  const [state] = useState({
    vertical: 'bottom',
    horizontal: 'center',
  });

  const { vertical, horizontal } = state;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      open={props.open}
      message={props.message}
      onClose={props.onClose}
      ContentProps={{ style: props.style }}
      autoHideDuration={props.duration || 2000}
    />
  );
}
