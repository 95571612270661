import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import CustomCheckboxGroup from 'qs-common/CustomCheckboxGroup';
import DraggableIcon from 'qs-assets/icons/DraggableIcon';
import Delete from '@material-ui/icons/Delete';
import Loader from 'qs-common/Loader';
import FieldIcon from './FormFieldIcon';
import { SET_NEXT_STATE, UPDATE_NEW_FIELD } from '../reducer';
import { CHECKOUT_FORM_STATES, CHECKOUT_FORM_FIELD_TYPES } from '../constants';
import { addNewFormFieldComponent } from '../formFieldsData';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import {
  getNextFormState,
  checkIfNewFieldIsValid,
  checkIfOptionsAreRequiredFormField,
  getOptionIfRequiredForFormField,
  registerGetSelectedFormField,
  getAvailableOptionsToSelect,
  getCheckoutFormFieldTypeByType,
} from '../helpers';
import './style.scss';
import { getI18N } from '../../../i18N';

const SortableDragHandle = SortableHandle(({ className }) => (
  <DraggableIcon className={className} />
));

const SortableItem = SortableElement(({
  fieldOption,
  fieldOptionIndex,
  onChangeOptionValueHandler,
  onDeleteOptionValueHandler,
  formFieldOptionsHasError,
  refreshing,
}) => {
  const { t } = getI18N();
  const inputRef = useRef(null);

  useEffect(() => {
    if (formFieldOptionsHasError === fieldOptionIndex && inputRef.current && typeof inputRef.current.focus === 'function') {
      inputRef.current.focus();
    }
  }, [formFieldOptionsHasError, fieldOptionIndex])

  return (
    <ButtonBase
      className="new-field-options-list-item"
      classes={{ root: 'new-field-options-list-item-sortable' }}
      disabled={refreshing}
      disableRipple={true}
      component="li"
    >
      <TextField
        type="text"
        variant="outlined"
        value={fieldOption}
        className={`new-field-label-input${formFieldOptionsHasError === fieldOptionIndex ? ' new-field-label-input-error' : ''}`}
        placeholder={t('enter_choice_number', { number: fieldOptionIndex + 1 })}
        error={formFieldOptionsHasError === fieldOptionIndex}
        onChange={({ target: { value: fieldOptionValue } }) => onChangeOptionValueHandler(fieldOptionValue, fieldOptionIndex)}
        inputRef={inputRef}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={refreshing}
      />
      <Box className="new-field-label-actions">
        <IconButton
          disabled={refreshing}
          disableRipple={refreshing}
          className="remove-btn"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onDeleteOptionValueHandler(fieldOptionIndex);
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          disabled={refreshing}
          disableRipple={true}
          className="drag-btn"
        >
          <SortableDragHandle />
        </IconButton>
      </Box>
    </ButtonBase>
  );
});

const SortableList = SortableContainer(({
  fieldOptions = [],
  onChangeOptionValueHandler,
  onDeleteOptionValueHandler,
  formFieldOptionsHasError,
  refreshing,
}) => {
  return (
    <List className="new-field-options-list">
      {fieldOptions.map((fieldOption, fieldOptionIndex) =>
        <SortableItem
          key={fieldOptionIndex}
          index={fieldOptionIndex}
          fieldOption={fieldOption}
          fieldOptionIndex={fieldOptionIndex}
          onChangeOptionValueHandler={onChangeOptionValueHandler}
          onDeleteOptionValueHandler={onDeleteOptionValueHandler}
          formFieldOptionsHasError={formFieldOptionsHasError}
          refreshing={refreshing}
        />
      )}
    </List>
  );
});

const EditCheckoutFormFields = ({
  theme = {},
  refreshing,
  newFormField,
  formFieldsData,
  currentFormState,
  onCloseCallback,
  dispatchCheckoutFormsData,
  showSnackbarMessage,
}) => {
  const { t } = getI18N();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';

  const [formFieldLabelHasError, setFormFieldLabelHasError] = useState(false);
  const [formFieldOptionsHasError, setFormFieldOptionsHasError] = useState(-1);

  const updateNewFormField = useCallback(
    (updatedFormField) => {
      dispatchCheckoutFormsData({
        type: UPDATE_NEW_FIELD,
        formField: updatedFormField,
      });
    },
    [dispatchCheckoutFormsData]
  );

  const updateFormState = (changedState) => {
    if (!changedState) {
      return;
    }
    const copiedNewFormField = { ...newFormField, ...changedState };
    updateNewFormField(copiedNewFormField);
    registerGetSelectedFormField(copiedNewFormField);
  };

  const setCurrentFormState = useCallback(
    (nextState) => {
      dispatchCheckoutFormsData({
        type: SET_NEXT_STATE,
        nextState,
      });
    },
    [dispatchCheckoutFormsData]
  );

  const setNextFormState = (nextFormState) => {
    setCurrentFormState(
      getNextFormState(nextFormState === undefined ? currentFormState : nextFormState)
    );
  };

  const addNewOptionInFormField = () => {
    const fieldOptions = [...(newFormField.fieldOptions || [])];
    updateFormState({ fieldOptions: fieldOptions.concat('') });
  };

  const onSubmitHandler = useCallback((event) => {
    event.preventDefault();
    if (
      checkIfNewFieldIsValid(newFormField, {
        fieldLabelErrorCallback: setFormFieldLabelHasError,
        fieldOptionsErrorCallback: setFormFieldOptionsHasError,
      })
    ) {
      addNewFormFieldComponent(
        newFormField,
        formFieldsData,
        dispatchCheckoutFormsData,
        (message) => {
          onCloseCallback();
          showSnackbarMessage(typeof message === 'string' ? t(message) : message);
          onCloseCallback();
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFormField, formFieldsData, dispatchCheckoutFormsData, onCloseCallback, showSnackbarMessage]);

  const addFieldTypeToFormField = (fieldType) => {
    setTimeout(() => {
      updateFormState({ fieldType, fieldOptions: getOptionIfRequiredForFormField(fieldType) });
      setNextFormState(CHECKOUT_FORM_STATES.ENTER_NAME);
    }, 500);
  };

  const onChangeFieldLabelHandler = (event) => {
    const { value: fieldName } = event.target;
    updateFormState({ fieldName });
    setFormFieldLabelHasError(!fieldName);
  };

  const onChangeFieldRequiredHandler = (event) => {
    const { checked: required } = event.target;
    updateFormState({ required });
  };

  const onReorderOptionsHandler = ({ oldIndex, newIndex }) => {
    const fieldOptions = [...(newFormField.fieldOptions || [])];
    const [removed] = fieldOptions.splice(oldIndex, 1);
    fieldOptions.splice(newIndex, 0, removed);
    updateFormState({ fieldOptions });
  };

  const onChangeOptionValueHandler = (optionValue, optionIndex) => {
    const fieldOptions = [...(newFormField.fieldOptions || [])];
    fieldOptions.splice(optionIndex, 1, optionValue);
    updateFormState({ fieldOptions });
    setFormFieldOptionsHasError(-1);
  };

  const onDeleteOptionValueHandler = (optionIndex) => {
    const fieldOptions = [...(newFormField.fieldOptions || [])];
    fieldOptions.splice(optionIndex, 1);
    updateFormState({ fieldOptions });
    setFormFieldOptionsHasError(-1);
  };

  const handleOnChangeOption = (selectedOptions) => {
    updateFormState({ fieldOptions: selectedOptions });
  };

  const renderFormTitle = () => {
    const { label } = getCheckoutFormFieldTypeByType(newFormField.fieldType);
    if (!label) {
      return;
    }
    return (
      <Box className="form-field-list-details form-title">
        {t(label)}
      </Box>
    );
  };

  const renderOptionsComponent = () => {
    const { fieldType, fieldOptions = {} } = newFormField;

    // SINGLE_CHOICE and MULTI_CHOICE
    if (checkIfOptionsAreRequiredFormField(fieldType)) {
      return (
        <Fragment>
          {(Array.isArray(fieldOptions) && fieldOptions.length > 0) && (
            <SortableList
              axis="y"
              lockAxis="y"
              lockToContainerEdges={true}
              distance={desktop ? 1 : undefined}
              pressDelay={desktop ? undefined : 200}
              onSortEnd={onReorderOptionsHandler}
              useDragHandle={true}
              helperClass="new-field-options-list-dragging-item"
              fieldOptions={fieldOptions}
              onChangeOptionValueHandler={onChangeOptionValueHandler}
              onDeleteOptionValueHandler={onDeleteOptionValueHandler}
              formFieldOptionsHasError={formFieldOptionsHasError}
              refreshing={refreshing}
            />
          )}
          <Button
            className="add-new-option-btn"
            type="button"
            variant="outlined"
            color="secondary"
            onClick={addNewOptionInFormField}
          >
            + {t('add')}
          </Button>
        </Fragment>
      );
    }

    // DATE
    const availableSelectableOptionsConfig = getAvailableOptionsToSelect(fieldType);
    if (availableSelectableOptionsConfig) {
      const { label, options = [] } = availableSelectableOptionsConfig;
      return Array.isArray(options) && options.length > 0 && (
        <CustomCheckboxGroup
          className="new-field-available-options"
          labelClassName="new-field-available-options-label"
          label={label}
          options={options}
          onChange={handleOnChangeOption}
        />
      );
    }
  };

  const renderFormContent = () => {
    const { main: secondaryColor } = (theme.palette || {}).secondary || { main: {} };
    if (currentFormState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
      return (
        <List className="field-type-list">
          {CHECKOUT_FORM_FIELD_TYPES.map(({ fieldType, label, tagline }, index) => (
            <ButtonBase
              key={index}
              disabled={refreshing}
              disableRipple={refreshing}
              className="field-type-list-item"
              onClick={() => addFieldTypeToFormField(fieldType)}
              component="li"
            >
              <Box className="form-field-list-details-container">
                <Box className="icon">
                  <FieldIcon iconType={fieldType} color={secondaryColor} />
                </Box>
                <Box className="form-field-list-details">
                  <h5 className="name">
                    {t(label)}
                  </h5>
                  <h6 className="tagline">
                    {t(tagline)}
                  </h6>
                </Box>
              </Box>
            </ButtonBase>
          ))}
        </List>
      );
    }

    if (currentFormState === CHECKOUT_FORM_STATES.ENTER_NAME) {
      const { fieldName, required } = newFormField || {};
      return (
        <Fragment>
          <TextField
            type="text"
            placeholder={t('enter_your_field_name_here')}
            className="new-field-label-input"
            autoFocus
            value={fieldName}
            error={formFieldLabelHasError}
            onChange={onChangeFieldLabelHandler}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={refreshing}
          />
          {renderOptionsComponent()}
          <FormControlLabel
            className="new-field-required"
            control={
              <Checkbox
                label={t('required')}
                checked={required || false}
                onChange={onChangeFieldRequiredHandler}
                classes={{ root: 'new-field-required-checkbox' }}
              />
            }
            label={t('required_field')}
          />
        </Fragment>
      );
    }
  };

  const renderFormActions = () => {
    if (currentFormState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
      return;
    }
    return (
      <Box className="actions">
        <Button
          className="submit-btn"
          variant="contained"
          type="submit"
          color="secondary"
          disabled={refreshing || formFieldLabelHasError || !checkIfNewFieldIsValid(newFormField)}
          onClick={onSubmitHandler}
        >
          {refreshing ? <Loader small /> : t('save')}
        </Button>
      </Box>
    );
  };

  return (
    <Fragment>
      <Box className={`form-content${currentFormState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE ? ' form-type-content' : ''}`}>
        {renderFormTitle()}
        {renderFormContent()}
      </Box>
      {renderFormActions()}
    </Fragment>
  );
};

export default EditCheckoutFormFields;
