import {
  EmailChanger,
  PaymentDetail,
  Payments,
  Webinar,
  WebinarList,
  OtherPages,
  OtherPage,
  CustomFields,
  CheckoutForm,
  ReferralPage,
  MyReferralsPage,
  ReferralTermsAndCondition,
  Reports,
  ShippingSettings,
  ShippingProfiles,
  ShippingModeSettings,
  ShiprocketShippingSetting,
  ShippingRates,
  GoogleMerchantCenter,
  GoogleMerchantCenterDetails,
  FacebookPixel,
  GoogleAnalytics,
  ThemesManager,
  ThemeUpdate,
  ThemesColorPicker,
  Coupons,
  CouponForm,
  HelpCenter,
  MetalRate,
  ProductType,
  Learn,
  VariantsManager,
  ManageApp,
  PushNotification,
  Engage,
  ConfigureShipRocketShippingSettings,
  IndianRefferalMobileHomePage,
  YourReferrals,
  YourRewards,
  SelectContacts,
  GoogleSearchConsole,
  GoogleSearchConsoleDetails,
  PrivacySettings,
  GlobalGroups,
  GroupMembers,
  AddExistingGroup,
  CataloguePrivacySettings,
  ManageApiKey,
  AllowedCustomer,
  PendingUsers,
  ExpiredUsers,
  SelectCustomer,
  SmsSettings,
  SmsUsageReport,
  ForgotLogin,
  JewelleryPriceDisplay,
  CustomWebsiteFonts,
  Webhooks,
  WebhooksForm,
  CreateOrders,
  ChooseCustomer,
} from '../pages';

const Routes = [
  {
    path: '/payments',
    component: Payments,
    exact: true,
  },
  {
    path: '/payments/:id',
    component: PaymentDetail,
    exact: false,
  },
  {
    path: '/google-merchant-center/details',
    component: GoogleMerchantCenterDetails,
    exact: true,
  },
  {
    path: '/google-merchant-center',
    component: GoogleMerchantCenter,
    exact: true,
  },
  {
    path: '/change-email',
    component: EmailChanger,
    exact: true,
  },
  {
    path: '/webinar',
    component: WebinarList,
    exact: true,
  },
  {
    path: '/webinar/:id',
    component: Webinar,
    exact: true,
  },
  {
    path: '/other-pages',
    component: OtherPages,
    exact: true,
  },
  {
    path: '/other-page/:pageType',
    component: OtherPage,
    exact: true,
  },
  {
    path: '/custom-fields',
    component: CustomFields,
    exact: true,
  },
  {
    path: '/jewellery-price-display',
    component: JewelleryPriceDisplay,
    exact: true,
  },
  {
    path: '/webhooks',
    component: Webhooks,
    exact: true,
  },
  {
    path: '/webhooks/create',
    component: WebhooksForm,
    exact: true,
  },
  {
    path: '/webhooks/edit/:url',
    component: WebhooksForm,
    exact: true,
  },
  {
    path: '/custom-fonts',
    component: CustomWebsiteFonts,
    exact: true,
  },
  {
    path: '/checkout-form',
    component: CheckoutForm,
    exact: true,
  },
  {
    path: '/referral',
    component: ReferralPage,
    exact: true,
  },
  {
    path: '/referral/track/:isIndiaReferral',
    component: MyReferralsPage,
    exact: true,
  },
  {
    path: '/referral/tnc',
    component: ReferralTermsAndCondition,
    exact: true,
  },
  {
    path: '/referrals',
    component: IndianRefferalMobileHomePage,
    exact: true,
  },
  {
    path: '/referrals/yourReferrals',
    component: YourReferrals,
    exact: true,
  },
  {
    path: '/referrals/yourRewards',
    component: YourRewards,
    exact: true,
  },
  {
    path: '/referrals/selectContacts',
    component: SelectContacts,
    exact: true,
  },
  {
    path: '/privacy-settings',
    component: PrivacySettings,
    exact: true,
  },
  {
    path: '/global-groups/:globalGroupId',
    component: GlobalGroups,
    exact: true,
  },
  {
    path: '/group-members/:entityType/:globalGroupId/:groupId',
    component: GroupMembers,
    exact: true,
  },
  {
    path: '/add-existing-groups/:entityType/:accessLevel',
    component: AddExistingGroup,
    exact: true,
  },
  {
    path: '/catalogue-privacy-settings/:entityId/:entityType/:accessLevel',
    component: CataloguePrivacySettings,
    exact: true,
  },
  {
    path: '/sms-settings',
    component: SmsSettings,
    exact: true,
  },
  {
    path: '/sms-usage-report',
    component: SmsUsageReport,
    exact: true,
  },
  {
    path: '/reports',
    component: Reports,
    exact: true,
  },
  {
    path: '/shipping-settings',
    component: ShippingModeSettings,
    exact: true,
  },
  {
    path: '/custom-shipping-settings',
    component: ShippingSettings,
    exact: true,
  },
  {
    path: '/shiprocket-shipping-settings',
    component: ShiprocketShippingSetting,
    exact: true,
  },
  {
    path: '/configure-shiprocket-settings',
    component: ConfigureShipRocketShippingSettings,
    exact: true,
  },
  {
    path: '/shipping-profiles',
    component: ShippingProfiles,
    exact: true,
  },
  {
    path: '/shipping-rates',
    component: ShippingRates,
    exact: true,
  },
  {
    path: '/facebook-pixel',
    component: FacebookPixel,
    exact: true,
  },
  {
    path: '/google-analytics',
    component: GoogleAnalytics,
    exact: true,
  },
  {
    path: '/google-search-console',
    component: GoogleSearchConsole,
    exact: true,
  },
  {
    path: '/google-search-console/details',
    component: GoogleSearchConsoleDetails,
    exact: true,
  },
  {
    path: '/themes-manager',
    component: ThemesManager,
    exact: true,
  },
  {
    path: '/theme-update',
    component: ThemeUpdate,
    exact: true,
  },
  {
    path: '/color-picker',
    component: ThemesColorPicker,
    exact: true,
  },
  {
    path: '/coupons',
    component: Coupons,
    exact: true,
  },
  {
    path: '/coupons/:action/',
    component: CouponForm,
    exact: true,
  },
  {
    path: '/coupons/:action/:code',
    component: CouponForm,
    exact: true,
  },
  {
    path: '/help-center/:videoId/:from',
    component: HelpCenter,
    exact: true,
  },
  {
    path: '/learn',
    component: Learn,
    exact: true,
  },
  {
    path: '/metal-rate',
    component: MetalRate,
    exact: true,
  },
  {
    path: '/metal-rate/:metalType',
    component: MetalRate,
    exact: false,
  },
  {
    path: '/product-type',
    component: ProductType,
    exact: true,
  },
  {
    path: '/variants-manager',
    component: VariantsManager,
  },
  {
    path: '/manage-app',
    component: ManageApp,
    exact: true,
  },
  {
    path: '/push-notification',
    component: PushNotification,
    exact: true,
  },
  {
    path: '/engage',
    component: Engage,
    exact: true,
  },
  {
    path: '/manage-api-key',
    component: ManageApiKey,
    exact: true,
  },
  {
    path: '/allowed-customer',
    component: AllowedCustomer,
    exact: true,
  },
  {
    path: '/pending-users',
    component: PendingUsers,
    exact: true,
  },
  {
    path: '/expired-users',
    component: ExpiredUsers,
    exact: true,
  },
  {
    path: '/select-customer',
    component: SelectCustomer,
    exact: true,
  },
  {
    path: '/forgot-login',
    component: ForgotLogin,
    exact: true,
  },
  {
    path: '/create-order',
    component: CreateOrders,
    exact: true,
  },
  {
    path: '/choose-customer',
    component: ChooseCustomer,
    exact: true,
  },
];

export default Routes;
