import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  actionButton: {
    textTransform: 'none',
    flex: '0 0 auto',
    border: 'none',
    marginRight: '12px',
    marginTop: '13px',
    borderRadius: '6px',
    backgroundColor: '#49505d',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: 700,
    fontStyle: 'normal',
    letterPpacing: '0px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#49505d',
      borderColor: '#49505d',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#49505d',
      borderColor: '#49505d',
    },
  },
  generateKeyButton: {
    padding: '6px 11px',
    textTransform: 'none',
    flex: '0 0 auto',
    border: 'none',
    marginTop: '16px',
    borderRadius: '5px',
    backgroundColor: '#54ae82',
    marginRight: 'auto',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: '700',
    fontStyle: 'normal',
    letterSpacing: '0px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#54ae82',
      borderColor: '#54ae82',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#54ae82',
      borderColor: '#54ae82',
    },
  },
});

export default useStyles;
