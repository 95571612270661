import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DialogBox from "qs-common/DialogBox";
import Dialog from "qs-common/Alerts/Dialog";
import CustomTextField from "qs-common/CustomTextField";
import { getI18N } from "../../../i18N";

const useStyles = makeStyles({
  recieveBtn: {
    background: "#31BD84",
    color: "#FFFFFF",
    marginBottom: "10px",
    marginTop: "20px",
    width: 154,
    "&:hover": {
      background: "rgba(87,221,156,0.88)",
    },
    textTransform: "uppercase"
  },
  steps: {
    display: "flex",
    flexFlow: "column",
  },
  inputField: {
    marginTop: "20px",
  },
  content: {
    padding: "10px 0px 40px",
    lineHeight: "1.5",
  },
  stepTwoButton: {
    color: "#92CFB3",
    width: "max-content",
    alignSelf: "flex-end",
  },
  highlightEmail: {
    fontSize: "bold",
    color: "#92CFB3",
  },
});

export default function AddUserEmail({
  setToastState,
  onSendVerificationEmail,
  setShowDialogue,
}) {
  const classes = useStyles();
  const [newEmail, setNewEmail] = useState("");
  const [step, setStep] = useState(0);

  const { t } = getI18N();

  const validateEmail = () => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(newEmail);
  };

  const submitEmail = () => {
    if (!validateEmail()) {
      setToastState({
        open: true,
        message: t('enter_valid_email'),
      });
      return;
    }
    setStep(1);
  };

  return (
    <DialogBox
      width={400}
      show={true}
      title={step === 0 ? t('email_setting') : t('email_verification')}
      type={"custom"}
      hideFooter={true}
      onClose={() => setShowDialogue(false)}
    >
      <div className={`${classes.steps}`}>
        {step === 0 ? (
          <>
            <span style={{ color: "#999DA4", marginTop: 0, marginBottom: 10 }}>
              {t('to_receive_the_report_please_set_the_email_id_on_your_account')}
            </span>
            <CustomTextField
              fullWidth
              variant="filled"
              label={t('email')}
              margin="normal"
              name="displayEmail"
              type="email"
              value={newEmail}
              onChange={(event) => {
                setNewEmail(event.target.value);
              }}
            />
            <Button
              onClick={() => submitEmail()}
              className={`${classes.recieveBtn}`}
            >
              {t('set_email_id')}
            </Button>
          </>
        ) : (
          <Dialog
            open={true}
            title={t('email_verification')}
            description={t('we_will_send_an_email_to_verify_account_email', { email: newEmail })}
            confirmText={t('proceed_with_verification')}
            onClose={() => setShowDialogue(false)}
            handleConfirm={() => onSendVerificationEmail(newEmail)}
          />
        )}
      </div>
    </DialogBox>
  );
}
