import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Loader from '../../../common/Loader';
import Toast from '../../../common/Alerts/Toast';
import { useAppContext } from '../../../common/Contexts/AppContext';
import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../../os';
import CustomerList from 'qs-components/CustomerList';
import './style.scss';
import CustomerListData from 'qs-data/customerList';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import DialogBox from 'qs-common/DialogBox';
import { createQueryParams } from 'qs-data/util';
import ContactPermission from '../ContactPermission';
import { PENING_MEMEBRS_LENGTH } from 'qs-data/globalConstants';
import { getContactListMap, getNameFromContactList } from 'qs-helpers/contacts';
import usePhoneUtil from 'qs-common/Hooks/usePhoneUtil';
import { getI18N } from '../../../i18N';

function PendingUsers() {
  const { t } = getI18N();
  const [loading, setLoading] = useState(false);
  const [errorComponent, setErrorComponent] = useState(false);
  const [pendingRequest, setPendingRequest] = useState();
  const [dialog, setDialog] = useState(false);
  const [disableDialogButton, setDisableDialogButton] = useState();
  const [page, setPage] = useState(1);
  const [shouldStopPaginating, setShouldStopPaginating] = useState(false);
  const [paginatedListLoading, setPaginatedListLoading] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [hasContactsPermission, setHasContactsPermission] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [expandSearchBox, setExpandSearchBox] = useState(false);
  const { phoneUtil, loading: utilLoading } = usePhoneUtil();

  const NAVBAR_HEIGHT = 55;

  let POP_STATE_COUNTER = 0;

  const popStateListener = () => {
    if (POP_STATE_COUNTER === 0) {
      POP_STATE_COUNTER++;
    } else {
      onClose();
      POP_STATE_COUNTER = 0;
    }
  };

  const onScrollHandler = (event) => {
    const element = event.target;
    if (element.scrollHeight - Math.ceil(element.scrollTop) === element.clientHeight && !paginatedListLoading && !shouldStopPaginating) {
      setPaginatedListLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', popStateListener);
    return () => {
      window.removeEventListener('popstate', popStateListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStopPaginating, paginatedListLoading, page]);

  const onSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearchExpand = useCallback(() => {
    setExpandSearchBox(!expandSearchBox);
    setSearchText('');
  }, [expandSearchBox]);

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#4DA47A',
        color: '#FFFFFF',
        title: t('pending_requests'),
        placeholder: t('search_your_contacts'),
        searchable: true,
        expandSearchBox: expandSearchBox,
        onClick: onSearchExpand,
        onChange: (e) => onSearch(e),
        value: searchText,
        hideBack: false,
        height: NAVBAR_HEIGHT,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#252c36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, expandSearchBox, onSearch, onSearchExpand]);

  const getPendingList = useCallback(() => {
    let query;

    if (!pendingRequest || !pendingRequest.members.length) {
      setLoading(true);
    } else {
      query = createQueryParams([
        {
          key: 'lastDateCreated',
          value: pendingRequest.members
            ? [...pendingRequest.members].splice(-1)[0].dateCreated
            : null,
        },
      ]);
    }

    if (
      pendingRequest &&
      [...pendingRequest.members].splice(-1)[0] &&
      ![...pendingRequest.members].splice(-1)[0].dateCreated
    ) {
      setPaginatedListLoading(false);
      setLoading(false);
      return;
    }

    CustomerListData.getCustomerRequest(query)
      .then(({ members }) => {
        setPendingRequest({
          ...pendingRequest,
          members: pendingRequest ? pendingRequest.members.concat(members) : members,
        });
        if (members.length === 0) {
          setShouldStopPaginating(true);
        }
      })
      .catch(() => {
        setErrorComponent(true);
        setToastState({
          open: true,
          message: t('an_error_occured_while_pending_requests'),
        });
      }).finally(() => {
        setPaginatedListLoading(false);
        setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleAccessAll = () => {
    setDisableDialogButton(true);
    CustomerListData.allowAccessToAll()
      .then(() => {
        setDialog(false);
        setDisableDialogButton(false);
        setToastState({
          open: true,
          message: t('all_are_allowed_access_successfully'),
        });
        getPendingList();
      })
      .catch(() => {
        setDialog(false);
        setDisableDialogButton(false);
        setToastState({
          open: true,
          message: t('an_error_occured_while_providing_access_to_all'),
        });
      });
  };

  const onClose = () => {
    setDialog(false);
  };

  const removeRow = ({ id }) => {
    const updatedList = pendingRequest.members.filter((customer) => {
      if (customer.id !== id) {
        return customer;
      }
      return undefined;
    });
    setPendingRequest({
      ...pendingRequest,
      members: updatedList,
    });
    if (pendingRequest.members.length < PENING_MEMEBRS_LENGTH) {
      setPaginatedListLoading(false);
    }
  };

  useEffect(() => {
    getPendingList();
    trackAnalytics({
      eventName: 'view_pending_request_list',
    });
  }, [getPendingList]);

  return loading || utilLoading ? (
    <div className="componentContainer">
      <Loader />
    </div>
  ) : errorComponent ? (
    <div className="componentContainer">
      <CustomErrorComponent onRetryClick={getPendingList} />
    </div>
  ) : (
    <div className="pendingUsers">
      <ContactPermission
        hasContactsPermission={hasContactsPermission}
        setHasContactsPermission={setHasContactsPermission}
      />
      {pendingRequest && pendingRequest.members && pendingRequest.members.length > 0 && (
        <Fragment>
          <div className="pendingRequest" onScroll={onScrollHandler}>
            <Fragment>
              {pendingRequest.members.map((request, index) => {
                const phoneNumber =
                  !!(request || {}).phones && !!request.phones[0] ? request.phones[0] : '';
                const name =
                  getContactListMap().get(phoneNumber) ||
                  getNameFromContactList(phoneNumber, phoneUtil) ||
                  request.name ||
                  '';
                if (expandSearchBox && !name.toLowerCase().includes(searchText.toLowerCase())) {
                  return null;
                }
                return (
                  <CustomerList
                    key={index}
                    id={request.id}
                    title={name}
                    subtitle={request.phones}
                    buttonText="Allow"
                    timerIcon
                    closeIcon
                    removeRow={removeRow}
                  />
                );
              })}
              {(pendingRequest.members.length > 1 && paginatedListLoading) && (
                <div className="rowLoading">
                  <Loader small />
                </div>
              )}
            </Fragment>
          </div>
          <div className="allowAllPendingRequests">
            <Button style={{ backgroundColor: '#4DA47A' }} onClick={handleAccessAll}>
              <span>{t('allow_all')}</span>
            </Button>
          </div>
        </Fragment>
      )}

      {pendingRequest && !(pendingRequest.members.length > 0) && (
        <div className="titleRecentCustomers noList">
          {t('no_requests')}
        </div>
      )}

      {dialog && (
        <DialogBox
          type="simple"
          show={dialog}
          onClose={onClose}
          width={350}
          primaryBtnText={t('allow_all')}
          secondaryBtnText={t('cancel')}
          disabledPrimaryButton={disableDialogButton}
          onSuccess={handleAccessAll}
        >
          <div>
            <div className="timerTitle">{t('allow_access')}</div>
            <div className="timerContent">{t('give_all_permanent_access')}</div>
          </div>
        </DialogBox>
      )}

      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}

export default withRouter(PendingUsers);
