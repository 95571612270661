import React, { useEffect } from 'react';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import './style.scss';
import { getI18N } from '../../i18N';

export default function ReferralTermsAndCondition() {
  const { t } = getI18N();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#ffffff',
        color: '#242B35',
        title: <b>{t('terms_and_conditions')}</b>,
        boxShadow: 'none',
        borderBottom: '1px solid #ffffff',
        hideBack: desktop,
      },
    });
    setNavigationBarColor('#ffffff');
    setStatusBarColor('#ffffff');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="referralTermsAndCondition">
      <ol>
        <li>
          {t(
            'a_user_needs_to_create_an_account_on_quicksell_and_purchase_a_subscription_plan_to_be_eligible_to_get_a_free_rs_2000_amazon_gift_card'
          )}
        </li>
        <li>
          <b>{t('valid_referral')}</b>
          {t(
            'a_referral_will_be_counted_successful_only_when_the_referee_purchases_any_quicksell_subscription_plan_using_the_referral_code_or_link_shared_by_the_referrer'
          )}
          <br />
          <b>{t('referrer')}</b>
          {t(
            'the_person_who_refers_quicksell_to_their_friends_using_their_unique_referral_code_link'
          )}
          <br />
          <b>{t('referee')}</b>
          {t(
            'the_person_who_purchases_the_quicksell_subscription_plan_using_the_refer_code_link_shared_by_the_referrer'
          )}
        </li>
        <li>
          {t('referral_tnc_pt3_a')}
          <br />
          {t('referral_tnc_pt3_b')}
        </li>
        <li>{t('referral_tnc_pt4')}</li>
        <li>{t('referral_tnc_pt5')}</li>
        <li>{t('referral_tnc_pt6')}</li>
        <li>{t('referral_tnc_pt7')}</li>
      </ol>
    </div>
  );
}
