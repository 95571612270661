import React, { useEffect, useState, useCallback, useRef } from 'react';
import './styles.scss';
import { useParams } from 'react-router';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import TrashIcon from 'qs-assets/images/ic_trash.png';
import {
  COUNT_TYPE,
  ENTITY_TYPE,
  getGroupMemberCacheKey,
  GlOBAL_GROUPS,
  MEMBER_COLORS,
  renderCount,
  renderIcon,
} from '../privacySettingConstants';
import Toast from 'qs-common/Alerts/Toast';
import network from 'qs-data/network';
import CustomCheckbox from 'qs-common/CustomCheckbox';
import { makeStyles } from '@material-ui/core';
import PrivacySettingsCache from '../PrivacySettingsCache';
import Loader from 'qs-common/Loader';
import ConfirmationDialogBox from '../ConfirmationDialogBox';
import CacheKeys from '../CacheKeys';
import { getI18N } from '../../../i18N';

const useStyles = makeStyles({
  checkBoxStyle: {
    padding: 0,
  },
  formControlLabel: {
    marginLeft: '0px',
    marginRight: '0px',
  },
});

const GroupMembers = () => {
  const { t } = getI18N();
  const classes = useStyles();
  const [, dispatch] = useAppContext();
  const { groupId, entityType, globalGroupId } = useParams();

  const headerSectionRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [expandSearchBox, setExpandSearchBox] = useState(false);
  const [groupMembersList, setGroupMembersList] = useState(
    PrivacySettingsCache.getCacheForKey(getGroupMemberCacheKey(entityType, groupId)) || []
  );
  const [searchResults, setSearchResults] = useState([]);
  const [isAnyGroupMembersSelected, setGroupMembersSelected] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [scrolling, setScrolling] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);

  const [confirmationBoxDetails, setConfirmationBoxDetails] = useState({});
  const [headerHeight, setHeaderSectionHeight] = useState(null);

  const unSelectAllGroupsMembers = () => {
    const updatedGroupsMembers = (groupMembersList || []).map((groupMembers) => ({
      ...groupMembers,
      checked: false,
    }));
    setGroupMembersList(updatedGroupsMembers);
  };

  const onSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onClose = () => {
    setDeleteConfirmationDialog(false);
    setConfirmationBoxDetails({});
  };

  const closeSelectedGroupMemberNavbar = useCallback(() => {
    setGroupMembersSelected(!isAnyGroupMembersSelected);
    setSearchText('');
    setExpandSearchBox(false);
    unSelectAllGroupsMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyGroupMembersSelected]);

  const onSearchExpand = useCallback(() => {
    setExpandSearchBox(!expandSearchBox);
    setSearchText('');
  }, [expandSearchBox]);

  const showDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(true);
    const count = (getSelectedGroupMembers() || []).length;
    const details = {
      title: t('remove_members'),
      message: t(count === 1 ? 'remove_group_member_confirmation' : 'remove_group_members_confirmation', {
        count
      }),
      onClose: onClose,
      api: deleteSelectedMembers,
    };
    setConfirmationBoxDetails(details);
  };

  const getCompanyEntityTypeGroupCache = () => {
    const keyName = `${CacheKeys.SELECTED_COMPANY_GROUP}_${globalGroupId}_${groupId}`;
    return PrivacySettingsCache.getCacheForKey(keyName);
  };

  const getCatalogueGroupCache = () => {
    const keyName = `${CacheKeys.SELETCED_CATALOGUE_GROUP}_${globalGroupId}_${groupId}`;
    return PrivacySettingsCache.getCacheForKey(keyName);
  };

  const group =
    (entityType === ENTITY_TYPE.COMPANY
      ? getCompanyEntityTypeGroupCache()
      : getCatalogueGroupCache()) || {};

  const groupName = () => {
    if (entityType === ENTITY_TYPE.COMPANY) {
      return group.name;
    } else if (entityType === ENTITY_TYPE.CATALOGUE && globalGroupId === GlOBAL_GROUPS.ALLOWED) {
      return t('catalogue_allowed_group');
    } else if (entityType === ENTITY_TYPE.CATALOGUE && globalGroupId === GlOBAL_GROUPS.BLOCKED) {
      return t('catalogue_blocked_group');
    }
  };

  const showSelectedGroupMembersCount = useCallback(() => {
    let updatedSelectedGroupMember = [];
    if (isAnyGroupMembersSelected) {
      updatedSelectedGroupMember = [...groupMembersList].filter((groupMember) => {
        if (groupMember && groupMember.checked) {
          return { ...groupMember };
        }
        return undefined;
      });
    }
    return t(updatedSelectedGroupMember.length === 1 ? 'member_selected' : 'members_selected', { count: updatedSelectedGroupMember.length });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMembersList]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background:
          expandSearchBox || (scrolling && !isAnyGroupMembersSelected)
            ? `${group.iconColor}`
            : isAnyGroupMembersSelected
            ? '#212532'
            : 'transparent',
        title: groupName() || '',
        subTitle: showGroupMembersCount() || '',
        showTitleSubTitle: scrolling,
        color: '#FFFFFF',
        boxShadow: false,
        enableBoxShadow: false,
        placeholder: `${t('search')}...`,
        searchable: true,
        expandSearchBox: expandSearchBox,
        onClick: onSearchExpand,
        onChange: (e) => onSearch(e),
        value: searchText,
        changedNavbarTitle: showSelectedGroupMembersCount(),
        hideChangedNavbar: closeSelectedGroupMemberNavbar,
        onDeleteIconClick: showDeleteConfirmationDialog,
        isSelected: isAnyGroupMembersSelected,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: '120vh',
      },
    });

    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    searchText,
    expandSearchBox,
    onSearch,
    onSearchExpand,
    isAnyGroupMembersSelected,
    scrolling,
    showSelectedGroupMembersCount,
    closeSelectedGroupMemberNavbar,
  ]);

  useEffect(() => {
    if (headerSectionRef && headerSectionRef.current) {
      const headerSectionHeight = headerSectionRef.current.clientHeight;
      setHeaderSectionHeight(headerSectionHeight);
    }
  }, [headerSectionRef]);

  useEffect(() => {
    if (isAnyGroupMembersSelected) {
      window.scrollTo({
        top: window.scrollY - headerHeight + 55,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: scrolling ? window.scrollY + headerHeight - 55 : 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyGroupMembersSelected]);

  useEffect(() => {
    if (groupMembersList.length) {
      if (!searchText && expandSearchBox) {
        setSearchResults([...groupMembersList]);
        return;
      }
      if (searchText) {
        window.scrollTo(0, 0);
        const copyGroupMembersList = [...groupMembersList];
        const filteredGroupMembersList = copyGroupMembersList.filter((groupMember) => {
          const groupMemberName = groupMember.name;
          const groupMemberPhoneNumber = groupMember.phones[0];
          if (
            groupMemberName.toLowerCase().includes(searchText) ||
            groupMemberPhoneNumber.includes(searchText)
          ) {
            return groupMember;
          }
          return undefined;
        });
        setSearchResults(filteredGroupMembersList);
      }
    }
  }, [searchText, groupMembersList, expandSearchBox]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getGroupMembers();
    window.addEventListener('scroll', onScrollHandler);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroupMembers = async () => {
    if (!PrivacySettingsCache.getCacheForKey(getGroupMemberCacheKey(entityType, groupId))) {
      setLoading(true);
    }
    try {
      const { members = [] } = (await network.getGroupMembers(groupId)) || {};
      const addCheckedPropertyToGroupMembersList = [...members].map((groupMember) => {
        groupMember.checked = false;
        return { ...groupMember };
      });
      setGroupMembersList(addCheckedPropertyToGroupMembersList || []);
      PrivacySettingsCache.setCacheForKey(
        getGroupMemberCacheKey(entityType, groupId),
        addCheckedPropertyToGroupMembersList
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const showConfirmationDialogOnDeletingSIngleGroupMember = (id) => {
    setDeleteConfirmationDialog(true);
    const details = {
      title: t('delete_member'),
      message: t('are_you_sure_you_want_to_remove_this_contact_from_group'),
      onClose: onClose,
      api: () => deleteGroupMember([id]),
    };
    setConfirmationBoxDetails(details);
  };

  const deleteGroupMember = async (groupIds) => {
    setRowLoading(true);
    try {
      await network.deleteGroupMember({ memberIds: groupIds });

      const updatedGroupMembersList = [...groupMembersList].filter(
        (groupMember) => groupIds.indexOf(groupMember.id) === -1
      );
      PrivacySettingsCache.setCacheForKey(
        getGroupMemberCacheKey(entityType, groupId),
        updatedGroupMembersList
      );
      setGroupMembersList(updatedGroupMembersList);
      setGroupMembersSelected(false);
      setRowLoading(false);
      setExpandSearchBox(false);
      setSearchText('');
      if (deleteConfirmationDialog) {
        setDeleteConfirmationDialog(false);
      }
      setToastState({
        open: true,
        message: t('successfully_deleted'),
      });
      onClose();
    } catch (error) {
      setRowLoading(false);
      setGroupMembersSelected(false);
      setDeleteConfirmationDialog(false);
      setExpandSearchBox(false);
      if (deleteConfirmationDialog) {
        setDeleteConfirmationDialog(false);
      }
      setToastState({
        open: true,
        message: t('something_went_wrong_could_not_delete'),
      });
      onClose();
    }
  };
  const deleteSelectedMembers = () => {
    const ids = (getSelectedGroupMembers() || []).map((groupMember) => groupMember.id);
    deleteGroupMember(ids);
  };
  const groupMembersSelected = ({ checked, id }) => {
    const groupMemberelectedList = [...groupMembersList].map((groupMember) => {
      if (groupMember.id === id) {
        groupMember.checked = !checked;
      }
      return { ...groupMember };
    });
    if (expandSearchBox) {
      setExpandSearchBox(false);
    }
    const checkIfAnyGroupIsSelected = groupMemberelectedList.some(
      (groupMember) => groupMember.checked === true
    );
    if (searchText && !checkIfAnyGroupIsSelected) {
      setSearchText(false);
    }
    setGroupMembersList(groupMemberelectedList);
    setGroupMembersSelected(checkIfAnyGroupIsSelected);
  };
  const getSelectedGroupMembers = () => {
    let updatedSelectedGroupMembers = [];
    if (isAnyGroupMembersSelected) {
      updatedSelectedGroupMembers = [...groupMembersList].filter((group) => {
        if (group && group.checked) {
          return { ...group };
        }
        return undefined;
      });
      return updatedSelectedGroupMembers;
    }
  };

  const showGroupMembersCount = () => {
    return renderCount(groupMembersList.length, COUNT_TYPE.MEMBER);
  };

  const renderInitialTextForMemberIcon = (name, phones, emails) => {
    let initial = null;
    if (name) {
      initial = name[0];
    } else if (phones && phones.length) {
      initial = phones[0][0];
    } else if (emails && emails.length) {
      initial = emails[0][0];
    }
    return initial;
  };

  const onScrollHandler = () => {
    if (window.scrollY > 80) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const renderGroupsList = searchText ? searchResults : groupMembersList;

  const CIRCLE_SIZE = 50;
  const FONT_SIZE = CIRCLE_SIZE / 2;
  const HEADER_IMAGE_CONTAINER_SIZE = 250;

  const renderDeleteConfimrationBox = () => {
    return (
      <ConfirmationDialogBox
        open={deleteConfirmationDialog}
        title={confirmationBoxDetails.title}
        message={confirmationBoxDetails.message}
        onClose={onClose}
        onClickHandler={confirmationBoxDetails.api}
        onSuccessLoader={rowLoading}
      />
    );
  };

  return (
    <>
      <div className="groupMembersContainer">
        {!(expandSearchBox || isAnyGroupMembersSelected) && (
          <>
            <div className="headerSection" style={{ position: 'relative' }} ref={headerSectionRef}>
              <div
                className="headerImageContainer"
                style={{
                  position: 'absolute',
                  top: (-1 * HEADER_IMAGE_CONTAINER_SIZE) / 2,
                  width: HEADER_IMAGE_CONTAINER_SIZE,
                  height: HEADER_IMAGE_CONTAINER_SIZE,
                  borderRadius: HEADER_IMAGE_CONTAINER_SIZE / 2,
                  backgroundColor: group.iconColor || '#000000',
                }}
              ></div>
              <img
                src={renderIcon(group.iconName) || 'ic_bird_4'}
                alt="group icon"
                style={{ width: 90, height: 60, position: 'absolute', top: 40 }}
              />
              <div className="groupMembersTitle">{groupName()}</div>
              <div className="groupMembersCount">{showGroupMembersCount()}</div>
            </div>
          </>
        )}
        {loading ? (
          <div className="loader">
            <Loader />;
          </div>
        ) : (
          <div
            className="groupMembers"
            style={expandSearchBox || isAnyGroupMembersSelected ? { paddingTop: '3.5rem' } : {}}
          >
            {renderGroupsList.map(({ id, name, phones, checked, emails }, index) => {
              const iconColor = MEMBER_COLORS[(index || 0) % MEMBER_COLORS.length];

              return (
                <div className="singleGroupMember" key={id}>
                  <div
                    style={{
                      width: CIRCLE_SIZE,
                      height: CIRCLE_SIZE,
                      borderRadius: CIRCLE_SIZE / 2,
                      backgroundColor: iconColor || '#000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      className="initialtext"
                      style={{
                        fontSize: FONT_SIZE,
                        color: '#ffffff',
                      }}
                    >
                      {renderInitialTextForMemberIcon(name, phones, emails)}
                    </p>
                  </div>
                  <div className="checkbox">
                    <CustomCheckbox
                      key={id}
                      checked={checked}
                      checkBoxStyle={classes.checkBoxStyle}
                      formControlLabel={classes.formControlLabel}
                      handleChange={() => groupMembersSelected({ checked, id, name })}
                    />
                  </div>
                  <div className="nameAndPhoneNumber">
                    {name && <div className="name">{name}</div>}
                    {phones &&
                      phones.length > 0 &&
                      phones.map((phone, index) => {
                        return (
                          <div className="phoneNumber" key={index}>
                            {phone}
                          </div>
                        );
                      })}
                    {emails &&
                      emails.length > 0 &&
                      emails.map((email, index) => {
                        return (
                          <div className="phoneNumber" key={index}>
                            {email}
                          </div>
                        );
                      })}
                  </div>
                  {!isAnyGroupMembersSelected && (
                    <div className="trashIcon">
                      <img
                        src={TrashIcon}
                        alt="delete icon"
                        style={{
                          padding: 10,
                          width: 16,
                          height: 20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          showConfirmationDialogOnDeletingSIngleGroupMember(id);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            {renderGroupsList.length === 0 && <div className="noMembersText">No members</div>}
          </div>
        )}
      </div>
      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={3000}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
      {renderDeleteConfimrationBox()}
    </>
  );
};

export default GroupMembers;
