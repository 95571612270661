import React from 'react';
import Ripples from 'react-ripples';
import { ReactComponent as NextIcon } from '../../assets/images/right_arrow.svg';
import "./style.scss";

export default function ({ text, onClick }) {
    return <Ripples className='addMore' onClick={onClick} >
        <span className='addText'>{text}</span>
        <NextIcon fill="#FFF" width="20" height="20" />
    </Ripples>
}