import React, { Fragment } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import DraggableIcon from 'qs-assets/icons/DraggableIcon';
import { ReactComponent as BinSvg } from 'qs-assets/images/bin.svg';
import { ReactComponent as EditSvg } from 'qs-assets/images/edit.svg';
import { ReactComponent as LockSvg } from 'qs-assets/images/lock.svg';
import useStyles from './styles.js';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const SortableDragHandle = SortableHandle(({ disabled, id }) => (
  <div className={`menu-icon${disabled ? ' disabled' : ''}`} id={id}>
    <DraggableIcon />
  </div>
));

const Element = SortableElement(
  ({
    field,
    type,
    idx,
    isLastItem,
    onDelete,
    id,
    visibility,
    reorderingItem,
    onEdit,
  }) => {
    const disabled = !!reorderingItem;
    const isLoading = reorderingItem === id;

    const classes = useStyles();

    return (
      <Fragment>
        <ListItem
          onClick={() => onEdit(idx)}
          className={`${classes.listItem} ${isLoading ? 'loading' : ''} ${
            disabled ? 'disabled' : ''
          }`}
        >
          <SortableDragHandle
            onClick={(e) => e.stopPropagation()}
            disabled={disabled}
          />
          <Box>
            <p className={`field-text ${classes.listText}`}>{field}</p>
            <div className="type-box">
              <p className={`field-type ${classes.listSubText}`}>{type}</p>
              <Box className={`${classes.visibility} ${classes[visibility]} list-mob-visibility`}>
                {visibility === 'PRIVATE' && (
                  <LockSvg width="12.20" height="16" className={classes.image} />
                )}
                {visibility}
              </Box>
            </div>
          </Box>
          <ListItemSecondaryAction className={`${classes.listActions} list-actions`}>
            <Box className={`${classes.visibility} ${classes[visibility]} list-visibility`}>
              {visibility === 'PRIVATE' && (
                <LockSvg width="12.20" height="16" className={classes.image} />
              )}
              {visibility}
            </Box>
            <ListItemIcon
              className={`${classes.listButton}  ${disabled ? 'disabledListButton' : ''}`}
            >
              <EditSvg
                width="22"
                height="22"
                onClick={() => onEdit(idx)}
                fill="#9CA0AC"
                className={classes.image}
              />
            </ListItemIcon>
            <ListItemIcon
              className={`${classes.listButton}  ${disabled ? 'disabledListButton' : ''}`}
            >
              <BinSvg
                width="22"
                height="22"
                onClick={() => onDelete(id)}
                fill="#9CA0AC"
                className={classes.image}
              />
            </ListItemIcon>
          </ListItemSecondaryAction>
        </ListItem>
        {!isLastItem && !isLoading && <Divider className={classes.divider} />}
      </Fragment>
    );
  }
);

const FieldsList = SortableContainer((props) => {
  const { fields = [], onEdit, onDelete, reorderingItem } = props;

  return (
    <List>
      {Array.isArray(fields) &&
        fields.map(({ fieldName: field, fieldType: type, visibility, id }, index) => (
          <Element
            key={id}
            index={index}
            idx={index}
            isLastItem={index === fields.length - 1}
            field={field}
            type={type}
            onEdit={onEdit}
            onDelete={onDelete}
            reorderingItem={reorderingItem}
            id={id}
            visibility={visibility}
          />
        ))}
    </List>
  );
});

export default FieldsList;
