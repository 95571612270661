import React, { useState, useEffect } from 'react';
import { isNil, isEmpty } from 'lodash';
import { CircularProgress } from "@material-ui/core";
import ShippingData from "qs-data/shipping";
import ChangeText from 'qs-common/ChangeText';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import MinimumOrderPriceDialog from "../MinimumOrderPriceDialog";
import CurrencyMapper from 'qs-helpers/currency';
import { getI18N } from '../../../../i18N';
import "./style.scss";

export default function ({ shipping, currencyUnit }) {

  const [minimumOrderAmount, updateMinimumOrderAmount] = useState(null);
  const [changeMinimumAmountDialog, toggleDialog] = useState(false);
  const [deleteMinimumAmount, toggleDeleteMinimumAmount] = useState(false);
  const [shippingData, setShippingData] = useState([]);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    setShippingData(shipping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNil(shippingData) && !isEmpty(shippingData)) {
      fetchMinOrderAmountForFreeShipping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingData]);

  /* Fetch Shipping mode api call */
  const fetchMinOrderAmountForFreeShipping = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ShippingData.getMinOrderAmountForFreeShipping(shippingData.mode.id, shippingData.zone.id).then((unit) => {
      updateMinimumOrderAmount(unit);
    }).catch(() => {
      showErrorComponent(true);
    }).then(() => {
      setProgressBar(false)
    });
  }

  const openDialog = (deleteMinAmount) => {
    toggleDeleteMinimumAmount(deleteMinAmount);
    toggleDialog(true)
  }

  const closeDialog = () => {
    toggleDialog(false);
    toggleDeleteMinimumAmount(false);
  }

  const onSuccess = (minAmount) => {
    updateMinimumOrderAmount(minAmount);
    toggleDialog(false);
    toggleDeleteMinimumAmount(false);
  }

  return <div className='minOrderContainer'>
    <p className='minOrderForFreeShippingText'>{t('minimum_order_amount_for_free_shipping')}</p>
    {
      progressBar ? <CircularProgress size={24} color="primary" style={{ marginTop: '8px' }} /> :
        errorComponent ? <CustomErrorComponent style={{ marginTop: '8px' }} onRetryClick={fetchMinOrderAmountForFreeShipping} /> :
          <div className='changeMinimumAmountContainer'>
            {isNil(minimumOrderAmount) ? <span className='noMinimumAmountSet'>{t('no_minimum')}</span> :
              <span className='minimumOrderAmount'>{CurrencyMapper[currencyUnit] || currencyUnit} {minimumOrderAmount}</span>
            }
            <div>
              {!isNil(minimumOrderAmount) && <ChangeText text={t('remove')} style={{color: '#ff7a7a', marginRight: '8px'}} onClick={() => openDialog(true)} />}
              <ChangeText text={isNil(minimumOrderAmount) ? t('add') : t('change')} onClick={() => openDialog(false)} />
            </div>
          </div>
    }

    {changeMinimumAmountDialog && <MinimumOrderPriceDialog
      shippingData={shippingData}
      currencyUnit={currencyUnit}
      minimumOrderAmount={minimumOrderAmount}
      showDialog={changeMinimumAmountDialog}
      onClose={closeDialog}
      onSuccess={(minAmount) => onSuccess(minAmount)}
      deleteMode={deleteMinimumAmount}
    />}
  </div>
}
