import React, { useEffect } from 'react';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { navigationBarColor, statusBarColor } from 'qs-data/globalConstants';

import { useHistory } from 'react-router-dom';
import './styles.scss';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import UsageReport from '../UsageReport';
import { getI18N } from '../../../i18N';

const SmsUsageReport = () => {
  const { t } = getI18N();
  const history = useHistory();
  const { months } = history.location.state || {};
  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#17A778',
        color: '#FFFFFF',
        boxShadow: false,
        title: t('sms_usage_report'),
        enableBoxShadow: false,
      },
    });
    setNavigationBarColor(navigationBarColor);
    setStatusBarColor(statusBarColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
 
  if (!months) {
    return null;
  }
  return (
    <div className="smsUsageReport">
      <UsageReport months={months} />
    </div>
  );
};

export default SmsUsageReport;
