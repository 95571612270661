import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Loader from 'qs-common/Loader';
import { getI18N } from '../../i18N';

const useStyles = makeStyles({
  dialogPaper: {
    backgroundColor: '#ffffff',
  },
  dialogTitle: {
    color: '#000000',
    paddingBottom: 0,
  },
  dialogTitleTypoGraphy: {
    fontWeight: 600,
    fontSize: 20,
  },
  typography: {
    color: '#000000',
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const ConfirmationDialogBox = ({
  open,
  title,
  message,
  onClickHandler,
  onClose,
  onSuccessLoader,
}) => {
  const { t } = getI18N();
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      className="confirmation"
    >
      {title && (
        <DialogTitle classes={{ root: classes.dialogTitle }} className="title">
          {title}
        </DialogTitle>
      )}
      {message && (
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography classes={{ root: classes.typography }} className="message">
            {message}
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button disabled={onSuccessLoader} color={'primary'} onClick={onClose}>
          <span className="buttonText">{t('no')}</span>
        </Button>

        <Button disabled={onSuccessLoader} color={'primary'} onClick={onClickHandler}>
          {onSuccessLoader ? (
            <div>
              <Loader small />
            </div>
          ) : (
            <span className="buttonText">{t('yes')}</span>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogBox;
