import React from 'react';

export default function ({ color, size  }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 32 32"
			version="1.1"
			fill={color || '#3833EA'}>
			<g id="surface1" fill={color || '#3833EA'}>
				<path d="M 7.304688 4 L 4.664063 11.042969 L 6.804688 11.042969 L 8.695313 6 L 23.308594 6 L 25.199219 11.042969 L 27.332031 11.042969 L 24.691406 4 Z M 2 12 L 2 18 L 3.257813 18 L 6.257813 28 L 25.746094 28 L 28.746094 18 L 30 18 L 30 12 Z M 4 14 L 28 14 L 28 16 L 27.253906 16 L 24.253906 26 L 7.742188 26 L 4.742188 16 L 4 16 Z M 11 17 L 11 24 L 13 24 L 13 17 Z M 15 17 L 15 24 L 17 24 L 17 17 Z M 19 17 L 19 24 L 21 24 L 21 17 Z "/>
			</g>
		</svg>
	);
}
