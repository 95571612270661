import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ShippingData from "qs-data/shipping";
import { ReactComponent as EditIcon } from 'qs-assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from 'qs-assets/images/trash.svg';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import AddNewItem from 'qs-common/AddNewItem';
import CustomDivider from 'qs-common/CustomDivider';
import CustomLabel from 'qs-common/CustomLabel';
import Utility from "qs-helpers/utility";
import AddNewShippingWeightDialog from "../ShippingWeightDialog";
import { getI18N } from '../../../../i18N';
import "./style.scss";

function ShippingWeightProfile({ weightUnit }) {

    const [showDialog, setShowDialog] = useState(false);
    const [selectedWeightProfile, updatedSelectedWeightProfile] = useState(null);
    const [weightProfiles, onWeightProfilesUpdate] = useState([]);
    const [deleteMode, toggleDeleteMode] = useState(false);
    const [progressBar, setProgressBar] = useState(true);
    const [errorComponent, showErrorComponent] = useState(false);

    const { t } = getI18N();

    useEffect(() => {
        fetchShippingWeightProfiles();
    }, []);

    /* Fetch Shipping weight profiles api call */
    const fetchShippingWeightProfiles = async () => {
        setProgressBar(true);
        showErrorComponent(false);
        ShippingData.getShippingWeightProfiles().then((weights) => {
            weights.sort((a, b) => parseFloat(a.min) - parseFloat(b.min));
            onWeightProfilesUpdate(weights);
        }).catch(() => {
            showErrorComponent(true);
        }).then(() => {
            setProgressBar(false)
        });
    }

    const editWeightProfile = (profile) => {
        updatedSelectedWeightProfile(profile);
        setShowDialog(true)
    }

    const deleteWeightProfile = (profile) => {
        updatedSelectedWeightProfile(profile);
        toggleDeleteMode(true);
        setShowDialog(true)
    }

    const onSuccess = () => {
        setShowDialog(false);
        toggleDeleteMode(false);
        updatedSelectedWeightProfile(null);
        fetchShippingWeightProfiles();
    }

    const openDialog = () => {
        setShowDialog(true)
    }

    const onClose = () => {
        setShowDialog(false);
        toggleDeleteMode(false);
        fetchShippingWeightProfiles();
    }

    const renderList = () => {
        if (progressBar) {
            return <CircularProgress size={24} color="primary" style={{ margin: '8px 0' }} />
        } else if (errorComponent) {
            return <CustomErrorComponent style={{paddingBottom: '16px'}} onRetryClick={fetchShippingWeightProfiles} />
        } else {
            return weightProfiles.map(function (item, index) {
                return <div className='singleWeightProfile' key={index}>
                   <p className='weight' >{Utility.getWeightProfileName(item, weightUnit)}</p>
                    <div className='actionItemsContainer'>
                        <div className='shippingModeEditIconContainer'>
                            <EditIcon fill="#FFF" width="20" height="20" onClick={() => editWeightProfile(item)} />
                        </div>
                        <div className='shippingModeDeleteIconContainer'>
                            <DeleteIcon fill="#FFF" width="16" height="16" onClick={() => deleteWeightProfile(item)} />
                        </div>
                    </div>
                </div>;
            })
        }
    }

    const renderAddNewWeightProfile = () => {
        if (progressBar || errorComponent) {
            return null;
        }

        return <div>
            <CustomDivider />
            <AddNewItem text={t('create_weight_profile')} onClick={openDialog} />
        </div>
    }

    return <div className='shippingItemContainer weightProfiles'>
        <CustomLabel value={t('weight_profiles')} />
        {renderList()}
        {renderAddNewWeightProfile()}
        {showDialog && <AddNewShippingWeightDialog
            showDialog={showDialog}
            selectedWeightProfile={selectedWeightProfile}
            onClose={onClose}
            onSuccess={onSuccess}
            weightUnit={weightUnit}
            deleteMode={deleteMode}
        />}
    </div>
}

export default withRouter(ShippingWeightProfile);