import React, { useState, useEffect, Fragment, useCallback } from 'react';
import Dialog from '../../common/Alerts/Dialog';
import { TextField, InputAdornment, Button, makeStyles } from '@material-ui/core';
import { useHistory, withRouter, useLocation, useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
import Toast from '../../common/Alerts/Toast';
import { useAppContext } from '../../common/Contexts/AppContext';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import CompanyData from 'qs-data/companyData';
import metalRateData from 'qs-data/metalRateData';
import { PAGE_TITLES, PAGE_TYPE, PAGE_UNITS, PAGE_ENTITY_TYPE } from './constants';
import { createQueryParams } from 'qs-data/util';
import './style.scss';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { getI18N } from '../../i18N';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

function MetalRate() {
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const { metalType } = useParams();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeMetalType, setActiveMetalType] = useState(metalType || PAGE_TYPE.GOLD_RATE);
  const [ratesLoading, setRatesLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [companyDetails, setCompanyDetails] = useState({});
  const currencySymbol = companyDetails.currencySymbol || '';
  const currencyAlignment = companyDetails.currencyAlignment || 'left';
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const [metalVariant, setMetalVariant] = useState([]);
  const [errorField, setErrorField] = useState({});
  const [disabledField, setDisabledField] = useState({});
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const NAVBAR_HEIGHT = 55;

  const { t } = getI18N();

  const fetchMetalRates = useCallback(() => {
    const metalData = createQueryParams([
      { key: 'productType', value: 'JEWELLERY' },
      { key: 'entityLabel', value: 'METAL' },
      { key: 'entityType', value: PAGE_ENTITY_TYPE[activeMetalType] },
    ]);
    setRatesLoading(true);
    metalRateData
      .getMetalRates(metalData, activeMetalType)
      .then((rates) => {
        setMetalVariant(rates);
        setRatesLoading(false);
        setDisabled(!rates[0].rate);
      })
      .catch(() => {
        setRatesLoading(false);
        setToastState({ open: true, message: t('error_while_fetching_rates') });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMetalType]);

  const [, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: !metalType ? t('metal_rates') : t(PAGE_TITLES[activeMetalType]),
        hideBack: desktop,
        height: NAVBAR_HEIGHT,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeMetalType, desktop, metalType]);

  useEffect(() => {
    fetchMetalRates();
  }, [fetchMetalRates]);

  useEffect(() => {
    CompanyData.getCompanyDetails()
      .then((companyDetailsResponse) => setCompanyDetails({ ...companyDetailsResponse }))
      .catch(() => {
        setToastState({ open: true, message: t('error_while_fetching_company_details') });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.hash === '#update') {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  }, [location.hash]);

  const currencySymbolAlignment = (position) => {
    return (
      <InputAdornment position={position} style={{ fontWeight: 700 }}>
        <p className="currency">
          {currencySymbol}
          {position === 'end' && <> {t('per_gm')}</>}
        </p>
      </InputAdornment>
    );
  };

  const setMetalPrices = () => {
    const metalData = {
      productType: 'JEWELLERY',
      entityLabel: 'METAL',
      entityType: PAGE_ENTITY_TYPE[activeMetalType],
      rates: metalVariant.map((item) => {
        return {
          ...item,
          rate: Number(item.rate)
        };
      }),
    };
    setDisabled(true);
    metalRateData
      .setMetalRates(metalData, activeMetalType)
      .then(() => {
        setToastState({
          open: true,
          message: t('metal_type_update_success', { metalType: t(PAGE_TITLES[activeMetalType]) }),
        });
        setDisabled(false);
        history.goBack();
      })
      .catch(() => {
        setToastState({ open: true, message: t('error_while_updating_rates') });
        setDisabled(false);
        history.goBack();
      });
  };

  const goldCalculator = (item) => {
    const firstMetalVariant = metalVariant[0];

    let percent = (item.quality * 100) / firstMetalVariant.quality;
    let value = (firstMetalVariant.rate * item.quality) / firstMetalVariant.quality;
    value = +value.toFixed(2);
    percent = +percent.toFixed(2);

    return { percent, value };
  };

  const onChangeMetalVariant =
    (fieldKey) =>
    (...args) => {
      if (args) {
        const fieldValue = args[0].target.value.trim();
        if (fieldValue.match(/^[0-9]+\.?[0-9]{0,2}$/) || fieldValue === '') {
          const updatedMetalVariant = metalVariant.map((item) =>
            item.quality === fieldKey.quality ? { ...item, rate: fieldValue } : item
          );
          return setMetalVariant(updatedMetalVariant);
        }
      }
    };

  const onChangeMetalMain =
    (fieldKey) =>
    (...args) => {
      if (args) {
        const fieldValue = args[0].target.value.trim();
        if (fieldValue === '' || Number(fieldValue) === 0 || fieldValue.match(/0\.0{2}[0-9-,.]/g)) {
          setErrorField({ ...errorField, [fieldKey.quality]: true });
        } else {
          const fields = Object.keys(metalVariant);
          fields.forEach((fieldKey) => {
            if (errorField[metalVariant[fieldKey].quality]) {
              delete errorField[metalVariant[fieldKey].quality];
              return;
            }
          });
        }
        if (fieldValue.match(/0\.0{2}[0-9-,.]/g) || fieldValue === '' || Number(fieldValue) === 0) {
          setDisabledField({ ...disabledField, [fieldKey.quality]: true });
          setDisabled(true);
        } else {
          const fields = Object.keys(metalVariant);
          fields.forEach((fieldKey) => {
            if (disabledField[metalVariant[fieldKey].quality]) {
              delete disabledField[metalVariant[fieldKey].quality];
              return;
            }
          });
          if (!Object.keys(errorField).length && !Object.keys(disabledField).length) {
            setDisabled(false);
          }
        }
        if (fieldValue.match(/^[0-9]+\.?[0-9]{0,2}$/) || fieldValue === '') {
          const updatedMetalVariant = metalVariant.map((item) =>
            item.quality === fieldKey.quality
              ? { ...item, rate: fieldValue }
              : {
                  ...item,
                  rate: +((fieldValue * item.quality) / metalVariant[0].quality).toFixed(2),
                }
          );
          return setMetalVariant(updatedMetalVariant);
        }
      }
    };

  return (
    <div className="metalContainer">
      <div className="MetalRateSettingsContainer">
        {!metalType ? (
          <div className={'metalRatesTabsContainer'}>
            <Button
              className={`metalRateButton goldRateButton ${
                activeMetalType === PAGE_TYPE.GOLD_RATE ? 'active' : ''
              }`}
              onClick={() => setActiveMetalType(PAGE_TYPE.GOLD_RATE)}
            >
              {t(PAGE_TYPE.GOLD_RATE.toLowerCase())}
            </Button>
            <Button
              className={`metalRateButton silverRateButton ${
                activeMetalType === PAGE_TYPE.SILVER_RATE ? 'active' : ''
              }`}
              onClick={() => setActiveMetalType(PAGE_TYPE.SILVER_RATE)}
            >
              {t(PAGE_TYPE.SILVER_RATE.toLowerCase())}
            </Button>
          </div>
        ) : null}
        {ratesLoading ? (
          <div className="LoaderContainer">
            <Loader />
          </div>
        ) : (
          <Fragment>
            {metalVariant.length === 0 && !ratesLoading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: '1',
                }}
              >
                <CustomErrorComponent onRetryClick={fetchMetalRates} />
              </div>
            )}
            <div className="MetalRateSettingsContentContainer">
              {metalVariant.map((item, index) => {
                const metalItem = goldCalculator(item);
                return (
                  <div className="rateComponentContainer" key={index}>
                    <div className="tileHeader">
                      <div className="rateTitle">{`${item.quality || ''}${item.label || ''} ${
                        PAGE_TITLES[activeMetalType] ? t(PAGE_TITLES[activeMetalType]) : ''
                      }`}</div>
                      {activeMetalType === PAGE_TYPE.GOLD_RATE && metalItem.percent !== 100 && (
                        <div className="metalPercent">
                          {metalItem.percent}%
                        </div>
                      )}
                    </div>
                    <div>
                      <TextField
                        id="standard-start-adornment"
                        value={item.rate || item.rate === '' ? item.rate : ''}
                        fullWidth
                        type="number"
                        onChange={
                          metalItem.percent === 100
                            ? onChangeMetalMain(item)
                            : onChangeMetalVariant(item)
                        }
                        inputProps={{
                          inputMode: 'decimal',
                        }}
                        className={classes.input}
                        InputProps={{
                          startAdornment:
                            currencyAlignment === 'left' ? currencySymbolAlignment('start') : null,
                          endAdornment:
                            currencyAlignment === 'right' ? (
                              currencySymbolAlignment('end')
                            ) : (
                              <InputAdornment position="end">
                                <p style={{ fontWeight: '700' }}>{t('per_gm')}</p>
                              </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        error={(item.rate === '' && disabled) || errorField[item.quality]}
                        helperText={
                          (item.rate === '' && disabled) || errorField[item.quality]
                            ? t('please_enter_form_fields', { field: `${item.quality}${PAGE_UNITS[activeMetalType]} ${t(PAGE_TITLES[activeMetalType])}` })
                            : ''
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {metalVariant.length > 0 && (
              <div className="buttonSave">
                <Button
                  style={{
                    backgroundColor: disabled ? '#666666' : '#4da47a',
                    padding: '8px 20px 8px 20px',
                    color: '#ffffff',
                    height: '50px',
                  }}
                  onClick={() => history.push('#update')}
                  disabled={Object.keys(disabledField).length || disabled}
                >
                  <span>{t('save_rates')}</span>
                </Button>
              </div>
            )}
          </Fragment>
        )}
      </div>

      <Dialog
        open={dialogOpen}
        title={t('change_gold_rate')}
        description={
          <span>
            <span style={{ color: '#ffffff' }}>
              {t('change_rates_confirmation', { metalType: t(PAGE_TITLES[activeMetalType]) })}
            </span>
            <span style={{ color: '#F9B7B7' }}>
              {t('this_will_change_the_pricing_for_all_your_products')}
            </span>
          </span>
        }
        confirmText={t('yes_change_rate')}
        cancelText={t('cancel')}
        onClose={() => {
          history.goBack();
        }}
        handleConfirm={setMetalPrices}
        disabled={disabled}
      />
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}

export default withRouter(MetalRate);
