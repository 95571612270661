import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { getI18N } from '../../../i18N';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import SearchableInput from './components/SearchableInput';
import { Box, Button, ButtonBase, Dialog, Typography } from '@material-ui/core';
import AddCustomerModal from './components/AddOrEditCustomerModal';
import { Done } from '@material-ui/icons';
import { useAtom, useSetAtom } from 'jotai';
import { orderAtom, selectedCustomerAtom } from './state';
import network from 'qs-data/network';
import Loader from 'qs-common/Loader';
import usePrevious from '../../../utils/usePrevious';
import useDebounce from '../../../utils/useDebounce';
import { parseSymbolAndPhoneNumber } from './components/CountryCodeSelector/countries-mapper';
import { useHistory } from 'react-router-dom';

const ChooseCustomer = () => {
  const [, dispatch] = useAppContext();
  const { t } = getI18N();

  const [selectedCustomer, setSelectedCustomer] = useAtom(selectedCustomerAtom);
  const setOrder = useSetAtom(orderAtom);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState();
  const [customersData, setCustomersData] = useState({
    customers: [],
    sortAfter: null,
  });
  const [customersListLoading, setCustomersListLoading] = useState(true);
  const [paginatedListLoading, setPaginatedListLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [hasSearchTermChanged, setHasSearchTermChanged] = useState(false);
  const [addCustomerLoading, setAddCustomerLoading] = useState(false);
  const [addCustomerDetails, setAddCustomerDetails] = useState({
    id: '',
    name: '',
    phone: '',
    businessName: '',
    email: '',
    symbol: '',
  });

  const debouncedSearchTerm = useDebounce(searchTerm);
  const prevDebouncedSearchTerm = usePrevious(debouncedSearchTerm);
  const prevPage = usePrevious(page);

  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#0F151B',
        color: '#FFFFFF',
        title: t('select_a_customer'),
        showDeleteButton: true,
        deleteBtnStyle: {
          opacity: '0',
        },
        hideBack: false,
        height: NAVBAR_HEIGHT,
        overrideTitleClassStyle: {
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '700',
        },
        arrowBackButtonStyle: {
          marginRight: '0px',
        },
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#252c36');
    setStatusBarColor('#0F151B');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getCustomersList = useCallback(() => {
    if (!customersData.sortAfter && page > 0 && debouncedSearchTerm === prevDebouncedSearchTerm)
      return;
    let url = `searchTerm=${debouncedSearchTerm}&`;
    if (customersData.sortAfter && customersData.sortAfter.length > 0 && page !== prevPage) {
      for (let [index, sortAfterElement] of customersData.sortAfter.entries()) {
        url += `sortAfter[${index}]=${sortAfterElement}`;
        if (index !== customersData.sortAfter.length - 1) {
          url += `&`;
        }
      }
    }
    network
      .getCustomersData(url)
      .then((data) => {
        if (!data) {
          console.error('No data received from the network request.');
          return;
        }
        setCustomersData((prevData) => {
          const isPaginatedRequest = prevData?.sortAfter && page > 0;
          const shouldAppendData = isPaginatedRequest || !hasSearchTermChanged;

          return {
            customers: shouldAppendData
              ? [...(prevData?.customers || []), ...(data?.customers || [])]
              : data?.customers || [],
            sortAfter: data?.sortAfter,
          };
        });
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setCustomersListLoading(false);
        setPaginatedListLoading(false);
        setHasSearchTermChanged(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    getCustomersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomersList]);

  const handleScroll = (event) => {
    const element = event.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) === element.clientHeight &&
      !paginatedListLoading &&
      customersData.sortAfter
    ) {
      setPaginatedListLoading(true);
      setPage((prev) => prev + 1);
    }
  };

  const renderAddCustomerButton = () => {
    const handleAddNewCustomerClick = () => setShowAddCustomerModal(true);

    return (
      <Button className="addCustomerButton" onClick={handleAddNewCustomerClick}>
        {t('add_new_customer')}
      </Button>
    );
  };

  const handleAddCustomerSubmit = () => {
    setAddCustomerLoading(true);
    network
      .createCustomer({
        data: {
          ...addCustomerDetails,
          phone: `${addCustomerDetails.symbol}${addCustomerDetails.phone}`,
        },
      })
      .then((data) =>
        setCustomersData((prevData) => ({
          customers: [data.customer, ...prevData.customers],
          sortAfter: prevData.sortAfter,
        }))
      )
      .catch((error) => console.log(error))
      .finally(() => {
        setAddCustomerDetails({ name: '', phone: '', businessName: '', email: '' });
        setShowAddCustomerModal(false);
        setAddCustomerLoading(false);
      });
  };

  const renderAddCustomerModal = () => {
    return (
      <Dialog open={showAddCustomerModal} onClose={() => setShowAddCustomerModal(false)}>
        <AddCustomerModal
          title={t('add_new_customer')}
          btnText={t('add_customer')}
          onClose={() => setShowAddCustomerModal(false)}
          onSubmit={handleAddCustomerSubmit}
          loading={addCustomerLoading}
          customerInfo={addCustomerDetails}
          setCustomerInfo={setAddCustomerDetails}
        />
      </Dialog>
    );
  };

  const renderCustomers = () => {
    return (
      <div className="customersListContainer">
        {customersData.customers.map((customer, index) => {
          const isCustomerSelected = customer.id === selectedCustomer.id;
          const handleCustomerClick = () => {
            const parsedPhoneNumber = parseSymbolAndPhoneNumber(customer.phone);
            setSelectedCustomer({
              ...customer,
              phone: parsedPhoneNumber.phoneNumber,
              symbol: parsedPhoneNumber.symbol,
            });
            setOrder();
            history.goBack();
          };
          return (
            <ButtonBase
              key={customer.id}
              className={`customerContainer ${
                index === customersData.customers.length - 1 ? 'lastCustomer' : ''
              }`}
              onClick={handleCustomerClick}
            >
              <Box className="customersInfo">
                <Typography className="customerName">{customer.name}</Typography>
                <Typography className="customerPhoneNumber">{customer.phone}</Typography>
              </Box>

              {isCustomerSelected && (
                <Box className="customerSelectedContainer">
                  <Done className="customerSelectedIcon" />
                </Box>
              )}
            </ButtonBase>
          );
        })}
        {paginatedListLoading && (
          <Box className="paginationLoader">
            <Loader small />
          </Box>
        )}
      </div>
    );
  };

  if (customersListLoading) {
    return (
      <Box className="customersListLoader">
        <Loader />
      </Box>
    );
  }

  return (
    <div className="chooseCustomerContainer" onScroll={handleScroll}>
      <SearchableInput
        placeholder={t('search_by_name_or_number')}
        inputValue={searchTerm}
        onInputValueChange={(event) => {
          setSearchTerm(event.target.value);
          setHasSearchTermChanged(true);
        }}
      />
      {renderCustomers()}
      {renderAddCustomerButton()}
      {renderAddCustomerModal()}
    </div>
  );
};

export default ChooseCustomer;
