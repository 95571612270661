import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Ripples from 'react-ripples';
import ReferralCard from '../ReferralCard';
import { ReactComponent as RewardSchemeIcon } from 'qs-assets/images/reward_scheme_icon.svg';
import ReferralRewardSchemeDialog from '../ReferralRewardSchemeDialog';
import './style.scss';
import { getI18N } from '../../../i18N';

export default function ReferralList({ referralList, onRedeemSuccess, isIndiaReferral }) {
  const { t } = getI18N();
  const [showDialog, toggleShowDialog] = useState(false);
  return (
    <div className={'referralSection'}>
      {!isIndiaReferral && (
        <div className="rewardSection">
          <Ripples className="rewardContainer" onClick={() => toggleShowDialog(true)}>
            <RewardSchemeIcon fill="#FFF" width="16" height="16" />
            <span className="rewardText">{t('view_reward_scheme')}</span>
          </Ripples>
        </div>
      )}
      {isEmpty(referralList) ? (
        <p className="emptyReferralList" style={{ color: isIndiaReferral ? '#242b35' : '#ffffff' }}>
          {t('start_sharing_your_referral_code_with_friends_now')}
        </p>
      ) : (
        referralList.map(function (item, index) {
          return (
            <ReferralCard
              key={index}
              data={item}
              onRedeemSuccess={onRedeemSuccess}
              isIndiaReferral={isIndiaReferral}
            />
          );
        })
      )}
      <ReferralRewardSchemeDialog showDialog={showDialog} onClose={() => toggleShowDialog(false)} />
    </div>
  );
}
