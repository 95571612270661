import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Toast from 'qs-common/Alerts/Toast';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useStyles from 'qs-common/CustomStyles';
import CustomTextField from 'qs-common/CustomTextField';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import Loader from 'qs-common/Loader';
import {
  getCachedEnteredCredentials,
  getCachedSelectedProvider,
  setCachedEnteredCredentials,
} from 'qs-data/shippingProviders';
import payments from 'qs-data/payments';
import { createQueryParams } from 'qs-data/util';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { SHIPPING_PROVIDERS, updateNewShippingProvider } from '../ShippingModeSettings/helpers';
import { getShippingCredentials, updateShippingCredentials } from './helpers';
import './style.scss';
import SettingCard from 'qs-common/SettingCard';
import { getI18N } from '../../i18N';

const DEFAULT_PASSWORD_KEY = '************';
const INVALID_CREDENTIALS_MESSAGE = 'Invalid credentials';
const PAYMENT_MODE_NA_ERROR_MESSAGE = 'Payment modes not selected';

const primaryColor = '#0f141a',
  primaryColorMobile = '#4DA47A',
  textColor = '#FFFFFF';

const LOADER_STATE = {
  UPDATE: 'UPDATE',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
};

const CustomButton = ({ label, onClick, show = true, type = 'button', loading, ...restProps }) => {
  if (!show) {
    return null;
  }
  const onClickAction = (event) => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Button
      variant="contained"
      size="large"
      type={type}
      {...restProps}
      onClick={onClickAction}
      style={{ position: 'relative', marginTop: 0, marginBottom: 0 }}
    >
      <span style={{ opacity: loading ? 0 : 1 }}>{label}</span>
      {loading ? <Loader style={{ position: 'absolute', stroke: '#ffffff' }} small /> : null}
    </Button>
  );
};

const ShiprocketShippingSetting = () => {
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const token = useSearchParamsQuery().get('token');
  const desktop = !source || source === 'desktop';
  const classes = useStyles();
  const history = useHistory();
  const [, dispatch] = useAppContext();
  const [toastState, setToastState] = useState({ message: '', open: false });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState();
  const [error, setError] = useState(null);
  const [isActive, setIsActive] = useState(
    getCachedSelectedProvider() === SHIPPING_PROVIDERS.SHIPROCKET
  );
  const [credentialsSaved, setCredentialsSaved] = useState({});
  const [credentials, setCredentials] = useState({});
  const [invalidCredentials, setInvalidCredentials] = useState({});

  const [fetchingPaymentModes, setFetchingPaymentModes] = useState(false);
  const [hasPaymentModes, setHasPaymentModes] = useState(true);
  const [showAddPaymentModesConfirmation, setShowAddPaymentModesConfirmation] = useState(false);

  const fetchPaymentModesActionRef = useRef(null);

  const isCredentialsAvailable = Object.keys(credentialsSaved).length > 0;

  const { t } = getI18N();

  const fetchPaymentModes = useCallback(() => {
    if (fetchingPaymentModes) {
      return;
    }
    setFetchingPaymentModes(true);
    payments
      .getPaymentMethods()
      .then((paymentModes) => {
        if (Array.isArray(paymentModes)) {
          setHasPaymentModes(paymentModes.some(({ isActive }) => isActive));
        }
        setFetchingPaymentModes(false);
      })
      .catch(() => {
        setFetchingPaymentModes(false);
      });
  }, [fetchingPaymentModes]);

  useEffect(() => {
    fetchPaymentModesActionRef.current = fetchPaymentModes;
  }, [fetchPaymentModes]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? primaryColor : primaryColorMobile,
        color: textColor,
        title: t('shiprocket'),
        hideBack: false,
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor(primaryColorMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  useEffect(() => {
    if (toastState.open) {
      setTimeout(() => setToastState({ open: false, message: '' }), 2000);
    }
  }, [toastState.open]);

  useEffect(() => {
    if (fetchPaymentModesActionRef.current) {
      fetchPaymentModesActionRef.current();
    }
    getShippingCredentials()
      .then((credentialsResponse) => {
        const { identifier: cachedEnteredIdentifier, password: cachedEnteredPassword } =
          getCachedEnteredCredentials() || {};
        setCredentialsSaved(credentialsResponse || {});
        const { identifier, password } = credentialsResponse || {};
        setCredentials({
          identifier: cachedEnteredIdentifier || identifier,
          password: cachedEnteredPassword || (password ? DEFAULT_PASSWORD_KEY : ''),
        });
        setLoading(false);
      })
      .catch(() => {
        setToastState({
          message: t('fetching_credentials_failed'),
          open: true,
        });
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateAndPrepareCredentials = () => {
    let { password, identifier } = credentials || {};

    const invalidFields = {};
    const newCrendentials = {};

    identifier = (identifier || '').trim();

    if (!password) {
      invalidFields.password = true;
    } else if (password !== DEFAULT_PASSWORD_KEY) {
      // already have a password and updated
      newCrendentials.password = password;
    }

    if (!identifier) {
      invalidFields.identifier = true;
    } else if (identifier === credentialsSaved.identifier) {
      // if identifier is not changed
      // but password changed
      if (password !== DEFAULT_PASSWORD_KEY) {
        newCrendentials.identifier = identifier;
      }
    } else {
      newCrendentials.identifier = identifier;
    }

    if (Object.keys(invalidFields).length) {
      setInvalidCredentials(invalidFields);
      return;
    }

    return Object.keys(newCrendentials).length && newCrendentials.identifier
      ? newCrendentials
      : null;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newCrendentials = validateAndPrepareCredentials();
    if (!newCrendentials || loading || updating) {
      return;
    }
    setUpdating(isCredentialsAvailable ? LOADER_STATE.UPDATE : LOADER_STATE.ENABLE);
    setError(null);
    updateShippingCredentials({
      credentials: newCrendentials,
      provider: SHIPPING_PROVIDERS.SHIPROCKET,
    })
      .then(async () => {
        if (!isCredentialsAvailable) {
          await updateShipRocket(true)({ preventDefault: () => {} }, true);
        }
        return Promise.resolve();
      })
      .then(() => {
        setUpdating();
        if (!isCredentialsAvailable) {
          setIsActive(true);
        }
        setToastState({
          message: !isCredentialsAvailable
            ? t('added_credentials_and_enabled_shiprocket')
            : t('updated_credentials'),
          open: true,
        });
        const { identifier, password = credentialsSaved.password } = newCrendentials;
        setCredentialsSaved({ identifier, password: !!password });
        setCredentials({ identifier, password: password ? DEFAULT_PASSWORD_KEY : '' });
      })
      .catch((ex) => {
        let message = !isCredentialsAvailable ? t('enable_failed') : t('update_failed');
        let open = true;
        if (ex === INVALID_CREDENTIALS_MESSAGE) {
          message = ex || t('update_failed');
          setError(ex);
        } else if (ex === PAYMENT_MODE_NA_ERROR_MESSAGE) {
          setHasPaymentModes(false);
          setShowAddPaymentModesConfirmation(true);
          open = false;
        }
        setToastState({
          message,
          open,
        });
        setUpdating();
      });
  };

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (error) {
      setError(null);
    }
    setInvalidCredentials((invalids) => ({
      ...invalids,
      [name]: !value,
    }));
    setCredentials((credentials) => {
      return {
        ...credentials,
        [name]: value,
      };
    });
  };

  const onFocusOut = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'password' && value === '') {
      const { password } = credentialsSaved;
      if (password) {
        setCredentials((fields) => ({
          ...fields,
          [name]: DEFAULT_PASSWORD_KEY,
        }));
      }
    }

    if (name === 'identifier' && value === '') {
      const { identifier } = credentialsSaved;
      if (identifier) {
        setCredentials((fields) => ({
          ...fields,
          [name]: identifier,
        }));
      }
    }

    setInvalidCredentials({});
  };

  const onFocusPassword = () => {
    const { password } = credentials;
    if (password === DEFAULT_PASSWORD_KEY) {
      setCredentials((fields) => ({
        ...fields,
        password: '',
      }));
    }
  };

  const updateShipRocket = (enable) => (event, forceUpdate) => {
    if (event) {
      event.preventDefault();
    }
    if ((loading || updating) && !forceUpdate) {
      return Promise.resolve();
    }

    setUpdating(enable ? LOADER_STATE.ENABLE : LOADER_STATE.DISABLE);

    return updateNewShippingProvider(enable ? SHIPPING_PROVIDERS.SHIPROCKET : null)
      .then(() => {
        setIsActive(enable);
        setToastState({
          message: `${enable ? t('shiprocket_enabled'): t('shiprocket_disabled')}`,
          open: true,
        });
        setUpdating();
        return Promise.resolve();
      })
      .catch((err) => {
        if (err !== PAYMENT_MODE_NA_ERROR_MESSAGE) {
          setToastState({
            message: (err || {}).message || `${enable ? t('shiprocket_enable_failed') : t('shiprocket_disable_failed')}`,
            open: true,
          });
        } else {
          setHasPaymentModes(false);
          setShowAddPaymentModesConfirmation(true);
        }
        setUpdating();
        return Promise.reject(err);
      });
  };

  const configureShippingSettings = () => {
    history.push({
      pathname: `/configure-shiprocket-settings`,
      search: createQueryParams([
        { key: 'token', value: token },
        { key: 'source', value: source },
        { key: 'languageCode', value: languageCode },
      ]),
    });
  };

  const redirectToChangePaymentSettings = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setCachedEnteredCredentials(credentials);
    history.push({
      pathname: '/payments',
      search: createQueryParams([
        { key: 'token', value: token },
        { key: 'source', value: source },
        { key: 'languageCode', value: languageCode },
        { key: 'showDoneButton', value: true },
      ]),
    });
  };

  const closeConfirmationBox = () => setShowAddPaymentModesConfirmation(false);

  const getApiErrorMessage = () => {
    if (!error || error !== INVALID_CREDENTIALS_MESSAGE) {
      return;
    }
    return (
      <Typography className="error-message" color="error">
        {error || INVALID_CREDENTIALS_MESSAGE}
      </Typography>
    );
  };

  const renderLoader = (isLoading, small = true) => {
    if (!isLoading) {
      return null;
    }
    return <Loader small={small} style={{ margin: 0 }} />;
  };

  const renderApiInformation = () => {
    return <div className="loader-container">{getApiErrorMessage()}</div>;
  };

  const renderSetUpButton = () => (
    <Button className={classes.button} onClick={redirectToChangePaymentSettings}>
      {t('setup_now')}
    </Button>
  );

  const renderPaymentGatwayInfoSection = () => {
    if (hasPaymentModes) {
      return null;
    }

    return (
      <Card id="paymentGatwaySetting" className="payment-gatway-settings-container">
        <Typography color="error" className="error-message">
          {t('payment_modes_are_not_selected')}
        </Typography>
        <div className="payment-actions">{renderSetUpButton()}</div>
      </Card>
    );
  };
  const onEnable = (event) => {
    if (!hasPaymentModes) {
      event.preventDefault();
      event.stopPropagation();
      setShowAddPaymentModesConfirmation(true);
      return;
    }
    if (isCredentialsAvailable) {
      return updateShipRocket(true)(event);
    }
    onSubmit(event);
  };

  const renderContent = () => {
    if (loading) {
      return <div className="loader-container">{renderLoader(loading, false)}</div>;
    }

    return (
      <div>
        {isCredentialsAvailable && (
          <>
            <SettingCard
              id={'SHIPROCKET_SETTINGS'}
              className="ship-settings-card"
              settingTitle={t('ship_using_shiprocket')}
              showIcon={false}
              onCardClick={({ event }) => {
                if (!isActive) {
                  return onEnable(event);
                }
                return updateShipRocket(false)(event);
              }}
              disabled={true}
              source={source}
              languageCode={languageCode}
              initialActiveValue={isActive}
            />
            <SettingCard
              id={'CONFIGURE_SHIPPING_SETTINGS'}
              showButton={true}
              className="ship-settings-card"
              settingTitle={t('shipping_settings')}
              settingSubtitle={t('configure_the_available_shipping_settings_to_suit_your_business')}
              showIcon={false}
              onCardClick={configureShippingSettings}
              disabled={true}
              source={source}
              languageCode={languageCode}
            />
          </>
        )}

        <div className="api-credentials-container">
          <div className="intro-container">
            <div className="title">{t('api_credentials')}</div>
            <div className="intro-text">
              {t('to_enable_shipping_with_shiprocket_please_enter_your_shiprocket_credentials')}
            </div>
            <a
              className="service-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.shiprocket.in/api-user"
            >
              {t('click_here_to_access_your_shiprocket_api_credentials')}
            </a>

            {renderPaymentGatwayInfoSection()}
          </div>
          <form
            noValidate
            autoComplete="off"
            className="shipping-setting-form"
            onSubmit={onSubmit}
            action="/"
          >
            <CustomTextField
              fullWidth
              label={t('email')}
              margin="dense"
              name="identifier"
              InputLabelProps={{ shrink: true }}
              value={credentials.identifier}
              error={invalidCredentials.identifier}
              helperText={
                invalidCredentials.identifier
                  ? credentials.identifier
                    ? t('invalid_email')
                    : t('please_enter_your_email')
                  : ' '
              }
              disabled={!hasPaymentModes}
              onChange={onChange}
              onBlur={onFocusOut}
            />
            <CustomTextField
              fullWidth
              type="password"
              label={t('password')}
              margin="dense"
              name="password"
              InputLabelProps={{ shrink: true }}
              value={credentials.password}
              error={invalidCredentials.password}
              helperText={
                invalidCredentials.password
                  ? credentials.password
                    ? t('invalid_password')
                    : t('please_enter_your_password')
                  : error
                  ? ''
                  : ' '
              }
              disabled={!hasPaymentModes}
              onFocus={onFocusPassword}
              onChange={onChange}
              onBlur={onFocusOut}
            />
            <div className="action-buttons">
              {renderApiInformation()}
              <CustomButton
                label={t('update')}
                show={isCredentialsAvailable}
                type="submit"
                disabled={!hasPaymentModes}
                loading={updating === LOADER_STATE.UPDATE}
                className={classes.button}
              />
              <CustomButton
                label={t('enable')}
                show={Object.keys(credentialsSaved).length === 0}
                type={isCredentialsAvailable ? 'button' : 'submit'}
                className={classes.button}
                loading={updating === LOADER_STATE.ENABLE}
                disabled={!hasPaymentModes}
                onClick={onEnable}
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="shippng-container">
      {renderContent()}
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
      <Dialog
        id="payment-mode-confirmation-box"
        maxWidth="xs"
        open={showAddPaymentModesConfirmation}
      >
        <DialogTitle>{t('setup_payment_modes')}</DialogTitle>
        <DialogContent className="confirmation-box-content">
          <Typography component="p" className="confirmation-question">
            {t('setup_payment_gateway_to_start_using_shiprocket')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationBox}>{t('cancel')}</Button>
          {renderSetUpButton()}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(ShiprocketShippingSetting);
