import React from 'react';
import CustomTextField from '../../../../common/CustomTextField';
import { makeStyles } from '@material-ui/core/styles';
import { getI18N } from '../../../../i18N';

const useStyles = makeStyles({
  title: {
    margin: '24px 24px 0px',
    color: '#ffffff',
    fontSize: '17px',
    fontWeight: '700',
  },
});

export default function StepOne({
  state,
  isActive,
  credentialsExist,
  errors,
  onChange,
  showOldForm,
}) {
  const classes = useStyles();
  const { t } = getI18N();
  return (
    <>
      {!showOldForm && (
        <div className={classes.title}>
          {t('personal_details')}
        </div>
      )}
      <CustomTextField
        fullWidth
        label={t('name')}
        margin="normal"
        value={state.name}
        name="name"
        onChange={onChange}
        error={!!errors['name']}
        helperText={errors['name']}
        InputLabelProps={{ shrink: true }}
        disabled={isActive || credentialsExist}
      />
      <CustomTextField
        fullWidth
        label={t('email')}
        value={state.email}
        name="email"
        onChange={onChange}
        error={!!errors['email']}
        helperText={errors['email']}
        InputLabelProps={{ shrink: true }}
        disabled={isActive || credentialsExist}
      />
      {!showOldForm && (
        <>
          <CustomTextField
            fullWidth
            label={t('phone')}
            value={state.phone}
            name="phone"
            onChange={onChange}
            error={!!errors['phone']}
            helperText={errors['phone']}
            InputLabelProps={{ shrink: true }}
            disabled={isActive || credentialsExist}
          />
          <CustomTextField
            fullWidth
            label={t('PAN')}
            name="pan"
            value={state.pan}
            onChange={onChange}
            error={!!errors['pan']}
            helperText={errors['pan']}
            InputLabelProps={{ shrink: true }}
            disabled={isActive || credentialsExist}
          />
        </>
      )}
    </>
  );
}
