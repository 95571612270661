import React from 'react';
import { withRouter } from 'react-router-dom';
import { Toolbar, Box, IconButton, Button, Typography, AppBar, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAppContext } from './Contexts/AppContext';
import { ReactComponent as EditIcon } from 'qs-assets/images/edit.svg';
import SearchIcon from 'qs-assets/icons/SearchIcon';
import CloseIcon from '@material-ui/icons/Close';
import { goBack, postMessage, PLATFORMS, getPlatform } from '../os';
import './style.css';
import Ripples from 'react-ripples';
import { ReactComponent as GoogleLogo } from 'qs-assets/images/Google__G__Logo.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '../assets/icons/DeleteIcon';
import { WarningRounded } from '@material-ui/icons';
import { getI18N } from '../i18N';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  gmcTitle: {
    fontSize: 20,
    flexGrow: 1,
  },
  navButton: {
    color: '#000',
    textTransform: 'none',
    marginRight: '20px',
    cursor: 'pointer',
  },
  warningButton: {
    paddingLeft: '5px',
    paddingRight: '5px',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  searchIconButton: {
    height: '20px',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  closeIconButton: {
    width: '20px',
    cursor: 'pointer',
  },
  supportButton: {
    width: '98px',
    height: '32px',
    borderRadius: '18px',
    backgroundColor: '#fff',
    color: '#289D75',
    fontSize: '12px',
    textTransform: 'capitalize',
    fontWeight: 700,
  },
}));

const NewToolbar = withStyles({
  root: {
    minHeight: 55,
    height: 55,
  },
})(Toolbar);

function GenericNavBar(props) {
  const { history } = props;
  const { navBar } = useAppContext()[0];
  const {
    title = '',
    background = '#fff',
    color = '#000',
    boxShadow,
    borderBottom,
    display,
    hideBack,
    hide,
    actions,
    value,
    placeholder,
    onClick,
    readOnlyTextField,
    expandSearchBox,
    searchable,
    searchIconColor,
    onChange,
    goToHelpCenter,
    support,
    googleMerchantCenter,
    maxWidth,
    height,
    warning,
    warningClick,
    overrideTitleClassStyle = {},
    enableBoxShadow = true,
    showTitleSubTitle = false,
    subTitle = '',
    changedNavbarTitle = '',
    isSelected = false,
    hideChangedNavbar = null,
    onMoreIconClick = null,
    onDeleteIconClick = null,
    showCloseIcon = false,
    onCloseIconClick = null,
    onBackClick,
    showDeleteButton = false,
    titleSubTitleStyle,
    arrowBackButtonStyle = {},
    deleteBtnStyle = {}
  } = navBar;
  const { t } = getI18N();
  const classes = useStyles();
  const bgStyle = boxShadow ? { background, boxShadow, display } : { background, display };
  const navBarBoxShadow = !enableBoxShadow ? { boxShadow: 'none' } : {};
  const borderBottomStyle = borderBottom ? { borderBottom: borderBottom } : {};
  const clrStyle = { color };
  const actionStyle = actions
    ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }
    : {};
  const isPlatformIos = getPlatform() === PLATFORMS.IOS;
  const platformToolBarStyle = isPlatformIos ? { flex: '1 1 auto' } : {};
  const platformTitleStyle = isPlatformIos
    ? {
        textAlign: 'center',
        fontSize: 18,
        margin: !maxWidth ? '0px 40px' : '0px',
      }
    : {};
  const platflormBackButtonStlye = isPlatformIos ? { position: 'absolute' } : {};
  const platflormActionButtonStlye = isPlatformIos
    ? { position: 'absolute', top: 8, right: 0 }
    : {};
  const navBtnClick = () => {
    if (typeof onBackClick === 'function') {
      onBackClick();
      return;
    }
    const postMessageDispatched = postMessage(history);
    if (!postMessageDispatched) {
      goBack(history);
    }
  };
  if (hide) return null;

  const renderActions = () => {
    if (!actions) {
      return;
    }

    const actionsContent = typeof actions === 'function' ? actions() : actions;

    return (
      <Box className="actions-box" style={platflormActionButtonStlye}>
        {actionsContent}
      </Box>
    );
  };

  const newAppbarStyle = {};
  const newToolbarStyle = {};
  if (maxWidth || maxWidth === 0) {
    newToolbarStyle.maxWidth = maxWidth;
    newToolbarStyle.flex = isPlatformIos ? '1 1 auto' : 'initial';
  }
  if (height || height === 0) {
    newAppbarStyle.height = height;
    newAppbarStyle.minHeight = height;
  }

  return (
    <AppBar
      style={{
        ...bgStyle,
        ...clrStyle,
        ...actionStyle,
        ...borderBottomStyle,
        ...navBarBoxShadow,
        ...newAppbarStyle,
      }}
    >
      {!isSelected ? (
        <NewToolbar style={{ ...newToolbarStyle, ...platformToolBarStyle }}>
          {!hideBack && !expandSearchBox && (
            <IconButton
              onClick={navBtnClick}
              edge="start"
              className={`icon ${classes.menuButton}`}
              color="primary"
              aria-label="menu"
              style={
                maxWidth || maxWidth === 0 || readOnlyTextField
                  ? {}
                  : { ...platflormBackButtonStlye, ...arrowBackButtonStyle }
              }
            >
              <ArrowBackIcon style={clrStyle} />
            </IconButton>
          )}
          {googleMerchantCenter && getPlatform() === PLATFORMS.WEB && (
            <GoogleLogo width="30" height="30" style={{ marginRight: 10, marginLeft: -16 }} />
          )}
          {readOnlyTextField ? (
            <div style={{ display: 'flex', alignItems: 'flex-end' }} onClick={onClick}>
              <TextField
                value={value || ''}
                placeholder={placeholder}
                InputProps={{
                  readOnly: true,
                }}
              />
              <div style={{ marginLeft: '10px' }}>
                <Ripples onClick={onClick}>
                  <EditIcon fill="#FFF" width="16" height="16" />
                </Ripples>
              </div>
            </div>
          ) : (
            !expandSearchBox && (
              <Typography
                variant="h6"
                style={{
                  ...clrStyle,
                  ...platformTitleStyle,
                  ...overrideTitleClassStyle,
                }}
                className={`heading ${googleMerchantCenter ? classes.gmcTitle : classes.title}`}
                color="primary"
                noWrap
              >
                {title && subTitle ? (
                  <div className="titleAndSubtitleStyle" style={titleSubTitleStyle}>
                    {showTitleSubTitle && <div className="titleStyleOnScrolling">{title}</div>}
                    {showTitleSubTitle && (
                      <div className="subTitleStyleOnScrolling">{subTitle}</div>
                    )}
                  </div>
                ) : (
                  title
                )}
              </Typography>
            )
          )}
          {showCloseIcon && (
            <div onClick={onCloseIconClick} className={classes.closeIconButton}>
              <CloseIcon />
            </div>
          )}
          {goToHelpCenter && (
            <Button variant="contained" onClick={goToHelpCenter} className={classes.navButton}>
              {t('how_to_use')}
            </Button>
          )}
          {warning && (
            <div className={classes.warningButton} onClick={warningClick}>
              <WarningRounded color="primary" width="30" height="30" />
            </div>
          )}
          {showDeleteButton && (
            <div className={classes.deleteButton} onClick={onDeleteIconClick} style={deleteBtnStyle}>
              <DeleteIcon color="#fff" />
            </div>
          )}
          {searchable && !expandSearchBox ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                alignSelf: 'center',
                paddingLeft: '5px',
              }}
            >
              <div onClick={onClick} className={classes.searchIconButton}>
                <SearchIcon
                  color={searchIconColor || '#FFFFFF'}
                  width="16"
                  height="16"
                  style={{ height: 16, width: 16 }}
                />
              </div>
            </div>
          ) : (
            expandSearchBox && (
              <Box style={{ display: 'flex', alignItems: 'flex-end', flex: '1 1 0' }}>
                <div className={classes.searchIconButton}>
                  <SearchIcon
                    color={searchIconColor || '#FFFFFF'}
                    width="16"
                    height="16"
                    style={{ height: 16, width: 16 }}
                  />
                </div>
                <TextField
                  autoFocus
                  fullWidth
                  value={value || ''}
                  placeholder={placeholder}
                  onChange={onChange}
                  InputProps={{ disableUnderline: true }}
                  style={{ marginLeft: 20 }}
                />
                <div onClick={onClick} className={classes.closeIconButton}>
                  <CloseIcon />
                </div>
              </Box>
            )
          )}
          {support && (
            <Button variant="contained" className={classes.supportButton}>
              {t('support')}
            </Button>
          )}
        </NewToolbar>
      ) : (
        <NewToolbar style={{ ...newToolbarStyle, ...platformToolBarStyle }}>
          <div
            onClick={hideChangedNavbar}
            className={classes.closeIconButton}
            style={{ paddingRight: 25 }}
          >
            <CloseIcon />
          </div>
          <Typography
            variant="h6"
            style={{
              ...clrStyle,
              ...platformTitleStyle,
              ...overrideTitleClassStyle,
            }}
            className={`heading ${classes.title}`}
            color="primary"
            noWrap
          >
            {changedNavbarTitle}
          </Typography>
          {onDeleteIconClick && (
            <img
              src={DeleteIcon}
              alt="delete icon"
              style={{ width: 20, cursor: 'pointer' }}
              onClick={onDeleteIconClick}
            />
          )}
          {onMoreIconClick && (
            <MoreVertIcon
              style={{ width: 20, paddingLeft: 15, cursor: 'pointer' }}
              onClick={onMoreIconClick}
            />
          )}
        </NewToolbar>
      )}
      {renderActions()}
    </AppBar>
  );
}

export default withRouter(GenericNavBar);
