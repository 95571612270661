import React, { Fragment, useState, useEffect } from 'react';
import { ArrowForward } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import ReferralData from 'qs-data/referral';
import './style.scss';
import Utility from 'qs-helpers/utility';
import ReferralRedeemedDialog from '../ReferralRedeemedDialog';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { getI18N } from '../../../i18N';

export default function ReferralCard({ data, onRedeemSuccess, isIndiaReferral }) {
  const [progressBar, setProgressBar] = useState(false);
  const [showDialog, toggleShowDialog] = useState(false);
  const [referralReward, setReferralReward] = useState({});
  const [errorComponent, showErrorComponent] = useState(false);
  const { t } = getI18N();
  const { name, email, status, userId, redeemedOn, phone } = data;

  useEffect(() => {
    if (status === 'REDEEMABLE') {
      fetchRewardContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRewardContent = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ReferralData.getReferralReward(userId)
      .then((data) => {
        setReferralReward(data);
        setProgressBar(false);
      })
      .catch(() => {
        showErrorComponent(true);
      });
  };

  const redeemReferralCode = async (friendUserId) => {
    setProgressBar(true);
    ReferralData.redeemReferralCoupon({ friendUserId: friendUserId })
      .then(() => {
        toggleShowDialog(true);
        setProgressBar(false);
      })
      .catch(() => {
        showErrorComponent(true);
      });
  };

  const getStatusContent = (status) => {
    switch (status) {
      case 'PAYMENT_PENDING':
        return t('friend_joined_not_yet_paid');
      case 'REDEEMABLE':
        return t('redeem_days_free', { duration: referralReward.duration });
      case 'REDEEMED':
        return t('redeemed_on') + Utility.getFormattedDate(new Date(redeemedOn));
      default:
        return '';
    }
  };

  const onClose = () => {
    toggleShowDialog(false);
    onRedeemSuccess();
  };

  const isRedeemable = status === 'REDEEMABLE';

  return (
    <Fragment>
      <div
        className="ref-card"
        style={{
          background: isIndiaReferral ? '#279B74' : '#ffffff',
        }}
        onClick={isRedeemable && !isIndiaReferral ? () => redeemReferralCode(userId) : null}
      >
        <div
          className="name"
          style={{
            color: isIndiaReferral ? '#ffffff' : '#242b35',
          }}
        >
          {name}
        </div>
        <div
          className="email"
          style={{
            color: isIndiaReferral ? '#ffffff' : '#242b35',
          }}
        >
          {email}
        </div>
        <div
          className="phone"
          style={{
            color: isIndiaReferral ? '#ffffff' : '#242b35',
          }}
        >
          {phone}
        </div>
        {!isIndiaReferral &&
          (progressBar ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <div className="redeemStatus">
              {errorComponent ? (
                <CustomErrorComponent onRetryClick={fetchRewardContent} />
              ) : (
                <span className={`status ${status}`}>{getStatusContent(status)}</span>
              )}
              {isRedeemable && (
                <span className={status}>
                  <ArrowForward className={`redeemArrow ${status}`} />
                </span>
              )}
            </div>
          ))}
      </div>
      <ReferralRedeemedDialog
        showDialog={showDialog}
        onClose={onClose}
        successMessage={t('redeem_reward_message', {
          duration: referralReward.duration,
          planType: referralReward.planType,
        })}
      />
    </Fragment>
  );
}
