import React from 'react';
import {isNil} from 'lodash';
import { Select, MenuItem } from "@material-ui/core";
import "./style.scss";

const CustomSelect = ({ handleChange, selectedValue, itemList, dataKey }) => {
    let index = 0;
    if (isNil(dataKey)) {
        for (let m = 0; m < itemList.length; m++) {
            if (itemList[m] === selectedValue) {
                index = m;
                break;
            }
        }
    } else {
        for (let m = 0; m < itemList.length; m++) {
            if (itemList[m][dataKey] === selectedValue) {
                index = m;
                break;
            }
        }
    }

    return <Select className={'customSelect'} value={itemList[index]} onChange={handleChange} autoWidth>
        {
            itemList.map(function (item) {
                if (typeof (item) === 'string') {
                    return <MenuItem
                        className='menuItem'
                        key={item}
                        value={item}>{item}
                    </MenuItem>
                } else {
                    return <MenuItem
                        className='menuItem'
                        key={item[dataKey]}
                        value={item}
                    >{item[dataKey]}
                    </MenuItem>
                }
            })
        }
    </Select>
}

export default CustomSelect;