import React, { useRef } from 'react';
import rt from 'remaining-time';
import { getI18N } from '../../i18N';

function ListCountDownTime({ webinarTime }) {
  const { t } = getI18N();
  const timeObjRef = useRef(rt.date(webinarTime));

  const getDataToShow = () => {
    if (new Date() > new Date(webinarTime)) {
      return t('live_now');
    }

    const timeObj = timeObjRef.current;

    if (timeObj.day >= 2) {
      return t(timeObj.day === 1 ? 'day_from_now' : 'days_from_now', { count: timeObj.day });
    }

    if (timeObj.day > 0 || timeObj.hour > 0) {
      const numberOfHours = 24 * timeObj.day + timeObj.hour;
      return t(numberOfHours === 1 ? 'hour_remaining' : 'hours_remaining', { count: numberOfHours });
    }

    if (timeObj.minute > 0) {
      return t(timeObj.minute === 1 ? 'minute_remaining' : 'minutes_remaining', { count: timeObj.minute });
    }
  };

  return <div className='ListCountDownTime'>{getDataToShow()}</div>;
}

export default ListCountDownTime;
