import React from 'react';
import './style.scss';

export default function ColoredButton(props) {
  const {
    primaryColor = '#ffffff',
    primaryContent = <p></p>,
    secondaryColor = '#ffffff',
    secondaryContent = <p></p>,
    className = '',
    style = {},
    isIndiaReferral,
    ...remains
  } = props;

  const priColors = {
    background: primaryColor,
    height: isIndiaReferral ? '50px' : '60px',
  };

  const secColors = {
    background: secondaryColor,
    width: isIndiaReferral ? '' : '50%',
    clipPath: isIndiaReferral ? '' : 'polygon(0.01% 100%, 100% -100%, 100% 100%)',
  };

  return (
    <div className={`coloredButton ${className}`} style={{ ...style, ...priColors }} {...remains}>
      <div className="primary" style={{ width: isIndiaReferral ? '' : '50%' }}>
        {primaryContent}
      </div>
      <div className="secondary" style={secColors}>
        {secondaryContent}
      </div>
    </div>
  );
}
