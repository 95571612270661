import React, { useEffect, useState } from 'react';
import Ripples from 'react-ripples';
import { withRouter, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import ShippingPricingToggle from 'qs-components/Shipping/Settings/ShippingPricingToggle';
import HomeLocation from 'qs-components/Shipping/Settings/HomeLocation';
import ShippingWeightUnit from 'qs-components/Shipping/Settings/ShippingWeightUnit';
import ShippingMode from 'qs-components/Shipping/Settings/ShippingMode';
import ShippingZones from 'qs-components/Shipping/Settings/ShippingZone';
import ShippingWeightProfiles from 'qs-components/Shipping/Settings/ShippingWeightProfiles';
import { ReactComponent as NextIcon } from '../../assets/images/right_arrow.svg';
import { getI18N } from '../../i18N';
import './style.scss';
import { getToken } from 'qs-data/util';

const ShippingSettings = () => {
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();

  const history = useHistory();

  const [shippingCostCollectionStatus, setShippingCostCollectionStatus] = useState(false);
  const [homeLocation, setHomeLocation] = useState(null);
  const [shippingModes, updateShippingModes] = useState([]);
  const [shippingZones, updateShippingZones] = useState([]);
  const [weightUnit, setWeightUnit] = useState(null);

  const [numberOfProfiles, setNumberOfProfiles] = useState(0);
  const { t } = getI18N();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('custom'),
      },
    });
    setNavigationBarColor('#28303A');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  useEffect(() => {
    setNumberOfProfiles(getNumberOfProfilesSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingModes, shippingZones]);

  useEffect(() => {
    if (!shippingCostCollectionStatus) {
      setNumberOfProfiles(0);
    }
  }, [shippingCostCollectionStatus]);

  function navigateToShippingProfiles() {
    history.push({
      pathname: '/shipping-profiles',
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
      state: {
        shippingModes: shippingModes,
        shippingZones: shippingZones,
        weightUnit: weightUnit
      },
    });
  }

  const onWeightUpdated = (weight) => {
    setWeightUnit(weight);
  };

  const getNumberOfProfilesSelected = () => {
    let numberOfProfilesSelected = 0;
    shippingModes.forEach(function (mode) {
      if (mode.enabled) {
        shippingZones.forEach(function (zone) {
          if (zone.enabled) {
            numberOfProfilesSelected++;
          }
        });
      }
    });
    return numberOfProfilesSelected;
  };

  const renderBottomNavigationBar = () => {
    if (isEmpty(shippingModes) || isEmpty(shippingZones) || numberOfProfiles === 0) {
      return null;
    }

    return (
      <Ripples className="actionButtonsContainer" onClick={navigateToShippingProfiles}>
        <span>{t('enter_rates')}</span>
        <NextIcon fill="#FFF" width="20" height="20" />
      </Ripples>
    );
  };

  return (
    <div className="shippingSettingsContainer">
      <div
        className="shippingComponents"
        style={{ marginBottom: numberOfProfiles !== 0 ? 0 : '48px' }}
      >
        <ShippingPricingToggle
          shippingCostCollectionUpdate={(shippingCostCollectionStatus) =>
            setShippingCostCollectionStatus(shippingCostCollectionStatus)
          }
        />
        {!shippingCostCollectionStatus ? null : (
          <div>
            <HomeLocation onHomeLocationUpdated={(homeLocation) => setHomeLocation(homeLocation)} />
            {!homeLocation || !homeLocation.name ? null : (
              <div>
                <ShippingWeightUnit onWeightUpdated={onWeightUpdated} />
                <ShippingMode
                  onUpdateShippingModes={(shippingModes) => updateShippingModes(shippingModes)}
                />
                <ShippingZones
                  homeLocation={homeLocation}
                  onUpdateShippingZones={(shippingZones) => updateShippingZones(shippingZones)}
                />
                <ShippingWeightProfiles weightUnit={weightUnit} />
              </div>
            )}
          </div>
        )}
      </div>
      {renderBottomNavigationBar()}
    </div>
  );
};

export default withRouter(ShippingSettings);
