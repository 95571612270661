import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

let apis = {
  getGoogleAnalyticsEndPoint: '/v1/experiment/field-value',
  setGoogleAnalyticsIdEndPoint: '/v1/experiment/field-value',
};

/* Google Analytics code */

const getGoogleAnalyticsData = async (fields) => {
  const data = await Promise.all(fields.map(field => ApiWrapper.getDataPromise(
      `${apis.getGoogleAnalyticsEndPoint}?field=${field}`,
      '',
      CacheKeys[field]
    )));

  return data.reduce((prev, { value }, idx) => {
    prev[fields[idx]] = value;
    return prev;
  }, {})
};

const setGoogleAnalyticsFieldValue = (companyId, field, value) => {
  return ApiWrapper.postDataPromise(
    apis.setGoogleAnalyticsIdEndPoint,
    {
      entityType: 'COMPANY',
      entityId: companyId,
      field,
      value,
    },
    CacheKeys.googleAnalyticsId
  );
};

export default {
  getGoogleAnalyticsData,
  setGoogleAnalyticsFieldValue,
};
