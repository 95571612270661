import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import RewardsStarIcon from 'qs-assets/icons/RewardsStarIcon';
import RewardsGiftIcon from 'qs-assets/icons/RewardsGiftIcon';
import ReferNowButtonIcon from 'qs-assets/icons/ReferNowButtonIcon';
import CopyToClipBoardIcon from 'qs-assets/icons/CopyToClipBoardIcon';
import ContactIcon from 'qs-assets/icons/ContactIcon';
import HandShakeIcon from 'qs-assets/icons/HandShakeIcon';
import DiamondIcon from 'qs-assets/icons/DiamondIcon';
import EmeraldIcon from 'qs-assets/icons/EmeraldIcon';
import GoldIcon from 'qs-assets/icons/GoldIcon';
import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../os';
import network from 'qs-data/network';
import { Button } from '@material-ui/core';
import ReferralData from 'qs-data/referral';
import './style.scss';
import Loader from 'qs-common/Loader';
import Toast from 'qs-common/Alerts/Toast';
import { getToken } from 'qs-data/util';
import DialogBox from 'qs-common/DialogBox';
import offerCardsData from './OfferCards.json';
import offerCardDetailsData from './OfferCardsDetail.json';
import CloseIcon from '@material-ui/icons/Close';
import Cache from './Cache';
import Utility from 'qs-helpers/utility';
import { navigationBarColor, statusBarColor } from './Constants';
import { getI18N } from '../../i18N';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';

const inviteSentDataKeys = {
  paid: 'paid',
  downloads: 'downloads',
  invited: 'invite sent',
};

const IndianRefferalMobileHomePage = () => {
  const { t } = getI18N();
  const history = useHistory();
  const [referralLinkData, setReferralLinkData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inviteSentAndRewardsUnLockedData, setInviteSentAndRewardsUnLockedData] = useState({});
  const [offerInfo, setOfferInfo] = useState({});
  const [showDialog, setShowDialog] = useState(false || Cache.getShowModal());

  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');

  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#2B2F39',
        color: '#FFFFFF',
        boxShadow: false,
        enableBoxShadow: false,
      },
    });
    setNavigationBarColor(navigationBarColor);
    setStatusBarColor(statusBarColor);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
    showDialog && getOfferInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //adding popstate eventlistener to handle browser back and mobile back to close and repoen the dialog box
    if (showDialog) {
      window.addEventListener('popstate', closeDialogBox);
    }

    return () => {
      window.removeEventListener('popstate', closeDialogBox);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  const closeDialogBox = () => {
    if (showDialog) {
      onClose();
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([getReferralLink(), getUserInviteSentData()]);
    setLoading(false);
  };

  const getReferralLink = async () => {
    try {
      const data = await ReferralData.getReferralCodeAndOffer();
      setReferralLinkData(data);
      Cache.setReferralLinkCache(data.referralCode || '');
    } catch (error) {
      setReferralLinkData({});
      setToastState({
        open: true,
        message: t('could_not_get_referral_code'),
      });
      Cache.setReferralLinkCache('');
    }
  };

  const getUserInviteSentData = async () => {
    try {
      const data = await network.getReferralStats();
      setInviteSentAndRewardsUnLockedData(data);
    } catch (error) {
      setToastState({
        open: true,
        message: t('could_not_get_referral_information'),
      });
    }
  };

  const getOfferInfo = (id = '') => {
    if (!id) {
      id = Cache.getOfferModalId();
    }
    const offerDetails = offerCardDetailsData.filter(
      (singleOfferDetail) => singleOfferDetail.id === id
    );

    const [{ eventName }] = offerDetails;
    trackAnalytics({
      eventName: eventName,
      props: Cache.getReferralAnalyticsObject(),
    });
    setOfferInfo(offerDetails[0]);
    setShowDialog(true);
  };

  const onClose = () => {
    setShowDialog(false);
    setOfferInfo({});
    Cache.setShowModal(false);
    Cache.setOfferModalId('');
  };

  const referNow = (id) => {
    if (showDialog) {
      trackAnalytics({
        eventName: 'referral_terms_and_conditions',
        props: Cache.getReferralAnalyticsObject(),
      });
    } else {
      trackAnalytics({
        eventName: 'refer_now',
        props: Cache.getReferralAnalyticsObject(),
      });
    }

    history.push(`/referrals/selectContacts?token=${getToken()}&source=${source}&languageCode=${languageCode}`);
    if (showDialog && id) {
      Cache.setOfferModalId(id);
      Cache.setShowModal(true);
    }
  };

  const copyToClipboard = () => {
    Utility.copyToClipboard(`qsell.co/refer/${referralLinkData.referralCode}`);
    trackAnalytics({
      eventName: 'copy_referral_code',
      props: Cache.getReferralAnalyticsObject(),
    });
    setToastState({
      open: true,
      message: t('referral_code_copied_to_clipboard'),
    });
  };

  const trackReferrals = () => {
    trackAnalytics({
      eventName: 'track_referral',
      props: Cache.getReferralAnalyticsObject(),
    });
    history.push(`/referrals/yourReferrals?token=${getToken()}&source=${source}&languageCode=${languageCode}`);
  };

  const getViewRewards = () => {
    trackAnalytics({
      eventName: 'view_rewards',
      props: Cache.getReferralAnalyticsObject(),
    });
    history.push(`/referrals/yourRewards?token=${getToken()}&source=${source}&languageCode=${languageCode}`);
  };

  const onCloseDialogCrossIcon = () => {
    history.goBack();
    onClose();
  };

  const renderUserReferralData = (inviteSentAndRewardsUnLockedData) => {
    const inviteSentData = { ...inviteSentAndRewardsUnLockedData };
    delete inviteSentData.rewardsUnlocked;
    if (Object.keys(inviteSentData).length > 0) {
      return (
        <div className="userInviteSentDataContainer">
          {Object.keys(inviteSentData).map((value) => {
            return (
              <div className="inviteData" key={value}>
                <div className="count">{inviteSentData[value] || 0}</div>
                <div className="title">{inviteSentDataKeys[value]}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const renderOfferInfo = () => {
    const {
      title,
      header,
      subHeading,
      termsAndCondition,
      background,
      buttonColors,
      offertextColor,
      termsAndConditionTextColor,
      id,
    } = offerInfo;
    const width = 58;
    const height = 62;
    return (
      <DialogBox
        type={'simple'}
        dialogId={'#offer-info'}
        show={showDialog}
        width={318}
        onClose={onClose}
        showProgressBar={false}
        hideFooter={true}
        hidePrimaryButton={true}
        hideCancelButton={true}
        hideDialogActions={true}
      >
        <div className="offerInfoContainer" style={{ background, color: offertextColor }}>
          <div className="closeIcon" onClick={onCloseDialogCrossIcon}>
            <CloseIcon />
          </div>
          <div className="offerInfoImage">{renderIcon({ id, width, height })}</div>
          <header className="offerTitle">{title}</header>
          <h2 className="offerPrice">{header}</h2>
          <h3 className="offerDescription">{subHeading}</h3>
          <Button
            className="refferNowButton"
            onClick={() => referNow(id)}
            style={{
              color: buttonColors.textColor,
              background: buttonColors.background,
            }}
          >
            <ReferNowButtonIcon />
            <span className="referNowButtonHeading">{t('refer_now')}</span>
          </Button>
          <div className="termsAndConditionsTitle">{t('terms_and_conditions')}</div>
          <section>
            {termsAndCondition.map((point, index) => {
              return (
                <div
                  className="termsAndConditionsDescription"
                  style={{ color: termsAndConditionTextColor }}
                  key={index}
                >{`${index + 1}. ${point}`}</div>
              );
            })}
          </section>
        </div>
      </DialogBox>
    );
  };
  const renderIcon = ({ id, width, height }) => {
    switch (id) {
      case 1:
        return null;

      case 2:
        return <GoldIcon width={width} height={height} />;

      case 3:
        return <EmeraldIcon width={width} height={height} />;

      case 4:
        return <DiamondIcon width={width} height={height} />;

      default:
        return null;
    }
  };
  const renderCards = () => {
    const cardsData = offerCardsData;
    if (!cardsData.length) {
      return null;
    }
    return (
      <div className="cardsDataContainer">
        {cardsData.map((cardData) => {
          const {
            id,
            title,
            mainTitle,
            subHeading,
            borderText,
            background,
            mainTitleColor,
            textColor,
          } = cardData;

          return (
            <div
              className="cardContainer"
              key={id}
              style={{ background, color: textColor }}
              onClick={() => getOfferInfo(id)}
            >
              <div className={`dashedBorder ${id !== 1 ? 'firstOfferDashedBorder' : ''}`}></div>
              <div className="icon">{renderIcon({ id })}</div>
              <header className="title">{title}</header>
              <h2 className="mainTitle" style={{ color: mainTitleColor }}>
                {mainTitle}
              </h2>
              <p
                className="subHeading"
                style={
                  id === 1 ? { fontSize: '14px', lineHeight: '19px', paddingBottom: '0px' } : {}
                }
              >
                {subHeading}
              </p>
              <p className="bottomText" style={id === 1 ? { paddingTop: '30px' } : {}}>
                {borderText}
              </p>
            </div>
          );
        })}
      </div>
    );
  };
  const referralLinkSection = () => {
    return (
      <div className="referNowContainer">
        <div className="referralLinkContainer">
          <div className="referralLink">
            <div className="referralLinkTitle">{t('referral_link')}</div>
            <div className="referralLinkValue">
              {`qsell.co/refer/${referralLinkData.referralCode}`}
            </div>
          </div>
          <div className="copyClipBoardIcon" onClick={copyToClipboard}>
            <CopyToClipBoardIcon />
          </div>
        </div>
        <Button className="refferNowButton" onClick={referNow}>
          <ReferNowButtonIcon />
          <span className="referNowButtonHeading">{t('refer_now_and_earn')}</span>
        </Button>
      </div>
    );
  };
  const renderTopSection = () => {
    return (
      <div className="referralsTopSection">
        <div className="referralsTopSectionIcon">
          <HandShakeIcon />
        </div>
        <div className="referralsTopSectionTitle">{t('refer_your_friends')}</div>
        <div className="referralsTopSectionSubHeading">
          {t('earn_upto_unlimited_incentives_by_referring_customers_to_quicksell')}
        </div>
      </div>
    );
  };
  const renderTrackReferralButton = () => {
    return (
      <div className="trackReferralContainer">
        <Button className="trackReferral" onClick={trackReferrals}>
          <div className="contactIcon">
            <ContactIcon />
          </div>
          <span className="trackReferrralHeading">{t('track_referrals')}</span>
        </Button>
      </div>
    );
  };
  const renderRewardsSection = () => {
    return (
      <div className="rewardsContainer">
        <div className="rewardsTitleContiner">
          <div className="giftIcon">
            <RewardsGiftIcon />
          </div>
          <div className="rewardsTitle">{t('rewards')}</div>
        </div>
        <div className="viewRewardsContainer">
          <div className="starIcon">
            <RewardsStarIcon />
          </div>
          <div className="rewardsUnlockedAndViewRewards">
            <div className="rewardsUnLockedTitle"> {rewardsTitle}</div>
            {inviteSentAndRewardsUnLockedData &&
              inviteSentAndRewardsUnLockedData.rewardsUnlocked >= 0 && (
                <div className="viewRewards" onClick={getViewRewards}>
                  {t('view_rewards')}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  const rewardsTitle =
    inviteSentAndRewardsUnLockedData &&
    typeof inviteSentAndRewardsUnLockedData.rewardsUnlocked === 'number' &&
    inviteSentAndRewardsUnLockedData.rewardsUnlocked >= 0
      ? t(inviteSentAndRewardsUnLockedData.rewardsUnlocked === 1 ? 'reward_unlocked_message' : 'rewards_unlocked_message', {
          count: inviteSentAndRewardsUnLockedData.rewardsUnlocked
        })
      : '';

  return (
    <div className="referralsHomePageContainer">
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <>
          {renderTopSection()}
          {Object.keys(referralLinkData).length > 0 &&
            referralLinkData.referralCode &&
            referralLinkSection()}
          {renderUserReferralData(inviteSentAndRewardsUnLockedData)}
          {renderTrackReferralButton()}
          {rewardsTitle && renderRewardsSection()}
          {renderCards()}
          {Object.keys(offerInfo).length > 0 && renderOfferInfo()}
          <Toast
            message={toastState.message}
            open={toastState.open}
            duration={3000}
            onClose={() => {
              setToastState({
                open: false,
                message: '',
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default IndianRefferalMobileHomePage;
