import React from 'react';
import TextIcon from 'qs-assets/icons/TextIcon';
import CalenderIcon from 'qs-assets/icons/CalenderIcon';
import HashtagIcon from 'qs-assets/icons/HashtagIcon';
import DropdownIcon from 'qs-assets/icons/DropdownIcon';
import SelectIcon from 'qs-assets/icons/SelectIcon';
import YesNoIcon from 'qs-assets/icons/YesNoIcon';
import { FORM_FIELD_TYPES } from '../constants';


export default ({ iconType, ...restProps }) => {
  switch (iconType) {
    case FORM_FIELD_TYPES.TEXT:
      return <TextIcon {...restProps} />;
    case FORM_FIELD_TYPES.TEXTAREA:
      return <TextIcon {...restProps} />;
    case FORM_FIELD_TYPES.DATE:
      return <CalenderIcon {...restProps} />;
    case FORM_FIELD_TYPES.SINGLE_CHOICE:
      return <DropdownIcon {...restProps} />
    case FORM_FIELD_TYPES.MULTI_CHOICE:
      return <SelectIcon {...restProps} />;
    case FORM_FIELD_TYPES.BOOLEAN:
      return <YesNoIcon {...restProps} />
    case FORM_FIELD_TYPES.NUMBER:
      return <HashtagIcon {...restProps} />
    default:
      break;
  }
};