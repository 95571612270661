import ApiWrapper from './apiWrapper';

let apis = {
  getRecentCustomerListEndPoint: '/v1/contact-group/members/recently-allowed',
  getCustomerRequestEndpoint: '/v1/contact-group/members/access-requested',
  getExpiredMemberEndpoint: '/v1/contact-group/members/access-expired',
  allowSingleAccessEndpoint: '/v1/contact-group/members/allow',
  allowAccessToAllEndpoint: '/v1/contact-group/members/allow-all',
  directAllowEndpoint: '/v1/contact-group/members/allowed-list',
  deleteMemberEndpoint: '/v1/contact-group/members/allowed-list',
  changeAccessTimeEndpoint: '/v1/contact-group/members/access-time',
};

const getRecentCustomerList = (queryData) => {
  return ApiWrapper.getDataPromise(apis.getRecentCustomerListEndPoint, '', '', queryData);
};

const getCustomerRequest = (queryData) => {
  return ApiWrapper.getDataPromise(apis.getCustomerRequestEndpoint, '', '', queryData);
};

const getExpiredMember = (queryData) => {
  return ApiWrapper.getDataPromise(apis.getExpiredMemberEndpoint, '', '', queryData);
};

const allowSingleAccess = (data) => {
  const api = data.accessGivenTime ? apis.changeAccessTimeEndpoint : apis.allowSingleAccessEndpoint;
  return ApiWrapper.postDataPromise(api, data, '');
};

const allowAccessToAll = (data) => {
  return ApiWrapper.postDataPromise(apis.allowAccessToAllEndpoint, data, '');
};

const setDirectAllow = (data) => {
  return ApiWrapper.postDataPromise(apis.directAllowEndpoint, data, '');
};

const deleteMember = (data) => {
  return ApiWrapper.deleteDataPromise(apis.deleteMemberEndpoint, data, '');
};

export default {
  getRecentCustomerList,
  getCustomerRequest,
  getExpiredMember,
  allowSingleAccess,
  allowAccessToAll,
  setDirectAllow,
  deleteMember,
};
