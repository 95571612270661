import React from 'react';
import './styles.scss';
import PriceInfo from './components/PriceInfo';
import Actions from './components/Actions';
import Image from './components/Image';
import Details from './components/Details';

export default function Product({ productDetails, big, showAdded }) {
  const {
    imageUrl = 'https://images.pexels.com/photos/27269578/pexels-photo-27269578/free-photo-of-marti.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    name,
    price,
    quantity,
    onIncrement,
    onDecrement,
    onClick,
    currency,
  } = productDetails;
  return big ? (
    <div className={'product-col'}>
      <Image big src={imageUrl} />
      <PriceInfo big name={name} price={price} quantity={quantity} currency={currency} />
      <Details productDetails={productDetails} />
    </div>
  ) : (
    <div className={'product-row'}>
      <Image src={imageUrl} />
      <PriceInfo name={name} price={price} quantity={quantity} currency={currency} />
      <Actions
        quantity={quantity}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        onClick={onClick}
        showAdded={showAdded}
      />
    </div>
  );
}
