import React from 'react';
import ThemeSectionPreview from '../ThemeSectionPreview';
import './style.scss';
import { getI18N } from '../../../i18N';

export default function ({ selectedColors, setSelectedColorPicker, companyDetails = {} }) {
  const { t } = getI18N();
  const renderColorSection = (title, key, showBorderBottom) => {
    return (
      <div
        className={`colorSection ${showBorderBottom ? 'borderBottom' : ''}`}
        onClick={() => setSelectedColorPicker({ title, key, value: selectedColors[key] })}
      >
        <div
          className="color"
          style={{
            backgroundColor: selectedColors[key],
          }}
        />
        <p className="label">{typeof title === 'string' ? t(title) : title}</p>
      </div>
    );
  };

  const renderColorEditorSection = () => {
    return (
      <div className="editorSection">
        {renderColorSection(t('primary'), 'primaryColor', true)}
        {renderColorSection(t('primary_text'), 'contrastTextColor', true)}
        {renderColorSection(t('background'), 'backgroundColor', true)}
        {renderColorSection(t('background_text'), 'pageTextColor', false)}
      </div>
    );
  };

  const renderPreviewSection = () => {
    return <ThemeSectionPreview {...companyDetails} theme={selectedColors} />;
  };

  return (
    <div className="themesEditContainer">
      {renderPreviewSection()}
      {renderColorEditorSection()}
    </div>
  );
}
