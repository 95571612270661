import React, { useEffect, useState, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import GoogleAnalyticsData from 'qs-data/googleAnalytics';
import './style.scss';
import GoogleAnalyticsComponent from 'qs-components/GoogleAnalyticsComponent';
import CompanyData from 'qs-data/companyData';
import { getI18N } from '../../i18N';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';

const GoogleAnalytics = () => {
  const { t } = getI18N();
  const [companyId, setCompanyId] = useState('');
  const [googleAnalytics, setGoogleAnalytics] = useState({});
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const fetchGoogleAnalyticsRef = useRef();

  const analyticsFields = [{
      label: t('google_analytics_id'),
      field: 'googleAnalytics'
    },
    {
      label: t('google_tag_manager_id'),
      field: 'googleTagManagerId'
    },
    {
      label: t('google_analytics_measurement_id'),
      field: 'googleAnalyticsMeasurementId'
    },
  ];

  const updateGoogleAnalytics = useCallback((updates) => {
    setGoogleAnalytics((prevState) => ({
      ...prevState,
      ...updates,
    }));
  }, []);

  const fetchGoogleAnalytics = useCallback(async () => {
    setProgressBar(true);
    showErrorComponent(false);

    try {
      const [{ id }, googleAnalytics] = await Promise.all([
        CompanyData.getCompanyDetails(),
        GoogleAnalyticsData.getGoogleAnalyticsData(analyticsFields.map(({ field }) => field)),
      ]);
      setCompanyId(id);
      setGoogleAnalytics(googleAnalytics);
    } catch (ex) {
      showErrorComponent(true);
    }

    setProgressBar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCompanyId, setGoogleAnalytics, setProgressBar, showErrorComponent]);

  fetchGoogleAnalyticsRef.current = fetchGoogleAnalytics;

  useEffect(() => {
    if (typeof fetchGoogleAnalyticsRef.current !== 'function') {
      return;
    }
    fetchGoogleAnalyticsRef.current();
  }, []);

  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#222B36' : '#4DA47A',
        color: '#FFFFFF',
        title: t('google_analytics'),
        height: NAVBAR_HEIGHT,
        boxShadow: desktop ? 'none' : undefined,
        hideBack: desktop,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  return (
    <div
      className="googleAnalyticsPage"
      style={{ alignItems: progressBar || errorComponent ? 'center' : 'flex-start' }}
    >
      {progressBar ? (
        <div className="googleAnalyticsLoader">
          <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} />
        </div>
      ) : errorComponent ? (
        <CustomErrorComponent onRetryClick={fetchGoogleAnalytics} />
      ) : (
        analyticsFields.map(({ label, field }) => (
          <GoogleAnalyticsComponent
            key={field}
            field={field}
            label={t(label)}
            companyId={companyId}
            analyticsValue={googleAnalytics[field]}
            updateGoogleAnalytics={updateGoogleAnalytics}
          />
        ))
      )}
    </div>
  );
};

export default withRouter(GoogleAnalytics);
