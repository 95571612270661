import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import './style.css';
import Loader from 'qs-common/Loader';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import Toast from 'qs-common/Alerts/Toast';
import network from 'qs-data/network';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import axios from 'axios';
import GscDetailsRow from './detailsRow';
import GscSitemapRow from './sitemapRow';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { getI18N } from '../../i18N';
import { getToken } from 'qs-data/util';

const NAVBAR_HEIGHT = 55;

const GoogleSearchConsoleDetails = () => {
  const { t } = getI18N();
  const history = useHistory();
  const [, dispatch] = useAppContext();
  const [accessToken, setAccessToken] = useState(localStorage.getItem('gscToken') || null)
  const [domain] = useState(localStorage.getItem('selectedDomain') || null)

  const [loadingWebmasters, setLoadingWebmasters] = useState(true);
  const [loadingVerify, setLoadingVerify] = useState(true);
  const [loadingSitemap, setLoadingSitemap] = useState(true)

  const [siteInWebmaster, setSiteInWebmaster] = useState(false)
  const [siteVerified, setSiteVerified] = useState(false)
  const [sitemapAdded, setSitemapAdded] = useState(false)
  const [verificationTokenStep, setVerificationTokenStep] = useState(false)

  const [sitemapLastSubmitted, setSitemapLastSubmitted] = useState(null)

  const [googleSearchConsoleDetailsLoading, setGoogleSearchConsoleDetailsLoading] = useState(true);

  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';

  let webSourceData = {
    "site": {
      "type": 'SITE',
      "identifier": 'https://' + domain
    },
    "verificationMethod": 'META'
  }

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#222B36' : '#4DA47A',
        color: '#FFFFFF',
        title: t('google_search_console'),
        height: NAVBAR_HEIGHT,
        boxShadow: desktop ? 'none' : undefined,
        hideBack: desktop,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#212934');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  const onHandleSignOut = () => {
    localStorage.setItem('gscToken', null)
    setAccessToken(null)
    history.replace({
      pathname: `/google-search-console`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`
    });
  }

  useEffect(() => {
    if (accessToken) {
      return
    }
    history.replace({
      pathname: `${window.location.host}/google-search-console`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setGoogleSearchConsoleDetailsLoading(false);
    checkSiteInWebmasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (siteInWebmaster) {
      getVerificationToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteInWebmaster])

  useEffect(() => {
    if (verificationTokenStep) {
      verifySite()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationTokenStep])

  const checkSiteInWebmasters = () => {
    axios.get(`https://www.googleapis.com/webmasters/v3/sites/https%3A%2F%2F${domain}`, { params: { access_token: accessToken } })
      .then(() => {
        setSiteInWebmaster(true)
        setLoadingWebmasters(false)
      })
      .catch(() => {
        setSiteInWebmaster(false);
        addSiteToWebmasters()
      })
  }

  const addSiteToWebmasters = () => {
    if (!siteInWebmaster) {
      axios.put(`https://www.googleapis.com/webmasters/v3/sites/https%3A%2F%2F${domain}?access_token=${accessToken}`)
        .then(() => {
          setSiteInWebmaster(true)
          setLoadingWebmasters(false)

        })
        .catch(() => {
          setLoadingWebmasters(false)
        })
    }
  }

  const metaFilter = (token) => {
    let tmpSplit = token.split('"')
    return tmpSplit[3]
  }

  const getVerificationToken = async () => {
    let verificationToken = await network.getGoogleSiteVerification()
    if (siteInWebmaster) {
      axios.post(`https://www.googleapis.com/siteVerification/v1/token?access_token=${accessToken}`, webSourceData)
        .then((res) => {
          metaFilter(res.data.token)
          if (verificationToken.googleSiteVerification !== metaFilter(res.data.token))
            network.postGoogleSiteVerification({ googleSiteVerification: metaFilter(res.data.token) })
          setVerificationTokenStep(true)
          return
        })
        .catch(() => { setVerificationTokenStep(false) })
      return
    }
  }

  const verifySite = () => {
    setLoadingVerify(true)
    setLoadingSitemap(true)
    axios.post(`https://www.googleapis.com/siteVerification/v1/webResource?verificationMethod=META&access_token=${accessToken}`, webSourceData)
      .then(() => {
        setSiteVerified(true)
        setLoadingVerify(false)
        setLoadingSitemap(false)
        getSitemap()
      })
      .catch(() => {
        setSiteVerified(false)
        setLoadingVerify(false)
        setLoadingSitemap(false)
        setToastState({
          open: true,
          message: t('site_verification_failed'),
        });
      })
  }

  const filterDateTime = (data) => {
    let tmp = new Date(data)
    let time = tmp.toLocaleTimeString('en-us', { hour12: true })
    let date = tmp.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
    return date + ' at ' + time.toString()
  }

  const getSitemap = () => {
    setLoadingSitemap(true)
    let sitemapFeedUrl = 'https%3A%2F%2F' + domain + '%2Fsitemap.xml'
    axios.get(`https://www.googleapis.com/webmasters/v3/sites/https%3A%2F%2F${domain}/sitemaps/${sitemapFeedUrl}?access_token=${accessToken}`)
      .then((res) => {
        setLoadingSitemap(false)
        setSitemapLastSubmitted(filterDateTime(res.data.lastSubmitted))
      })
      .catch(() => {
        setSitemapLastSubmitted(null)
        setLoadingSitemap(false)
      })
  }

  const addSitemap = () => {
    let sitemapFeedUrl = 'https%3A%2F%2F' + domain + '%2Fsitemap.xml'
    axios.put(`https://www.googleapis.com/webmasters/v3/sites/https%3A%2F%2F${domain}/sitemaps/${sitemapFeedUrl}?access_token=${accessToken}`)
      .then(() => {
        setSitemapAdded(true)
        getSitemap()
      })
      .catch(() => {
      })
  }


  return (
    <div className="gscDetailsContainer">
      {googleSearchConsoleDetailsLoading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div>
          <div className='gscDomain'>
            <p>{t('currently_showing_details_for')} <a href={`https://${domain}`} target="__blank"> <b>{domain}</b></a></p>
          </div>
          <GscDetailsRow
            title={t('google_webmaster')}
            subtitle={t('checks_if_the_website_is_added_to_your_google_webmasters_account')}
            success={siteInWebmaster}
            loading={loadingWebmasters}
            retryFunc={checkSiteInWebmasters}
          />
          <GscDetailsRow
            title={t('google_site_verification')}
            subtitle={t('verifies_the_ownership_of_the_domain_using_the_meta_method')}
            success={siteVerified}
            loading={loadingVerify}
            retryFunc={verifySite}
          />
          <GscSitemapRow
            title={t('google_sitemap')}
            subtitle={t('checks_if_the_sitemap_is_updated_in_the_webmasters_account')}
            success={sitemapAdded}
            loading={loadingSitemap}
            siteVerified={siteVerified}
            func={addSitemap}
            lastSubmitted={sitemapLastSubmitted}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '24px', marginLeft: '15px' }} className="gscSignOutContainer">
            <div onClick={onHandleSignOut} style={{ color: '#5883EF', textDecoration: 'underline', cursor: 'pointer' }}>
              {t('sign_out_from_google_search_console')}
            </div>
          </div>
        </div>
      )}
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
};
export default withRouter(GoogleSearchConsoleDetails);
