import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Loader from "../../common/Loader";
import Toast from "../../common/Alerts/Toast";
import { Button } from "@material-ui/core";
import Icon from "../../common/Icon";
import './style.css'
import { getI18N } from "../../i18N";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        backgroundColor: "#3F4653",
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        color: "#fff",
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "coloumn",
        "&:last-child": {
            paddingBottom: 16,
        },
    },
    title: {
        fontSize: 14,
    },
    addSitemapBtn: {
        fontSize: 16,
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: '#4da47a',
        color: '#FFFFFF',
        '&:hover, &:focus': {
            backgroundColor: '#387256',
        },
        '&:active': {
            backgroundColor: 'none',
        },
    },
});


function GscSitemapRow({ title, subtitle, loading, siteVerified, func, lastSubmitted }) {
    const { t } = getI18N();
    const classes = useStyles();
    const [toastState, setToastState] = useState({
        open: false,
        message: "",
    });

    const failIcon = () => {
        return (<svg xmlns="http://www.w3.org/2000/svg" style={{ padding: '12px' }} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" />
            <path fill="#F54C4C" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
        </svg>)
    }

    const renderIcon = () => {
        return (siteVerified ? submitBtn() : failIcon())
    }
    const submitBtn = () => {
        return (
            <Button
                variant="contained"
                size="small"
                color="success"
                onClick={func}
                className={classes.addSitemapBtn}
            >
                {t('submit_now')}
            </Button>)
    }

    return (
        <>
            <Card
                className={`paymentRow ${classes.root}`}
                elevation={3}            >
                <CardContent className={classes.content}>
                    <div>
                        <Icon className="PrimaryIcon" />
                    </div>
                    <div className="paymentName">
                        {title}
                        <div className="SubTitleContainer">
                            <span>{subtitle}</span>
                        </div>
                    </div>
                    <div className="RowLoaderContainer">
                        {loading ? <div style={{ padding: '12px' }}>
                            <Loader small />
                        </div> : renderIcon()}
                    </div>
                </CardContent>
                {lastSubmitted ? <CardContent style={{ paddingTop: '0px' }}>
                    <p style={{ paddingTop: '12px', color: '#DFE1E6' }}>{t('sitemap_was_last_submitted_on_date', { date: lastSubmitted })}</p>
                </CardContent> : null}
            </Card>
            <Toast
                message={toastState.message}
                open={toastState.open}
                onClose={() => {
                    setToastState({
                        open: false,
                        message: "",
                    });
                }}
            />
        </>
    );
}

export default GscSitemapRow;
