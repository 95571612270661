import React from 'react';
import './style.scss';
import SearchIcon from 'qs-assets/icons/SearchIcon';
import OrderBarIcon from 'qs-assets/icons/OrderBarIcon';
import RightArrowIcon from 'qs-assets/icons/RightArrowIcon';
import Utility from 'qs-helpers/utility';
import { Avatar, makeStyles } from '@material-ui/core';
import { getI18N } from '../../../i18N';

const useStyle = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '14px',
  },
}));

export default function ({
  theme: { primaryColor, contrastTextColor, backgroundColor, pageTextColor },
  companyName,
  logo,
  currency = 'Rs.',
  currencyAlignment = 'left',
  hideOrderBar,
}) {
  const { t } = getI18N();
  const classes = useStyle();
  const renderHeader = () => {
    const name = companyName ? companyName : t('company_name')
    return (
      <div className="header">
        <div className="logoAndCompanyName">
          <div className="logo">
            <Avatar
              alt={`${name} logo`}
              src={logo || name}
              className={classes.small}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)', color: pageTextColor }}
            />
          </div>
          <p
            className="companyName"
            style={{
              color: pageTextColor,
            }}
          >
            {companyName}
          </p>
        </div>
        <div className="searchIcon">
          <SearchIcon color={primaryColor} width="16" height="16" />
        </div>
      </div>
    );
  };

  const renderTagsSection = () => {
    return (
      <div className="tagsContainer">
        <div
          className="tags"
          style={{
            backgroundColor: primaryColor,
            color: contrastTextColor,
          }}
        >
          ~~~
        </div>
        <div
          className="tags"
          style={{
            border: `solid 1px ${primaryColor}`,
            color: primaryColor,
            backgroundColor: 'transparent',
          }}
        >
          ~~~
        </div>
      </div>
    );
  };

  const renderProductContainer = () => {
    return (
      <div className="productContainer">
        {renderProduct(false)}
        {renderProduct(true)}
      </div>
    );
  };

  const renderProduct = (showDiscount) => {
    return (
      <div className="product">
        {showDiscount && (
          <div
            className="discountBadge"
            style={{
              backgroundColor: primaryColor,
            }}
          >
            ~~~
          </div>
        )}
        <div className="productImage" />
        <div className="priceAndDescription">
          <p className="description">~~~~~~</p>
          <p className="price">
            {Utility.getFormattedCurrency('~~~', currency, currencyAlignment)}
          </p>
        </div>
        <div
          className="addToCartButton"
          style={{
            backgroundColor: primaryColor,
            color: contrastTextColor,
          }}
        >
          {t('add')}
        </div>
      </div>
    );
  };

  const renderOrderBar = () => {
    if (hideOrderBar) {
      return null;
    }
    return (
      <div
        className="orderBar"
        style={{
          backgroundColor: primaryColor,
          color: contrastTextColor,
        }}
      >
        <div className="leftContainer">
          <div className="orderBarIcon">
            <OrderBarIcon color={contrastTextColor} width="16" height="16" />
          </div>
          <div>
            <p className="totalItemCount">~~~ {t('items')}</p>
            <p className="totalPrice">
              {Utility.getFormattedCurrency('~~~', currency, currencyAlignment)}
            </p>
          </div>
        </div>
        <div className="rightContainer">
          <div className="viewBasket">~~~</div>
          <div className="nextIcon">
            <RightArrowIcon color={contrastTextColor} width="24" height="16" />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="previewSection">
      <div className="previewContainer" style={{ backgroundColor }}>
        <div className="topSection">
          {renderHeader()}
          <p className="descriptionContainer" style={{ color: pageTextColor }}>
            ~~~~~
          </p>
          <p className="productCountContainer" style={{ color: pageTextColor }}>
            {t('count_items', { count: '#' })}
          </p>
          {renderTagsSection()}
          {renderProductContainer()}
        </div>
        {renderOrderBar()}
      </div>
    </div>
  );
}
