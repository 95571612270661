import React from 'react';

export default ({ color = '#00A674', size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android"
        transform="translate(-38.000000, -652.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="icons8-iOS-Filled-8186-50-000000" transform="translate(38.000000, 652.000000)">
          <path
            d="M17.9824602,0 C17.4674412,0 16.9657135,0.210991648 16.5736348,0.598086259 L16.560344,0.598086259 L15.9622577,1.18288171 L13.3040966,3.84104286 L0.425305784,3.84104286 C0.189393769,3.84104286 0,4.0320983 0,4.26634865 L0,19.5773569 C0,19.8116072 0.189393769,20.0026627 0.425305784,20.0026627 L15.736314,20.0026627 C15.972226,20.0026627 16.1616198,19.8116072 16.1616198,19.5773569 L16.1616198,6.6985661 L18.8463626,4.01382334 C18.8629758,3.99721004 18.8845737,3.97561216 18.9128166,3.94736931 C18.967641,3.89254484 19.037418,3.8227679 19.1121787,3.74800722 C19.2600388,3.60014711 19.4045764,3.44231869 19.4045764,3.44231869 C20.1903952,2.65649991 20.2020247,1.3822438 19.4178672,0.598086259 L19.4178672,0.584795453 C19.0241272,0.192716683 18.5008016,0 17.9824602,0 Z M17.9824602,0.850611568 C18.2798421,0.850611568 18.5755623,0.965244661 18.8064901,1.19617252 C19.2633617,1.65304407 19.2583775,2.37074758 18.8064901,2.83094162 L17.171721,1.19617252 C17.4009872,0.971890064 17.6884012,0.850611568 17.9824602,0.850611568 Z M16.560344,1.78096797 L18.2216947,3.44231869 L8.61244213,13.0382804 L6.96438221,11.3902205 L16.560344,1.78096797 Z M6.36629595,12.0015976 L8.00106506,13.6363667 L5.95428098,14.0483817 L6.36629595,12.0015976 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
