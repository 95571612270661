import React from 'react';

export default ({ color = "#00A674", size }) => {
  return (
    <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.8 8.48071H22.2V10.9038H25.8C25.906 10.9038 26.0078 10.9463 26.0828 11.0221C26.1578 11.0978 26.2 11.2005 26.2 11.3076V14.9423H28.6V11.3076C28.6 10.5579 28.305 9.83885 27.7798 9.3087C27.2547 8.77855 26.5426 8.48071 25.8 8.48071Z" fill={color}/>
      <path d="M26.2 29.0769C26.2 29.184 26.1578 29.2867 26.0828 29.3624C26.0078 29.4382 25.906 29.4807 25.8 29.4807H22.2V31.9038H25.8C26.5426 31.9038 27.2547 31.606 27.7798 31.0758C28.305 30.5457 28.6 29.8266 28.6 29.0769V25.4423H26.2V29.0769Z" fill={color}/>
      <path d="M8.20002 31.9038H11.8V29.4807H8.20002C8.09394 29.4807 7.9922 29.4382 7.91718 29.3624C7.84217 29.2867 7.80002 29.184 7.80002 29.0769V25.4423H5.40002V29.0769C5.40002 29.8266 5.69502 30.5457 6.22013 31.0758C6.74523 31.606 7.45742 31.9038 8.20002 31.9038Z" fill={color}/>
      <path d="M7.80002 11.3076C7.80002 11.2005 7.84217 11.0978 7.91718 11.0221C7.9922 10.9463 8.09394 10.9038 8.20002 10.9038H11.8V8.48071H8.20002C7.45742 8.48071 6.74523 8.77855 6.22013 9.3087C5.69502 9.83885 5.40002 10.5579 5.40002 11.3076V14.9423H7.80002V11.3076Z" fill={color}/>
      <path d="M22.2 14.9423H18.2H15.8H11.8V17.3653H15.8V26.25H18.2V17.3653H22.2V14.9423Z" fill={color}/>
    </svg>
  );
}