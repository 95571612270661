import React from 'react';
import './styles.scss'; // Import the CSS file for styles

const AnimatedEllipsis = ({ message = 'Working' }) => {
  return (
    <div className="loading-dots bottom-sheet-footer">
      {message}
      <span className="dots"></span>
    </div>
  );
};

export default AnimatedEllipsis;
