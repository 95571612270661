import network from './network';

let api = {};
let cache = {
  methods: [],
};

api.getPaymentMethods = async (refresh) => {
  if (!refresh && cache.methods.length) {
    return Promise.resolve(cache.methods);
  }
  try {
    const data = await network.fetchAvailablePaymentGateways();
    cache.methods = data;
    return Promise.resolve(cache.methods);
  } catch (error) {
    return Promise.reject(error);
  }
};

api.getPaymentMethodForId = (paymentMethodId) => {
  return new Promise((resolve) => {
    api.getPaymentMethods(false).then((methods) => {
      if (Array.isArray(methods)) {
        const method = methods.filter((method) => method.id === paymentMethodId)[0] || {};
        network.fetchPaymentGatewayData(paymentMethodId).then((resp) => {
          resolve({ ...method, ...resp });
        });
      }
    });
  });
};

api.getRazorpayNewCompanyTypes = () => {
  return {
    proprietorship: 'Proprietorship',
    partnership: 'Partnership',
    private_limited: 'Private Limited',
    public_limited: 'Public Limited',
    llp: 'Limited Liability Partnership',
    not_yet_registered: 'Not Yet Registered',
  };
};

api.getRazorpayCompanyTypes = () => {
  return {
    individual: 'Individual',
    partnership: 'Partnership',
    proprietorship: 'Proprietorship',
    public_limited: 'Public Limited',
    private_limited: 'Private Limited',
    llp: 'Limited Liability Partnership',
    ngo: 'Non governmental organization',
    trust: 'Trust',
    society: 'Society',
    not_yet_registered: 'Not Yet Registered',
    educational_institutes: 'Educational Institutes',
    other: 'Other',
  };
};

api.getRazorpayBusinessCategories = () => {
  return {
    financial_services: 'Financial Services',
    education: 'Education',
    healthcare: 'Healthcare',
    utilities: 'Utilities',
    government: 'Government',
    logistics: 'Logistics',
    tours_and_travel: 'Tours And Travel',
    transport: 'Transport',
    ecommerce: 'Ecommerce',
    food: 'Food',
    it_and_software: 'It And Software',
    gaming: 'Gaming',
    media_and_entertainment: 'Media And Entertainment',
    services: 'Services',
    housing: 'Housing',
    not_for_profit: 'Not For Profit',
    social: 'Social',
    others: 'Others',
  };
};

api.getRazorpayBusinessSubCategories = () => {
  return {
    financial_services: {
      mutual_fund: 'Mutual Fund',
      lending: 'Lending',
      cryptocurrency: 'Cryptocurrency',
      insurance: 'Insurance',
      nbfc: 'Nbfc',
      cooperatives: 'Cooperatives',
      pension_fund: 'Pension Fund',
      forex: 'Forex',
      securities: 'Securities',
      commodities: 'Commodities',
      accounting: 'Accounting',
      financial_advisor: 'Financial Advisor',
      crowdfunding: 'Crowdfunding',
      trading: 'Trading',
      betting: 'Betting',
      get_rich_schemes: 'Get Rich Schemes',
      moneysend_funding: 'Moneysend Funding',
      wire_transfers_and_money_orders: 'Wire Transfers And Money Orders',
      tax_preparation_services: 'Tax Preparation Services',
      tax_payments: 'Tax Payments',
      digital_goods: 'Digital Goods',
      atms: 'Atms',
    },
    education: {
      college: 'College',
      schools: 'Schools',
      university: 'University',
      professional_courses: 'Professional Courses',
      distance_learning: 'Distance Learning',
      day_care: 'Day Care',
      coaching: 'Coaching',
      elearning: 'Elearning',
      vocational_and_trade_schools: 'Vocational And Trade Schools',
      sporting_clubs: 'Sporting Clubs',
      dance_halls_studios_and_schools: 'Dance Halls Studios And Schools',
      correspondence_schools: 'Correspondence Schools',
    },
    healthcare: {
      pharmacy: 'Pharmacy',
      clinic: 'Clinic',
      hospital: 'Hospital',
      lab: 'Lab',
      dietician: 'Dietician',
      fitness: 'Fitness',
      health_coaching: 'Health Coaching',
      health_products: 'Health Products',
      drug_stores: 'Drug Stores',
      healthcare_marketplace: 'Healthcare Marketplace',
      osteopaths: 'Osteopaths',
      medical_equipment_and_supply_stores: 'Medical Equipment And Supply Stores',
      podiatrists_and_chiropodists: 'Podiatrists And Chiropodists',
      dentists_and_orthodontists: 'Dentists And Orthodontists',
      hardware_stores: 'Hardware Stores',
      ophthalmologists: 'Ophthalmologists',
      orthopedic_goods_stores: 'Orthopedic Goods Stores',
      testing_laboratories: 'Testing Laboratories',
      doctors: 'Doctors',
      health_practitioners_medical_services: 'Health Practitioners Medical Services',
    },
    utilities: {
      electricity: 'Electricity',
      gas: 'Gas',
      telecom: 'Telecom',
      water: 'Water',
      cable: 'Cable',
      broadband: 'Broadband',
      dth: 'Dth',
      internet_provider: 'Internet Provider',
      bill_and_recharge_aggregators: 'Bill And Recharge Aggregators',
    },
    government: {
      central: 'Central',
      state: 'State',
      intra_government_purchases: 'Intra Government Purchases',
      goverment_postal_services: 'Goverment Postal Services',
    },
    logistics: {
      freight: 'Freight',
      courier: 'Courier',
      warehousing: 'Warehousing',
      distribution: 'Distribution',
      end_to_end_logistics: 'End To End Logistics',
      courier_services: 'Courier Services',
    },
    tours_and_travel: {
      aviation: 'Aviation',
      accommodation: 'Accommodation',
      ota: 'Ota',
      travel_agency: 'Travel Agency',
      tourist_attractions_and_exhibits: 'Tourist Attractions And Exhibits',
      timeshares: 'Timeshares',
      aquariums_dolphinariums_and_seaquariums: 'Aquariums Dolphinariums And Seaquariums',
    },
    transport: {
      cab_hailing: 'Cab Hailing',
      bus: 'Bus',
      train_and_metro: 'Train And Metro',
      automobile_rentals: 'Automobile Rentals',
      cruise_lines: 'Cruise Lines',
      parking_lots_and_garages: 'Parking Lots And Garages',
      transportation: 'Transportation',
      bridge_and_road_tolls: 'Bridge And Road Tolls',
      freight_transport: 'Freight Transport',
      truck_and_utility_trailer_rentals: 'Truck And Utility Trailer Rentals',
    },
    ecommerce: {
      ecommerce_marketplace: 'Ecommerce Marketplace',
      agriculture: 'Agriculture',
      books: 'Books',
      electronics_and_furniture: 'Electronics And Furniture',
      coupons: 'Coupons',
      rental: 'Rental',
      fashion_and_lifestyle: 'Fashion And Lifestyle',
      gifting: 'Gifting',
      grocery: 'Grocery',
      baby_products: 'Baby Products',
      office_supplies: 'Office Supplies',
      wholesale: 'Wholesale',
      religious_products: 'Religious Products',
      pet_products: 'Pet Products',
      sports_products: 'Sports Products',
      arts_and_collectibles: 'Arts And Collectibles',
      sexual_wellness_products: 'Sexual Wellness Products',
      drop_shipping: 'Drop Shipping',
      crypto_machinery: 'Crypto Machinery',
      tobacco: 'Tobacco',
      weapons_and_ammunitions: 'Weapons And Ammunitions',
      stamps_and_coins_stores: 'Stamps And Coins Stores',
      office_equipment: 'Office Equipment',
      automobile_parts_and_equipements: 'Automobile Parts And Equipements',
      garden_supply_stores: 'Garden Supply Stores',
      household_appliance_stores: 'Household Appliance Stores',
      non_durable_goods: 'Non Durable Goods',
      pawn_shops: 'Pawn Shops',
      electrical_parts_and_equipment: 'Electrical Parts And Equipment',
      wig_and_toupee_shops: 'Wig And Toupee Shops',
      gift_novelty_and_souvenir_shops: 'Gift Novelty And Souvenir Shops',
      duty_free_stores: 'Duty Free Stores',
      office_and_commercial_furniture: 'Office And Commercial Furniture',
      dry_goods: 'Dry Goods',
      books_and_publications: 'Books And Publications',
      camera_and_photographic_stores: 'Camera And Photographic Stores',
      record_shops: 'Record Shops',
      meat_supply_stores: 'Meat Supply Stores',
      leather_goods_and_luggage: 'Leather Goods And Luggage',
      snowmobile_dealers: 'Snowmobile Dealers',
      men_and_boys_clothing_stores: 'Men And Boys Clothing Stores',
      paint_supply_stores: 'Paint Supply Stores',
      automotive_parts: 'Automotive Parts',
      jewellery_and_watch_stores: 'Jewellery And Watch Stores',
      auto_store_home_supply_stores: 'Auto Store Home Supply Stores',
      tent_stores: 'Tent Stores',
      shoe_stores_retail: 'Shoe Stores Retail',
      petroleum_and_petroleum_products: 'Petroleum And Petroleum Products',
      department_stores: 'Department Stores',
      automotive_tire_stores: 'Automotive Tire Stores',
      sport_apparel_stores: 'Sport Apparel Stores',
      variety_stores: 'Variety Stores',
      chemicals_and_allied_products: 'Chemicals And Allied Products',
      commercial_equipments: 'Commercial Equipments',
      fireplace_parts_and_accessories: 'Fireplace Parts And Accessories',
      family_clothing_stores: 'Family Clothing Stores',
      fabric_and_sewing_stores: 'Fabric And Sewing Stores',
      home_supply_warehouse: 'Home Supply Warehouse',
      art_supply_stores: 'Art Supply Stores',
      camper_recreational_and_utility_trailer_dealers:
        'Camper Recreational And Utility Trailer Dealers',
      clocks_and_silverware_stores: 'Clocks And Silverware Stores',
      discount_stores: 'Discount Stores',
      school_supplies_and_stationery: 'School Supplies And Stationery',
      second_hand_stores: 'Second Hand Stores',
      watch_and_jewellery_repair_stores: 'Watch And Jewellery Repair Stores',
      liquor_stores: 'Liquor Stores',
      boat_dealers: 'Boat Dealers',
      opticians_optical_goods_and_eyeglasse_stores: 'Opticians Optical Goods And Eyeglasse Stores',
      wholesale_footwear_stores: 'Wholesale Footwear Stores',
      cosmetic_stores: 'Cosmetic Stores',
      home_furnishing_stores: 'Home Furnishing Stores',
      antique_stores: 'Antique Stores',
      plumbing_and_heating_equipment: 'Plumbing And Heating Equipment',
      telecommunication_equipment_stores: 'Telecommunication Equipment Stores',
      women_clothing: 'Women Clothing',
      florists: 'Florists',
      computer_software_stores: 'Computer Software Stores',
      building_matrial_stores: 'Building Matrial Stores',
      candy_nut_confectionery_shops: 'Candy Nut Confectionery Shops',
      glass_and_wallpaper_stores: 'Glass And Wallpaper Stores',
      commercial_photography_and_graphic_design_services:
        'Commercial Photography And Graphic Design Services',
      video_game_supply_stores: 'Video Game Supply Stores',
      fuel_dealers: 'Fuel Dealers',
      drapery_and_window_coverings_stores: 'Drapery And Window Coverings Stores',
      hearing_aids_stores: 'Hearing Aids Stores',
      automotive_paint_shops: 'Automotive Paint Shops',
      durable_goods_stores: 'Durable Goods Stores',
      uniforms_and_commercial_clothing_stores: 'Uniforms And Commercial Clothing Stores',
      fur_shops: 'Fur Shops',
      industrial_supplies: 'Industrial Supplies',
      bicycle_stores: 'Bicycle Stores',
    },
    food: {
      online_food_ordering: 'Online Food Ordering',
      restaurant: 'Restaurant',
      food_court: 'Food Court',
      catering: 'Catering',
      alcohol: 'Alcohol',
      restaurant_search_and_booking: 'Restaurant Search And Booking',
      dairy_products: 'Dairy Products',
      bakeries: 'Bakeries',
    },
    it_and_software: {
      saas: 'Saas',
      paas: 'Paas',
      iaas: 'Iaas',
      consulting_and_outsourcing: 'Consulting And Outsourcing',
      web_development: 'Web Development',
      technical_support: 'Technical Support',
      data_processing: 'Data Processing',
    },
    gaming: {
      game_developer: 'Game Developer',
      esports: 'Esports',
      online_casino: 'Online Casino',
      fantasy_sports: 'Fantasy Sports',
      gaming_marketplace: 'Gaming Marketplace',
    },
    media_and_entertainment: {
      video_on_demand: 'Video On Demand',
      music_streaming: 'Music Streaming',
      multiplex: 'Multiplex',
      content_and_publishing: 'Content And Publishing',
      ticketing: 'Ticketing',
      news: 'News',
      video_game_arcades: 'Video Game Arcades',
      video_tape_production_and_distribution: 'Video Tape Production And Distribution',
      bowling_alleys: 'Bowling Alleys',
      billiard_and_pool_establishments: 'Billiard And Pool Establishments',
      amusement_parks_and_circuses: 'Amusement Parks And Circuses',
      ticket_agencies: 'Ticket Agencies',
    },
    services: {
      repair_and_cleaning: 'Repair And Cleaning',
      interior_design_and_architect: 'Interior Design And Architect',
      movers_and_packers: 'Movers And Packers',
      legal: 'Legal',
      event_planning: 'Event Planning',
      service_centre: 'Service Centre',
      consulting: 'Consulting',
      ad_and_marketing: 'Ad And Marketing',
      services_classifieds: 'Services Classifieds',
      multi_level_marketing: 'Multi Level Marketing',
      construction_services: 'Construction Services',
      architectural_services: 'Architectural Services',
      car_washes: 'Car Washes',
      motor_home_rentals: 'Motor Home Rentals',
      stenographic_and_secretarial_support_services:
        'Stenographic And Secretarial Support Services',
      chiropractors: 'Chiropractors',
      automotive_service_shops: 'Automotive Service Shops',
      shoe_repair_shops: 'Shoe Repair Shops',
      telecommunication_service: 'Telecommunication Service',
      fines: 'Fines',
      security_agencies: 'Security Agencies',
      tailors: 'Tailors',
      type_setting_and_engraving_services: 'Type Setting And Engraving Services',
      small_appliance_repair_shops: 'Small Appliance Repair Shops',
      photography_labs: 'Photography Labs',
      dry_cleaners: 'Dry Cleaners',
      massage_parlors: 'Massage Parlors',
      electronic_repair_shops: 'Electronic Repair Shops',
      cleaning_and_sanitation_services: 'Cleaning And Sanitation Services',
      nursing_care_facilities: 'Nursing Care Facilities',
      direct_marketing: 'Direct Marketing',
      lottery: 'Lottery',
      veterinary_services: 'Veterinary Services',
      affliated_auto_rental: 'Affliated Auto Rental',
      alimony_and_child_support: 'Alimony And Child Support',
      airport_flying_fields: 'Airport Flying Fields',
      golf_courses: 'Golf Courses',
      tire_retreading_and_repair_shops: 'Tire Retreading And Repair Shops',
      television_cable_services: 'Television Cable Services',
      recreational_and_sporting_camps: 'Recreational And Sporting Camps',
      barber_and_beauty_shops: 'Barber And Beauty Shops',
      agricultural_cooperatives: 'Agricultural Cooperatives',
      carpentry_contractors: 'Carpentry Contractors',
      wrecking_and_salvaging_services: 'Wrecking And Salvaging Services',
      automobile_towing_services: 'Automobile Towing Services',
      video_tape_rental_stores: 'Video Tape Rental Stores',
      miscellaneous_repair_shops: 'Miscellaneous Repair Shops',
      motor_homes_and_parts: 'Motor Homes And Parts',
      horse_or_dog_racing: 'Horse Or Dog Racing',
      laundry_services: 'Laundry Services',
      electrical_contractors: 'Electrical Contractors',
      debt_marriage_personal_counseling_service: 'Debt Marriage Personal Counseling Service',
      air_conditioning_and_refrigeration_repair_shops:
        'Air Conditioning And Refrigeration Repair Shops',
      credit_reporting_agencies: 'Credit Reporting Agencies',
      heating_and_plumbing_contractors: 'Heating And Plumbing Contractors',
      carpet_and_upholstery_cleaning_services: 'Carpet And Upholstery Cleaning Services',
      swimming_pools: 'Swimming Pools',
      roofing_and_metal_work_contractors: 'Roofing And Metal Work Contractors',
      internet_service_providers: 'Internet Service Providers',
      recreational_camps: 'Recreational Camps',
      masonry_contractors: 'Masonry Contractors',
    },
    housing: {
      developer: 'Developer',
      facility_management: 'Facility Management',
      rwa: 'Rwa',
      coworking: 'Coworking',
      realestate_classifieds: 'Realestate Classifieds',
      space_rental: 'Space Rental',
    },
    not_for_profit: {
      charity: 'Charity',
      educational: 'Educational',
      religious: 'Religious',
      personal: 'Personal',
    },
    social: {
      matchmaking: 'Matchmaking',
      social_network: 'Social Network',
      messaging: 'Messaging',
      professional_network: 'Professional Network',
      neighbourhood_network: 'Neighbourhood Network',
      political_organizations: 'Political Organizations',
      automobile_associations_and_clubs: 'Automobile Associations And Clubs',
      country_and_athletic_clubs: 'Country And Athletic Clubs',
      associations_and_membership: 'Associations And Membership',
    },
    others: {},
  };
};

api.statusMessages = () => {
  return {
    NEEDS_CLARIFICATION: `Some of the required information is missing/incorrect. Please login to the razorpay dashboard and provide the necessary information`,
    UNDER_REVIEW: `Your application is under review by Razorpay. This process generally takes 24-48 hours. You will be able to accept payments once the verification is successful`,
    SUSPENDED: `Your account has been suspended. Please login to the razorpay dashboard for further steps`,
  };
};

api.getRazorpayGatewayCharges = () => {
  return [
    {
      info: 'Debit Cards more than Rs. 2000',
      charges: '0.90%',
    },
    {
      info: 'Debit Cards less than Rs. 2000',
      charges: '0.40%',
    },
    {
      info: 'Rupay',
      charges: '0.10%',
    },
    {
      info: 'UPI more than Rs. 2000',
      charges: '0.10%',
    },
    {
      info: 'UPI less than Rs. 2000',
      charges: '0.10%',
    },
    {
      info: 'Credit Cards',
      charges: '1.75%',
    },
    {
      info: 'Net-banking (HDFC ICICI AXIS SBI KOTAK)',
      charges: '1.65%',
    },
    {
      info: 'Net-banking (All Others)',
      charges: '1.50%',
    },
    {
      info: 'Mobile Wallets',
      charges: '1.80%',
    },
    {
      info: 'Corporate Cards',
      charges: '2.70%',
    },
    {
      info: 'International Cards',
      charges: '2.85%',
    },
    {
      info: 'Diners / AMEX',
      charges: '2.70%',
    },
    {
      info: 'EMI & Cardless EMI (Zest Money, Early Salary, etc)',
      charges: '2.70%',
    },
  ];
};

// network.fetchPaymentGatewayData('RAZORPAY').then((resp) => {
//     console.log("SINGLE", resp);
// });

const updateCachedMethod = (id, isActive) => {
  if (Array.isArray(cache.methods) && cache.methods.length) {
    const cachedMethodIndex = cache.methods.findIndex(({ id: methodId }) => methodId === id);

    if (cachedMethodIndex !== -1) {
      cache.methods[cachedMethodIndex].isActive = isActive;
    }
  }
};

api.createOrUpdatePaymentAccount = async (id, data) => {
  try {
    const response = await network.addOrUpdatePaymentGateway(id, data);
    updateCachedMethod(id, true);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

api.disablePaymentGateway = async (id) => {
  try {
    const response = await network.disablePaymentGateway(id);
    updateCachedMethod(id, false);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

api.enableCOD = () => {
  return api.createOrUpdatePaymentAccount('CASH_ON_DELIVERY', {
    credentials: {},
  });
};
api.disabledCOD = () => {
  return api.disablePaymentGateway('CASH_ON_DELIVERY');
};

api.enablePCOD = (data) => {
  return api.createOrUpdatePaymentAccount('PARTIAL_COD', {
    credentials: data,
  });
};

api.disabledPCOD = () => {
  return api.disablePaymentGateway('PARTIAL_COD');
};

api.disableRZP = () => {
  return api.disablePaymentGateway('RAZORPAY');
};

api.enableRZP = (data) => {
  return api.createOrUpdatePaymentAccount('RAZORPAY', { credentials: data });
};

api.disablePayTM = () => {
  return api.disablePaymentGateway('PAYTM');
};

api.enablePayTM = (data) => {
  return api.createOrUpdatePaymentAccount('PAYTM', { credentials: data });
};

api.enablePhonePe = (data) => {
  return api.createOrUpdatePaymentAccount('PHONEPE', { credentials: data });
};

api.disablePhonePe = () => {
  return api.disablePaymentGateway('PHONEPE');
};


api.enableStripe = (data) => {
  return api.createOrUpdatePaymentAccount('STRIPE', { credentials: data });
};

api.disableStripe = () => {
  return api.disablePaymentGateway('STRIPE');
};

api.disablePayPal = () => {
  return api.disablePaymentGateway('PAYPAL');
};

api.enablePayPal = (data) => {
  return api.createOrUpdatePaymentAccount('PAYPAL', { credentials: data });
};

api.enableMercado = (data) => {
  return api.createOrUpdatePaymentAccount('MERCADO_PAGO', {
    credentials: data,
  });
};

api.disableMercado = () => {
  return api.disablePaymentGateway('MERCADO_PAGO');
};

export default api;

//
// {name, tncAccepted, businessName, businessType,
//     beneficiaryName, accountNumber, ifscCode,
//     accountType, mid, secret_key}
