import React, { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { CircularProgress, TextField, Button } from '@material-ui/core';
import GoogleAnalyticsData from 'qs-data/googleAnalytics';
import Toast from 'qs-common/Alerts/Toast';
import './style.scss';
import { getI18N } from '../../i18N';

export default function GoogleAnalyticsComponent({
  label,
  field,
  analyticsValue,
  companyId,
  updateGoogleAnalytics,
}) {
  const { t } = getI18N();
  const [value, setValue] = useState(analyticsValue || '');
  const [progressBar, setProgressBar] = useState(false);
  const [enableButton, setEnableButton] = useState(!isNil(analyticsValue));
  const [errorField, setErrorField] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    setValue(analyticsValue || '');
  }, [analyticsValue]);

  const onChange = (value) => {
    const newValue = (typeof value === 'string') ? value.trim() : '';

    setValue(newValue);
    const changed = newValue !== (analyticsValue || '');

    if (changed) {
      setEnableButton(true);
      return;
    }

    setEnableButton(false);
    if (!newValue) {
      setErrorField();
    }
  };

  const setGoogleAnalyticsCode = () => {
    if (!companyId || value === analyticsValue) {
      setToastState({
        open: true,
        message: t('error_while_updating_label', { label }),
      });
      return;
    }

    setProgressBar(true);
    GoogleAnalyticsData.setGoogleAnalyticsFieldValue(companyId, field, value)
      .then(() => {
        setToastState({
          open: true,
          message: t('label_updated_successfully', { label }),
        });
        updateGoogleAnalytics({ [field]: value });
        setEnableButton(false);
      })
      .catch(() => {
        setToastState({
          open: true,
          message: t('error_while_updating_label', { label }),
        });
      })
      .then(() => setProgressBar(false));
  };

  const enable = enableButton && value !== analyticsValue;

  return (
    <>
      <div className="googleIntegrationContainer">
        <p className="googleIntegrationLabel">{label}</p>
        <div className="googleAnalyticsIdContainer">
          <TextField
            className="googleAnalyticsInput"
            placeholder={label}
            value={value || ""}
            onChange={({ target: { value } }) => onChange(value)}
            defaultValue={analyticsValue}
            error={errorField}
            helperText={errorField ? t('required_field') : ''}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (enableButton) {
                  setGoogleAnalyticsCode();
                }
              }
            }}
          />
          {progressBar ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <Button
              className={`saveButton ${!enable ? 'disable' : ''}`}
              onClick={enable ? setGoogleAnalyticsCode : null}
              disabled={!enable}
            >
              <span>{t('save')}</span>
            </Button>
          )}
        </div>
      </div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
}
