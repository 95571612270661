import CacheRequest from '../data/cacheRequest';
import CacheKeys from '../data/cacheKeys';
import ApiWrapper from './apiWrapper';

const apis = {
  geoConfig: '/config/ip-geo',
};

function getToken() {
  let token = CacheRequest.getCacheForKey(CacheKeys.token);
  if (!token) {
    try {
      let ar = window.location.href.split('?');
      token = ar[ar.length - 1]
        .split('&')
        .filter((token) => token.indexOf('token') !== -1)[0]
        .split('=')[1];
      saveToken(token);
    } catch (e) {
      // TODO : throw error to error library
      token = '';
    }
  }
  return token;
}

function saveToken(token) {
  CacheRequest.setCacheForKey(CacheKeys.token, token);
}

function validateEmail(email) {
  let patt =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return email.match(patt);
}

const getGeoData = () => {
  return ApiWrapper.getDataPromise(apis.geoConfig, null, CacheKeys.geoConfig);
};

const setCachedReferralPageView = (referralPageViewType) => {
  CacheRequest.setCacheForKey(CacheKeys.referralPageView, referralPageViewType);
};

const getCachedReferralPageView = () => CacheRequest.getCacheForKey(CacheKeys.referralPageView);

/**
 * Formats the company details to props value for theme preview section
 * @returns
 */
function prepareCompanyDetailsForThemes({ name, logoUrl, currencySymbol, currencyAlignment }) {
  const formatedCompanyDetails = {};
  if (name) {
    formatedCompanyDetails.companyName = name;
  }
  if (logoUrl) {
    formatedCompanyDetails.logo = logoUrl;
  }
  if (currencySymbol) {
    formatedCompanyDetails.currency = currencySymbol;
  }
  if (currencyAlignment) {
    formatedCompanyDetails.currencyAlignment = currencyAlignment;
  }
  return formatedCompanyDetails;
}

const createQueryParams = (queryParamsData) => {
  const queryParams = queryParamsData.reduce((cumulativeArray, { key, value }) => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value = JSON.stringify(value);
      }
      cumulativeArray.push(`${key}=${encodeURIComponent(value)}`);
    }
    return cumulativeArray;
  }, []);

  if (queryParams.length > 0) {
    return `?${queryParams.join('&')}`;
  }
  return '';
};

export {
  getToken,
  saveToken,
  validateEmail,
  prepareCompanyDetailsForThemes,
  createQueryParams,
  getGeoData,
  setCachedReferralPageView,
  getCachedReferralPageView,
};
