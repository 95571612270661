import React from 'react';
import { ReactComponent as Play } from '../../../assets/images/play.svg';
import { getI18N } from '../../../i18N';
import Utility from 'qs-helpers/utility';

function YouTubeRow({ videoId, title }) {
  const { t } = getI18N();
  const openYouTube = () => {
    Utility.openPopup(`https://www.youtube.com/watch?v=${videoId}`);
  };

  return (
    <>
      <div className="YouTubeRowContainer" onClick={openYouTube}>
        <div className="PlayIcon">
          <Play />
        </div>
        <div className="RightContainer">
          <div
            className="Title"
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            {title || t('how_to_connect_paytm_to_quicksell')}
          </div>
          <div className="SubTitle" style={{ color: "#ffc1be" }}>
            {t('tap_to_see_video')}
          </div>
        </div>
      </div>
    </>
  );
}

export default YouTubeRow;
