import promiseWithTimeout from 'qs-helpers/promise-with-timeout';
import { useEffect, useState } from 'react';

const usePhoneUtil = () => {
  const [phoneUtil, setPhoneUtil] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (phoneUtil) {
      return;
    }
    try {
      setLoading(true);
      const loadPhoneUtil = async () => {
        // Waiting for only 5 seconds so that it doesn't block the UI
        const phoneUtil = await promiseWithTimeout(import('google-libphonenumber'), 5000);
        setPhoneUtil(phoneUtil);
      };
      loadPhoneUtil();
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { phoneUtil, loading };
};

export default usePhoneUtil;
