import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import payments from '../../../../data/payments';
import CustomTextField from '../../../../common/CustomTextField';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getI18N } from '../../../../i18N';
import { RAZORPAY_REQUIRED_DOCUMENTS } from '../constants';

const useStyles = makeStyles({
  title: {
    margin: '24px 24px 0px',
    color: '#ffffff',
    fontSize: '17px',
    fontWeight: '700',
  },
  checkboxSection: {
    margin: '24px 24px 0px',
    color: 'white',
    width: '100%',
  },
});

export default function StepTwo({
  classes,
  state,
  isActive,
  credentialsExist,
  errors,
  onChange,
  updateState,
  showOldForm,
  sameCustomerBusinessName,
  updateCustomerBusinessName,
  requiredDocumentsState,
  setRequiredDocumentsState,
}) {
  const stepTwoClasses = useStyles();
  const { t } = getI18N();

  const onBusinessTypeUpdate = (event) => {
    setRequiredDocumentsState(RAZORPAY_REQUIRED_DOCUMENTS[event.target.value]);
    onChange(event);
  };

  const onBusinessCategoryUpdate = (event) => {
    onChange(event);
    updateState({
      name: 'subCategory',
      value: event.target.value === 'others' ? 'others' : '',
    });
  };

  const razorpayCompanyTypes = showOldForm
    ? payments.getRazorpayCompanyTypes()
    : payments.getRazorpayNewCompanyTypes();
  const razorpayBusinessCategories = payments.getRazorpayBusinessCategories();

  return (
    <>
      {!showOldForm && (
        <div className={stepTwoClasses.title}>
          {t('business_profile')}
        </div>
      )}
      <CustomTextField
        fullWidth
        label={showOldForm ? t('company_name') : t('business_name')}
        value={state.businessName}
        name="businessName"
        onChange={onChange}
        error={!!errors['businessName']}
        helperText={errors['businessName']}
        InputLabelProps={{ shrink: true }}
        disabled={isActive || credentialsExist}
      />
      {!showOldForm && (
        <>
          <div className={stepTwoClasses.checkboxSection}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sameCustomerBusinessName}
                  onChange={() => updateCustomerBusinessName()}
                  disabled={isActive || credentialsExist}
                />
              }
              label={t('keep_customer_business_name_same_as_business_name')}
            />
          </div>
          {!sameCustomerBusinessName && (
            <CustomTextField
              fullWidth
              label={t('customer_business_name')}
              value={state.customerBusinessName}
              name="customerBusinessName"
              onChange={onChange}
              error={!!errors['customerBusinessName']}
              helperText={errors['customerBusinessName']}
              InputLabelProps={{ shrink: true }}
              disabled={isActive || credentialsExist}
            />
          )}
        </>
      )}
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!errors['businessType']}
        helperText={errors['businessType']}
        disabled={isActive || credentialsExist}
      >
        <InputLabel shrink id="demo-simple-select-label" className={classes.inputLabel}>
          {t('business_type')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={onBusinessTypeUpdate}
          name="businessType"
          value={state.businessType}
        >
          {Object.keys(razorpayCompanyTypes).map((ct, i) => {
            return (
              <MenuItem key={i} value={ct}>
                {razorpayCompanyTypes[ct]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {!showOldForm && (
        <>
          {requiredDocumentsState.businessPanRequired && (
            <CustomTextField
              fullWidth
              label={t('business_pan')}
              name="businessPan"
              value={state.businessPan}
              onChange={onChange}
              error={!!errors['businessPan']}
              helperText={errors['businessPan']}
              InputLabelProps={{ shrink: true }}
              disabled={isActive || credentialsExist}
            />
          )}
          {(requiredDocumentsState.cinRequired || requiredDocumentsState.llpinRequired) && (
            <CustomTextField
              fullWidth
              label={requiredDocumentsState.cinRequired ? t('CIN') : t('LLPIN')}
              name="cin"
              value={state.cin}
              onChange={onChange}
              error={!!errors['cin']}
              helperText={errors['cin']}
              InputLabelProps={{ shrink: true }}
              disabled={isActive || credentialsExist}
            />
          )}
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!errors['category']}
            helperText={errors['category']}
            disabled={isActive || credentialsExist}
          >
            <InputLabel shrink id="demo-simple-select-label" className={classes.inputLabel}>
              {t('business_category')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={onBusinessCategoryUpdate}
              name="category"
              value={state.category}
            >
              {Object.keys(razorpayBusinessCategories).map((ct, i) => {
                return (
                  <MenuItem key={i} value={ct}>
                    {razorpayBusinessCategories[ct]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {state.category === 'others' ? null : (
            <FormControl
              fullWidth
              className={classes.formControl}
              error={!!errors['subCategory']}
              helperText={errors['subCategory']}
              disabled={isActive || credentialsExist}
            >
              <InputLabel shrink id="demo-simple-select-label" className={classes.inputLabel}>
                {t('business_sub_category')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={onChange}
                name="subCategory"
                value={state.subCategory}
              >
                {payments.getRazorpayBusinessSubCategories()[state.category] &&
                  Object.keys(payments.getRazorpayBusinessSubCategories()[state.category]).map(
                    (ct, i) => {
                      return (
                        <MenuItem key={i} value={ct}>
                          {payments.getRazorpayBusinessSubCategories()[state.category][ct]}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
            </FormControl>
          )}
          <CustomTextField
            fullWidth
            label={t('street_one')}
            name="street1"
            value={state.street1}
            onChange={(event) => {
              if (event.target.value.length > 99) {
                return;
              }
              onChange(event);
            }}
            error={!!errors['street1']}
            helperText={errors['street1']}
            InputLabelProps={{ shrink: true }}
            disabled={isActive || credentialsExist}
          />
          <CustomTextField
            fullWidth
            label={t('street_two')}
            name="street2"
            value={state.street2}
            onChange={(event) => {
              if (event.target.value.length > 99) {
                return;
              }
              onChange(event);
            }}
            error={!!errors['street2']}
            helperText={errors['street2']}
            InputLabelProps={{ shrink: true }}
            disabled={isActive || credentialsExist}
          />
          <CustomTextField
            fullWidth
            label={t('city')}
            name="city"
            value={state.city}
            onChange={onChange}
            error={!!errors['city']}
            helperText={errors['city']}
            InputLabelProps={{ shrink: true }}
            disabled={isActive || credentialsExist}
          />
          <CustomTextField
            fullWidth
            label={t('state')}
            name="state"
            value={state.state}
            onChange={onChange}
            error={!!errors['state']}
            helperText={errors['state']}
            InputLabelProps={{ shrink: true }}
            disabled={isActive || credentialsExist}
          />
          <CustomTextField
            fullWidth
            label={t('pincode')}
            name="pincode"
            value={state.pincode}
            onChange={onChange}
            error={!!errors['pincode']}
            helperText={errors['pincode']}
            InputLabelProps={{ shrink: true }}
            disabled={isActive || credentialsExist}
          />
          <CustomTextField
            fullWidth
            label={t('country')}
            name="country"
            value="India"
            onChange={onChange}
            error={!!errors['country']}
            helperText={errors['country']}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        </>
      )}
    </>
  );
}
