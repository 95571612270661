import {withStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
    root: {
        '& .MuiFormLabel-root' : {
            color : '#fff'
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#5F6672',
        }
    }
})(TextField);

export default CustomTextField;