import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import './style.css';
import { ReactComponent as WebinarListIcon } from 'qs-assets/images/webinar_list_icon.svg';
import { ReactComponent as RightArrowIcon } from 'qs-assets/images/list_right_arrow.svg';
import Loader from '../../common/Loader';
import dateFormat from 'dateformat';
import ListCountDownTime from './ListCountDownTime';
import network from '../../data/network';
import CustomErrorComponent from '../../common/CustomErrorComponent';
import { useAppContext } from '../../common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { getI18N } from '../../i18N';

function WebinarList() {
  const { t } = getI18N();
  const [errorComponent, showErrorComponent] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const { url } = useRouteMatch();

  useEffect(() => {
    loadWebinarList();
    if (desktop) {
      dispatch({
        type: 'HIDE_NAVBAR',
      });
    } else {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          background: desktop ? 'linear-gradient(360deg, #0f101a 0%, #0F101A 100%)' : '#4DA47A',
          color: '#FFFFFF',
          title: '',
          boxShadow: 'none',
        },
      });
      setNavigationBarColor('#0f101a');
      setStatusBarColor('#4DA47A');
    }
  }, [dispatch, desktop]);

  const loadWebinarList = () => {
    network
      .getUpcomingWebinars()
      .then((response) => {
        setData(response);
        setLoading(false);
        showErrorComponent(false);
      })
      .catch(() => {
        showErrorComponent(true);
        setLoading(false);
      });
  };

  const retryLoadingWebinarList = () => {
    setLoading(true);
    loadWebinarList();
  };

  if (loading) {
    return (
      <div className="LoaderContainer">
        <Loader />
      </div>
    );
  }

  if (errorComponent) {
    return (
      <div className="WebinarListContainer emptyWebinarList">
        <CustomErrorComponent style={{ margin: 'auto' }} onRetryClick={retryLoadingWebinarList} />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="WebinarListContainer emptyWebinarList">
        {t('we_will_schedule_a_webinar_soon')}
      </div>
    );
  }

  return (
    <div className="WebinarListContainer">
      <WebinarListIcon className="webinarListIcon" />
      <div className="webinarListTitle">
        {t('quicksell_live_training')}
      </div>
      <div className="webinarListContent">
        {data.map((webinar) => {
          const { webinarId, webinarTitle, webinarTime } = webinar;
          return (
            <Link key={webinarId} to={`${url}/${webinarId}`}>
              <div className="singleWebinarItem">
                <div className="singleWebinarItemConent">
                  <div className="webinarItemTitle">{webinarTitle}</div>
                  <div className="webinarItemTiming">
                    {webinarTime && dateFormat(new Date(webinarTime), 'dddd, dS mmmm, HH:MMtt')}
                  </div>
                  <ListCountDownTime webinarTime={webinarTime} />
                </div>
                <RightArrowIcon className="webinarListRightIcon" />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default WebinarList;
