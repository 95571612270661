import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Dialog } from '@material-ui/core';
import { Share, FileCopyOutlined } from '@material-ui/icons';

import { useAppContext } from 'qs-common/Contexts/AppContext';
import Utility, { PUBLIC_KEY } from 'qs-helpers/utility';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import Toast from 'qs-common/Alerts/Toast';
import network from 'qs-data/network';
import {
  isFeatureAllowed,
  setNavigationBarColor,
  setStatusBarColor,
  shareReferral,
} from '../../os';

import { ReactComponent as ErrorIcon } from 'qs-assets/images/icons8-error.svg';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { getI18N } from '../../i18N';
import useStyles from './styles';
import './style.scss';

const paperStyle = { minWidth: '25%', backgroundColor: '#161C23', color: '#ffffff' };

const ManageApiKey = () => {
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();

  const [featureAllowed, setfeatureAllowed] = useState(true);
  const [apiKey, setApiKey] = useState(null);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [generatingApiKey, setGeneratingApiKey] = useState(false);
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });

  const { t } = getI18N();

  const componentStyles = useStyles();

  useEffect(() => {
    checkIsFeatureAllowedAndFetchApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#4DA47A',
        color: '#ffffff',
        title: t('manage_your_api_key'),
        boxShadow: 'none',
        borderBottom: '1px solid #242C36',
        hideBack: desktop,
      },
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const checkIsFeatureAllowedAndFetchApiKey = useCallback(async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      const featureAllowed = await isFeatureAllowed(PUBLIC_KEY);
      if (!featureAllowed) {
        setfeatureAllowed(false);
        setProgressBar(false);
        return;
      }
      const { data } = await network.getMerchantApiKey();
      if (data && Array.isArray(data) && data.length !== 0 && data[0].apiKey) {
        setApiKey(data[0].apiKey);
      }
    } catch (error) {
      showErrorComponent(true);
    }
    setProgressBar(false);
  }, []);

  const generateNewApiKey = async (event) => {
    event.preventDefault();
    if (apiKey) {
      setShowDialog(true);
      return;
    }
    setGeneratingApiKey(true);
    try {
      const { data } = await network.generateMerchantApiKey();
      if (data && Array.isArray(data) && data.length !== 0 && data[0].apiKey) {
        setApiKey(data[0].apiKey);
        setToastState({
          open: true,
          message: t('api_key_generated'),
        });
      }
    } catch (error) {
      setToastState({
        open: true,
        message: t('failed_to_generate_new_api_key'),
      });
    }
    setGeneratingApiKey(false);
  };

  const regenerateApiKey = async (event) => {
    event.preventDefault();
    setGeneratingApiKey(true);
    setShowDialog(false);
    try {
      const result = await network.regenerateMerchantApiKey();
      if (result && result.data && result.data.apiKey) {
        setApiKey(result.data.apiKey);
        setToastState({
          open: true,
          message: t('new_api_key_generated'),
        });
      }
    } catch (error) {
      setToastState({
        open: true,
        message: t('failed_to_generate_new_api_key'),
      });
    }
    setGeneratingApiKey(false);
  };

  const onShareClick = (event) => {
    event.preventDefault();
    shareReferral('Api key: ' + apiKey);
  };

  const onCopyClick = (event) => {
    event.preventDefault();
    Utility.copyToClipboard(apiKey);
    setToastState({
      open: true,
      message: t('api_key_copied_to_clipboard'),
    });
  };

  const closeShowDialog = (event) => {
    event.preventDefault();
    setShowDialog(false);
  };

  const getManageApiContainerStyle = () => {
    if (!featureAllowed || progressBar || errorComponent) {
      return { alignItems: 'center' };
    }

    return { alignItems: 'flex-start' };
  };

  const renderUserActionsForApiKey = () => {
    if (!apiKey) {
      return null;
    }
    return (
      <div className="apiKeysUserActions">
        <Button
          startIcon={<FileCopyOutlined />}
          classes={{ root: componentStyles.actionButton }}
          variant="outlined"
          onClick={onCopyClick}
        >
          {t('copy_key')}
        </Button>
        <Button
          startIcon={<Share />}
          classes={{ root: componentStyles.actionButton }}
          variant="outlined"
          onClick={onShareClick}
        >
          {t('share_key')}
        </Button>
      </div>
    );
  };

  const renderRegenerateApiKeyConfirmationDialog = () => (
    <Dialog
      open={showDialog}
      PaperProps={{
        style: paperStyle,
        component: 'form',
        onSubmit: regenerateApiKey,
        noValidate: true,
      }}
      onClose={closeShowDialog}
      aria-labelledby="form-dialog-title"
    >
      <div className="generateKeyConfirmModal">
        <div className="generateKeyTitle">{t('generate_new_key')}</div>
        <div className="generateKeyWarningDescription">
          <div className="descriptionTitle descriptionText">
            <ErrorIcon className="errorIcon" fill="#D21F1F" width="15" height="15" />
            {t('warning')}
          </div>
          <div className="descriptionRemark descriptionText">
            {t('your_previous_integrations_might_break_if_you_generate_a_new_key')}
          </div>
          <div className="description descriptionText">
            {t('are_you_sure_you_want_to_generate_a_new_key')}
          </div>
        </div>
        <div className="generateKeyModalUserActions">
          <Button
            className="cancelAction"
            type="reset"
            onClick={closeShowDialog}
          >{t('cancel')}</Button>
          <Button className="submitAction" type="submit">{t('generate_new_key')}</Button>
        </div>
      </div>
    </Dialog>
  );

  const renderContent = () => {
    if (progressBar) {
      return <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} />;
    }
    if (errorComponent) {
      return <CustomErrorComponent onRetryClick={checkIsFeatureAllowedAndFetchApiKey} />;
    }
    if (!featureAllowed) {
      return <p className="featureNotAvailable">{t('feature_is_unavailable_for_current_plan')}</p>;
    }
    return (
      <div className="manageApiContainer">
        <div className="manageApiDescriptionContainer">
          <ErrorIcon className="errorIcon" fill="#FFC107" width="20" height="20" />
          <p className="manageApiDescription">
            {t('your_api_key_provide_access_to_your_entire_account_please_be_careful_when_sharing_with_3rd_parties')}
          </p>
        </div>
        <div className="apiKeyDetails">
          <p className="apiKeyTitle">{t('api_key')}</p>
          <p className={`apiKeyValue ${apiKey ? 'highlight' : ''}`}>
            {apiKey ? apiKey : t('tap_on_the_button_below_to_generate_a_key')}
          </p>
        </div>
        {renderUserActionsForApiKey()}
        <Button classes={{ root: componentStyles.generateKeyButton }} onClick={generateNewApiKey}>
          {generatingApiKey ? (
            <CircularProgress size={18} color="secondary" style={{ margin: '4px' }} />
          ) : (
            t('generate_new_api_key')
          )}
        </Button>
      </div>
    );
  };

  return (
    <div className="manageApiKeyPage" style={getManageApiContainerStyle()}>
      {renderContent()}
      {renderRegenerateApiKeyConfirmationDialog()}
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({ open: false, message: '' });
        }}
      />
    </div>
  );
};

export default withRouter(ManageApiKey);
