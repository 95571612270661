import CacheRequest from './cacheRequest';
import cacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';
import network from './network';
import { createQueryParams } from './util';
import { trackAnalytics } from '../os';
import { handleError as reportError } from '../common/error';

const getCouponsData = (afterCode = null, afterDate = null) => {
  const apiEndpoint = '/v2/company/coupon';
  if (afterCode && afterDate) {
    return network
      .getData(
        apiEndpoint,
        createQueryParams([
          {
            key: 'afterCode',
            value: afterCode,
          },
          {
            key: 'afterDate',
            value: afterDate,
          },
        ])
      )
      .then(({ data }) => data);
  }

  return ApiWrapper.getDataPromise(apiEndpoint, '', cacheKeys.companyCoupon);
};

const getCouponData = (code) => ApiWrapper.getDataPromise(`/v1/company/coupon/${code}`);

const createCouponApi = (couponData) =>
  ApiWrapper.postDataPromise(`/v3/company/coupon`, couponData);

const couponUpdate = (code, updates) =>
  ApiWrapper.patchDataPromise(`/v3/company/coupon/${code}`, updates);

const couponDelete = (code) => ApiWrapper.deleteDataPromise(`/v2/company/coupon`, { code });

const attachCompanyCouponListener = (listener) => {
  CacheRequest.attachListener(cacheKeys.companyCoupon, listener);
};
const attachCompanyListener = (listener) => {
  CacheRequest.attachListener(cacheKeys.companyDetails, listener);
};

const removeCompanyCouponListener = (listener) => {
  CacheRequest.removeListener(cacheKeys.companyCoupon, listener);
};
const removeCompanyListener = (listener) => {
  CacheRequest.removeListener(cacheKeys.companyDetails, listener);
};

const getCouponFromCache = () => CacheRequest.getCacheForKey(cacheKeys.companyCoupon);

const setCouponInCache = (couponData) => {
  const existingData = getCouponFromCache();
  CacheRequest.setCacheForKey(cacheKeys.companyCoupon, {
    ...existingData,
    ...couponData,
  });
};

const getCouponMetaData = (couponCode) => {
  const { coupons } = getCouponFromCache() || {};
  return coupons && coupons.find((data) => data.code === couponCode);
};

const getCompanyCoupons = () =>
  getCouponsData().catch((error) => {
    CacheRequest.setCacheForKey(cacheKeys.companyCoupon, null, { error });
  });

const loadMoreCouponsIfPossible = async () => {
  const key = cacheKeys.companyCoupon;
  const { coupons, moreCouponsAvailable } = getCouponFromCache() || {};
  if (!moreCouponsAvailable || !Array.isArray(coupons) || coupons.length === 0) {
    return;
  }

  const { code, dateCreated } = coupons[coupons.length - 1];
  try {
    const { coupons: nextPageCoupons, moreCouponsAvailable: updatedMoreCoupons } =
      await getCouponsData(code, dateCreated);
    const newCouponData = {
      coupons: [...coupons, ...nextPageCoupons],
      moreCouponsAvailable: updatedMoreCoupons,
    };
    CacheRequest.setCacheForKey(key, newCouponData);
  } catch (error) {
    CacheRequest.setCacheForKey(key, null, { error });
  }
};

const clearCachedData = () => CacheRequest.deleteCacheForKeys([cacheKeys.companyCoupon]);

const deleteCoupon = async ({ code, name }) => {
  try {
    await couponDelete(code);
    trackAnalytics({
      eventName: 'coupon_deleted',
      props: { name, code },
    });
    const { coupons } = getCouponFromCache() || {};
    if (Array.isArray(coupons)) {
      const filteredCoupons = coupons.filter(
        ({ code: currentCouponCode }) => currentCouponCode !== code
      );
      setCouponInCache({ coupons: filteredCoupons });
    }
  } catch (err) {
    reportError(err);
    throw err;
  }
};

const createCoupon = async (couponData) => {
  try {
    const { coupon: newCouponData } = await createCouponApi(couponData);
    trackAnalytics({
      eventName: 'coupon_created',
      props: { ...couponData },
    });
    let { coupons } = getCouponFromCache() || {};
    if (Array.isArray(coupons)) {
      newCouponData.createdSuccessfully = true;
      setCouponInCache({ coupons: [newCouponData].concat(coupons) });
    }
  } catch (error) {
    reportError(error);
    throw error;
  }
};

const updateCoupon = async (code, updates) => {
  trackAnalytics({
    eventName: 'coupon_changed',
    props: { ...updates, code },
  });
  await couponUpdate(code, updates);
  const { coupons } = getCouponFromCache() || {};
  if (Array.isArray(coupons)) {
    const updatedCouponsList = coupons.map((couponSavedData) => {
      const { code: savedCode } = couponSavedData;
      if (savedCode !== code) {
        return couponSavedData;
      }
      return {
        ...couponSavedData,
        ...updates,
      };
    });
    setCouponInCache({ coupons: updatedCouponsList });
  }
};

export {
  attachCompanyCouponListener,
  attachCompanyListener,
  removeCompanyCouponListener,
  removeCompanyListener,
  getCompanyCoupons,
  getCouponFromCache,
  clearCachedData,
  loadMoreCouponsIfPossible,
  getCouponsData,
  createCoupon,
  getCouponData,
  deleteCoupon,
  updateCoupon,
  getCouponMetaData,
};
