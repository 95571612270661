import React, { useEffect, useState } from 'react';
import './style.css';
import CustomTextField from '../../../common/CustomTextField';
import payments from '../../../data/payments';
import useStyles from '../../../common/CustomStyles';
import Loader from '../../../common/Loader';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ErrorIcon } from 'qs-assets/images/icons8-error.svg';
import { InputAdornment } from '@material-ui/core';
import Toast from '../../../common/Alerts/Toast';
import CompanyData from 'qs-data/companyData';
import { getI18N } from '../../../i18N';

function PartialCOD({ gatewayData, paymentModes }) {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(gatewayData.isActive || false);
  const [credentials, setCredentials] = useState(
    gatewayData && gatewayData.credentials ? gatewayData.credentials : {}
  );
  const [companyDetails, setCompanyDetails] = useState({});

  const [inputText, setInputText] = useState(credentials.partialCodAmount || '');
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });

  const { t } = getI18N();

  const currencySymbol = companyDetails.currencySymbol || '';
  const currencyAlignment = companyDetails.currencyAlignment || 'left';

  const onSetupPaymentGatewayClick = () => {
    history.goBack();
  };

  useEffect(() => {
    CompanyData.getCompanyDetails()
      .then((companyDetailsResponse) => setCompanyDetails({ ...companyDetailsResponse }))
      .catch(() => {
        setToastState({ open: true, message: t('error_while_fetching_company_details') });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPaymentModes = paymentModes
    .filter(
      (item) =>
        item.isActive === true && item.id !== 'CASH_ON_DELIVERY' && item.id !== 'PARTIAL_COD'
    )
    .map((item) => t(item.id.toLowerCase()))
    .join(', ');
  const isPaymentModeSelected = gatewayData.gatewayConfig.paymentGatewayConnected;
  const onSubmit = () => {
    setLoading(true);
    payments
      .enablePCOD(credentials)
      .then(() => {
        setLoading(false);
        setIsActive(true);
        setToastState({
          message: t('partial_cod_enabled'),
          open: true,
        });
      })
      .catch((error) => {
        let message = t('an_error_occured');

        if (error && error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message;
        }
        setLoading(false);
        setToastState({
          message,
          open: true,
        });
      });
  };
  const currencySymbolAlignment = (position) => {
    return (
      <InputAdornment position={position}>
        <p className="currency">{currencySymbol}</p>
      </InputAdornment>
    );
  };
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputText(value);
    setCredentials((credentials) => {
      return {
        ...credentials,
        [name]: +value,
      };
    });
  };

  const onKeyPress = (e) => {
    if ((e.which !== 8 && e.which !== 0 && e.which < 48) || e.which > 57) {
      e.preventDefault();
    }
  };

  return (
    <div className="PartialCODContainer">
      <div className="introContainer">
        {t('reduce_returns_from_cod_orders_by_collecting_partial_amount_upfront')}
      </div>
      <div className="PartialCODAmountSetupContainer">
        <div className="paymentGateway">
          <div className="paymentGatewayStatus">
            <div className="paymentGatewayCircle">
              <p>1</p>
            </div>
            <div className="line"></div>
          </div>
          <div className="paymentGatewaySetupContainer">
            {isPaymentModeSelected ? (
              <div className="paymentGatewayText flexStart">
                <div className="displayNone"></div>
                <header>{t('payment_gateway_connected')}</header>
              </div>
            ) : (
              <div className="paymentGatewayText ">
                <ErrorIcon fill="#FFF" width="15" height="15" />
                <header>{t('payment_gateway_not_setup')}</header>
              </div>
            )}

            <div className="setupPaymentGatewayBtn">
              {isPaymentModeSelected ? (
                <div className="existingGatewayPayments">
                  <span className="paymentTitle">{filteredPaymentModes}</span>
                </div>
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  style={{ marginBottom: 36, marginLeft: 15 }}
                  className={classes.button}
                  onClick={onSetupPaymentGatewayClick}
                >
                  {t('setup_payment_gateway')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="setPartialCODAmountContainer">
          <div
            className={`setPartialCODStatus ${
              isPaymentModeSelected && inputText ? 'completed' : 'incomplete'
            }`}
          >
            <p>2</p>
          </div>
          <div className="setPartialCODText">
            <header>{t('set_partial_cod_amount')}</header>
            <p>{t('this_amount_will_be_collected_online')}</p>
          </div>
        </div>
        <form className={`${classes.root} textField`} noValidate autoComplete="off">
          <CustomTextField
            fullWidth
            style={{ marginLeft: 15 }}
            value={inputText}
            margin="normal"
            name="partialCodAmount"
            onChange={onChange}
            onKeyPress={onKeyPress}
            InputProps={{
              startAdornment:
                currencyAlignment === 'left' ? currencySymbolAlignment('start') : null,
              endAdornment: currencyAlignment === 'right' ? currencySymbolAlignment('end') : null,
              classes: {
                root: classes.inputPaddingBottom,
              },
            }}
          />
        </form>
        {loading ? (
          <div style={{ marginLeft: 24, marginTop: 24 }}>
            <Loader small />
          </div>
        ) : isActive && isPaymentModeSelected ? (
          <div>
            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: 0 }}
              className={`${classes.buttonDisable} ${
                inputText && isPaymentModeSelected ? '' : 'hidden'
              }`}
              onClick={() => {
                setLoading(true);
                payments.disabledPCOD().then(() => {
                  setIsActive(false);
                  setLoading(false);
                  setToastState({
                    message: t('partial_cod_disabled'),
                    open: true,
                  });
                });
              }}
            >
              {t('disable')}
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            size="large"
            style={{ marginLeft: 0 }}
            className={`${classes.button} ${inputText && isPaymentModeSelected ? '' : 'hide'}`}
            onClick={onSubmit}
          >
            {t('enable')}
          </Button>
        )}
      </div>
      <div className="concludingtextContainer">
        *{t('if_order_amount_is_less_than_partial_cod_amount_then_customer_will_have_to')}
      </div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}
export default PartialCOD;
