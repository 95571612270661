import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';
import CurrencyMapper, { currencyOptions } from '../helpers/currency';
import CacheRequest from './cacheRequest';

const apis = {
  companyAmalgamEndpoint: '/v1/company',
};

const getCompanyDetails = () => {
  return ApiWrapper.getDataPromise(apis.companyAmalgamEndpoint, '', CacheKeys.companyDetails);
};

const getCompanyData = () =>
  getCompanyDetails().catch((error) => {
    CacheRequest.setCacheForKey(CacheKeys.companyDetails, null, { error });
  });

const getCachedCompanyDetails = () => CacheRequest.getCacheForKey(CacheKeys.companyDetails);

const getCompanyCurrencyProperties = () => {
  const cachedCompayDetails = CacheRequest.getCacheForKey(CacheKeys.companyDetails);
  if (!cachedCompayDetails) {
    return { symbol: '', symbolOnRight: false };
  }

  const { currencyCode } = cachedCompayDetails;
  return {
    symbol: CurrencyMapper[currencyCode] || '',
    symbolOnRight: (currencyOptions[currencyCode] || {}).symbolOnRight || false,
  };
};

export default {
  getCompanyData,
  getCompanyDetails,
  getCompanyCurrencyProperties,
  getCachedCompanyDetails,
};
