export const PAGE_TYPE = {
  GOLD_RATE: 'GOLD',
  SILVER_RATE: 'SILVER'
};

export const PAGE_ENTITY_TYPE = {
  [PAGE_TYPE.GOLD_RATE]: 'GOLD',
  [PAGE_TYPE.SILVER_RATE]: 'SILVER'
};

export const PAGE_TITLES = {
  [PAGE_TYPE.GOLD_RATE]: 'gold_rate',
  [PAGE_TYPE.SILVER_RATE]: 'silver_rate'
};

export const PAGE_UNITS = {
  [PAGE_TYPE.GOLD_RATE]: 'kt',
  [PAGE_TYPE.SILVER_RATE]: ''
};
