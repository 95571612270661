import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Loader from "../../common/Loader";
import Toast from "../../common/Alerts/Toast";
import { IconButton } from "@material-ui/core";
import Icon from "../../common/Icon";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        backgroundColor: "#3F4653",
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        color: "#fff",
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "coloumn",
        "&:last-child": {
            paddingBottom: 16,
        },
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
});


function GscDetailsRow({ title, subtitle, success, retryFunc, loading }) {
    const classes = useStyles();
    const [toastState, setToastState] = useState({
        open: false,
        message: "",
    });

    const renderIcon = () => {
        return (success ? <svg xmlns="http://www.w3.org/2000/svg" style={{ padding: '12px' }} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" />
            <path fill="#4DA47A" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
        </svg> : retryBtn())
    }

    const retryBtn = () => {
        return (
            <IconButton size="medium" className="gscRetryBtn" onClick={retryFunc}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M13.1459 11.0499L12.9716 9.05752L15.3462 8.84977C14.4471 7.98322 13.2242 7.4503 11.8769 7.4503C9.11547 7.4503 6.87689 9.68888 6.87689 12.4503C6.87689 15.2117 9.11547 17.4503 11.8769 17.4503C13.6977 17.4503 15.2911 16.4771 16.1654 15.0224L18.1682 15.5231C17.0301 17.8487 14.6405 19.4503 11.8769 19.4503C8.0109 19.4503 4.87689 16.3163 4.87689 12.4503C4.87689 8.58431 8.0109 5.4503 11.8769 5.4503C13.8233 5.4503 15.5842 6.24474 16.853 7.52706L16.6078 4.72412L18.6002 4.5498L19.1231 10.527L13.1459 11.0499Z" fill="white" />
                </svg>
            </IconButton>
        )
    }

    return (
        <>
            <Card
                className={`paymentRow ${classes.root}`}
                elevation={3}            >
                <CardContent className={classes.content}>
                    <div>
                        <Icon className="PrimaryIcon" />
                    </div>
                    <div className="paymentName">
                        {title}
                        <div className="SubTitleContainer">
                            <span>{subtitle}</span>
                        </div>
                    </div>
                    <div className="RowLoaderContainer">
                        {loading ? <div style={{ padding: '12px' }}>
                            <Loader small />
                        </div> : renderIcon()}
                    </div>
                </CardContent>
            </Card>
            <Toast
                message={toastState.message}
                open={toastState.open}
                onClose={() => {
                    setToastState({
                        open: false,
                        message: "",
                    });
                }}
            />
        </>
    );
}

export default GscDetailsRow;
