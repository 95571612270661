import { useEffect } from 'react';
import firebase from '../../../FirebaseConfig';
import { getCustomToken } from '../API';
import { getToken } from 'qs-data/util';

export const useHandleTokenFirebaseLogin = (showToast) => {
  useEffect(() => {
    const handleLogin = async () => {
      if (window.top !== window.self) return;
      if (firebase.auth().currentUser) return;
      if (!getToken()) return showToast('Please check the link and try again');
      const data = await getCustomToken();
      if (data && !data.token) {
        showToast && showToast('Please check the link and try again');
      } else {
        try {
          const result = await firebase.auth().signInWithCustomToken(data.token);
          if (result.user) {
            console.log('User Firebase:', result.user);
          }
        } catch (error) {
          console.error('Firebase login error:', error);
          showToast && showToast('Please check the link and try again');
        }
      }
    };

    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);
};
