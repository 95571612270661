import Toast from 'qs-common/Alerts/Toast';
import Loader from 'qs-common/Loader';
import network from 'qs-data/network';
import React, { useState } from 'react';
import './styles.scss';
import { getI18N } from '../../../i18N';

const UsageReport = ({ months }) => {
  const { t } = getI18N();
  const [loading, setLoading] = useState(false);
  const [selectedDownloadMonth, setSelectedDownloadMonth] = useState('');
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const downloadFileFromLink = (link, fileName) => {
    const a = document.createElement('a');
    a.href = link;
    if (fileName) {
      a.download = fileName;
    }
    a.click();
  };

  const downloadReport = async (month, index) => {
    setLoading(true);
    setSelectedDownloadMonth(index);
    try {
      const { excelLink, fileName } = (await network.downloadUsageReport(month)) || {};
      downloadFileFromLink(excelLink, fileName);
      setToastState({
        open: true,
        message: t('successfully_downloaded_the_report'),
      });
    } catch (error) {
      setToastState({
        open: true,
        message: t('something_went_wrong_could_not_download_the_sms_usage_report'),
      });
    } finally {
      setLoading(false);
      setSelectedDownloadMonth('');
    }
  };
  if (!(months && Array.isArray(months))) {
    return null;
  }
  return (
    <>
      <div className="smsReports">
        <div className="smsUsageReportContainer">
          <div className="title">{t('sms_usage_report')}</div>
          <div className="downloadReportMonthsList">
            {months.map((month, index) => {
              return (
                <div className="monthRow" key={index}>
                  <div className="month">{month.display}</div>
                  <div className="download" onClick={() => downloadReport(month.request, index)}>
                    {loading && index === selectedDownloadMonth && (
                      <Loader small style={{ margin: 0, padding: 0, marginRight: 20 }} />
                    )}
                    {t('download')}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default UsageReport;
