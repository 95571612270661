// TODO replace with what is sent from BE
export const RAZORPAY_REQUIRED_DOCUMENTS = {
  proprietorship: {
    businessPanRequired: false,
    cinRequired: false,
    llpinRequired: false,
  },
  partnership: {
    businessPanRequired: true,
    cinRequired: false,
    llpinRequired: false,
  },
  private_limited: {
    businessPanRequired: true,
    cinRequired: true,
    llpinRequired: false,
  },
  public_limited: {
    businessPanRequired: true,
    cinRequired: true,
    llpinRequired: false,
  },
  llp: {
    businessPanRequired: true,
    cinRequired: false,
    llpinRequired: true,
  },
  not_yet_registered: {
    businessPanRequired: false,
    cinRequired: false,
    llpinRequired: false,
  },
};
