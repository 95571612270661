import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import ApiWrapper from 'qs-data/apiWrapper';
import { trackAnalytics } from '../os';
import { getPlatform, PLATFORMS } from '../os';
import Utility from 'qs-helpers/utility';

const isPlatformWeb = getPlatform() === PLATFORMS.WEB;

const useStyles = makeStyles({
  button: {
    color: '#82B4FB',
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 400,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
  },
  manageBtn: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    textDecorationThickness: '0.8px',
    textDecorationColor: '#82B4FB',
    textDecorationStyle: 'dashed',
  },
  icon: {
    color: isPlatformWeb ? '#82B4FB' : '#ffffff',
    fontSize: isPlatformWeb ? 14 : 24,
    marginRight: isPlatformWeb ? '2px' : '0px',
  },
  iconMob: {
    color: '#82B4FB',
    fontSize: 22,
    marginRight: '2px',
  },
  fieldsContainer: {
    height: '100%',
  },
});

const getPostIdApi = () => {
  return ApiWrapper.getDataPromise('/v1/help-center/button');
};

const getPostId = (buttonId) => {
  getPostIdApi()
    .then((result) => {
      result.buttonArticleMap.forEach((ids) => {
        if (ids.buttonId === buttonId) {
          trackAnalytics({
            eventName: 'help_center_clicked',
            props: {
              article_id: ids.articleId,
              buttonId: ids.buttonId,
            },
          });
          Utility.openPopup(ids.url);
        }
      });
    })
    .catch((e) => e);
};

export default function HelpButton({ postName, buttonId }) {
  const classes = useStyles();

  return (
    <Box className={`${classes.fieldsContainer}`}>
      {isPlatformWeb ? (
        <div className={classes.manageBtn}>
          <Button onClick={() => getPostId(buttonId)} className={classes.button}>
            <HelpIcon className={classes.icon} />
            {postName}
          </Button>
        </div>
      ) : (
        <HelpIcon className={classes.icon} onClick={() => getPostId(buttonId)} />
      )}
    </Box>
  );
}
