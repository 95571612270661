import React, { useState } from 'react';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import './style.scss';
import { addSeconds } from 'date-fns';
import { defaultTime, addTime, setTime } from './Constants';
import { getI18N } from '../../i18N';

const useStyles = makeStyles({

  root: {
    color: '#677184',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-around',

    '&$disabled': {
      color: '#677184',
    },
  },

  disabled: {},
});

export default function Timer({ accessTime, newAccessSetTime, accessGivenTime }) {
  const { t } = getI18N();
  const timerDefault = addSeconds(defaultTime, 0);
  const [timerValue, setTimerValue] = useState(timerDefault);
  const dialogStyles = useStyles();

  const handleTimeChange = (time) => {
    setTimerValue(time);
    newAccessSetTime(time);
  };

  const handleAddTime = (timeAdd) => {
    const updTime = (!timerValue || (timerValue && isNaN(timerValue.getTime()))) ? 1663180200000 : timerValue;

    addTime.forEach((timer) => {
      if (timer.time === timeAdd) {
        setTimerValue(addSeconds(updTime, timer.timeInSeconds));
        newAccessSetTime(addSeconds(updTime, timer.timeInSeconds));
      }
    });
  };

  const handleSetTime = (timeSet) => {
    setTime.forEach((timer) => {
      if (timer.time === timeSet) {
        setTimerValue(addSeconds(timerDefault, timer.timeInSeconds));
        newAccessSetTime(addSeconds(timerDefault, timer.timeInSeconds));
      }
    });
  };


  return (
    <div>
      <div className="timerTitle">{(accessGivenTime && accessTime) ? t('change_time') : t('set_timer')}</div>
      <div className="timerContent">
        <div>
          <div className="timeRow">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                clearable
                ampm={false}
                value={timerValue}
                inputProps={{ style: { color: '#ffffff', fontSize: '60px', width: '150px' } }}
                KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                InputAdornmentProps={{ position: '' }}
                helperText={
                  <>
                    <span>{t('hours')}</span> <span>{t('minutes')}</span>
                  </>
                }
                FormHelperTextProps={{
                  classes: { root: dialogStyles.root, disabled: dialogStyles.disabled },
                }}
                onChange={handleTimeChange}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="timeButton">
          <div className="timeList">{t('add_time')}</div>
          <div className="timeButtonContainer">
            {addTime.map((time) => {
              return (
                <Button
                  key={time.time}
                  variant="outlined"
                  style={{ borderColor: '1px solid #4DA47A', color: '#4DA47A', margin: '2px' }}
                  onClick={() => handleAddTime(time.time)}
                >
                  <span>{time.time}</span>
                </Button>
              );
            })}
          </div>
        </div>
        <div className="timeButton">
          <div className="timeList">{t('set_time')}</div>
          <div className="timeButtonContainer">
            {setTime.map((time) => {
              return (
                <Button
                  key={time.time}
                  variant="outlined"
                  style={{ borderColor: '1px solid #4DA47A', color: '#4DA47A', margin: '2px' }}
                  onClick={() => handleSetTime(time.time)}
                >
                  <span>{time.time}</span>
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
