import Native from './shared';

const Android = window.Android || {};

class AndroidImpl extends Native {
  static isAndroid() {
    return typeof window !== 'undefined' && typeof window.Android !== 'undefined';
  }

  goBack() {
    if (Android.goBack) {
      Android.goBack();
    }
  }

  seatReserved(webinarName, startTime) {
    if (Android.seatReserved) {
      Android.seatReserved(webinarName, startTime);
    }
  }

  setNavigationBarColor(color) {
    if (Android.setNavigationBarColor) {
      Android.setNavigationBarColor(color);
    }
  }

  initiateGoogleLogin() {
    if (Android.initiateGoogleLogin) {
      Android.initiateGoogleLogin();
    }
  }

  shareIntent(url, content, contentType) {
    if (Android.shareIntent) {
      Android.shareIntent(url, content, contentType);
    }
  }

  setStatusBarColor(color) {
    if (Android.setStatusBarColor) {
      Android.setStatusBarColor(color);
    }
  }

  async isFeatureAllowed(feature) {
    if (Android.isFeatureAllowed) {
      return Android.isFeatureAllowed(feature);
    }
    return true;
  }

  async fetchContactList() {
    if (Android.fetchContactList) {
      return Android.fetchContactList();
    }
    return [];
  }

  async getIdToken(callbackFunctionName) {
    if (Android.getIdToken) {
      return Android.getIdToken(callbackFunctionName);
    }
    return false;
  }

  async hasPermission(permissionName) {
    if (Android.hasPermission) {
      return Android.hasPermission(permissionName);
    }
    return false;
  }

  async requestPermission(permissionName) {
    if (Android.requestPermission) {
      return Android.requestPermission(permissionName);
    }
    return false;
  }

  getSavedLoginCredentials() {
    if (typeof Android.getSavedLoginCredentials === 'function') {
      return Android.getSavedLoginCredentials();
    }
    return false;
  }

  deleteLoginCredentials(loginCredentials) {
    if (typeof Android.deleteLoginCredentials == 'function') {
      return Android.deleteLoginCredentials(loginCredentials);
    }
    return;
  }

  showPremiumFeatureDialog(feature) {
    if (Android.showPremiumFeatureDialog) {
      Android.showPremiumFeatureDialog(feature);
    }
  }

  shareReferral(content) {
    this.shareIntent(null, content, 'text/plain');
  }

  trackAnalytics(params) {
    if (Android.sendEvent) {
      Android.sendEvent(params.eventName, JSON.stringify(params.props));
    }
  }

  shareImage(url) {
    if (Android.shareImage) {
      Android.shareImage(url);
    }
  }
}

export default AndroidImpl;
