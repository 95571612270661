import React, { useEffect, useState } from 'react';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import './style.scss';
import Loader from 'qs-common/Loader';
import Toast from 'qs-common/Alerts/Toast';
import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../../os';
import { Button } from '@material-ui/core';
import IdeaIcon from 'qs-assets/icons/IdeaIcon';
import network from 'qs-data/network';
import { navigationBarColor, statusBarColor } from '../Constants';
import Cache from '../Cache';
import { getI18N } from '../../../i18N';

const rewardClaimState = {
  CLAIMED: 'CLAIMED',
  CLAIM_REWARD: 'CLAIM REWARD',
  IN_PROGRESS: 'IN PROGRESS',
};
const rewardStatusKey = {
  ADDED: 'ADDED',
  CLAIMED: 'CLAIMED',
};
const YourRewards = () => {
  const { t } = getI18N();
  const [, dispatch] = useAppContext();
  const [loading, setLoading] = useState(true);
  const [rewardsData, setRewardsData] = useState([]);
  const [claimStateLoader, setClaimStateLoader] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#2B2F39',
        color: '#FFFFFF',
        boxShadow: false,
        referral: true,
        enableBoxShadow: false,
        title: t('your_rewards'),
        overrideTitleClassStyle: {
          color: '#FFFFFF',
          lineHeight: '25px',
          fontSize: '18px',
          fontWeight: 600,
          flexGrow: 1,
        },
      },
    });
    setNavigationBarColor(navigationBarColor);
    setStatusBarColor(statusBarColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getRewardsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRewardsData = async () => {
    setLoading(true);
    try {
      const data = await network.getYourRewards();
      if (data.length === 0) {
        setMessage(t('no_rewards_available'));
      }
      setLoading(false);
      setRewardsData(data);
    } catch (error) {
      setLoading(false);
      setRewardsData([]);
      setToastState({
        open: true,
        message: t('something_went_wrong_could_not_get_your_rewards'),
      });
    }
  };
  const changeRewardState = async ({ id, rewardAmount, rewardType, btnText, canClaim, index }) => {
    if (btnText.toUpperCase() !== rewardClaimState.CLAIM_REWARD || !canClaim) {
      return;
    }
    trackAnalytics({
      eventName: 'claim_reward',
      props: {
        ...Cache.getReferralAnalyticsObject(),
        rewardId: id,
        rewardAmount: rewardAmount,
        rewardType: rewardType,
      },
    });

    try {
      setClaimStateLoader(true);
      setSelectedId(index);
      const rewardStatus = { rewardType, reward: rewardAmount, rewardId: id };
      const data = await network.claimYourReward({
        rewardStatus,
      });
      const copiedList = [...rewardsData];
      const updatedList = copiedList.map((reward, idx) => {
        if (
          reward.rewardType === rewardType &&
          reward.id === id &&
          rewardAmount === reward.rewardAmount &&
          index === idx
        ) {
          reward.btnText = data.btnText || t('claim_reward');
          reward.rewardStatus = t('claimed');
        }
        return reward;
      });
      setClaimStateLoader(false);
      setRewardsData(updatedList);
    } catch (error) {
      setClaimStateLoader(false);
      setToastState({
        open: true,
        message: `${error.response.data.reason}`,
      });
    }
  };

  const showMessage = () => {
    return <div className="message">{message}</div>;
  };

  const renderRewards = () => {
    return (
      <div className="rewardsContainer">
        {rewardsData.map((reward, index) => {
          let {
            canClaim,
            noOfUsersReferred,
            rewardAmount,
            rewardStatus,
            rewardType,
            tip,
            btnText,
            id,
            rewardTitle,
          } = reward;
          return (
            <div className="reward" key={index}>
              <div className="rewardDetails">
                <div className="titleSubtitleContainer">
                  <div className="title">{rewardTitle}</div>
                  <div className="subTitle">{noOfUsersReferred}</div>
                </div>
                <Button
                  className={`rewardStateButton ${
                    rewardStatus === rewardStatusKey.ADDED && canClaim ? '' : 'claimed'
                  }`}
                  onClick={() =>
                    changeRewardState({
                      id,
                      rewardAmount,
                      rewardType,
                      btnText,
                      rewardStatus,
                      canClaim,
                      index,
                    })
                  }
                >
                  {claimStateLoader && selectedId === index ? (
                    <div>
                      <Loader small />
                    </div>
                  ) : (
                    <span
                      style={{
                        visibility: `${claimStateLoader && selectedId === index ? 'hidden' : ''}`,
                      }}
                    >
                      {btnText}
                    </span>
                  )}
                </Button>
              </div>
              {tip && (
                <div className="tipSection">
                  <IdeaIcon />
                  <span>
                    {t('tip')}: {tip}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="yourRewardsContainer">
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className="yourRewardsTitle">{t('rewards')}</div>
          {rewardsData.length > 0 ? renderRewards() : message ? showMessage() : ''}
          <Toast
            message={toastState.message}
            open={toastState.open}
            duration={3000}
            onClose={() => {
              setToastState({
                open: false,
                message: '',
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default YourRewards;
