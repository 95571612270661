import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import CustomLabel from 'qs-common/CustomLabel';
import ChangeText from 'qs-common/ChangeText';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import ShippingWeightUnitDialog from '../ShippingWeightUnitDialog';
import { getI18N } from '../../../../i18N';
import "./style.scss";

function ShippingWeightUnit({ onWeightUpdated }) {

  const [selectedWeight, setSelectedWeight] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    fetchWeightUnit();
  }, []);

  useEffect(() => {
    onWeightUpdated(selectedWeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeight]);

  /* Fetch shipping weight unit */
  const fetchWeightUnit = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ShippingData.getShippingWeightUnit().then((weightUnit) => {
      setSelectedWeight(weightUnit);
    }).catch(() => {
      showErrorComponent(true);
    }).finally(() => {
      setProgressBar(false);
    });
  }

  const openDialog = () => {
    setShowDialog(true);
  }

  const closeDialog = () => {
    setShowDialog(false);
  }

  return <div className='shippingItemContainer weightContainer'>
    <CustomLabel value={t('weight_unit')} />
    {
      progressBar ? <CircularProgress size={24} color="primary" style={{ marginTop: '8px' }} /> :
        errorComponent ? <CustomErrorComponent onRetryClick={fetchWeightUnit} /> :
          <div className='updateWeightUnitContainer'>
            {selectedWeight && <span className='weightUnit'>{selectedWeight}</span>}
            <ChangeText text={isNil(selectedWeight) ? t('select') : t('change')} onClick={openDialog} />
          </div>
    }
    <ShippingWeightUnitDialog
      showDialog={showDialog}
      onClose={closeDialog}
      weightUnit={selectedWeight}
      onSuccess={(weight) => {
        setSelectedWeight(weight);
        closeDialog();
      }}
    />
  </div>
}

export default withRouter(ShippingWeightUnit);
