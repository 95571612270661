import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import './style.scss';

export default function ({
  handleChange,
  disabled,
  checked,
  label,
  labelStyleClassName = '',
  checkBoxStyle = null,
  formControlLabel = null,
}) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={handleChange}
            name={label}
            classes={checkBoxStyle ? { root: checkBoxStyle } : {}}
          />
        }
        label={
          label && <div className={`customCheckboxLabel  ${labelStyleClassName}`}> {label} </div>
        }
        classes={formControlLabel ? { root: formControlLabel } : {}}
      />
    </FormGroup>
  );
}
