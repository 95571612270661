import React, { useRef, useEffect, useState } from 'react';
import './styles.scss';

export default function GhostInput({
  inputRef,
  barcode,
  onChange,
  debounceTime = 300,
  handleBlur,
}) {
  const [inputValue, setInputValue] = useState(barcode);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    setInputValue(barcode);
  }, [barcode]);

  const handleChange = (value) => {
    setInputValue(value);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (onChange) onChange(value);
      console.log('input changed');
    }, debounceTime);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <>
      <input
        className="create-order-ghost-input"
        autoFocus
        ref={inputRef}
        type="text"
        value={inputValue}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
}
