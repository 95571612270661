import React from 'react';

export default ({ color = '#00A674', size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android"
        transform="translate(-38.000000, -531.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="icons8-iOS-Glyph-60022-50-000000" transform="translate(38.000000, 531.000000)">
          <path
            d="M10,0 C4.0546876,0 0.2140624,6.1406252 0.1453124,6.2531252 C0.0515624,6.3703124 0,6.5171876 0,6.6671876 C0,6.7968752 0.0375,6.9218752 0.1078124,7.03125 C0.1093752,7.03125 0.1093752,7.0328124 0.1109376,7.0343752 C0.1218752,7.0578124 3.3453124,13.3343752 10,13.3343752 C16.6265624,13.3343752 19.8453124,7.1203124 19.8843752,7.04375 C19.8875,7.0390628 19.8890624,7.0343752 19.8921876,7.03125 C19.9625,6.9218752 20,6.7968752 20,6.6671876 C20,6.5171876 19.95,6.3718752 19.85625,6.2546876 C19.85625,6.2546876 19.85625,6.2531252 19.8546876,6.2531252 C19.7859376,6.1406252 15.9453124,0 10,0 Z M10,2 C12.5765624,2 14.6671876,4.0906252 14.6671876,6.6671876 C14.6671876,9.24375 12.5765624,11.3343752 10,11.3343752 C7.4234376,11.3343752 5.3328124,9.24375 5.3328124,6.6671876 C5.3328124,4.0906252 7.4234376,2 10,2 Z M10,4.6671876 C8.8953124,4.6671876 8,5.5625 8,6.6671876 C8,7.7718752 8.8953124,8.6671876 10,8.6671876 C11.1046876,8.6671876 12,7.7718752 12,6.6671876 C12,5.5625 11.1046876,4.6671876 10,4.6671876 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
