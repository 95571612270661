import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  createMuiTheme,
  ThemeProvider,
  TextField,
  Typography,
} from '@material-ui/core';
import Loader from 'qs-common/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import Toast from 'qs-common/Alerts/Toast';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import network from 'qs-data/network';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  BACKGROUND_COLOR,
  LINK_REGEX,
  getWebhookEvents,
} from '../constants';
import { getI18N } from '../../../i18N';

import './style.scss';

const NAVBAR_HEIGHT = 55;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: SECONDARY_COLOR,
    },
    secondary: {
      main: PRIMARY_COLOR,
    },
    background: {
      paper: BACKGROUND_COLOR,
      default: BACKGROUND_COLOR,
    },
    text: {
      color: SECONDARY_COLOR,
    },
    type: 'dark',
  },
});

export default () => {
  const { url } = useParams();
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookEventTypes, setWebhookEventTypes] = useState({});
  const [webhookEvents, setWebhookEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isWebhookUrlValid, setIsWebhookUrlValid] = useState(true);
  const history = useHistory();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });
  const [, dispatch] = useAppContext();

  const { t } = getI18N();

  const getWebhookEventTypes = (allWebhookEvents, currentSelectedWebhook) => {
    let events = [];
    Object.keys(allWebhookEvents).forEach((eventGroup) => {
      allWebhookEvents[eventGroup].events.forEach((event) => {
        events.push(event);
      });
    });
    const formattedEventTypes = events.reduce((accumulator, { event: eventType }) => {
      accumulator[eventType] = (currentSelectedWebhook.events || []).includes(eventType);
      return accumulator;
    }, {});
    return formattedEventTypes;
  };

  const getAllWebhookEvents = async () => {
    try {
      setLoading(true);
      const webhooksList = url ? await network.getWebhooks() : [];
      const webhookEventsAll = getWebhookEvents(t);
      setWebhookEvents(webhookEventsAll);
      const currentSelectedWebhook =
        webhooksList.find(({ webhookUrl }) => encodeURIComponent(webhookUrl) === url) || {};
      const formattedEventTypes = getWebhookEventTypes(webhookEventsAll, currentSelectedWebhook);
      setWebhookUrl(currentSelectedWebhook.webhookUrl || '');
      setWebhookEventTypes(formattedEventTypes);
    } catch (error) {
      setToastState({ open: true, message: t('error_while_fetching_webhook_events') });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllWebhookEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        title: url ? t('edit_webhook') : t('create_webhook'),
        height: NAVBAR_HEIGHT,
        hideBack: false,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });

    setNavigationBarColor('#242c36');
    setStatusBarColor(PRIMARY_COLOR);

    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop, url]);

  const isValidLinkUrl = (linkUrl) => {
    try {
      linkUrl = linkUrl.replace('https://', '').replace('http://', '');
      const url = new URL(`https://${linkUrl}`);
      const pattern = new RegExp(LINK_REGEX, 'i');
      return !!pattern.test(url.href);
    } catch (_error) {
      return false;
    }
  };

  const submitAddWebhooksForm = async (event) => {
    event.preventDefault();
    if (loading || disabled) {
      return;
    }
    const selectedWebhookEventTypes = Object.entries(webhookEventTypes || {})
      .filter(([, value]) => !!value)
      .map(([eventType]) => eventType);
    if (!selectedWebhookEventTypes || Object.keys(selectedWebhookEventTypes).length === 0) {
      return;
    }
    if (!isValidLinkUrl(webhookUrl)) {
      setIsWebhookUrlValid(false);
      return;
    }
    setDisabled(true);
    try {
      await network.createWebhook({
        webhookUrl: webhookUrl,
        webhookEvents: selectedWebhookEventTypes,
      });
      history.goBack();
    } catch (error) {
      setToastState({ open: true, message: t('error_while_adding_webhook') });
    } finally {
      setDisabled(false);
    }
  };

  const handleEventTypeChecked = ({ eventType, checked }) => {
    setWebhookEventTypes({ ...webhookEventTypes, [eventType]: checked });
  };

  const renderCreateWebhookForm = () => {
    return (
      <form
        onSubmit={submitAddWebhooksForm}
        className="webhooks-form"
        noValidate
        autoComplete="off"
      >
        <div className="webhooks-form-header">
          <Typography className="webhooks-form-url-label" color="primary">
            {t('webhook_url')}
          </Typography>
          <TextField
            color="primary"
            value={webhookUrl}
            placeholder={t('enter_webhook_url')}
            disabled={loading || disabled}
            onChange={(event) => {
              setWebhookUrl(event.target.value);
              setIsWebhookUrlValid(true);
            }}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: false }}
            classes={{ root: 'webhooks-form-url-input' }}
          />
          {!isWebhookUrlValid && (
            <Typography variant="caption" color="error">
              {t('please_enter_a_valid_url')}
            </Typography>
          )}
        </div>
        <div className="event-types-checkboxes-container">
          {Object.keys(webhookEvents || {}).map((eventGroup, eventGroupIndex) => (
            <div key={eventGroupIndex} className="event-types-checkboxes-type">
              <Typography className="event-types-checkboxes-heading" color="primary">
                {webhookEvents[eventGroup].formattedName}
              </Typography>
              {((webhookEvents[eventGroup] || {}).events || []).map((eventData, eventTypeIndex) => {
                return (
                  <div key={eventTypeIndex} className="event-types-checkboxes">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={eventData.event}
                          checked={webhookEventTypes[eventData.event]}
                          onChange={(event) => {
                            handleEventTypeChecked({
                              eventType: eventData.event,
                              checked: event.target.checked,
                            });
                          }}
                        />
                      }
                      label={eventData.formattedName}
                      classes={{
                        root: 'event-types-checkbox',
                        label: 'event-types-checkbox-label',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="save-webhook-button"
          onClick={submitAddWebhooksForm}
          disabled={
            !Object.values(webhookEventTypes || {}).some((value) => !!value) || loading || disabled
          }
          startIcon={loading || disabled ? <Loader small={true} /> : null}
        >
          <span className="save-webhook-button-text">{t('save_webhook')}</span>
        </Button>
      </form>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="webhooks-form-page">
        {loading ? (
          <div className="webhooks-form-page-loader">
            <Loader />
          </div>
        ) : (
          renderCreateWebhookForm()
        )}
      </div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({ open: false, message: '' });
        }}
      />
    </ThemeProvider>
  );
};
