import React, { useState, useEffect, useRef } from 'react';
import rt from 'remaining-time';
import { getI18N } from '../../i18N';

function CountDownTimer({goLiveTime, isLiveCallback, endTime, isDead}) {
    const { t } = getI18N();
    const [timeObj, setTimeObj] = useState(rt.date(goLiveTime));
    const [goLive, setGoLive] = useState(goLiveTime);
    const timer = useRef();

    useEffect(() => {
        setTimeObj(rt.date(goLiveTime));
        setGoLive(goLiveTime);
    }, [goLiveTime]);

    useEffect(() => {
        if(goLive > 0 && !timer.current) {
            timer.current = setInterval(() => {
                const webinarStart = goLive;
                const webinarEnd = endTime;
                const now = new Date().getTime();

                if(now > webinarStart && now < webinarEnd){
                    isLiveCallback();
                }

                if(now > webinarEnd){
                    isDead();
                    setTimeObj({
                        day : 0,
                        month : 0,
                        hour : 0,
                        second : 0
                    });
                    clearInterval(timer.current);
                    return;
                }

                setGoLive((t) => t - 1000);
                setTimeObj(rt.date(goLive));

            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goLive]);

    return (
        <>
            {!timeObj.day && !timeObj.hour && !timeObj.minute && !timeObj.second ?  null : <div className="TimeRemaining">
                {timeObj.day ? <span>{`${timeObj.day}`} {timeObj.day === 1 ? t('day') : t('days')} {timeObj.day < 1 ? ':' : null} </span> : null}
                {timeObj.day < 1 && timeObj.hour ? <span>{`${timeObj.hour}`}  {timeObj.hour === 1 ? t('hour') : t('hours')} : </span> : null}
                {timeObj.day < 1 && timeObj.minute ? <span>{`${timeObj.minute}`} {timeObj.minute === 1 ? t('minute') : t('minutes')} : </span> : null}
                {timeObj.day < 1 && <span>{`${timeObj.second}`} {timeObj.second === 1 ? t('second') : t('seconds')}</span>}
            </div>}
        </>
    )
}

export default CountDownTimer;
