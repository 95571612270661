import React, { useEffect, useState, useCallback } from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import {
  ACCESS_TYPE,
  ATOM,
  COUNT_TYPE,
  ENTITY_TYPE,
  getAdditionalMembersCacheKey,
  GlOBAL_GROUPS,
  renderButtonText,
  renderCount,
} from '../privacySettingConstants';
import network from 'qs-data/network';
import Toast from 'qs-common/Alerts/Toast';
import RenderRowIcon from '../RenderRowIcon';
import Loader from 'qs-common/Loader';
import PrivacySettingsCache from '../PrivacySettingsCache';
import { getI18N } from '../../../i18N';

const NAVBAR_HEIGHT = 55;

const AddExistingGroup = () => {
  const { t } = getI18N();
  const [, dispatch] = useAppContext();
  const { entityType, accessLevel } = useParams();
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [expandSearchBox, setExpandSearchBox] = useState(false);
  const [additionalGroupsList, setAdditionalGroupsList] = useState(
    PrivacySettingsCache.getCacheForKey(getAdditionalMembersCacheKey(entityType, accessLevel)) || []
  );

  const [searchResults, setSearchResults] = useState([]);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const onSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearchExpand = useCallback(() => {
    setExpandSearchBox(!expandSearchBox);
    setSearchText('');
  }, [expandSearchBox]);

  const showAdditionalGroupCount = useCallback(() => {
    return renderCount(additionalGroupsList.length, COUNT_TYPE.GROUP);
  }, [additionalGroupsList]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#4DA47A',
        color: '#FFFFFF',
        boxShadow: false,
        enableBoxShadow: false,
        searchable: true,
        showTitleSubTitle: true,
        placeholder: `${t('search')}...`,
        expandSearchBox: expandSearchBox,
        title: t('customer_groups'),
        subTitle: showAdditionalGroupCount(),
        onClick: onSearchExpand,
        onChange: (e) => onSearch(e),
        value: searchText,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
      },
    });

    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, expandSearchBox, onSearch, onSearchExpand, showAdditionalGroupCount]);

  useEffect(() => {
    if (additionalGroupsList.length) {
      if (!searchText && expandSearchBox) {
        setSearchResults(additionalGroupsList);
        return;
      }
      if (searchText) {
        window.scrollTo(0, 0);
        const filteredGroups = additionalGroupsList.filter((group) => {
          const groupName = group.name;
          if (groupName.toLowerCase().includes(searchText)) {
            return group;
          }
          return undefined;
        });
        setSearchResults(filteredGroups);
      }
    }
  }, [searchText, additionalGroupsList, expandSearchBox]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAdditionalGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdditionalGroupsData = async () => {
    if (entityType === ENTITY_TYPE.CATALOGUE) {
      const entityId = getEntityId();
      return (await network.getAdditionalGroupsForCataloguePrivacySettings(entityId)) || {};
    }
    return (await network.getAdditionalGroups()) || {};
  };

  const getAdditionalGroups = async () => {
    if (
      !PrivacySettingsCache.getCacheForKey(getAdditionalMembersCacheKey(entityType, accessLevel))
    ) {
      setLoading(true);
    }
    try {
      const { groups = [] } = await getAdditionalGroupsData();
      const addIsActivatedPropertyToAdditionalGroups = (groups || []).map((group) => ({
        ...group,
        isActivated: false,
      }));
      PrivacySettingsCache.setCacheForKey(
        getAdditionalMembersCacheKey(entityType, accessLevel),
        addIsActivatedPropertyToAdditionalGroups
      );
      setLoading(false);
      setAdditionalGroupsList(addIsActivatedPropertyToAdditionalGroups);
    } catch (error) {
      setAdditionalGroupsList([]);
      setLoading(false);
      setToastState({
        open: true,
        message: t('could_not_get_additional_groups'),
      });
    }
  };

  const addOrRemoveFromPrivacyList = (id, isActivated) => {
    if (!isActivated) {
      addToPrivacyList(id);
    } else {
      removeFromPrivacyList(id);
    }
  };
  const getEntityId = () => {
    if (entityType === ENTITY_TYPE.CATALOGUE) {
      const { entityId } = history.location.state || {};
      if (entityId !== null && entityId !== undefined) {
        return entityId;
      }
    }
    return null;
  };

  const addToPrivacyList = async (id) => {
    setSelectedId(id);
    try {
      await network.addToPrivacyList({
        entityType: entityType,
        ...(getEntityId() && { entityId: getEntityId() }),
        forceListType:
          accessLevel === GlOBAL_GROUPS.ALLOWED ? ACCESS_TYPE.WHITELIST : ACCESS_TYPE.BLACKLIST,
        ids: [id],
        type: ATOM.GROUP,
      });
      setSelectedId('');
      const updatedGroup = additionalGroupsList.find((group) => group.id === id);
      updatedGroup.isActivated = !updatedGroup.isActivated;
      PrivacySettingsCache.setCacheForKey(
        getAdditionalMembersCacheKey(entityType, accessLevel),
        additionalGroupsList
      );
      setAdditionalGroupsList(additionalGroupsList);
      setToastState({
        open: true,
        message: t(updatedGroup.memberCount === 1 ? 'contact_privacy_list_status' : 'contacts_frivacy_list_status', {
          count: updatedGroup.memberCount,
          status: t(accessLevel === GlOBAL_GROUPS.ALLOWED ? 'allowed' : 'blocked'),
        })
      });
    } catch (error) {
      setAdditionalGroupsList([]);
      setSelectedId('');
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const removeFromPrivacyList = async (id) => {
    setSelectedId(id);
    try {
      await network.removeFromPrivacyList({
        entityType: entityType,
        ...(getEntityId() && { entityId: getEntityId() }),
        forceListType:
          accessLevel === GlOBAL_GROUPS.ALLOWED ? ACCESS_TYPE.WHITELIST : ACCESS_TYPE.BLACKLIST,
        ids: [id],
        type: ATOM.GROUP,
      });
      setSelectedId('');
      const updatedGroup = additionalGroupsList.find((group) => group.id === id);
      updatedGroup.isActivated = !updatedGroup.isActivated;
      PrivacySettingsCache.setCacheForKey(
        getAdditionalMembersCacheKey(entityType, accessLevel),
        additionalGroupsList
      );
      setAdditionalGroupsList(additionalGroupsList);
      setToastState({
        open: true,
        message: t(updatedGroup.memberCount === 1 ? 'contact_privacy_list_status' : 'contacts_frivacy_list_status', {
          count: updatedGroup.memberCount,
          status: t(accessLevel === GlOBAL_GROUPS.ALLOWED ? 'denied' : 'unblocked'),
        }),
      });
    } catch (error) {
      setSelectedId('');
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const renderGroupsList = searchText && expandSearchBox ? searchResults : additionalGroupsList;
  return (
    <>
      <div className="addExistingGroup">
        {loading ? (
          <div className="loader">
            <Loader />;
          </div>
        ) : (
          renderGroupsList.map(({ id, name, iconColor, iconName, memberCount, isActivated }) => {
            return (
              <RenderRowIcon
                key={id}
                name={name}
                iconColor={iconColor}
                iconName={iconName}
                memberCount={memberCount}
                loading={id === selectedId}
                renderCount={() => renderCount(memberCount, COUNT_TYPE.CONTACT)}
                renderButtontext={() => renderButtonText(accessLevel, isActivated)}
                api={() => addOrRemoveFromPrivacyList(id, isActivated)}
              />
            );
          })
        )}
      </div>
      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={3000}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default AddExistingGroup;
