import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import SettingCard from 'qs-common/SettingCard';
import Toast from 'qs-common/Alerts/Toast';
import Loader from 'qs-common/Loader';
import { getCachedAvailableProvider, getCachedSelectedProvider } from 'qs-data/shippingProviders';
import { setNavigationBarColor, showPremiumFeatureDialog, setStatusBarColor } from '../../os';
import {
  customShipment,
  fetchSelectedShippingProvider,
  hasShiprocketInPlan,
  SHIPPING_PROVIDERS,
  shiprocketShipment,
  SHIPROCKET_FEATURE,
} from './helpers';
import { getI18N } from '../../i18N';
import './style.scss';
import { capitalize } from 'lodash';

const primaryColor = '#0f141a',
  primaryColorMobile = '#4DA47A',
  textColor = '#FFFFFF';

const ShippingModeSettings = () => {
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [isShiprocketInPlan, setIsShiprocketInPlan] = useState(false);
  const [toastState, setToastState] = useState({});
  const [loading, setLoading] = useState(true);
  const [availableShippingProviders, setAvailableShippingProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(getCachedSelectedProvider());
  
  const { t } = getI18N(); 

  useEffect(() => {
    const canShowShiprocketOption = async () => {
      const hasShiprocket = await hasShiprocketInPlan();
      setIsShiprocketInPlan(hasShiprocket);
    };

    canShowShiprocketOption();
  }, []);

  const hideToast = () => setToastState({ open: false, message: '' });

  const showToast = useCallback((message) => {
    setToastState({ open: true, message });
    setTimeout(hideToast, 2000);
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? primaryColor : primaryColorMobile,
        color: textColor,
        title: t('shipping_cost_settings'),
        hideBack: desktop,
      },
    });
    setNavigationBarColor('#3f4653');
    setStatusBarColor(primaryColorMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  useEffect(() => {
    fetchSelectedShippingProvider()
      .then((shippingProvider) => {
        setLoading(false);
        setAvailableShippingProviders(getCachedAvailableProvider() || []);
        setSelectedProvider(shippingProvider);
      })
      .catch(() => {
        setLoading(false);
        setAvailableShippingProviders([SHIPPING_PROVIDERS.CUSTOM]);
        showToast(t('could_not_fetch_selected_shipping_provider'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToast]);

  const onChangeToggle =
    (shipmentModeId) =>
    (shipmentModeNextActiveState, onDone = () => {}) => {
      const checkIfShipRocketCannotChange = () =>
        !isShiprocketInPlan && shipmentModeId === SHIPPING_PROVIDERS.SHIPROCKET;

      if (checkIfShipRocketCannotChange()) {
        showPremiumFeatureDialog(SHIPROCKET_FEATURE);
      }
      onDone(!shipmentModeNextActiveState);
    };

  const renderCustomShipmentMode = () => (
    <SettingCard
      key={customShipment.id}
      id={customShipment.id}
      source={source}
      languageCode={languageCode}
      className="shipment-mode-setting"
      cardIconClassName="custom-mode-setting-card-icon"
      iconColor={'#FFFFFF'}
      iconStyle={{ width: 28 }}
      initialActiveValue={selectedProvider === SHIPPING_PROVIDERS.CUSTOM}
      toLink={'/custom-shipping-settings'}
      settingTitle={capitalize(t(customShipment.label))}
      settingSubtitle={capitalize(t(customShipment.subtitle))}
      settingSubtitleIcon={customShipment.subtitleIcon}
      onChange={onChangeToggle(customShipment.id)}
    />
  );

  const renderShiprocketShipmentMode = () => (
    <SettingCard
      key={shiprocketShipment.id}
      id={shiprocketShipment.id}
      source={source}
      languageCode={languageCode}
      disabled={!isShiprocketInPlan}
      className="shipment-mode-setting"
      cardIconClassName="shiprocket-mode-setting-card-icon"
      iconStyle={{ width: 22, height: 22 }}
      initialActiveValue={selectedProvider === SHIPPING_PROVIDERS.SHIPROCKET}
      toLink={'/shiprocket-shipping-settings'}
      settingTitle={capitalize(t(shiprocketShipment.label))}
      settingSubtitle={capitalize(t(shiprocketShipment.subtitle))}
      settingSubtitleIcon={shiprocketShipment.subtitleIcon}
      onChange={onChangeToggle(shiprocketShipment.id)}
    />
  );

  return (
    <div className="shippingmode-setting-container">
      {loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {availableShippingProviders.map((shippingProviderKey) => {
            switch (shippingProviderKey) {
              case SHIPPING_PROVIDERS.CUSTOM:
                return renderCustomShipmentMode();
              case SHIPPING_PROVIDERS.SHIPROCKET:
                return renderShiprocketShipmentMode();
              default:
                return null;
            }
          })}
        </Fragment>
      )}
      <Toast open={toastState.open} message={toastState.message} onClose={hideToast} />
    </div>
  );
};

export default withRouter(ShippingModeSettings);
