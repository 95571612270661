import React, { useEffect, useState } from 'react';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import './style.scss';
import Loader from 'qs-common/Loader';
import Toast from 'qs-common/Alerts/Toast';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import network from 'qs-data/network';
import Cache from '../Cache';
import { filterOptions, navigationBarColor, statusBarColor } from '../Constants';
import { getI18N } from '../../../i18N';

const YourReferrals = () => {
  const { t } = getI18N();
  const [, dispatch] = useAppContext();
  const [yourReferralsLoading, setYourReferralsLoading] = useState(true);
  const [yourReferralsData, setYourReferralsData] = useState([]);
  const [message, setMessage] = useState('');
  const [selectedFilterLoader, setSelectedFilterLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#2B2F39',
        color: '#FFFFFF',
        boxShadow: false,
        referral: true,
        enableBoxShadow: false,
        title: t('your_referrals'),
        overrideTitleClassStyle: {
          color: '#FFFFFF',
          lineHeight: '25px',
          fontSize: '18px',
          fontWeight: 600,
          flexGrow: 1,
        },
      },
    });
    setNavigationBarColor(navigationBarColor);
    setStatusBarColor(statusBarColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getMyReferrals(filterOptions.ALL.title);
    return () => {
      Cache.deleteYourReferralsData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMyReferrals = async (filterOption) => {
    if (filterOption === selectedOption) {
      return;
    }
    setSelectedOption(filterOption);
    const filtersDataFromCache = Cache.getYourReferralsCache(filterOption);
    if (filtersDataFromCache) {
      setYourReferralsData(filtersDataFromCache);
      if (filtersDataFromCache.length === 0) {
        setMessage(t('no_referrals_available'));
      }
      return;
    }

    setMessage('');
    setSelectedFilterLoader(true);
    try {
      const data = await network.getYourReferralsDetails(filterOption);
      if (data.length === 0) {
        setMessage(t('no_referrals_available'));
      }
      setYourReferralsLoading(false);
      setSelectedFilterLoader(false);
      setYourReferralsData(data);
      Cache.setYourReferralsCache(filterOption, data);
    } catch (error) {
      setYourReferralsLoading(false);
      setYourReferralsData([]);
      setSelectedFilterLoader(false);
      setToastState({
        open: true,
        message: t('something_went_wrong_could_not_get_your_referrals_data'),
      });
    }
  };

  const filterOptionsHeading = (option = '', id) => {
    return (
      <div
        key={id}
        className="filter"
        style={selectedOption === option ? { backgroundColor: '#fff', color: '#2b2f39' } : {}}
        onClick={() => getMyReferrals(option)}
      >
        {option.toLowerCase()}
      </div>
    );
  };

  const renderCustomersData = () => {
    return (
      <div className="customerDataContainer">
        {yourReferralsData.map(({ name, phone }, index) => {
          return (
            <div className="customerData" key={index}>
              <div className="name">{name}</div>
              <div className="phoneNumber">{phone}</div>
            </div>
          );
        })}
      </div>
    );
  };
  const showMessage = () => {
    return <div className="message">{message}</div>;
  };

  return (
    <div className="yourReferralsParentContainer">
      {yourReferralsLoading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div div className="filterOptionsContainer">
          {Object.keys(filterOptions).map((option) => {
            return filterOptionsHeading(filterOptions[option].title, filterOptions[option].id);
          })}
        </div>
      )}
      {selectedFilterLoader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : yourReferralsData.length > 0 ? (
        renderCustomersData()
      ) : message ? (
        showMessage()
      ) : (
        <div />
      )}

      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={3000}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
};

export default YourReferrals;
