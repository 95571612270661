import CacheRequest from './cacheRequest';
import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

const API_ENDPOINTS = {
  GET_SHIPPING_PROVIDER: '/v1/shipping/company-providers',
  UPDATE_SHIPPING_PROVIDER: '/v1/shipping/provider-details',
  UPDATE_SHIPPING_CREDENTIALS: '/v1/shipping/company/credentials',
  GET_SHIPPING_CREDENTIALS: '/v1/shipping/company/credentials?provider=SHIPROCKET'
};

/**
 * Cache methods
 */
export const setCachedSelectedProvider = (provider) => {
  CacheRequest.setCacheForKey(CacheKeys.selectedShippingProvider, provider);
};

export const getCachedSelectedProvider = () => CacheRequest.getCacheForKey(CacheKeys.selectedShippingProvider);

export const setCachedAvailableProvider = (providers) => {
  CacheRequest.setCacheForKey(CacheKeys.availableShippingProviders, providers);
};

export const getCachedAvailableProvider = () =>
  CacheRequest.getCacheForKey(CacheKeys.availableShippingProviders);

export const setCachedShippingCredentials = (crendentials) => {
  CacheRequest.setCacheForKey(CacheKeys.shippingCredentials, crendentials);
};

export const getCachedShippingCredentials = () =>
  CacheRequest.getCacheForKey(CacheKeys.shippingCredentials);

/**
 * Payment and shiprocket communication cache
 */

export const getCachedEnteredCredentials = () =>
  CacheRequest.getCacheForKey(CacheKeys.shiprocketEnteredCredentials);

export const setCachedEnteredCredentials = (credentials) => {
  CacheRequest.setCacheForKey(CacheKeys.shiprocketEnteredCredentials, credentials);
}

/**
 * Api handlers
 */
export const getShippingProvider = () =>
  ApiWrapper.getDataPromise(API_ENDPOINTS.GET_SHIPPING_PROVIDER);

export const updateShippingProvider = (provider) =>
  ApiWrapper.putDataPromise(API_ENDPOINTS.UPDATE_SHIPPING_PROVIDER, { provider });

export const fetchShippingCredentials = (credentials) =>
  ApiWrapper.getDataPromise(API_ENDPOINTS.GET_SHIPPING_CREDENTIALS, credentials);

// credentials = { provider, identifier, password }
export const putShippingCredentials = (credentials) =>
  ApiWrapper.putDataPromise(API_ENDPOINTS.UPDATE_SHIPPING_CREDENTIALS, credentials);

export const getShippingAccount = () =>
  ApiWrapper.getDataPromise(API_ENDPOINTS.GET_SHIPPING_ACCOUNT);
