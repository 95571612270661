import React, { useEffect, useState } from 'react';
import Ripples from 'react-ripples';
import { useHistory, withRouter } from 'react-router-dom';
import './style.scss';

import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import HSLColorPicker from 'qs-components/Theme/HSLColorPicker';
import ThemeSectionPreview from 'qs-components/Theme/ThemeSectionPreview';
import CacheRequest from 'qs-data/cacheRequest';
import { ReactComponent as BackIcon } from 'qs-assets/images/back.svg';
import CacheKeys from 'qs-data/cacheKeys';
import { prepareCompanyDetailsForThemes } from 'qs-data/util';
import { getI18N } from '../../i18N';

const ThemesColorPicker = () => {
  const { t } = getI18N();
  const history = useHistory();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [selectedColors, setSelectedColors] = useState(null);
  const [selectedColorPicker, setSelectedColorPicker] = useState(null);
  const cachedCompanyDetails = CacheRequest.getCacheForKey(CacheKeys.companyDetails) || {};
  const companyDetails = prepareCompanyDetailsForThemes(cachedCompanyDetails);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#141922' : '#4DA47A',
        color: '#FFFFFF',
        title: !selectedColorPicker ? t('choose_color') : t('choose_title_color', { title: selectedColorPicker.title }),
        hideBack: desktop,
        hide: true,
      },
    });
    setNavigationBarColor('#212934');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedColorPicker]);

  useEffect(() => {
    const state = history.location.state || {};
    if (state.selectedColors) {
      setSelectedColors(state.selectedColors);
    }
    if (state.selectedColorPicker) {
      setSelectedColorPicker(state.selectedColorPicker);
    }
    const mainAppElement = document.getElementById('App-main');
    mainAppElement.style.paddingTop = 0; //remove empty navbar

    return () => {
      mainAppElement.style.paddingTop = '4rem'; //reinstate navbar padding
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedColorPicker || !selectedColors) {
    return null;
  }

  const onColorChanged = (updatedColor) => {
    const updatedColorVal = {};
    updatedColorVal[selectedColorPicker.key] = updatedColor;
    setSelectedColors((oldState) => ({ ...oldState, [selectedColorPicker.key]: updatedColor }));

    CacheRequest.setCacheForKey(selectedColorPicker.key, updatedColor);
    CacheRequest.setCacheForKey(CacheKeys.colorUpdatedToggle, true);
  };

  const renderColorPickerSliderSection = () => {
    return (
      <div className="pickerContainer">
        <Ripples className="backButtonContainer" onClick={onCancel}>
          <BackIcon fill="#FFF" width="15" height="15" />
          <p className={'back'}>{t('back')}</p>
        </Ripples>
        <HSLColorPicker selectedColorPicker={selectedColorPicker} onColorChanged={onColorChanged} />
      </div>
    );
  };

  const renderPreviewSection = () => {
    return <ThemeSectionPreview {...companyDetails} theme={selectedColors} />;
  };

  const onCancel = () => {
    history.goBack();
  };

  return (
    <div className="themesColorPickerContainer">
      {renderPreviewSection()}
      {renderColorPickerSliderSection()}
    </div>
  );
};

export default withRouter(ThemesColorPicker);
