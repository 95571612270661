import { useRef, useCallback } from 'react';

const useTimer = (initialState = 0) => {
  const timer = useRef(initialState);
  const countRef = useRef(null);

  const handleStart = useCallback(() => {
    countRef.current = setInterval(() => {
      timer.current = timer.current + 1;
    }, 1000);
  }, []);

  const handlePause = useCallback(() => {
    clearInterval(countRef.current);
  }, []);

  const handleReset = useCallback(() => {
    clearInterval(countRef.current);
    timer.current = 0;
  }, []);

  return {
    timer,
    handleStart,
    handlePause,
    handleReset,
  };
};

export default useTimer;
