import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

let apis = {
  getProductTypeEndPoint: '/v1/product-type-rate/all',
  setProductTypeEndPoint: '/v1/product-type-rate/product-type',
};

const getProductType = () => {
  return ApiWrapper.getDataPromise(
    apis.getProductTypeEndPoint,
    'productType',
    CacheKeys.productType,
    ''
  );
};

const setProductType = (data) => {
  return ApiWrapper.postDataPromise(apis.setProductTypeEndPoint, data, CacheKeys.productType);
};

export default {
  getProductType,
  setProductType,
};
