import React from 'react';
import "./style.scss";
import { getI18N } from '../../i18N';

export default function ({ errorMessage, onRetryClick, style }) {
    const { t } = getI18N();
    return <div className='errorMessageContainer' style={style}>
        <span className='errorMessage'>
            {errorMessage ? errorMessage : t('something_went_wrong')}
        </span>
        {onRetryClick ? <span>,
            <span className='retryButton' onClick={onRetryClick}>
                {t('retry')}
            </span>
        </span> : null}
    </div>
} 