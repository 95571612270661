import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import es from './translations/es.json';
import pt from './translations/pt.json';
import hi from './translations/hi.json';

let i18N;

export function getI18N() {
  if (!i18N) {
    throw new Error('Called before initialization');
  }
  return i18N;
}

export default function i18NInit() {
  const searchParams = new URLSearchParams(window.location.href.split('?')[1]);
  const languageCode = searchParams.get('languageCode') || 'en';

  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: false,
      lng: languageCode,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        en: {
          translation: en,
        },
        en_US: {
          translation: en,
        },
        es: {
          translation: es,
        },
        es_ES: {
          translation: es,
        },
        pt: {
          translation: pt,
        },
        pt_PT: {
          translation: pt,
        },
        hi: {
          translation: hi,
        },
        hi_IN: {
          translation: hi,
        },
      },
    });

  i18N = i18n;
}
