import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { getToken } from 'qs-data/util';
import {
  ACCESS_LEVEL,
  ACCESS_LEVEL_META,
  ALLOWED_CUSTOMER,
  COUNT_TYPE,
  ENTITY_TYPE,
  renderCount,
  settingOptions,
} from './privacySettingConstants';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import network from 'qs-data/network';
import Toast from 'qs-common/Alerts/Toast';
import PrivacySettingsCache from './PrivacySettingsCache';
import DefaultAccessLevelIcon from 'qs-assets/images/DEFAULT.png';
import PrivateAccessLevelIcon from 'qs-assets/images/PRIVATE.png';
import SemiPrivateAccessLevelIcon from 'qs-assets/images/SEMI_PRIVATE.png';
import StopHandIcon from 'qs-assets/images/stop-hand-circle.png';
import StopHandFilledIcon from 'qs-assets/images/stop-hand-circle-filled.png';
import DialogBox from 'qs-common/DialogBox';
import RightArrowIcon from 'qs-assets/icons/RightArrowIcon';
import Loader from 'qs-common/Loader';
import CacheKeys from './CacheKeys';
import { getI18N } from '../../i18N';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';

const PrivacySettings = () => {
  const { t } = getI18N();
  const history = useHistory();
  const [privacySettings, setPrivacySettings] = useState(
    PrivacySettingsCache.getCacheForKey(CacheKeys.COMPANY_PRIVACY_SETTINGS) || {}
  );
  const [accessLevel, setAccessLevel] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [progressBar, setProgressBar] = useState(false);

  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');

  const [, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#252c36',
        color: '#FFFFFF',
        boxShadow: false,
        enableBoxShadow: false,
        hideBack: true,
      },
    });
    setNavigationBarColor('#252C36');
    setStatusBarColor('#242C36');
  }, [dispatch]);

  useEffect(() => {
    getGlobalPrivacySettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGlobalPrivacySettings = async () => {
    if (!PrivacySettingsCache.getCacheForKey(CacheKeys.COMPANY_PRIVACY_SETTINGS)) {
      setLoading(true);
    }
    try {
      const data = (await network.getPrivacySettings()) || {};
      PrivacySettingsCache.setCacheForKey(CacheKeys.COMPANY_PRIVACY_SETTINGS, data);
      setPrivacySettings(data);
      setAccessLevel(data.accessLevel);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const showGlobalGroups = (groupId) => {
    if (groupId === ACCESS_LEVEL.DEFAULT) {
      setShowDialog(true);
      setAccessLevel(privacySettings.accessLevel);
      return;
    }
    if (groupId === ALLOWED_CUSTOMER) {
      history.push({
        pathname: `/allowed-customer`,
        search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
      });
      return;
    }
    history.push({
      pathname: `/global-groups/${groupId}`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };
  const showGroupCount = (id) => {
    const group = settingOptions.find((group) => group.id === id) || {};
    const groupCount = privacySettings.meta[group.groupCountKey];
    if (id === ALLOWED_CUSTOMER) {
      return renderCount(groupCount, COUNT_TYPE.ALLOWED_CUSTOMER);
    }
    return renderCount(groupCount, COUNT_TYPE.GROUP);
  };

  const renderAccessIcon = (accessLevel) => {
    switch (accessLevel) {
      case ACCESS_LEVEL.DEFAULT:
        return DefaultAccessLevelIcon;
      case ACCESS_LEVEL.PRIVATE:
        return PrivateAccessLevelIcon;
      case ACCESS_LEVEL.SEMI_PRIVATE:
        return SemiPrivateAccessLevelIcon;
      default:
        return null;
    }
  };
  const onClose = () => {
    setShowDialog(false);
  };

  const changeAccessLevel = (accessLevelName) => {
    setAccessLevel(accessLevelName);
  };

  const setDefaultPrivacy = async () => {
    setProgressBar(true);
    try {
      await network.setAccessLevel({
        entityType: ENTITY_TYPE.COMPANY,
        accessLevel,
      });
      setProgressBar(false);
      setPrivacySettings({
        ...privacySettings,
        accessLevel,
      });
      PrivacySettingsCache.setCacheForKey(CacheKeys.COMPANY_PRIVACY_SETTINGS, {
        ...privacySettings,
        accessLevel,
      });
      onClose();
      history.goBack();
      setToastState({
        open: true,
        message: t('access_level_set_to', {
          accessLevel: t(ACCESS_LEVEL_META[accessLevel].displayName),
        }),
      });
    } catch (error) {
      history.goBack();
      setProgressBar(false);
      setToastState({
        open: true,
        message: t('could_not_update_the_access_level'),
      });
      onClose();
    }
  };

  const renderAccessLevels = () => {
    return Object.keys(ACCESS_LEVEL_META).map((accessLevelName, index) => {
      return (
        <div className="accessLevelRowContainer" key={index}>
          <div className="accessLevelRowHeader">
            <div className="accessLevelIconAndText">
              <img className="accessLevelRowIcon" src={renderAccessIcon(accessLevelName)} alt="access icon" />
              <p className="accessLevelText">
                {t(ACCESS_LEVEL_META[accessLevelName].displayName)}
              </p>
            </div>
            <Button
              className={`accessLevelSelectButton ${
                accessLevel === accessLevelName ? 'accessLevelSelectButtonSelected' : ''
              }`}
              onClick={() => changeAccessLevel(accessLevelName)}
            >
              <div className="buttonTitle">
                {accessLevel === accessLevelName ? t('selected') : t('select')}
              </div>
            </Button>
          </div>
          <div className="accessLevelDescription">
            {t(ACCESS_LEVEL_META[accessLevelName].description)}
          </div>
        </div>
      );
    });
  };

  const renderDefaultPrivacyDialogueBox = () => {
    return (
      <DialogBox
        type={'simple'}
        dialogId={'#default-privacy-info'}
        show={showDialog}
        onClose={onClose}
        width={400}
        showProgressBar={progressBar}
        primaryBtnText={t('set_default_privacy')}
        onSuccess={setDefaultPrivacy}
      >
        <div className="defaultPrivacyLevelContainer">
          <div className="defaultPrivacyLevelHeader">
            <img className="defaultPrivacyLevelIcon" src={StopHandFilledIcon} alt="blocked icon" />
            <div className="defaultPrivacyLevelHeaderText">{t('default_privacy_level')}</div>
          </div>
          <div className="defaultPrivacyLevelDescription">
            {t('select_the_default_privacy_level_for_new_catalogues')}
          </div>
          {renderAccessLevels()}
        </div>
      </DialogBox>
    );
  };
  if (loading) {
    return (
      <div className="loader">
        <Loader />;
      </div>
    );
  }
  return (
    <>
      <div className="privacySettingsContainer">
        <div className="header">
          <img src={StopHandIcon} className="icon" alt="blocked icon" />
          <div className="title">{t('privacy_settings')}</div>
          <div className="description">{t('control_access_to_your_catalogues')}</div>
        </div>
        {privacySettings &&
          Object.keys(privacySettings).length > 0 &&
          privacySettings.meta &&
          Object.keys(privacySettings.meta).length > 0 &&
          settingOptions.map(({ id, title, description, styles }) => {
            return (
              <div
                className="optionContainer"
                style={{ ...styles.card }}
                key={id}
                onClick={() => showGlobalGroups(id)}
              >
                <div className="optiontitle" style={{ ...styles.title }}>
                  {t(title)}
                </div>
                <div className="optionDescription" style={{ ...styles.subtitle }}>
                  {t(description)}
                </div>
                <div className="optionGroupCountAndManageButton">
                  {id !== 'DEFAULT' ? (
                    <div className="count" style={{ ...styles.countColor }}>
                      {showGroupCount(id)}
                    </div>
                  ) : (
                    Object.keys(privacySettings).length > 0 &&
                    privacySettings.accessLevel && (
                      <div className="accessLevel">
                        <img
                          className="accessLevelIcon"
                          src={renderAccessIcon(privacySettings.accessLevel)}
                          alt="access icon"
                        />
                        <p className="accessLevelName">
                          {t(ACCESS_LEVEL_META[privacySettings.accessLevel].displayName)}
                        </p>
                      </div>
                    )
                  )}
                  <Button className="manageButton" style={{ ...{ ...styles.manage } }}>
                    <div className="buttonTitle" style={{ ...{ ...styles.manageText } }}>
                      {t('manage')}
                    </div>
                    <RightArrowIcon size={18} color={styles.manageButtonArrowColor} />
                  </Button>
                </div>
              </div>
            );
          })}
      </div>
      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={3000}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
      {showDialog && renderDefaultPrivacyDialogueBox()}
    </>
  );
};

export default PrivacySettings;
