import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, withRouter } from 'react-router-dom';
import Loader from '../../../common/Loader';
import Toast from '../../../common/Alerts/Toast';
import { useAppContext } from '../../../common/Contexts/AppContext';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import CustomerList from 'qs-components/CustomerList';
import Timer from 'qs-components/CustomerList/Timer';
import RightArrowIcon from 'qs-assets/icons/RightArrowIcon';
import DialogBox from 'qs-common/DialogBox';
import { AvTimer, Add } from '@material-ui/icons';
import CustomerListData from 'qs-data/customerList';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { createQueryParams } from 'qs-data/util';
import { getToken } from 'qs-data/util';
import { intervalToDuration, isValid } from 'date-fns';
import Ripples from 'react-ripples';
import './style.scss';
import ContactPermission from '../ContactPermission';
import { getContactListMap, getNameFromContactList } from 'qs-helpers/contacts';
import usePhoneUtil from 'qs-common/Hooks/usePhoneUtil';
import { getI18N } from '../../../i18N';

const NAVBAR_HEIGHT = 55;

function AllowedCustomer() {
  const { t } = getI18N();
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [customersList, setCustomersList] = useState();
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldStopPaginating, setShouldStopPaginating] = useState(false);
  const [paginatedListLoading, setPaginatedListLoading] = useState(false);
  const [errorComponent, setErrorComponent] = useState(false);
  const [page, setPage] = useState(1);
  const [timerValue, setTimerValue] = useState();
  const [disableDialogButton, setDisableDialogButton] = useState();
  const [allowAll, setAllowAll] = useState(false);
  const [hasContactsPermission, setHasContactsPermission] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [expandSearchBox, setExpandSearchBox] = useState(false);

  const history = useHistory();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';

  const [, dispatch] = useAppContext();

  const { phoneUtil, loading: utilLoading } = usePhoneUtil();

  const onSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearchExpand = useCallback(() => {
    setExpandSearchBox(!expandSearchBox);
    setSearchText('');
  }, [expandSearchBox]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#4DA47A',
        color: '#FFFFFF',
        title: t('allowed_customer_list'),
        placeholder: t('search_your_contacts'),
        searchable: true,
        expandSearchBox: expandSearchBox,
        onClick: onSearchExpand,
        onChange: (e) => onSearch(e),
        value: searchText,
        hideBack: false,
        height: NAVBAR_HEIGHT,
        warning: !expandSearchBox,
        warningClick: () =>
          history.push({
            pathname: `/expired-users`,
            search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
          }),
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#252c36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, expandSearchBox, onSearch, onSearchExpand]);

  let POP_STATE_COUNTER = 0;

  const popStateListener = () => {
    if (POP_STATE_COUNTER === 0) {
      POP_STATE_COUNTER++;
    } else {
      onClose();
      POP_STATE_COUNTER = 0;
    }
  };

  const onScrollHandler = () => {
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    if (window.scrollY >= scrollableHeight && !paginatedListLoading && !shouldStopPaginating) {
      setPaginatedListLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', popStateListener);
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.removeEventListener('popstate', popStateListener);
      window.removeEventListener('scroll', onScrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStopPaginating, paginatedListLoading, page]);

  const removeRow = ({ id, isItRequest }) => {
    if (isItRequest) {
      setTimeout(() => {
        getAllowedList({ addingMember: true });
      }, 1000);
    }

    if (!isItRequest) {
      const updatedList = customersList.members.filter((customer) => {
        if (customer.id !== id) {
          return customer;
        }
        return undefined;
      });
      setCustomersList({
        ...customersList,
        members: updatedList,
      });
      setToastState({
        open: true,
        message: t('access_revoked_successfully'),
      });
    }
  };

  const onClose = () => {
    setDialog(false);
  };

  const getAllowedList = useCallback((addingMember) => {
    let query;

    if (!customersList || !customersList.members.length || addingMember) {
      setLoading(true);
      query = createQueryParams([{ key: 'returnAccessRequests', value: true }]);
    } else {
      query = createQueryParams([
        { key: 'returnAccessRequests', value: true },
        {
          key: 'lastAccessGivenTime',
          value: customersList.members
            ? [...customersList.members].splice(-1)[0].accessGivenTime
            : null,
        },
      ]);
    }

    if (
      customersList &&
      [...customersList.members].splice(-1)[0] &&
      ![...customersList.members].splice(-1)[0].accessGivenTime
    ) {
      setPaginatedListLoading(false);
      setLoading(false);
      return;
    }

    CustomerListData.getRecentCustomerList(query)
      .then(({ members, accessRequested }) => {
        setCustomersList({
          ...customersList,
          members:
            customersList && !addingMember ? customersList.members.concat(members) : members,
          accessRequested,
        });
        if (members.length === 0) {
          setShouldStopPaginating(true);
        }
      })
      .catch(() => {
        setErrorComponent(true);
        setToastState({
          open: true,
          message: t('an_error_occured_while_fetching_customer_list'),
        });
      }).finally(() => {
        setPaginatedListLoading(false);
        setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

  useEffect(() => {
    getAllowedList();
  }, [getAllowedList]);

  const handleAccessAll = () => {
    setDisableDialogButton(true);
    CustomerListData.allowAccessToAll({ accessTime: Number(timerValue) })
      .then(() => {
        setDialog(false);
        setDisableDialogButton(false);
        setAllowAll(false);
        setToastState({
          open: true,
          message: t('all_are_allowed_access_successfully'),
        });
        const isItRequest = true;
        removeRow({ isItRequest });
      })
      .catch(() => {
        setDialog(false);
        setDisableDialogButton(false);
        setAllowAll(false);
        setToastState({
          open: true,
          message: t('an_error_occured_while_providing_access_to_all'),
        });
      });
  };

  const handleSetTimer = (time) => {
    if (!isValid(new Date(time)) || !time) {
      return;
    }
    const duration = intervalToDuration({
      start: new Date(2001, 0, 1, 0, 0, 0),
      end: time,
    });
    const totalSeconds = duration.hours * 3600 + duration.minutes * 60 + duration.seconds;
    setTimerValue(totalSeconds);
  };

  const handleAllowAll = () => {
    setAllowAll(true);
    setDialog(true);
  };

  return loading || utilLoading ? (
    <div className="componentContainer">
      <Loader />
    </div>
  ) : errorComponent ? (
    <div className="componentContainer">
      <CustomErrorComponent onRetryClick={getAllowedList} />
    </div>
  ) : (
    <div className="allowedCustomer">
      <ContactPermission
        hasContactsPermission={hasContactsPermission}
        setHasContactsPermission={setHasContactsPermission}
      />
      {customersList &&
        customersList.accessRequested &&
        customersList.accessRequested.members &&
        customersList.accessRequested.members.length > 0 && (
          <div className="recentRequest">
            <div className="customerRowsContainer">
              {customersList.accessRequested.members.map((request, index) => {
                const phoneNumber = !!(request || {}).phones && !!request.phones[0] ? request.phones[0] : '';
                const name = getContactListMap().get(phoneNumber) || getNameFromContactList(phoneNumber, phoneUtil) || request.name || '';
                return (
                  <CustomerList
                    key={index}
                    id={request.id}
                    title={name}
                    subtitle={request.phones}
                    buttonText={t('allow')}
                    removeRow={removeRow}
                    timerIcon
                    closeIcon
                  />
                );
              })}
            </div>

            {customersList.accessRequested.total > 4 && (
              <div className="viewAllCustomers">
                <div
                  className="viewAll"
                  onClick={() =>
                    history.push({
                      pathname: `/pending-users`,
                      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
                    })
                  }
                >
                  <div>{t('view_all')} {customersList.accessRequested.total}</div>
                  <RightArrowIcon color="#4DA47A" size="35px" />
                </div>
                <div className="actionButtonAll">
                  <Button style={{ backgroundColor: '#4DA47A' }} onClick={() => handleAllowAll()}>
                    <span>{t('allow_all')}</span>
                  </Button>
                  <div className="timerIcon" onClick={() => setDialog(true)}>
                    <AvTimer />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      {(customersList && customersList.members && customersList.members.length > 0) && (
        <Fragment>
          <div className="titleRecentCustomers">
            {t('recently_allowed_customers')}
          </div>
          <div className="customerRowsContainer">
            {customersList.members.map((request, index) => {
              const phoneNumber = !!(request || {}).phones && !!request.phones[0] ? request.phones[0] : '';
              const name = getContactListMap().get(phoneNumber) || getNameFromContactList(phoneNumber, phoneUtil) || request.name || '';
              if (expandSearchBox && !name.toLowerCase().includes(searchText.toLowerCase())) {
                return null;
              }
              return (
                <CustomerList
                  key={index}
                  id={request.id}
                  title={name}
                  subtitle={request.phones}
                  accessTime={request.accessTime}
                  accessExpiryTime={request.accessExpiryTime}
                  accessGivenTime={request.accessGivenTime}
                  menuIcon
                  buttonText={request.accessTime ? 'Allow' : null}
                  removeRow={removeRow}
                />
              );
            })}
          </div>
          {(customersList.members.length > 1 && paginatedListLoading) && (
            <div className="rowLoading">
              <Loader small />
            </div>
          )}
        </Fragment>
      )}

      {customersList &&
        !(customersList.members && customersList.members.length > 0) &&
        !(customersList.accessRequested && customersList.accessRequested.members.length > 0) && (
          <div className="titleRecentCustomers noList">{t('no_requests')}</div>
        )}

      {!desktop && (
        <Ripples
          className="addCustomers"
          onClick={() =>
            history.push({
              pathname: `/select-customer`,
              search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
            })
          }
        >
          <Add />
        </Ripples>
      )}

      {dialog && (
        <DialogBox
          type={'simple'}
          show={dialog}
          onClose={onClose}
          width={350}
          primaryBtnText={t('allow_all')}
          secondaryBtnText={t('Cancel')}
          disabledPrimaryButton={disableDialogButton}
          onSuccess={handleAccessAll}
        >
          {!allowAll && <Timer accessTime={0} newAccessSetTime={handleSetTimer} />}
          {allowAll && (
            <div>
              <div className="timerTitle">{t('allow_access')}</div>
              <div className="timerContent">{t('give_all_permanent_access')}</div>
            </div>
          )}
        </DialogBox>
      )}

      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}

export default withRouter(AllowedCustomer);