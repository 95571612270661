import React, { useState } from 'react';
import payments from '../../../data/payments';
import YouTubeRow from './YouTubeRow';
import './style.css';
import useStyles from '../../../common/CustomStyles';
import CustomTextField from '../../../common/CustomTextField';
import Loader from '../../../common/Loader';
import Button from '@material-ui/core/Button';
import Toast from '../../../common/Alerts/Toast';
import { getI18N } from '../../../i18N';

export default function Paytm({ gatewayData }) {
  const [loading, setLoading] = useState(false);
  const _credentials = gatewayData.credentials || {};
  const [isActive, setIsActive] = useState(gatewayData['isActive'] || false);
  const [credentials, setCredentials] = useState({
    mid: _credentials.mid || '',
    secret_key: _credentials.secret_key || '',
  });

  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });

  const [errors, setErrors] = useState({});

  const classes = useStyles();

  const { t } = getI18N();

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCredentials((credentials) => {
      return {
        ...credentials,
        [name]: value,
      };
    });
    setErrors({});
  };

  const validateForm = () => {
    const _errors = {};
    Object.keys(credentials).forEach((element) => {
      if (credentials[element] === '') {
        _errors[element] = t('this_field_cannot_be_left_blank');
      }
    });
    setErrors(_errors);
    return Object.keys(_errors).length;
  };

  const onSubmit = () => {
    let numberErrors = validateForm();

    if (numberErrors) {
      return;
    }
    setLoading(true);
    payments
      .enablePayTM(credentials)
      .then(() => {
        setErrors({});
        setLoading(false);
        setIsActive(true);
        setToastState({
          message: t('payment_gateway_enabled'),
          open: true,
        });
      })
      .catch((error) => {
        let message = t('an_error_occured');

        if (typeof error === 'object' && error.message) {
          message = error.message;
        }

        setErrors({});
        setLoading(false);
        setToastState({
          message,
          open: true,
        });
      });
  };

  return (
    <div className="PaytmContainer">
      <div className="introContainer">
        <div className="introText">
          {t('please_enter_your_paytm_credentials_in_the_boxes_below_you_will_find_these')}
        </div>

        <a
          className="PaytmLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://dashboard.paytm.com/next/apikeys"
        >
          {t('click_here_to_access_paytm')}
        </a>

        {gatewayData.youtubeTutorialVideoId && (
          <YouTubeRow videoId={gatewayData.youtubeTutorialVideoId} />
        )}
      </div>

      <form className={classes.root} noValidate autoComplete="off">
        <CustomTextField
          fullWidth
          label={t('merchant_id')}
          margin="normal"
          value={credentials.mid}
          name="mid"
          onChange={onChange}
          error={errors['mid']}
          helperText={errors['mid']}
          disabled={isActive}
        />
        <CustomTextField
          fullWidth
          label={t('merchant_key_secret_key')}
          value={credentials.secret_key}
          name="secret_key"
          onChange={onChange}
          error={!!errors['secret_key']}
          helperText={errors['secret_key']}
          disabled={isActive}
        />

        {loading ? (
          <div style={{ marginLeft: 24, marginTop: 24 }}>
            <Loader small />
          </div>
        ) : isActive ? (
          <div>
            <Button
              variant="contained"
              size="large"
              className={classes.buttonDisable}
              onClick={() => {
                setLoading(true);
                payments.disablePayTM().then(() => {
                  setIsActive(false);
                  setLoading(false);
                  setToastState({
                    message: t('payment_gateway_disabled'),
                    open: true,
                  });
                });
              }}
            >
              {t('disable')}
            </Button>
          </div>
        ) : (
          <Button variant="contained" size="large" className={classes.button} onClick={onSubmit}>
            {t('enable')}
          </Button>
        )}
      </form>

      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}
