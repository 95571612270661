import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import MinimumOrderPrice from 'qs-components/Shipping/Rates/MinimumOrderPrice';
import WeightProfilesWithPrices from 'qs-components/Shipping/Rates/WeightProfilesWithPrices';
import Utility from 'qs-helpers/utility';
import ShippingData from 'qs-data/shipping';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { getI18N } from '../../i18N';
import './style.scss';

const ShippingPricing = () => {
  const history = useHistory();
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [progressBar, setProgressBar] = useState(true);
  const [currencyUnit, setCurrencyUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [errorComponent, showErrorComponent] = useState(false);
  const { t } = getI18N();

  const [title, setTitle] = useState(t('add_shipping_price'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const shippingData = history.location.state;
    if (shippingData !== null && shippingData !== undefined) {
      const mode = (shippingData || {}).mode || {};
      const zone = (shippingData || {}).zone || {};
      setWeightUnit(shippingData.weightUnit);
      setTitle(`${mode.value} - ${Utility.getShippingZoneDisplayName(zone)}`);
      fetchCurrencyUnit();
    }
  }, [history.location.state]);

  /* Fetch Currency api call */
  const fetchCurrencyUnit = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ShippingData.getCurrencyUnit()
      .then((unit) => {
        setCurrencyUnit(unit);
      })
      .catch(() => {
        showErrorComponent(true);
      })
      .then(() => {
        setProgressBar(false);
      });
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: title,
      },
    });
    setNavigationBarColor('#28303A');
    setStatusBarColor('#4DA47A');
  }, [dispatch, desktop, title]);

  return (
    <div className="shippingRateContainer">
      {progressBar ? (
        <CircularProgress
          size={24}
          color="primary"
          style={{ marginRight: '16px', marginTop: '8px' }}
        />
      ) : errorComponent ? (
        <CustomErrorComponent onRetryClick={fetchCurrencyUnit} />
      ) : (
        <div>
          <MinimumOrderPrice currencyUnit={currencyUnit} shipping={history.location.state} />
          <WeightProfilesWithPrices
            weightUnit={weightUnit}
            currencyUnit={currencyUnit}
            shipping={history.location.state}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(ShippingPricing);
