import React, { useRef, useState } from 'react';
import { isNil } from 'lodash';
import Ripples from 'react-ripples';
import { CircularProgress, TextField } from '@material-ui/core';
import FacebookPixelData from 'qs-data/facebookPixel';
import CustomLabel from 'qs-common/CustomLabel';
import Toast from 'qs-common/Alerts/Toast';
import './style.scss';
import { getI18N } from '../../i18N';

export default function FacebookPixelId({ pixelId }) {
  const inputRef = useRef();
  const { t } = getI18N();
  const [progressBar, setProgressBar] = useState(false);
  const [enableButton, setEnableButton] = useState(!isNil(pixelId));
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const onChange = () => {
    if (inputRef && inputRef.current && inputRef.current.value) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  };

  const setFacebookPixelCode = async () => {
    setProgressBar(true);
    FacebookPixelData.setFacebookPixelId(inputRef.current.value)
      .then(() => {
        setToastState({
          open: true,
          message: t('pixel_id_updated_successfully'),
        });
      })
      .then(() => {
        setProgressBar(false);
      });
  };

  return (
    <div className={'facebookIntegrationContainer'}>
      <CustomLabel value={t('facebook_pixel_id')} />
      <div className={'facebookPixelIdContainer'}>
        <TextField
          className="facebookPixelInput"
          inputRef={inputRef}
          onChange={onChange}
          defaultValue={pixelId}
        />
        {progressBar ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          <Ripples
            className={`saveButton ${!enableButton ? 'disable' : ''}`}
            onClick={enableButton ? setFacebookPixelCode : null}
          >
            <span>{t('save')}</span>
          </Ripples>
        )}
      </div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}
