import ApiWrapper from './apiWrapper';

import { PAGE_TYPE } from '../pages/OtherPages/constants';

const Api = {
  getAboutUsCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.ABOUT_US}`,
  setAboutUsCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.ABOUT_US}`,
  getPrivacyPolicyCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.PRIVACY_POLICY}`,
  setPrivacyPolicyCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.PRIVACY_POLICY}`,
  getTermsAndConditionsCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.TERMS_AND_CONDITIONS}`,
  setTermsAndConditionsCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.TERMS_AND_CONDITIONS}`,
  getRefundAndExchangeCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY}`,
  setRefundAndExchangeCompanyDetailsEndpoint: `/v1/company/company-details/${PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY}`,
  getCustomPagePreviewLinkEndpoint: '/v1/custom-page/link?id={customPageId}',
  getCustomPageSlugAvailabilityEndpoint: `/v1/custom-page/slug-available`,
  getCustomPagesEndpoint: `/v1/custom-page/all?skipHydrate=true&skipCreateIfNotExists=true`,
  createCustomPageEndpoint: `/v1/custom-page?skipHydrate=true`,
  editCustomPageEndpoint: `/v1/custom-page?skipHydrate=true&skipCreateIfNotExists=true`,
  deleteCustomPageEndpoint: `/v1/custom-page`,
};

/* other pages api */

const getOtherPageCompanyDetails = (pageType) => {
  switch (pageType) {
    case PAGE_TYPE.ABOUT_US: {
      return ApiWrapper.getDataPromise(Api.getAboutUsCompanyDetailsEndpoint, 'aboutUs', pageType);
    }
    case PAGE_TYPE.PRIVACY_POLICY: {
      return ApiWrapper.getDataPromise(
        Api.getPrivacyPolicyCompanyDetailsEndpoint,
        'privacyPolicy',
        pageType
      );
    }
    case PAGE_TYPE.TERMS_AND_CONDITIONS: {
      return ApiWrapper.getDataPromise(
        Api.getTermsAndConditionsCompanyDetailsEndpoint,
        'termsAndConditions',
        pageType
      );
    }
    case PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY: {
      return ApiWrapper.getDataPromise(
        Api.getRefundAndExchangeCompanyDetailsEndpoint,
        'refundAndExchangePolicy',
        pageType
      );
    }
    default: {
      return undefined;
    }
  }
};

const setOtherPageCompanyDetails = (pageType, value) => {
  switch (pageType) {
    case PAGE_TYPE.ABOUT_US: {
      return ApiWrapper.postDataPromise(Api.setAboutUsCompanyDetailsEndpoint, { value }, pageType);
    }
    case PAGE_TYPE.PRIVACY_POLICY: {
      return ApiWrapper.postDataPromise(
        Api.setPrivacyPolicyCompanyDetailsEndpoint,
        { value },
        pageType
      );
    }
    case PAGE_TYPE.TERMS_AND_CONDITIONS: {
      return ApiWrapper.postDataPromise(
        Api.setTermsAndConditionsCompanyDetailsEndpoint,
        { value },
        pageType
      );
    }
    case PAGE_TYPE.REFUND_AND_EXCHANGE_POLICY: {
      return ApiWrapper.postDataPromise(
        Api.setRefundAndExchangeCompanyDetailsEndpoint,
        { value },
        pageType
      );
    }
    default: {
      return undefined;
    }
  }
};

const getCustomPagePreviewLink = ({ customPageId }) => {
  return ApiWrapper.getDataPromise(Api.getCustomPagePreviewLinkEndpoint.replace('{customPageId}', customPageId));
}

const getCustomPageSlugAvailability = ({ customPageId, customPageSlug }) => {
  return ApiWrapper.postDataPromise(Api.getCustomPageSlugAvailabilityEndpoint, { customPageId, customPageSlug });
}

const getCustomPages = () => {
  return ApiWrapper.getDataPromise(Api.getCustomPagesEndpoint);
};

const createCustomPage = ({ title, isFrontPage, isPublished, defaultSlug }) => {
  return ApiWrapper.postDataPromise(Api.createCustomPageEndpoint, { title, isFrontPage, isPublished, defaultSlug });
};

const editCustomPage = ({ id, title, isFrontPage, isPublished, defaultSlug }) => {
  return ApiWrapper.postDataPromise(Api.editCustomPageEndpoint, { id, title, isFrontPage, isPublished, defaultSlug });
};

const deleteCustomPage = ({ id }) => {
  return ApiWrapper.deleteDataPromise(Api.deleteCustomPageEndpoint, { id });
};

export default {
  getOtherPageCompanyDetails,
  setOtherPageCompanyDetails,
  getCustomPagePreviewLink,
  getCustomPageSlugAvailability,
  getCustomPages,
  createCustomPage,
  editCustomPage,
  deleteCustomPage
};
