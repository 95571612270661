import React from 'react';

export default () => (
  <svg
    width="16px"
    height="15px"
    viewBox="0 0 16 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons8-star</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android-Copy-5"
        transform="translate(-26.000000, -625.000000)"
        fill="#EDD25C"
        fillRule="nonzero"
      >
        <g id="icons8-star" transform="translate(26.000000, 625.000000)">
          <polygon
            id="Path"
            points="7.895 12.055 12.7742857 15 11.4792857 9.45 15.79 5.71571429 10.1135714 5.23428571 7.895 0 5.67642857 5.23428571 0 5.71571429 4.31071429 9.45 3.01571429 15"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
