import React from 'react';
import { SHIPPING_PROVIDERS } from '../pages/ShippingModeSettings/helpers';
import { ReactComponent as PayTMIcon } from '../assets/images/paytm.svg';
import { ReactComponent as PayPalIcon } from '../assets/images/paypal.svg';
import { ReactComponent as PhonePeIcon } from '../assets/images/phonepe.svg';
import { ReactComponent as StripeIcon } from '../assets/images/stripe.svg';
import { ReactComponent as RazorpayIcon } from '../assets/images/master_card.svg';
import { ReactComponent as CODIcon } from '../assets/images/cod.svg';
import { ReactComponent as MercadoIcon } from '../assets/images/mercadopago.svg';
import CustomShippingIcon from 'qs-assets/icons/CustomShippingIcon';
import ShiprocketIcon from 'qs-assets/images/shiprocket-icon.png';

function Icon(props = {}) {
  function getIconForId(id) {
    const _id = id.toString().toUpperCase();
    switch (_id) {
      case 'RAZORPAY':
        return <RazorpayIcon {...props} />;
      case 'PAYPAL':
        return <PayPalIcon {...props} />;
      case 'PAYTM':
        return <PayTMIcon {...props} />;
      case 'CASH_ON_DELIVERY':
        return <CODIcon {...props} />;
      case 'MERCADO_PAGO':
        return <MercadoIcon {...props} />;
      case 'PARTIAL_COD':
        return <CODIcon {...props} />;
      case 'PHONEPE':
        return <PhonePeIcon {...props} />;
      case 'STRIPE':
        return <StripeIcon {...props} />;
      case SHIPPING_PROVIDERS.CUSTOM:
        return <CustomShippingIcon {...props} />;
      case SHIPPING_PROVIDERS.SHIPROCKET: {
        const { width, height, ...restProps } = props;
        return (
          <img
            {...restProps}
            style={{ width: width || '18px', height: height || '18px' }}
            src={ShiprocketIcon}
            alt=""
          />
        );
      }
      default:
        return null;
    }
  }
  return props.id ? getIconForId(props.id) : null;
}

export default Icon;
