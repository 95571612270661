import { getCheckoutFormFieldsDataCacheKey } from '../pages/CheckoutForm/helpers';
import ApiWrapper from './apiWrapper';

const apis = {
  checkoutFormFieldEndpoint: '/v1/checkout-field',
  reorderCheckoutFormFieldEndpoint: '/v2/checkout-field/re-order'
};

/* Checkout fields apis */

const getCheckoutFields = () => {
  return ApiWrapper.getDataPromise(apis.checkoutFormFieldEndpoint, 'fields' ,getCheckoutFormFieldsDataCacheKey())
};

const addCheckoutField = newfield => {
  return ApiWrapper.postDataPromise(apis.checkoutFormFieldEndpoint, { fieldsData: [newfield] });
};

const updateCheckoutField = updatedField => {
  return ApiWrapper.putDataPromise(apis.checkoutFormFieldEndpoint, { fieldData: updatedField });
};

const deleteCheckoutField = fieldId => {
  return ApiWrapper.deleteDataPromise(apis.checkoutFormFieldEndpoint, { fieldIds: [ fieldId ] });
};

const reorderCheckoutFields = updatedFieldPositions => {
  return ApiWrapper.postDataPromise(apis.reorderCheckoutFormFieldEndpoint, { updatedFieldPositions });
}

export default {
  getCheckoutFields,
  addCheckoutField,
  updateCheckoutField,
  deleteCheckoutField,
  reorderCheckoutFields
}