export default {
  token: 'token',
  analyticFunc: 'analyticFunc',
  collectShippingCostStatusKey: 'collect-shipping-cost',
  shippingHomeLocationKey: 'shipping-home-location',
  shippingWeightUnitKey: 'shipping-weight-unit',
  shippingModesCacheKey: 'shipping-modes',
  availableShippingProviders: 'available-shipping-providers',
  selectedShippingProvider: 'selected-shipping-provider',
  shippingCredentials: 'shipping-Credentials',
  shiprocketEnteredCredentials: 'shiprocketEnteredCredentials',
  shippingZonesCacheKey: 'shipping-zones',
  shippingWeightProfilesCacheKey: 'shipping-weight-profiles',
  shippingProfilesCacheKey: 'shipping-profiles',
  referralCodeAndOffer: 'referral-code-and-offer',
  facebookPixelId: 'facebook-pixel-id',
  googleAnalyticsId: 'google-analytics-id',
  googleTagManagerFieldId: 'googleTagManagerFieldId',
  googleAnalyticsMeasurementId: 'googleAnalyticsMeasurementId',
  metalRates: 'metal-rates',
  productType: 'product-type',
  defaultTheme: 'defaultTheme',
  allThemes: 'allThemes',
  selectedThemeId: 'selectedThemeId',
  selectedTheme: 'selectedTheme',
  companyDetails: 'companyDetails',
  name: 'name',
  primaryColor: 'primaryColor',
  contrastTextColor: 'contrastTextColor',
  pageTextColor: 'pageTextColor',
  backgroundColor: 'backgroundColor',
  colorUpdatedToggle: 'colorUpdatedToggle',
  nullEntryAddedToHistory: 'nullEntryAddedToHistory',
  customVariant: 'customVariant',
  companyCoupon: 'company-coupons',
  geoConfig: 'geoConfig',
  referralPageView: 'referralPageView',
  nativeAppDetails: 'native-app-details',
  nativeAppPushNotification: 'native-app-push-notification',
  nativePushNotificationList: 'native-push-notification-list',
};
