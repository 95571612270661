import { useEffect, useRef } from 'react';

export default ({ onBackClick, shouldTrap, routeChanged }) => {
  const backDetectProps = useRef({
    onBackClickFunc: null,
    backButtonClicked: false,
    routeChanged: false,
    historyCopied: false,
  });
  backDetectProps.current.onBackClickFunc = onBackClick;
  backDetectProps.current.routeChanged = routeChanged;

  useEffect(() => {
    if (!shouldTrap) {
      // NOTE: Return a function, because the one returned before is called again. Maybe a bug in preact
      return () => {};
    }

    const preventBackButton = function () {
      backDetectProps.current.backButtonClicked = true;
      backDetectProps.current.historyCopied = false;
      window.removeEventListener('popstate', preventBackButton);
      // Push to end of the queue to allow all immediate processing to complete
      // and get a clean call to the back clicked callback
      setTimeout(() => {
        backDetectProps.current.onBackClickFunc();
      }, 0);
    };

    // Push the current URL so that on back click the current URL does not unmount
    if (!backDetectProps.current.historyCopied) {
      window.history.pushState(null, document.title, window.location.href);
      backDetectProps.current.historyCopied = true;
    }
    window.addEventListener('popstate', preventBackButton);
    return () => {
      window.removeEventListener('popstate', preventBackButton);
      // Back handler not clicked, so manually remove the added history state
      // Only go back if route has not changed in between due to another push
      if (!backDetectProps.current.backButtonClicked && !backDetectProps.current.routeChanged) {
        window.history.go(-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        backDetectProps.current.historyCopied = false;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTrap]);
};
