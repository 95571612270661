import React from 'react';
import './styles.scss';

export default function Image({ src, big }) {
  return (
    <div className={big ? 'product-row-image product-row-image-big' : 'product-row-image'}>
      <img src={src} alt="product" />
    </div>
  );
}
