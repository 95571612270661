import React, { useState, useCallback } from "react";
import Loader from "../../../common/Loader";
import { Button } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, isToday } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Network from "../../../data/network";
import Toast from "../../../common/Alerts/Toast";
import "./style.scss";
import Analytics from "qs-data/analytics";
import { getI18N } from "../../../i18N";

const useStyles = makeStyles({
  statusBtn: {
    fontSize: 16,
    fontWeight: 500,
    padding: 2,
    maxWidth: 76,
    marginBottom: "1rem",
  },
  dateField: {
    border: "2px solid #515c6d",
    borderRadius: 4,
    paddingLeft: 6,
  },
  recieveBtn: {
    background: "#31BD84",
    color: "#FFFFFF",
    padding: "0.75rem",
    marginBottom: "2rem",
    marginTop: "1rem",
    width: 154,
    "&:hover": {
      background: "#57dd9c",
    },
    textTransform: "uppercase"
  },
  disabled: {
    opacity: "0.5",
    cursor: "not-allowed",
    "&:hover": {
      background: "#57dd9c",
    },
  },
});

const TOAST_DURATION = 3000;

export default function Reports({
  source,
  reportType,
  trackEvent,
  email: emailId,
  showDialogue,
}) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sendingReport, setSending] = useState(false);
  const [email, setEmail] = useState(emailId);
  const [toastState, setToastState] = useState({
    open: false,
    message: "",
  });

  const { t } = getI18N();

  const getEmailId = async () => {
    setSending(true);
    try {
      const result = await Network.getDailyReportStatus();
      setEmail(result && result.email ? result.email : "");
      setSending(false);
      return result && result.email ? result.email : "";
    } catch (error) {
      setSending(false);
    }
    return null;
  };

  const onRecieveClick = useCallback(() => {
    Analytics.trackEvent({
      eventName: trackEvent,
      source: source === "desktop" ? "web" : "android",
    });
    const callApi = async () => {
      setSending(true);
      try {
        const onlyDate = format(new Date(endDate), "MM/dd/yyyy").split("/");

        const finalDate = isToday(endDate)
          ? new Date().getTime()
          : new Date(
              onlyDate[2],
              onlyDate[0] - 1,
              onlyDate[1],
              23,
              59,
              59
            ).getTime();

        if (reportType === "sales-report") {
          await Network.getSalesReport({
            startDate: new Date(
              format(new Date(startDate), "MM/dd/yyyy")
            ).getTime(),
            endDate: finalDate,
          });
        } else if (reportType === "visitor-report") {
          await Network.getVisitorReport({
            startDate: new Date(
              format(new Date(startDate), "MM/dd/yyyy")
            ).getTime(),
            endDate: finalDate,
          });
        }

        setToastState({
          open: true,
          message: t('you_will_receive_an_email_with_the_requested_report'),
        });
        setSending(false);
      } catch (error) {
        setSending(false);
        setToastState({
          open: true,
          message: t('failed_to_send_email'),
        });
      }
    };

    if (!email) {
      getEmailId().then((value) => {
        if (!value) {
          return showDialogue();
        } else {
          callApi();
        }
      });
    } else {
      callApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, email]);

  const changeStartDate = useCallback((time) => {
    setStartDate(time);
  }, []);

  const changeEndDate = useCallback((time) => {
    setEndDate(time);
  }, []);

  const validStart = startDate && startDate.toString() !== "Invalid Date";
  const validEnd = endDate && endDate.toString() !== "Invalid Date";
  const disabledBtn = !validEnd || !validStart ? classes.disabled : "";

  return (
    <div>
      <p className="report-heading">
        {t(reportType.replace(/-/g, '_'))}
      </p>
      <p className="report-info">
        {t('export_report_type_between_a_specified_time_period', { reportType : t(reportType.replace(/-/g, '_')) })}
      </p>
      <div className="dates-container">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="one-date">
            <p className="date-label">{t('start_date')}</p>
            <KeyboardDatePicker
              disableFuture
              openTo="date"
              format="dd-MM-yyyy"
              views={["year", "month", "date"]}
              className={classes.dateField}
              InputProps={{ disableUnderline: true }}
              onChange={changeStartDate}
              invalidDateMessage=""
              disableToolbar
              value={startDate}
            />
          </div>
          <p className="seperator">-</p>
          <div className="one-date">
            <p className="date-label">{t('end_date')}</p>
            <KeyboardDatePicker
              disableFuture
              openTo="date"
              format="dd-MM-yyyy"
              views={["year", "month", "date"]}
              className={classes.dateField}
              InputProps={{ disableUnderline: true }}
              onChange={changeEndDate}
              invalidDateMessage=""
              disableToolbar
              value={endDate}
              minDate={new Date(startDate)}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
      {sendingReport ? (
        <div className="reportBtnLoader">
          <Loader small style={{ margin: 10 }} />
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={onRecieveClick}
          className={`${classes.recieveBtn} ${disabledBtn}`}
        >
          {t('receive_report')}
        </Button>
      )}
      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={TOAST_DURATION}
        onClose={() => {
          setToastState({
            open: false,
            message: "",
          });
        }}
      />
    </div>
  );
}
