import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import MerchantIcon from 'qs-assets/images/Bitmap@2x.png';
import GoogleLogo from 'qs-assets/images/google.png';
import Loader from 'qs-common/Loader';
import network from 'qs-data/network';
import { getToken } from 'qs-data/util';
import './style.css';
import Toast from 'qs-common/Alerts/Toast';
import config from '../../config';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { getI18N } from '../../i18N';

const NAVBAR_HEIGHT = 55;

const GoogleSearchConsole = () => {
  const { t } = getI18N();
  const history = useHistory();
  const [client, setClient] = useState(null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('gscToken') || null);
  const [, dispatch] = useAppContext();
  const [domain, setDomain] = useState(null);
  const { url } = useRouteMatch();
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#222B36' : '#4DA47A',
        color: '#FFFFFF',
        title: t('google_search_console'),
        height: NAVBAR_HEIGHT,
        boxShadow: desktop ? 'none' : undefined,
        hideBack: desktop,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#212934');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  useEffect(() => {
    async function getLink() {
      localStorage.setItem('selectedDomain', null);
      let data = await network.getDomain();
      if (data.length > 0) {
        let tmpDomains = data.filter((x) => x.domainStatus === 'connected');
        if (tmpDomains && tmpDomains.length > 0) {
          let connectedCustom = tmpDomains.filter((x) => x.type === 'custom');
          if (connectedCustom && connectedCustom.length > 0) {
            setDomain(connectedCustom[0].domainName);
            localStorage.setItem('selectedDomain', connectedCustom[0].domainName);
          } else {
            let connectedCatalog = tmpDomains.filter((x) => x.type === 'catalog');
            setDomain(connectedCatalog[0].domainName);
            localStorage.setItem('selectedDomain', connectedCatalog[0].domainName);
          }
        }
      }
    }
    getLink();
  }, []);

  const onSuccessLogin = (accessToken) => {
    localStorage.setItem('gscToken', accessToken);
    history.replace({
      pathname: `${url}/details`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };

  useEffect(() => {
    if (window.google) {
      let tmpClient = window.google.accounts.oauth2.initTokenClient({
        client_id: `${config.googleClientId}.apps.googleusercontent.com`,
        scope:
        // eslint-disable-next-line no-multi-str
          'https://www.googleapis.com/auth/siteverification \
        https://www.googleapis.com/auth/webmasters \
        https://www.googleapis.com/auth/webmasters.readonly',
        callback: (tokenResponse) => {
          if (
            window.google.accounts.oauth2.hasGrantedAllScopes(
              tokenResponse,
              'https://www.googleapis.com/auth/siteverification',
              'https://www.googleapis.com/auth/webmasters',
              'https://www.googleapis.com/auth/webmasters.readonly'
            )
          ) {
            setAccessToken(tokenResponse.access_token);
            setToastState({
              open: true,
              message: t('login_success'),
            });
            onSuccessLogin(tokenResponse.access_token);
            return;
          }
          window.google.accounts.oauth2.revoke(accessToken, () => {
            setAccessToken(null);
          });
          setToastState({
            open: true,
            message: t('please_grant_all_permissions'),
          });
        },
      });
      setClient(tmpClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* commented codes will be used later on when backend is ready */
  // useEffect(() => {
  //   if (window.google) {
  //     let tmpClient = window.google.accounts.oauth2.initCodeClient({
  //       client_id: config.gsaClientId,
  //       scope: 'https://www.googleapis.com/auth/siteverification \
  //       https://www.googleapis.com/auth/webmasters \
  //       https://www.googleapis.com/auth/webmasters.readonly',
  //       ux_mode: 'popup',
  //       state: 'asas217831trgdv287od',
  //       // redirect_uri: 'https://harsh.dev-2.quicksell.co',
  //       access_type: 'offline',
  //       callback: (tokenResponse) => {
  //         console.log(tokenResponse)
  //       },
  //     })
  //     setClient(tmpClient)
  //   }
  // }, []);

  // useEffect(() => {
  //   if (accessToken) {
  //     history.replace({
  //       pathname: `${url}/details`,
  //       search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
  //     });
  //   }
  // }, [])

  const onClickButton = () => {
    if (!client || !client.requestAccessToken) {
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
      return;
    }
    client.requestAccessToken();
  };

  const renderImage = () => {
    return (
      <div className="centerLoader" style={{ width: '150px', height: '150px' }}>
        {imageLoaded ? null : <Loader />}
        <img
          src={MerchantIcon}
          style={imageLoaded ? {} : { display: 'none' }}
          alt={t('google_logo')}
          width="150px"
          height="150px"
          onLoad={() => setImageLoaded(true)}
        />
      </div>
    );
  };

  const closeToast = () => {
    setToastState({ open: false, message: '' });
  };

  return (
    <div className="gscParentContainer">
      <div className="gscContainer">
        <div className="imageContainer">{renderImage()}</div>
        {domain ? (
          <div className="gscSignInContainer">
            <p className="gscDesc">{t('use_this_tool_to_for_better_seo_optimisations')}</p>
            <button onClick={() => onClickButton(accessToken)} className="gscSignInBtn">
              <div className="gscGoogleLogoContainer">
                <img src={GoogleLogo} alt={t('google_logo')} />
              </div>
              <span className="googleSignin">{t('sign_in_with_google')}</span>
            </button>
          </div>
        ) : (
          <p className="gscDomainError">
            {t('please_connect_a_domain_to_your_account_to_use_this_feature')}
          </p>
        )}
      </div>
      <Toast open={toastState.open} message={toastState.message} onClose={closeToast} />
    </div>
  );
};
export default withRouter(GoogleSearchConsole);
