export const JEWELLERY_PRICE_DISPLAY_KEYS = {
  HIDE_METAL_NET_WEIGHT_PER_GRAM: 'hideMetalNetWeightPerGram',
  HIDE_METAL_GROSS_WEIGHT_PER_GRAM: 'hideMetalGrossWeightPerGram',
  HIDE_METAL_RATE_PER_GRAM: 'hideMetalRatePerGram',
  HIDE_MAKING_CHARGES_SECTION: 'hideMakingChargesSection',
  HIDE_MAKING_CHARGES_WEIGHT: 'hideMakingChargesWeight',
  HIDE_MAKING_CHARGES_RATE: 'hideMakingChargesRate',
  HIDE_DIAMOND_STONE_WEIGHT: 'hideDiamondStoneWeight',
  HIDE_DIAMOND_STONE_RATE: 'hideDiamondStoneRate',
  HIDE_OTHER_CHARGES_SECTION: 'hideOtherChargesSection',
  HIDE_WASTAGE_CHARGES_SECTION: 'hideWastageChargesSection',
  HIDE_PRICE_SUMMARY_SECTION: 'hidePriceSummarySection',
  HIDE_METAL_SUMMARY_SECTION: 'hideMetalSummarySection',
  HIDE_STONE_SUMMARY_SECTION: 'hideStoneSummarySection',
  HIDE_PRICE_SUMMARY_SUB_TOTAL: 'hidePriceSummarySubTotal',
  HIDE_PRICE_SUMMARY_DISCOUNT: 'hidePriceSummaryDiscount',
  HIDE_PRICE_SUMMARY_TAXES: 'hidePriceSummaryTaxes',
  HIDE_PRICE_SUMMARY_TOTAL: 'hidePriceSummaryTotal',
};

export const JEWELLERY_PRICE_DISPLAY_TITLES = {
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_NET_WEIGHT_PER_GRAM]: 'metal_net_weight_per_gram',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_GROSS_WEIGHT_PER_GRAM]: 'metal_gross_weight_per_gram',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_RATE_PER_GRAM]: 'metal_rate_per_gram',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_SECTION]: 'making_charges_section',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_WEIGHT]: 'making_charges_weight',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_RATE]: 'making_charges_rate',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_WEIGHT]: 'diamond_stone_weight',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_RATE]: 'diamond_stone_rate',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_OTHER_CHARGES_SECTION]: 'other_charges_section',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_WASTAGE_CHARGES_SECTION]: 'wastage_charges_section',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SECTION]: 'price_summary_section',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_SUMMARY_SECTION]: 'metal_summary_section',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_STONE_SUMMARY_SECTION]: 'stone_summary_section',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SUB_TOTAL]: 'price_summary_sub_total',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_DISCOUNT]: 'price_summary_discount',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TAXES]: 'price_summary_taxes',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TOTAL]: 'price_summary_total',
};

export const JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS = {
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_NET_WEIGHT_PER_GRAM]: 'display_the_metal_net_weight_per_gram_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_GROSS_WEIGHT_PER_GRAM]: 'display_the_metal_gross_weight_per_gram_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_RATE_PER_GRAM]: 'display_the_metal_rate_per_gram_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_SECTION]: 'display_the_making_charges_section_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_WEIGHT]: 'display_the_making_charges_weight_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_RATE]: 'display_the_making_charges_rate_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_WEIGHT]: 'display_the_diamond_stone_weight_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_RATE]: 'display_the_diamond_stone_rate_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_OTHER_CHARGES_SECTION]: 'display_the_other_charges_section_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_WASTAGE_CHARGES_SECTION]: 'display_the_wastage_charges_section_in_the_product_price',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SECTION]: 'display_the_price_summary_section_in_the_product_page',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_SUMMARY_SECTION]: 'display_the_metal_summary_section_in_the_product_page',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_STONE_SUMMARY_SECTION]: 'display_the_stone_summary_section_in_the_product_page',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SUB_TOTAL]: 'display_the_price_summary_sub_total_in_the_product_page',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_DISCOUNT]: 'display_the_price_summary_discount_in_the_product_page',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TAXES]: 'display_the_price_summary_taxes_in_the_product_page',
  [JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TOTAL]: 'display_the_price_summary_total_in_the_product_page',
};

export const JEWELLERY_PRICE_DISPLAY_TOGGLES = [{
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_GROSS_WEIGHT_PER_GRAM],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_GROSS_WEIGHT_PER_GRAM],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_GROSS_WEIGHT_PER_GRAM,
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_NET_WEIGHT_PER_GRAM],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_NET_WEIGHT_PER_GRAM],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_NET_WEIGHT_PER_GRAM,
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_RATE_PER_GRAM],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_RATE_PER_GRAM],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_RATE_PER_GRAM,
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_SECTION],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_SECTION],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_SECTION,
    nestedToggles: [
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_WEIGHT],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_WEIGHT],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_WEIGHT,
      },
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_RATE],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_RATE],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_MAKING_CHARGES_RATE,
      },
    ],
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_WEIGHT],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_WEIGHT],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_WEIGHT,
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_RATE],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_RATE],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_DIAMOND_STONE_RATE,
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_OTHER_CHARGES_SECTION],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_OTHER_CHARGES_SECTION],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_OTHER_CHARGES_SECTION,
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_WASTAGE_CHARGES_SECTION],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_WASTAGE_CHARGES_SECTION],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_WASTAGE_CHARGES_SECTION,
  },
  {
    title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SECTION],
    description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SECTION],
    fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SECTION,
    nestedToggles: [
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_SUMMARY_SECTION],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_SUMMARY_SECTION],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_METAL_SUMMARY_SECTION,
      },
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_STONE_SUMMARY_SECTION],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_STONE_SUMMARY_SECTION],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_STONE_SUMMARY_SECTION,
      },
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SUB_TOTAL],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SUB_TOTAL],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_SUB_TOTAL,
      },
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_DISCOUNT],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_DISCOUNT],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_DISCOUNT,
      },
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TAXES],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TAXES],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TAXES,
      },
      {
        title: JEWELLERY_PRICE_DISPLAY_TITLES[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TOTAL],
        description: JEWELLERY_PRICE_DISPLAY_DESCRIPTIONS[JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TOTAL],
        fieldName: JEWELLERY_PRICE_DISPLAY_KEYS.HIDE_PRICE_SUMMARY_TOTAL,
      },
    ],
  },
];
