export const CHECKOUT_FORM_LABELS = {
  title: 'checkout_form',
  tagline: 'you_can_collect_customer_preferences_and_additional_information_before_checkout',
  noDataMessage: 'no_form_fields_found',
  SELECT_FIELD_TYPE_TITLE: 'field_type',
  SELECT_FIELD_TYPE_TAGLINE: 'choose_a_type_of_question',
  ENTER_NAME_OF_FIELD_TAGLINE: 'enter_your_field_name',
  CONFIRMATION_BOX_MESSAGE: 'are_you_sure_you_want_to_delete_this_field',
  CONFIRM_DELETE_BUTTON_TEXT: 'delete',
  COMMON_ERROR_MESSAGE: "something_went_wrong_could_not_fetch_checkout_form_fields",
  BOOLEAN_LABEL: 'select_yes_or_no',
  DATE_LABEL: 'select_date_format',
};

export const FORM_FIELD_TYPES = {
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  MULTI_CHOICE: 'MULTI_CHOICE',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  SINGLE_CHOICE: 'SINGLE_CHOICE',
};

export const CHECKOUT_FORM_STATES = {
  SELECT_FORM_TYPE: 0,
  ENTER_NAME: 1,
  SUBMIT: 2,
};

export const CHECKOUT_FORM_FIELD_TYPES = [
  {
    label: 'text',
    tagline: 'personal_details_or_text_based_questions',
    fieldType: FORM_FIELD_TYPES.TEXT,
  },
  // {
  //   label: 'number',
  //   tagline: 'alternate_phone_number_etc',
  //   fieldType: FORM_FIELD_TYPES.NUMBER,
  // },
  {
    label: 'date',
    tagline: 'delivery_or_any_other_date',
    fieldType: FORM_FIELD_TYPES.DATE,
  },
  {
    label: 'boolean',
    tagline: 'questions_with_a_yes_or_no_answer',
    fieldType: FORM_FIELD_TYPES.BOOLEAN,
  },
  {
    label: 'dropdown',
    tagline: 'to_choose_one_option_among_many',
    fieldType: FORM_FIELD_TYPES.SINGLE_CHOICE,
  }
];

export const AVAILABLE_DATE_FORMATS = [
  // { label: 'DD/MM/YYYY', value: 'dd/mm/yyyy' },
  // { label: 'MM/DD/YYYY', value: 'mm/dd/yyyy' },
  // { label: 'DD/MM/YY', value: 'dd/mm/yy' },
  // { label: 'MM/DD/YY', value: 'mm/dd/yy' },
];

export const AVAILABLE_TIME_FORMATS = [
  // { label: '24 Hour', value: '24' },
  // { label: '12 Hour', value: '12' },
];
