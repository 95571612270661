import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../../common/Loader';
import Toast from '../../../common/Alerts/Toast';
import { useAppContext } from '../../../common/Contexts/AppContext';
import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../../os';
import CustomerList from 'qs-components/CustomerList';
import './style.scss';
import CustomerListData from 'qs-data/customerList';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { createQueryParams } from 'qs-data/util';
import ContactPermission from '../ContactPermission';
import { getContactListMap, getNameFromContactList } from 'qs-helpers/contacts';
import usePhoneUtil from 'qs-common/Hooks/usePhoneUtil';
import { getI18N } from '../../../i18N';

const NAVBAR_HEIGHT = 55;

function ExpiredUsers() {
  const { t } = getI18N();
  const [loading, setLoading] = useState(false);
  const [errorComponent, setErrorComponent] = useState(false);
  const [expiredList, setExpiredList] = useState();
  const [page, setPage] = useState(1);
  const [shouldStopPaginating, setShouldStopPaginating] = useState(false);
  const [paginatedListLoading, setPaginatedListLoading] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [hasContactsPermission, setHasContactsPermission] = useState(false);

  const { phoneUtil, loading: utilLoading } = usePhoneUtil();

  const [, dispatch] = useAppContext();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#4DA47A',
        color: '#FFFFFF',
        title: t('expired_users'),
        hideBack: false,
        height: NAVBAR_HEIGHT,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#252c36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getExpiredList = useCallback(() => {
    let query;

    if (!expiredList || !expiredList.members.length) {
      setLoading(true);
    } else {
      query = createQueryParams([
        {
          key: 'lastAccessExpiryTime',
          value: expiredList.members
            ? [...expiredList.members].splice(-1)[0].accessExpiryTime
            : null,
        },
      ]);
    }

    if (
      expiredList &&
      [...expiredList.members].splice(-1)[0] &&
      ![...expiredList.members].splice(-1)[0].accessExpiryTime
    ) {
      setPaginatedListLoading(false);
      setLoading(false);
      return;
    }

    CustomerListData.getExpiredMember(query)
      .then(({ members }) => {
        setExpiredList({
          ...expiredList,
          members: expiredList ? expiredList.members.concat(members) : members,
        });
        if (members.length === 0) {
          setShouldStopPaginating(true);
        }
      })
      .catch(() => {
        setErrorComponent(true);
        setToastState({
          open: true,
          message: t('an_error_occured_while_fetching_expired_list'),
        });
      }).finally(() => {
        setPaginatedListLoading(false);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const removeRow = ({ id }) => {
    const updatedList = expiredList.members.filter((customer) => {
      if (customer.id !== id) {
        return customer;
      }
      return undefined;
    });
    setExpiredList({
      ...expiredList,
      members: updatedList,
    });
  };

  const onScrollHandler = () => {
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    if (window.scrollY >= scrollableHeight && !paginatedListLoading && !shouldStopPaginating) {
      setPaginatedListLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);
    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStopPaginating, paginatedListLoading, page]);

  useEffect(() => {
    getExpiredList();
    trackAnalytics({
      eventName: 'view_expired_customers_list',
    });
  }, [getExpiredList]);

  return loading || utilLoading ? (
    <div className="componentContainer">
      <Loader />
    </div>
  ) : errorComponent ? (
    <div className="componentContainer">
      <CustomErrorComponent onRetryClick={getExpiredList} />
    </div>
  ) : (
    <div className="expiredCustomer">
      <ContactPermission
        hasContactsPermission={hasContactsPermission}
        setHasContactsPermission={setHasContactsPermission}
      />
      {expiredList && expiredList.members && expiredList.members.length > 0 && (
        <Fragment>
          <div className="expiredRequest">
            <div>
              {expiredList.members.map((request, index) => {
                const phoneNumber =
                  !!(request || {}).phones && !!request.phones[0] ? request.phones[0] : '';
                const name =
                  getContactListMap().get(phoneNumber) ||
                  getNameFromContactList(phoneNumber, phoneUtil) ||
                  request.name ||
                  '';
                return (
                  <CustomerList
                    key={index}
                    id={request.id}
                    title={name}
                    subtitle={request.phones}
                    buttonText="Allow"
                    accessExpiryTime={request.accessExpiryTime}
                    removeRow={removeRow}
                  />
                );
              })}
              {(expiredList.members.length > 1 && paginatedListLoading) && (
                <div className="rowLoading">
                  <Loader small />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {expiredList && !(expiredList.members.length > 0) && (
        <div className="titleRecentCustomers noList">
          {t('no_expired_customers')}
        </div>
      )}

      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}

export default withRouter(ExpiredUsers);
