import React from 'react';

export default () => (
  <svg
    width="16px"
    height="17px"
    viewBox="0 0 16 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons8-person</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android-Copy-5"
        transform="translate(-39.000000, -512.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="icons8-person" transform="translate(39.000000, 512.000000)">
          <path
            d="M8,0 C5.45949015,0 3.4,2.05949015 3.4,4.6 C3.4,7.14050985 5.45949015,9.2 8,9.2 C10.5405098,9.2 12.6,7.14050985 12.6,4.6 C12.6,2.05949015 10.5405098,0 8,0 Z M8,10.8 C5,10.8 2.18,12.2798438 0.5,14.739844 C0.16,15.239844 0,15.819922 0,16.419922 C0,16.739922 0.28,17 0.6,17 L15.4,17 C15.72,17 16,16.739922 16,16.419922 C16.02,15.819922 15.84,15.219844 15.5,14.739844 C13.82,12.2798438 11,10.8 8,10.8 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
