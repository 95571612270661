import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { setNavigationBarColor, trackAnalytics, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import VideoListItem from './VideoListItem';
import VideoPlayer from './VideoPlayer';
import { useParams } from 'react-router-dom';
import ApiWrapper from '../../data/apiWrapper';
import Loader from '../../common/Loader';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import './style.scss';
import { getI18N } from '../../i18N';

const HelpCenter = () => {
  const { t } = getI18N();
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [expandSearchBox, setExpandSearchBox] = useState(false);
  const [videosList, setVideosList] = useState([]);

  const { videoId, from } = useParams();
  const videoParam = videoId || null;
  const comingFrom = from || null;

  const [selectedVideo, setSelectedVideo] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [offsetMargin, setOffsetMargin] = useState(0);

  const isCategoryParam = useRef(true);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState('');

  const videoListRef = useRef();
  const categoryListRef = useRef();

  const categoryRef = useRef({});
  const categoryButtonRef = useRef({});
  const videoRef = useRef(null);

  const categories = useRef({});
  const videosByCategory = useRef([]);
  const [searchResult, setSearchResult] = useState([]);

  const throttleTimer = useRef(false);

  const erroMessage = useRef(null);

  const getAllVideos = () => {
    return ApiWrapper.getDataPromise(
      '/v1/help-center/article?articleTypes=YOUTUBE_VIDEO&categories=HELP_CENTER',
      'articles',
      'help-center'
    );
  };

  useEffect(() => {
    getAllVideos()
      .then((articles) => {
        setVideosList(articles);
        const videoFromParam =
          articles.find((video) => {
            return video.id === videoParam;
          }) || {};

        if (Object.keys(videoFromParam).length !== 0) {
          setSelectedCategory(videoFromParam.tag);
          setSelectedVideo(videoFromParam);
        } else {
          erroMessage.current = t('video_not_found');
          setError(true);
        }

        setLoading(false);
      })
      .catch(() => {
        erroMessage.current = t('something_went_wrong');
        setLoading(false);
        setError(true);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoParam]);

  useEffect(() => {
    if (videosList) {
      videosList.forEach((item) => {
        if (!categories.current[item.tag]) {
          categories.current[item.tag] = [];
        }

        categories.current[item.tag].push({
          id: item.id,
          thumbnail: item.thumbnail,
          url: item.url,
          title: item.title,
          embedId: item.embedId,
          language: item.language,
        });
      });

      const allCategories = Object.keys(categories.current);

      videosByCategory.current = allCategories.map((key) => ({
        [key]: categories.current[key],
      }));
    }
  }, [videosList]);

  const categoriesSections = Object.keys(categories.current);

  const onSearch = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  useEffect(() => {
    if (videosList.length) {
      if (!searchText && expandSearchBox) {
        setSearchResult(videosList);
        return;
      }

      const filteredSearchResult = videosList
        .map((video) => {
          if (video.title.toLowerCase().includes(searchText.toLowerCase())) {
            return video;
          }
          return undefined;
        })
        .filter(Boolean);
      setSearchResult(filteredSearchResult);
    }
  }, [searchText, videosList, expandSearchBox]);

  useEffect(() => {
    trackAnalytics({
      eventName: 'help_center_video_search',
      props: {
        search_text: searchText,
        search_text_length: searchText.length,
        search_result_size: searchResult.length,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);

  const onSearchExpand = useCallback(() => {
    setExpandSearchBox(!expandSearchBox);
    setSearchText('');
  }, [expandSearchBox]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('help_center'),
        value: searchText,
        placeholder: t('search_for_a_topic'),
        searchable: true,
        expandSearchBox: expandSearchBox,
        onClick: onSearchExpand,
        onChange: (e) => onSearch(e),
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, expandSearchBox, onSearch, onSearchExpand]);

  const onVideoSelection = (video) => {
    setSelectedVideo(video);
    setExpandSearchBox(false);
  };

  const scrollToVideo = useCallback(() => {
    const activeVideo = videoRef.current;
    const videoList = videoListRef.current;

    if (activeVideo && videoList) {
      const scrollVideoToTop = activeVideo.offsetTop - videoList.offsetTop;
      videoList.scrollTop = scrollVideoToTop;
    }
  }, []);

  const scrollToSection = useCallback((category) => {
    const section = categoryRef.current[category];
    const videoList = videoListRef.current;

    if (videoList && section) {
      const srcollSectionToTop = section.offsetTop - videoList.offsetTop;
      videoList.scrollTop = srcollSectionToTop;
    }

    setSelectedCategory(category);
  }, []);

  const videoListHandleScroll = useCallback(() => {
    let videoList = videoListRef.current;
    let categoryList = categoryListRef.current;
    let selectedSection = categoryRef.current;
    let activeCategoryButton = categoryButtonRef.current[selectedCategory];
    let sectionInView;
    let marginOffset;

    for (let i = 0; i < categoriesSections.length; i++) {
      let section = selectedSection[categoriesSections[i]];

      if (videoList) {
        sectionInView = videoList.scrollTop - section.offsetTop + videoList.offsetTop;
        marginOffset = videoList.offsetHeight - section.offsetHeight;
      }

      if (categoriesSections.length - 1 === i && isCategoryParam && marginOffset > 0) {
        setOffsetMargin(marginOffset);
      }

      // Handle video list section scroll for setting active category
      if (sectionInView >= -40 && sectionInView <= 40) {
        // Scroll to active video only when landing on help center page
        if (isCategoryParam.current) {
          scrollToVideo();
          isCategoryParam.current = false;
        } else {
          setSelectedCategory(categoriesSections[i]);
        }

        // Handle category list horizaontal scroll to determin postion of active category button
        if (categoryList && activeCategoryButton) {
          // active category button postion from its center till the left edge of the category list
          let activeCategoryButtonPosition =
            // active button distance from the visible edge of the category list
            activeCategoryButton.offsetLeft +
            // active button center position derived from dividing it's width by 2
            activeCategoryButton.clientWidth / 2;

          let categoryListCenterPosition =
            // category list scroll left position
            categoryList.scrollLeft -
            // category list center position derived from dividing it's width by 2
            categoryList.offsetWidth / 2;

          // category list scroll position will handle the active button position to be allways visible in the category list area
          let categoryListScrollPosition =
            activeCategoryButtonPosition + categoryListCenterPosition;

          let hasVideoListScrolledToTop = videoList.scrollTop === 0;
          let hasVideoListScrolledToBottom =
            videoList.scrollHeight - videoList.scrollTop === videoList.offsetHeight;

          if (hasVideoListScrolledToTop) {
            categoryList.scrollLeft = 0;
          } else if (hasVideoListScrolledToBottom) {
            categoryList.scrollLeft = categoryList.offsetWidth;
          } else {
            categoryList.scrollLeft = categoryListScrollPosition;
          }
        }
      }
    }
  }, [selectedCategory, categoriesSections, scrollToVideo]);

  const throttleScroll = useCallback(() => {
    const throttle = (callback, time) => {
      if (throttleTimer.current) return;
      throttleTimer.current = true;
      setTimeout(() => {
        callback();
        throttleTimer.current = false;
      }, time);
    };

    throttle(videoListHandleScroll, 50);
  }, [videoListHandleScroll]);

  const activeCategory = (category) => {
    return selectedCategory === category;
  };

  const addToCategoryRefs = useCallback((el) => {
    if (el) {
      categoryRef.current[el.id] = el;
    }
  }, []);

  const addToCategoryButtonRefs = useCallback((el) => {
    if (el) {
      let navId = el.id.split('nav-').pop();
      categoryButtonRef.current[navId] = el;
    }
  }, []);

  const addVideoRef = useCallback((el) => {
    if (el) {
      videoRef.current = el;
    }
  }, []);

  useEffect(() => {
    if (isCategoryParam.current) {
      videoListHandleScroll();
    }
  }, [videoListHandleScroll]);

  if (error) {
    return <div className="alignCenterContainer">{erroMessage.current}</div>;
  }
  if (loading) {
    return (
      <div className="alignCenterContainer">
        <Loader style={{ marginTop: 10, marginBottom: 10 }} />
      </div>
    );
  }
  return (
    <div className="helpCenterContainer">
      {selectedVideo && !expandSearchBox ? (
        <VideoPlayer
          embedId={selectedVideo.embedId}
          videoTitle={selectedVideo.title}
          language={selectedVideo.language}
          redirectedFrom={comingFrom}
        />
      ) : null}
      {!expandSearchBox ? (
        <div className="categories" ref={categoryListRef}>
          {categoriesSections.map((category) => {
            return (
              <nav
                id={`nav-${category}`}
                className={`categoryButton ${activeCategory(category) ? 'active' : ''}`}
                ref={addToCategoryButtonRefs}
                key={category}
                onClick={() => scrollToSection(category)}
              >
                {category.replace(/_/g, ' ')}
              </nav>
            );
          })}
        </div>
      ) : null}
      <div className="videoLists" ref={videoListRef} onScroll={throttleScroll}>
        {!expandSearchBox
          ? videosByCategory.current.map((category, index) => {
              const categories = Object.keys(category).pop();
              return (
                <div
                  id={categories}
                  ref={addToCategoryRefs}
                  key={categories}
                  className="categoryVideos"
                  style={{
                    marginBottom: videosByCategory.current.length - 1 === index ? offsetMargin : '',
                  }}
                >
                  <p className="categoryTitle">
                    <span>{categories.replace(/_/g, ' ')}</span>
                  </p>
                  {category[categories].map((video) => (
                    <VideoListItem
                      id={video.id}
                      key={video.id}
                      title={video.title}
                      embedId={video.embedId}
                      onVideoSelect={() => onVideoSelection(video)}
                      currentlyPlaying={!expandSearchBox ? selectedVideo : null}
                      ref={video.id === selectedVideo.id ? addVideoRef : null}
                    />
                  ))}
                </div>
              );
            })
          : searchResult.map((video) => (
              <VideoListItem
                id={video.id}
                key={video.id}
                title={video.title}
                embedId={video.embedId}
                onVideoSelect={() => onVideoSelection(video)}
                currentlyPlaying={!expandSearchBox ? selectedVideo : null}
                ref={video.id === selectedVideo.id ? addVideoRef : null}
              />
            ))}
      </div>
    </div>
  );
};

export default withRouter(HelpCenter);
