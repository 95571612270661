let smsSettingsCache = null;

const getSmsSettingsDetailsCache = () => smsSettingsCache;

const setSmsSettingsDetailsCache = (data) => {
  smsSettingsCache = data;
};
export default {
  getSmsSettingsDetailsCache,
  setSmsSettingsDetailsCache,
};
