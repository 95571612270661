import React, { useState, useEffect } from 'react';
import computedStyle from './computedStyles';
import Card from '../../../node_modules/@material-ui/core/Card/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '../../../node_modules/@material-ui/core/CardContent/CardContent';
import Typography from '../../../node_modules/@material-ui/core/Typography/Typography';
import CustomTextField from '../../common/CustomTextField';
import Button from '@material-ui/core/Button';
import Dialog from '../../common/Alerts/Dialog';
import { withRouter } from 'react-router-dom';
import network from '../../data/network';
import Loader from '../../common/Loader';
import Toast from '../../common/Alerts/Toast';
import { useAppContext } from '../../common/Contexts/AppContext';
import { handleError } from '../../common/error';
import { validateEmail } from '../../data/util';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import './style.css';
import { getI18N } from '../../i18N';

const useStyles = makeStyles((theme) => ({
  infoCard: {
    minWidth: 275,
    backgroundColor: '#252B36',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 25,
    color: '#fff',
  },
  content: {
    display: 'flex',
    flexDirection: 'coloumn',
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  button: {
    background: '#2FB57A',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#2FB57A',
      borderColor: '#2FB57A',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2FB57A',
      borderColor: '#2FB57A',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function EmailChanger({ history, location }) {
  const { t } = getI18N();
  const classes = useStyles();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [accountEmail, setAccountEmail] = useState('');
  const [displayEmail, setDisplayEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailsLoading, setEmailsLoading] = useState(true);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (location.hash === '#alert') {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  }, [location.hash]);

  useEffect(() => {
    network
      .getEmails()
      .then(({ accountEmail, displayEmail }) => {
        setEmailsLoading(false);
        setAccountEmail(accountEmail);
        setDisplayEmail(displayEmail);
      })
      .catch((err) => {
        if (err.response.data.reason === 'FORBIDDEN') {
          setToastState({
            open: true,
            message: t('token_invalid'),
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendVerificationEmail = () => {
    history.goBack();
    setLoading(true);
    network
      .sendVerificationEmail(displayEmail)
      .then(() => {
        setLoading(false);
        setToastState({
          open: true,
          message: t('verification_email_sent_successfully'),
        });
      })
      .catch((err) => {
        setToastState({
          open: true,
          message: t('an_error_occured_error_message', { error: err.toString() }),
        });
        setLoading(false);
        handleError(err);
      });
  };

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#2b313c' : '#4DA47A',
        color: '#FFFFFF',
        title: t('email_settings'),
      },
    });
    setNavigationBarColor('#3F4953');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <div className="EmailSettingsContainer" style={computedStyle.EmailSettingsContainer}>
        {emailsLoading ? (
          <div className="LoaderContainer">
            <Loader />
          </div>
        ) : (
          <div className="EmailSettingsContentContainer">
            <Card className={`${classes.infoCard}`} elevation={3}>
              <CardContent>
                <Typography style={{ fontWeight: 500, fontSize: '1.2rem' }}>
                  {t('what_is_a_display_email')}
                </Typography>
                <Typography style={{ fontSize: '1rem' }}>
                  <p style={{ marginTop: 10 }}>
                    {t('your_display_email_will_be_visible_on_your_catalogues_for_your_customers_to_see')}
                  </p>

                  <p style={{ marginTop: 10 }}>
                    {t('you_will_also_receive_new_order_notifications_and_any_other_communication_from_quicksell_on_this_email_address')}
                  </p>
                </Typography>
              </CardContent>
            </Card>

            <div className="infoContainer">
              <CustomTextField
                fullWidth
                variant="filled"
                label={t('account_email')}
                margin="normal"
                value={accountEmail}
                name="email"
                disabled
              />
              <CustomTextField
                fullWidth
                variant="filled"
                label={t('display_email')}
                margin="normal"
                name="displayEmail"
                type="email"
                value={displayEmail}
                error={errors['email']}
                helperText={errors['email']}
                onChange={(e) => {
                  setDisplayEmail(e.target.value);
                }}
              />

              {loading ? (
                <Loader small style={{ marginTop: 10 }} />
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  className={classes.button}
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    if (displayEmail.length > 0 && validateEmail(displayEmail)) {
                      setErrors({});
                      history.push('#alert');
                    } else {
                      setErrors({
                        ...errors,
                        email: t('please_enter_a_valid_email'),
                      });
                    }
                  }}
                >
                  {t('change_display_email')}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={dialogOpen}
        title={t('email_change_request')}
        description={
          <span>
            {t('we_will_send_an_email_to_verify_display_email', { email: displayEmail })}
          </span>
        }
        confirmText={t('proceed_with_verification')}
        onClose={() => {
          history.goBack();
        }}
        handleConfirm={onSendVerificationEmail}
      />
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}

export default withRouter(EmailChanger);
