import ApiWrapper from './apiWrapper';

let apis = {
  productFieldEndPoint: '/v1/product-field',
  productFieldReorderEndPoint: '/v1/product-field/reorder',
};

/* Custom fields apis */

const getProductFields = () => {
  return ApiWrapper.getDataPromise(apis.productFieldEndPoint, 'fields');
};

const addProductField = (data) => {
  return ApiWrapper.postDataPromise(apis.productFieldEndPoint, data);
};

const updateProductField = (data) => {
  return ApiWrapper.putDataPromise(apis.productFieldEndPoint, data);
};

const deleteProductField = (data) => {
  return ApiWrapper.deleteDataPromise(apis.productFieldEndPoint, data);
};

const reorderProductFields = (data) => {
  return ApiWrapper.postDataPromise(apis.productFieldReorderEndPoint, data);
};

export default {
  addProductField,
  getProductFields,
  deleteProductField,
  updateProductField,
  reorderProductFields,
};
