import React, { Fragment } from "react";
import Switch from 'react-switch';
import Loader from "../../common/Loader";
import { getI18N } from "../../i18N";
import { useStyles } from './styles';
import { ButtonBase } from "@material-ui/core";

function JewelleryPriceSectionRow({ fieldName, title, description, nestedToggles = [], onChange, checked = false, disabled = null }) {
  const classes = useStyles();

  const { t } = getI18N();

  const handleChange = (fieldName) => {
    onChange(fieldName);
  };

  const renderToggle = ({ key, fieldName, title, description, nestedToggles = [], checked = false, isNested = false }) => {
    return (
      <Fragment key={key}>
        <ButtonBase
          className={`${classes.jewelleryPriceRowContainer} ${isNested ? classes.nestedJewelleryPriceRowContainer : ''} ${(!!nestedToggles && nestedToggles.length > 0) ? classes.parentJewelleryPriceRowContainer : classes.singleJewelleryPriceRowContainer}`}
          onClick={(event) => {
            if (disabled) {
              return;
            }
            event.stopPropagation();
            handleChange(fieldName);
          }}
          component="div"
        >
          <div className={`${(!!nestedToggles && nestedToggles.length > 0) ? classes.parentJewelleryPriceFlexContainer : classes.childJewelleryPriceFlexContainer}`}>
            <div className={classes.jewelleryPriceFlexContainer}>
              {((!!title && title.trim().length > 0) || (!!description && description.trim().length > 0)) && (
                <div className={classes.jewelleryPriceRowTitleContainer}>
                  {(!!title && title.trim().length > 0) && (
                    <h4 className={classes.jewelleryPriceTitle}>
                      {t(title.trim())}
                    </h4>
                  )}
                  {(!!description && description.trim().length > 0) && (
                    <div className={classes.jewelleryPriceDescription} style={{ marginTop: (!!title && title.trim().length > 0) ? '10px' : undefined }}>
                      {t(description.trim())}
                    </div>
                  )}
                </div>
              )}
              {(disabled === fieldName) ? (
                <Loader style={{ height: 25, width: 48 }} />
              ) : (
                <Switch
                  checked={checked}
                  onChange={() => {}}
                  onColor="#515863"
                  offColor="#515863"
                  onHandleColor="#4DA47A"
                  offHandleColor="#929faf"
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={17}
                  width={40}
                  disabled={!!disabled}
                />
              )}
            </div>
          </div>
        </ButtonBase>
        {(!!checked && !!nestedToggles && nestedToggles.length > 0) && (
          nestedToggles.map((nestedToggle, index) => {
            return renderToggle({ ...nestedToggle, key: index, isNested: true });
          })
        )}
      </Fragment>
    );
  }

  return renderToggle({
    fieldName,
    title,
    description,
    nestedToggles,
    checked,
    isNested: false
  });
}

export default JewelleryPriceSectionRow;
