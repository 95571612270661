import React, { useState } from 'react';
import Ripples from 'react-ripples';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ThemeSectionPreview from 'qs-components/Theme/ThemeSectionPreview';
import Loader from 'qs-common/Loader';
import { prepareCompanyDetailsForThemes } from 'qs-data/util';
import ThemeEditDrawer from './ThemeEditDrawer';
import { getI18N } from '../../i18N';

export default ({
  loading,
  themes,
  selectedTheme,
  classes,
  companyDetails,
  editThemeHandler,
  themeItemRefMounted,
  onSelectedThemeChanged,
  source = 'mobile',
  languageCode = 'en'
}) => {
  const { t } = getI18N();
  const [showThemesEditDrawer, setShowThemesEditDrawer] = useState(false);
  const [themeToEdit, setThemeToEdit] = useState(null);
  const openThemesEditDrawer = (e, theme) => {
    e.preventDefault();
    setShowThemesEditDrawer(true);
    setThemeToEdit(theme);
  };
  const renderSingleTheme = (theme, i, style) => {
    return (
      <Grid
        key={theme.id + i}
        item
        xs={6}
        sm={4}
        md={3}
        lg={2}
        elevation={0}
        style={style}
        ref={themeItemRefMounted}
      >
        <Paper className={classes.paper}>
          <div className="theme-wrapper">
            <div className="theme-container">
              <Ripples onClick={(e) => openThemesEditDrawer(e, theme)}>
                <ThemeSectionPreview
                  {...prepareCompanyDetailsForThemes(companyDetails)}
                  theme={{
                    ...theme,
                    primaryColor: theme.addToCartButton.color,
                    contrastTextColor: theme.addToCartButton.textColor,
                    backgroundColor: theme.homeGrid.backgroundColor,
                    pageTextColor: theme.pageTextColor,
                  }}
                  hideOrderBar
                />
              </Ripples>
              <div className="theme-actions">
                <div className="theme-details">
                  <p className={classes.title}>{t(theme.name)}</p>
                  {selectedTheme.id === theme.id ? (
                    <Button className={classes.selectedTheme} onClick={editThemeHandler(theme)}>
                      {t('selected')}
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      className={classes.useTheme}
                      onClick={onSelectedThemeChanged(theme)}
                    >
                      {t('use_theme')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        {themes.map((theme, i) => renderSingleTheme(theme, i))}
      </Grid>
      {loading && (
        <div className="loader">
          <Loader small />
        </div>
      )}
      {showThemesEditDrawer && (
        <ThemeEditDrawer
          setShowThemesEditDrawer={setShowThemesEditDrawer}
          editThemeHandler={editThemeHandler}
          theme={themeToEdit}
          onSelectedThemeChanged={onSelectedThemeChanged}
          selectedTheme={selectedTheme}
          source={source}
          languageCode={languageCode}
        />
      )}
    </>
  );
};
