import React from 'react';

export default () => (
  <svg
    width="24px"
    height="14px"
    viewBox="0 0 24 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons8-right_arrow</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android-Copy-8"
        transform="translate(-294.000000, -637.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="icons8-right_arrow" transform="translate(294.000000, 637.000000)">
          <polygon
            id="Path"
            points="17.1875 0 15.78125 1.4375 20.0625 5.71875 0 5.71875 0 7.71875 20.0625 7.71875 15.78125 12 17.1875 13.4375 23.90625 6.71875"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
