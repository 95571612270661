import React, {createRef, Fragment, useState} from 'react';
import DialogBox from "qs-common/DialogBox";
import "./style.scss";
import { TextField } from "@material-ui/core";
import { getI18N } from '../../../i18N';

export default function ({ showDialog, defaultValue, onClose, onSuccess }) {
  const { t } = getI18N();
  const [name, setName] = useState(defaultValue);
  const [closeDialog, setCloseDialog] = useState(false);
  const inputRef = createRef();

  const onChange = () => {
    setName(inputRef.current.value);
  }

  const onSave = () => {
    setCloseDialog(true);
    setTimeout(() => {
      onSuccess(name)
    }, 200)
  }

  return <DialogBox
    type={"simple"}
    dialogId={'#theme-name'}
    show={showDialog}
    width={300}
    disabledPrimaryButton={!name}
    primaryBtnText={t('save')}
    onSuccess={onSave}
    onClose={onClose}
    showProgressBar={false}
    closeDialog={closeDialog}
  >
    <Fragment>
      <div className='themeNameContainer'>
        <p className={'dialogTitle'}>{t('set_theme_name')}</p>
        <TextField
          autoFocus
          defaultValue={defaultValue}
          onChange={onChange}
          inputRef={inputRef}
        />
      </div>
    </Fragment>
  </DialogBox>
}
