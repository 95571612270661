import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';
import { createQueryParams } from './util';

const apis = {
  allCataloguesEndPoint: '/v1/catalogue/allCatalogues',
  companyThemeEndPoint: '/v1/company/themes',
  selectedThemeEndPoint: '/v1/experiment/selected-theme',
  themeEndPoint: '/v1/catalogue-theme/theme'
};
const defaultThemesPageSize = 12;

/* Theme fields get and set */

const getAllCatalogues = () => {
  return ApiWrapper.getDataPromise(apis.allCataloguesEndPoint, 'catalogues', null);
};

const getAllThemesForCompany = ({ dateUpdated }) => {
  const queryParams = [
    { key: 'limit', value: defaultThemesPageSize},
    { key: 'dateUpdated', value: dateUpdated }
  ]
  return ApiWrapper.getDataPromise(apis.companyThemeEndPoint, '', '', createQueryParams(queryParams));
};

const getSelectedThemeForCompany = () => {
  return ApiWrapper.getDataPromise(`${apis.selectedThemeEndPoint}`, 'theme');
}

const setSelectedThemeIdForCompany = (themeId) => {
  return ApiWrapper.postDataPromise(apis.selectedThemeEndPoint, { themeId }, CacheKeys.allThemes);
};

const addTheme = (theme) => {
  return ApiWrapper.postDataPromise(apis.themeEndPoint, { theme }, CacheKeys.allThemes);
};

const updateTheme = (theme) => {
  return ApiWrapper.putDataPromise(apis.themeEndPoint, { theme }, CacheKeys.allThemes);
};

const deleteTheme = (themeId) => {
  return ApiWrapper.deleteDataPromise(`${apis.themeEndPoint}?id=${themeId}`, { themeId }, CacheKeys.allThemes);
};

const getTheme = (themeId) => {
  return ApiWrapper.getDataPromise(`${apis.themeEndPoint}?id=${themeId}`, 'theme');
};

export default {
  getAllCatalogues,
  getAllThemesForCompany,
  getSelectedThemeForCompany,
  setSelectedThemeIdForCompany,
  addTheme,
  updateTheme,
  deleteTheme,
  getTheme
}