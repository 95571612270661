import React, { Fragment, useState } from 'react';
import { isNil, sortBy } from 'lodash';
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ShippingData from "qs-data/shipping";
import CacheRequest from 'qs-data/cacheRequest';
import CacheKeys from 'qs-data/cacheKeys';
import DialogBox from "qs-common/DialogBox";
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { getI18N } from '../../../../i18N';
import "./style.scss";

export default function ({ showDialog, onClose, onSuccess }) {
    const { t } = getI18N();

    const noResultTextWhenCharLessThanThree = t('type_full_city_name');
    const noResultText = t('no_results_found');

    const [cities, setCities] = useState([]);
    const [updatedLocation, setUpdatedLocation] = useState(null);
    const [progressBar, setProgressBar] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [noOptionText, setNoOptionText] = useState(noResultTextWhenCharLessThanThree);

    const handleHomeLocationChange = (event, values) => {
        setUpdatedLocation(values);
    };

    const fetchCities = async (e) => {
        const content = (e.target.value).trim();
        if (!isNil(content) && content.length > 2) {
            setErrorMessage(null);
            setLoadingData(true);
            ShippingData.getAllCities(e.target.value).then((allCities) => {
                const sortedCities = sortBy(allCities, o => o.name);
                if (sortedCities.length === 0) {
                    setNoOptionText(noResultText);
                }
                setCities(sortedCities);
            }).catch(() => {
                // Handle error
            }).finally(() => {
                setLoadingData(false)
            });
        } else {
            setNoOptionText(noResultTextWhenCharLessThanThree);
            setCities([]);
        }
    }

    const updateHomeCity = async () => {
        setProgressBar(true);
        setErrorMessage(null);
        setUpdatedLocation(updatedLocation);
        ShippingData.updateShippingHomeLocation({ cityId: updatedLocation.cityId }).then(() => {
            CacheRequest.deleteCacheForKeys([CacheKeys.shippingZonesCacheKey]);
            onSuccess(updatedLocation);
        }).catch((error) => {
            setErrorMessage(error);
        }).finally(() => {
            setProgressBar(false);
        });
    };

    return (
        <DialogBox
            type="simple"
            dialogId="#select-city"
            show={showDialog}
            width={300}
            primaryBtnText={t('save')}
            onSuccess={updateHomeCity}
            onClose={onClose}
            disabledPrimaryButton={isNil(updatedLocation)}
            showProgressBar={progressBar}
        >
            <div className="cityDialogContent">
                <p className="dialogTitle">{t('select_city')}</p>
                <Autocomplete
                    loading={loadingData}
                    noOptionsText={noOptionText}
                    onChange={handleHomeLocationChange}
                    id="autocomplete-city-select"
                    options={cities}
                    getOptionLabel={(option) => option.name + ', ' + option.state}
                    renderInput={(params) => <TextField 
                        onChange={fetchCities} 
                        autoFocus {...params} 
                        placeholder={t('type_atleast_characters', { number : '3' })} 
                        margin="normal"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loadingData ? <CircularProgress color="inherit" size={18} /> : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }} />
                    }
                />
                {errorMessage && <CustomErrorComponent errorMessage={errorMessage} />}
            </div>
        </DialogBox>
    );
}
