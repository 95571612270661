import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { saveToken } from './data/util';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AppProvider } from './common/Contexts/AppContext';
import NavBar from './common/navbar';
import Routes from './common/routes';

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d475801c13854a0d89ecea0fb8bcd008@o110511.ingest.sentry.io/5209743',
    beforeSend(event, hint) {
      if (
        (event.message || '').includes('ResizeObserver') ||
        ((((event.exception || {}).values || [])[0] || {}).type || '').includes('ResizeObserver') ||
        (((event.exception || {}).values || [])[0] || {}).type ===
          'ResizeObserver loop limit exceeded'
      ) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      'ResizeObserver',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ]
  });
}

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#fff',
      text: 'rgb(178, 191, 214)',
    },
    background: {
      paper: '#252B36',
    },
  },
});

function App() {
  const initialState = {
    navBar: {
      background: 'transparent',
      color: '#000000',
      title: '',
      hide: true,
    },
    pageContainerStyle: {},
  };

  const [showNavBar, toggleNavBar] = useState(false);
  const [pageContainerStyle, setPageContainerStyle] = useState({});

  useEffect(() => {
    const saveNewToken = ({ detail }) => {
      const { idToken } = detail || {};
      if (idToken) {
        saveToken(idToken);
      }
    };
    document.addEventListener('getIdTokenResult', saveNewToken);

    return () => {
      document.removeEventListener('getIdTokenResult', saveNewToken);
    };
  }, []);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_NAVBAR':
        toggleNavBar(true);
        return {
          ...state,
          navBar: { ...action.navBar },
        };
      case 'HIDE_NAVBAR':
        toggleNavBar(false);
        return state;
      case 'SET_PAGE_CONTAINER_STYLE':
        setPageContainerStyle(action.pageContainerStyle);
        return {
          ...state,
          pageContainerStyle: { ...action.pageContainerStyle },
        };
      default:
        return state;
    }
  };

  const listenToNewTokenFromParent = () => {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    eventer(messageEvent, function (e) {
      if (e && e.type === 'message' && e.data) {
        let eventData = e.data;
        if (typeof eventData === 'string') {
          if (eventData.indexOf('token') !== -1) {
            const token = JSON.parse(e.data).token;
            if (token) {
              saveToken(token);
            }
          }
        }
      }
    });
  };

  listenToNewTokenFromParent();

  return (
    <AppProvider initialState={initialState} reducer={reducer}>
      <HashRouter>
        <ThemeProvider theme={theme}>
          {showNavBar && <NavBar />}
          <section
            id="App-main"
            style={{ paddingTop: showNavBar ? '3.5rem' : 0, ...pageContainerStyle }}
          >
            <Route exact path="/">
              <Redirect to="/payments" />
            </Route>
            {Routes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </section>
        </ThemeProvider>
      </HashRouter>
    </AppProvider>
  );
}
export default App;
