import React, { useEffect, useState } from 'react';
import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { useHistory, withRouter } from 'react-router-dom';
import ApiWrapper from 'qs-data/apiWrapper';
import VideoPlayer from '../HelpCenter/VideoPlayer';
import Loader from '../../common/Loader';
import { getToken } from '../../data/util';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import Utility from 'qs-helpers/utility';
import { getI18N } from '../../i18N'
import './style.scss';

const Learn = () => {
  const { t } = getI18N()
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const getLearnPosts = () => {
    return ApiWrapper.getDataPromise('/v1/learn/article', 'articles', 'learn');
  };

  useEffect(() => {
    getLearnPosts()
      .then((articles) => {
        setPosts(articles);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#289D75' : '#4DA47A',
        color: '#ffffff',
        title: t('learn'),
        boxShadow: 'none',
        borderBottom: '1px solid #242C36',
        hideBack: false,
        support: false,
        settings: false,
      },
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const goToPost = (postId, url, title) => {
    if (postId) {
      trackAnalytics({
        eventName: 'learn_video_clicked',
        props: {
          title: title,
          url: url,
        },
      });
      history.push(`/help-center/${postId}/learn?token=${getToken()}&source=${source}&languageCode=${languageCode}`);
    } else {
      trackAnalytics({
        eventName: 'primary_video_clicked',
        props: {
          title: title,
          url: url,
        },
      });
      Utility.openPopup(url);
    }
  };

  const primaryPosts = posts && posts.filter((post) => post.tag === 'primary');
  const helpCenterPosts = posts && posts.filter((post) => post.tag !== 'primary');

  if (loading) {
    return (
      <div className="alignCenterContainer">
        <Loader style={{ marginTop: 10, marginBottom: 10 }} />
      </div>
    );
  }
  if (error) {
    return <div className="alignCenterContainer">{t('something_went_wrong')}</div>;
  }
  return (
    <div className="learnContainer">
      <h1>
        {t('worlds_most_powerful_mobile_cataloguing_system')}
      </h1>
      {primaryPosts && primaryPosts.length > 0 ? (
        <div className="primarySection">
          <p className="headerTitle">{t('explore_the_app')}</p>
          {primaryPosts.map((primaryPost) => {
            return (
              <div key={primaryPost.id} className="primaryPost">
                {imageLoaded ? null : (
                  <div className="loader">
                    <Loader style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                )}
                <div
                  className="primaryPostVideo"
                  style={imageLoaded ? {} : { display: 'none' }}
                  onLoad={() => setImageLoaded(true)}
                >
                  <VideoPlayer
                    embedId={primaryPost.embedId}
                    videoTitle={primaryPost.title}
                    language={primaryPost.language}
                    redirectedFrom="home"
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      {helpCenterPosts && helpCenterPosts.length > 0 ? (
        <div className="helpCenterSection">
          <p className="headerTitle">{t('help_center_how_to_use_quicksell')}</p>
          <div className="postsContainer">
            {helpCenterPosts.map((post) => {
              return (
                <div
                  key={post.id}
                  className="post"
                  onClick={() => goToPost(post.helpCenterArticleId, post.url, post.title)}
                >
                  <div className="postThumbnail">
                    {post.helpCenterArticleId && <span className="playIcon"></span>}
                    {imageLoaded ? null : (
                      <div className="loader">
                        <Loader style={{ marginTop: 10, marginBottom: 10 }} />
                      </div>
                    )}
                    <img
                      style={imageLoaded ? {} : { display: 'none' }}
                      src={`https://img.youtube.com/vi/${post.embedId}/sddefault.jpg`}
                      alt={post.tag}
                      onLoad={() => setImageLoaded(true)}
                    />
                  </div>
                  <p>{post.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(Learn);
