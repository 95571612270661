import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { getI18N } from '../../i18N';

function LiveButton() {
    const { t } = getI18N();
    const [show, setShow] = useState(false);

    useEffect(() => {
        setInterval(() => {
            setShow((s) => !s)
        }, 700);
    }, []);

    return (
        <>
            <CSSTransition in={show} timeout={700} classNames="live">
                <div className="TimeRemaining" style={{marginBottom : 10}}>
                    {t('live_now').toUpperCase()}
                </div>
            </CSSTransition>
        </>
    )
}

export default LiveButton;