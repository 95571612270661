import { handleError } from 'qs-common/error';
import network from 'qs-data/network';
import { createQueryParams } from 'qs-data/util';

export const createOrder = async ({ customerId, items, barcode }) => {
  const apiEndpoint = '/v1/order/customers/create-order';

  const requestBody = {
    customerId,
    items,
    barcode,
  };

  try {
    const response = await network.postData(apiEndpoint, requestBody);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const addUpdateProductsInOrder = async ({ orderId, items, barcode }) => {
  const apiEndpoint = '/v1/order/customers/add-inquiries';

  const requestBody = {
    orderId,
    items,
    barcode,
  };

  try {
    const response = await network.postData(apiEndpoint, requestBody);
    return response.data || response;
  } catch (error) {
    handleError(error);
  }
};

export const searchProductsByBarcode = async (barcode) => {
  const apiEndpoint = '/v1/order/customers/search-products';

  const queryParams = createQueryParams([
    {
      key: 'barcode',
      value: barcode,
    },
  ]);

  try {
    const response = await network.getData(apiEndpoint, queryParams);
    return response.data;
  } catch (error) {
    console.error('Error searching products by barcode:', error);
    throw error;
  }
};

export const confirmOrder = async (orderId) => {
  const apiEndpoint = '/v1/order/mark-confirmed';

  const requestBody = {
    orderId,
  };

  try {
    const response = await network.putData(apiEndpoint, requestBody);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteItemFromInquiry = async ({ inquiryIds }) => {
  const requestBody = {
    inquiryIds,
  };

  try {
    const response = await network.deleteItemFromInquiry(requestBody);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const updateQuantityInInquiry = async ({ inquiryId, itemCount }) => {
  const requestBody = {
    inquiryId,
    itemCount,
  };

  try {
    const response = await network.updateQuantityInInquiry(requestBody);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const getCustomToken = async () => {
  const apiEndpoint = '/v1/auth/custom-token';
  try {
    const response = await network.getData(apiEndpoint);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
