import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CouponIcon from 'qs-assets/images/ic_coupon_white.png';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import Loader from 'qs-common/Loader';
import CompanyData from 'qs-data/companyData';
import {
  attachCompanyListener,
  attachCompanyCouponListener,
  getCompanyCoupons,
  getCouponFromCache,
  loadMoreCouponsIfPossible,
  removeCompanyListener,
  removeCompanyCouponListener,
  deleteCoupon,
} from 'qs-data/coupons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Toast from 'qs-common/Alerts/Toast';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { setNavigationBarColor, setStatusBarColor, PLATFORMS, getPlatform } from '../../os';
import CouponCards from './CouponCards';
import { getToken } from 'qs-data/util';
import { getI18N } from '../../i18N';

import useStyles from './styles';
import './styles.scss';

export default () => {
  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#4DA47A',
      },
      secondary: {
        main: '#fff',
        text: 'rgb(178, 191, 214)',
      },
      background: {
        paper: '#ffffff',
      },
    },
  });

  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState(false);
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [updateData, setUpdateData] = useState({});
  const [couponData, setCouponData] = useState(() => {
    const cachedCoupons = getCouponFromCache();
    return cachedCoupons ? cachedCoupons.coupons : [];
  });
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });
  const [operationLoader, setOperationLoader] = useState(false);
  const [operationError, setOperationError] = useState('');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const timerRef = useRef();
  const couponAreaRef = useRef();
  const apiCallRef = useRef({ companyCompleted: false, couponCompleted: false });

  const history = useHistory();

  const { t } = getI18N();

  useEffect(() => {
    const addCouponButton =
      getPlatform() === PLATFORMS.IOS ? (
        <IconButton
          onClick={() => history.push(`/coupons/create?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
          style={{ width: 30, height: 40, padding: 0 }}
          color="primary"
          aria-label="add-coupon"
        >
          <AddIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          onClick={() => history.push(`/coupons/create?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
          color="success"
          className={`${classes.navBtn} btn-top`}
        >
          <span>{t('add_coupon')}</span>
        </Button>
      );

    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('coupons'),
        hideBack: desktop,
        actions: addCouponButton,
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop, classes.navBtn, history]);

  useEffect(() => {
    const coupons = getCouponFromCache();
    const cachedCompanyData = CompanyData.getCachedCompanyDetails();
    if (coupons && cachedCompanyData) {
      setLoading(false);

      const { coupons: recentCoupon } = coupons;
      if (
        Array.isArray(recentCoupon) &&
        recentCoupon.length > 0 &&
        recentCoupon[0].createdSuccessfully
      ) {
        setToastState({
          message: t('coupon_created'),
          open: true,
        });
        recentCoupon[0].createdSuccessfully = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unsetLoadingIfDataReceived = useCallback(() => {
    const { companyCompleted, couponCompleted } = apiCallRef.current;
    if (couponCompleted && companyCompleted) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const companyListeners = (err, payload) => {
      const { data } = payload;

      if (data || err) {
        apiCallRef.current.companyCompleted = true;
        unsetLoadingIfDataReceived();
      }

      if (err) {
        setToastState({
          message: t('something_went_wrong_while_fetching_the_company_details'),
          open: true,
        });
      }
    };

    attachCompanyListener(companyListeners);
    CompanyData.getCompanyData();

    return () => {
      removeCompanyListener(companyListeners);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsetLoadingIfDataReceived]);

  useEffect(() => {
    const couponsListener = function (err, payload) {
      const { data } = payload;
      if (data || err) {
        apiCallRef.current.couponCompleted = true;
        unsetLoadingIfDataReceived();
      }

      if (err) {
        setLoading(false);
        setError(true);
      }

      if (data) {
        setCouponData(data.coupons);
      }
    };

    attachCompanyCouponListener(couponsListener);
    getCompanyCoupons();

    return () => {
      removeCompanyCouponListener(couponsListener);
    };
  }, [unsetLoadingIfDataReceived]);

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  useEffect(() => {
    if (!couponAreaRef.current) {
      return;
    }

    async function loadMoreCoupons() {
      if (couponAreaRef.current.scrollHeight === couponAreaRef.current.clientHeight) {
        await loadMoreCouponsIfPossible();
      }
    }

    loadMoreCoupons();
  }, [couponData]);

  const handleCouponClick = async (info) => {
    history.push({
      pathname: `/coupons/update/${info.code}`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };

  const handleScroll = (event) => {
    const element = event.target;
    if (element.scrollHeight - Math.ceil(element.scrollTop) === element.clientHeight) {
      loadMoreCouponsIfPossible();
    }
  };

  const scrollHandlerWrapper = (event) => {
    event.persist();
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleScroll(event);
    }, 100);
  };

  const clickDelete = (info) => {
    setUpdateData(info);
    setDeleteModal(true);
  };

  const handleDeleteApi = async () => {
    setOperationLoader(true);
    try {
      await deleteCoupon(updateData);
      setDeleteModal(false);
      setToastState({
        message: t('coupon_deleted'),
        open: true,
      });
    } catch (err) {
      setOperationError(t('something_went_wrong_while_deleting_this_coupon'));
    } finally {
      setUpdateData({});
      setOperationLoader(false);
    }
  };

  const handleCouponAreaMount = useCallback((ref) => {
    if (!ref) {
      return;
    }

    async function loadMoreCoupons() {
      if (ref.scrollHeight === ref.clientHeight) {
        await loadMoreCouponsIfPossible();
      }
    }

    loadMoreCoupons();
  }, []);

  const renderDeleteModal = () => {
    if (!deleteModal) {
      return null;
    }

    let dialogChidlren;
    if (operationLoader) {
      dialogChidlren = (
        <DialogContent>
          <div className="alignCenterContainer">
            <Loader style={{ marginTop: 10, marginBottom: 10 }} />
          </div>
        </DialogContent>
      );
    } else if (operationError) {
      dialogChidlren = (
        <>
          <DialogContent>
            <span>{operationError}</span>
          </DialogContent>
          <DialogActions className={'couponDeleteActions'}>
            <Button
              color={'primary'}
              className={'deleteButtonCancel'}
              onClick={() => setDeleteModal(false)}
            >
              <span className="cancelButtonText">{t('cancel')}</span>
            </Button>
          </DialogActions>
        </>
      );
    } else {
      dialogChidlren = (
        <>
          <DialogContent>
            <Typography classes={{ root: classes.typography }}>
              {t('are_you_sure_you_want_to_delete_this_coupon')}
            </Typography>
          </DialogContent>
          <DialogActions className={'couponDeleteActions'}>
            <Button
              color={'primary'}
              className={'deleteButtonCancel'}
              onClick={() => setDeleteModal(false)}
            >
              <span className="cancelButtonText">{t('cancel')}</span>
            </Button>
            <Button color={'primary'} className={'deleteButtonConfirm'} onClick={handleDeleteApi}>
              <span className="confirmButtonText">{t('delete_coupon')}</span>
            </Button>
          </DialogActions>
        </>
      );
    }

    return (
      <Dialog open={true} onClose={() => setDeleteModal(false)}>
        <DialogTitle id="couponDeleteTitle">{t('delete_coupon')}</DialogTitle>
        {dialogChidlren}
      </Dialog>
    );
  };

  if (loading) {
    return (
      <div className="alignCenterContainer">
        <Loader />
      </div>
    );
  }
  if (error) {
    return <div className="alignCenterContainer">{t('something_went_wrong')}</div>;
  }
  if (couponData.length === 0) {
    return (
      <div className={'couponEmpty'}>
        <div className={'couponEmptyArea'}>
          <img src={CouponIcon} alt={''} height={100} widht={150} />
          <span className={'noCouponText'}>{t('no_coupons_added')}</span>
          <Button
            variant="contained"
            onClick={() => history.push(`/coupons/create?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
            color="primary"
          >
            <span>{t('add_coupon')}</span>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="couponsContainer">
        <div className={'couponArea'} onScroll={scrollHandlerWrapper} ref={handleCouponAreaMount}>
          {couponData.map((item) => {
            const info = {
              name: item.name,
              color: item.color,
              code: item.code,
              discount: item.discountPercent,
              couponAmount: item.discountAmount,
              applyDiscount: item.applyOnDiscountedPrice,
              perUser: item.perUserLimit,
              totalUsageLimit: item.totalUsageLimit,
              validTill: item.validTill,
            };

            return (
              <CouponCards
                info={info}
                onEdit={() => handleCouponClick(info)}
                onDelete={(code) => clickDelete(info, code)}
                showActionButtons={true}
                key={item.code}
              />
            );
          })}
        </div>
        {renderDeleteModal()}
        <Toast
          open={toastState.open}
          message={toastState.message}
          onClose={() => {
            setToastState({
              open: false,
              message: '',
            });
          }}
        />
      </div>
    </ThemeProvider>
  );
};
