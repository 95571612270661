import React, { Fragment, useRef, useEffect, useState } from 'react';
import { FormControl, Typography, Popover, TextField } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useOnClickOutside from 'qs-common/Hooks/useOnClickOutside';

import { FONT_TYPES, OVERRIDDEN_USER_AGENT } from './constants';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../OtherPages/constants';
import { setNavigationBarColor, setStatusBarColor } from '../../os';

import { useStyles } from './styles';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';

import network from 'qs-data/network';
import Loader from 'qs-common/Loader';
import Toast from 'qs-common/Alerts/Toast';

import { useAppContext } from '../../common/Contexts/AppContext';
import { getI18N } from '../../i18N';

const NAVBAR_HEIGHT = 55;

export default function CustomWebsiteFonts() {
  const classes = useStyles();
  const iframeRef = useRef();

  const [customFontsList, setCustomFontsList] = useState([]);
  const [customFontsMap, setCustomFontsMap] = useState([]);
  const [fontSearchQuery, setFontSearchQuery] = useState('');
  const [currentFontType, setCurrentFontType] = useState(null);
  const [cataloguePreviewUrl, setCataloguePreviewUrl] = useState(null);
  const [toastState, setToastState] = useState({ open: false, message: '' });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [iframeRefreshing, setIframeRefreshing] = useState(false);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);
  const [infoPopoverAnchorElement, setInfoPopoverAnchorElement] = useState(null);
  const [infoPopoverAnchorType, setInfoPopoverAnchorType] = useState(null);
  const [, dispatch] = useAppContext();
  const desktop = useSearchParamsQuery().get('source') === 'desktop';

  const { t } = getI18N();

  const handlePopoverClick = (anchorElement, fontType) => {
    if (!fontType) {
      setToastState({ open: true, message: t('please_select_a_font_type') });
      return;
    }
    setPopoverAnchorElement(anchorElement);
    setCurrentFontType(fontType);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorElement(null);
    setCurrentFontType(null);
    setFontSearchQuery('');
  };

  const handleInfoPopoverOpen = (anchorElement) => {
    setInfoPopoverAnchorElement(anchorElement);
    setInfoPopoverAnchorType(anchorElement.getAttribute('data-type'));
  };

  const handleInfoPopoverClose = () => {
    setInfoPopoverAnchorElement(null);
    setInfoPopoverAnchorType(null);
  };

  useEffect(() => {
    getCompanyFonts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('blur', windowBlurListener);
    return () => {
      window.removeEventListener('blur', windowBlurListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const windowBlurListener = () => {
    handlePopoverClose();
  };

  const popoverContainerRef = useOnClickOutside(handlePopoverClose, !!popoverAnchorElement);

  const getCompanyFonts = async () => {
    setLoading(true);
    setIframeLoading(true);
    try {
      const [customFonts, fontsMap, { url: previewUrl }] = await Promise.all([
        network.getCustomFonts(),
        network.getFontsMap(),
        network.getCustomPageOrCataloguePreviewLink()
      ]);
      setCustomFontsList(customFonts);
      setCustomFontsMap(fontsMap);
      setCataloguePreviewUrl(previewUrl);
    } catch (error) {
      setToastState({ open: true, message: t('error_while_fetching_custom_website_fonts') });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        display: desktop ? 'none' : undefined,
        title: desktop ? '' : t('custom_website_fonts'),
        height: desktop ? 0 : NAVBAR_HEIGHT,
        hideBack: desktop,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: desktop ? '100%' : `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: desktop ? 0 : `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor(PRIMARY_COLOR);
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  const onFontFamilyChange = async (font) => {
    if (!currentFontType || !font || !font.fontFamily) {
      setToastState({ open: true, message: t('please_select_a_font_type') });
      return;
    }
    setDisabled(font.fontFamily);
    try {
      const currentFont = customFontsMap.find((customFonts) => customFonts.type === currentFontType);
      const updatedFont = customFontsList.find((customFonts) => customFonts.fontFamily === font.fontFamily);
      if (!updatedFont || (!!currentFont && currentFont.fontFamily === updatedFont.fontFamily)) {
        setPopoverAnchorElement(null);
        return;
      }
      const upsertedFont = await network.upsertCustomFont({
        fontFamily: updatedFont.fontFamily,
        variant: updatedFont.variant,
        url: updatedFont.url,
        type: currentFontType
      });
      const updatedCustomFontsMap = [...customFontsMap];
      const fontMapIndex = updatedCustomFontsMap.findIndex((font) => font.type === currentFontType);
      if (fontMapIndex > -1) {
        updatedCustomFontsMap[fontMapIndex] = upsertedFont;
      } else {
        updatedCustomFontsMap.push(upsertedFont);
      }
      setCustomFontsMap(updatedCustomFontsMap);
      setIframeRefreshing(true);
      if (iframeRef.current) {
        // eslint-disable-next-line no-self-assign
        iframeRef.current.src = iframeRef.current.src;
      }
    } catch (error) {
      setToastState({ open: true, message: t('error_while_fetching_custom_website_fonts') });
    } finally {
      setDisabled(null);
    }
  };

  const getFontLabelForFontTyle = (fontType) => {
    switch (fontType) {
      case FONT_TYPES.DEFAULT: {
        return t('default_font');
      }
      case FONT_TYPES.HEADING: {
        return t('headings_titles');
      }
      case FONT_TYPES.SUB_HEADING: {
        return t('sub_headings');
      }
      case FONT_TYPES.PARAGRAPH: {
        return t('paragraphs');
      }
      default: {
        return t('select_font');
      }
    }
  };

  const getFontFamilyId = (index, data) => {
    const font = data[index];
    if (font === undefined) {
      return `noDataAvailable-${index}`;
    }
    return `${data[index].fontFamily}${index}`;
  };

  const renderSelectFontLabel = (fontType) => {
    const currentFont = customFontsMap.find((customFonts) => customFonts.type === fontType);
    const defaultFont = customFontsList.find((customFonts) => customFonts.isDefault);
    return (
      <div className={classes.customWebsiteFontsFormPopoverLabel} onClick={({ currentTarget: anchorElement }) => handlePopoverClick(anchorElement, fontType)}>
        <Typography>{currentFont ? ((currentFont.isDefault || currentFont.url === 'DEFAULT') ? t('default_font') : currentFont.fontFamily) : (defaultFont ? ((defaultFont.isDefault || defaultFont.url === 'DEFAULT') ? t('default_font') : defaultFont.fontFamily) : undefined)}</Typography>
      </div>
    );
  };

  const renderPreviewIframe = () => {
    let previewUrl = cataloguePreviewUrl;
    if (previewUrl) {
      previewUrl = new URL(previewUrl);
      if (!previewUrl.searchParams.has('userAgentOverride')) {
        previewUrl.searchParams.append('userAgentOverride', encodeURIComponent(OVERRIDDEN_USER_AGENT));
      }
      previewUrl = previewUrl.href;
    }
    return (
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        src={previewUrl}
        title={t('catalogue_preview')}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={false}
        className={classes.customWebsiteFontsPreviewIframe}
        onLoad={() => {
          setIframeLoading(false);
          setIframeRefreshing(false);
        }}
        sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation allow-modals allow-orientation-lock allow-pointer-lock allow-scripts allow-top-navigation allow-same-origin"
        style={{ display: (iframeLoading || iframeRefreshing) ? 'none' : undefined }}
      />
    );
  };

  const renderFontFamilyRow = ({ data, index, style }) => {
    const font = data[index];
    if (!font || !font.fontFamily) {
      return null;
    }
    const currentFont = customFontsMap.find((customFonts) => customFonts.type === currentFontType);
    return (
      <div
        key={index}
        style={style}
        onClick={() => onFontFamilyChange(font)}
        className={`${classes.customWebsiteFontsFormPopoverVirtualListItem} ${(!!currentFont && currentFont.fontFamily === font.fontFamily) ? classes.customWebsiteFontsFormPopoverVirtualListItemActive : ''}`}
      >
        <Typography>{(font.isDefault || font.url === 'DEFAULT') ? t('default_font') : font.fontFamily}</Typography>
        {(disabled === font.fontFamily) && <Loader small={true} style={{ marginLeft: "18px" }} />}
      </div>
    );
  };

  const renderSelectFontMenu = () => {
    const currentFontsList = (!!fontSearchQuery && fontSearchQuery.length > 0) ? customFontsList.filter(({ fontFamily }) => fontFamily.toLowerCase().includes(fontSearchQuery.toLowerCase())) : customFontsList
    return (
      <Fragment>
        <div className={classes.customWebsiteFontsFormPopoverHeading}>
          <Typography>
            {getFontLabelForFontTyle(currentFontType)}
          </Typography>
          <Typography>
            ({t('count_of_fonts', { count: customFontsList.length })})
          </Typography>
        </div>
        <div className={classes.customWebsiteFontsFormPopoverSearchContainer}>
          <TextField
            value={fontSearchQuery}
            onChange={({ target: { value: currentSearchQuery } }) => setFontSearchQuery(currentSearchQuery)}
            placeholder={t('search_for_a_font')}
            InputProps={{ disableUnderline: true }}
            className={classes.customWebsiteFontsFormPopoverSearch}
          />
        </div>
        <AutoSizer className={classes.customWebsiteFontsFormPopoverVirtualList}>
          {({ height, width }) => (
            <List
              height={height}
              itemData={currentFontsList}
              itemCount={currentFontsList.length}
              itemKey={getFontFamilyId}
              itemSize={48}
              width={width}
              overscanCount={5}
            >
              {renderFontFamilyRow}
            </List>
          )}
        </AutoSizer>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {(loading || !customFontsList || !customFontsList.length === 0) ? (
        <div className={classes.customWebsiteFontsPageLoader}>
          <Loader />
        </div>
      ): (
        <div className={classes.customWebsiteFontsPageContainer}>
          <div className={classes.customWebsiteFontsListContainer}>
            <FormControl variant="outlined" className={classes.customWebsiteFontsFormControl}>
              <div className={classes.customWebsiteFontsSelectLabel}>
                <Typography>{getFontLabelForFontTyle(FONT_TYPES.DEFAULT)}</Typography>
                <Typography
                  className={classes.customWebsiteFontsInfoPopoverLabel}
                  onMouseEnter={({ currentTarget: anchorElement }) => handleInfoPopoverOpen(anchorElement)}
                  onMouseLeave={handleInfoPopoverClose}
                  data-type={FONT_TYPES.DEFAULT}
                >
                  i
                </Typography>
                <Popover
                  style={{ pointerEvents: 'none' }}
                  open={!!infoPopoverAnchorElement && infoPopoverAnchorType === FONT_TYPES.DEFAULT}
                  anchorEl={infoPopoverAnchorElement}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={handleInfoPopoverClose}
                  disableRestoreFocus={true}
                >
                  <div className={classes.customWebsiteFontsInfoPopoverContent}>{t('this_is_the_default_font_for_the_entire_website')}</div>
                </Popover>
              </div>
              {renderSelectFontLabel(FONT_TYPES.DEFAULT)}
            </FormControl>
            <FormControl variant="outlined" className={classes.customWebsiteFontsFormControl}>
              <div className={classes.customWebsiteFontsSelectLabel}>
                <Typography>{getFontLabelForFontTyle(FONT_TYPES.HEADING)}</Typography>
                <Typography
                  className={classes.customWebsiteFontsInfoPopoverLabel}
                  onMouseEnter={({ currentTarget: anchorElement }) => handleInfoPopoverOpen(anchorElement)}
                  onMouseLeave={handleInfoPopoverClose}
                  data-type={FONT_TYPES.HEADING}
                >
                  i
                </Typography>
                <Popover
                  style={{ pointerEvents: 'none' }}
                  open={!!infoPopoverAnchorElement && infoPopoverAnchorType === FONT_TYPES.HEADING}
                  anchorEl={infoPopoverAnchorElement}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={handleInfoPopoverClose}
                  disableRestoreFocus={true}
                >
                  <div className={classes.customWebsiteFontsInfoPopoverContent}>{t('this_is_the_font_for_all_the_headings')}</div>
                </Popover>
              </div>
              {renderSelectFontLabel(FONT_TYPES.HEADING)}
            </FormControl>
            <FormControl variant="outlined" className={classes.customWebsiteFontsFormControl}>
              <div className={classes.customWebsiteFontsSelectLabel}>
                <Typography>{getFontLabelForFontTyle(FONT_TYPES.SUB_HEADING)}</Typography>
                <Typography
                  className={classes.customWebsiteFontsInfoPopoverLabel}
                  onMouseEnter={({ currentTarget: anchorElement }) => handleInfoPopoverOpen(anchorElement)}
                  onMouseLeave={handleInfoPopoverClose}
                  data-type={FONT_TYPES.SUB_HEADING}
                >
                  i
                </Typography>
                <Popover
                  style={{ pointerEvents: 'none' }}
                  open={!!infoPopoverAnchorElement && infoPopoverAnchorType === FONT_TYPES.SUB_HEADING}
                  anchorEl={infoPopoverAnchorElement}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={handleInfoPopoverClose}
                  disableRestoreFocus={true}
                >
                  <div className={classes.customWebsiteFontsInfoPopoverContent}>{t('this_is_the_font_for_all_the_subheadings')}</div>
                </Popover>
              </div>
              {renderSelectFontLabel(FONT_TYPES.SUB_HEADING)}
            </FormControl>
            <FormControl variant="outlined" className={classes.customWebsiteFontsFormControl}>
              <div className={classes.customWebsiteFontsSelectLabel}>
                <Typography>{getFontLabelForFontTyle(FONT_TYPES.PARAGRAPH)}</Typography>
                <Typography
                  className={classes.customWebsiteFontsInfoPopoverLabel}
                  onMouseEnter={({ currentTarget: anchorElement }) => handleInfoPopoverOpen(anchorElement)}
                  onMouseLeave={handleInfoPopoverClose}
                  data-type={FONT_TYPES.PARAGRAPH}
                >
                  i
                </Typography>
                <Popover
                  style={{ pointerEvents: 'none' }}
                  open={!!infoPopoverAnchorElement && infoPopoverAnchorType === FONT_TYPES.PARAGRAPH}
                  anchorEl={infoPopoverAnchorElement}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={handleInfoPopoverClose}
                  disableRestoreFocus={true}
                >
                  <div className={classes.customWebsiteFontsInfoPopoverContent}>{t('this_is_the_font_for_all_the_descriptions_and_long_texts')}</div>
                </Popover>
              </div>
              {renderSelectFontLabel(FONT_TYPES.PARAGRAPH)}
            </FormControl>
          </div>
          <div className={classes.customWebsiteFontsDividerContainer} />
          <div className={classes.customWebsiteFontsPreviewContainer}>
            <div className={classes.customWebsiteFontsPreviewHeader}>{t('preview')}</div>
            <div className={classes.customWebsiteFontsPreviewRenderer}>
              {(iframeLoading || iframeRefreshing) && (
                <div className={classes.customWebsiteFontsIframeLoader}>
                  <Loader />
                </div>
              )}
              {renderPreviewIframe()}
            </div>
          </div>
        </div>
      )}
      <Popover
        open={!!popoverAnchorElement && !!currentFontType}
        anchorEl={popoverAnchorElement}
        onClose={handlePopoverClose}
        hideBackdrop={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus={true}
        className={classes.customWebsiteFontsPopoverContainer}
        classes={{ paper: classes.customWebsiteFontsPopoverPaper, root: classes.customWebsiteFontsPopoverRoot }}
      >
        <div className={classes.customWebsiteFontsFormPopoverContent} ref={popoverContainerRef}>
          {!!currentFontType && renderSelectFontMenu(currentFontType)}
        </div>
      </Popover>
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({ open: false, message: '' });
        }}
      />
    </Fragment>
  );
}
