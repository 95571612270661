import React from 'react';

export default ({ color = '#00A674', size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android-Copy"
        transform="translate(-40.000000, -653.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g
          id="icons8-Material-Sharp-8ZSnZzc0rGjQ-50-000000"
          transform="translate(40.000000, 653.000000)"
        >
          <path
            d="M11.684595,4.10539823 L0,15.7899932 L0,20 L4.21000681,20 L15.8946018,8.31540504 L11.684595,4.10539823 Z M13.2635943,2.52639891 L15.7899932,0 L20,4.21000681 L17.4736011,6.73640572 L13.2635943,2.52639891 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
