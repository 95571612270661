
export const UPDATE_SELECTED_THEME = 'UPDATE_SELECTED_THEME';
export const SET_THEMES_META = 'SET_THEMES_META';
export const THEMES_API_HAS_ERROR = 'THEMES_API_HAS_ERROR';
export const FETCH_COMPANY_DETAILS = 'FETCH_COMPANY_DETAILS';
export const FETCH_COMPANY_DETAILS_SUCCESS = 'FETCH_COMPANY_DETAILS_SUCCESS';
export const FETCH_COMPANY_DETAILS_ERROR = 'FETCH_COMPANY_DETAILS_ERROR';

let themesMetaCache = null;
let themeScrollPosition = 0;

export const getThemesMetaCache = () => {
  return themesMetaCache;
};

export const deleteThemeFromCache = (id) => {
  if (themesMetaCache) {
    let tmpThemes = (themesMetaCache.themes || []).filter(x => x.id !== id)
    themesMetaCache.themes = tmpThemes
    themesMetaCache.total -= 1
  }
}
export const setThemesMetaCache = (themesMeta) => {
  themesMetaCache = themesMeta;
};
export const clearThemesMetaCache = () => {
  themesMetaCache = null;
  themeScrollPosition = 0;
};

export const getThemeScrollPosition = () => {
  return themeScrollPosition;
};
export const setThemeScrollPosition = (scrollPosition) => {
  themeScrollPosition = scrollPosition;
};

export const themeStateInitializer = (cache) => {
  if (cache) {
    return cache;
  }
  return {
    themes: [],
    total: null,
    dateUpdated: '',
    selectedTheme: null,
    companyDetails: {
      loading: false,
      error: false,
      data: null
    },
    error: null,
  };
};

export const setThemes = (dispatch, { themes, total, dateUpdated }) => dispatch({
  type: SET_THEMES_META,
  themes,
  total,
  dateUpdated
});
export const setErrorInThemes = (dispatch, error) => dispatch({
  type: THEMES_API_HAS_ERROR,
  error
});
export const setSelectedTheme = (dispatch, selectedTheme) => dispatch({
  type: UPDATE_SELECTED_THEME,
  selectedTheme
});

export const themesMetaReducer = (state, action) => {
  switch (action.type) {
    case SET_THEMES_META: {
      return {
        ...state,
        error: null,
        themes: [...state.themes, ...action.themes],
        total: action.total || state.total,
        dateUpdated: action.dateUpdated
      };
    }
    case UPDATE_SELECTED_THEME: {
      return {
        ...state,
        selectedTheme: action.selectedTheme
      };
    }
    case THEMES_API_HAS_ERROR: {
      return {
        ...state,
        error: action.error
      };
    }
    case FETCH_COMPANY_DETAILS: {
      return {
        ...state,
        companyDetails: {
          loading: true,
          error: false,
          data: null
        }
      }
    }
    case FETCH_COMPANY_DETAILS_SUCCESS: {
      return {
        ...state,
        companyDetails: {
          loading: false,
          error: false,
          data: action.data
        }
      }
    }
    case FETCH_COMPANY_DETAILS_ERROR: {
      return {
        ...state,
        companyDetails: {
          loading: false,
          error: true,
          data: action.data || null
        }
      }
    }
    default:
      return state;
  }
}