import React from 'react';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../node_modules/@material-ui/core/Button/Button';
import green from '@material-ui/core/colors/green';
import Loader from '../Loader';
import { getI18N } from '../../i18N';

function Dialog({
  open,
  onClose,
  handleConfirm,
  title,
  description,
  confirmText,
  cancelText,
  disabled,
}) {
  const { t } = getI18N();
  return (
    <MaterialDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: 'rgb(178, 191, 214)' }}>
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions
        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
      >
        <Button
          onClick={handleConfirm}
          style={{ color: green.A100 }}
          disabled={disabled}
          autoFocus
        >
          <div style={{ marginRight: disabled ? '5px' : '30px' }}>
            {confirmText || t('confirm')}
          </div>
          {disabled && <Loader small />}
        </Button>
        {cancelText && (
          <Button onClick={onClose} disabled={disabled} style={{ marginLeft: '5px' }}>
            {cancelText || t('cancel')}
          </Button>
        )}
      </DialogActions>
    </MaterialDialog>
  );
}

export default Dialog;
