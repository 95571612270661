import React, { useState, useEffect } from 'react';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { navigationBarColor, statusBarColor } from 'qs-data/globalConstants';

import { useHistory } from 'react-router-dom';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';
import './styles.scss';
import { getToken } from 'qs-data/util';
import UsageReport from './UsageReport';
import network from 'qs-data/network';
import Toast from 'qs-common/Alerts/Toast';
import Loader from 'qs-common/Loader';
import SmsSettingsCache from './Cache';
import { getI18N } from '../../i18N';

const NAVBAR_HEIGHT = 55;

const SmsSettings = () => {
  const { t } = getI18N()
  const history = useHistory();
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const [loading, setLoading] = useState(true);
  const [smsSettingsData, setSmsSettingsData] = useState(
    SmsSettingsCache.getSmsSettingsDetailsCache()
  );
  const desktop = !source || source === 'desktop';

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#4DA47A',
        color: '#FFFFFF',
        boxShadow: false,
        title: t('sms_ettings'),
        enableBoxShadow: false,
        hideBack: desktop,
      },
    });
    setNavigationBarColor(navigationBarColor);
    setStatusBarColor(statusBarColor);
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  useEffect(() => {
    getSmsSettingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSmsSettingsData = async () => {
    if (SmsSettingsCache.getSmsSettingsDetailsCache()) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const data = await network.getSmsSettingsData();
      SmsSettingsCache.setSmsSettingsDetailsCache(data);
      setSmsSettingsData(data);
      setLoading(false);
    } catch (error) {
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
      setSmsSettingsData(null);
      setLoading(false);
    }
  };

  const getMonthsArray = (contentArray) => {
    if (contentArray.length > 2) {
      return contentArray.slice(0, 2);
    }
    return contentArray;
  };

  const updatedMonthsList =
    smsSettingsData && smsSettingsData.eligibleMonths
      ? getMonthsArray(smsSettingsData.eligibleMonths)
      : [];

  if (!(updatedMonthsList && Array.isArray(updatedMonthsList))) {
    return null;
  }

  const viewAllOlderMonths = () => {
    history.push({
      pathname: `/sms-usage-report`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
      state: {
        months: smsSettingsData.eligibleMonths,
      },
    });
  };

  if (loading) {
    return (
      <div className="loader">
        <Loader />;
      </div>
    );
  }

  const attributes =
    smsSettingsData && smsSettingsData.contactSupport
      ? { href: smsSettingsData.contactSupport }
      : {};

  return (
    <>
      <div className="smsSettingContainer">
        <div className="smsWalletBalanceContainer">
          <div className="title">{t('sms_wallet_balance')}</div>
          <div className="balanceAmount">{`$ ${
            ((smsSettingsData || {}).balanceDetails || {}).balance ? smsSettingsData.balanceDetails.balance
              : 0 
          }`}</div>
          <a {...attributes} className="contactSupport" target="_blank" rel="noopener noreferrer">
            {t('contact_support')}
          </a>
          <div className="description">{t('to_recharge_contact_support')}</div>
        </div>
        <UsageReport months={updatedMonthsList} />
        {smsSettingsData &&
          smsSettingsData.eligibleMonths &&
          smsSettingsData.eligibleMonths.length > 2 && (
            <div className="viewOlderMonths" onClick={viewAllOlderMonths}>
              {t('view_older_months')}
            </div>
          )}

        <div className="smsChargesContainer">
          <div className="title">{t('sms_charges')}</div>
          <div className="subtitle">{t('cost_per_message')}</div>
          <div className="countryCostList">
            {smsSettingsData &&
              (smsSettingsData.smsRates || []).map((sms, index) => {
                return (
                  <div className="countryCostRow" key={index}>
                    <div className="country">{`${sms.displayName}`}</div>
                    <div className="cost">{`$ ${sms.smsCharge}`}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default SmsSettings;
