export const filterOptions = {
  ALL: {
    id: 1,
    title: 'ALL',
  },
  PAID: {
    id: 2,
    title: 'PAID',
  },
  JOINED: {
    id: 3,
    title: 'JOINED',
  },
  INVITED: {
    id: 4,
    title: 'INVITED',
  },
};

export const navigationBarColor = '#2b2f39';
export const statusBarColor = '#2b2f39';
