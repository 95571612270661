import React, { useState, useEffect } from 'react';
import './style.css';
import CustomTextField from 'qs-common/CustomTextField';
import useStyles from 'qs-common/CustomStyles';
import Loader from 'qs-common/Loader';
import Button from '@material-ui/core/Button';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import Toast from 'qs-common/Alerts/Toast';
import network from 'qs-data/network';
import { withRouter } from 'react-router';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { getI18N } from '../../i18N';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';

const GoogleMerchantCenterDetails = () => {
  const { t } = getI18N();
  const classes = useStyles();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [merchantDetails, setMerchantDetails] = useState({
    googleMerchantId: '',
    gmcDomainVerificationTag: '',
  });
  const [googleMerchantCenterDetailsLoading, setGoogleMerchantCenterDetailsLoading] =
    useState(true);

  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#222B36' : '#4DA47A',
        color: '#FFFFFF',
        title: t('google_merchant_center'),
        height: NAVBAR_HEIGHT,
        boxShadow: desktop ? 'none' : undefined,
        hideBack: desktop,
        googleMerchantCenter: true,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  useEffect(() => {
    network
      .getGoogleMerchantCenterDetails()
      .then((credentials) => {
        setMerchantDetails(credentials);
      })
      .catch(() => {
        setToastState({
          open: true,
          message: t('invalid_credentials'),
        });
      })
      .finally(() => {
        setGoogleMerchantCenterDetailsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!merchantDetails.googleMerchantId && !merchantDetails.gmcDomainVerificationTag) {
      return setErrors({
        merchantID: t('merchant_id_field_cant_be_left_empty'),
        domainMeta: t('domain_name_meta_field_cant_be_left_empty'),
      });
    }
    if (!merchantDetails.googleMerchantId) {
      return setErrors({
        ...errors,
        merchantID: t('merchant_id_field_cant_be_left_empty'),
      });
    }
    if (!merchantDetails.gmcDomainVerificationTag) {
      return setErrors({
        ...errors,
        domainMeta: t('domain_name_meta_field_cant_be_left_empty'),
      });
    }
    setErrors({});
    setLoading(true);
    network
      .postGoogleMerchantCenterDetails(merchantDetails)
      .then(() => {
        setToastState({
          open: true,
          message: t('merchant_id_and_domain_name_sent_successfully'),
        });
      })
      .catch((err) => {
        if (err.response) {
          setToastState({
            open: true,
            message: t('something_went_wrong'),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onGoogleMerchantIdChange = (e) => {
    if (!merchantDetails.googleMerchantId) {
      setErrors({
        ...errors,
        merchantID: '',
      });
    }

    const { name, value } = e.target;
    setMerchantDetails((merchantDetails) => ({
      ...merchantDetails,
      [name]: value,
    }));
  };

  const onGmcDomainChange = (e) => {
    if (!merchantDetails.gmcDomainVerificationTag) {
      setErrors({
        ...errors,
        domainMeta: '',
      });
    }

    const { name, value } = e.target;
    setMerchantDetails((merchantDetails) => ({
      ...merchantDetails,
      [name]: value,
    }));
  };

  return (
    <div className="googleMerchantCenterDetailsContainer">
      {googleMerchantCenterDetailsLoading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="merchantDetailsContainer">
          <form className={classes.root} noValidate autoComplete="off" onSubmit={onSubmit}>
            <div className="merchantIdText">
              <h1>{t('merchant_id')}</h1>
            </div>
            <CustomTextField
              fullWidth
              style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}
              value={merchantDetails.googleMerchantId}
              margin="normal"
              name="googleMerchantId"
              onChange={onGoogleMerchantIdChange}
              variant="filled"
              error={!!errors['merchantID']}
              helperText={errors['merchantID']}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.fieldBackgroundColor,
                  root: classes.inputRoot,
                },
              }}
            />
            <div className="merchantIdText">
              <h1>{t('domain_verification_meta')}</h1>
            </div>
            <CustomTextField
              fullWidth
              style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}
              value={merchantDetails.gmcDomainVerificationTag}
              margin="normal"
              name="gmcDomainVerificationTag"
              onChange={onGmcDomainChange}
              variant="filled"
              error={!!errors['domainMeta']}
              helperText={errors['domainMeta']}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                  input: classes.fieldBackgroundColor,
                },
              }}
            />
            {loading ? (
              <div style={{ marginLeft: 24, marginTop: 24 }}>
                <Loader small />
              </div>
            ) : (
              <Button
                type="submit"
                variant="contained"
                size="large"
                style={{ marginLeft: 15, marginTop: 15 }}
                className={classes.button}
              >
                {t('enable')}
              </Button>
            )}
          </form>
        </div>
      )}

      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
};
export default withRouter(GoogleMerchantCenterDetails);
