import React, { Fragment } from 'react';
import DialogBox from "qs-common/DialogBox";
import "./style.scss";
import { getI18N } from '../../../i18N';

export default function ({ showDialog, onClose, successMessage }) {
    const { t } = getI18N();
    return showDialog && <DialogBox
        type={"simple"}
        dialogId={'#referal-redeemed'}
        show={showDialog}
        width={300}
        onSuccess={onClose}
        onClose={onClose}
        secondaryBtnText={t('close')}
        hidePrimaryButton={true}
    >
        <Fragment>
            <div className='referralRedeemedDialogContent'>
                <p className='dialogTitle'>{t('congratulations')}</p>
                <p className='dialogContent'>{successMessage ? successMessage : t('you_have_successfully_redeemed_referral_continue_using_quicksell_and_grow_your_business')}</p>
            </div>
        </Fragment>
    </DialogBox>
}
