import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import CustomCheckbox from 'qs-common/CustomCheckbox';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import Label from 'qs-common/CustomLabel';
import Utility from 'qs-helpers/utility';
import { getI18N } from '../../../../i18N';
import "./style.scss";

function ShippingZones({ homeLocation, onUpdateShippingZones }) {

    const [progressBar, setProgressBar] = useState(true);
    const [shippingZones, onShippingZonesUpdate] = useState([]);
    const [errorComponent, showErrorComponent] = useState(false);

    const { t } = getI18N();

    useEffect(() => {
        fetchShippingZones();
    }, [homeLocation]);

    useEffect(() => {
        onUpdateShippingZones(shippingZones);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingZones]);

    /* Fetch Shipping zones api call */
    const fetchShippingZones = async () => {
        setProgressBar(true);
        showErrorComponent(false);
        ShippingData.getShippingZones().then((data) => {
            const sortedZones = Utility.getSortedZones(data);
            onShippingZonesUpdate(sortedZones);
        }).catch(() => {
            showErrorComponent(true);
        }).then(() => {
            setProgressBar(false);
        });
    }

     /* Update Shipping zone api call */
     const updateShippingZone = async (zone) => {
        ShippingData.updateShippingZone({
            "id": zone.id,
            "enabled": zone.enabled
        }).then(() => {
            setProgressBar(false)
        });
    }

    /* Shipping Zone update */
    const updateShippingZoneCheckState = useCallback(
        e => {
            const newZones = Array.from(shippingZones);
            const newZone = newZones.filter(obj => {
                return e.target.name.includes(Utility.getShippingZoneDisplayName(obj))
            });
            newZone[0].enabled = e.target.checked;
            onShippingZonesUpdate(newZones);
            updateShippingZone(newZone[0]);
            onShippingZonesUpdate(newZones);
        },
        [shippingZones]
    );

    const renderList = () => {
        if (progressBar) {
            return <CircularProgress size={24} color="primary" style={{ marginTop: '8px' }} />
        } else {
            return errorComponent ? <CustomErrorComponent style={{paddingBottom: '8px'}} onRetryClick={fetchShippingZones} /> :
                shippingZones.map(function (item, index) {
                    return <CustomCheckbox
                        key={index}
                        checked={isNil(item.enabled) ? false : item.enabled}
                        label={Utility.getShippingZoneDisplayName(item)}
                        handleChange={updateShippingZoneCheckState}
                    />
                })
        }
    }

    return <div className='shippingItemContainer shippingZonesContainer'>
        <Label value={t('shipping_zones')} />
        {renderList()}
    </div>
}

export default withRouter(ShippingZones);