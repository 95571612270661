import Native from './shared';

class IOSImpl extends Native {
  constructor() {
    super();
    this.cachedPromise = {};
    this.listenForMessageFromIos();
  }

  static isIOS() {
    if (
      typeof window === 'undefined' ||
      typeof window.webkit === 'undefined' ||
      typeof window.webkit.messageHandlers === 'undefined' ||
      typeof window.webkit.messageHandlers.iOS === 'undefined' ||
      typeof window.webkit.messageHandlers.iOS.postMessage !== 'function'
    ) {
      return false;
    }
    return true;
  }

  handleMessageType({ messageType, messageData }) {
    switch (messageType) {
      case 'isFeatureAllowed': {
        const { isAllowed } = messageData || {};
        const { cachedPromiseResolve } = this.cachedPromise[messageType] || {};
        if (typeof cachedPromiseResolve !== 'function') {
          console.warn(`Expected a function but got ${typeof cachedPromiseResolve}`);
          break;
        }
        cachedPromiseResolve(isAllowed || false);
        delete this.cachedPromise[messageType];
        break;
      }
      case 'hasPermission': {
        const { permissionName, isAllowed } = messageData || {};
        const { cachedPromiseResolve } = this.cachedPromise[`${messageType}_${permissionName}`] || {};
        if (typeof cachedPromiseResolve !== 'function') {
          console.warn(`Expected a function but got ${typeof cachedPromiseResolve}`);
          break;
        }
        cachedPromiseResolve(isAllowed || false);
        delete this.cachedPromise[messageType];
        break;
      }
      case 'requestPermission': {
        const { isAllowed } = messageData || {};
        const permissionResultEvent = new CustomEvent('permissionResult', {
          detail: { permission: isAllowed },
        });
        document.dispatchEvent(permissionResultEvent);
        break;
      }
      case 'fetchContactList': {
        const { contactList = [] } = messageData || {};
        const { cachedPromiseResolve } = this.cachedPromise[messageType] || {};
        if (typeof cachedPromiseResolve !== 'function') {
          console.warn(`Expected a function but got ${typeof cachedPromiseResolve}`);
          break;
        }
        let contactListResult = !!contactList && contactList.length > 0 ? [...contactList] : [];
        if (contactListResult.some(({ id }) => !id)) {
          contactListResult = contactListResult.map((contact, index) => ({ ...contact, id: index }));
        }
        cachedPromiseResolve(contactListResult);
        delete this.cachedPromise[messageType];
        break;
      }
      default:
        break;
    }
  }

  listenForMessageFromIos() {
    window.onMessageFromIOS = (receivedMessage) => {
      if (!receivedMessage) {
        return;
      }
      this.handleMessageType(typeof receivedMessage === 'string' ? JSON.parse(receivedMessage) : receivedMessage);
    };
  }

  goBack() {
    window.webkit.messageHandlers.iOS.postMessage({ messageType: 'goBack' });
  }

  setNavigationBarColor(color) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'changeBottomBarColor',
      messageParams: { color },
    });
  }

  setStatusBarColor(color) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'changeTopBarColor',
      messageParams: { color },
    });
  }

  seatReserved(webinarName, startTime) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'seatReserved',
      messageParams: { webinarName, startTime },
    });
  }

  initiateGoogleLogin() {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'initiateGoogleLogin',
      messageParams: {},
    });
  }

  shareIntent(url, content, contentType) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'shareIntent',
      messageParams: { url, content, contentType },
    });
  }

  isFeatureAllowed(feature) {
    return new Promise((resolve) => {
      this.cachedPromise['isFeatureAllowed'] = { cachedPromiseResolve: resolve };
      window.webkit.messageHandlers.iOS.postMessage({
        messageType: 'isFeatureAllowed',
        messageParams: { feature },
      });
    });
  }

  showPremiumFeatureDialog(feature) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'showPremiumFeatureDialog',
      messageParams: { feature },
    });
  }

  shareReferral(content) {
    this.shareIntent(null, content, 'text/plain');
  }

  shareImage(url) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'shareImage',
      messageParams: { url },
    });
  }

  async getIdToken(callbackFunctionName) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'getIdToken',
      messageParams: { callbackFunctionName },
    });
  }

  async hasPermission(permissionName) {
    return new Promise((resolve) => {
      this.cachedPromise[`hasPermission_${permissionName}`] = { cachedPromiseResolve: resolve };
      window.webkit.messageHandlers.iOS.postMessage({
        messageType: 'hasPermission',
        messageParams: { permissionName },
      });
    });
  }

  async requestPermission(permissionName) {
    window.webkit.messageHandlers.iOS.postMessage({
      messageType: 'requestPermission',
      messageParams: { permissionName },
    });
  }

  async fetchContactList() {
   return new Promise((resolve) => {
      this.cachedPromise['fetchContactList'] = { cachedPromiseResolve: resolve };
      window.webkit.messageHandlers.iOS.postMessage({
        messageType: 'fetchContactList',
        messageParams: {},
      });
    });
  }
}

export default IOSImpl;
