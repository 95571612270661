export default async function promiseWithTimeout(promise, timeout) {
  const data = await Promise.race([
    new Promise((resolve, reject) => {
      promise.then(resolve, reject);
    }),
    new Promise((resolve) => {
      setTimeout(() => resolve(null), timeout);
    }),
  ]);

  return data;
}
