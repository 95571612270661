import React from 'react';

export default ({ width = 85, height = 82 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 85 82"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons8-diamond</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android-Copy-5"
        transform="translate(-271.000000, -1246.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group-3" transform="translate(23.000000, 1246.000000)">
          <g id="icons8-diamond">
            <g transform="translate(248.000000, 0.000000)" id="Shape">
              <path
                d="M41.8407872,0 L22.4480911,20.6433569 L26.780058,0 L41.8407872,0 Z M58.1850635,0 L62.600738,20.6433569 L43.4173166,0 L58.1850635,0 Z M61.7148141,25.0419579 L23.3340169,25.0419579 L42.5313908,4.3496495 L61.7148141,25.0419579 Z M17.9068532,25.0419579 L0.132540455,25.0419579 L22.9363973,0.993006109 L17.9068532,25.0419579 Z M18.116126,28.6223775 L35.4928192,74.5524479 L0,28.6223775 L18.116126,28.6223775 Z M21.9946652,28.6223775 L63.0681164,28.6223775 L42.5313908,82 L21.9946652,28.6223775 Z M67.1419777,25.0419579 L62.0217488,0.895104905 L84.7837501,25.0419579 L67.1419777,25.0419579 Z M49.5839149,74.5174833 L66.953631,28.6223775 L85,28.6223775 L49.5839149,74.5174833 Z"
                style={{ mixBlendMode: 'soft-light' }}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
