import React from 'react';
import { useHistory } from "react-router-dom";
import { ReactComponent as NextIcon } from 'qs-assets/images/right_arrow.svg';
import CustomDivider from 'qs-common/CustomDivider';
import Utility from 'qs-helpers/utility';
import { getI18N } from '../../../i18N';
import "./style.scss";
import { getToken } from 'qs-data/util';

export default function ({ shippingModes = [], shippingZones = [], weightUnit, source = 'mobile', languageCode = 'en' }) {
    const history = useHistory();
    const { t } = getI18N();

    function addPrice(item) {
        history.push({
            pathname: '/shipping-rates',
            search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
            state: {
                mode: item.mode,
                zone: item.zone,
                weightUnit: weightUnit
            }
        });
    }

    const shippingProfiles = [];
    shippingModes.forEach(function (mode) {
        if (mode.enabled) {
            const sortedZones = Utility.getSortedZones(shippingZones);
            sortedZones.forEach(function (zone) {
                if (zone.enabled) {
                    shippingProfiles.push({
                        mode: mode,
                        zone: zone
                    })
                }
            })
        }
    })

    return <div className='shippingProfiles'>
        {
            shippingProfiles.map(function (item, index) {
                return <div key={index} onClick={() => addPrice(item)}>
                    <div className='singleShippingProfile'>
                        <span className='shippingProfileText' >{item.mode.value + ' - ' + Utility.getShippingZoneDisplayName(item.zone)}</span>
                        <div className='shippingProfileEnterRates'>
                            <p className='rateText'>{t('enter_rates')}</p>
                            <NextIcon fill="#FFF" width="20" height="20" />
                        </div>
                    </div>
                    {index === shippingProfiles.length - 1 ? null :
                        <CustomDivider customStyle={{ marginBottom: '16px', marginTop: '16px' }} />}
                </div>
            })
        }
    </div>
} 