import React, { useState, useEffect } from 'react';
import Loader from '../../common/Loader';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import Network from '../../data/network';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';
import Toast from '../../common/Alerts/Toast';
import GenerateReports from '../../components/Report/GenerateReports';
import './style.css';
import network from '../../data/network';
import { handleError } from 'qs-common/error';
import AddUserEmail from '../../components/Report/AddUserEmail';
import { getI18N } from '../../i18N';

const TOAST_DURATION = 3000;

export default function Reports() {
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const [updatingStatus, setUpdating] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [email, setEmail] = useState('');
  const [showDialogue, setShowDialogue] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('reports'),
        hideBack: desktop,
      },
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  useEffect(() => {
    const callApi = async () => {
      setUpdating(true);
      try {
        const result = await Network.getDailyReportStatus();
        setEmail(result && result.email ? result.email : '');
        setUpdating(false);
      } catch (err) {
        setUpdating(false);
        console.error(err);
        setToastState({
          open: true,
          message: t('failed_to_get_current_status'),
        });
      }
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUpdating]);

  const onSendVerificationEmail = (value) => {
    network
      .sendVerificationEmail(value)
      .then(() => {
        setToastState({
          open: true,
          message: 'Verification email sent successfully',
        });
      })
      .catch((err) => {
        setToastState({
          open: true,
          message: t('an_error_occured_error_message', { error: err.toString() }),
        });
        handleError(err);
      })
      .finally(() => {
        setShowDialogue(false);
      });
  };

  const renderFooter = () => {
    if (updatingStatus && email.length === 0) {
      return <Loader small style={{ margin: 10 }} />;
    } else {
      return <p className="report-note">{t('you_will_receive_the_reports_on_email')}</p>;
    }
  };

  return (
    <div className="report-container">
      <GenerateReports
        source={source}
        reportType={'sales-report'}
        trackEvent={'sales_report_downloaded'}
        email={email}
        showDialogue={() => setShowDialogue(true)}
      />
      <GenerateReports
        source={source}
        reportType={'visitor-report'}
        trackEvent={'visitor_report_downloaded'}
        email={email}
        showDialogue={() => setShowDialogue(true)}
      />
      {renderFooter()}
      {showDialogue && (
        <AddUserEmail
          setToastState={setToastState}
          onSendVerificationEmail={onSendVerificationEmail}
          setShowDialogue={setShowDialogue}
        />
      )}
      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={TOAST_DURATION}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}
