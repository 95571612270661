const contactListMap = new Map();

let contactList = [];

export const setContactList = (contacts) => {
  contactList = contacts;
};

export const getContactListMap = () => contactListMap;

export const getContactList = () => {
  return contactList;
};

export const getNameFromContactList = (phoneNumber = '', phoneUtilLib) => {
  let name = '';
  let phoneNumberLength = 0;
  const contacts = getContactList();
  if (!phoneUtilLib) {
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      if (
        phoneNumber.length >= (contact.phoneNumber || '').length &&
        phoneNumber.includes(contact.phoneNumber)
      ) {
        if (contact.phoneNumber.length >= phoneNumberLength) {
          name = contact.name;
          contactListMap.set(phoneNumber, name);
          phoneNumberLength = contact.phoneNumber.length;
        }
      } else if (
        contact.phoneNumber.length >= phoneNumber.length &&
        contact.phoneNumber.includes(phoneNumber)
      ) {
        if (contact.phoneNumber.length >= phoneNumberLength) {
          name = contact.name;
          contactListMap.set(phoneNumber, name);
          phoneNumberLength = contact.phoneNumber.length;
        }
      }
    }
    if (!name) {
      return '';
    }
    return name;
  } else {
    const phoneUtil = phoneUtilLib.PhoneNumberUtil.getInstance();
    try {
      let formattedPhoneNumber = {
        phoneNumber: '',
        countryCode: '',
      };

      if (!phoneNumber) return;
      const parsedNumber = phoneUtil.parse(phoneNumber, '');
      formattedPhoneNumber = {
        phoneNumber: parsedNumber.getNationalNumber(),
        countryCode: parsedNumber.getCountryCode(),
        number: phoneUtil.format(parsedNumber, 'E164'),
      };

      for (let i = 0; i < contacts.length; i++) {
        const contact = contacts[i];
        if (
          contact.phoneNumber &&
          formattedPhoneNumber.phoneNumber &&
          formattedPhoneNumber.countryCode &&
          (contact.phoneNumber.replace('+', '') ===
            `${formattedPhoneNumber.countryCode}${formattedPhoneNumber.phoneNumber}` ||
            contact.phoneNumber === formattedPhoneNumber.phoneNumber ||
            contact.phoneNumber === formattedPhoneNumber.number ||
            contact.phoneNumber === phoneNumber)
        ) {
          contactListMap.set(phoneNumber, contact.name);
          return contact.name;
        }
      }
      return '';
    } catch (error) {
      return '';
    }
  }
};
