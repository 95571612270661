import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

let apis = {
  facebookPixelIdEndPoint: '/v1/experiment/facebook-pixel-id'
};

/* Facebook Pixel code */

const getFacebookPixelId = () => {
  return ApiWrapper.getDataPromise(apis.facebookPixelIdEndPoint, 'facebookPixelId', CacheKeys.facebookPixelId);
};

const setFacebookPixelId = (facebookPixelId) => {
  return ApiWrapper.postDataPromise(apis.facebookPixelIdEndPoint, {
    facebookPixelId
  }, CacheKeys.facebookPixelId);
};

export default {
  getFacebookPixelId,
  setFacebookPixelId
}