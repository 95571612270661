import { WindowPostMessageProxy } from 'window-post-message-proxy';

export const POST_MESSAGE_SLUGS = {
  RefreshToken: 'refresh-token',
  ProductType: 'product-type',
};

export const windowPostMessageProxy = new WindowPostMessageProxy({
  name: 'custompages',
  suppressWarnings: true,
});
