import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  navBtn: {
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: '#4da47a',
    color: '#FFFFFF',
    height: 38,
    '&:hover, &:focus': {
      backgroundColor: '#387256',
    },
    '&:active': {
      backgroundColor: 'none',
    },
  },
  typography: {
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
  },
});

export default useStyles;
