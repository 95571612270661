import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router';
import useStyles from 'qs-common/CustomStyles';
import payments from '../../data/payments';
import Loader from '../../common/Loader';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from '../../common/Contexts/AppContext';
import './style.css';
import PaymentRow from './paymentRow';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';
import { Button } from '@material-ui/core';
import { getI18N } from '../../i18N';

const NAVBAR_HEIGHT = 55;

function Payments() {
  const [paymentModes, setPaymentModes] = useState([]);
  const history = useHistory();
  const locationQueryString = useSearchParamsQuery();
  const classes = useStyles();
  const source = locationQueryString.get('source');
  const languageCode = locationQueryString.get('languageCode');
  const desktop = !source || source === 'desktop';
  const showDoneButton = locationQueryString.get('showDoneButton');

  const { t } = getI18N();

  useEffect(() => {
    payments.getPaymentMethods(true).then((response) => {
      if (Array.isArray(response)) {
        setPaymentModes((paymentModes) => {
          return paymentModes.concat(response);
        });
      }
    });
  }, []);

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('payment_modes'),
        hideBack: desktop || !!showDoneButton,
        height: NAVBAR_HEIGHT,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#3f4653');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop, showDoneButton]);

  const onClickDone = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!showDoneButton) {
      return;
    }

    history.goBack();
  };

  return (
    <div className="pageBackground">
      {paymentModes.length === 0 ? (
        <div className="loaderContainer">
          <Loader />
        </div>
      ) : null}
      {paymentModes.map((paymentMode, i) => {
        return <PaymentRow key={i} paymentMode={paymentMode} source={source} languageCode={languageCode} />;
      })}
      {showDoneButton && paymentModes.length ? (
        <div className="done-button-section">
          <Button className={classes.button} size="large" variant="contained" onClick={onClickDone}>
            {t('done')}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(Payments);
