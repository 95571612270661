import React from 'react';
import { priceFormatter } from '../Product/components/PriceInfo';
import './styles.scss';
import { Button } from '@material-ui/core';
import { confirmOrder } from '../API';
import Loader from 'qs-common/Loader';
import { getI18N } from '../../../i18N';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';

export default function Total({ totalPrice, order, onConfirmSuccess }) {
  const { t } = getI18N();
  const [busy, setBusy] = React.useState(false);
  const [, dispatch] = useAppContext();

  const onClick = async () => {
    setBusy(true);
    try {
      await confirmOrder(order.id);
      onConfirmSuccess(t('order_confirmed_successful'));
      setBusy(false);
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          background: '#0F151B',
          color: '#FFFFFF',
          title: t('create_order'),
          hideBack: false,
          height: NAVBAR_HEIGHT,
          showDeleteButton: true,
          deleteBtnStyle: {
            opacity: '0',
          },
          onDeleteIconClick: null,
          titleSubTitleStyle: {
            alignItems: 'center',
          },
          overrideTitleClassStyle: {
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: '700',
          },
          arrowBackButtonStyle: {
            marginRight: '0px',
          },
        },
      });
    } catch (error) {
      console.error(t('error_confirming_order'), error);
      setBusy(false);
    }
  };
  return (
    <div className="order-total-price">
      <div className="order-total-price-block">
        <div>{t('order_total')}</div>
        <div>{priceFormatter(totalPrice)}</div>
      </div>
      <div>
        <Button
          onClick={onClick}
          loadin
          variant="contained"
          color="primary"
          size="medium"
          className="order-total-cta"
          disabled={!order?.items || order?.items?.length === 0 || busy}
        >
          {busy ? <Loader style={{ marginLeft: '8px' }} small /> : t('confirm_order')}
        </Button>
      </div>
    </div>
  );
}
