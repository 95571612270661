import React, { useEffect, useState } from 'react';
import Card from "@material-ui/core/Card";
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../OtherPages/constants';

import { useStyles } from './styles';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';

import network from 'qs-data/network';
import CompanyData from 'qs-data/companyData';
import Loader from 'qs-common/Loader';
import Toast from 'qs-common/Alerts/Toast';

import { useAppContext } from '../../common/Contexts/AppContext';
import JewelleryPriceSectionRow from './JewelleryPriceSectionRow';
import { ENTITY_TYPE } from '../PrivacySettings/privacySettingConstants'; 
import { JEWELLERY_PRICE_DISPLAY_TOGGLES } from './constants';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { getI18N } from '../../i18N';

const NAVBAR_HEIGHT = 55;

export default function JewelleryPriceDisplay() {
  const classes = useStyles();
  const [jewelleryPriceDisplayStatus, setJewelleryPriceDisplayStatus] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const [toastState, setToastState] = useState({ open: false, message: '' });
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(null);
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';

  const { t } = getI18N();
  
  useEffect(() => {
    setLoading(true);
    CompanyData.getCompanyDetails()
      .then((companyDetailsResponse) => {
        setCompanyDetails({ ...companyDetailsResponse })
      })
      .catch(() => {
        setToastState({ open: true, message: t('error_while_fetching_company_details') });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyDetails.id) {
      network.getJewelleryPriceDisplayStatus({
        entityType: ENTITY_TYPE.COMPANY,
        entityId: companyDetails.id
      }).then((jewelleryPriceDisplayResponse) => {
        setJewelleryPriceDisplayStatus((!!jewelleryPriceDisplayResponse && Object.keys(jewelleryPriceDisplayResponse).length > 0) ? jewelleryPriceDisplayResponse : {});
      }).catch(() => {
        setToastState({ open: true, message: t('error_while_fetching_jewellery_price_config') });
      }).finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyDetails]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : PRIMARY_COLOR,
        color: SECONDARY_COLOR,
        display: desktop ? 'none' : undefined,
        title: desktop ? '' : t('jewellery_price_display'),
        height: desktop ? 0 : NAVBAR_HEIGHT,
        hideBack: desktop,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: desktop ? '100%' : `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: desktop ? 0 : `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor(PRIMARY_COLOR);
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  const onChange = (fieldName) => {
    if (!fieldName || !!disabled || loading) {
      return;
    }
    setDisabled(fieldName);
    const updatedJewelleryPriceDisplayStatus = {
      ...jewelleryPriceDisplayStatus,
      [fieldName]: !jewelleryPriceDisplayStatus[fieldName]
    }
    network.setJewelleryPriceDisplayStatus({
      jewelleryPriceConfig: updatedJewelleryPriceDisplayStatus,
      entityId: companyDetails.id,
      entityType: ENTITY_TYPE.COMPANY
    }).then(() => {
      setJewelleryPriceDisplayStatus(updatedJewelleryPriceDisplayStatus);
    }).catch(() => {
      setToastState({ open: true, message: t('error_while_saving_jewellery_price_config') });
    }).finally(() => {
      setDisabled(null);
    });
  }

  return (
    <div className={classes.jewelleryPricePageContainer}>
      {loading ? (
        <div className={classes.jewelleryPricePageLoader}>
          <Loader />
        </div>
      ): JEWELLERY_PRICE_DISPLAY_TOGGLES.map((jewelleryPriceDisplayToggle) => {
        return {
          ...jewelleryPriceDisplayToggle,
          checked: !jewelleryPriceDisplayStatus[jewelleryPriceDisplayToggle.fieldName],
          nestedToggles: (jewelleryPriceDisplayToggle.nestedToggles || []).map((nestedJewelleryPriceDisplayToggle) => {
            return {
              ...nestedJewelleryPriceDisplayToggle,
              checked: !jewelleryPriceDisplayStatus[nestedJewelleryPriceDisplayToggle.fieldName]
            };
          })
        }
      }).map((jewelleryPriceDisplayToggle, index) => {
        return (
          <Card key={index} className={classes.root}>
            <JewelleryPriceSectionRow
              {...jewelleryPriceDisplayToggle}
              onChange={onChange}
              disabled={disabled}
            />
          </Card>
        )
      })}
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({ open: false, message: '' });
        }}
      />
    </div>
  );
}
