import { saveToken } from 'qs-data/util';
import { POST_MESSAGE_SLUGS, windowPostMessageProxy } from 'qs-data/Windowpostmessageproxy';
import Native from './shared';
import Utility from 'qs-helpers/utility';
import firebase from './../FirebaseConfig';

class Web extends Native {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  goBack(...params) {
    const [history] = params || [];
    if (history && history.goBack()) {
      history.goBack();
    }
  }

  shareReferral(content) {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (!userAgent.match(/Android/i)) {
      Utility.openPopup(`https://api.whatsapp.com/send?text=${content}`);
    }
  }

  trackAnalytics(params) {
    window.parent.postMessage(
      {
        func: 'trackAnalytics',
        params: params,
      },
      '*'
    );
  }

  postMessage(...params) {
    const [history] = params || [];
    if (
      history &&
      history.location &&
      history.location.search &&
      typeof history.location.search === 'string'
    ) {
      const params = new URLSearchParams(history.location.search);
      const postMessageKey = params.get('postMessageKey');
      const postMessageValue = params.get('postMessageValue');
      if (typeof postMessageKey === 'string') {
        window.parent.postMessage(
          JSON.stringify({
            [postMessageKey]: postMessageValue,
          }),
          '*'
        );
        return true;
      }
    }
    return false;
  }

  async isFeatureAllowed() {
    // TODO fix this to ensure that isFeatureAllowed is actually checked using post message
    return true;
  }

  async getIdToken() {
    if (window.self === window.top && firebase.auth().currentUser) {
      let tokenFromFirebase = await firebase.auth().currentUser.getIdToken();
      if (tokenFromFirebase) {
        return saveToken(tokenFromFirebase);
      }
    }
    const data = await windowPostMessageProxy.postMessage(window.parent, {
      slug: POST_MESSAGE_SLUGS.RefreshToken,
    });
    const { token } = data || {};
    if (token) {
      saveToken(token);
    }
  }

  postProductType(productType) {
    windowPostMessageProxy.postMessage(window.parent, {
      slug: POST_MESSAGE_SLUGS.ProductType,
      value: productType,
    });
  }
}

export default Web;
