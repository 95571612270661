import { isFeatureAllowed, trackAnalytics } from "../../os";
import {
  getCachedAvailableProvider,
  getCachedSelectedProvider,
  getShippingProvider,
  setCachedAvailableProvider,
  setCachedSelectedProvider,
  updateShippingProvider
} from "qs-data/shippingProviders";

export const SHIPROCKET_FEATURE = 'DELIVERY_SHIPROCKET';

export const SHIPPING_PROVIDERS = {
  CUSTOM: 'CUSTOM',
  SHIPROCKET: 'SHIPROCKET',
};

export const customShipment = {
  id: SHIPPING_PROVIDERS.CUSTOM,
  label: 'custom',
  subtitle: '',
  subtitleIcon: ''
};

export const shiprocketShipment = {
  id: SHIPPING_PROVIDERS.SHIPROCKET,
  label: 'shiprocket',
  subtitle: '',
  subtitleIcon: '',
};

export const hasShiprocketInPlan = () => isFeatureAllowed(SHIPROCKET_FEATURE);

export const trackUpdateShippingProviderEvent = provider => {
  trackAnalytics({ eventName: 'changed_shipping_provider', props: { provider } });
}

export const fetchSelectedShippingProvider = () => {
  const cachedSelectedProvider = getCachedSelectedProvider();
  const cachedAvailableProvider = getCachedAvailableProvider();

  // if cached selected provider value is null
  // so to avoid again an api call checking the value of available providers
  if (cachedSelectedProvider || cachedAvailableProvider) {
    return Promise.resolve(cachedSelectedProvider);
  }

  return getShippingProvider().then(({ selectedProvider, availableProviders } = {}) => {
    setCachedSelectedProvider(selectedProvider);
    setCachedAvailableProvider(availableProviders);
    return Promise.resolve(selectedProvider);
  });
};

export const updateNewShippingProvider = async (provider) => {
  try {
    await updateShippingProvider(provider);
    trackUpdateShippingProviderEvent(provider);
    setCachedSelectedProvider(provider);
    return Promise.resolve(provider);
  } catch (error) {
    return Promise.reject(error);
  }
}