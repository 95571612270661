import React, { useEffect, useState } from 'react';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { CircularProgress } from '@material-ui/core';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import ReferralList from 'qs-components/Referral/ReferralList';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import ReferralData from 'qs-data/referral';
import { useParams } from 'react-router';
import './style.scss';
import { getI18N } from '../../i18N';

export default function MyReferralsPage() {
  const { t } = getI18N();
  const [referralList, setReferralList] = useState([]);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const { isIndiaReferral } = useParams();

  useEffect(() => {
    fetchReferralCode();
  }, []);

  const fetchReferralCode = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ReferralData.getReferralFriendList()
      .then((data) => {
        setReferralList(data);
      })
      .catch(() => {
        showErrorComponent(true);
      })
      .then(() => {
        setProgressBar(false);
      });
  };

  const [, dispatch] = useAppContext();

  useEffect(() => {
    const setNativeColor = isIndiaReferral === 'true' ? '#ffffff' : '#242C36';
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: isIndiaReferral === 'true' ? '#ffffff' : '#242C36',
        color: isIndiaReferral === 'true' ? '#242B35' : '#ffffff',
        title: t('your_referrals'),
        boxShadow: 'none',
        borderBottom: `1px solid ${isIndiaReferral === 'true' ? '#ffffff' : '#242C36'}`,
      },
    });
    setNavigationBarColor(setNativeColor);
    setStatusBarColor(setNativeColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div
      className="myReferralPage"
      style={{
        justifyContent: progressBar || errorComponent ? 'center' : 'flex-start',
        background: isIndiaReferral === 'true' ? '#ffffff' : '#242c36',
      }}
    >
      {progressBar ? (
        <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} />
      ) : errorComponent ? (
        <CustomErrorComponent onRetryClick={fetchReferralCode} />
      ) : (
        <ReferralList
          referralList={referralList}
          onRedeemSuccess={fetchReferralCode}
          isIndiaReferral={isIndiaReferral === 'true'}
        />
      )}
    </div>
  );
}
