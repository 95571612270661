import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import ChangeText from 'qs-common/ChangeText';
import CustomLabel from 'qs-common/CustomLabel';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import HomeLocationDialog from '../HomeLocationDialog';
import { getI18N } from '../../../../i18N';
import "./style.scss";

function HomeLocation({ onHomeLocationUpdated }) {

  const [selectedLocation, updateLocation] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);

  const { t } = getI18N();

  useEffect(() => {
    fetchHomeLocation();
  }, []);

  useEffect(() => {
    onHomeLocationUpdated(selectedLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const fetchHomeLocation = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ShippingData.getShippingHomeLocation().then((data) => {
      updateLocation(data);
    }).catch(() => {
      showErrorComponent(true);
    }).finally(() => {
      setProgressBar(false);
    });
  }

  const openDialog = () => {
    setShowDialog(true);
  }

  const closeDialog = () => {
    setShowDialog(false);
  }

  return <div className='shippingItemContainer shippingHomeCityContainer' onClick={openDialog}>
    <CustomLabel value={t('home_city')} />
    {
      progressBar ? <CircularProgress size={24} color="primary" style={{ marginTop: '8px' }} /> :
        errorComponent ? <CustomErrorComponent onRetryClick={fetchHomeLocation} /> : <div className='updateCityContainer'>
          {(selectedLocation || {}).name && <span className='city'>{selectedLocation.name}</span>}
          <ChangeText text={isNil((selectedLocation || {}).name) ? t('select') : t('change')} onClick={openDialog} />
        </div>
    }
    <HomeLocationDialog
      showDialog={showDialog}
      onClose={closeDialog}
      onSuccess={(location) => {
        updateLocation(location);
        closeDialog();
      }}
    />
  </div>
}

export default withRouter(HomeLocation);
