import { useEffect, useRef } from 'react';

export default function useOnClickOutside(handler, value) {
  const ref = useRef();
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    if (value) {
      document.addEventListener('click', listener);
    }
    return () => {
      document.removeEventListener('click', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return ref;
}
