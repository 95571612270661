import React from 'react';

export default ({ color = '#00A674', size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android"
        transform="translate(-38.000000, -586.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="icons8-iOS-Filled-8191-50-000000" transform="translate(38.000000, 586.000000)">
          <path
            d="M15.1783162,0 C15.03556,0 14.9063995,0.0594818911 14.8248245,0.176745867 L9.53604432,7.80400984 C9.50205473,7.85329463 9.4799616,7.92127381 9.46806514,7.98075571 C9.35589971,8.57557375 9.57003391,9.22647407 10.0934736,9.85698107 C10.9483119,10.8868659 12.4812422,11.6924189 13.5740076,11.6924189 C13.6980695,11.6924189 13.8170333,11.6720252 13.9274993,11.6516314 C14.0583591,11.6278385 14.1637271,11.551362 14.2266077,11.434098 L19.9504547,0.63900429 C20.0218326,0.504745301 20.0150351,0.334797352 19.9368588,0.203937539 C19.8586825,0.073077727 19.7091284,0 19.5561754,0 L15.1783162,0 Z M8.5843358,8.55178082 C5.66123107,9.22137552 4.95084886,11.5802535 4.26085997,13.8677527 C3.5793688,16.1263608 2.93526596,18.2592077 0.304471703,19.142937 C0.102233535,19.2109162 -0.0286262777,19.4080558 0.0053633122,19.6187913 C0.0393529021,19.8295267 0.227995235,19.9858789 0.440430063,19.9858789 C8.94632482,19.9858789 13.259604,18.8268341 13.7507535,12.5353608 C13.6878728,12.5387595 13.638588,12.5625524 13.5740076,12.5625524 C12.2297192,12.5625524 10.4435666,11.6397349 9.42727763,10.4144103 C8.85285378,9.72272208 8.6353204,9.07861968 8.5843358,8.55178082 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
