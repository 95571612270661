import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import './style.scss';

import PersonalizedThemeData from 'qs-data/personalizedTheme';
import EditIcon from 'qs-assets/icons/EditIcon';
import CacheRequest from 'qs-data/cacheRequest';
import cacheKeys from 'qs-data/cacheKeys';
import Ripples from 'react-ripples';
import Toast from 'qs-common/Alerts/Toast';
import Utility, { CUSTOM_THEME_FEATURE } from 'qs-helpers/utility';
import { isFeatureAllowed, showPremiumFeatureDialog, trackAnalytics } from '../../../os';
import { getToken } from 'qs-data/util';
import { t } from 'i18next';

const SingleThemeContainer = ({
  theme,
  hideSetThemeIcon,
  onSelectedThemeChanged,
  customStyle,
  fromThemeEditDrawer,
  themeEditDrawerHeaderClass,
  source = 'mobile',
  languageCode = 'en'
}) => {
  const history = useHistory();
  const colors = [
    theme.addToCartButton.color,
    theme.addToCartButton.textColor,
    theme.homeGrid.backgroundColor,
    theme.pageTextColor,
  ];
  const [progressBar, setProgressBar] = useState(false);
  const isEditable = Utility.isThemeEditable(theme);
  const [toastState, setToastState] = useState({ open: false, message: '' });

  const editTheme = (themeId) => {
    CacheRequest.setCacheForKey(cacheKeys.selectedThemeId, themeId);
    setTimeout(() => {
      history.push({
        pathname: '/theme-update',
        search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
      });
    }, 100);
  };

  const updateSelectedTheme = async (e, themeId, themeName) => {
    e.stopPropagation();
    const featureAllowed = await isFeatureAllowed(CUSTOM_THEME_FEATURE);
    if (featureAllowed) {
      setProgressBar(true);
      PersonalizedThemeData.setSelectedThemeIdForCompany(themeId)
        .then(() => {
          trackAnalytics({
            eventName: 'theme_set',
            props: {
              theme_id: themeId,
              theme_name: themeName,
            },
          });
          onSelectedThemeChanged();
        })
        .catch(() => {
          // Handle error
        })
        .then(() => {
          setTimeout(() => {
            setProgressBar(false);
          }, 1000);
        });
    } else {
      showPremiumFeatureDialog(CUSTOM_THEME_FEATURE);
    }
  };

  const renderColors = () => {
    return (
      <div className="colorsContainer">
        {colors.map((color, i) => {
          return (
            <span
              className="singleColor"
              key={`${color}-${i}`}
              style={{ backgroundColor: color }}
            />
          );
        })}
      </div>
    );
  };

  const editSingleTheme = (themeId) => {
    if (isEditable) {
      editTheme(themeId);
    } else {
      setToastState({
        open: true,
        message: t('quicksell_basic_themes_cant_be_edited'),
      });
    }
  };

  return (
    <div className="singleThemeContainer" key={theme.id}>
      <div className="container" style={customStyle}>
        <Ripples
          className={`rippleContainer ${themeEditDrawerHeaderClass}`}
          onClick={() => {
            if (fromThemeEditDrawer) {
              return;
            }
            editSingleTheme(theme.id);
          }}
          during={fromThemeEditDrawer ? 0 : 1200}
        >
          <div>
            <p className="title">
              {theme.name}
            </p>
            {renderColors()}
          </div>
          <div className="actionItemsContainer">
            {!progressBar && isEditable && !fromThemeEditDrawer ? (
              <div className="themeEditIconContainer">
                <EditIcon color="#FFF" size="17" />
              </div>
            ) : null}
            {!hideSetThemeIcon &&
              (progressBar ? (
                <div className="leftContainer">
                  <CircularProgress size={20} color="primary" />
                </div>
              ) : theme.isSelected ? (
                <span className="setThemeButton selectedThemeButton">
                  {t('selected')}
                </span>
              ) : (
                <span
                  className="setThemeButton"
                  onClick={(e) => updateSelectedTheme(e, theme.id, theme.name)}
                >
                  {t('apply_theme')}
                </span>
              ))}
          </div>
        </Ripples>
      </div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({ open: false, message: '' });
        }}
      />
    </div>
  );
};

export default SingleThemeContainer;
