import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import NativeAppData from 'qs-data/nativeApp';
import TextField from '@material-ui/core/TextField';
import Loader from 'qs-common/Loader';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Toast from 'qs-common/Alerts/Toast';
import { trackAnalytics } from '../../os';
import './style.scss';
import { getI18N } from '../../i18N';

const useStyles = makeStyles({
  inputLabel: {
    color: '#fff',
    fontWeight: 600,
    fontSize: 20,
    marginLeft: 0,
    marginBottom: 16,
    transform: 'translate(0, -1.5px) scale(0.75)',
  },
  label: {
    fontSize: 15,
    color: '#fff',
    fontWeight: 500,
  },
});

const SaveButton = withStyles({
  root: {
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#4DA47A',
    },
  },
})(Button);

const ManageApp = () => {
  const { t } = getI18N();
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [loading, setLoading] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const [appName, setAppName] = useState('');
  const [appNameError, setAppNameError] = useState(false);
  const [appShortDescription, setappShortDescription] = useState('');
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [appLongDescription, setAppLongDescription] = useState('');
  const [longDescriptionError, setLongDescriptionError] = useState(false);
  const [appIcon, setAppIcon] = useState(null);
  const [appIconImageFile, setAppIconImageFile] = useState(null);
  const [iconLoader, setIconLoader] = useState(false);
  const [iconError, setIconError] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const classes = useStyles();
  const APP_NAME_LIMIT = 30;
  const SHORT_DESCRIPTION_LIMIT = 80;
  const LONG_DESCRIPTION_LIMIT = 4000;

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: 'Manage App',
      },
    });
    setNavigationBarColor('#28303A');
    setStatusBarColor('#4DA47A');
  }, [dispatch, desktop]);

  const trackManageAppOpenedEvent = () => {
    trackAnalytics({
      eventName: 'Start - ManageAppActivity',
      props: {},
    });
  };

  const trackSavedClickedEvent = () => {
    trackAnalytics({
      eventName: 'app_details_saved',
      props: {},
    });
  };

  const fetchNativeAppData = async () => {
    await NativeAppData.getNativeAppDetails()
      .then(({ data }) => {
        if (data) {
          const { appDetails } = data;
          const { appName, shortDescription, longDescription, iconUrl } = appDetails;
          setAppName(appName || '');
          setappShortDescription(shortDescription || '');
          setAppLongDescription(longDescription || '');
          setAppIcon(iconUrl);
          trackManageAppOpenedEvent();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          trackManageAppOpenedEvent();
          return; // Returning as the app details have not been created.
        }
        showErrorComponent(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchNativeAppData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccessState = (response) => {
    trackSavedClickedEvent();
    return setToastState({
      open: true,
      message: response.message ? response.message : 'Details saved successfully',
    });
  };

  const handleErrorState = (err) => {
    return setToastState({
      open: true,
      message: err,
    });
  };

  const handleManageAppForm = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!appIcon) {
      setIconError(true);
    }
    if (!appName || appName.length > APP_NAME_LIMIT) {
      setAppNameError(true);
    }
    if (!appShortDescription || appShortDescription.length > SHORT_DESCRIPTION_LIMIT) {
      setShortDescriptionError(true);
    }
    if (!appLongDescription || appLongDescription.length > LONG_DESCRIPTION_LIMIT) {
      setLongDescriptionError(true);
    }

    if (
      !appIcon ||
      !appName ||
      appName.length > APP_NAME_LIMIT ||
      !appShortDescription ||
      appShortDescription.length > SHORT_DESCRIPTION_LIMIT ||
      !appLongDescription ||
      appLongDescription.length > LONG_DESCRIPTION_LIMIT
    ) {
      return;
    }

    setIconLoader(true);

    NativeAppData.updateNativeAppDetails({
      appName: appName,
      shortDescription: appShortDescription,
      longDescription: appLongDescription,
      icon: appIconImageFile,
    })
      .then((res) => {
        handleSuccessState(res);
        setIconLoader(false);
        setAppIconImageFile(null);
      })
      .catch((err) => {
        handleErrorState(err);
        setIconLoader(false);
      });
  };

  const onUpload = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIconError(false);
    const imageFile = e.target.files;
    if (imageFile && imageFile[0]) {
      const selectedImage = imageFile[0];
      setAppIconImageFile(selectedImage);
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onload = (e) => {
        setAppIcon(e.target.result);
      };
    }
  };

  const onAppNameChange = (e) => {
    const appName = e.target.value;
    if (appName.length > APP_NAME_LIMIT) {
      setAppName(appName);
      setAppNameError(true);
      return;
    }
    setAppNameError(false);
    setAppName(appName);
  };

  const onShortDescriptionChange = (e) => {
    const shortDescription = e.target.value;
    if (shortDescription.length > SHORT_DESCRIPTION_LIMIT) {
      setappShortDescription(shortDescription);
      setShortDescriptionError(true);
      return;
    }
    setShortDescriptionError(false);
    setappShortDescription(shortDescription);
  };

  const onLongDescriptionChange = (e) => {
    const longDescription = e.target.value;
    if (longDescription.length > LONG_DESCRIPTION_LIMIT) {
      setAppLongDescription(longDescription);
      setLongDescriptionError(true);
      return;
    }
    setLongDescriptionError(false);
    setAppLongDescription(longDescription);
  };

  const renderCharacterCounts = (textLength, textLimit) => {
    return (
      <span className="characterCounts">
        {textLength}/{textLimit}
      </span>
    );
  };

  if (loading) {
    return (
      <div className="centerAlignContent">
        <Loader />
      </div>
    );
  }
  if (errorComponent) {
    return (
      <div className="centerAlignContent">
        <CustomErrorComponent onRetryClick={fetchNativeAppData} />
      </div>
    );
  }
  return (
    <div className="manageAppContainer">
      <div className="manageAppInstructions">
        {t('please_fill_in_these_basic_details_to_get_started')}
      </div>

      <form className="manageAppForm" onSubmit={handleManageAppForm}>
        <div className="iconUploadContainer">
          <p style={iconError ? { color: '#f44336' } : {}}>Icon</p>
          <div
            className="iconUploadButton"
            style={iconError ? { border: '1px solid #f44336' } : {}}
          >
            {appIcon ? (
              <div className="selectedIconContainer">
                <AddIcon className="removeAppIcon" onClick={() => setAppIcon(null)} />
                <img className="selectedIcon" src={appIcon} alt="selected icon" />
              </div>
            ) : (
              <>
                <AddIcon className="addAppIcon" style={iconError ? { color: '#f44336' } : {}} />
                <input
                  type="file"
                  name="appIcon"
                  accept="image/*"
                  onChange={onUpload}
                  className="iconPicker"
                />
              </>
            )}
          </div>
        </div>
        {iconError && <div className="iconErrorMessage">{t('icon_is_required')}</div>}

        <TextField
          fullWidth
          value={appName}
          label="App Name"
          error={appNameError}
          helperText={
            appNameError
              ? !appName.length
                ? t('app_name_is_required')
                : t('app_name_can_not_exceed_character_limit', { limit: `${appName.length}/${APP_NAME_LIMIT}` })
              : renderCharacterCounts(appName.length, APP_NAME_LIMIT)
          }
          className="manageAppInputField"
          InputLabelProps={{
            classes: { formControl: classes.inputLabel },
            shrink: true,
          }}
          onChange={onAppNameChange}
        />

        <TextField
          fullWidth
          value={appShortDescription}
          multiline
          error={shortDescriptionError}
          helperText={
            shortDescriptionError
              ? !appShortDescription.length
                ? t('short_description_is_required')
                : t('short_description_can_not_exceed_character_limit', { limit: `${appShortDescription.length}/${SHORT_DESCRIPTION_LIMIT}` })
              : renderCharacterCounts(appShortDescription.length, SHORT_DESCRIPTION_LIMIT)
          }
          label={t('short_description')}
          className="manageAppInputField"
          InputLabelProps={{
            classes: { formControl: classes.inputLabel },
            shrink: true,
          }}
          onChange={onShortDescriptionChange}
        />

        <div className="longDescriptionField">
          <TextField
            fullWidth
            value={appLongDescription}
            multiline
            error={longDescriptionError}
            helperText={
              longDescriptionError
                ? !appLongDescription.length
                  ? t('long_description_is_required'): t('long_description_can_not_exceed_character_limit', {
                    limit: `${appLongDescription.length}/${LONG_DESCRIPTION_LIMIT}`
                  })
                : renderCharacterCounts(appLongDescription.length, LONG_DESCRIPTION_LIMIT)
            }
            label={t('long_description')}
            className="manageAppInputField"
            InputLabelProps={{
              classes: { formControl: classes.inputLabel },
              shrink: true,
            }}
            onChange={onLongDescriptionChange}
          />
        </div>
        <SaveButton
          type="submit"
          disabled={iconLoader}
          style={iconLoader ? { background: '#777b7a' } : {}}
          className="saveAppDetailsButton"
        >
          {t('save')}
        </SaveButton>
      </form>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
};

export default withRouter(ManageApp);
