import React, { useState } from 'react';
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import CustomSelect from 'qs-common/CustomSelect';
import DialogBox from "qs-common/DialogBox";
import Utility from 'qs-helpers/utility';
import { getI18N } from '../../../../i18N';
import "./style.scss";

export default function ({ showDialog, onClose, onSuccess, weightUnit }) {
    const weights = Utility.getWeightUnits();
    const [selectedWeight, setSelectedWeight] = useState(weightUnit);
    const [progressBar, setProgressBar] = useState(false);

    const { t } = getI18N();

    const unitChange = (e) => {
        setSelectedWeight(e.target.value)
    }

    const updateWeigthUnit = async () => {
        setProgressBar(true);
        ShippingData.updateShippingWeightUnit({ unit: selectedWeight }).then(() => {
            onSuccess(selectedWeight);
        }).catch(() => {
            // Handle error
        }).finally(() => {
            setProgressBar(false);
        });
    };

    return (
        <DialogBox
            type="simple"
            dialogId="#select-weight"
            show={showDialog}
            width={300}
            primaryBtnText={t('save')}
            onSuccess={updateWeigthUnit}
            onClose={onClose}
            disabledPrimaryButton={isNil(selectedWeight)}
            showProgressBar={progressBar}
        >
            <div className="weigthDialogContent">
                <p className="dialogTitle">{t('select_weight_unit')}</p>
                <CustomSelect
                    handleChange={unitChange}
                    selectedValue={selectedWeight}
                    itemList={weights}
                />
            </div>
        </DialogBox>
    );
}
