import React, { useState, useEffect, Fragment } from 'react';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';
import { Button, TextField } from '@material-ui/core';
import {
  deleteLoginCredentials,
  getSavedLoginCredentials,
  setNavigationBarColor,
  setStatusBarColor,
} from '../../os';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';
import './styles.scss';
import network from 'qs-data/network';
import Toast from 'qs-common/Alerts/Toast';
import Loader from 'qs-common/Loader';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { EXPIRED, PAID, TRIAL } from './constants';
import DialogBox from 'qs-common/DialogBox';
import { getI18N } from '../../i18N';

const useStyles = makeStyles({
  textFieldRoot: {
    width: '100%',
  },
  root: {
    borderRadius: 8,
    backgroundColor: '#222E3D',
    fontSize: '18px',
  },
  outline: {
    borderColor: 'transparent !important',
  },
  button: {
    textTransform: 'none',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 14,
    borderRadius: 4,
  },
  input: {
    '&::placeholder': {
      color: '#8798AC',
    },
  },
});

const ForgotLogin = () => {
  const { t } = getI18N();
  const classes = useStyles();
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const [loading, setLoading] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const [link, setLink] = useState('');
  const [loginDetails, setLoginDetails] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');

  const desktop = !source || source === 'desktop';

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#161F2A',
        color: '#FFFFFF',
        title: t('forgot_login'),
        boxShadow: false,
        enableBoxShadow: false,
        hideBack: desktop,
      },
    });
    setNavigationBarColor('#161F2A');
    setStatusBarColor('#161F2A');
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  useEffect(() => {
    try {
      const data = getSavedLoginCredentials();
      if (data) {
        const parsedAccountDetails = typeof data === 'string' ? JSON.parse(data) : data;
        if (Array.isArray(parsedAccountDetails)) {
          setAccountDetails(parsedAccountDetails);
        }
      }
    } catch (error) {
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLinkChange = (value) => {
    setLink(value);
    setErrorMessage('');
  };

  const findLoginDetails = async () => {
    if (!link) {
      setToastState({
        open: true,
        message: t('paste_the_link_to_get_your_login_details'),
      });
      return;
    }
    setLoading(true);
    try {
      const data = await network.getLoginDetails(link);
      setLoginDetails(data);
    } catch (error) {
      setLoginDetails([]);
      setErrorMessage(t('invalid_url'));
    } finally {
      setLoading(false);
    }
  };
  const renderDetail = (title, value) => {
    return (
      <div className="heading">
        <div className="title">{title}</div>
        {value && <div className="value">{value}</div>}
      </div>
    );
  };

  const getAccountType = (accountType) => {
    if (accountType === PAID) {
      return t('paid_account');
    } else if (accountType === TRIAL) {
      return t('trial_expired');
    } else if (accountType === EXPIRED) {
      return t('account_expired');
    }
    return '';
  };

  const deleteAccountDetail = (id) => {
    const deletedAccountDetails = (accountDetails || []).find(({ userId }) => userId === id);
    if (!deletedAccountDetails) {
      return;
    }
    const loginCredentials = JSON.stringify(deletedAccountDetails);
    const isDeleted = deleteLoginCredentials(loginCredentials);
    if (isDeleted) {
      setAccountDetails((prevState) => {
        return (prevState || []).filter(({ userId }) => userId !== id);
      });
    }
    onClose();
  };
  const onClose = () => {
    setSelectedUserId('');
  };

  const renderConfirmationBox = () => {
    return (
      <DialogBox
        type={'simple'}
        dialogId={'#delete-account-detail'}
        show={!!selectedUserId}
        onSuccess={() => deleteAccountDetail(selectedUserId)}
        onClose={onClose}
        secondaryBtnText={'Cancel'}
        primaryBtnText={'Delete'}
      >
        <Fragment>
          <div className="deleteDialog">
            <div className="title">{t('clear_login_detail')}</div>
            <div className="subtitle">{t('are_you_sure_you_want_clear_login_details')}</div>
          </div>
        </Fragment>
      </DialogBox>
    );
  };

  return (
    <>
      <div className="forgotLoginContainer">
        {!!accountDetails && (accountDetails || []).length > 0 && (
          <div className="accountDetailsContainer">
            {(accountDetails || []).map(
              ({
                brandName = '',
                accountType = '',
                phoneNumber = '',
                accountSource = '',
                userId,
                userName = '',
              }) => {
                return (
                  <div className="singleAccountDetailRow" key={userId}>
                    <div className="singleAccountDetail">
                      {brandName && <div className="companyName">{brandName}</div>}
                      {accountType && (
                        <div className={`accountType ${accountType === PAID ? 'paidColor' : ''}`}>
                          {getAccountType(accountType)}
                        </div>
                      )}
                      {(phoneNumber || userName) && (
                        <div className="phoneNumberAndProviderName">{phoneNumber || userName}</div>
                      )}
                      {accountSource && (
                        <div className="phoneNumberAndProviderName">{accountSource}</div>
                      )}
                    </div>
                    <CloseIcon className="closeIcon" onClick={() => setSelectedUserId(userId)} />
                  </div>
                );
              }
            )}
          </div>
        )}
        <div className="findloginDetailsContainer">
          <div className="description">
            {t('retrieve_your_login_details_by_entering_your_catalogue_link_or_website')}
          </div>
          <div className="pasteLink">
            <TextField
              autoComplete="off"
              id="outlined-basic"
              variant="outlined"
              placeholder={t('paste_catalogue_or_website_link')}
              value={link}
              onChange={(e) => onLinkChange(e.target.value)}
              classes={{ root: classes.textFieldRoot }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.root,
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
            />
          </div>

          <Button
            variant="contained"
            size="medium"
            className="findDetailsButton"
            classes={{ root: classes.button }}
            onClick={findLoginDetails}
          >
            {loading ? (
              <div className="buttonloader">
                <Loader small style={{ stroke: '#fff' }} />
              </div>
            ) : (
              t('find_login_details')
            )}
          </Button>
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>
        {loginDetails && loginDetails.length > 0 && (
          <div className="loginDetailsContainer">
            <div>{t('your_login_details')}</div>
            {(loginDetails || []).map((loginDetail, index) => {
              return (
                <div className="loginDetailContainer" key={index}>
                  {renderDetail(t('login_method'), loginDetail.providerName)}
                  {renderDetail(t('username'), loginDetail.username)}
                </div>
              );
            })}
          </div>
        )}
        {selectedUserId && renderConfirmationBox()}
      </div>
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default ForgotLogin;
