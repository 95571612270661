import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

const apis = {
  customVariantEndpoint: '/v1/option-type',
  customVariantProducts: '/v1/option-type/vairant-products-count',
};

const getCustomVariant = () => {
  return ApiWrapper.getDataPromise(
    apis.customVariantEndpoint,
    'optionTypes',
    CacheKeys.customVariant
  );
};

const saveCustomVariant = (data) => {
  return ApiWrapper.postDataPromise(apis.customVariantEndpoint, data);
};

const getCustomVariantProducts = (optionType) => {
  return ApiWrapper.getDataPromise(`${apis.customVariantProducts}?optionTypeId=${optionType}`);
};

const deleteCustomVariant = (data) => {
  return ApiWrapper.deleteDataPromise(apis.customVariantEndpoint, data);
};

export default {
  saveCustomVariant,
  getCustomVariant,
  getCustomVariantProducts,
  deleteCustomVariant,
};
