import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  setNavigationBarColor,
  setStatusBarColor,
  trackAnalytics,
  PLATFORMS,
  getPlatform,
} from '../../os';
import Loader from 'qs-common/Loader';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import NativeAppData from 'qs-data/nativeApp';
import { percentOpened } from 'qs-helpers/nativeApp';
import Toast from 'qs-common/Alerts/Toast';
import { getToken } from 'qs-data/util';
import './style.scss';
import BroadcastIcon from 'qs-assets/images/broadcast.png';
import { formatDistanceToNowStrict } from 'date-fns';
import { getI18N } from '../../i18N';

const Engage = () => {
  const { t } = getI18N();
  const [pushNotificationList, setPushNotificationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [errorComponent, showErrorComponent] = useState(false);
  const pageContainerRef = useRef(null);
  const notificationContainerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [, setRender] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const limitPerNotificationRequest = 10;
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const history = useHistory();
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    const sendPushNotificationButton =
      getPlatform() === PLATFORMS.IOS ? (
        <IconButton
          onClick={() => history.push(`/push-notification?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
          style={{ width: 30, height: 40, padding: 0 }}
          color="primary"
          aria-label="send-push-notification"
        >
          <img src={BroadcastIcon} alt="send-push-notification" />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          onClick={() => history.push(`/push-notification?token=${getToken()}&source=${source}&languageCode=${languageCode}`)}
          color="success"
        >
          <span>{t('send_now')}</span>
        </Button>
      );
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#191f27' : '#4DA47A',
        color: '#ffffff',
        title: t('engage'),
        boxShadow: 'none',
        borderBottom: '1px solid #191f27',
        hideBack: desktop,
        actions: sendPushNotificationButton,
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  const trackEngageOpenedEvent = () => {
    trackAnalytics({
      eventName: 'Start - EngagePageActivity',
      props: {},
    });
  };

  const fetchPushNotificationList = useCallback(() => {
    if (page === 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    NativeAppData.getNativeAppNotificationList({ page })
      .then((data) => {
        setHasMoreData(data.length === limitPerNotificationRequest);
        if (data.length) {
          setPushNotificationList((prevData) => [...prevData, ...data]);
        }
      })
      .catch(() => showErrorComponent(true))
      .then(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  }, [page]);

  useEffect(() => {
    if (errorComponent || loading) {
      return;
    }
    trackEngageOpenedEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPushNotificationList();
  }, [page, fetchPushNotificationList]);

  const pageContainerRefMounted = useCallback(
    (ref) => {
      setRender(true);
      pageContainerRef.current = ref;
      return () => {
        pageContainerRef.current = null;
      };
    },
    [setRender]
  );

  const notificationContainerRefMounted = useCallback(
    (ref) => {
      setRender(true);
      notificationContainerRef.current = ref;
      return () => {
        notificationContainerRef.current = null;
      };
    },
    [setRender]
  );

  const handlePushNotificationScroll = useCallback(() => {
    if (!hasMoreData || loadingMore) {
      return;
    }
    const scrollTop = pageContainerRef.current.scrollTop;
    const screenHeight = window.screen.height;
    const hasReacedAtBottom =
      scrollTop + screenHeight < notificationContainerRef.current.clientHeight;
    if (hasReacedAtBottom) {
      return;
    }
    setPage((prevPage) => prevPage + 1);
  }, [hasMoreData, loadingMore]);

  const renderLoader = () => (
    <div className="centerAlignContent">
      <Loader />
    </div>
  );

  if (loading) {
    return renderLoader();
  }
  if (errorComponent) {
    return (
      <div className="centerAlignContent">
        <CustomErrorComponent onRetryClick={fetchPushNotificationList} />
      </div>
    );
  }
  return (
    <div
      className="engagePage"
      onScroll={handlePushNotificationScroll}
      ref={pageContainerRefMounted}
    >
      {pushNotificationList.length ? (
        <div ref={notificationContainerRefMounted} className="notificationList">
          {pushNotificationList.map((notification, index) => {
            return (
              <div key={index} className="notificationContainer">
                <div className="title">{notification.title}</div>
                <div className="description">{notification.subtitle}</div>
                <div className="timeSinceOpened">
                  {`${t('launched')} ${formatDistanceToNowStrict(new Date(notification.notificationSentOn), { addSuffix: true })}`}
                </div>
                <div className="status">
                  <p>
                    {t('delivered_to_users', { count: notification.deliveredTo })}
                  </p>
                  <p>
                    {`${t('opened_by_users', { count: notification.totalOpens })} (${percentOpened(notification.deliveredTo, notification.totalOpens)}%)`}
                  </p>
                </div>
              </div>
            );
          })}
          {loadingMore && renderLoader()}
        </div>
      ) : (
        <div className="centerAlignContent">{t('no_push_notifications')}</div>
      )}
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
};

export default withRouter(Engage);
