import React, { Fragment, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import DialogBox from "qs-common/DialogBox";
import { getI18N } from '../../../../i18N';
import "./style.scss";

export default function ({ showDialog, onClose, onSuccess, selectedShippingMode, deleteMode }) {
    const inputRef = useRef();
    const [enableButton, setEnableButton] = useState(deleteMode || false);

    const [progressBar, setProgressBar] = useState(false);
    const [closeDialog, setCloseDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const { t } = getI18N();

    const onChange = () => {
        if (!isNil(errorMessage)) {
            setErrorMessage(null);
        }
        if (isNil(inputRef.current)) {
            setEnableButton(false);
            return;
        }
        setEnableButton(inputRef.current.value !== null && inputRef.current.value !== '');
    }

    /* Add / Update Shipping mode api call */
    const addOrUpdateShippingMode = async () => {
        setProgressBar(true);
        setErrorMessage(null);
        if (isNil(selectedShippingMode)) {
            const input = { "value": inputRef.current.value, "enabled": true }
            ShippingData.addShippingMode(input).then(() => {
                onApiSuccess();
            }).catch((err) => {
                onApiFailure(err)
            }).then(() => {
                setProgressBar(false)
            });
        } else {
            const input = {
                "id": selectedShippingMode.id,
                "value": inputRef.current.value
            }
            ShippingData.updateShippingMode(input, true).then(() => {
                onApiSuccess();
            }).catch((err) => {
                onApiFailure(err)
            }).then(() => {
                setProgressBar(false)
            });
        }
    }

    /* Delete Shipping mode api call */
    const deleteShippingMode = async () => {
        setProgressBar(true);
        setErrorMessage(null);
        ShippingData.deleteShippingMode({ id: selectedShippingMode.id }).then(() => {
            onApiSuccess();
        }).catch((err) => {
            onApiFailure(err)
        }).then(() => {
            setProgressBar(false)
        });
    }

    const onApiSuccess = () => {
        setCloseDialog(true);
        onSuccess();
    }

    const onApiFailure = (error) => {
        setErrorMessage(error);
    }

    return <DialogBox
        type={"simple"}
        dialogId={'#add-new-shipping-mode'}
        show={showDialog}
        width={300}
        primaryBtnText={deleteMode ? t('yes') : t('save')}
        onSuccess={deleteMode ? deleteShippingMode : addOrUpdateShippingMode}
        onClose={onClose}
        disabledPrimaryButton={!enableButton}
        showProgressBar={progressBar}
        closeDialog={closeDialog}
        danger={deleteMode}
    >
        <Fragment>
            <div className='newShippingMode'>
                <p className='dialogTitle'>{deleteMode ? t('are_you_sure_you_want_to_delete_this_shipping_mode') : t('shipping_mode_name')}</p>
                {!deleteMode && <TextField
                    autoFocus
                    disabled={progressBar}
                    onChange={onChange}
                    className='newShippingModeLabel'
                    inputRef={inputRef}
                    error={errorMessage !== null}
                    helperText={errorMessage}
                    defaultValue={!isNil(selectedShippingMode) ? selectedShippingMode.value : null}
                />}
            </div>
        </Fragment>
    </DialogBox>
}
