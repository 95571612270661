import BirdOne from 'qs-assets/images/ic_bird_1.png';
import BirdTwo from 'qs-assets/images/ic_bird_2.png';
import BirdThree from 'qs-assets/images/ic_bird_3.png';
import BirdFour from 'qs-assets/images/ic_bird_4.png';
import BirdFive from 'qs-assets/images/ic_bird_5.png';
import BirdSix from 'qs-assets/images/ic_bird_6.png';
import BirdSeven from 'qs-assets/images/ic_bird_7.png';
import BirdEight from 'qs-assets/images/ic_bird_8.png';
import BirdNine from 'qs-assets/images/ic_bird_9.png';
import CacheKeys from './CacheKeys';
import { getI18N } from '../../i18N';

const whiteColor = '#FFFFFF';
const blackColor = '#000000';
const backgroundColor = '#252934';
const backgroundColor1 = '#4DA47A';
const backgroundColor2 = '#439875';
const color1 = '#838CA2';
const color2 = '#4DF6B0';
const color3 = '#3F6FF5';
const color4 = '#F53F3F';
const color5 = '#333845';

export const settingOptions = [
  {
    id: 'DEFAULT',
    title: 'default_catalogue_privacy',
    description: 'set_the_default_privacy_level_of_new_catalogues_as_either_public_semi_private_or_private',
    groupName: 'DEFAULT',
    styles: {
      card: {
        marginTop: 0,
        backgroundColor: backgroundColor,
        border: `1px solid ${color2}`,
      },
      title: {
        color: whiteColor,
      },
      subtitle: {
        color: color1,
      },
      manage: {
        border: `1px solid ${color2}`,
      },
      manageText: {
        color: whiteColor,
      },
      countColor: {
        color: whiteColor,
      },
      manageButtonArrowColor: color2,
    },
  },
  {
    id: 'ALLOWED_CUSTOMER',
    title: 'allowed_customer_list',
    description: 'manage_your_global_allowed_customer_list',
    groupCountKey: 'requestGroupMembers',
    groupName: 'Allowed customer',
    styles: {
      card: {
        backgroundColor: backgroundColor1,
      },
      title: {
        color: whiteColor,
      },
      subtitle: {
        color: whiteColor,
      },
      manage: {
        backgroundColor: whiteColor,
      },
      manageText: {
        color: blackColor,
      },
      countColor: {
        color: whiteColor,
      },
      manageButtonArrowColor: blackColor,
    },
  },
  {
    id: 'CUSTOMER',
    title: 'customer_groups',
    description: 'create_and_manage_your_customer_groups',
    groupName: 'CUSTOMER',
    groupCountKey: 'customerGroups',
    styles: {
      card: {
        backgroundColor: whiteColor,
      },
      title: {
        color: blackColor,
      },
      subtitle: {
        color: color1,
      },
      manage: {
        backgroundColor: backgroundColor2,
      },
      manageText: {
        color: whiteColor,
      },
      countColor: {
        color: blackColor,
      },
      background: `linear-gradient(${backgroundColor2},${color5})`,
      navBarColor: backgroundColor2,
      manageButtonArrowColor: whiteColor,
    },
  },
  {
    id: 'ALLOWED',
    title: 'global_allowed_groups',
    groupName: 'ALLOWED',
    groupCountKey: 'allowedGroups',
    description: 'view_and_manage_a_list_of_customer_groups_that_will_be_granted_access_to_all_of_your_existing_and_new_catalogues',
    styles: {
      card: {
        backgroundColor: color3,
      },
      title: {
        color: whiteColor,
      },
      subtitle: {
        color: whiteColor,
      },
      manage: {
        backgroundColor: whiteColor,
      },
      manageText: {
        color: color3,
      },
      countColor: {
        color: whiteColor,
      },
      background: `linear-gradient(${color3}, ${color5})`,
      navBarColor: color3,
      manageButtonArrowColor: color3,
    },
  },
  {
    id: 'BLOCKED',
    groupName: 'BLOCKED',
    title: 'global_blocked_groups',
    groupCountKey: 'blockedGroups',
    description: 'view_and_manage_a_list_of_customer_groups_that_will_be_blocked_from_accessing_all_of_your_existing_and_new_catalogues',
    styles: {
      card: {
        backgroundColor: color4,
      },
      title: {
        color: whiteColor,
      },
      subtitle: {
        color: whiteColor,
      },
      manage: {
        backgroundColor: whiteColor,
      },
      manageText: {
        color: color4,
      },
      countColor: {
        color: whiteColor,
      },
      background: `linear-gradient(${color4}, ${color5})`,
      navBarColor: color4,
      manageButtonArrowColor: color4,
    },
  },
];

export const ALLOWED_CUSTOMER = 'ALLOWED_CUSTOMER';

export const ACCESS_LEVEL_META = {
  DEFAULT: {
    displayName: 'public',
    description: 'public_catalogue_meta_description',
  },
  SEMI_PRIVATE: {
    displayName: 'semi_private',
    description: 'semi_private_catalogue_meta_description',
  },
  PRIVATE: {
    displayName: 'private',
    description: 'private_catalogue_meta_description',
  },
};

export const GlOBAL_GROUPS = {
  ALLOWED: 'ALLOWED',
  BLOCKED: 'BLOCKED',
  CUSTOMER: 'CUSTOMER',
};

export const ENTITY_TYPE = {
  COMPANY: 'COMPANY',
  CATALOGUE: 'CATALOGUE',
};

export const MEMBER_COLORS = [
  '#FF7B7B',
  '#6A63FF',
  '#17B572',
  '#8B7BFF',
  '#FF7BCA',
  '#69B4B7',
  '#FFAE7B',
  '#8AC180',
  '#BA668D',
];

export const ATOM = {
  GROUP: 'GROUP',
  MEMBER: 'MEMBER',
};
export const ACCESS_TYPE = {
  BLACKLIST: 'BLACKLIST',
  WHITELIST: 'WHITELIST',
};
export const ACCESS_COLOR = {
  ALLOWED_COLOR: '#3F6FF5',
  BLOCKED_COLOR: '#F53F3F',
};
export const ACCESS_LEVEL = {
  DEFAULT: 'DEFAULT',
  SEMI_PRIVATE: 'SEMI_PRIVATE',
  PRIVATE: 'PRIVATE',
};

export const CATALOGUE_LEVEL_PRIVACY_SETTINGS = {
  ALLOWED: {
    title: 'allowed_contacts',
    description: 'anyone_in_this_group_will_have_access_to_this_catalogue',
    defaultTitle: 'default_allowed_groups',
    otherGroupsTitle: 'other_allowed_groups',
  },
  BLOCKED: {
    title: 'blocked_contacts',
    description: 'anyone_in_this_group_will_not_have_access_to_this_catalogue',
    defaultTitle: 'default_blocked_groups',
    otherGroupsTitle: 'other_blocked_groups',
  },
};

export const SWITCH = 'SWITCH';

export const CATALOGUE_GROUPS_KEYNAME = {
  CATALOGUE_GROUP: 'catalogueGroups',
  DEFAULT_GROUPS: 'defaultGroups',
  OTHER_GROUPS: 'otherGroups',
};

const ICON_CIRCLE_SIZE = 37 * 1.2;
const ICON_IMAGE_SIZE = Math.round(1.5 * ICON_CIRCLE_SIZE);

export const renderItemIconStyle = {
  size: ICON_CIRCLE_SIZE,
  borderRadius: ICON_CIRCLE_SIZE / 2,
  marginLeft: -1 * ICON_CIRCLE_SIZE * 0.35,
  imageWidth: ICON_IMAGE_SIZE,
  imageMarginLeft: -1 * 0.6 * ICON_IMAGE_SIZE,
};

export const COUNT_TYPE = {
  CONTACT: 'contact',
  GROUP: 'group',
  MEMBER: 'member',
  ALLOWED_CUSTOMER: 'customer',
};

export const renderIcon = (name) => {
  switch (name) {
    case 'ic_bird_1':
      return BirdOne;
    case 'ic_bird_2':
      return BirdTwo;
    case 'ic_bird_3':
      return BirdThree;
    case 'ic_bird_4':
      return BirdFour;
    case 'ic_bird_5':
      return BirdFive;
    case 'ic_bird_6':
      return BirdSix;
    case 'ic_bird_7':
      return BirdSeven;
    case 'ic_bird_8':
      return BirdEight;
    case 'ic_bird_9':
      return BirdNine;
    default:
      return BirdFour;
  }
};

export const BUTTON_TEXT = {
  ALLOW: 'Allow',
  DENY: 'Deny',
  BLOCK: 'Block',
  UNBLOCK: 'Unblock',
};

export const getCacheKeyForGlobalGroup = (globalGroupId) => {
  switch (globalGroupId) {
    case GlOBAL_GROUPS.ALLOWED:
      return CacheKeys.GLOBAL_GROUP_ALLOWED;
    case GlOBAL_GROUPS.BLOCKED:
      return CacheKeys.GLOBAL_GROUP_BLOCKED;
    case GlOBAL_GROUPS.CUSTOMER:
      return CacheKeys.GLOBAL_GROUP_CUSTOMER;
    default:
      return null;
  }
};

export const getGroupMemberCacheKey = (entityType, groupId) => {
  if (entityType === ENTITY_TYPE.COMPANY) {
    return `${CacheKeys.COMPANY_GROUP_MEMBERS}_${entityType}_${groupId}`;
  }
  if (entityType === ENTITY_TYPE.CATALOGUE) {
    return `${CacheKeys.CATALOGUE_GROUP_MEMBERS}_${entityType}_${groupId}`;
  }
  return null;
};
export const getAdditionalMembersCacheKey = (entityType, accessLevel) => {
  if (entityType === ENTITY_TYPE.COMPANY) {
    return `${CacheKeys.COMPANY_ADDITIONAL_GROUPS}_${entityType}_${accessLevel}`;
  }
  if (entityType === ENTITY_TYPE.CATALOGUE) {
    return `${CacheKeys.CATALOGUE_ADDITIONAL_GROUPS}_${entityType}_${accessLevel}`;
  }
  return null;
};

export const renderCount = (count, type, entityType = ENTITY_TYPE.COMPANY) => {
  const { t } = getI18N();
  if (type === COUNT_TYPE.CONTACT && !count && entityType !== ENTITY_TYPE.CATALOGUE) {
    return `0 ${t('contacts')}`;
  }
  if (!count) {
    return t('no_entity_type', { entityType: `${t(`${type}s`)}` });
  }

  if (count === 1) {
    return `1 ${t(type)}`;
  }

  return `${count} ${t(`${type}s`)}`;
};

export const renderButtonText = (accessLevel, isActivated = true) => {
  if (accessLevel === GlOBAL_GROUPS.ALLOWED) {
    if (isActivated) {
      return BUTTON_TEXT.DENY;
    }
    return BUTTON_TEXT.ALLOW;
  }
  if (isActivated) {
    return BUTTON_TEXT.UNBLOCK;
  }
  return BUTTON_TEXT.BLOCK;
};
