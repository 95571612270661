import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, ListItem } from '@material-ui/core';
import { AvTimer, Close, MoreVert, Delete } from '@material-ui/icons';
import DialogBox from 'qs-common/DialogBox';
import { intervalToDuration, isValid } from 'date-fns';
import { trackAnalytics } from '../../os';
import customerListData from 'qs-data/customerList';
import Timer from './Timer';
import Toast from 'qs-common/Alerts/Toast';
import './style.scss';
import { getI18N } from '../../i18N';

const messageType = { timer: 'timer', allow: 'allow', delete: 'delete', update: 'update' };

export default function CustomerList({
  id,
  title,
  subtitle,
  buttonText,
  timerIcon,
  closeIcon,
  menuIcon,
  accessTime,
  removeRow,
  accessExpiryTime,
  accessGivenTime
}) {
  const { t } = getI18N();
  const [dialog, setDialog] = useState(false);
  const [dialogType, setDialogType] = useState();
  const [remainingTime, setRemainingTime] = useState();
  const [formattedRemainingTime, setFormattedRemainingTime] = useState();
  const [expiredMin, setExpiredMin] = useState();
  const [timerValue, setTimerValue] = useState();
  const [disableDialogButton, setDisableDialogButton] = useState();
  const [menuDialog, setMenuDialog] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const onClose = () => {
    setDialog(false);
    setMenuDialog(false);
  };

  let POP_STATE_COUNTER = 0;

  const popStateListener = () => {
    if (POP_STATE_COUNTER === 0) {
      POP_STATE_COUNTER++;
    } else {
      onClose();
      POP_STATE_COUNTER = 0;
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', popStateListener);
    return () => {
      window.removeEventListener('popstate', popStateListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExpiredMin = useCallback(() => {
    if (!isValid(new Date(accessExpiryTime)) || !accessExpiryTime) {
      return;
    }
    const duration = intervalToDuration({
      start: new Date(accessExpiryTime),
      end: new Date(),
    });
    if (duration.minutes === 0 && duration.hours === 0) {
      setExpiredMin(t('recently'));
      return;
    }
    setExpiredMin(duration.minutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRemainingTime = useCallback(() => {
    if (!isValid(new Date(accessExpiryTime)) || !accessExpiryTime) {
      return;
    }
    const duration = intervalToDuration({
      start: new Date(),
      end: new Date(accessExpiryTime),
    });
    const totalSeconds = duration.hours * 3600 + duration.minutes * 60 + duration.seconds;
    setRemainingTime(totalSeconds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (new Date(accessExpiryTime) < new Date()) {
      getExpiredMin();
    } else {
      getRemainingTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRemainingTime, getExpiredMin]);

  const convertHMS = (value) => {
    const duration = intervalToDuration({ start: 0, end: value * 1000 });

    return duration.hours
      ? duration.hours + 'h ' + duration.minutes + 'm ' + duration.seconds + 's'
      : duration.minutes
      ? duration.minutes + 'm ' + duration.seconds + 's'
      : duration.seconds + 's';
  };

  const dialogMessageType = (type) => {
    if (type === messageType.timer) {
      setMenuDialog(false);
      setDialogType({
        type,
        primaryButton: t('set_timer'),
      });
      setDialog(true);
    }
    if (type === messageType.allow) {
      setMenuDialog(false);
      setDialogType({
        type,
        primaryButton: t('allow'),
      });
      setDialog(true);
    }
    if (type === messageType.delete) {
      setMenuDialog(false);
      setDialogType({
        type,
        primaryButton: t('delete'),
      });
      setDialog(true);
    }
    if (type === messageType.update) {
      setMenuDialog(true);
    }
  };

  const handleSetTimer = (time) => {
    if (!isValid(new Date(time)) || !time) {
      setTimerValue();
      return;
    }
    const duration = intervalToDuration({
      start: new Date(2001, 0, 1, 0, 0, 0),
      end: time,
    });
    const totalSeconds = duration.hours * 3600 + duration.minutes * 60 + duration.seconds;
    setTimerValue(totalSeconds);
  };

  const renderDialogContent = (type) => {
    if (type === messageType.timer) {
      return (
        <Timer
          accessTime={accessTime}
          newAccessSetTime={handleSetTimer}
          accessGivenTime={accessGivenTime}
        />
      );
    }
    if (type === messageType.allow) {
      return (
        <div>
          <div className="timerTitle">
            {t('allow_access')}
          </div>
          <div className="contentContainer">
            {t('give_permanent_access_to_contact', { contact: title || subtitle })}
          </div>
        </div>
      );
    }
    if (type === messageType.delete) {
      return (
        <div>
          <div className="timerTitle">{t('remove_access')}</div>
          <div className="contentContainer">
            {t(accessGivenTime ? 'are_you_sure_you_want_to_remove_access_of_contact' : 'are_you_sure_you_want_to_reject_access_request_of_contact', { contact: title || subtitle })}
          </div>
        </div>
      );
    }
  };

  const handleSuccess = (type) => {
    setDisableDialogButton(true);
    if (type === messageType.allow) {
      trackAnalytics({
        eventName: 'allow_request',
        props: {
          phone_number: subtitle,
        },
      });
    }
    if (type === messageType.timer) {
      trackAnalytics({
        eventName: 'allow_request_with_timer',
        props: {
          phone_number: subtitle,
          duration_in_min: Number(timerValue),
        },
      });
    }
    if (type === messageType.timer || type === messageType.allow) {
      customerListData
        .allowSingleAccess({ memberId: id, accessTime: Number(timerValue), accessGivenTime })
        .then(() => {
          setDialog(false);
          setDisableDialogButton(false);
          const isItRequest = true;
          removeRow({ id, isItRequest, accessGivenTime: new Date(), accessTime: timerValue });
          setToastState({
            open: true,
            message: t('contact_is_allowed_access_successfully', { contact: title || subtitle }),
          });
        })
        .catch(() => {
          setDialog(false);
          setDisableDialogButton(false);
          setToastState({
            open: true,
            message: t('an_error_occured_while_providing_access_to_contact', { contact: title || subtitle }),
          });
        });
    }

    if (type === messageType.delete) {
      trackAnalytics({
        eventName: 'deny_request',
        props: {
          phone_number: subtitle,
        },
      });
      customerListData
        .deleteMember({ memberIds: [id] })
        .then(() => {
          setDialog(false);
          setDisableDialogButton(false);
          const isItRequest = !accessGivenTime;
          removeRow({ id, isItRequest });
          setToastState({
            open: true,
            message: t('contact_access_revoked_successfully', { contact: title || subtitle }),
          });
        })
        .catch(() => {
          setDialog(false);
          setDisableDialogButton(false);
          setToastState({
            open: true,
            message: t('an_error_occured_while_revoking_access_of_contact', { contact: title || subtitle }),
          });
        });
    }
  };

  const updateRemainingTime = (countdown) => {
    if (countdown > 0 && accessExpiryTime) {
      countdown--;
      setRemainingTime(countdown);
      setFormattedRemainingTime(convertHMS(countdown) + ' ' + t('remaining'));
    }
    if (countdown === 0) {
      const isItRequest = !accessGivenTime;
      removeRow({ id, isItRequest });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(remainingTime);
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  return (
    <div className="customerRowContainer">
      <div className="customerRow">
        <div className="customerDetails">
          <div className="customerName">{title}</div>
          {subtitle && <div className="customerPhone">{subtitle}</div>}
        </div>
        {(buttonText || timerIcon || closeIcon || menuIcon) && (
          <div className="actionButtons">
            {buttonText && (
              <div className="allowButton">
                <Button
                  style={{ backgroundColor: '#4DA47A' }}
                  onClick={() => dialogMessageType(messageType.allow)}
                >
                  <span>{buttonText}</span>
                </Button>
              </div>
            )}
            {timerIcon && (
              <div className="timerIcon" onClick={() => dialogMessageType(messageType.timer)}>
                <AvTimer />
              </div>
            )}
            {closeIcon && (
              <div className="closeIcon" onClick={() => dialogMessageType(messageType.delete)}>
                <Close />
              </div>
            )}
            {menuIcon && (
              <div className="closeIcon" onClick={() => dialogMessageType(messageType.update)}>
                <MoreVert />
              </div>
            )}
          </div>
        )}
      </div>

      <Fragment>
        {accessExpiryTime && new Date(accessExpiryTime) < new Date() && (
          <div
            style={{ color: '#F66C6C', alignItems: 'center', display: 'flex' }}
            className="marginTop"
          >
            <AvTimer style={{ height: '14px' }} />
            <span>
              {typeof expiredMin === 'number' ? t('expired_minutes_ago', { minutes: expiredMin }) : t('expired')}
            </span>
          </div>
        )}
        {accessExpiryTime &&
          new Date(accessExpiryTime) > new Date() &&
          accessTime &&
          formattedRemainingTime && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div
                style={{ color: '#F19C9C', alignItems: 'center', display: 'flex' }}
                className="marginTop"
              >
                <AvTimer style={{ height: '14px' }} />
                <span>{formattedRemainingTime}</span>
              </div>
              <div className="changeTime" onClick={() => dialogMessageType(messageType.timer)}>
                {t('change_time')}
              </div>
            </div>
          )}
        {!accessExpiryTime && accessTime && (
          <div style={{ color: '#E8BC57' }} className="marginTop">
            <div style={{ alignItems: 'center', display: 'flex', marginBottom: '5px' }}>
              <AvTimer style={{ height: '14px' }} />
              <span>{convertHMS(accessTime)}</span>
            </div>
            <div>{t('timer_will_start_when_customer_opens_catalogue')}</div>
          </div>
        )}
      </Fragment>

      {dialog && (
        <DialogBox
          type={'simple'}
          show={dialog}
          onClose={onClose}
          width={350}
          primaryBtnText={dialogType.primaryButton}
          secondaryBtnText={t('cancel')}
          disabledPrimaryButton={
            disableDialogButton || (dialogType.type === 'timer' && !timerValue)
          }
          onSuccess={() => handleSuccess(dialogType.type)}
        >
          {renderDialogContent(dialogType.type)}
        </DialogBox>
      )}
      {menuDialog && (
        <DialogBox
          type={'simple'}
          show={menuDialog}
          onClose={onClose}
          width={350}
          hideFooter
          hideDialogActions
          hideCancelButton
        >
          <div>
            <ListItem
              style={{ padding: '20px' }}
              divider
              onClick={() => dialogMessageType(messageType.timer)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AvTimer />
                <div style={{ marginLeft: '10px' }}>{accessTime ? t('change_time') : t('set_timer')}</div>
              </div>
            </ListItem>
            <ListItem
              style={{ padding: '20px' }}
              onClick={() => dialogMessageType(messageType.delete)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Delete />
                <div style={{ marginLeft: '10px' }}>{t('remove_access')}</div>
              </div>
            </ListItem>
          </div>
        </DialogBox>
      )}
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}
