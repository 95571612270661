import { Box, Button, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import './style.scss';
import { getI18N } from '../../../../../i18N';
import Loader from 'qs-common/Loader';
import CountryCodeSelector from '../CountryCodeSelector';

const AddOrEditCustomerModal = ({
  title,
  btnText,
  onClose,
  onSubmit,
  customerInfo,
  setCustomerInfo,
  loading,
}) => {
  const { t } = getI18N();

  const handleCustomerDetailsChange = (key, value) => {
    setCustomerInfo((prev) => ({ ...prev, [key]: value }));
  };

  const isSubmitDisabled =
    customerInfo.name.length === 0 || customerInfo.phone.length === 0 || loading;

  return (
    <Box className="addOrEditCustomerModalContainer">
      <Box className="addOrEditCustomerModalHeader">
        <Typography className="addOrEditCustomerModalTitle">{title}</Typography>
        <Close className="addOrEditCustomerModalCloseIcon" onClick={onClose} />
      </Box>
      <Box className="addOrEditCustomerModalInputs">
        <TextField
          variant="outlined"
          placeholder={t('enter_name') + '*'}
          className="name"
          InputProps={{
            classes: {
              formControl: 'name-input-form-control',
            },
          }}
          value={customerInfo.name}
          onChange={(e) => handleCustomerDetailsChange('name', e.target.value)}
        />
        <Box className="phoneNumberDetailsContainer">
          <CountryCodeSelector
            value={customerInfo.symbol}
            onChange={(countryInfo) => {
              if (!countryInfo) return;
              handleCustomerDetailsChange('symbol', countryInfo.symbol);
            }}
          />
          <TextField
            variant="outlined"
            placeholder={t('phone_number') + '*'}
            className="phone"
            InputProps={{
              classes: {
                formControl: 'phone-input-form-control',
              },
            }}
            value={customerInfo.phone}
            onChange={(e) => handleCustomerDetailsChange('phone', e.target.value)}
            type='number'
          />
        </Box>
        <TextField
          variant="outlined"
          placeholder={t('company_name')}
          className="companyName"
          InputProps={{
            classes: {
              formControl: 'companyName-input-form-control',
            },
          }}
          value={customerInfo.businessName}
          onChange={(e) => handleCustomerDetailsChange('businessName', e.target.value)}
        />
        <TextField
          variant="outlined"
          placeholder={t('email')}
          className="email"
          InputProps={{
            classes: {
              formControl: 'email-input-form-control',
            },
          }}
          value={customerInfo.email}
          onChange={(e) => handleCustomerDetailsChange('email', e.target.value)}
          type='email'
        />
      </Box>
      <Button
        onClick={onSubmit}
        className={`addOrEditCustomerModalSubmitButton ${
          isSubmitDisabled ? 'addOrEditCustomerModalSubmitButtonDisabled' : ''
        }`}
        disabled={isSubmitDisabled}
      >
        {loading ? (
          <Box className="btnLoader">
            <Loader small />
          </Box>
        ) : (
          btnText
        )}
      </Button>
    </Box>
  );
};

export default AddOrEditCustomerModal;
