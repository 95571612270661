import React from 'react';
import { priceFormatter } from './PriceInfo';
import { ReactComponent as Edit } from '../../../../assets/images/edit-icon.svg';
import Actions from './Actions';
import { getI18N } from '../../../../i18N';

function Details({ productDetails }) {
  const price = productDetails.price;
  const tax = productDetails.taxes || 0;
  const quantity = productDetails.quantity || 1;
  const currency = productDetails.currency;
  const total = price * quantity + (tax || 0) * quantity;
  const { t } = getI18N();
  return (
    <div className="product-details">
      <div className="sub-tax-row">
        <span>{t('price')}</span>
        <span>
          {priceFormatter(price, currency)} <Edit />
        </span>
      </div>
      <div className="sub-tax-row">
        <span>{t('tax')}</span>
        <span>{priceFormatter(tax, currency)}</span>
      </div>
      <div className="sub-tax-row">
        <span>{t('quantity')}</span>
        <span>
          <Actions
            noChevron
            quantity={quantity}
            onIncrement={productDetails?.onIncrement}
            onDecrement={productDetails?.onDecrement}
          />
        </span>
      </div>
      <div className="sub-tax-row">
        <span>{t('total')}</span>
        <span className="green-text">{priceFormatter(total, currency)}</span>
      </div>
    </div>
  );
}

export default Details;
