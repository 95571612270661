import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ButtonBase from '@material-ui/core/ButtonBase';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import DraggableIcon from 'qs-assets/icons/DraggableIcon';
import Loader from 'qs-common/Loader';
import { CHECKOUT_FORM_LABELS } from '../constants';
import { changeOrderCheckoutFields, updateFormFieldComponents } from '../formFieldsData';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { UPDATE_NEW_FIELD } from '../reducer';
import { getI18N } from '../../../i18N';
import './styles.scss';

const SortableDragHandle = SortableHandle(({ disabled }) => (
  <div className={`menu-icon${disabled ? ' disabled' : ''}`}>
    <DraggableIcon />
  </div>
));

const ListItem = ({
  formField,
  useDragHandle,
  refreshing,
  updatingField,
  onClickEditHandler,
  onDeleteCallback,
  toggleVisibility,
}) => {
  const { t } = getI18N();
  const {
    id,
    fieldName: fieldLabel,
    canToggleVisibility,
    visibility,
    removable,
    required,
    canReorder,
    editFieldData
  } = formField;
  const listItemDraggableText = canReorder && !useDragHandle ? 'draggable' : '';
  return (
    <ButtonBase
      key={id}
      className="form-field"
      onClick={() => {
        if (!editFieldData) {
          return;
        }
        onClickEditHandler(formField);
      }}
      component="li"
      disabled={refreshing}
      disableRipple={refreshing || !editFieldData}
      style={{ cursor: editFieldData ? 'pointer' : 'default' }}
    >
      <Box className="form-field-content">
        <Box className={`information ${listItemDraggableText}`}>
        {canReorder && (
            <SortableDragHandle
              disabled={!canReorder}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          )}
          <div className="form-field-list-details">
            <h5 className="name">
              {fieldLabel}
            </h5>
            <h6 className="tagline">
              {required ? t('mandatory') : t('not_mandatory')}
            </h6>
          </div>
        </Box>
        <Box className="actions-buttons">
          {refreshing && updatingField === id ? <Loader small /> : ''}
          {editFieldData && (
            <IconButton
              disabled={refreshing}
              disableRipple={refreshing}
              className="edit-btn"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onClickEditHandler(formField);
              }}
            >
              <Edit />
            </IconButton>
          )}
          {removable && (
            <IconButton
              disabled={refreshing}
              disableRipple={refreshing}
              className="remove-btn"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onDeleteCallback(formField);
              }}
            >
              <Delete />
            </IconButton>
          )}
          {canToggleVisibility && (
            <Switch
              checked={visibility || false}
              className="toggle-btn"
              onChange={() => toggleVisibility(formField)}
            />
          )}
        </Box>
      </Box>
    </ButtonBase>
  );
};

const SortableItem = SortableElement(({
  formField,
  useDragHandle,
  refreshing,
  updatingField,
  onClickEditHandler,
  onDeleteCallback,
  toggleVisibility,
}) => (
  <ListItem
    formField={formField}
    useDragHandle={useDragHandle}
    refreshing={refreshing}
    updatingField={updatingField}
    onClickEditHandler={onClickEditHandler}
    onDeleteCallback={onDeleteCallback}
    toggleVisibility={toggleVisibility}
  />
));

const SortableList = SortableContainer(({
  formFieldItems,
  useDragHandle,
  refreshing,
  updatingField,
  onClickEditHandler,
  onDeleteCallback,
  toggleVisibility,
}) => {
  return (
    <List className="form-fields">
      {formFieldItems.map((formField, fieldIndex) =>
        formField.canReorder ? (
          <SortableItem
            key={formField.id}
            index={fieldIndex}
            formField={formField}
            useDragHandle={useDragHandle}
            refreshing={refreshing}
            updatingField={updatingField}
            onClickEditHandler={onClickEditHandler}
            onDeleteCallback={onDeleteCallback}
            toggleVisibility={toggleVisibility}
          />
        ) : (
          <ListItem
            key={formField.id}
            index={fieldIndex}
            formField={formField}
            useDragHandle={useDragHandle}
            refreshing={refreshing}
            updatingField={updatingField}
            onClickEditHandler={onClickEditHandler}
            onDeleteCallback={onDeleteCallback}
            toggleVisibility={toggleVisibility}
          />
        )
      )}
    </List>
  );
});

const CheckoutFormFields = ({
  refreshing,
  formFieldsData: formFields,
  dispatchCheckoutFormsData,
  showSnackbarMessage,
  useDragHandle,
  onDeleteCallback = () => {},
}) => {
  const { t } = getI18N();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const [updatingField, setUpdatingField] = useState(null);

  useEffect(() => {
    if (!refreshing && updatingField) {
      setUpdatingField(null);
    }
  }, [refreshing, updatingField]);

  const toggleVisibility = (formField) => {
    setUpdatingField(formField.id);
    updateFormFieldComponents(formField, dispatchCheckoutFormsData, showSnackbarMessage);
  };

  const onSortEndHandler = ({ oldIndex, newIndex }) =>
    changeOrderCheckoutFields(
      { formFields, oldIndex, newIndex },
      dispatchCheckoutFormsData,
      showSnackbarMessage
    );

  const onClickEditHandler = (formField) =>
    dispatchCheckoutFormsData({
      type: UPDATE_NEW_FIELD,
      formField,
      editing: true,
    });

  const renderFormFields = () => {
    if (Array.isArray(formFields) && formFields.length === 0) {
      return <Typography>{t(CHECKOUT_FORM_LABELS.noDataMessage)}</Typography>;
    }

    return (
      <SortableList
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        distance={desktop ? 1 : undefined}
        pressDelay={desktop ? undefined : 200}
        onSortEnd={onSortEndHandler}
        useDragHandle={useDragHandle}
        helperClass="dragging-item"
        formFieldItems={formFields}
        refreshing={refreshing}
        updatingField={updatingField}
        onClickEditHandler={onClickEditHandler}
        onDeleteCallback={onDeleteCallback}
        toggleVisibility={toggleVisibility}
      />
    );
  };

  return (
    <Box className="form-fields-container">
      <Typography className="form-fields-label">
        {t(CHECKOUT_FORM_LABELS.tagline)}
      </Typography>
      {renderFormFields()}
    </Box>
  );
};

export default CheckoutFormFields;
