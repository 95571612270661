import React, { useEffect, useState } from 'react';
import { setNavigationBarColor, setStatusBarColor, trackAnalytics } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import ReferralHome from 'qs-components/Referral/ReferralHome';
import ReferralData from 'qs-data/referral';
import { CircularProgress } from '@material-ui/core';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { getGeoData, getCachedReferralPageView, setCachedReferralPageView } from 'qs-data/util';
import { useHistory } from 'react-router-dom';
import { getToken } from 'qs-data/util';
import './style.scss';
import { getI18N } from '../../i18N';

const VIEWS = {
  GLOBAL_REFERRAL_PAGE: 'GLOBAL_REFERRAL_PAGE',
  INDIA_REFERRAL_PAGE: 'INDIA_REFERRAL_PAGE',
};

const getReferralPageType = ({ countryCode }) => {
  if (countryCode === 'IN') {
    return VIEWS.INDIA_REFERRAL_PAGE;
  }
  return VIEWS.GLOBAL_REFERRAL_PAGE;
};

const getReferralPageTheme = (referralPageView) => {
  const { t } = getI18N();
  switch (referralPageView) {
    case VIEWS.INDIA_REFERRAL_PAGE:
      return {
        title: <b>{t('refer_and_win')}</b>,
        background: '#ffffff',
        color: '#242B35',
        borderBottom: '1px solid #ffffff',
      };
    default:
      return {
        title: <b>{t('use_quicksell_free')}</b>,
        background: '#242c36',
        color: '#ffffff',
        borderBottom: '1px solid #242B35',
      };
  }
};

export default function ReferralPage() {
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const history = useHistory();
  const [, dispatch] = useAppContext();
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [referralData, setReferralData] = useState(null);
  const cachedReferralPage = getCachedReferralPageView();
  const [referralPageView, setReferralPageView] = useState(cachedReferralPage);
  const isIndiaReferral = VIEWS.INDIA_REFERRAL_PAGE === cachedReferralPage;
  const referralTheme = getReferralPageTheme(cachedReferralPage);

  useEffect(() => {
    fetchReferralCode();
  }, []);

  const fetchReferralCode = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    try {
      const data = await ReferralData.getReferralCodeAndOffer();
      if (data) {
        setReferralData(data);
        trackAnalytics({
          eventName: 'START - ReferralActivity',
          props: {},
        });
      }
      setProgressBar(false);
    } catch (error) {
      showErrorComponent(true);
      setProgressBar(false);
    }
  };
  const showIndianReferralView = () => {
    return (
      referralPageView === VIEWS.INDIA_REFERRAL_PAGE &&
      source === 'mobile' &&
      showBanner &&
      window.location.href.includes('version=v2')
    );
  };

  const renderIndianReferralPage = () => {
    history.replace(`/referrals?token=${getToken()}&source=${source}&languageCode=${languageCode}`);
  };

  const setReferralPage = async () => {
    setProgressBar(true);
    try {
      const [{ geo }, { showBanner }] = await Promise.all([
        getGeoData(),
        ReferralData.checkIfUserIsJewellery(),
      ]);
      setProgressBar(false);
      if (geo) {
        const referralPageType = getReferralPageType(geo);
        setReferralPageView(referralPageType);
        setCachedReferralPageView(referralPageType);
        setShowBanner(showBanner);
      }
    } catch (error) {
      showErrorComponent(true);
      setProgressBar(false);
    }
  };

  useEffect(() => {
    setReferralPage();
  }, []);

  useEffect(() => {
    if ((progressBar || !referralPageView) && !errorComponent) {
      return;
    }
    if (showIndianReferralView()) {
      return;
    }
    const navConfig = getReferralPageTheme(referralPageView);
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        boxShadow: 'none',
        hideBack: desktop,
        ...navConfig,
      },
    });
    setNavigationBarColor(navConfig.background);
    setStatusBarColor(navConfig.background);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referralPageView, progressBar, showBanner]);

  if (showIndianReferralView()) {
    renderIndianReferralPage();
  }

  const renderReferralPageContent = () => {
    if (progressBar) {
      return <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} />;
    }
    if (errorComponent) {
      return <CustomErrorComponent onRetryClick={fetchReferralCode} />;
    }
    return <ReferralHome referralData={referralData} isIndiaReferral={isIndiaReferral} />;
  };

  if (!referralPageView && !errorComponent) {
    return (
      <div className="referralPage" style={{ alignItems: 'center' }}>
        <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} />
      </div>
    );
  }
  return (
    <div
      className="referralPage"
      style={{
        alignItems: progressBar || errorComponent ? 'center' : 'flex-end',
        background: referralTheme.background,
      }}
    >
      {renderReferralPageContent()}
    </div>
  );
}
