import React from 'react';

export default () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 5</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Android-Copy-5" transform="translate(-27.000000, -577.000000)" fillRule="nonzero">
        <g id="Group-5" transform="translate(27.000000, 577.000000)">
          <path
            d="M19.5555556,22 L2.44444444,22 C1.77222222,22 1.22222222,21.45 1.22222222,20.7777778 L1.22222222,8.55555556 L20.7777778,8.55555556 L20.7777778,20.7777778 C20.7777778,21.45 20.2277778,22 19.5555556,22 Z"
            id="Path"
            fill="#48CF99"
          ></path>
          <path
            d="M22,8.55555556 L0,8.55555556 L0,4.88888889 C0,4.21666667 0.55,3.66666667 1.22222222,3.66666667 L20.7777778,3.66666667 C21.45,3.66666667 22,4.21666667 22,4.88888889 L22,8.55555556 Z"
            id="Path"
            fill="#48CF99"
          ></path>
          <path
            d="M9.77777778,8.55555556 L12.2222222,8.55555556 L12.2222222,22 L9.77777778,22 L9.77777778,8.55555556 Z M15.8888889,0 L13.4444444,0 L9.77777778,3.66666667 L12.2222222,3.66666667 L15.8888889,0 Z"
            id="Shape"
            fill="#FCFF07"
          ></path>
          <polygon
            id="Path"
            fill="#FFC107"
            points="8.55555556 1.13686838e-13 6.11111111 1.13686838e-13 9.77777778 3.66666667 9.77777778 8.55555556 12.2222222 8.55555556 12.2222222 3.66666667"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
