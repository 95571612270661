import React, { useEffect, useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/styles';
import ConditionalLink from 'qs-common/ConditionalLink';
import Icon from 'qs-common/Icon';
import Loader from 'qs-common/Loader';
import { getToken } from 'qs-data/util';
import './style.scss';
import { Button, makeStyles } from '@material-ui/core';
import { getI18N } from '../../i18N';

const CustomSwitch = withStyles({
  switchBase: {
    color: '#949EAE',
    '&$checked': {
      color: '#3E925C',
    },
    '&$checked + $track': {
      backgroundColor: '#2F4B30',
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 24,
    background: '#2FB57A',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#2FB57A',
      borderColor: '#2FB57A',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2FB57A',
      borderColor: '#2FB57A',
    },
  },
}));

const SettingCard = ({
  id,
  className,
  cardIconClassName,
  toLink,
  settingTitle,
  settingSubtitle,
  settingSubtitleClassName = '',
  settingSubtitleIcon,
  initialActiveValue = false,
  onChange,
  iconColor,
  iconStyle = {},
  source = 'mobile',
  languageCode = 'en',
  disabled = false,
  showIcon = true,
  showButton = false,
  onCardClick = null,
  materialStyles = {},
}) => {
  const { t } = getI18N()
  const [isActiveState, setIsActiveState] = useState(initialActiveValue);
  const [loading, setLoading] = useState(false);
  const activeRef = useRef();
  const classes = useStyles();
  activeRef.current = isActiveState;
  useEffect(() => {
    if (activeRef.current !== initialActiveValue) {
      setIsActiveState(initialActiveValue);
    }
  }, [initialActiveValue]);

  const onToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    onChange(!isActiveState, (newValue) => {
      setLoading(false);
      setIsActiveState(newValue);
    });
  };
  const onSettingsCardClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    await onCardClick({ isActiveState, event });
    setLoading(false);
    setIsActiveState(!isActiveState);
  };
  return (
    <Card
      id={`setting-card-${id}`}
      className={`setting-card-container ${className || ''} ${materialStyles.root || ''}`}
      elevation={3}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) {
          return;
        }
        if (onCardClick) {
          onSettingsCardClick(e);
          return;
        }
        onToggle(e);
      }}
    >
      <ConditionalLink
        condition={!disabled}
        link={toLink ? `${toLink}?token=${getToken()}&source=${source}&languageCode=${languageCode}` : ''}
      >
        <CardContent
          id={`setting-card-content-${id}`}
          className={`setting-card-content ${
            materialStyles.configureSettingCardRoot ? materialStyles.configureSettingCardRoot : ''
          }`}
          classes={{ root: classes.root }}
        >
          {showIcon && (
            <div className={`setting-icon ${cardIconClassName || ''}`}>
              <Icon {...iconStyle} color={iconColor} id={id} />
            </div>
          )}

          <div id={`setting-details-${id}`} className="setting-details">
            <div className="setting-details-title">{settingTitle}</div>
            {settingSubtitle ? (
              <div className={` ${settingSubtitleClassName || 'setting-details-subtitle'}`}>
                {settingSubtitleIcon ? (
                  <img className="Icon" alt="sub title icon" src={settingSubtitleIcon} />
                ) : null}
                <span>{settingSubtitle}</span>
              </div>
            ) : null}
          </div>
          <div className="setting-action">
            {showButton ? (
              <Button
                variant="contained"
                size="medium"
                style={{ margin: 0, marginLeft: 15 }}
                className={classes.button}
              >
                {t('configure')}
              </Button>
            ) : loading ? (
              <div className="loader-container">
                <Loader small />
              </div>
            ) : (
              <CustomSwitch
                id={`setting-${id}-switch-button`}
                className="setting-action"
                name={`switch-setting-${id}`}
                elevation={4}
                checked={isActiveState}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
          </div>
        </CardContent>
      </ConditionalLink>
    </Card>
  );
};

export default SettingCard;
