/* eslint-disable no-unused-vars */
class Native {
  goBack() {}
  seatReserved(webinarName, startTime) {}
  setNavigationBarColor(color) {}
  initiateGoogleLogin() {}
  setStatusBarColor(color) {}
  async isFeatureAllowed(feature) {}
  showPremiumFeatureDialog(feature) {}
  shareReferral() {}
  trackAnalytics() {}
  postMessage() {}
  async fetchContactList() {}
  async getIdToken(callbackFunctionName) {}
  async hasPermission(permissionName) {}
  async requestPermission(permissionName) {}
  getSavedLoginCredentials() {}
  postProductType() {}
  deleteLoginCredentials(loginCredentials) {}
}

export default Native;
