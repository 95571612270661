import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import style from "./style.css";
import CustomTextField from "../../../common/CustomTextField";
import useStyles from "../../../common/CustomStyles";
import Loader from "../../../common/Loader";
import Button from "@material-ui/core/Button";
import payments from "../../../data/payments";
import Toast from "../../../common/Alerts/Toast";
import YouTubeRow from "../Paytm/YouTubeRow";
import { getI18N } from "../../../i18N";

function PayPal({ gatewayData }) {
  const [toastState, setToastState] = useState({
    message: "",
    open: false,
  });
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(gatewayData.isActive || false);
  const [credentials, setCredentials] = useState(
    gatewayData && gatewayData.credentials ? gatewayData.credentials : {}
  );
  const { t } = getI18N();

  const onSubmit = () => {
    setLoading(true);
    payments
      .enablePayPal(credentials)
      .then(() => {
        setLoading(false);
        setIsActive(true);
        setToastState({
          message: t('payment_gateway_enabled'),
          open: true,
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          setToastState({
            message: err.response.data.message,
            open: true,
          });
        }
        setLoading(false);
      });
  };

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCredentials((credentials) => {
      return {
        ...credentials,
        [name]: value,
      };
    });
  };

  const classes = useStyles();

  return (
    <div className="PayPalContainer">
      <div className="introContainer">
        <div className="introText">
          {t('please_enter_your_paypal_credentials_in_the_boxes_below_you_will_find_these')}
        </div>

        <a
          className="PaypalLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.paypal.com/signin?returnUri=https%3A%2F%2Fdeveloper.paypal.com%2Fdeveloper%2Fapplications"
        >
          {t('click_here_to_access_paypal')}
        </a>

        <YouTubeRow
          title={t('how_to_connect_paypal_to_quicksell')}
          videoId={'1XGgSNhX5TU'}
        />
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <CustomTextField
          fullWidth
          value={credentials.client_id}
          label={t('client_id')}
          margin="normal"
          name="client_id"
          onChange={onChange}
        />
        <CustomTextField
          fullWidth
          label={t('client_secret')}
          name="client_secret"
          value={credentials.client_secret}
          onChange={onChange}
        />

        {loading ? (
          <div style={{ marginLeft: 24, marginTop: 24 }}>
            <Loader small />
          </div>
        ) : isActive ? (
          <div>
            <Button
              variant="contained"
              size="large"
              className={classes.buttonDisable}
              onClick={() => {
                setLoading(true);
                payments.disablePayPal().then(() => {
                  setIsActive(false);
                  setLoading(false);
                  setToastState({
                    message: t('payment_gateway_disabled'),
                    open: true,
                  });
                });
              }}
            >
              {t('disable')}
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            onClick={onSubmit}
          >
            {t('enable')}
          </Button>
        )}
      </form>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: "",
          });
        }}
      />
    </div>
  );
}

export default PayPal;
