import CacheRequest from './cacheRequest';
import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

let apis = {
  shippingCostStatusEndPoint: '/v1/experiment/shipping-cost',
  shippingHomeLocationEndPoint: '/v1/shipping-cost/company-location',
  shippingWeightUnitEndPoint: '/v1/shipping-cost/weight',
  shippingCities: '/v1/shipping-cost/all-cities?nameLike=',
  shippingModesEndPoint: '/v1/shipping-cost/shipping-modes',
  shippingZonesEndPoint: '/v1/shipping-cost/shipping-zones',
  shippingWeightProfileEndPoint: '/v1/shipping-cost/shipping-weights',
  shippingCurrencyUnitEndPoint: '/v1/shipping-cost/currency',
  shippingMinimumAmountEndPoint: '/v1/shipping-cost/minimum-shipping-price',
  shippingPriceEndPoint: '/v1/shipping-cost/shipping-price'
};

/* Shipping Profile data cache */

const setCacheForShippingProfileData = (shippingData) => {
  if (shippingData) {
    CacheRequest.setCacheForKey(CacheKeys.shippingProfilesCacheKey, shippingData);
  }
}

const getCachedShippingProfileData = () => {
  return CacheRequest.getCacheForKey(CacheKeys.shippingProfilesCacheKey);
}

/* Shipping collection status apis */

const getShippingCostCollectionStatus = () => {
  return ApiWrapper.getDataPromise(apis.shippingCostStatusEndPoint, 'shippingCost', CacheKeys.collectShippingCostStatusKey);
};

const updateShippingCostCollectionStatus = (params) => {
  return ApiWrapper.postDataPromise(apis.shippingCostStatusEndPoint, params, CacheKeys.collectShippingCostStatusKey);
};

/* Shipping home location apis */

const getShippingHomeLocation = () => {
  return ApiWrapper.getDataPromise(apis.shippingHomeLocationEndPoint, 'companyAddress', CacheKeys.shippingHomeLocationKey);
};

const updateShippingHomeLocation = (params) => {
  return ApiWrapper.putDataPromise(apis.shippingHomeLocationEndPoint, params, CacheKeys.shippingHomeLocationKey);
};

const getAllCities = (query) => {
  return ApiWrapper.getDataPromise(apis.shippingCities, 'allCities', null, query);
};

/* Shipping weight unit apis */

const getShippingWeightUnit = () => {
  return ApiWrapper.getDataPromise(apis.shippingWeightUnitEndPoint, 'weightUnit', CacheKeys.shippingWeightUnitKey);
};

const updateShippingWeightUnit = (params) => {
  return ApiWrapper.putDataPromise(apis.shippingWeightUnitEndPoint, params, CacheKeys.shippingWeightUnitKey);
};

/* Shipping modes apis */

const getShippingModes = () => {
  return ApiWrapper.getDataPromise(apis.shippingModesEndPoint, 'shippingModes', CacheKeys.shippingModesCacheKey);
};

const addShippingMode = (params) => {
  return ApiWrapper.postDataPromise(apis.shippingModesEndPoint, params, CacheKeys.shippingModesCacheKey);
};

const updateShippingMode = (params, deleteCache) => {
  return ApiWrapper.putDataPromise(apis.shippingModesEndPoint, params, CacheKeys.shippingModesCacheKey);
};

const deleteShippingMode = (params) => {
  return ApiWrapper.deleteDataPromise(apis.shippingModesEndPoint, params, CacheKeys.shippingModesCacheKey);
};

/* Shipping zones apis */

const getShippingZones = () => {
  return ApiWrapper.getDataPromise(apis.shippingZonesEndPoint, 'shippingZones', CacheKeys.shippingZonesCacheKey);
};

const updateShippingZone = (params) => {
  return ApiWrapper.putDataPromise(apis.shippingZonesEndPoint, params, CacheKeys.shippingZonesCacheKey);
};

/* Shipping weight profile apis */

const getShippingWeightProfiles = () => {
  return ApiWrapper.getDataPromise(apis.shippingWeightProfileEndPoint, 'weightProfiles', CacheKeys.shippingWeightProfilesCacheKey);
};

const addShippingWeightProfile = (params) => {
  return ApiWrapper.postDataPromise(apis.shippingWeightProfileEndPoint, params, CacheKeys.shippingWeightProfilesCacheKey);
};

const updateShippingWeightProfile = (params) => {
  return ApiWrapper.putDataPromise(apis.shippingWeightProfileEndPoint, params, CacheKeys.shippingWeightProfilesCacheKey);
};

const deleteShippingWeightProfile = (params) => {
  return ApiWrapper.deleteDataPromise(apis.shippingWeightProfileEndPoint, params, CacheKeys.shippingWeightProfilesCacheKey);
};

/* Get company currency unit */

const getCurrencyUnit = () => {
  return ApiWrapper.getDataPromise(apis.shippingCurrencyUnitEndPoint, 'currencyCode', null);
};

/* Shipping cost prices */

const getMinOrderAmountForFreeShipping = (shippingModeId, shippingZoneId) => {
  return ApiWrapper.getDataPromise(apis.shippingMinimumAmountEndPoint + `?shippingModeId=${shippingModeId}&shippingZoneId=${shippingZoneId}`, 'minFreeShippingPrice', null);
};

const setMinOrderAmountForFreeShipping = (shippingModeId, shippingZoneId, amount) => {
  return ApiWrapper.putDataPromise(apis.shippingMinimumAmountEndPoint + `?shippingModeId=${shippingModeId}&shippingZoneId=${shippingZoneId}`, amount, null);
};

/* Shipping cost prices */

const getShippingCostPrices = (shippingModeId, shippingZoneId) => {
  return ApiWrapper.getDataPromise(apis.shippingPriceEndPoint + `?shippingModeId=${shippingModeId}&shippingZoneId=${shippingZoneId}`, 'shippingPrice', null);
};

const setShippingCostPrices = (price) => {
  return ApiWrapper.putDataPromise(apis.shippingPriceEndPoint, price, null);
};

export default {
  getShippingCostCollectionStatus,
  updateShippingCostCollectionStatus,
  getShippingHomeLocation,
  updateShippingHomeLocation,
  getShippingWeightUnit,
  updateShippingWeightUnit,
  getAllCities,
  getShippingModes,
  addShippingMode,
  updateShippingMode,
  deleteShippingMode,
  getShippingZones,
  updateShippingZone,
  getShippingWeightProfiles,
  addShippingWeightProfile,
  updateShippingWeightProfile,
  deleteShippingWeightProfile,
  getCurrencyUnit,
  getMinOrderAmountForFreeShipping,
  setMinOrderAmountForFreeShipping,
  getShippingCostPrices,
  setShippingCostPrices,
  setCacheForShippingProfileData,
  getCachedShippingProfileData
}