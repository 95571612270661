import { Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import network from 'qs-data/network';
import './style.scss';
import Toast from 'qs-common/Alerts/Toast';
import { useHistory } from 'react-router-dom';
import SingleThemeContainer from 'qs-components/Theme/SingleThemeContainer';
import CreateCustomThemeIcon from 'qs-assets/icons/CreateCustomThemeIcon';
import EditIcon from 'qs-assets/icons/EditIcon';
import UseThemeIcon from 'qs-assets/icons/UseThemeIcon';
import PreviewThemeIcon from 'qs-assets/icons/PreviewThemeIcon';
import RightArrowIcon from 'qs-assets/icons/RightArrowIcon';
import { getI18N } from '../../i18N';
import { getToken } from 'qs-data/util';
import Utility from 'qs-helpers/utility';

const useStyles = makeStyles({
  paper: {
    backgroundColor: '#161E27',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    margin: 'auto',
    maxWidth: 800,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 15,
    '&:hover': {
      outline: 'none',
      backgroundColor: '#0d131b',
    },
  },
  listItemIcon: {
    minWidth: 0,
    paddingRight: 20,
  },
});

const themeLinkCache = {};

const ThemeEditDrawer = ({
  setShowThemesEditDrawer,
  editThemeHandler,
  theme,
  onSelectedThemeChanged,
  selectedTheme,
  source = 'mobile',
  languageCode = 'en'
}) => {
  const { t } = getI18N();
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(true);

  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const navigateToAddNewTheme = () => {
    history.push({
      pathname: '/theme-update',
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
      state: {
        primaryColor: theme.addToCartButton.color,
        pageTextColor: theme.pageTextColor,
        contrastTextColor: theme.addToCartButton.textColor,
        backgroundColor: theme.homeGrid.backgroundColor,
        theme,
      },
    });
  };

  const onClose = () => {
    setShowThemesEditDrawer(false);
    setOpen(false);
  };
  const editQuickSellBasicThemes = () => {
    navigateToAddNewTheme();
  };
  const onEditThemeClick = (isEditable, e) => {
    isEditable ? editThemeHandler(theme)(e) : editQuickSellBasicThemes();
  };

  const onPreviewThemeClick = async () => {
    if (themeLinkCache[theme.id]) {
      Utility.openPopup(themeLinkCache[theme.id]);
      return;
    }
    try {
      let link = await network.getCatalogueLink();
      link = `${link}?themeId=${theme.id}`;
      themeLinkCache[theme.id] = link;
      Utility.openPopup(link);
    } catch (error) {
      setToastState({
        open: true,
        message: `${error.response.data.message}`,
      });
    }
  };
  const onUseThemeClick = () => {
    onSelectedThemeChanged(theme)();
    onClose();
  };

  const actions = [
    {
      title: t('preview_theme'),
      icon: <PreviewThemeIcon color="#FFF" size="20" />,
      onClick: onPreviewThemeClick,
      showLoader: false,
    },
    {
      title: selectedTheme.id === theme.id ? t('selected_theme') : t('use_theme'),
      icon: <UseThemeIcon color="#FFF" size="20" />,
      onClick: onUseThemeClick,
      showLoader: false,
    },
    {
      title: theme.isEditable ? t('edit_theme') : t('create_custom_theme_with_this_theme'),
      icon: theme.isEditable ? (
        <EditIcon color="#FFF" size="20" />
      ) : (
        <CreateCustomThemeIcon color="#FFF" size="20" />
      ),
      onClick: (e) => onEditThemeClick(theme.isEditable, e),
      showLoader: false,
    },
  ];
  const list = () => (
    <List>
      {actions.map(({ icon, onClick, title }, index) => (
        <ListItem
          button
          key={title}
          onClick={onClick}
          className="singleListItemContainer"
          classes={{ root: classes.listItem }}
          disabled={index === 1 && selectedTheme.id === theme.id}
        >
          <div
            className="singleList"
            style={index === actions.length - 1 ? { borderBottom: 'none' } : {}}
          >
            <ListItemIcon classes={{ root: classes.listItemIcon }}>{icon}</ListItemIcon>
            <ListItemText className="listItemText" primary={title} />
            <div className="arrow">
              <RightArrowIcon color="#FFF" size="32" />
            </div>
          </div>
        </ListItem>
      ))}
    </List>
  );
  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        classes={{ paper: classes.paper }}
        onClose={onClose}
        className="drawer"
      >
        <SingleThemeContainer
          theme={theme}
          hideSetThemeIcon
          fromThemeEditDrawer={true}
          themeEditDrawerHeaderClass={'drawerHeader'}
          source={source}
          languageCode={languageCode}
        />
        {list()}
      </Drawer>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};
export default ThemeEditDrawer;
