import React from 'react';
import './styles.scss';
import { priceFormatter } from '../Product/components/PriceInfo';
import { getI18N } from '../../../i18N';

export default function SubTax({ subTotal = 4000, totalTax = 4000, currency }) {
  const { t } = getI18N();
  return (
    <div className="sub-tax">
      <div className="sub-tax-row">
        <span>{t('subtotal')}</span>
        <span>{priceFormatter(subTotal, currency)}</span>
      </div>
      <div className="sub-tax-row">
        <span>{t('total_tax')}</span>
        <span>{priceFormatter(totalTax, currency)}</span>
      </div>
      <div className="sub-tax-row">
        <span>{t('discount')}</span>
        <span className="underline">{t('add_discount')}</span>
      </div>
    </div>
  );
}
