import React, { useState } from 'react';
import { ArrowForward, Share } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Toast from 'qs-common/Alerts/Toast';
import RefferalBanner from 'qs-assets/images/referralBanner.jpg';
import { ReactComponent as ReferralImage } from 'qs-assets/images/refer.svg';
import ColoredButton from 'qs-common/ColoredButton';
import Utility from 'qs-helpers/utility';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { shareReferral } from '../../../os';
import { getToken } from 'qs-data/util';
import Analytics from 'qs-data/analytics';
import Loader from 'qs-common/Loader';
import './style.scss';
import { getI18N } from '../../../i18N';

export default function Referral({ referralData = {}, isIndiaReferral }) {
  const { t } = getI18N();
  const history = useHistory();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });

  const [imageLoaded, setImageLoaded] = useState(false);

  const trackShareReferralEvent = (event) => {
    Analytics.trackEvent({ eventName: event });
  };

  const navigateToReferralList = () => {
    trackShareReferralEvent('track_referral');
    history.push({
      pathname: `/referral/track/${isIndiaReferral}`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };

  const navigateToTermsAndCondition = () => {
    trackShareReferralEvent('referral_tnc_clicked');
    history.push({
      pathname: `/referral/tnc`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
    });
  };

  const copyReferralCodeToClipboard = () => {
    trackShareReferralEvent('copied_referral_code');
    Utility.copyToClipboard(referralData.referralCode);
    setToastState({
      open: true,
      message: t('referral_code_copied_to_clipboard'),
    });
  };

  const shareReferralCode = () => {
    trackShareReferralEvent('share_referral_code');
    const referralContent = isIndiaReferral
      ? t('referral_reward_message_india', {
          referralCode: referralData.referralCode,
        })
      : t('referral_reward_message', {
          referralCode: referralData.referralCode,
        });
    shareReferral(referralContent);
  };

  const buttons = [
    {
      primaryColor: isIndiaReferral ? '#F54C49' : '#5D6EC2',
      secondaryColor: isIndiaReferral ? '#F54C49' : '#47529C',
      primaryContent: <span className="primaryButtonText textWhite">{t('share_invite_link')}</span>,
      secondaryContent: (
        <span className="secondaryButtonText">
          <Share />
        </span>
      ),
      onClick: shareReferralCode,
    },
    {
      primaryColor: isIndiaReferral ? '#279B74' : '#000000',
      secondaryColor: isIndiaReferral ? '#279B74' : '#292929',
      primaryContent: (
        <div className="code">
          {isIndiaReferral ? (
            <span className="secondaryButtonText">
              {t('referral_code')} <span className="subText">{referralData.referralCode}</span>
            </span>
          ) : (
            <>
              <span className="secondaryButtonText">{t('referral_code')}</span>
              <span className="primaryButtonText textWhite">{referralData.referralCode}</span>
            </>
          )}
        </div>
      ),
      secondaryContent: <span className="secondaryButtonText">{t('copy')}</span>,
      onClick: copyReferralCodeToClipboard,
    },
    {
      primaryColor: isIndiaReferral ? '#279B74' : '#ffffff',
      secondaryColor: isIndiaReferral ? '#279B74' : '#ABB6BD',
      primaryContent: (
        <span
          className="primaryButtonText"
          style={{ color: isIndiaReferral ? '#ffffff' : '#000000' }}
        >
          {t('track_referrals')}
        </span>
      ),
      secondaryContent: (
        <span className="secondaryButtonText">
          <ArrowForward />
        </span>
      ),
      onClick: navigateToReferralList,
    },
  ];

  const renderBannerAndTextForIndiaReferral = () => {
    return (
      <>
        <div className="referralBanner" style={{ minWidth: '250px', minHeight: '280px' }}>
          {imageLoaded ? null : <Loader />}
          <img
            src={RefferalBanner}
            style={imageLoaded ? {} : { display: 'none' }}
            alt="referral banner"
            className="referralImageIndia"
            onLoad={() => setImageLoaded(true)}
          />
        </div>
        <div className="referralSteps">
          <ol>
            <li>{t('refer_a_friend_to_quicksell')}</li>
            <li>{t('your_friend_purchases_any_quicksell_subscription_plan')}</li>
            <li>{t('you_both_get_reward_each', { reward: t('amount_amazon_gift_card', { amount: 'Rs.2000' }) })}</li>
          </ol>
        </div>
      </>
    );
  };

  const renderBannerAndTextForReferral = () => {
    return (
      <>
        <ReferralImage className="referralImage" />
        <p className="main">{t('refer_and_grow')}</p>
        <p className="info">
          {t('get_offer_for_every_referral', { offer: referralData.referralOfferShortText })}
        </p>
      </>
    );
  };

  return (
    <div
      className="referralHome"
      style={{ backgroundColor: isIndiaReferral ? '#ffffff' : '#242C36' }}
    >
      <div className="contentContainer">
        <div className="content">
          {isIndiaReferral
            ? renderBannerAndTextForIndiaReferral()
            : renderBannerAndTextForReferral()}
        </div>
      </div>
      <div className="actions">
        {buttons.map((btn, i) => (
          <ColoredButton key={i} {...btn} isIndiaReferral={isIndiaReferral} />
        ))}
        {isIndiaReferral && (
          <div className="referralTnc" onClick={navigateToTermsAndCondition}>
            {t('terms_and_conditions')}
          </div>
        )}
      </div>

      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </div>
  );
}
