import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Button } from '@material-ui/core';
import {
  fetchContactList as fetchContactListFromDevice,
  hasPermission,
  requestPermission,
} from '../../../os';
import Toast from '../../../common/Alerts/Toast';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { setContactList } from 'qs-helpers/contacts';
import { getI18N } from '../../../i18N';
import network from 'qs-data/network';

const ContactPermission = ({ hasContactsPermission, setHasContactsPermission }) => {
  const { t } = getI18N();
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';

  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [loading, setLoading] = useState(true);

  const permissionResult = async ({ detail } = {}) => {
    const { permission } = detail;
    setLoading(false);
    if (permission) {
      await getContactsList({ syncContactsToServer: true });
      setHasContactsPermission(true);
      return;
    } else {
      setHasContactsPermission(false);
      setToastState({
        open: true,
        message: t('allow_access_to_contacts'),
      });
    }
  };

  useEffect(() => {
    document.addEventListener('permissionResult', permissionResult);
    return () => {
      document.removeEventListener('permissionResult', permissionResult);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!desktop) {
      checkPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop]);

  const checkPermission = async () => {
    setLoading(true);
    try {
      const hasContactsPermission = await hasPermission('contacts');
      if (hasContactsPermission) {
        await getContactsList({ syncContactsToServer: true });
      }
      setHasContactsPermission(hasContactsPermission);
    } catch (error) {
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    } finally {
      setLoading(false);
    }
  };

  const getContactsList = async ({ syncContactsToServer = false } = {}) => {
    setLoading(true);
    try {
      const data = await fetchContactListFromDevice();
      const contacts = typeof data === 'string' ? JSON.parse(data) : data;
      const formattedContacts = (contacts || []).reduce((acc, contact) => {
        if (contact.phoneNumber) {
          acc.push({ phoneNumber: contact.phoneNumber, name: contact.name });
        }
        return acc;
      }, []);
      setContactList(formattedContacts);
      if (syncContactsToServer) {
        try {
          const contactsLastSyncedTime = localStorage.getItem('CONTACTS_LAST_SYNCED_TIME');
          if (typeof contactsLastSyncedTime === 'string' && contactsLastSyncedTime.length > 0) {
            if (new Date().getTime() - new Date(contactsLastSyncedTime).getTime() < 60 * 60 * 1000) {
              return;
            }
          }
          await network.syncContacts({ contactData: formattedContacts });
          localStorage.setItem('CONTACTS_LAST_SYNCED_TIME', new Date().toISOString());
        } catch (error) {
          console.error(error);
          localStorage.removeItem('CONTACTS_LAST_SYNCED_TIME');
        }
      }
    } catch (error) {
      setToastState({
        open: true,
        message: t('could_not_get_contacts'),
      });
    } finally {
      setLoading(false);
    }
  };

  const askForContactsPermission = () => {
    if(hasContactsPermission || loading) return;
    requestPermission('contacts');
  };

  if (hasContactsPermission || loading || desktop) {
    return null;
  }

  return (
    <>
      <div className="contactPermission">
        <div className="contactPermissionDetails">
          <div className="title">{t('sync_your_contacts_with_quicksell')}</div>
          <div className="description">{t('show_and_import_contacts_from_phonebook')}</div>
        </div>
        <Button className="allowButton" onClick={askForContactsPermission} disabled={loading}>
          {t('allow')}
        </Button>
      </div>
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default ContactPermission;
