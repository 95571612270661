import React, { useState, useRef, useEffect } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg';
import CustomDivider from 'qs-common/CustomDivider';
import { getI18N } from '../../i18N';
import './style.scss';

const useStyles = makeStyles({
  dlgTitle: {
    fontSize: 22,
  },
  dlgContent: {
    padding: '0 !important',
  },
});

const NewDialogTitle = withStyles({
  root: {
    color: 'white',
    fontSize: 26,
    fontWeight: 700,
  },
})(DialogTitle);

function DialogBox(props) {
  const {
    show = false,
    title = 'Title',
    onClose,
    onSuccess,
    // onDelete,
    hidePrimaryButton,
    primaryBtnText,
    disabledPrimaryButton,
    // deleteBtnText,
    secondaryBtnText,
    content,
    dark = false,
    danger = false,
    width,
    height,
    children,
    type,
    dialogId,
    showProgressBar,
    closeDialog,
    showFooterDivider,
    hideFooter = false,
    hideCancelButton = false,
    hideDialogActions = false,
    // ...rest
  } = props;

  const [open, setOpen] = useState(show);

  const { t } = getI18N();

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  let paperStyle = {
    width,
    height,
    minWidth: '25%',
  };
  paperStyle = dark
    ? {
        backgroundColor: '#161C23',
        ...paperStyle,
      }
    : paperStyle;

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (open) {
      history.push(dialogId);
    } else {
      // onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);

  const isMount = isMountRef.current;
  useEffect(() => {
    if (isMount) {
      isMountRef.current = false;
      return;
    }

    if (location.hash === dialogId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  const closeDialogAndGoBack = () => {
    history.goBack();
    setOpen(false);
  };

  const onCancelClick = () => {
    closeDialogAndGoBack();
    onClose && onClose();
    // setOpen(!open);
  };

  const onSucessClick = (e) => {
    e.preventDefault();
    !disabledPrimaryButton && onSuccess && onSuccess();
    // setOpen(!open);
  };

  useEffect(() => {
    if (closeDialog) {
      closeDialogAndGoBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDialog]);

  const DiagTitle = dark ? NewDialogTitle : DialogTitle;

  const MainButton = withStyles({
    root: {
      color: danger ? '#E76F6F' : '#92CFB3',
    },
  })(Button);

  if (!open) {
    return false;
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: paperStyle,
        component: 'form',
        onSubmit: onSucessClick,
        noValidate: true,
      }}
      onClose={onCancelClick}
      aria-labelledby="form-dialog-title"
      // {...rest}
    >
      {type !== 'simple' && (
        <DiagTitle disableTypography={true} id="form-dialog-title">
          <div className={classes.dlgTitle}>{title}</div>
        </DiagTitle>
      )}
      {(content || children) && (
        <DialogContent className={`${type === 'simple' ? classes.dlgContent : ''}`}>
          {content || children}
        </DialogContent>
      )}
      {type !== 'simple' && !hideFooter && (
        <DialogActions>
          <Button onClick={onCancelClick} color="primary">
            {secondaryBtnText || t('cancel')}
          </Button>
          <MainButton onClick={onSucessClick} color="primary">
            {primaryBtnText || t('ok')}
          </MainButton>
        </DialogActions>
      )}
      {type === 'simple' && !hideDialogActions && (
        <div>
          {showFooterDivider && <CustomDivider />}
          <DialogActions>
            {showProgressBar && (
              <CircularProgress
                style={{ marginRight: '8px', padding: '8px' }}
                size={24}
                color="primary"
              />
            )}
            {/* {!showProgressBar && secondaryBtnText && <Button onClick={onCancelClick}>
              {secondaryBtnText || t('cancel')}
            </Button>} */}
            {/* {!showProgressBar && deleteBtnText &&
              <div className='deleteButton' onClick={onDelete}>
                <TrashIcon width="18" height="18" />
              </div>
            } */}
            {!showProgressBar && !hideCancelButton && (
              <Button className="cancelButton" onClick={onCancelClick} color="primary">
                {secondaryBtnText || t('cancel')}
              </Button>
            )}
            {!showProgressBar && !hidePrimaryButton && (
              <MainButton
                className="mainButton"
                onClick={onSucessClick}
                disabled={disabledPrimaryButton}
              >
                {primaryBtnText || t('ok')}
              </MainButton>
            )}
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
}

export default withRouter(DialogBox);
