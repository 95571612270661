import React, { Fragment, useState } from 'react';
import DialogBox from "qs-common/DialogBox";
import "./style.scss";
import { getI18N } from '../../../i18N';

export default function ({ showDialog, onClose, onSave }) {
  const { t } = getI18N();
  const [closeDialog, setCloseDialog] = useState(false);

  const onSaveClick = async () => {
    setCloseDialog(true);
    setTimeout(()=> {
      onSave();
    }, 200)
  }

  return <DialogBox
    type={"simple"}
    dialogId={'#save-theme'}
    show={showDialog}
    width={300}
    primaryBtnText={t('save_theme')}
    secondaryBtnText={t('cancel')}
    onSuccess={onSaveClick}
    onClose={onClose}
    closeDialog={closeDialog}
  >
    <Fragment>
      <div className='saveTheme'>
        <p className='dialogTitle'>
          {t('this_theme_is_still_unsaved_would_you_like_to_save_this_theme')}
        </p>
      </div>
    </Fragment>
  </DialogBox>
}
