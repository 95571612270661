import React from 'react';

export default () => (
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons8-copy</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Android-Copy-5"
        transform="translate(-302.000000, -272.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="icons8-copy" transform="translate(302.000000, 272.000000)">
          <path
            d="M1.7,0 C0.76075,0 0,0.76075 0,1.7 L0,13.6 L1.7,13.6 L1.7,1.7 L13.6,1.7 L13.6,0 L1.7,0 Z M5.1,3.4 C4.16075,3.4 3.4,4.16075 3.4,5.1 L3.4,15.3 C3.4,16.23925 4.16075,17 5.1,17 L15.3,17 C16.23925,17 17,16.23925 17,15.3 L17,5.1 C17,4.16075 16.23925,3.4 15.3,3.4 L5.1,3.4 Z M5.1,5.1 L15.3,5.1 L15.3,15.3 L5.1,15.3 L5.1,5.1 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
