import React, { Fragment, useState } from "react";
import DialogBox from "qs-common/DialogBox";
import { CircularProgress } from "@material-ui/core";
import { getI18N } from '../../../i18N';
import "./style.scss";

export default function ({ showDialog, onClose }) {
  const [progressBar, setProgressBar] = useState(true);
  const { t } = getI18N();
  return (
    showDialog && (
      <DialogBox
        type={'simple'}
        dialogId={'#referal-reward-scheme'}
        show={showDialog}
        onSuccess={onClose}
        onClose={onClose}
        secondaryBtnText={t('close')}
        hidePrimaryButton={true}
      >
        <Fragment>
          <div className="referralRewardSchemeContent">
            <p className="dialogTitle">{t('reward_scheme')}</p>
            <div className="rewardSchemeImage">
              {progressBar && <CircularProgress size={36} color="primary" />}
              <img
                style={{ visibility: progressBar ? "hidden" : "visible" }}
                src="https://s3.amazonaws.com/appext.quicksell.co/referral_reward_scheme.png"
                alt={t('reward_scheme')}
                width="100%"
                onLoad={() => setProgressBar(false)}
              />
            </div>
          </div>
        </Fragment>
      </DialogBox>
    )
  );
}
