import CacheKeys from './cacheKeys';
import { handleError } from 'qs-common/error';
import network from './network';
import ApiWrapper from './apiWrapper';

const apis = {
  nativeAppDetails: '/v1/native-app-offering/app-details',
  pushNotification: '/v1/native-app-offering/send-notification',
  pushNotificationList: '/v1/native-app-offering/notifications-list',
};

const getNativeAppDetails = () => {
  return network.getNativeAppDetails().catch((err) => {
    handleError(err);
    throw err;
  });
};

const getNativeAppNotificationList = ({ page = 1 }) => {
  return ApiWrapper.getDataPromise(
    `${apis.pushNotificationList}?page=${page}`,
    'notificationsList'
  );
};

const updateNativeAppDetails = (data) => {
  const formdata = new FormData();
  formdata.append('appName', data.appName);
  formdata.append('shortDescription', data.shortDescription);
  formdata.append('longDescription', data.longDescription);
  formdata.append('icon', data.icon);
  return ApiWrapper.putDataPromise(apis.nativeAppDetails, formdata, CacheKeys.nativeAppDetails);
};

const getNativeAppPushNotificationData = () => {
  return ApiWrapper.getDataPromise(apis.pushNotification, '', CacheKeys.nativeAppPushNotification);
};

const setNativeAppPushNotification = (data) => {
  const formdata = new FormData();
  formdata.append('title', data.title);
  formdata.append('body', data.body);
  formdata.append('icon', data.icon);
  return ApiWrapper.postDataPromise(
    apis.pushNotification,
    formdata,
    CacheKeys.nativeAppPushNotification
  );
};

export default {
  getNativeAppDetails,
  getNativeAppPushNotificationData,
  getNativeAppNotificationList,
  updateNativeAppDetails,
  setNativeAppPushNotification,
};
