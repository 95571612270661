import React from 'react';

export default ({ color = "#00A674", width = 26, height = 29 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M23.9473 9.55586C23.8665 7.64222 23.0743 5.83468 21.7359 4.5101C20.3974 3.18553 18.616 2.44617 16.7631 2.44617C14.9102 2.44617 13.1289 3.18553 11.7904 4.5101C10.452 5.83468 9.65973 7.64222 9.57892 9.55586C9.58174 10.164 9.65525 10.7695 9.79787 11.3595C8.41796 11.5193 7.11231 12.0887 6.03861 12.9989C4.96491 13.9091 4.16909 15.1212 3.74724 16.4888C3.32539 17.8563 3.29557 19.3209 3.66138 20.7057C4.02719 22.0904 4.77298 23.336 5.80868 24.2921C6.84438 25.2482 8.1257 25.8739 9.49788 26.0935C10.8701 26.3132 12.2744 26.1175 13.5414 25.5301C14.8083 24.9426 15.8836 23.9885 16.6374 22.7831C17.3913 21.5776 17.7914 20.1723 17.7894 18.7368C17.7866 18.1287 17.7131 17.5232 17.5705 16.9332C19.3239 16.7266 20.9426 15.8607 22.1185 14.5004C23.2943 13.14 23.9453 11.3802 23.9473 9.55586ZM13.4105 20.6537L12.4663 21.6298L10.6121 19.7059L8.75787 21.6298L7.81366 20.6537L9.67471 18.7368L7.81366 16.82L8.75787 15.8439L10.6121 17.7678L12.4663 15.8439L13.4105 16.82L11.5494 18.7368L13.4105 20.6537ZM15.8258 12.4842L13 9.52049L13.9373 8.55147L15.86 10.539L19.6437 6.62757L20.5263 7.59659L15.8258 12.4842Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}