import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import ShippingData from 'qs-data/shipping';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import Profiles from 'qs-components/Shipping/Profiles';
import { getI18N } from '../../i18N';
import './style.scss';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';

const ShippingProfile = () => {
  const history = useHistory();
  const [, dispatch] = useAppContext();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [shippingModes, setShippingModes] = useState(null);
  const [shippingZones, setShippingZones] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);

  const { t } = getI18N();

  useEffect(() => {
    window.scrollTo(0, 0);
    let shippingData = history.location.state;
    if (!shippingData) {
      shippingData = ShippingData.getCachedShippingProfileData();
    }
    if (shippingData) {
      setShippingModes(shippingData.shippingModes);
      setShippingZones(shippingData.shippingZones);
      setWeightUnit(shippingData.weightUnit);
      ShippingData.setCacheForShippingProfileData(shippingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('shipping_profiles'),
      },
    });
    setNavigationBarColor('#28303A');
    setStatusBarColor('#4DA47A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop]);

  return (
    <div className="shippingProfileContainer">
      {shippingModes && shippingZones && (
        <Profiles
          shippingModes={shippingModes}
          shippingZones={shippingZones}
          weightUnit={weightUnit}
          source={source}
          languageCode={languageCode}
        />
      )}
    </div>
  );
};

export default withRouter(ShippingProfile);
