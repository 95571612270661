import React, { useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import {
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import FieldsList from './fieldsList';
import { useAppContext } from '../../common/Contexts/AppContext';
import CustomFieldDialogBox from '../../common/CustomFieldDialogBox';
import Loader from '../../common/Loader';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';
import { ReactComponent as EditSvg } from '../../assets/images/edit.svg';
import useStyles from './styles';
import './style.css';
import customFieldData from 'qs-data/customFields';
import Toast from '../../common/Alerts/Toast';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import HelpButton from 'qs-data/helpButton';
import { getI18N } from '../../i18N';
import { arrayMove } from 'react-sortable-hoc';
import Utility from 'qs-helpers/utility';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';

const FIELD_TYPES = ['STRING', 'NUMBER', 'BOOLEAN'];

const DLG_BUTTONS = ['private', 'public'];

const ROW_HEIGHT = 80;
const HEADER_HEIGHT = 64;

let lastAddedValue = '';

export default function CustomFields(props) {
  const classes = useStyles();
  const [showAddDlg, setShowAddDlg] = useState(false);
  const [dlgText, setDlgText] = useState('');
  const [dlgType, setDlgType] = useState('');
  const [btnSelected, setBtnSelected] = useState('');
  const [textErr, setTxtErr] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [btnErr, setBtnErr] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [showErrorDlg, setShowErrorDlg] = useState({});
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const [updatingFields, setUpdatingFields] = useState(false);
  const [reorderingItem, setReorderingItem] = useState('');
  const source = useSearchParamsQuery().get('source');
  const desktop = !source || source === 'desktop';
  const dispatch = useAppContext()[1];
  const { history } = props;
  const {
    location: { hash, search },
  } = history;

  const { t } = getI18N();

  const getFields = useCallback(() => {
    const callApi = async () => {
      try {
        const fields = await customFieldData.getProductFields();
        setAllFields(fields);
        if (lastAddedValue !== '') {
          const index = fields.findIndex(({ fieldName }) => fieldName === lastAddedValue);
          const scrollHeight = HEADER_HEIGHT + index * ROW_HEIGHT;
          if (
            scrollHeight > window.innerHeight &&
            (scrollHeight < window.scrollY - window.innerHeight ||
              scrollHeight > window.scrollY + window.innerHeight) &&
            lastAddedValue !== ''
          ) {
            window.scrollTo(0, scrollHeight);
          }
        }
      } catch (e) {
        Sentry.captureException(e);
        toggleErrorDlg(t('failed_to_get_fields'), e.message);
      }
      setLoadingFields(false);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsLength = useMemo(() => allFields.length, [allFields]);

  const addField = useCallback(
    (data) => {
      setUpdatingFields(true);
      const callApi = async () => {
        try {
          await customFieldData.addProductField(data);
          setToastState({
            open: true,
            message: t('field_saved_successfully'),
          });
          setUpdatingFields(false);
        } catch (e) {
          setUpdatingFields(false);
          Sentry.captureException(e);
          toggleErrorDlg(t('failed_to_add_field'), e);
        }
        getFields();
      };
      callApi();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFields]
  );

  const deleteField = async (id) => {
    try {
      await customFieldData.deleteProductField({ fieldIds: [id] });
    } catch (e) {
      Sentry.captureException(e);
      toggleErrorDlg(t('failed_to_delete_field'), e);
    }
    getFields();
  };

  const updateField = useCallback(
    (data) => {
      setUpdatingFields(true);
      const callApi = async () => {
        try {
          await customFieldData.updateProductField(data);
          setToastState({
            open: true,
            message: t('field_saved_successfully'),
          });
          setUpdatingFields(false);
        } catch (e) {
          setUpdatingFields(false);
          console.error(e);
          Sentry.captureException(e);
          toggleErrorDlg(t('failed_to_update_field'), e);
        }
        getFields();
      };
      callApi();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFields]
  );

  useEffect(() => {
    setLoadingFields(true);
    getFields();
  }, [getFields]);

  useEffect(() => {
    if (hash === '#active') {
      setShowAddDlg(true);
    } else {
      setShowAddDlg(false);
    }
  }, [hash]);

  const toggleAddDialog = useCallback(() => {
    if (hash === '#active') {
      setDialogData();
      history.goBack();
    } else {
      history.push(`${search}#active`);
    }
  }, [hash, history, search]);

  const onAddField = () => {
    setEditIndex(-1);
    toggleAddDialog();
  };

  const handleFieldText = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setDlgText(value);
  }, []);

  const handleSelect = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setDlgType(value);
  }, []);

  const setDialogData = (data = {}) => {
    const {
      fieldName = '',
      fieldType = '',
      visibility = '',
      textErr = false,
      typeErr = false,
      btnErr = false,
    } = data;
    setDlgText(fieldName);
    setDlgType(fieldType);
    setBtnSelected(visibility);
    setTxtErr(textErr);
    setTypeErr(typeErr);
    setBtnErr(btnErr);
  };

  const onDlgAddField = useCallback(() => {
    let isError = false;
    if (dlgType.length === 0) {
      setTypeErr(true);
      isError = true;
    }
    if (dlgText.length === 0) {
      setTxtErr(true);
      isError = true;
    }
    if (btnSelected.length === 0) {
      setBtnErr(true);
      isError = true;
    }
    if (!isError) {
      const newField = {
        fieldName: dlgText.trim(),
        fieldType: dlgType,
        visibility: btnSelected.toUpperCase(),
        // add new fields
        order: fieldsLength,
      };
      lastAddedValue = dlgText;
      if (editIndex > -1) {
        const { id } = allFields[editIndex];
        if (id) {
          const updatedField = { fieldId: id, updates: { ...newField } };
          updateField(updatedField);
        }
      } else {
        addField(newField);
        setEditIndex(-1);
      }
      setDialogData();
      toggleAddDialog();
    }
  }, [
    dlgType,
    dlgText,
    btnSelected,
    allFields,
    fieldsLength,
    editIndex,
    addField,
    updateField,
    toggleAddDialog,
  ]);

  const handleBtnClick = useCallback((btn) => {
    setBtnErr(false);
    setBtnSelected(btn);
  }, []);

  const onListEdit = (index) => {
    const data = index < allFields.length ? allFields[index] : {};
    setEditIndex(index);
    setDialogData(data);
    toggleAddDialog();
  };

  const onListReorder = useCallback(
    ({ oldIndex, newIndex }) => {
      if (
        oldIndex === newIndex ||
        typeof newIndex === 'undefined' ||
        typeof oldIndex === 'undefined'
      )
        return;

      const oldList = [...allFields];
      const newList = arrayMove(allFields, oldIndex, newIndex);

      setReorderingItem(newList[newIndex].id);

      setAllFields(newList);
      const callApi = async () => {
        try {
          await customFieldData.reorderProductFields({
            productFieldIds: newList.map(({ id }) => id),
          });
          setReorderingItem();
          setToastState({
            open: true,
            message: 'Fields have been re-ordered successfully',
          });
        } catch (e) {
          setAllFields(oldList);
          console.error(e);
          setReorderingItem();
          Sentry.captureException(e);
          toggleErrorDlg('Failed to re-order fields', e);
        }
        getFields();
      };
      callApi();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFields]
  );

  const onListDelete = (id) => {
    deleteField(id);
  };

  const isReorderingItem = !!reorderingItem;

  const addFieldButton = (
    <div className={`${classes.boxContainer}`}>
      <HelpButton postName={t('what_are_custom_fields')} buttonId="custom_fields" />
      <Button
        variant="contained"
        disabled={isReorderingItem}
        onClick={onAddField}
        className={`${classes.navBtn} btn-top ${!desktop ? 'd-none' : ''}`}
      >
        {t('add_field')}
      </Button>
    </div>
  );

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: t('custom_fields'),
        height: NAVBAR_HEIGHT,
        hideBack: desktop,
        actions: addFieldButton,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`
      }
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: ''
        }
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, desktop, isReorderingItem]);

  const toggleErrorDlg = useCallback(
    (type, error) => {
      if (Object.keys(showErrorDlg).length > 0) {
        setShowErrorDlg({});
      } else {
        setShowErrorDlg({
          type,
          error,
        });
      }
    },
    [showErrorDlg]
  );

  const onContactUs = () => {
    Utility.openPopup(
      `mailto:support@quicksell.co?subject=${showErrorDlg.type}&body=${showErrorDlg.error}.`
    );
    toggleErrorDlg();
  };

  return (
    <Box className={`${classes.customFields} ${allFields.length === 0 && 'customfields-mainbox'}`}>
      <div
        className={`${classes.titleContainer} ${
          allFields.length === 0 ? classes.noFieldsTitle : ''
        }`}
      >
        <div className={classes.titleInfo}>
          {/* <Typography className={classes.title} color="primary" gutterBottom>
            Custom fields
          </Typography> */}
          <p
            className={`main-info ${classes.info} ${allFields.length === 0 && 'title-info'} ${
              loadingFields && 'loading-fields'
            }`}
          >
            {t('create_custom_fields_that_you_can_specify_for_each_product')}
          </p>
        </div>
      </div>
      {loadingFields ? (
        <Box className="custom-field loader loaderContainer">
          <Loader />
        </Box>
      ) : (
        <Box>
          <FieldsList
            lockAxis="y"
            useDragHandle
            fields={allFields}
            onSortEnd={onListReorder}
            onEdit={onListEdit}
            axis="y"
            reorderingItem={reorderingItem}
            lockToContainerEdges
            distance={desktop ? 1 : undefined}
            pressDelay={desktop ? undefined : 200}
            onDelete={onListDelete}
            helperClass={classes.draggingItem}
          />
        </Box>
      )}
      <Box className="button-box">
        {allFields.length === 0 ? (
          <Button
            onClick={onAddField}
            className={`no-fields-box ${classes.newFieldBtn} ${desktop ? 'd-none' : ''} btn-bottom`}
            style={{ width: 'auto', minWidth: 'auto' }}
          >
            <EditSvg fill="#FFF" width="16" height="16" className="edit-img" />
            <Typography className="no-fields">{t('create_field')}</Typography>
          </Button>
        ) : (
          <IconButton
            className={`${classes.newFieldBtn} ${desktop ? 'd-none' : ''} ${
              classes.plusSvg
            } btn-bottom`}
            onClick={onAddField}
          >
            +
          </IconButton>
        )}
      </Box>
      <CustomFieldDialogBox
        show={showAddDlg || updatingFields}
        width={313}
        height={432}
        dark
        primaryBtnText={editIndex === -1 ? t('add_field') : t('save_field')}
        title={t('add_new_field')}
        onClose={toggleAddDialog}
        onSuccess={onDlgAddField}
        disableBackdropClick
        updatingContent={updatingFields}
      >
        <Fragment>
          <TextField
            className={classes.newField}
            error={textErr}
            onChange={handleFieldText}
            value={dlgText}
            label={t('field_name')}
            fullWidth
            autoFocus
          />
          <FormControl variant="outlined" className={classes.formControl} error={typeErr}>
            <InputLabel id="demo-simple-select-outlined-label">
              {t('field_type')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={dlgType}
              onChange={handleSelect}
              label={t('field_type')}
              disabled={editIndex !== -1}
            >
              {FIELD_TYPES.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography className={classes.txtVisibility}>
            {t('field_visibility')}
          </Typography>
          <Typography className={classes.txtControl}>
            {t('control_whether_this_field_will_be_publically_visible_to_customers')}
          </Typography>
          {DLG_BUTTONS.map((btn) => (
            <Button
              key={btn}
              className={`${classes.dlgButton} ${
                btnSelected.toLowerCase() === btn.toLowerCase() ? classes.selected : ''
              }`}
              variant={btnSelected.toLowerCase() === btn.toLowerCase() ? 'contained' : 'outlined'}
              onClick={() => handleBtnClick(btn)}
              error={btnErr}
            >
              {t(btn)}
            </Button>
          ))}
          {btnErr && (
            <Typography className={classes.btnError}>
              {t('please_select_a_visibility_setting')}
            </Typography>
          )}
        </Fragment>
      </CustomFieldDialogBox>
      <CustomFieldDialogBox
        show={Object.keys(showErrorDlg).length > 0}
        dark
        title={showErrorDlg.type}
        primaryBtnText={t('contact_us')}
        disableBackdropClick
        onSuccess={onContactUs}
        onClose={toggleErrorDlg}
      >
        <p className="error-info main">{`${showErrorDlg.error}`}</p>
        <p className="error-info">{t('please_contact_support_for_further_assistance')}</p>
      </CustomFieldDialogBox>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </Box>
  );
}
