import React, { useState, useEffect } from 'react';
import { useParams, Switch, Route } from 'react-router-dom';
import payments from '../../data/payments';
import Rzp from './rzp/Rzp';
import MercadoPago from './mercadoPago';
import Loader from '../../common/Loader';
import { useAppContext } from '../../common/Contexts/AppContext';
import Paytm from './Paytm';
import PhonePe from './PhonePe';
import Stripe from './Stripe';
import PayPal from './paypal/PayPal';
import './style.css';
import useSearchParamsQuery from '../../common/Hooks/useSearchParamsQuery';
import PartialCOD from './PartialCOD/partialCOD';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { getI18N } from '../../i18N';

const NAVBAR_HEIGHT = 55;

export default function PaymentDetail() {
  const { id } = useParams();
  const [gatewayData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentModes, setPaymentModes] = useState([]);
  const source = useSearchParamsQuery().get('source');

  const { t } = getI18N();

  useEffect(() => {
    payments.getPaymentMethods(true).then((response) => {
      setPaymentModes((paymentModes) => {
        return paymentModes.concat(response);
      });
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    payments.getPaymentMethodForId(id).then((data) => {
      setPaymentData(data);
      setLoading(false);
    });
  }, [id]);

  const [, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: source === 'desktop' ? '#0f141a' : '#4DA47A',
        color: '#FFFFFF',
        title: gatewayData.title ? t(gatewayData.title) : null,
        hideBack: false,
        height: NAVBAR_HEIGHT,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#3f4653');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, gatewayData]);

  return (
    <div className="paymentDetailContainer">
      <div className="paymentDetailContent">
        <Switch>
          <Route path="/payments/RAZORPAY">
            {loading ? (
              <div className="loaderContainer">
                <Loader />
              </div>
            ) : (
              <Rzp id={id} gatewayData={gatewayData} setPaymentData={setPaymentData} />
            )}
          </Route>
          <Route path="/payments/PAYTM">
            {loading ? (
              <div className="loaderContainer">
                <Loader />
              </div>
            ) : (
              <Paytm gatewayData={gatewayData} />
            )}
          </Route>
          <Route path="/payments/PAYPAL">
            {loading ? (
              <div className="loaderContainer">
                <Loader />
              </div>
            ) : (
              <PayPal gatewayData={gatewayData} />
            )}
          </Route>
          <Route path="/payments/MERCADO_PAGO">
            {loading ? (
              <div className="loaderContainer">
                <Loader />
              </div>
            ) : (
              <MercadoPago gatewayData={gatewayData} />
            )}
          </Route>
          <Route path="/payments/PARTIAL_COD">
            {loading ? (
              <div className="loaderContainer">
                <Loader />
              </div>
            ) : (
              <PartialCOD gatewayData={gatewayData} paymentModes={paymentModes} />
            )}
          </Route>
          <Route path="/payments/PHONEPE">
            {loading ? (
              <div className="loaderContainer">
                {' '}
                <Loader />
              </div>
            ) : (
              <PhonePe gatewayData={gatewayData} />
            )}
          </Route>
          <Route path="/payments/STRIPE">
            {loading ? (
              <div className="loaderContainer">
                {' '}
                <Loader />
              </div>
            ) : (
              <Stripe gatewayData={gatewayData} />
            )}
          </Route>
        </Switch>
      </div>
    </div>
  );
}
