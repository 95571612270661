export const addTime = [
  { time: '15m', timeInSeconds: 900 },
  { time: '30m', timeInSeconds: 1800 },
  { time: '1hr', timeInSeconds: 3600 },
];

export const setTime = [
  { time: '30m', timeInSeconds: 1800 },
  { time: '1hr', timeInSeconds: 3600 },
  { time: '3hr', timeInSeconds: 10800 },
];
export let defaultTime = new Date('2001-01-01T00:00:00');
