import React from 'react';

export default ({ width = 63, height = 81 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 63 81"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons8-emerald</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.99">
      <g
        id="Android-Copy-5"
        transform="translate(-285.000000, -1037.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group-2" transform="translate(23.000000, 1037.000000)">
          <g id="icons8-emerald">
            <g transform="translate(262.000000, 0.000000)" id="Shape">
              <path
                d="M14.4457047,0 L0,14.2869591 L0,66.7130393 L0.92285172,67.6257504 L14.4457047,81 L48.5542953,81 L63,66.7130393 L63,14.2869591 L48.5542953,0 L14.4457047,0 Z M20.8441422,6.23076923 L42.1558578,6.23076923 L39.0058578,12.4615385 L23.9941422,12.4615385 L20.8441422,6.23076923 Z M14.8886703,8.37259615 L18.2170894,14.9623649 L15.1286121,18.016902 L8.465625,14.7250601 L14.8886703,8.37259615 Z M48.1113297,8.37259615 L54.534375,14.7250601 L47.8713879,18.016902 L44.7829105,14.9623649 L48.1113297,8.37259615 Z M23.3542953,18.6923077 L39.6457047,18.6923077 L44.1,23.0976547 L44.1,57.9023453 L39.6457047,62.3076923 L24.5909192,62.3076923 L18.9,56.8375162 L18.9,23.0976547 L23.3542953,18.6923077 Z M6.3,20.6150841 L12.6,23.7304703 L12.6,57.2695297 L6.3,60.3849143 L6.3,20.6150841 Z M56.7,20.6150841 L56.7,60.3849143 L50.4,57.2695297 L50.4,23.7304703 L56.7,20.6150841 Z M15.8668933,62.6180127 L18.6108395,65.2527028 L14.8886703,72.6274038 L8.465625,66.2749415 L15.8668933,62.6180127 Z M47.8713879,62.9830983 L54.534375,66.2749415 L48.1113297,72.6274038 L44.7829105,66.0376364 L47.8713879,62.9830983 Z M23.9941422,68.5384615 L39.0058578,68.5384615 L42.1558578,74.7692308 L20.8441422,74.7692308 L23.9941422,68.5384615 Z"
                style={{ mixBlendMode: 'soft-light' }}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
