import { makeStyles } from '@material-ui/core/styles';

const PAGE_PADDING = 18;
const PREVIEW_SIZE = 360;
const BREAK_POINT = 480;

export const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#252B36',
    margin: '0px',
    padding: '0px',
    color: '#FFFFFF',
    boxShadow: 'none',
    outline: 'none',
    overflow: 'initial',
    borderRadius: '0px'
  },
  customWebsiteFontsPageLoader: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  customWebsiteFontsPageContainer: {
    backgroundColor: '#242C36',
    height: '100%',
    width: `calc(100% - 2 * ${PAGE_PADDING}px)`,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: `${PAGE_PADDING}px`,
    color: '#FFFFFF',
    [`@media screen and (max-width: ${BREAK_POINT}px)`]: {
      flexDirection: 'column',
      overflow: 'auto'
    }
  },
  customWebsiteFontsListContainer: {
    height: '100%',
    width: `calc(100% - ${PREVIEW_SIZE}px - 2 * ${PAGE_PADDING}px - 1px)`,
    paddingRight: `${PAGE_PADDING}px`,
    [`@media screen and (max-width: ${BREAK_POINT}px)`]: {
      height: 'auto',
      width: '100%',
      paddingRight: '0px'
    }
  },
  customWebsiteFontsDividerContainer: {
    height: `calc(100% - 2 * ${PAGE_PADDING}px - 2px)`,
    minHeight: `calc(100% - 2 * ${PAGE_PADDING}px - 2px)`,
    maxHeight: `calc(100% - 2 * ${PAGE_PADDING}px - 2px)`,
    width: '1px',
    minWidth: '1px',
    maxWidth: '1px',
    backgroundColor: '#FFFFFF',
    opacity: '0.25',
    marginTop: `calc(2 * ${PAGE_PADDING}px)`,
    marginBottom: '0px',
    [`@media screen and (max-width: ${BREAK_POINT}px)`]: {
      height: '1px',
      minHeight: '1px',
      maxHeight: '1px',
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      marginTop: `${PAGE_PADDING}px`,
      marginBottom: `${PAGE_PADDING}px`
    }
  },
  customWebsiteFontsPreviewContainer: {
    height: '100%',
    minHeight: '100%',
    width: `calc(${PREVIEW_SIZE}px + 2 * ${PAGE_PADDING}px)`,
    paddingLeft: `${PAGE_PADDING}px`,
    [`@media screen and (max-width: ${BREAK_POINT}px)`]: {
      width: '100%',
      paddingLeft: '0px'
    }
  },
  customWebsiteFontsPreviewHeader: {
    fontSize: '16px',
    fontWeight: '500',
    height: `calc(2 * ${PAGE_PADDING}px)`,
    width: '100%'
  },
  customWebsiteFontsPreviewRenderer: {
    height: `calc(100% - 2 * ${PAGE_PADDING}px)`,
    width: '100%',
    backgroundColor: '#151C27',
    borderRadius: '5px'
  },
  customWebsiteFontsPreviewIframe: {
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    height: '100%',
    width: '100%',
    borderRadius: '5px'
  },
  customWebsiteFontsIframeLoader: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  customWebsiteFontsFormControl: {
    width: '100%',
    '&:not(:first-child)': {
      marginTop: '24px'
    }
  },
  customWebsiteFontsSelectLabel: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    '& p': {
      fontSize: '16px',
      fontWeight: '500',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '0px',
      padding: '0px'
    }
  },
  customWebsiteFontsFormSelect: {
    width: '100%',
    '& .MuiSelect-root': {
      padding: '12px 16px',
      backgroundColor: '#1A222D',
      borderRadius: '5px'
    },
    '& .MuiSelect-icon': {
      right: '8px',
      height: '24px',
      width: '24px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  customWebsiteFontsInfoPopoverLabel: {
    borderRadius: '50%',
    border: '1px solid #FFFFFF',
    height: '14px',
    minHeight: '14px',
    width: '14px',
    minWidth: '14px',
    cursor: 'pointer',
    backgroundColor: '#1A222D',
    textAlign: 'center',
    margin: '0px',
    padding: '0px',
    fontSize: '10px !important',
    lineHeight: '15px'
  },
  customWebsiteFontsInfoPopoverContent: {
    padding: '12px 16px',
    fontSize: '16px',
    backgroundColor: '#1A222D',
    maxWidth: '200px'
  },
  customWebsiteFontsFormPopoverLabel: {
    width: 'calc(100% - 32px)',
    maxWidth: 'calc(100% - 32px)',
    marginTop: `${PAGE_PADDING}px`,
    padding: '12px 16px',
    backgroundColor: '#1A222D',
    outline: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '0px',
      padding: '0px'
    }
  },
  customWebsiteFontsPopoverContainer: {
    top: `${PAGE_PADDING}px !important`,
    left: `${PAGE_PADDING}px !important`,
    height: `calc(100% - 2 * ${PAGE_PADDING}px) !important`,
    maxHeight: '100% !important',
    width: `calc(100% - (${PREVIEW_SIZE}px + 2 * ${PAGE_PADDING}px) - 2 * ${PAGE_PADDING}px + 2px) !important`,
    maxWidth: '100% !important',
    [`@media screen and (max-width: ${BREAK_POINT}px)`]: {
      width: `calc(100% - 2 * ${PAGE_PADDING}px) !important`
    }
  },
  customWebsiteFontsPopoverPaper: {
    top: '0px !important',
    left: '0px !important',
    height: '100% !important',
    maxHeight: '100% !important',
    width: '100% !important',
    maxWidth: '100% !important',
    padding: '0px !important',
    transformOrigin: '0px 0px !important',
    backgroundColor: '#19202B'
  },
  customWebsiteFontsFormPopoverContent: {
    height: '100%',
    width: '100%'
  },
  customWebsiteFontsFormPopoverHeading: {
    fontSize: '16px',
    fontWeight: '500',
    height: '54px',
    width: `calc(100% - 2 * ${PAGE_PADDING}px)`,
    maxWidth: `calc(100% - 2 * ${PAGE_PADDING}px)`,
    padding: `0px ${PAGE_PADDING}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '0px',
      padding: '0px'
    }
  },
  customWebsiteFontsFormPopoverSearchContainer: {
    height: '42px',
    width: `calc(100% - 2 * ${PAGE_PADDING}px)`,
    padding: `0px ${PAGE_PADDING}px`
  },
  customWebsiteFontsFormPopoverSearch: {
    fontSize: '16px',
    height: '42px',
    color: '#FFFFFF',
    width: '100%',
    borderRadius: '5px',
    backgroundColor: '#10151D',
    '& .MuiInput-root': {
      height: '42px'
    },
    '& .MuiInput-root input': {
      padding: '12px 16px',
      height: `${PAGE_PADDING}px`,
      borderRadius: '5px'
    }
  },
  customWebsiteFontsFormPopoverVirtualList: {
    height: `calc(100% - 54px - 42px - ${PAGE_PADDING}px) !important`,
    marginTop: `${PAGE_PADDING}px !important`
  },
  customWebsiteFontsFormPopoverVirtualListItem: {
    display: 'flex',
    alignItems: 'center',
    width: `calc(100% - 2 * ${PAGE_PADDING}px)`,
    maxWidth: `calc(100% - 2 * ${PAGE_PADDING}px)`,
    padding: `0px ${PAGE_PADDING}px`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#12161E'
    },
    '&:first-child': {
      borderTop: '1px solid #2C333F'
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #2C333F'
    },
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '0px',
      padding: '0px'
    }
  },
  customWebsiteFontsFormPopoverVirtualListItemActive: {
    backgroundColor: '#12161E'
  }
});