import AndroidImpl from './android';
import IOSImpl from './ios';
import Web from './web';

let platform;

if (AndroidImpl.isAndroid()) {
  platform = new AndroidImpl();
} else if (IOSImpl.isIOS()) {
  platform = new IOSImpl();
} else {
  platform = new Web();
}

const goBack = (...params) => {
  platform.goBack(...params);
};

const postMessage = (...params) => {
  return platform.postMessage(...params);
};

const postProductType = (productType) => {
  return platform.postProductType(productType);
};

const seatReservedNative = (webinarName, startTime) => {
  platform.seatReserved(webinarName, startTime);
};

const setNavigationBarColor = (color) => {
  platform.setNavigationBarColor(color);
};

const initiateGoogleLogin = () => {
  platform.initiateGoogleLogin();
};

const setStatusBarColor = (color) => {
  platform.setStatusBarColor(color);
};

const shareReferral = (content) => {
  platform.shareReferral(content);
};

const trackAnalytics = (content) => {
  platform.trackAnalytics(content);
};

const showPremiumFeatureDialog = (feature) => {
  platform.showPremiumFeatureDialog(feature);
};

const isFeatureAllowed = (feature) => {
  return platform.isFeatureAllowed(feature);
};

const shareImage = (url) => {
  platform.shareImage(url);
};
const fetchContactList = () => {
  return platform.fetchContactList();
};

const getIdToken = (callbackFunctionName) => {
  return platform.getIdToken(callbackFunctionName);
};

const hasPermission = (permissionName) => {
  return platform.hasPermission(permissionName);
};
const getSavedLoginCredentials = () => {
  return platform.getSavedLoginCredentials();
};
const deleteLoginCredentials = (loginCredentials) => {
  return platform.deleteLoginCredentials(loginCredentials);
};
const requestPermission = (permissionName) => {
  return platform.requestPermission(permissionName);
};

const PLATFORMS = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
  WEB: 'WEB',
};

const getPlatform = () => {
  if (AndroidImpl.isAndroid()) {
    return PLATFORMS.ANDROID;
  } else if (IOSImpl.isIOS()) {
    return PLATFORMS.IOS;
  } else {
    return PLATFORMS.WEB;
  }
};

export {
  goBack,
  postMessage,
  initiateGoogleLogin,
  seatReservedNative,
  setNavigationBarColor,
  setStatusBarColor,
  shareReferral,
  trackAnalytics,
  isFeatureAllowed,
  showPremiumFeatureDialog,
  shareImage,
  getPlatform,
  PLATFORMS,
  fetchContactList,
  getIdToken,
  hasPermission,
  requestPermission,
  postProductType,
  getSavedLoginCredentials,
  deleteLoginCredentials,
};
