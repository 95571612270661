import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

let apis = {
  getMetalRateEndPoint: '/v1/product-type-rate',
  setMetalRateEndPoint: '/v1/product-type-rate'
};

const getMetalRates = (param, activeMetalType) => {
  return ApiWrapper.getDataPromise(
    apis.getMetalRateEndPoint,
    'rates',
    `${CacheKeys.metalRates}_${activeMetalType}`,
    param
  );
};

const setMetalRates = (data, activeMetalType) => {
  return ApiWrapper.postDataPromise(
    apis.setMetalRateEndPoint,
    data,
    `${CacheKeys.metalRates}_${activeMetalType}`
  );
};

export default {
  getMetalRates,
  setMetalRates
};
