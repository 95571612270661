import { 
  CHECKOUT_FORM_STATES, 
  CHECKOUT_FORM_LABELS,
  FORM_FIELD_TYPES,
  CHECKOUT_FORM_FIELD_TYPES,
  AVAILABLE_DATE_FORMATS,
} from './constants';
import { getI18N } from '../../i18N';

let selectedFormField = { fieldType: FORM_FIELD_TYPES.TEXT };

export const getCheckoutFormFieldsDataCacheKey = () => 'GET_CHECKOUT_FORMS_FIELDS_DATA'

export const registerGetSelectedFormField = (formField) => {
  selectedFormField = formField;
}

export const getCheckoutFormFieldTypeByType = (type) =>  CHECKOUT_FORM_FIELD_TYPES
  .find(({ fieldType }) => fieldType === type) || {};

export const getLabelOfSelectedFormField = () => {
  const { fieldType } = selectedFormField || {};
  if (!fieldType) {
    return '';
  }
  return getCheckoutFormFieldTypeByType(fieldType).label;
};

export const getCheckoutFormTitleDetails = (state) => {
  const { t } = getI18N();
  if (state === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
    return {
      label: t(CHECKOUT_FORM_LABELS.SELECT_FIELD_TYPE_TITLE),
      tagline: t(CHECKOUT_FORM_LABELS.SELECT_FIELD_TYPE_TAGLINE)
    };
  }
  if (state === CHECKOUT_FORM_STATES.ENTER_NAME) {
    const label = getLabelOfSelectedFormField();
    return { label: t(label), tagline: t(CHECKOUT_FORM_LABELS.ENTER_NAME_OF_FIELD_TAGLINE) };
  }
  return {};
};

export const getNextFormState = (currentState) => {
  if (currentState !== undefined) {
    return currentState;
  }
  if (currentState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
    return CHECKOUT_FORM_STATES.ENTER_NAME;
  }
  if (currentState === CHECKOUT_FORM_STATES.ENTER_NAME) {
    return CHECKOUT_FORM_STATES.SUBMIT;
  }
};

// Checks for validity of new field, checks for all required fields and values
export const checkIfNewFieldIsValid = (formField, { fieldLabelErrorCallback, fieldOptionsErrorCallback } = {}) => {
  const { fieldName, fieldType, fieldOptions = {} } = formField;
  let isValid = true;
  if (!fieldName) {
    if (typeof fieldLabelErrorCallback === 'function') {
      fieldLabelErrorCallback(true);
    }
    isValid = false;
  }
  if (
    (fieldType === FORM_FIELD_TYPES.SINGLE_CHOICE || fieldType === FORM_FIELD_TYPES.MULTI_CHOICE) &&
    (!fieldOptions || !Array.isArray(fieldOptions) || fieldOptions.length === 0 || fieldOptions.some(option => !option))
  ) {
    if (typeof fieldOptionsErrorCallback === 'function') {
      const fieldOptionsErrorIndex = (fieldOptions || []).findIndex(option => !option);
      fieldOptionsErrorCallback(fieldOptionsErrorIndex);
    }
    isValid = false;
  }
  return isValid;
};

export const checkIfOptionsAreRequiredFormField = (fieldType) =>
  fieldType === FORM_FIELD_TYPES.SINGLE_CHOICE || fieldType === FORM_FIELD_TYPES.MULTI_CHOICE;

export const getOptionIfRequiredForFormField = fieldType => {
  if (checkIfOptionsAreRequiredFormField(fieldType)) {
    return [''];
  }
  if (fieldType === FORM_FIELD_TYPES.DATE) {
    return [];
  }
  return null;
}

export const getAvailableOptionsToSelect = (fieldType) => {
  const { t } = getI18N();

  switch (fieldType) {
    case FORM_FIELD_TYPES.DATE:
      return { label: t(CHECKOUT_FORM_LABELS.DATE_LABEL), options: AVAILABLE_DATE_FORMATS };
    default:
      return null;
  }
}

export const dataExists = data => Array.isArray(data) && data.length > 0;

// Add a new form field
export const addNewField = (data, field, insertedId) => {
  if (!field) {
    return data;
  }
  if (insertedId) {
    // Updating the new id received from server before this using a dummy id
    field.id = insertedId;
  }
  const copiedData = [...data];
  const fieldIndex = copiedData.findIndex(({ id }) => id === field.id);
  if (fieldIndex === -1) {
    // Assuming all global non-orderable fields are before that.
    const indexOfFirstOrderableField = copiedData.findIndex(({ canReorder }) => canReorder);
    if (indexOfFirstOrderableField === -1) {
      // Insert it as at bottom of non-orderable fields
      copiedData.push(field);
    } else {
      copiedData.splice(indexOfFirstOrderableField + 1, 0, field);
    }
  } else {
    copiedData[fieldIndex] = field;
  }
  return copiedData;
};

// Removes a form field from the list
export const removeFormField = (data, fieldId) =>
  data.filter(({ id }) => id !== fieldId);

export const toggleVisibility = (data, fieldId) => {
  const index = data.findIndex(({ id }) => id === fieldId);
  if (index === -1) {
    return data;
  }
  const { visibility, canToggleVisibility } = data[index];
  if (!canToggleVisibility) {
    return data;
  }
  const copiedData = [...data];
  copiedData[index] = {
    ...copiedData[index],
    visibility: !visibility
  }
  return copiedData;
};

const invalidIndex = (index, length) => index === undefined || index < 0 || index >= length;

export const moveArray = (data, { oldIndex, newIndex }) => {
  if (Array.isArray(data) &&
    !invalidIndex(oldIndex, data.length) &&
    !invalidIndex(newIndex, data.length)
  ) {
    data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]);
  }

  return data;  
};
