export default {
  COMPANY_PRIVACY_SETTINGS: 'COMPANY_PRIVACY_SETTINGS',
  CATALOGUE_PRIVACY_SETTINGS: 'CATALOGUE_PRIVACY_SETTINGS',
  GLOBAL_GROUP_ALLOWED: 'GLOBAL_GROUP_ALLOWED',
  GLOBAL_GROUP_BLOCKED: 'GLOBAL_GROUP_BLOCKED',
  GLOBAL_GROUP_CUSTOMER: 'GLOBAL_GROUP_CUSTOMER',
  COMPANY_GROUP_MEMBERS: 'COMPANY_GROUP_MEMBERS',
  CATALOGUE_GROUP_MEMBERS: 'CATALOGUE_GROUP_MEMBERS',
  CATALOGUE_ADDITIONAL_GROUPS: 'CATALOGUE_ADDITIONAL_GROUPS',
  COMPANY_ADDITIONAL_GROUPS: 'COMPANY_ADDITIONAL_GROUPS',
  SELECTED_COMPANY_GROUP: 'SELECTED_COMPANY_GROUP',
  SELETCED_CATALOGUE_GROUP: 'SELETCED_CATALOGUE_GROUP',
};
