import axios from 'axios';

import { getToken } from './util';
import { handleError } from '../common/error';

import config from '../config';
import { getIdToken } from '../os';
import { MAX_TOKEN_REFRESH_RETRY, MAX_HTTP_REQUEST_RETRY } from './globalConstants';

const HTTP_ERROR = {
  error403: {
    status: 403,
    reason: 'EXPIRED_ID_TOKEN',
    message: 'Forbidden',
  },
  error401: {
    status: 401,
    reason: 'WRONG_ID_TOKEN',
    message: 'Unauthorized',
  },
  error500: {
    status: 500,
    reason: 'INTERNAL_SERVER_ERROR',
    message: `Firebase ID token has "kid" claim which does not correspond to a known public key. Most likely the ID token is expired, so get a fresh token from your client app and try again.`,
  },
};

let failedRequestsIdMap = {};

const instance = axios.create();

const getRefreshedToken = async () => {
  const oldToken = getToken();
  await getIdToken('getIdTokenCallback');
  let count = 0;
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      count++;
      const token = getToken();
      if (oldToken !== token) {
        count = 0;
        clearInterval(intervalId);
        resolve(token);
      }
      if (count === MAX_TOKEN_REFRESH_RETRY) {
        count = 0;
        clearInterval(intervalId);
        reject();
      }
    }, 100);
  });
};

const isExpiredTokenError = (errorResponse, httpError) => {
  return (
    errorResponse.status === httpError.status &&
    errorResponse.data.reason === httpError.reason &&
    errorResponse.data.message === httpError.message
  );
};

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return {
      ...config,
      id: config.id || Math.random(), // adding an id to prevent infinite api calls if platform gives a different token but that token might be expired or backend keeps giving 403/401 error even with the new token
      headers: {
        ...(config.headers || {}),
        Authorization: getToken(),
        'content-type': 'application/json',
      },
    };
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error &&
      error.config &&
      error.response &&
      (isExpiredTokenError(error.response, HTTP_ERROR.error403) ||
        isExpiredTokenError(error.response, HTTP_ERROR.error401))
    ) {
      if (failedRequestsIdMap[originalRequest.id] === MAX_HTTP_REQUEST_RETRY) {
        return Promise.reject(error);
      }

      failedRequestsIdMap[originalRequest.id] = failedRequestsIdMap[originalRequest.id]
        ? failedRequestsIdMap[originalRequest.id] + 1
        : 1;
      try {
        const newToken = await getRefreshedToken();
        originalRequest.headers['Authorization'] = newToken;
        return instance.request(originalRequest);
      } catch (error) {
        Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const network = {};

network.getPrivacySettings = () =>
  instance
    .get(`${config.apiHost}/v1/entity-filter/global-privacy-settings`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getGlobalGroups = (listType) =>
  instance
    .get(`${config.apiHost}/v1/contact-group/global?listType=${listType}`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.deleteGroups = (groupIds) =>
  instance
    .delete(`${config.apiHost}/v1/contact-group`, { data: groupIds })
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.setAccessLevel = (accessLevel) =>
  instance
    .post(`${config.apiHost}/v1/entity-filter/access-level`, accessLevel)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getGroupMembers = (groupId) =>
  instance
    .get(`${config.apiHost}/v1/contact-group/members?groupId=${groupId}`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getAdditionalGroups = () =>
  instance
    .get(`${config.apiHost}/v1/contact-group/global/add-list`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.deleteGroupMember = (memberIds) =>
  instance
    .delete(`${config.apiHost}/v1/contact-group/members`, { data: memberIds })
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.addToPrivacyList = (body) =>
  instance
    .post(`${config.apiHost}/v1/entity-filter`, body)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.removeFromPrivacyList = (body) =>
  instance
    .delete(`${config.apiHost}/v1/entity-filter`, { data: body })
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getCataloguePrivacySettingsGroups = (catalogueId) =>
  instance
    .get(`${config.apiHost}/v1/contact-group/catalogue?catalogueId=${catalogueId}`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getAdditionalGroupsForCataloguePrivacySettings = (catalogueId) =>
  instance
    .get(`${config.apiHost}/v1/contact-group/catalogue/add-list?catalogueId=${catalogueId}`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.toggleSingleDefaultGlobal = (body) =>
  instance
    .post(`${config.apiHost}/v1/contact-group/default-group/toggle`, body)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });
network.toggleGlobalDefaultGlobal = (body) =>
  instance
    .post(`${config.apiHost}/v1/contact-group/default-group/toggle-global`, body)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getSmsSettingsData = () =>
  instance
    .get(`${config.apiHost}/v1/sms/wallet/details`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.downloadUsageReport = (data) =>
  instance
    .post(`${config.apiHost}/v1/sms/wallet/report`, data)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getCatalogueLink = () =>
  instance
    .get(`${config.apiHost}/v1/showcase/catalogue-link`)
    .then((response) => response.data.link)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getReferralStats = () =>
  instance
    .get(`${config.apiHost}/v1/referral/stats`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getYourReferralsDetails = (filterOption) =>
  instance
    .get(`${config.apiHost}/v1/referral/company?status=${filterOption}`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.claimYourReward = ({ rewardStatus }) =>
  instance
    .post(`${config.apiHost}/v1/referral/claim-reward`, { ...rewardStatus })
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getYourRewards = () =>
  instance
    .get(`${config.apiHost}/v1/referral/all-rewards`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.sendInvites = async (selectedContactsList) => {
  return instance
    .post(`${config.apiHost}/v1/referral/whatsapp-invite`, { ...selectedContactsList })
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });
};
network.getLoginDetails = async (link) =>
  instance
    .get(`${config.apiHost}/v1/auth/company/login-details?link=${link}`)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

network.getDomain = () =>
  instance.get(`${config.apiHost}/v1/domain`).then((response) => response.data.data);

network.getAutomaticCourierSelection = () => {
  return instance
    .get(`${config.apiHost}/v1/company/settings/SHIPROCKET_SETTINGS`)
    .then((response) => {
      if (
        Object.keys(response.data).length === 0 ||
        Object.keys(response.data.value).length === 0
      ) {
        return true;
      }
      return !response.data.value.manualCourierSelection;
    })
    .catch(handleError);
};
network.setAutomaticCourierSelection = (value) => {
  return instance
    .put(`${config.apiHost}/v1/company/settings/SHIPROCKET_SETTINGS`, {
      value: {
        manualCourierSelection: value,
      },
    })
    .then((response) => response.data)
    .catch(handleError);
};

network.fetchAvailablePaymentGateways = () => {
  return instance
    .get(`${config.apiHost}/v6/external-account/available-payment-gateways`)
    .then((response) => response.data.gateways)
    .catch(handleError);
};

network.fetchPaymentGatewayData = (gatewayId) => {
  return instance
    .get(`${config.apiHost}/v2/external-account/payment-gateway`, {
      params: { gatewayId },
    })
    .then((response) => response.data)
    .catch(handleError);
};

network.addOrUpdatePaymentGateway = (gatewayId, data) => {
  return instance
    .post(`${config.apiHost}/v2/external-account/payment-gateway`, {
      gatewayId,
      ...data,
    })
    .then((response) => response.data);
};

network.disablePaymentGateway = (gatewayId) => {
  return instance
    .delete(`${config.apiHost}/v1/external-account/payment-gateway`, {
      data: { gatewayId },
    })
    .then((response) => response.data)
    .catch(handleError);
};

network.sendVerificationEmail = (email) => {
  return instance
    .post(`${config.apiHost}/user/change-email-request`, { newEmail: email })
    .then((response) => response.data);
};

network.getEmails = () => {
  return instance.get(`${config.apiHost}/user/get-emails`).then((response) => response.data);
};

network.postGoogleMerchantCenter = (authCode) => {
  return instance
    .post(`${config.apiHost}/v1/auth/google/exchange-token`, {
      authCode: authCode,
    })
    .then((response) => response.data)
    .catch(() => {});
};

network.postGoogleMerchantCenterDetails = (data) =>
  instance
    .post(`${config.apiHost}/v1/google-merchant/meta-data`, data)
    .then((response) => response.data)
    .catch(() => {});

network.getGoogleMerchantCenterDetails = () =>
  instance
    .get(`${config.apiHost}/v1/google-merchant/meta-data`)
    .then((response) => response.data)
    .catch(() => {});

network.postGoogleSiteVerification = (data) =>
  instance
    .post(`${config.apiHost}/v1/experiment/google-site-verification`, data)
    .then((response) => response.data);

network.getGoogleSiteVerification = () =>
  instance
    .get(`${config.apiHost}/v1/experiment/google-site-verification`)
    .then((response) => response.data);

network.getUpcomingWebinars = () => {
  return instance.get(`${config.apiHost}/v1/webinar/upcoming`).then((response) => response.data);
};

network.getWebinarDetails = (id) => {
  return instance
    .get(`${config.apiHost}/v1/webinar?webinarId=${id}`)
    .then((response) => response.data);
};

network.getUserInfo = () => {
  return instance.get(`${config.apiHost}/v1/user/name-and-email`).then((res) => res.data);
};

network.reserveASeat = ({ webinarId, firstName, lastName, email }) => {
  return instance
    .post(`${config.apiHost}/v1/webinar/register-for-webinar`, {
      webinarId,
      firstName,
      lastName,
      email,
    })
    .then((res) => res.data);
};

network.getWebinarAttendance = ({ webinarId }) => {
  return instance
    .post(`${config.apiHost}/v1/webinar/webinar-attendance`, { webinarId })
    .then((res) => res.data);
};

network.getSalesReport = ({ startDate, endDate }) => {
  return instance
    .get(
      `${
        config.apiHost
      }/v1/reports/sales-report/?startDate=${startDate}&endDate=${endDate}&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    )
    .then((res) => res.data)
    .catch(handleError);
};

network.getVisitorReport = ({ startDate, endDate }) => {
  return instance
    .get(
      `${
        config.apiHost
      }/v1/reports/visitor-excel/?startDate=${startDate}&endDate=${endDate}&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    )
    .then((res) => res.data)
    .catch(handleError);
};

network.getDailyReportStatus = () => {
  return instance
    .get(`${config.apiHost}/v1/reports/sales-report/status`)
    .then((res) => res.data)
    .catch(handleError);
};

network.setDailyReport = (dailyReportStatus) => {
  return instance
    .post(`${config.apiHost}/v1/reports/sales-report/status`, {
      dailyReportStatus,
    })
    .then((res) => res.data)
    .catch((e) => e);
};

network.getTemporaryCredentials = (idToken) =>
  instance
    .get(`${config.s3TempCredentials}?idToken=${idToken}`)
    .then((res) => res.data)
    .catch(handleError);

network.getNativeAppDetails = () =>
  instance.get(`${config.apiHost}/v1/native-app-offering/app-details`);

network.getMerchantApiKey = () => instance.get(`${config.merchantApiHost}/v1/apikey`);

network.generateMerchantApiKey = () => instance.post(`${config.merchantApiHost}/v1/apikey`);

network.regenerateMerchantApiKey = () =>
  instance.post(`${config.merchantApiHost}/v1/apikey/regenerate`);

network.getJewelleryPriceDisplayStatus = ({ entityType, entityId }) => {
  return instance
    .get(
      `${config.apiHost}/experiment/jewellery-price-visibility?entityId=${entityId}&entityType=${entityType}`
    )
    .then((res) => res.data)
    .catch(handleError);
};

network.setJewelleryPriceDisplayStatus = ({ jewelleryPriceConfig = {}, entityId, entityType }) => {
  return instance
    .post(
      `${config.apiHost}/experiment/jewellery-price-visibility?entityId=${entityId}&entityType=${entityType}`,
      jewelleryPriceConfig
    )
    .then((res) => res.data)
    .catch(handleError);
};

network.getWebhooks = () => {
  return instance
    .get(`${config.apiHost}/v1/webhook/events`)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

network.getAllWebhookEvents = () => {
  return instance
    .get(`${config.apiHost}/v1/webhook/all-events`)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

network.createWebhook = ({ webhookUrl, webhookEvents }) => {
  return instance
    .post(`${config.apiHost}/v1/webhook/register-events`, {
      webhookUrl,
      webhookEvents,
    })
    .then((res) => res.data)
    .catch(handleError);
};

network.deleteWebhook = ({ webhookUrls }) => {
  return instance
    .post(`${config.apiHost}/v1/webhook/deregister-events`, {
      webhookUrls,
    })
    .then((res) => res.data)
    .catch(handleError);
};

network.syncContacts = (data) =>
  instance
    .post(`${config.apiHost}/v1/company-contact`, data)
    .then((response) => response.data)
    .catch((error) => {
      handleError(error);
      throw error;
    });

/* Apis wrapper */

network.getData = (url, query) => {
  let updatedUrl = config.apiHost + url;
  if (query) {
    updatedUrl = updatedUrl + query;
  }
  return instance
    .get(updatedUrl)
    .then((res) => res)
    .catch((e) => e);
};

network.postData = (url, data) => {
  return instance
    .post(`${config.apiHost}${url}`, data)
    .then((res) => res)
    .catch((e) => e);
};

network.putData = (url, data) => {
  return instance
    .put(`${config.apiHost}${url}`, data)
    .then((res) => res)
    .catch((e) => e);
};

network.patchData = (url, data) => instance.patch(`${config.apiHost}${url}`, data);

network.deleteData = (url, data) => {
  return instance
    .delete(`${config.apiHost}${url}`, { data: data })
    .then((res) => res)
    .catch((e) => e);
};

network.upsertCustomFont = (data) => {
  return instance
    .post(`${config.apiHost}/v1/company/upsert-font`, data)
    .then((res) => res.data)
    .catch((e) => e);
};

network.getCustomFonts = () => {
  return instance
    .get(`${config.apiHost}/v1/company/fonts-list`)
    .then((res) => res.data)
    .catch(handleError);
};

network.getFontsMap = () => {
  return instance
    .get(`${config.apiHost}/v1/company/fonts-map`)
    .then((res) => res.data)
    .catch(handleError);
};

network.getCustomPageOrCataloguePreviewLink = () => {
  return instance
    .get(`${config.apiHost}/v1/menu/preview-url`)
    .then((res) => res.data)
    .catch(handleError);
};

network.getCustomersData = (url) => {
  return instance
    .get(`${config.apiHost}/v1/order/customers?${url}`)
    .then((res) => res.data)
    .catch(handleError);
};

network.createCustomer = ({ data }) => {
  return instance
    .post(`${config.apiHost}/v1/order/customers`, data)
    .then((res) => res.data)
    .catch(handleError);
};

network.editCustomerDetails = (data) => {
  return instance
    .put(`${config.apiHost}/v1/order/customers`, data)
    .then((res) => res.data)
    .catch(handleError);
};

network.deleteOrder = (orderId) => {
  return instance
    .delete(`${config.apiHost}/v2/order/app`, { data: { orderId } })
    .then((res) => res.data)
    .catch(handleError);
};

network.deleteItemFromInquiry = ({ inquiryIds }) => {
  return instance
    .delete(`${config.apiHost}/v2/inquiry/app`, { data: { inquiryIds } })
    .then((res) => res.data)
    .catch(handleError);
};

network.updateQuantityInInquiry = ({ inquiryId, itemCount }) => {
  return instance
    .put(`${config.apiHost}/v1/inquiry/quantity`, { inquiryId, itemCount })
    .then((res) => res.data)
    .catch(handleError);
};

network.getCountryCode = () => {
  return instance.get(`${config.apiHost}/v1/config/ip-geo`);
};

export default network;
