import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useParams } from 'react-router';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import network from 'qs-data/network';
import { Button, Switch, withStyles } from '@material-ui/core';
import {
  ACCESS_TYPE,
  ATOM,
  CATALOGUE_LEVEL_PRIVACY_SETTINGS,
  COUNT_TYPE,
  ENTITY_TYPE,
  GlOBAL_GROUPS,
  renderButtonText,
  renderCount,
  SWITCH,
} from '../privacySettingConstants';
import { setNavigationBarColor, setStatusBarColor } from '../../../os';
import WhitePlus from 'qs-assets/images/ic_white_plus.png';
import Toast from 'qs-common/Alerts/Toast';
import { useHistory } from 'react-router-dom';
import { getToken } from 'qs-data/util';
import RenderRowIcon from '../RenderRowIcon';
import PrivacySettingsCache from '../PrivacySettingsCache';
import Loader from 'qs-common/Loader';
import CacheKeys from '../CacheKeys';
import { getI18N } from '../../../i18N';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';

const CustomSwitch = withStyles({
  switchBase: {
    color: '#949EAE',
    '&$checked': {
      color: '#3E925C',
    },
    '&$checked + $track': {
      backgroundColor: '#2F4B30',
    },
  },
  checked: {},
  track: {},
})(Switch);

const NAVBAR_HEIGHT = 55;

const CataloguePrivacySettings = () => {
  const { t } = getI18N();
  const [, dispatch] = useAppContext();
  const history = useHistory();
  const { entityId, entityType, accessLevel } = useParams();
  const [loading, setLoading] = useState(false);
  const [cataloguePrivacyGroups, setCataloguePrivacyGroups] = useState(
    PrivacySettingsCache.getCacheForKey(CacheKeys.CATALOGUE_PRIVACY_SETTINGS) || {}
  );
  const [selectedId, setSelectedId] = useState();
  const [toastState, setToastState] = useState({
    open: false,
    message: '',
  });
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');

  const onCloseIconClick = () => {
    window.history.back();
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: '#439875',
        color: '#FFFFFF',
        boxShadow: false,
        enableBoxShadow: false,
        title: t('catalogue_privacy_settings'),
        hideBack: true,
        showCloseIcon: true,
        onCloseIconClick: onCloseIconClick,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
      },
    });
    setNavigationBarColor('#439875');
    setStatusBarColor('#242C36');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getCataloguePrivacySettingsGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCataloguePrivacySettingsGroups = async () => {
    if (!PrivacySettingsCache.getCacheForKey(CacheKeys.CATALOGUE_PRIVACY_SETTINGS)) {
      setLoading(true);
    }

    try {
      const data = await network.getCataloguePrivacySettingsGroups(entityId);
      setCataloguePrivacyGroups(data);
      PrivacySettingsCache.setCacheForKey(CacheKeys.CATALOGUE_PRIVACY_SETTINGS, data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setToastState({
        open: true,
        message: t('catalogue_privacy_details_error'),
      });
    }
  };
  const renderCatalogueGroupMembers = (groupId, group) => {
    const keyName = `${CacheKeys.SELETCED_CATALOGUE_GROUP}_${accessLevel}_${groupId}`;
    PrivacySettingsCache.setCacheForKey(keyName, group);
    history.push({
      pathname: `/group-members/${ENTITY_TYPE.CATALOGUE}/${accessLevel}/${groupId}`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
      state: {
        entityId: entityId,
      },
    });
  };

  const showContactsCount = (count) => {
    return renderCount(count, COUNT_TYPE.CONTACT, ENTITY_TYPE.CATALOGUE);
  };
  const showGroupCount = () => {
    return renderCount(cataloguePrivacyGroups.defaultGroups.length, COUNT_TYPE.GROUP);
  };
  const toggleSwitch = async () => {
    setSelectedId(SWITCH);
    try {
      await network.toggleGlobalDefaultGlobal({
        catalogueId: entityId,
        enabled: !cataloguePrivacyGroups.defaultGroupsEnabled,
      });
      setSelectedId('');
    } catch (error) {
      setSelectedId('');
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
    PrivacySettingsCache.setCacheForKey(CacheKeys.CATALOGUE_LEVEL_PRIVACY_SETTINGS, {
      ...cataloguePrivacyGroups,
      defaultGroupsEnabled: !cataloguePrivacyGroups.defaultGroupsEnabled,
    });
    setCataloguePrivacyGroups((prevState) => {
      return { ...prevState, defaultGroupsEnabled: !prevState.defaultGroupsEnabled };
    });
  };
  const renderAddExistingGroup = () => {
    history.push({
      pathname: `/add-existing-groups/${entityType}/${accessLevel}`,
      search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
      state: {
        entityId: entityId,
      },
    });
  };

  const renderToastMessage = (isAdded) => {
    if (accessLevel === GlOBAL_GROUPS.ALLOWED) {
      if (!isAdded) {
        return 'denied';
      }
      return 'allowed';
    }
    if (!isAdded) {
      return 'unblocked';
    }
    return 'blocked';
  };

  const toggleSingleDefaultGlobal = async (group) => {
    setSelectedId(group.id);
    try {
      await network.toggleSingleDefaultGlobal({
        catalogueId: entityId,
        listType: accessLevel,
        groupId: group.id,
        isAdded: !group.isAdded,
      });
      const updatedDefaultGroup = cataloguePrivacyGroups.defaultGroups.find(
        (defaultGroup) => defaultGroup.id === group.id
      );
      updatedDefaultGroup.isAdded = !updatedDefaultGroup.isAdded;
      setSelectedId('');
      PrivacySettingsCache.setCacheForKey(CacheKeys.CATALOGUE_LEVEL_PRIVACY_SETTINGS, {
        ...cataloguePrivacyGroups,
        defaultGroups: cataloguePrivacyGroups.defaultGroups,
      });
      setCataloguePrivacyGroups((prevState) => {
        return { ...prevState, defaultGroups: cataloguePrivacyGroups.defaultGroups };
      });
      setToastState({
        open: true,
        message: t(updatedDefaultGroup.memberCount === 1 ? 'contact_privacy_list_status' : 'contacts_frivacy_list_status', {
          memberCount: updatedDefaultGroup.memberCount,
          status: renderToastMessage(updatedDefaultGroup.isAdded)
        }),
      });
    } catch (error) {
      setSelectedId('');
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const removeGroupsFromCataloguePrivacyListInfo = async (group) => {
    setSelectedId(group.id);
    try {
      await network.removeFromPrivacyList({
        entityType: ENTITY_TYPE.CATALOGUE,
        entityId: entityId,
        forceListType:
          accessLevel === GlOBAL_GROUPS.ALLOWED ? ACCESS_TYPE.WHITELIST : ACCESS_TYPE.BLACKLIST,
        ids: [group.id],
        type: ATOM.GROUP,
      });
      const updatedOtherGroups = cataloguePrivacyGroups.otherGroups.filter(
        (otherGroup) => otherGroup.id !== group.id
      );
      PrivacySettingsCache.setCacheForKey(CacheKeys.CATALOGUE_LEVEL_PRIVACY_SETTINGS, {
        ...cataloguePrivacyGroups,
        otherGroups: updatedOtherGroups,
      });
      setSelectedId('');
      setCataloguePrivacyGroups((prevState) => {
        return { ...prevState, otherGroups: updatedOtherGroups };
      });
    } catch (error) {
      setSelectedId('');
      setToastState({
        open: true,
        message: t('something_went_wrong'),
      });
    }
  };

  const renderCatalogueGroups = () => {
    if (
      !cataloguePrivacyGroups ||
      !Object.keys(cataloguePrivacyGroups).length ||
      !cataloguePrivacyGroups.catalogueGroups ||
      !cataloguePrivacyGroups.catalogueGroups.length
    ) {
      return null;
    }

    return cataloguePrivacyGroups.catalogueGroups.map((catalogueGroup) => {
      return (
        <div className="cataloueGroupContainer" key={catalogueGroup.id}>
          <div className="catalogueGroupTitle">{t('catalogue_group')}</div>
          <div className="description">
            {t(CATALOGUE_LEVEL_PRIVACY_SETTINGS[accessLevel].description)}
          </div>
          <div className="contactCountandEditButton">
            <div className="titleAndCount">
              <div className="contactType">
                {t(CATALOGUE_LEVEL_PRIVACY_SETTINGS[accessLevel].title)}
              </div>
              <div className="count">{showContactsCount(catalogueGroup.memberCount)}</div>
            </div>
            <Button
              className="editButton"
              onClick={() => renderCatalogueGroupMembers(catalogueGroup.id, catalogueGroup)}
            >
              <span className="editButtonText">{t('edit')}</span>
            </Button>
          </div>
        </div>
      );
    });
  };

  const renderDefaultGroups = () => {
    if (
      !cataloguePrivacyGroups ||
      !Object.keys(cataloguePrivacyGroups).length ||
      !cataloguePrivacyGroups.defaultGroups ||
      !cataloguePrivacyGroups.defaultGroups.length
    ) {
      return null;
    }
    return (
      <div className="groupContainer">
        <div className="groupHeaderSection">
          <div className="groupContainerTitle">
            {t(CATALOGUE_LEVEL_PRIVACY_SETTINGS[accessLevel].defaultTitle)}
          </div>
          <div className="groupContainerCount">{showGroupCount()}</div>
          <div className="groupContainerDescription">
            {t('these_groups_have_been_applied_by_default_as_specified_in_your_company_settings')}
          </div>
          <div className="toggleSwitch">
            <div className="text">{t('enabled')}</div>
            <div className="switch">
              {selectedId === SWITCH ? (
                <Loader small />
              ) : (
                <CustomSwitch
                  checked={cataloguePrivacyGroups.defaultGroupsEnabled || false}
                  name="cataloguePrivacySettings"
                  onChange={() => toggleSwitch()}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}
            </div>
          </div>
        </div>

        {cataloguePrivacyGroups.defaultGroups.map((defaultGroup) => {
          const { id, name, iconColor, iconName, memberCount, isAdded } = defaultGroup;
          return (
            <RenderRowIcon
              key={id}
              name={name}
              iconColor={iconColor}
              iconName={iconName}
              renderCount={() =>
                renderCount(memberCount, COUNT_TYPE.CONTACT, ENTITY_TYPE.CATALOGUE)
              }
              renderButtontext={() => renderButtonText(accessLevel, isAdded)}
              api={() => toggleSingleDefaultGlobal(defaultGroup)}
              loading={id === selectedId}
              entityType={entityType}
              additionalStyles={true}
            />
          );
        })}
      </div>
    );
  };

  const renderOtherGroups = () => {
    if (
      !cataloguePrivacyGroups ||
      !Object.keys(cataloguePrivacyGroups).length ||
      !cataloguePrivacyGroups.otherGroups ||
      !cataloguePrivacyGroups.otherGroups.length
    ) {
      return null;
    }
    return (
      <div className="groupContainer">
        <div className="groupHeaderSection">
          <div className="groupContainerTitle">
            {t(CATALOGUE_LEVEL_PRIVACY_SETTINGS[accessLevel].otherGroupsTitle)}
          </div>
          <div className="groupContainerCount">{showGroupCount()}</div>
        </div>

        {cataloguePrivacyGroups.otherGroups.map((otherGroup) => {
          const { id, name, iconColor, iconName, memberCount, isAdded } = otherGroup;
          return (
            <RenderRowIcon
              key={id}
              name={name}
              iconColor={iconColor}
              iconName={iconName}
              memberCount={memberCount}
              renderCount={() =>
                renderCount(memberCount, COUNT_TYPE.CONTACT, ENTITY_TYPE.CATALOGUE)
              }
              renderButtontext={() => renderButtonText(accessLevel, isAdded)}
              api={() => removeGroupsFromCataloguePrivacyListInfo(otherGroup)}
              loading={id === selectedId}
              entityType={entityType}
              additionalStyles={true}
            />
          );
        })}
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className="cataloguePrivacySettingsContainer">
            {renderCatalogueGroups()}
            {renderDefaultGroups()}
            {renderOtherGroups()}
          </div>
          <img
            onClick={renderAddExistingGroup}
            src={WhitePlus}
            alt="plus icon"
            className={'plusIcon'}
            style={{
              backgroundColor: '#439875',
            }}
          />
        </>
      )}

      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={3000}
        onClose={() => {
          setToastState({
            open: false,
            message: '',
          });
        }}
      />
    </>
  );
};

export default CataloguePrivacySettings;
