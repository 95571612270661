import React from 'react';
import './styles.scss';
import { getI18N } from '../../../../i18N';

export default function Actions({
  quantity,
  onIncrement,
  onDecrement,
  onClick,
  noChevron,
  showAdded,
  isLoading,
}) {
  const { t } = getI18N();

  return showAdded ? (
    <span className="product-row-action-message">{t('added')}</span>
  ) : (
    <>
      <div className="product-row-actions">
        <button
          className={isLoading ? 'decrement-btn quantity-btn-disabled' : 'decrement-btn'}
          onClick={onDecrement}
        >
          -
        </button>
        <span>{quantity}</span>
        <button
          className={isLoading ? 'increment-btn quantity-btn-disabled' : 'increment-btn'}
          onClick={onIncrement}
        >
          +
        </button>
      </div>
      {!noChevron && (
        <div className="chevron-btn" onClick={onClick}>
          &#8250;
        </div>
      )}
    </>
  );
}
