import { makeStyles } from '@material-ui/core';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import Loader from 'qs-common/Loader';
import SettingCard from 'qs-common/SettingCard';
import network from 'qs-data/network';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import './style.scss';
import { getI18N } from '../../i18N';

const primaryColor = '#0f141a',
  primaryColorMobile = '#4DA47A',
  textColor = '#FFFFFF';
const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
  },
  configureSettingCardRoot: {
    padding: '15px 30px',
    '&:last-child': {
      paddingBottom: 15,
    },
  },
});
const ConfigureShipRocketSettings = () => {
  const classes = useStyles();
  const [, dispatch] = useAppContext();
  const [isActive, setIsActive] = useState(false);
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const [loading, setLoading] = useState(true);
  const desktop = !source || source === 'desktop';
  const { t } = getI18N();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? primaryColor : primaryColorMobile,
        color: textColor,
        title: t('shipping_settings'),
        hideBack: false,
      },
    });
    setNavigationBarColor('#242c36');
    setStatusBarColor(primaryColorMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);
  const getAutomaticCourierSelection = async () => {
    try {
      const data = await network.getAutomaticCourierSelection();
      setIsActive(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //handled unresolved promise
    }
  };

  useEffect(() => {
    getAutomaticCourierSelection();
  }, []);

  const setAutomaticCourierSelection = async ({ isActiveState }) => {
    try {
      await network.setAutomaticCourierSelection(isActiveState);
      setIsActive(!isActiveState);
    } catch (error) {
      //handled unresolved promise
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div>
          <SettingCard
            id={'COURIER_SELECTION'}
            settingTitle={t('automatic_courier_selection')}
            settingSubtitle={t('show_and_collect_shipping_charges_from_your_customers')}
            showIcon={false}
            initialActiveValue={isActive}
            onCardClick={setAutomaticCourierSelection}
            disabled={true}
            source={source}
            languageCode={languageCode}
            materialStyles={classes}
          />
          <div style={{ borderBottom: '1px solid #3A424E' }} />
        </div>
      )}
    </>
  );
};

export default withRouter(ConfigureShipRocketSettings);
