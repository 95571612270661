import React from 'react';

export default ({ color = "#fff", width = 17, height = 19 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 5H0V6H17V5Z" fill={color}/>
      <path d="M17 9H0V10H17V9Z" fill={color}/>
      <path d="M17 13H0V14H17V13Z" fill={color}/>
      <path d="M11.12 2.56H6L8.56 0L11.12 2.56Z" fill={color}/>
      <path d="M11.12 16.44H6L8.56 19L11.12 16.44Z" fill={color}/>
    </svg>
  );
}