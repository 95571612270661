import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import MerchantIcon from 'qs-assets/images/Bitmap@2x.png';
import GoogleLogo from 'qs-assets/images/google.png';
import Loader from 'qs-common/Loader';
import network from 'qs-data/network';
import { getToken } from 'qs-data/util';
import './style.css';
import Toast from 'qs-common/Alerts/Toast';
import config from '../../config';
import { initiateGoogleLogin, setNavigationBarColor, setStatusBarColor } from '../../os';
import useSearchParamsQuery from 'qs-common/Hooks/useSearchParamsQuery';
import { getI18N } from '../../i18N';
import { NAVBAR_HEIGHT } from 'qs-data/globalConstants';

const GoogleMerchantCenter = () => {
  const { t } = getI18N();
  const history = useHistory();
  const source = useSearchParamsQuery().get('source');
  const languageCode = useSearchParamsQuery().get('languageCode');
  const desktop = !source || source === 'desktop';
  const [, dispatch] = useAppContext();
  const { url } = useRouteMatch();
  const [toastState, setToastState] = useState({
    message: '',
    open: false,
  });
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: desktop ? '#222B36' : '#4DA47A',
        color: '#FFFFFF',
        title: t('google_merchant_center'),
        height: NAVBAR_HEIGHT,
        boxShadow: desktop ? 'none' : undefined,
        hideBack: desktop,
        googleMerchantCenter: true,
      },
    });
    dispatch({
      type: 'SET_PAGE_CONTAINER_STYLE',
      pageContainerStyle: {
        height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
        paddingTop: `${NAVBAR_HEIGHT}px`,
      },
    });
    setNavigationBarColor('#242C36');
    setStatusBarColor('#4DA47A');
    return () => {
      dispatch({
        type: 'UPDATE_NAVBAR',
        navBar: {
          height: '',
        },
      });
      dispatch({
        type: 'SET_PAGE_CONTAINER_STYLE',
        pageContainerStyle: {},
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, dispatch]);

  useEffect(() => {
    try {
      if (window.gapi && window.gapi.load) {
        window.gapi.load('auth2', () => {
          window.auth2 = window.gapi.auth2.init({
            clientId: `${config.googleClientId}.apps.googleusercontent.com`,
            scope: 'https://www.googleapis.com/auth/content',
            ux_mode: 'popup',
          });
        });
      }
    } catch (error) {
      setToastState({
        open: true,
        message: t('something_went_wrong_with_google_sign_in'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('googleAuthToken', ({ detail }) => {
      const { authCode } = detail;
      postAuthCode(authCode);
    });

    return () => {
      document.removeEventListener('googleAuthToken', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postAuthCode = (authCode) => {
    network
      .postGoogleMerchantCenter(authCode)
      .then(() => {
        history.replace({
          pathname: `${url}/details`,
          search: `?token=${getToken()}&source=${source}&languageCode=${languageCode}`,
        });
      })
      .catch(() => {
        setToastState({
          open: true,
          message: t('token_is_invalid'),
        });
      });
  };

  const renderImage = () => {
    return (
      <div className="centerLoader" style={{ width: '150px', height: '150px' }}>
        {imageLoaded ? null : <Loader />}
        <img
          src={MerchantIcon}
          style={imageLoaded ? {} : { display: 'none' }}
          alt=""
          width="150px"
          height="150px"
          onLoad={() => setImageLoaded(true)}
        />
      </div>
    );
  };

  const closeToast = () => {
    setToastState({ open: false, message: '' });
  };

  return (
    <div className="googleMerchantParentContainer">
      <div className="googleMerchantContainer">
        <div className="imageContainer">{renderImage()}</div>
        <div className="textContainer">
          <p>{t('get_your_product_in_front_of_hundreds')}</p>
        </div>
        <div className="learnMoreLink">
          <a
            href="https://www.google.com/retail/solutions/merchant-center/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('learn_more')}
          </a>
        </div>
        <button onClick={initiateGoogleLogin} className="googleBtn">
          <div className={'logoContainer googleLogoContainer'}>
            <img src={GoogleLogo} alt={t('google_logo')} />
          </div>
          <span className="googleSignin">{t('sign_in_with_google')}</span>
        </button>
      </div>
      <Toast open={toastState.open} message={toastState.message} onClose={closeToast} />
    </div>
  );
};
export default withRouter(GoogleMerchantCenter);
