import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from 'qs-assets/icons/SearchIcon';
import './style.scss';

export default function SearchableInput({
  inputValue,
  onInputValueChange,
  placeholder,
}) {
  return (
    <TextField
      autoFocus={true}
      fullWidth={true}
      value={inputValue}
      onChange={onInputValueChange}
      placeholder={placeholder}
      variant={'outlined'}
      InputProps={{
        classes: {
          input: 'choose-customer-search-input',
          formControl: 'choose-customer-search-input-form-control'
        },
        startAdornment: (
          <InputAdornment className="searchIconAdornment">
            <SearchIcon color={'#fff'} size={20} />
          </InputAdornment>
        ),
      }}
      className="choose-customer-search"
    />
  );
}
