import React, { useCallback, useEffect, useRef } from 'react';
import { trackAnalytics } from '../../../os';
import useTimer from 'qs-common/Hooks/useTimer';
import './style.scss';

export default function VideoPlayer({ embedId, videoTitle, language, redirectedFrom }) {
  const { timer, handleStart, handlePause, handleReset } = useTimer(0);
  const videoDurationRef = useRef(0);
  const timerRef = useRef(0);

  const percentagePlayed = useCallback(() => {
    return `${Math.floor((timer.current / videoDurationRef.current) * 100)}%`;
  }, [timer]);

  const handlePauseTimer = useCallback(() => {
    handlePause();
    clearInterval(timerRef.current);
  }, [handlePause]);

  const handleResetTimer = useCallback(() => {
    handleReset();
    clearInterval(timerRef.current);
  }, [handleReset]);

  const handleAnalytics = useCallback(
    (eventName, percentagePlayed) => {
      trackAnalytics({
        eventName,
        props: {
          videoId: embedId,
          videoTitle: videoTitle,
          language: language,
          duration: timer.current,
          percentagePlayed,
          from: redirectedFrom,
        },
      });
    },
    [embedId, language, videoTitle, timer, redirectedFrom]
  );

  const handleAnalyticsTimer = useCallback(() => {
    timerRef.current = setInterval(() => {
      handleAnalytics('help_video_played', percentagePlayed());
    }, 3000);
  }, [handleAnalytics, percentagePlayed]);

  const handleStartTimer = useCallback(() => {
    handleStart();
    handleAnalyticsTimer();
  }, [handleStart, handleAnalyticsTimer]);

  useEffect(
    () => () => {
      clearInterval(timerRef.current);
    },
    []
  );

  const onPlayerReady = useCallback((event) => {
    event.target.playVideo();
  }, []);

  const onPlayerStateChange = useCallback(
    (event) => {
      switch (event.data) {
        case 0:
          handleAnalytics('help_video_ended', percentagePlayed());
          handleResetTimer();
          break;
        case 1:
          videoDurationRef.current = Math.floor(event.target.playerInfo.duration);
          handleStartTimer();
          handleAnalytics('help_video_started', percentagePlayed());
          break;
        case 2:
          handlePauseTimer();
          break;
        default:
          break;
      }
    },
    [handlePauseTimer, handleResetTimer, percentagePlayed, handleStartTimer, handleAnalytics]
  );

  const loadVideo = useCallback(() => {
    handleResetTimer();
    window.YT.ready(() => {
      return new window.YT.Player('existing-iframe', {
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    });
  }, [handleResetTimer, onPlayerReady, onPlayerStateChange]);

  useEffect(() => {
    if (!window.YT) {
      let tag = document.createElement('script');
      tag.id = 'active-iframe';
      tag.src = 'https://www.youtube.com/iframe_api';

      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = loadVideo;
    } else {
      loadVideo();
    }
  }, [embedId, loadVideo]);

  useEffect(
    () => () => {
      if (timer.current > 0) {
        handleAnalytics('help_video_changed', percentagePlayed());
      }
    },
    [percentagePlayed, handleAnalytics, timer]
  );

  return (
    <div className="videoPlayer">
      <iframe
        id="existing-iframe"
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?enablejsapi=1`}
        title={videoTitle}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}
