let showModal = false;
let id = '';
let yourReferralsCache = {};
let referralLink = null;
const setShowModal = (value) => {
  showModal = value;
};
const getShowModal = () => showModal;
const setOfferModalId = (value) => {
  id = value;
};
const getOfferModalId = () => id;

const setYourReferralsCache = (filterOption, data) => {
  yourReferralsCache[filterOption] = data;
};
const getYourReferralsCache = (filterOption) => {
  return yourReferralsCache[filterOption];
};

const setReferralLinkCache = (value) => {
  referralLink = value;
};
const getReferralLinkCache = () => referralLink;

const getReferralAnalyticsObject = () => {
  return {
    link: `qsell.co/refer/${getReferralLinkCache()}`,
    code: getReferralLinkCache(),
  };
};

const deleteYourReferralsData = () => {
  Object.keys(yourReferralsCache).forEach((filterOption) => {
    delete yourReferralsCache[filterOption];
  });
};
export default {
  setShowModal,
  getShowModal,
  getOfferModalId,
  setOfferModalId,
  setYourReferralsCache,
  getYourReferralsCache,
  deleteYourReferralsData,
  setReferralLinkCache,
  getReferralLinkCache,
  getReferralAnalyticsObject,
};
